import { useContext } from 'react';
import { Link } from 'react-router-dom';
import SecutiryContext from '../features/securityContext';

const HeaderUserMenu = (props) => {
    const { context } = useContext(SecutiryContext);
    return (
        <div
            className={
                'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
            }
            data-kt-menu='true'
        >
            <div className='menu-item px-3'>
                <div className='menu-content d-flex align-items-center px-3'>
                    <div className='symbol symbol-50px me-5'>
                        <img alt='Logo' src='/images/no-user.jpg' />
                    </div>

                    <div className='d-flex flex-column'>
                        <div className='fw-bolder d-flex align-items-center fs-5'>
                            {context?.user?.lastName} {context?.user?.firstName}
                        </div>
                        <a href='#' className='fw-bold text-muted fs-7'>
                            {context?.user?.login}
                        </a>
                        {context?.isRoot ? (
                            <div>
                                <span className='badge badge-sm bg-light-success'>
                                    АДМИНИСТРАТОР
                                </span>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>

            <div className='separator my-2'></div>

            <div className='menu-item px-5'>
                <Link to={'/personal/profile'} className='menu-link px-5'>
                    Мой профиль
                </Link>
            </div>

            <div className='menu-item px-5'>
                <Link to={'/personal/overview'} className='menu-link px-5'>
                    <span className='menu-text'>Мои аккаунты</span>
                    <span className='menu-badge'>
                        <span className='badge badge-sm bg-light-success rounded-pill'>
                            {context?.accounts?.length}
                        </span>
                    </span>
                </Link>
            </div>

            <div className='separator my-2'></div>

            {context?.isRoot ? (
                <>
                    <div className='menu-item px-5'>
                        <Link
                            to={'/admin/dashboard'}
                            className='menu-link px-5'
                        >
                            Панель администратора
                        </Link>
                    </div>
                    <div className='menu-item px-5'>
                        <Link
                            to={'/personal/notifications/settings'}
                            className='menu-link px-5'
                        >
                            Настройки уведомлений
                        </Link>
                    </div>

                    <div className='separator my-2'></div>
                </>
            ) : (
                ''
            )}

            <div className='menu-item px-5'>
                <Link to={'/logout'} className='menu-link px-5'>
                    Выйти
                </Link>
            </div>
        </div>
    );
};

export default HeaderUserMenu;
