import { useEffect, useState } from 'react';
import { DateToString, UtcToLocal } from '../../../helpers/DateHelpers';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectEmployees,
    getEmployeesAsync,
    selectPaginator,
} from '../../../app/storeReducers/AccountEmployeesSlice';
import SkeletonTable from '../../../components/skeleton/SkeletonTable';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import Paginator from '../../../components/Paginator';
import { useHistory } from 'react-router-dom';
import FindComponent from '../../../components/FindComponent';

const AdminEmployeesListTable = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const employees = useSelector(selectEmployees);
    const isLoading = useSelector(selectIsLoading);
    const paginator = useSelector(selectPaginator);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [empSort, setEmpSort] = useState('last_name');

    const [searchString, setSearchString] = useState('');

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(
                getEmployeesAsync({
                    accountId,
                    searchString,
                    sort: empSort,
                    pageSize,
                    pageIndex,
                })
            );
        }

        loadResourcesAndDataAsync();
    }, [searchString, dispatch, pageIndex, pageSize]);

    useEffect(() => {
        Array.from(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    }, [isLoading]);

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(0);
        setPageSize(size);
    };

    const openEntityCard = (accountId, entityId) => {
        history.push(`/account/${accountId}/employees/${entityId}`);
    };

    return (
        <>
            <div className='card mb-4'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    <div className='card-toolbar'>
                        <FindComponent
                            callback={(name) => setSearchString(name)}
                        />
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <SkeletonTable
                            cols={[
                                'Имя',
                                'Дата начала',
                                'Дата окончания',
                                '',
                                '',
                            ]}
                            rowsCount={15}
                            isLoading={isLoading}
                            classTable='table-striped gy-7 gs-7'
                        >
                            <table className='table table-striped gy-7 gs-7'>
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th>Дата</th>
                                        <th>Имя</th>
                                        <th>Логин</th>
                                        <th>Аккаунт</th>
                                        <th>Роль</th>
                                        <th>Статус</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employees?.map((item) => (
                                        <tr
                                            key={item.id}
                                            onClick={() =>
                                                openEntityCard(
                                                    item.account.id,
                                                    item.id
                                                )
                                            }
                                            className='cursor-pointer'
                                        >
                                            <td className='ps-4'>
                                                {DateToString(
                                                    UtcToLocal(item.createdAt)
                                                )}
                                            </td>
                                            <td className='ps-4'>
                                                {item.name}
                                            </td>
                                            <td>{item.user?.title}</td>
                                            <td>{item.account?.title}</td>
                                            <td>{item.roles.join(', ')}</td>
                                            <td>
                                                {item.blocked ? (
                                                    <i
                                                        className='fas fa-ban text-danger me-2'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-placement='top'
                                                        title='Заблокирован'
                                                    />
                                                ) : (
                                                    <span className='badge badge-success'>
                                                        OK
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </SkeletonTable>
                    </div>
                </div>
            </div>
            <Paginator
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                pageCount={paginator.totalPages}
                pageSizeArray={[50, 100, 500]}
                pageSize={pageSize}
                countCurrent={employees.length}
                countTotal={paginator.totalCount}
            />
        </>
    );
};

export default AdminEmployeesListTable;
