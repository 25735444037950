/* eslint-disable react/jsx-no-target-blank */
import { MenuItem } from './MenuItem';
import { useRouteMatch } from 'react-router';
import { useAccount } from '../../components/useAccount';
import { useContext, useEffect, useState } from 'react';
import { MenuItemWithSub } from './MenuItemWithSub';
import SecutiryContext from '../../features/securityContext';

interface LayoutParams {
    accountId: string;
}

interface SecurityContext {
    accounts: ContextAccount[];
    permissions: AccountPermission[];
    isRoot: boolean;
}

interface ContextAccount {
    id: number;
    title: string;
    actions: string[];
}
interface AccountPermission {
    id: number;
    title: string;
    actions: string[];
}

export function MenuMain() {
    const matchParams = useRouteMatch().params as LayoutParams;
    const accountId: number = parseInt(matchParams.accountId);
    const account = useAccount();
    const { context } = useContext(SecutiryContext) as any;
    const ctnx = context as SecurityContext;

    const [accountActions, setAccountActions] = useState<string[]>();

    useEffect(() => {
        setAccountActions(ctnx?.permissions?.find((x) => x.id === accountId)?.actions);
    }, [ctnx, accountId]);

    return (
        <>
            <MenuItem
                to={`/account/${accountId}/dashboard`}
                title='Обзор'
                imageIcon='/media/icons/avanguard/Home1.png'
            />
            {ctnx?.isRoot || accountActions?.includes('employees_list') ? (
                <MenuItem
                    to={`/account/${accountId}/employees`}
                    title='Сотрудники'
                    imageIcon='/media/icons/avanguard/Stuff.png'
                />
            ) : null}
            {ctnx?.isRoot || accountActions?.includes('devices_list') ? (
                <MenuItem
                    to={`/account/${accountId}/devices`}
                    title='Устройства'
                    imageIcon='/media/icons/avanguard/Devices2.png'
                />
            ) : null}
            {ctnx?.isRoot || accountActions?.includes('vehicles_list') ? (
                <MenuItem
                    to={`/account/${accountId}/vehicles`}
                    title='Транспорт'
                    imageIcon='/media/icons/avanguard/Transport.png'
                />
            ) : null}
            {ctnx?.isRoot || accountActions?.includes('guest_list') ? (
                <MenuItem
                    to={`/account/${accountId}/guests`}
                    title='Посетители'
                    imageIcon='/media/icons/avanguard/visitors1.png'
                />
            ) : null}
            {account.type !== 'subaccount' && (ctnx?.isRoot || accountActions?.includes('guest_panel')) ? (
                <MenuItem
                    to={`/account/${accountId}/operatorGuestsEntrance`}
                    title='Заявки на посетителей'
                    imageIcon='/media/icons/avanguard/Application1.png'
                />
            ) : null}
            {account.type !== 'subaccount' && (ctnx?.isRoot || accountActions?.includes('guest_car_panel')) ? (
                <MenuItem
                    to={`/account/${accountId}/operatorGuestsParking`}
                    title='Заявки на транспорт'
                    imageIcon='/media/icons/avanguard/Application3.png'
                />
            ) : null}

            {account.type !== 'subaccount' && (ctnx?.isRoot || accountActions?.includes('parking_area_list')) ? (
                <MenuItem
                    to={`/account/${accountId}/parking`}
                    title='Парковка'
                    imageIcon='/media/icons/avanguard/Parking.png'
                />
            ) : null}

            {ctnx?.isRoot || accountActions?.includes('access_log_view') ? (
                <MenuItem
                    to={`/account/${accountId}/accesslogs`}
                    title='Журнал доступа'
                    imageIcon='/media/icons/avanguard/Accesslog2.png'
                />
            ) : null}

            {ctnx?.isRoot || accountActions?.includes('monitor_list') ? (
                <MenuItem
                    to={`/account/${accountId}/monitors`}
                    title='Монитор доступа'
                    imageIcon='/media/icons/avanguard/monitor.png'
                />
            ) : null}

            {account.type === 'business' && (accountActions?.includes('subaccount_list') || ctnx?.isRoot) ? (
                <MenuItem
                    to={`/account/${accountId}/subaccounts`}
                    title='Субаккаунты'
                    imageIcon='/media/icons/avanguard/Subaccount.png'
                />
            ) : null}

            {ctnx?.isRoot || accountActions?.includes('report_timetracking') ? (
                <MenuItemWithSub
                    to={`/account/${accountId}/reports`}
                    title='Отчеты'
                    imageIcon='/media/icons/base/list.png'
                >
                    {ctnx?.isRoot || accountActions?.includes('report_timetracking') ? (
                        <MenuItem
                            to={`/account/${accountId}/reports/timeTrackings`}
                            title='Приход-уход'
                            imageIcon='/media/icons/base/clock.png'
                        />
                    ) : null}
                    {ctnx?.isRoot || accountActions?.includes('report_timetracking') ? (
                        <MenuItem
                            to={`/account/${accountId}/reports/timesheet`}
                            title='Табель'
                            imageIcon='/media/icons/avanguard/Accesslog2.png'
                        />
                    ) : null}
                    {ctnx?.isRoot || accountActions?.includes('report_timetracking') ? (
                        <MenuItem
                            to={`/account/${accountId}/reports/presence`}
                            title='Присутствие'
                            imageIcon='/media/icons/avanguard/Accesslog2.png'
                        />
                    ) : null}
                </MenuItemWithSub>
            ) : null}
            <MenuItemWithSub
                to={`/account/${accountId}/admin`}
                title='Администрирование'
                imageIcon='/media/icons/avanguard/Settings2.png'
            >
                {ctnx?.isRoot || accountActions?.includes('location_edit') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/locations`}
                        title='Объекты'
                        imageIcon='/media/icons/base/map-pin-g400.png'
                    />
                ) : null}
                {ctnx?.isRoot || accountActions?.includes('groups_list') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/groups`}
                        title='Группы'
                        imageIcon='/media/icons/avanguard/Group.png'
                    />
                ) : null}
                {ctnx?.isRoot || accountActions?.includes('roles_list') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/roles`}
                        title='Роли'
                        imageIcon='/media/icons/avanguard/role.png'
                    />
                ) : null}
                {ctnx?.isRoot || accountActions?.includes('camera_list') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/cameras`}
                        title='Камеры'
                        imageIcon='/media/icons/avanguard/Camera.png'
                    />
                ) : null}
                {ctnx?.isRoot || accountActions?.includes('schemes_list') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/schemes`}
                        title='Схемы доступа'
                        imageIcon='/media/icons/avanguard/AccessScheme.png'
                    />
                ) : null}
                {ctnx?.isRoot || accountActions?.includes('schemes_list') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/schemeSchedules`}
                        title='Графики доступа'
                        imageIcon='/media/icons/avanguard/Timeline1.png'
                    />
                ) : null}
                {ctnx?.isRoot || accountActions?.includes('schemes_list') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/schedules`}
                        title='Расписания'
                        imageIcon='/media/icons/avanguard/Timeline1.png'
                    />
                ) : null}

                {ctnx?.isRoot || accountActions?.includes('cards_list') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/cards`}
                        title='Карты'
                        imageIcon='/media/icons/avanguard/Cards1.png'
                    />
                ) : null}
                {ctnx?.isRoot || accountActions?.includes('user_log_show') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/userlogs`}
                        title='История пользователей'
                        imageIcon='/media/icons/avanguard/History.png'
                    />
                ) : null}
                <MenuItem
                    to={`/account/${accountId}/admin/notify`}
                    title='Уведомления'
                    imageIcon='/media/icons/avanguard/bell.png'
                />
                {(ctnx?.isRoot || accountActions?.includes('blacklist_list')) && account.type !== 'subaccount' ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/blacklist`}
                        title='Чёрный список'
                        imageIcon='/media/icons/avanguard/bell.png'
                    />
                ) : null}
                {process.env.NODE_ENV === 'development' ? (
                    <>
                        <MenuItem
                            to={`/account/${accountId}/admin/requestRegister`}
                            title='Анкеты саморегистрации'
                            imageIcon='/media/icons/avanguard/bell.png'
                        />
                    </>
                ) : null}
                {ctnx?.isRoot || accountActions?.includes('subaccount_edit') ? (
                    <MenuItem
                        to={`/account/${accountId}/admin/settings`}
                        title='Настройки'
                        imageIcon='/media/icons/avanguard/Admin.png'
                    />
                ) : null}
            </MenuItemWithSub>
        </>
    );
}
