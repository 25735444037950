import { useEffect, useState } from "react";
import Paginator from "../../../../components/Paginator";
import PermissionPage from "../../../../components/PermissionPage";
import { DateToString, UtcToLocal } from "../../../../helpers/DateHelpers";
import SkeletonTable from "../../../../components/skeleton/SkeletonTable";
import ThSortable from "../../../../components/table/ThSortable";
import FilterAccountSelector from "../../../accesslog/filters/FilterAccountSelector";
import LetOutModal from "./LetOutModal";
import {
    useGetGuestAutoControlQuery,
    useLazyExportGuestAutoControlQuery,
} from "../../../../app/storeReducers/guestsApiSlice";

const NightWatchPage = (props) => {
    const accountId = props.match.params.accountId;

    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(1);

    const [sort, setSort] = useState("");
    const [sortDest, setSortDest] = useState("asc");
    const [vehSort, setVehSort] = useState("");

    const [filterAccount, setFilterAccount] = useState("");

    const [isOpenLetOutModal, setIsOpenLetOutModal] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState({});

    const {
        data: guests,
        isLoading,
        refetch,
    } = useGetGuestAutoControlQuery({
        accountId,
        pageIndex,
        pageSize,
        sort: vehSort,
        subAccountId: filterAccount,
    });

    const [getExportGuests] = useLazyExportGuestAutoControlQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setForcePage(pageIndex - 1);
        }

        loadResourcesAndDataAsync();
    }, [pageIndex, pageSize, vehSort, filterAccount]);

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(1);
        setPageSize(size);
    };

    const columnFilter = (colName) => {
        const dest =
            colName === sort ? (sortDest === "asc" ? "desc" : "asc") : "asc";
        setSort(colName);
        setSortDest(dest);
        setVehSort(colName + (dest === "desc" ? "_desc" : ""));
    };

    const exportData = async () => {
        const response = await getExportGuests({
            accountId,
            pageIndex: 1,
            pageSize: 10000,
            subAccountId: filterAccount,
        });
        const a = document.createElement("a");
        a.href = response.data.url;
        a.download = "Ночной дозор.csv";
        a.click();
        URL.revokeObjectURL(response.data.url);
    };

    const handleDelete = (entity) => {
        setSelectedEntity(entity);
        setIsOpenLetOutModal(true);
    };

    const closeDeleteModal = (isReload) => {
        setIsOpenLetOutModal(false);
        if (isReload) {
            refetch();
        }
    };

    return (
        <>
            <LetOutModal
                entity={selectedEntity}
                isOpen={isOpenLetOutModal}
                closeHandle={closeDeleteModal}
            />
            <PermissionPage accountId={accountId} roles={["guest_car_panel"]}>
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="mb-5">Ночной дозор</h1>
                        <div className="row mb-3">
                            <div className="col text-start">
                                <div style={{ display: "inline-block" }}>
                                    Выберите аккаунт
                                </div>
                                <div
                                    style={{
                                        display: "inline-block",
                                        marginLeft: 5,
                                        width: 250,
                                    }}
                                >
                                    <FilterAccountSelector
                                        callback={(value) =>
                                            setFilterAccount(value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="col text-end">
                                <button
                                    className="btn av-btn-primary ms-2"
                                    onClick={() => exportData()}
                                >
                                    Экспорт
                                </button>
                            </div>
                        </div>
                        <div className="card border">
                            <div className="table-responsive">
                                <SkeletonTable
                                    cols={[
                                        "Аккаунт",
                                        "Посетитель",
                                        "Гос. номер",
                                        "Заезд",
                                        "",
                                    ]}
                                    rowsCount={15}
                                    isLoading={isLoading}
                                    classTable="table-striped"
                                >
                                    <table className="table mb-0">
                                        <thead>
                                            <tr className="fw-bold av-text-gray-500 border-bottom">
                                                <ThSortable
                                                    className="border-bottom ps-6"
                                                    colName="account"
                                                    colTitle="Аккаунт"
                                                    colChanged={(name) =>
                                                        columnFilter(name)
                                                    }
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <ThSortable
                                                    className="border-bottom"
                                                    colName="guest"
                                                    colTitle="Имя"
                                                    colChanged={(name) =>
                                                        columnFilter(name)
                                                    }
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <ThSortable
                                                    className="border-bottom"
                                                    colName="number"
                                                    colTitle="Гос. номер"
                                                    colChanged={(name) =>
                                                        columnFilter(name)
                                                    }
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <ThSortable
                                                    className="border-bottom"
                                                    colName="date"
                                                    colTitle="Заезд"
                                                    colChanged={(name) =>
                                                        columnFilter(name)
                                                    }
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <ThSortable
                                                    className="border-bottom"
                                                    colName="type"
                                                    colTitle="Тип"
                                                    colChanged={(name) =>
                                                        columnFilter(name)
                                                    }
                                                    sortCol={sort}
                                                    sortDestinaton={sortDest}
                                                />
                                                <th className="border-bottom"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {guests?.result?.map((item) => (
                                                <tr
                                                    key={item.entityId}
                                                    className="cursor-pointer"
                                                >
                                                    <td className="ps-6 av-text-gray-500 align-middle">
                                                        {item.account != null
                                                            ? item.account.title
                                                            : ""}
                                                    </td>
                                                    <td className="av-text-gray-500 align-middle">
                                                        {item.entityName}
                                                    </td>
                                                    <td className="fw-bolder align-middle">
                                                        {item.carNumber +
                                                            " " +
                                                            item.carRegion}
                                                    </td>
                                                    <td className="align-middle">
                                                        {DateToString(
                                                            UtcToLocal(
                                                                item.dateIn
                                                            ),
                                                            true
                                                        )}
                                                    </td>
                                                    <td className="align-middle">
                                                        {item.entityType ===
                                                        "guest"
                                                            ? "Посетитель"
                                                            : "Транспорт"}
                                                    </td>
                                                    <td className="align-middle">
                                                        <button
                                                            className="btn av-btn-primary btn-sm"
                                                            onClick={() => {
                                                                handleDelete(
                                                                    item
                                                                );
                                                            }}
                                                        >
                                                            Удалить
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </SkeletonTable>
                            </div>
                            <div className="card-body py-3 px-5">
                                <Paginator
                                    onPageChange={changePage}
                                    onPageSizeChange={changePageSize}
                                    pageCount={guests?.paginator.totalPages}
                                    pageSizeArray={[50, 100, 500]}
                                    pageSize={pageSize}
                                    countCurrent={guests?.result.length}
                                    countTotal={guests?.paginator.totalCount}
                                    forcePage={forcePage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PermissionPage>
        </>
    );
};

export default NightWatchPage;
