import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import VehicleServiceCreateModal from "./VehicleServiceCreateModal";
import VehicleServicesList from "./VehicleServicesList";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetVehicleByIdQuery } from "../../app/storeReducers/AccountVehiclesAPI";
import { useGetServicesQuery } from "../../app/storeReducers/servicesApiSlice";

const VehicleServicesPanel = (props) => {
    const { accountId, vehicleId } = props;

    const { data: vehicle } = useGetVehicleByIdQuery(vehicleId);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const { data: entityList, isLoading } = useGetServicesQuery({
        accountId,
        vehicleId,
    });

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("services_edit"));
        setCanList(context.isRoot || actions?.includes("services_list"));
    }, [context, accountId]);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    return (
        <>
            <VehicleServiceCreateModal
                accountId={accountId}
                entityId={editedEntity}
                vehicleId={vehicleId}
                consumerId={vehicle?.result?.account?.id}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className="card border mb-3">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col-md-6 align-self-center">
                            <h3>
                                Услуги{" "}
                                <span className="badge rounded-pill bg-light text-dark">
                                    {entityList?.result?.length}
                                </span>
                            </h3>
                        </div>
                        <div className="col-md-6 text-end">
                            {canEdit ? (
                                <button
                                    className="btn av-btn-primary"
                                    onClick={() => openCreateModal(0)}
                                >
                                    <i className="fas fa-plus me-2" />
                                    Добавить услугу
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {canList ? (
                    <VehicleServicesList
                        accountId={accountId}
                        services={entityList}
                        isLoading={isLoading}
                        selectEditEntity={openCreateModal}
                    />
                ) : (
                    <i>У вас нет прав на просмотр содержимого</i>
                )}
            </div>
        </>
    );
};

export default VehicleServicesPanel;
