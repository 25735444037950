import { useEffect, useState } from "react";
import FindComponent from "../../../components/FindComponent";
import { useLazyGetGuestsQuery } from "../../../app/storeReducers/guestsApiSlice";

const GuestsListSelected = (props) => {
    const { accountId, callback } = props;

    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const [entity, setEntity] = useState([]);
    const [nameGuest, setNameGuest] = useState("");
    const [selectedEntities, setSelectedEntities] = useState([]);

    const [getGuests] = useLazyGetGuestsQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (nameGuest !== "") {
                const getResult = await getGuests({
                    accountId,
                    search: nameGuest,
                }).unwrap();
                setEntity(
                    getResult.result?.map((item) => {
                        return { key: item.id, value: item.name };
                    })
                );
            } else {
                setEntity([]);
            }
        }

        loadResourcesAndDataAsync();
    }, [accountId, nameGuest]);

    useEffect(() => {
        function loadResourcesAndData() {
            callback(selectedEntities);
        }

        loadResourcesAndData();
    }, [selectedEntities]);

    const changeEntity = (e, item) => {
        if (e.target.checked) {
            if (!selectedEntities.some((x) => x.key === item.key)) {
                setSelectedEntities([...selectedEntities, item]);
            }
        } else {
            console.log("this is false");
            removeChanged(item);
        }
    };

    const removeChanged = (item) => {
        setSelectedEntities(selectedEntities.filter((x) => x.key !== item.key));
    };

    return (
        <div className="dropdown filter-list-block">
            <div
                className="form-select"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
                data-bs-toggle="dropdown"
            >
                {selectedEntities.length === 0 ? (
                    <span className="text-muted">Выбрать</span>
                ) : (
                    selectedEntities.map((item) => (
                        <div
                            className="border av-border-gray-300 rounded d-inline-block px-2 py-0 me-2 mb-2"
                            key={item.key}
                        >
                            {item.value}{" "}
                            <i
                                className="ms-2 fas fa-times cursor-pointer text-muted"
                                onClick={() => removeChanged(item)}
                            />
                        </div>
                    ))
                )}
            </div>
            <ul
                className={"dropdown-menu" + (isOpenMenu ? " show" : "")}
                aria-labelledby="dropdownMenu2"
            >
                <li className="mb-2">
                    <FindComponent
                        callback={(name) => setNameGuest(name)}
                        componentStyle={{ width: "100%" }}
                    />
                </li>
                {entity?.map((item) => (
                    <li key={item.key}>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={item.key}
                                id={"entity_" + item.key}
                                onChange={(e) => changeEntity(e, item)}
                                checked={selectedEntities.some(
                                    (x) => x.key === item.key
                                )}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={"entity_" + item.key}
                            >
                                {item.value}
                            </label>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GuestsListSelected;
