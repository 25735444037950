import { useEffect, useState } from 'react';
import { useAddMonitorMutation } from '../../app/storeReducers/AccountMonitorAPI';

const MonitorCreateModal = (props) => {
    const { accountId, monitorsCount, isOpen, closeHandle } = props;

    const [addMonitor, { isError }] = useAddMonitorMutation();
    const [name, setName] = useState('Новый монитор');

    useEffect(() => {
        setName('Новый монитор №' + monitorsCount);
    }, [monitorsCount]);

    const handleAddMonitor = async () => {
        const entity = {
            name,
            account: { id: accountId },
        };
        await addMonitor(entity).unwrap();
        setName('');
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить новый монитор
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-3 form-group'>
                                <label htmlFor='name'>Название</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={handleAddMonitor}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default MonitorCreateModal;
