import { CRUDReducer } from './CRUDReducer';

export const baseReducer = new CRUDReducer(
    'accountParkingPlaces',
    'parking/places'
);

export const { getListAsync, createAsync, updateAsync, deleteAsync } =
    baseReducer;

export const accountParkingPlacesSlice = baseReducer.reducerSlice;

export const { setPageSize, selectPage } = accountParkingPlacesSlice.actions;

export const selectList = (state) => state.accountParkingPlaces.objectsArray;
export const selectIsLoading = (state) => state.accountParkingPlaces.isLoading;

export default accountParkingPlacesSlice.reducer;
