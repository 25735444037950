const RequestFormPage = (props) => {
    return (
        <div className='row ms-0 me-0'>
            <div
                className='col-md-6 login-left-block d-flex flex-column justify-content-between'
                style={{
                    backgroundImage: 'url(/images/login-background.png)',
                }}
            >
                <div>
                    <img
                        src='/images/logo-inverted.svg'
                        className='h-30px'
                        alt='avanguard logo'
                    />
                    <p className='login-left-block-text'>
                        Облачная система контроля
                        <br />и управления доступом
                    </p>
                </div>
            </div>
            <div className='col-md-6 login-right-block d-flex justify-content-between flex-column'>
                <div></div>
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2'>
                        <form>
                            <div className='pb-5 pt-lg-0'>
                                <h1 className='font-size-h1-lg fw-boldest'>
                                    Рога и копыта
                                </h1>
                                <p className='text-gray-700 pt-1'>
                                    Заполните анкету для доступа к аккаунту
                                </p>
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>
                                    Выберите участок/квартиру
                                </label>
                                <input className='form-select' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Фамилия</label>
                                <input className='form-control' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Имя</label>
                                <input className='form-control' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Отчество</label>
                                <input className='form-control' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Номер телефона</label>
                                <input className='form-control' />
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor='login'>
                                            Номер автомобиля
                                        </label>
                                        <div className='input-group'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='carNumber'
                                                placeholder='А000АА'
                                            />
                                            <input
                                                type='number'
                                                className='form-control'
                                                id='carRegion'
                                                style={{ maxWidth: '80px' }}
                                                placeholder='777'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor='login'>
                                            Марка автомобиля
                                        </label>
                                        <input className='form-control' />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col text-end'>
                                    <button type='button' class='btn btn-link'>
                                        + Добавить ещё автомобиль
                                    </button>
                                </div>
                            </div>
                            <div className='d-grid'>
                                <button
                                    type='button'
                                    className='btn av-btn-primary'
                                >
                                    Отправить
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex justify-content-between'>
                            <div>© Связь-холдинг, 2022</div>
                            <div>
                                <a
                                    href='mailto:help@avanguard.pro'
                                    className='text-gray-700'
                                >
                                    <i className='far fa-envelope pe-2' />
                                    help@avanguard.pro
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestFormPage;
