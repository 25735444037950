import { useState } from 'react';
import LetInModal from './LetInModal';

const GuestNewButtons = (props) => {
    const { accountId, callback } = props;
    const [showButtons, setShowButtons] = useState(false);
    const [isOpenLetInModal, setIsOpenLetInModal] = useState(false);
    const [changedAccount, setChangedAccount] = useState(0);

    const openLetInModal = (accId) => {
        setChangedAccount(accId);
        setIsOpenLetInModal(true);
    };

    const closeLetInModal = (isSaved) => {
        setIsOpenLetInModal(false);
        if (isSaved) {
            callback();
        }
    };

    return (
        <>
            <LetInModal
                isOpen={isOpenLetInModal}
                closeHandle={(isSaved) => closeLetInModal(isSaved)}
                accountId={accountId}
                preAccountId={0}
            />
            <button
                id='btnGroupDrop1'
                type='button'
                className='btn av-btn-primary ms-2'
                onClick={() => {
                    openLetInModal(0);
                }}
            >
                Впустить посетителя
            </button>
        </>
    );
};

export default GuestNewButtons;
