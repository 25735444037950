/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    FC,
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';

export interface PageLink {
    title: string;
    path: string;
    isActive: boolean;
    isSeparator?: boolean;
}

export interface PageDataContextModel {
    pageTitle?: string;
    setPageTitle: (_title: string) => void;
    pageDescription?: string;
    setPageDescription: (_description: string) => void;
    pageBreadcrumbs?: Array<PageLink>;
    setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
    pageReturnLink?: string;
    setPageReturnLink: (_returnLink: string) => void;
    noTitle?: boolean;
    setNoTitle: (_noTitle: boolean) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
    setPageTitle: (_title: string) => {},
    setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {},
    setPageDescription: (_description: string) => {},
    setPageReturnLink: (_returnLink: string) => {},
    setNoTitle: (_noTitle: boolean) => {},
});

const PageDataProvider: React.FC = ({ children }) => {
    const [pageTitle, setPageTitle] = useState<string>('');
    const [pageDescription, setPageDescription] = useState<string>('');
    const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
    const [pageReturnLink, setPageReturnLink] = useState<string>('');
    const [noTitle, setNoTitle] = useState<boolean>(false);
    const value: PageDataContextModel = {
        pageTitle,
        setPageTitle,
        pageDescription,
        setPageDescription,
        pageBreadcrumbs,
        setPageBreadcrumbs,
        pageReturnLink,
        setPageReturnLink,
        noTitle,
        setNoTitle,
    };
    return (
        <PageDataContext.Provider value={value}>
            {children}
        </PageDataContext.Provider>
    );
};

function usePageData() {
    return useContext(PageDataContext);
}

type Props = {
    description?: string;
    breadcrumbs?: Array<PageLink>;
    returnLink?: string;
    noTitle?: boolean;
};

const PageTitle: FC<Props> = ({
    children,
    description,
    breadcrumbs,
    returnLink,
    noTitle,
}) => {
    const {
        setPageTitle,
        setPageDescription,
        setPageBreadcrumbs,
        setPageReturnLink,
        setNoTitle,
    } = usePageData();
    useEffect(() => {
        if (children) {
            setPageTitle(children.toString());
        }
        return () => {
            setPageTitle('');
        };
    }, [children]);

    useEffect(() => {
        if (description) {
            setPageDescription(description);
        }
        return () => {
            setPageDescription('');
        };
    }, [description]);

    useEffect(() => {
        if (breadcrumbs) {
            setPageBreadcrumbs(breadcrumbs);
        }
        return () => {
            setPageBreadcrumbs([]);
        };
    }, [breadcrumbs]);
    useEffect(() => {
        if (returnLink) {
            setPageReturnLink(returnLink);
        }
        return () => {
            setPageReturnLink('');
        };
    }, [returnLink]);
    useEffect(() => {
        if (noTitle) {
            setNoTitle(noTitle);
        }
        return () => {
            setNoTitle(false);
        };
    }, [returnLink]);

    return <></>;
};

const PageDescription: React.FC = ({ children }) => {
    const { setPageDescription } = usePageData();
    useEffect(() => {
        if (children) {
            setPageDescription(children.toString());
        }
        return () => {
            setPageDescription('');
        };
    }, [children]);
    return <></>;
};

export { PageDescription, PageTitle, PageDataProvider, usePageData };
