import React, { useEffect } from 'react';
import { removeNotification } from '../../app/storeReducers/NotifySlice';
import { useDispatch } from 'react-redux';

const NotificationItem = (props) => {
    const { notify } = props;
    const dispatch = useDispatch();

    const closeNotify = () => {
        dispatch(removeNotification(notify));
    };

    useEffect(() => {
        setTimeout(() => closeNotify(notify), 8000);
    });

    return (
        <div
            className={`alert alert-${notify.type} alert-dismissible fade show`}
            role="alert"
        >
            <strong>{notify.title}</strong> {notify.description}
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={closeNotify}
            ></button>
        </div>
    );
};

export default NotificationItem;
