import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../../../../app/storeReducers/NotifySlice';
import FindComponent from '../../../../../../components/FindComponent';
import { Http } from '../../../../../../http';

const AddVehicleModal = (props) => {
    const {
        accountId,
        placeId,
        selectedAccount,
        isOpen,
        closeHandle,
        okHandle,
    } = props;

    const dispatch = useDispatch();

    const [vehicles, setVehicles] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [accountSelected, setAccountSelected] = useState(0);
    const [search, setSearch] = useState('');

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (accountId !== undefined) {
                const getSubaccounts = await Http.get(
                    `api/accounts/${accountId}/subaccounts`
                );
                setAccounts(getSubaccounts.result);
                setAccountSelected(
                    accountId !== selectedAccount ? selectedAccount : '0'
                );
            }
        }

        loadResourcesAndDataAsync();
    }, [accountId, selectedAccount]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            console.log('accSele', accountSelected);
            const getResult = await Http.get(
                'api/vehicles?accountId=' +
                    (parseInt(accountSelected) !== 0
                        ? accountSelected
                        : accountId) +
                    '&search=' +
                    search
            );
            setVehicles(getResult.result);
        }

        loadResourcesAndDataAsync();
    }, [accountSelected, search]);

    const applyChanges = async (vehicle) => {
        let entity = vehicle;
        entity.parkingPlace = { id: placeId };
        const updateResult = await Http.put(
            'api/vehicles/' + vehicle.id,
            true,
            entity
        );
        if (updateResult?.result?.id > 0) {
            okHandle(vehicle);
        } else {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description:
                        'Не удалось отвязать транспортное средство! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Привязать транспорт</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-4'>
                                <div className='col-md-7'>
                                    <div className='mb-3'>
                                        <select
                                            className='form-select'
                                            value={accountSelected}
                                            onChange={(e) =>
                                                setAccountSelected(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value='0'>
                                                --Не выбрано--
                                            </option>
                                            {accounts.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <FindComponent
                                        callback={(name) => setSearch(name)}
                                        componentStyle={{ width: '100%' }}
                                    />
                                </div>
                            </div>
                        </div>
                        <table className='table table-sm mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Аккаунт
                                    </th>
                                    <th className='border-bottom'>Номер</th>
                                    <th className='border-bottom'>Сотрудник</th>
                                    <th className='border-bottom'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {vehicles?.map((item) => (
                                    <tr key={item.id}>
                                        <td className='ps-6 align-middle'>
                                            {item.account.title}
                                        </td>
                                        <td className='align-middle'>
                                            {item.carNumber + item.carRegion}
                                        </td>
                                        <td className='align-middle'>
                                            {item.employee?.title}
                                        </td>
                                        <td className='align-middle'>
                                            <button
                                                className='btn btn-sm av-btn-simple'
                                                onClick={() =>
                                                    applyChanges(item)
                                                }
                                            >
                                                Выбрать
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default AddVehicleModal;
