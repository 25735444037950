import { useEffect, useState } from "react";
import { useCreateLocationMutation } from "../../app/storeReducers/locationsApiSlice";

const LocationCreateModal = (props) => {
    const { accountId, isOpen, closeHandle } = props;

    const [name, setName] = useState("");
    const [address, setAddress] = useState("");

    const [createAsync] = useCreateLocationMutation();

    useEffect(() => {
        setName("");
        setAddress("");
    }, [isOpen]);

    const saveEntity = async () => {
        const entity = {
            name,
            account: { id: accountId },
            address,
        };
        await createAsync(entity);
        setName("");
        setAddress("");
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить новый объект
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 form-group">
                                <label htmlFor="name">Название</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 form-group">
                                <label htmlFor="name">Адрес</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default LocationCreateModal;
