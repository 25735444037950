import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../http';
import { useDispatch, useSelector } from 'react-redux';
import { updateAsync } from '../../../app/storeReducers/AccountSchemesSlice';
import SkeletonInput from '../../../components/skeleton/SkeletonInput';
import SkeletonSelect from '../../../components/skeleton/SkeletonSelect';
import { useHistory } from 'react-router';
import SchemeDeleteModal from '../SchemeDeleteModal';
import SecutiryContext from '../../../features/securityContext';

const SchemeEditPanel = (props) => {
    const { accountId, entityId } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');
    const [type, setType] = useState('user');
    const [isForGuest, setIsForGuest] = useState(false);
    const [schedule, setSchedule] = useState('');
    const [schedulesList, setSchedulesList] = useState([]);
    const [schemeAccountId, setSchemeAccountId] = useState(0);

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);

            const getResult = await Http.get('api/schemes/' + entityId, true);

            setName(getResult.result.name);
            setType(getResult.result.type);
            setIsForGuest(getResult.result.isForGuest);
            setSchedule(getResult.result.schedule?.id);
            setSchemeAccountId(getResult.result.account?.id);
            setIsLoading(false);
            const getSchedules = await Http.get(
                'api/schemes/schedules?accountId=' + accountId,
                true
            );
            let schedules = [];
            schedules.push({
                id: '',
                name: '',
                description: '--Без графика--',
            });
            if (
                parseInt(accountId) !== parseInt(getResult.result.account?.id)
            ) {
                console.log('this is block');
                schedules.push({
                    id: '0',
                    name: getResult.result.schedule?.id,
                    description: getResult.result.schedule?.title,
                });
            } else {
                getSchedules.result.map((item) => {
                    schedules.push({
                        id: item.id,
                        name: item.id,
                        description: item.name,
                    });
                });
            }
            setSchedulesList(schedules);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('employees_edit'));
    }, [context, accountId]);

    const saveEntity = async () => {
        let scheduleNew = null;
        if (schedule !== '' && schedule !== undefined) {
            scheduleNew = { id: schedule };
        }
        const entity = {
            name,
            account: { id: accountId },
            type,
            id: entityId,
            isForGuest,
            schedule: scheduleNew,
        };
        dispatch(updateAsync(entity));
    };

    const deleteEntity = () => {
        history.push(`/account/${accountId}/admin/schemes`);
    };

    return (
        <>
            <SchemeDeleteModal
                entity={{ id: entityId, name }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                deleteHandle={deleteEntity}
            />
            <div className='card border'>
                <div className='card-body pb-3'>
                    <div className='row mb-3'>
                        <div className='col-md-6 align-self-center'>
                            <h3>Схема</h3>
                        </div>
                    </div>
                    <div className='mb-4'>
                        {type !== 'full' ? (
                            <SkeletonInput
                                isLoading={isLoading}
                                id='name'
                                label='Название'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        ) : (
                            <>
                                <label htmlFor='name' className='form-label'>
                                    Название
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    disabled
                                />
                            </>
                        )}
                    </div>
                    <div className='mb-4'>
                        <SkeletonSelect
                            isLoading={isLoading}
                            label='График доступа'
                            value={schedule}
                            onChange={(e) => setSchedule(e.target.value)}
                            itemsArray={schedulesList}
                            disabled={
                                type === 'full' ||
                                !canEdit ||
                                parseInt(accountId) !==
                                    parseInt(schemeAccountId)
                            }
                        />
                    </div>
                    <div className='form-group form-check mb-4'>
                        <input
                            type='checkbox'
                            className='form-check-input'
                            id='isForGuest'
                            checked={isForGuest}
                            onChange={(e) => setIsForGuest(e.target.checked)}
                            disabled={type === 'full' || !canEdit}
                        />
                        <label
                            className='form-check-label'
                            htmlFor='isForGuest'
                        >
                            Разрешена для посетителей
                        </label>
                    </div>
                    {type === 'full' ? (
                        <div className='row'>
                            <div className='col'>
                                <i>
                                    Системная схема. Редактирование запрещено.
                                </i>
                            </div>
                        </div>
                    ) : null}
                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <button
                                className='btn av-btn-danger fw-boldest'
                                onClick={() => setDeleteModalIsOpen(true)}
                                disabled={
                                    type === 'full' ||
                                    !canEdit ||
                                    parseInt(accountId) !==
                                        parseInt(schemeAccountId)
                                }
                            >
                                <i className='far fa-trash-alt me-2' /> Удалить
                            </button>
                        </div>
                        <div className='col-md-6 text-end'>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                                disabled={
                                    type === 'full' ||
                                    !canEdit ||
                                    parseInt(accountId) !==
                                        parseInt(schemeAccountId)
                                }
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SchemeEditPanel;
