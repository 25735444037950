import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
} from '../../../../app/storeReducers/AccountParkingPlacesTSSlice';
import SkeletonTable from '../../../../components/skeleton/SkeletonTable';
import { useHistory } from 'react-router-dom';
import ParkingPlaceCreateModal from './ParkingPlaceCreateModal';
import SecutiryContext from '../../../../features/securityContext';

const ParkingPlacesList = (props) => {
    const { accountId, parkingId } = props;

    const dispatch = useDispatch();
    const history = useHistory();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);

    const { context } = useContext(SecutiryContext);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        dispatch(getListAsync([{ key: 'areaId', value: parkingId }]));
    }, [parkingId, dispatch]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('parking_place_edit'));
    }, [context, accountId]);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const openEntityCard = (entityId) => {
        history.push(
            `/account/${accountId}/parking/${parkingId}/places/${entityId}`
        );
    };

    console.log('entityList', entityList);

    return (
        <>
            <ParkingPlaceCreateModal
                parkingId={parkingId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className='row'>
                <div className='col mb-3'>
                    <button
                        className='btn av-btn-primary'
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className='fas fa-plus me-3' />
                        Добавить парковочное место
                    </button>
                </div>
            </div>
            <div className='card border'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Парковка', 'Номер', 'Владелец', 'Транспорт']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Парковка
                                    </th>
                                    <th className='border-bottom'>Номер</th>
                                    <th className='border-bottom'>Владелец</th>
                                    <th className='border-bottom'>Транспорт</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={() => openEntityCard(item.id)}
                                        className='cursor-pointer'
                                    >
                                        <td className='ps-6'>
                                            {item.area.title}
                                        </td>
                                        <td>{item.number}</td>
                                        <td>{item.service?.consumer?.title}</td>
                                        <td>
                                            {item.vehicle?.carNumber}
                                            {item.vehicle?.carRegion} -{' '}
                                            {item.vehicle?.model}
                                            <br />
                                            {item.vehicle?.account?.title}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default ParkingPlacesList;
