import SupportTasksList from './SupportTasksList';

const SupportPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <div className='row'>
            <div className='col-md-12'>
                <h1 className='mb-5'>Заявки в техподдержку</h1>
                <SupportTasksList accountId={accountId} />
            </div>
        </div>
    );
};

export default SupportPage;
