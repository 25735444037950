import PermissionPage from '../../../components/PermissionPage';
import Paginator from '../../../components/Paginator';
import RequestEditModal from './RequestEditModal';
import { useState } from 'react';

const forms = [
    {
        account: 'Рога и копыта',
        fio: 'Иванов Иван Иванович',
        autos: 'E002EE 50',
        phone: '+7 916 123 12 12',
    },
    {
        account: 'Рога и копыта',
        fio: 'Иванов Иван Иванович',
        autos: 'A001AA 777, E002EE 50, B003BB 77',
        phone: '+7 916 123 12 12',
    },
    {
        account: 'Рога и копыта',
        fio: 'Иванов Иван Иванович',
        autos: 'A001AA 777, E002EE 50',
        phone: '+7 916 123 12 12',
    },
    {
        account: 'Рога и копыта',
        fio: 'Иванов Иван Иванович',
        autos: 'A001AA 777, E002EE 50, B003BB 77',
        phone: '+7 916 123 12 12',
    },
    {
        account: 'Рога и копыта',
        fio: 'Иванов Иван Иванович',
        autos: 'A001AA 777, E002EE 50, B003BB 77',
        phone: '+7 916 123 12 12',
    },
    {
        account: 'Рога и копыта',
        fio: 'Иванов Иван Иванович',
        autos: 'A001AA 777, E002EE 50, B003BB 77',
        phone: '+7 916 123 12 12',
    },
    {
        account: 'Рога и копыта',
        fio: 'Иванов Иван Иванович',
        autos: 'A001AA 777, E002EE 50, B003BB 77',
        phone: '+7 916 123 12 12',
    },
    {
        account: 'Рога и копыта',
        fio: 'Иванов Иван Иванович',
        autos: 'A001AA 777, E002EE 50, B003BB 77',
        phone: '+7 916 123 12 12',
    },
];

const RequestRegisterPage = (props) => {
    const accountId = props.match.params.accountId;

    const [editModalIsOpen, setEditModalIsOpen] = useState(false);

    return (
        <PermissionPage accountId={accountId} roles={['parking_area_list']}>
            <RequestEditModal
                isOpen={editModalIsOpen}
                closeHandle={() => setEditModalIsOpen(false)}
            />
            <div className='row'>
                <div className='col'>
                    <h1 className='mb-5'>Анкеты саморегистрации</h1>
                </div>
            </div>
            <div className='row'>
                <table className='table mb-0'>
                    <thead>
                        <tr className='fw-bold av-text-gray-500 border-bottom'>
                            <th className='border-bottom ps-6'>Субаккаунт</th>
                            <th className='border-bottom'>ФИО</th>
                            <th className='border-bottom'>Телефон</th>
                            <th className='border-bottom'>Автомобили</th>
                            <th className='border-bottom'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {forms.map((item) => (
                            <tr
                                className='cursor-pointer'
                                onClick={() => setEditModalIsOpen(true)}
                            >
                                <td className='ps-6'>{item.account}</td>
                                <td>{item.fio}</td>
                                <td>{item.phone}</td>
                                <td>{item.autos}</td>
                                <td className='text-end'>
                                    <button className='btn av-btn-primary me-2'>
                                        Разрешить
                                    </button>
                                    <button className='btn av-btn-simple'>
                                        Отклонить
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='card-body py-3 px-5'>
                <Paginator
                    onPageChange={null}
                    onPageSizeChange={{}}
                    pageCount={10}
                    pageSizeArray={[50, 100, 500]}
                    pageSize={100}
                    countCurrent={10}
                    countTotal={500}
                    forcePage={null}
                />
            </div>
        </PermissionPage>
    );
};

export default RequestRegisterPage;
