import { baseApi } from "../baseApi";

export const ticketsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getTickets: builder.query({
            query: (data) =>
                `tickets?accountId=${data.accountId ?? ""}&onlyActive=${
                    data.onlyActive ?? true
                }&search=${data.search ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Tickets",
                              id,
                          })),
                          { type: "Tickets", id: "LIST" },
                      ]
                    : [{ type: "Tickets", id: "LIST" }],
        }),
        getTicketById: builder.query({
            query: (id) => `tickets/${id}`,
            providesTags: (result) => [
                { type: "Ticket", id: result?.result.id },
            ],
        }),
        createTicket: builder.mutation({
            query: (body) => ({
                url: "tickets",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Tickets", id: "LIST" }],
        }),
        updateTicket: builder.mutation({
            query: (body) => ({
                url: "tickets",
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Tickets", id: "LIST" },
                { type: "Ticket", id: result?.result.id },
            ],
        }),
        deleteTicket: builder.mutation({
            query: (id) => ({
                url: `tickets/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result) => [
                { type: "Tickets", id: "LIST" },
                { type: "Ticket", id: result?.result.id },
            ],
        }),
        closeTicket: builder.mutation({
            query: (id) => ({
                url: `tickets/${id}/done`,
                method: "PUT",
            }),
            invalidatesTags: (result) => [
                { type: "Tickets", id: "LIST" },
                { type: "Ticket", id: result?.result.id },
            ],
        }),
        getTicketMessages: builder.query({
            query: (id) => `tickets/${id}/messages`,
        }),
        createTicketMessage: builder.mutation({
            query: (data) => ({
                url: `tickets/${data.ticketId}/messages`,
                method: "POST",
                body: { ...data.body },
            }),
        }),
        attachTicketFile: builder.mutation({
            query: ({ ticketId, body }) => ({
                url: `tickets/${ticketId}/messages/attachment`,
                method: "POST",
                body,
            }),
        }),
        getTicketFile: builder.query({
            query: (id) => ({
                url: `tickets/messages/${id}/attachment`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
    }),
});

export const {
    useGetTicketsQuery,
    useGetTicketByIdQuery,
    useCreateTicketMutation,
    useUpdateTicketMutation,
    useDeleteTicketMutation,
    useCloseTicketMutation,
    useGetTicketMessagesQuery,
    useCreateTicketMessageMutation,
    useAttachTicketFileMutation,
    useGetTicketFileQuery,
    useLazyGetTicketFileQuery,
} = ticketsApiSlice;
