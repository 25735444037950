import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeviceCreateModal from "./DeviceCreateModal";
import DeviceDeleteModal from "./DeviceDeleteModal";
import DeviceReplaceModal from "./DeviceReplaceModal";
import DeviceIcon from "./DeviceIcon";
import AvanguardProperties from "./AvanguardProperties";
import UniubiProperties from "./UniubiProperties";
import UniubiDeviceCreateModal from "./UniubiDeviceCreateModal";
import TimeTrackingProperties from "./TimeTrackingProperties";
import TimeTrackingDeviceCreateModal from "./TimeTrackingDeviceCreateModal";
import MqttProperties from "./MqttProperties";
import MqttDeviceCreateModal from "./MqttDeviceCreateModal";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import {
    useGetDeviceByIdQuery,
    useOpenDeviceMutation,
} from "../../app/storeReducers/devicesApiSlice";
import { useLazyGetLocationsQuery } from "../../app/storeReducers/locationsApiSlice";

const DevicePanel = (props) => {
    const { accountId, deviceId } = props;

    const { data: device } = useGetDeviceByIdQuery(deviceId, {
        skip: !deviceId,
    });
    const [getLocations] = useLazyGetLocationsQuery();
    const [openDevice] = useOpenDeviceMutation();

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canReplace, setCanReplace] = useState(false);
    const [canOpen, setCanOpen] = useState(false);

    const [replaceModalIsOpen, setReplaceModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const actions = context?.permissions?.find(
                (x) => x.id === parseInt(accountId)
            )?.actions;
            setCanEdit(context.isRoot || actions?.includes("devices_edit"));
            setCanReplace(
                context.isRoot && device?.result?.provider !== "mqtt"
            );
            setCanOpen(
                context.isRoot || actions?.includes("device_web_access")
            );

            if (accountId > 0 && context !== null && context !== undefined) {
                const locationResponse = await getLocations({
                    accountId,
                }).unwrap();
                setLocations(locationResponse.result);
            }
        }
        loadResourcesAndDataAsync();
    }, [context, accountId, device]);

    const openHandle = async (direction) => {
        await openDevice({ id: deviceId, direction });
    };

    const Properties = () => {
        if (
            device?.result?.provider === "atlas" ||
            device?.result?.provider === "lite"
        ) {
            return <AvanguardProperties device={device?.result} />;
        } else if (device?.result?.provider === "uniubi") {
            return <UniubiProperties device={device?.result} />;
        } else if (device?.result?.provider === "timetracking") {
            return <TimeTrackingProperties device={device?.result} />;
        } else if (device?.result?.provider === "mqtt") {
            return <MqttProperties device={device?.result} />;
        }
        return <></>;
    };

    const EditModal = () => {
        if (
            device?.result?.provider === "atlas" ||
            device?.result?.provider === "lite"
        ) {
            return (
                <DeviceCreateModal
                    accountId={accountId}
                    entityId={device?.result?.id}
                    isOpen={updateModalIsOpen}
                    closeHandle={() => setUpdateModalIsOpen(false)}
                    isLite={device?.result?.provider === "lite"}
                    locations={locations}
                />
            );
        } else if (device?.result?.provider === "uniubi") {
            return (
                <UniubiDeviceCreateModal
                    accountId={accountId}
                    entityId={device?.result?.id}
                    isOpen={updateModalIsOpen}
                    closeHandle={() => setUpdateModalIsOpen(false)}
                    locations={locations}
                />
            );
        } else if (device?.result?.provider === "timetracking") {
            return (
                <TimeTrackingDeviceCreateModal
                    accountId={accountId}
                    entityId={device?.result?.id}
                    isOpen={updateModalIsOpen}
                    closeHandle={() => setUpdateModalIsOpen(false)}
                    locations={locations}
                />
            );
        } else if (device?.result?.provider === "mqtt") {
            return (
                <MqttDeviceCreateModal
                    accountId={accountId}
                    entityId={device?.result?.id}
                    isOpen={updateModalIsOpen}
                    closeHandle={() => setUpdateModalIsOpen(false)}
                    locations={locations}
                />
            );
        }
        return <></>;
    };

    const GetButtonName = (direction) => {
        if (device?.result?.type === "autogate2") {
            return direction === "in" ? "Въезд" : "Выезд";
        } else if (device?.result?.type === "turnstile") {
            return direction === "in" ? "Вход" : "Выход";
        } else {
            return "Открыть";
        }
    };

    const GetOpenButtons = () => {
        if (device?.result?.provider === "timetracking") {
            return "";
        }
        if (
            device?.result?.type === "autogate2" ||
            device?.result?.type === "turnstile"
        ) {
            return (
                <>
                    <button
                        className="btn av-btn-primary btn-sm me-2"
                        onClick={() => openHandle("in")}
                        disabled={!canOpen}
                    >
                        {GetButtonName("in")}
                    </button>
                    <button
                        className="btn av-btn-primary btn-sm me-2"
                        onClick={() => openHandle("out")}
                        disabled={!canOpen}
                    >
                        {GetButtonName("out")}
                    </button>
                </>
            );
        }

        return (
            <button
                className="btn av-btn-primary btn-sm me-2"
                onClick={() => openHandle("in")}
                disabled={!canOpen}
            >
                {GetButtonName("in")}
            </button>
        );
    };

    return (
        <>
            <EditModal />
            <DeviceDeleteModal
                entity={device?.result}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <DeviceReplaceModal
                device={device?.result}
                isOpen={replaceModalIsOpen}
                closeHandle={() => setReplaceModalIsOpen(false)}
            />
            <div className="card border mb-3">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col d-flex">
                            <div className="card-type border-0">
                                <div className="card-icon-block me-3 rounded-circle">
                                    <DeviceIcon device={device?.result} />
                                </div>
                            </div>
                            <h3 className="align-self-center">
                                {device?.result?.name}
                            </h3>
                        </div>
                    </div>

                    <Properties device={device?.result} />

                    <div className="row mb-3">
                        <div className="col-md-5">
                            {canEdit ? (
                                <button
                                    className="btn av-btn-danger fw-boldest"
                                    onClick={() => setDeleteModalIsOpen(true)}
                                >
                                    <i className="far fa-trash-alt me-2" />{" "}
                                    Удалить
                                </button>
                            ) : null}
                            {canReplace ? (
                                <>
                                    <button
                                        className="btn av-btn-simple fw-boldest mt-2"
                                        onClick={() =>
                                            setReplaceModalIsOpen(true)
                                        }
                                    >
                                        <i className="fas fa-exchange-alt"></i>{" "}
                                        Замена
                                    </button>
                                </>
                            ) : null}
                        </div>
                        <div className="col text-end">
                            {GetOpenButtons()}

                            {canEdit ? (
                                <button
                                    className="btn av-btn-simple fw-boldest"
                                    onClick={() => setUpdateModalIsOpen(true)}
                                >
                                    <img
                                        src="/media/icons/base/edit-3.svg"
                                        className="align-top"
                                    />{" "}
                                    Изменить
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DevicePanel;
