import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../app/storeReducers/NotifySlice';
import WaitLoading from '../../components/WaitLoading';
import { Http } from '../../http';

const EmployeeImportModal = (props) => {
    const { accountId, isOpen, closeHandle, okHandle } = props;

    const dispatch = useDispatch();

    const ref = useRef();

    const [changedFile, setChangedFile] = useState(null);
    const [uploadedEmployees, setUploadedEmployees] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [canUpload, setIsCanUpload] = useState(false);
    const [uploadingEmp, setUploadingEmp] = useState(false);

    const handleSetFile = (event) => {
        const { files } = event.target;
        var data = new FormData();
        for (const file of files) {
            data.append('file', file, file.name);
        }
        setChangedFile(data);
    };

    const uploadFile = async () => {
        setIsLoading(true);
        const result = await Http.postMedia(
            `api/employees/import/xlsx?accountId=${accountId}`,
            changedFile
        );
        setUploadedEmployees(result.result);
        setIsCanUpload(true);
        setIsLoading(false);
    };

    const uploadEmp = async () => {
        setUploadingEmp(true);
        const result = await Http.post(
            'api/employees/import?accountId=' + accountId,
            true,
            uploadedEmployees
        );
        setUploadingEmp(false);
        if (result.result === true) {
            clearModal();
            okHandle();
        } else {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка импорта!',
                    description: 'Перезагрузите страницу и попробуйте снова.',
                    type: 'danger',
                })
            );
        }
    };

    const closeModal = () => {
        clearModal();
        closeHandle();
    };

    const clearModal = () => {
        setChangedFile(null);
        setUploadedEmployees([]);
        setIsLoading(false);
        setIsCanUpload(false);
        setUploadingEmp(false);
        ref.current.value = '';
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Импорт из Excel</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeModal}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-4'>Для загрузки списка сотрудников используйте следующий <a href="/files/employee_imports.xlsx">файл шаблона</a>.</div>
                            <div className='input-group'>
                                <input
                                    type='file'
                                    name='files'
                                    className='form-control'
                                    id='files'
                                    onChange={(e) => handleSetFile(e)}
                                    ref={ref}
                                />
                                <button
                                    className='btn btn-secondary'
                                    type='button'
                                    id='files'
                                    onClick={uploadFile}
                                    disabled={changedFile === null}
                                >
                                    Загрузить
                                </button>
                            </div>
                            <hr />
                            <WaitLoading isLoading={isLoading}>
                                {uploadedEmployees.length > 0 ? (
                                    <table className='table table-sm table-striped'>
                                        <thead>
                                            <tr className='fw-bold'>
                                                <th>Фамилия</th>
                                                <th>Имя</th>
                                                <th>Отчество</th>
                                                <th>Должность</th>
                                                <th>Табельный номер</th>
                                                <th>Карта</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {uploadedEmployees.map(
                                                (item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>
                                                                {item.lastName}
                                                            </td>
                                                            <td>
                                                                {item.firstName}
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.secondName
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.occupation
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    item.persNumber
                                                                }
                                                            </td>
                                                            <td>{item.card}{item.card2 ? ', ' +item.card2 : ''}{item.card3 ? ', ' +item.card3 : ''}</td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                ) : (
                                    <i>Нет данных для загрузки</i>
                                )}
                            </WaitLoading>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                onClick={closeModal}
                            >
                                Закрыть
                            </button>
                            {!uploadingEmp ? (
                                <button
                                    type='button'
                                    className='btn btn-success'
                                    onClick={uploadEmp}
                                    disabled={
                                        !canUpload ||
                                        uploadedEmployees.length === 0
                                    }
                                >
                                    Импортировать
                                </button>
                            ) : (
                                <button
                                    type='button'
                                    className='btn btn-success'
                                    disabled={true}
                                >
                                    <div
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                    >
                                        <span className='visually-hidden'>
                                            Loading...
                                        </span>
                                    </div>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default EmployeeImportModal;
