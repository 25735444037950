import { useEffect, useState } from "react";
import { useSetDeviceQrCodeMutation } from "../../app/storeReducers/devicesApiSlice";

const DeviceQrCodeEditModal = (props) => {
    const { isOpen, closeHandle, device } = props;

    const [qrCode, setQrCode] = useState("");

    const [setDeviceQrCode] = useSetDeviceQrCodeMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (Object.keys(device ?? {}).length !== 0) {
                setQrCode(
                    device?.qrCode !== null ? device?.qrCode : device?.uid
                );
            }
        }

        loadResourcesAndDataAsync();
    }, [device]);

    const saveEntity = async () => {
        await setDeviceQrCode({ id: device.id, qrCode: { value: qrCode } });
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Изменить QR-код устройства
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body pb-2">
                            <div className="row mb-3">
                                <div className="col mb-2">
                                    <div className="form-group">
                                        <label htmlFor="qrCode">
                                            Значение QR-кода
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="qrCode"
                                            value={qrCode}
                                            onChange={(e) =>
                                                setQrCode(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer py-2">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Отменить
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить изменения
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default DeviceQrCodeEditModal;
