import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: [],
};

const notifySlice = createSlice({
    name: 'notify',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addNotification: (state, action) => {
            state.notifications.push(action.payload);
        },
        removeNotification: (state, action) => {
            const filteredNotify = state.notifications.filter((x) => x.dateNotify !== action.payload.dateNotify);
            state.notifications = filteredNotify;
        },
    },
});

export const { addNotification, removeNotification } = notifySlice.actions;

export const selectNotifications = (state) => state.notify.notifications;

export default notifySlice.reducer;
