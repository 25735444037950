import { useEffect, useState } from 'react';
import { Http } from '../../../../http';
import SkeletonSelect from '../../../../components/skeleton/SkeletonSelect';

const EmployeeAddRoleModal = (props) => {
    const { accountId, employeeId, isOpen, okHandle, closeHandle } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [roleSelected, setRoleSelected] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            setRoleSelected({});
            const roleResult = await Http.get(
                `api/roles?sort=name&accountId=${accountId}`
            );
            roleResult?.result?.map((item) => {
                item.description = item.name;
                item.name = item.id;
            });
            setRoles(roleResult.result || []);
            if (roleResult?.result?.length > 0) {
                setRoleSelected(roleResult.result[0].id);
            }
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [employeeId]);

    const saveMemberRole = async () => {
        const addResult = await Http.put(
            `api/employees/${employeeId}/roles/${roleSelected}`
        );

        okHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить роль сотруднику
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-4'>
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label='Роль'
                                    value={roleSelected}
                                    onChange={(e) =>
                                        setRoleSelected(e.target.value)
                                    }
                                    itemsArray={roles}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveMemberRole}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default EmployeeAddRoleModal;
