import { useEffect, useState } from "react";
import WaitLoading from "../../../components/WaitLoading";
import {
    useLazyGetCameraImageQuery,
    useLazyGetCameraTranslationsQuery,
} from "../../../app/storeReducers/camerasApiSlice";

const FrameModal = (props) => {
    const { cameraId, name, isOpen, closeHandle, isRtspProxy } = props;

    const [image, setImage] = useState("");
    const [frameUrl, setFrameUrl] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [getCameraTranslations] = useLazyGetCameraTranslationsQuery();
    const [getCameraImage] = useLazyGetCameraImageQuery();

    useEffect(async () => {
        setIsLoading(true);
        if (cameraId > 0) {
            if (isRtspProxy) {
                const getResult = await getCameraTranslations(
                    cameraId
                ).unwrap();
                setFrameUrl(getResult?.result?.webrtc);
            } else {
                const getResult = await getCameraImage(cameraId).unwrap();
                setImage(getResult?.url);
            }
        }
        setIsLoading(false);
    }, [cameraId]);

    const closeModal = () => {
        setFrameUrl("");
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Просмотр изображения - {name}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeModal}
                            ></button>
                        </div>
                        <div
                            className="modal-body"
                            style={{ maxHeight: 600, overflowY: "scroll" }}
                        >
                            <WaitLoading isLoading={isLoading}>
                                {isRtspProxy && frameUrl ? (
                                    <div style={{ textAlign: "center" }}>
                                        <iframe
                                            src={frameUrl}
                                            style={{ width: 600, height: 400 }}
                                        ></iframe>
                                    </div>
                                ) : (
                                    <img
                                        src={
                                            image !== null &&
                                            image !== undefined &&
                                            image !== ""
                                                ? image
                                                : null
                                        }
                                        className="img-fluid"
                                        style={{ maxHeight: 600 }}
                                    />
                                )}
                            </WaitLoading>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeModal}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default FrameModal;
