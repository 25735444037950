import PermissionPage from '../../components/PermissionPage';
import ServicesList from './ServicesList';

const ServicesPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['services_list']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Услуги</h1>
                    <ServicesList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default ServicesPage;
