import PermissionPage from "../../../../components/PermissionPage";
import ParkingPlacesImagesCard from "./imagesCard/ParkingPlaceImagesCard";
import ParkingPlacesList from "./ParkingPlacesList";

const ParkingPlacesPage = (props) => {
    const accountId = props.match.params.accountId;
    const parkingId = props.match.params.parkingId;

    return (
        <PermissionPage accountId={accountId} roles={["parking_place_list"]}>
            <h1 className="mb-5">Парковочные места</h1>
            <div className="row mt-4">
                <div className="col-md-8">
                    <ParkingPlacesList
                        accountId={accountId}
                        parkingId={parkingId}
                    />
                </div>
                <div className="col-md-4">
                    <ParkingPlacesImagesCard areaId={parkingId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default ParkingPlacesPage;
