import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../app/storeReducers/CurrentUserSlice';
import { useDeleteSecurityContextMutation } from '../app/storeReducers/authApiSlice';

const LogoutPage = () => {
    const dispatch = useDispatch();

    const [deleteContext] = useDeleteSecurityContextMutation();
    const user = useSelector((state) => state.currentUser.user);

    useEffect(async () => {
        async function loadData() {
            if (user) {
                await deleteContext();
                dispatch(logoutUser());
            } else {
                window.location.href = '/';
            }
        }
        loadData();
    }, [user]);

    return null;
};

export default LogoutPage;
