import { useState } from "react";
import CardListTableComponent from "../../components/cards/CardsListTableComponent";
import VehicleServicesPanel from "./VehicleServicesPanel";
import PermissionPage from "../../components/PermissionPage";
import AccessLogComponent from "../../components/accesslogs/AccessLogComponent";
import VehiclePanel from "./VehiclePanel";
import { ACTIONS, STATUS } from "react-joyride";
import Onboarding from "../../components/onboarding/Onboarding";

const VehicleEditPage = (props) => {
    const accountId = props.match.params.accountId;
    const vehicleId = props.match.params.vehicleId;

    const [steps, setSteps] = useState([
        {
            target: "#vehiclePanel",
            disableBeacon: true,
            placement: "right",
            content: (
                <div className="text-start">
                    <div className="text-muted mb-2 step-panel">
                        Шаг 12 из 13
                    </div>
                    <div className="mb-1 ob-title">
                        <b>Карточка транспорта</b>
                    </div>
                    <div className="mb-3 ob-text">
                        Аналогично карточке сотрудника, в карточке транспорта вы
                        можете просматривать и редактировать информацию о
                        транспортном средстве.
                    </div>
                </div>
            ),
        },
        {
            target: "#vehicleServices",
            disableBeacon: true,
            placement: "left",
            content: (
                <div className="text-start">
                    <div className="text-muted mb-2 step-panel">
                        Шаг 13 из 13
                    </div>
                    <div className="mb-1 ob-title">
                        <b>Карточка транспорта</b>
                    </div>
                    <div className="mb-3 ob-text">
                        В блоке «Услуги» вы можете закрепить за автомобилем
                        доступ на парковки, а также создать карту доступа
                        транспортного средства.
                    </div>
                </div>
            ),
        },
    ]);

    const onboardingCallback = (action, status, index) => {
        if (
            action === ACTIONS.CLOSE &&
            status === STATUS.FINISHED &&
            index === 1
        ) {
            //
        }
    };

    return (
        <PermissionPage accountId={accountId} roles={["vehicles_list"]}>
            <Onboarding
                steps={steps}
                run={true}
                callback={onboardingCallback}
                last
            />
            <h1 className="mb-5">Карточка транспорта</h1>
            <div className="row">
                <div className="col-md-6">
                    <VehiclePanel accountId={accountId} vehicleId={vehicleId} />
                </div>
                <div className="col-md-6" id="vehicleServices">
                    <VehicleServicesPanel
                        accountId={accountId}
                        vehicleId={vehicleId}
                    />
                    <CardListTableComponent
                        userId=""
                        employeeId=""
                        vehicleId={vehicleId}
                        accountId={accountId}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <AccessLogComponent
                        accountId={accountId}
                        vehicleId={vehicleId}
                    />
                </div>
                <div className="col-md-6"></div>
            </div>
        </PermissionPage>
    );
};

export default VehicleEditPage;
