import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const employeesApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getEmployees: builder.query({
            query: (data) =>
                `employees?accountId=${data.accountId ?? ""}&sort=${
                    data.sort ?? ""
                }&search=${data.search ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}` +
                `&withChilds=${data.withChilds ?? false}&roleId=${
                    data.roleId ?? ""
                }&groupId=${data.groupId ?? ""}&showDeleted=${
                    data.showDeleted ?? false
                }&thumbnail=${data.thumbnail ?? false}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Employees",
                              id,
                          })),
                          { type: "Employees", id: "List" },
                      ]
                    : [{ type: "Employees", id: "List" }],
        }),
        getEmployeeById: builder.query({
            query: (data) =>
                `employees/${data?.id}?thumbnail=${data?.thumbnail ?? false}`,
            providesTags: (result) => [
                { type: "Employee", id: result?.result?.id },
            ],
        }),
        createEmployee: builder.mutation({
            query: (body) => ({
                url: "employees",
                method: "POST",
                body: { ...body },
            }),
            invalidatesTags: [{ type: "Employees", id: "List" }],
        }),
        updateEmployee: builder.mutation({
            query: (body) => ({
                url: "employees/" + body.id,
                method: "PUT",
                body: { ...body },
            }),
            invalidatesTags: (result) => [
                { type: "Employees", id: "List" },
                { type: "Employee", id: result?.result.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить сотрудника! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Сотрудник сохранен!",
                                description:
                                    "Данные сотрудника успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить сотрудника! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteEmployee: builder.mutation({
            query: (id) => ({
                url: "employees/" + id,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Employees", id: "List" }],
        }),
        getEmployeePhoto: builder.query({
            query: (id) => `employees/${id}/photo`,
        }),
        uploadEmployeePhoto: builder.mutation({
            query: (body) => ({
                url: "employees/" + body.employeeId + "/photo",
                method: "POST",
                body: { ...body.photo },
            }),
        }),
        deleteEmployeePhoto: builder.mutation({
            query: (id) => ({
                url: "employees/" + id + "/photo",
                method: "DELETE",
            }),
        }),
        exportEmployees: builder.query({
            query: (accountId) => ({
                url: `employees/export?accountId=${accountId}`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
        addEmployeeRole: builder.mutation({
            query: (body) => ({
                url: "employees/" + body.employeeId + "/roles/" + body.roleId,
                method: "PUT",
            }),
        }),
        removeEmployeeRole: builder.mutation({
            query: (body) => ({
                url: "employees/" + body.employeeId + "/roles/" + body.roleId,
                method: "DELETE",
            }),
        }),
        getEmployeeSchemes: builder.query({
            query: (id) => `employees/${id}/schemes`,
        }),
        importEmployees: builder.mutation({
            query: (data) => ({
                url: "employees/import?accountId=" + data.accountId,
                method: "POST",
                body: data.body,
            }),
        }),
        importEmployeesXlsx: builder.mutation({
            query: (data) => ({
                url: "employees/import/xlsx?accountId=" + data.accountId,
                method: "POST",
                body: data.body,
            }),
        }),
    }),
});

export const {
    useGetEmployeesQuery,
    useLazyGetEmployeesQuery,
    useGetEmployeeByIdQuery,
    useLazyGetEmployeeByIdQuery,
    useCreateEmployeeMutation,
    useUpdateEmployeeMutation,
    useDeleteEmployeeMutation,
    useGetEmployeePhotoQuery,
    useLazyGetEmployeePhotoQuery,
    useUploadEmployeePhotoMutation,
    useDeleteEmployeePhotoMutation,
    useExportEmployeesQuery,
    useLazyExportEmployeesQuery,
    useAddEmployeeRoleMutation,
    useRemoveEmployeeRoleMutation,
    useGetEmployeeSchemesQuery,
    useImportEmployeesMutation,
    useImportEmployeesXlsxMutation,
} = employeesApiSlice;
