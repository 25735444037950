import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../app/storeReducers/NotifySlice";
import SkeletonTableFull from "../../../components/skeleton/SkeletonTableFull";
import {
    DateToString,
    ToUtcString,
    UtcToLocal,
} from "../../../helpers/DateHelpers";
import EmployeesDropDownInput from "../EmployeesDropDownInput";
import Paginator from "../../../components/Paginator";
import { useGetLocationsQuery } from "../../../app/storeReducers/locationsApiSlice";
import { useGetGroupsQuery } from "../../../app/storeReducers/groupsApiSlice";
import {
    useLazyExportLogCheckInQuery,
    useLazyGetLogCheckInQuery,
} from "../../../app/storeReducers/logsApiSlice";

const CheckInList = (props) => {
    const { accountId } = props;

    const dispatch = useDispatch();

    const [dateStart, setDateStart] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [report, setReport] = useState([]);

    const [getLogCheckIn] = useLazyGetLogCheckInQuery();
    const [exportLogCheckIn] =
        useLazyExportLogCheckInQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(false);
            const dtStartString = `${dateStart.getFullYear()}-${
                dateStart.getMonth() + 1
            }-${dateStart.getDate()}`;
            try {
                const getResult = await getLogCheckIn({
                    accountId,
                    date: dtStartString,
                }).unwrap();
                const tableResult = getResult?.result?.map((item, index) => ({
                    ...item,
                    keyItem: index,
                    dateInString: item.dateIn
                        ? DateToString(UtcToLocal(item.dateIn), true)
                        : null,
                }));
                setReport(tableResult);
            } catch (error) {
                console.log("error", error);
                dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: "Ошибка!",
                        description:
                            "Перезагрузите страницу и попробуйте снова! " +
                            error.message,
                        type: "danger",
                    })
                );
            }

            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [
        dateStart,
    ]);

    const exportData = async () => {
        const dtStartString = `${dateStart.getFullYear()}-${
            dateStart.getMonth() + 1
        }-${dateStart.getDate()}`;
        const response = await exportLogCheckIn({
            accountId,
            date: dtStartString,
            export: true,
        }).unwrap();
        const a = document.createElement("a");
        a.href = response.url;
        a.download = "Отчет - Кто пришел.xlsx";
        a.click();
        URL.revokeObjectURL(response.url);
    };

    return (
        <>
            <div className="card border">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column"></h3>
                    <div className="card-toolbar">
                        <div
                            className="input-group me-2 mb-3"
                            style={{ width: "400px" }}
                        >
                            <span className="input-group-text">Дата</span>
                            <input
                                type="date"
                                className="form-control"
                                value={ToUtcString(dateStart).split("T")[0]}
                                onChange={(e) =>
                                    setDateStart(new Date(e.target.value))
                                }
                            />
                        </div>
                        <button
                            className="btn av-btn-simple ms-3 mb-3"
                            onClick={exportData}
                        >
                            <i className="far fa-file-excel me-3"></i> Экспорт в
                            Excel
                        </button>
                    </div>
                </div>
                <div className="table-responsive">
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: "keyItem",
                            name: "Сотрудник",
                            occupation: "Должность",
                            personalNumber: "Таб. номер",
                            dateInString: "Приход",
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="mb-0"
                        entity={report}
                        tdClasses={{
                            locationName: "ps-6",
                            occupation: "report-occupation",
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default CheckInList;
