import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';

const initialState = {
    isLoading: true,
    items: [],
    paginator: [],
    errorDescription: '',
    pageSize: 1000
};

export const getMyLogsAsync = createAsyncThunk(
    'logs/getMyLog',
    async () => {
        const data = await Http.get('api/events/?pageSize='+initialState.pageSize, true);
        return data;
    }
);

export const logSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getMyLogsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getMyLogsAsync.fulfilled]: (state, action) => {
            state.isLoading = false;
            state.items = action.payload.result;
            state.paginator = action.payload.paginator;
        },
        [getMyLogsAsync.rejected]: (state, action) => {
            console.log('action', action);
            state.isLoading = false;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});


export const logs = (state) => state.logs.items;

export default logSlice.reducer;
