const ThSortable = (props) => {
    const {
        className,
        colName,
        colTitle,
        colChanged,
        sortCol,
        sortDestinaton,
    } = props;
    return (
        <th
            className={'cursor-pointer ' + className}
            onClick={() => colChanged(colName)}
        >
            {colTitle}
            {sortCol === colName ? (
                sortDestinaton === 'asc' ? (
                    <span className='fas fa-sort-down float-end align-middle pt-1' />
                ) : (
                    <span className='fas fa-sort-up float-end align-middle pt-1' />
                )
            ) : (
                <span className='fas fa-sort float-end align-middle pt-1' />
            )}
        </th>
    );
};

export default ThSortable;
