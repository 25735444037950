import { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import FindComponent from "../../../../components/FindComponent";
import Paginator from "../../../../components/Paginator";
import PermissionPage from "../../../../components/PermissionPage";
import SkeletonTableFull from "../../../../components/skeleton/SkeletonTableFull";
import { ToUtcString } from "../../../../helpers/DateHelpers";
import { HumanPhone } from "../../../../helpers/PhoneHelper";
import BarrierModal from "./BarrierModal";
import GuestModal from "./GuestModal";
import GuestNewButtons from "./GuestNewButtons";
import LetInModal from "./LetInModal";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import { useSelector } from "react-redux";
import {
    useLazyGetGuestAuthorQuery,
    useLazyGetGuestAutoButtonsQuery,
    useLazyGetGuestsQuery,
} from "../../../../app/storeReducers/guestsApiSlice";
import { useLazyGetVehiclesQuery } from "../../../../app/storeReducers/AccountVehiclesAPI";

const OperatorGusetsParkingPage = (props) => {
    const accountId = props.match.params.accountId;

    const [isLoading, setIsLoading] = useState(true);
    const [guests, setGuests] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [dateReport, setDateReport] = useState(new Date());
    const [guestName, setGuestName] = useState("");
    // Всё, что относится к пагинатору
    const [paginator, setPaginator] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(1);

    const [selectedVehicle, setSelectedVehicle] = useState({});
    const [vehicleDirection, setVehicleDirection] = useState("all");
    const [isOpenBarrierModal, setIsOpenBarrierModal] = useState(false);

    const [selectedGuest, setSelectedGuest] = useState({});
    const [selectedAuthor, setSelectedAuthor] = useState({});
    const [isOpenGuestModal, setIsOpenGuestModal] = useState(false);

    const [accounts, setAccounts] = useState([]);
    const [isOpenLetInModal, setIsOpenLetInModal] = useState(false);
    const [changedAccount, setChangedAccount] = useState(0);

    const context = useSelector(selectContext);

    const guestNameRef = useRef(guestName);

    const [getAutoButtons] = useLazyGetGuestAutoButtonsQuery();
    const [getGuests] = useLazyGetGuestsQuery();
    const [getVehicles] = useLazyGetVehiclesQuery();
    const [getGuestAuthor] = useLazyGetGuestAuthorQuery();

    useEffect(() => {
        guestNameRef.current = guestName;
    }, [guestName]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            await loadGuests();
            await loadVehicles();
            setIsLoading(false);
            setForcePage(pageIndex - 1);
        }

        loadResourcesAndDataAsync();
    }, [dateReport, guestName, pageIndex, pageSize]);

    useEffect(() => {
        async function loadResourcesAndData() {
            const getAccounts = await getAutoButtons(accountId).unwrap();
            setAccounts(getAccounts.result);
            window.interval23 = setInterval(() => {
                if (guestNameRef.current === "") {
                    loadGuests();
                }
            }, 1000 * 60);
        }

        loadResourcesAndData();
        return () => clearInterval(window.interval23);
    }, []);

    const loadGuests = async () => {
        const dtNow = dateReport;
        const dtString = `${dtNow.getFullYear()}-${
            dtNow.getMonth() + 1
        }-${dtNow.getDate()}`;
        const getResult = await getGuests({
            accountId,
            dateStart: dtString + "T00:00:00",
            dateFinish: dtString + "T23:59:59",
            search: guestName,
            pageIndex,
            pageSize,
            onlyCars: true,
            withBlacklist: true,
        }).unwrap();
        convertToTable(getResult.result);
        setPaginator(getResult.paginator);
    };

    const loadVehicles = async () => {
        if (guestName) {
            const dtNow = dateReport;
            const dtString = `${dtNow.getFullYear()}-${
                dtNow.getMonth() + 1
            }-${dtNow.getDate()}`;
            const getResult = await getVehicles({
                accountId,
                dateStart: dtString + "T00:00:00",
                dateFinish: dtString + "T23:59:59",
                number: guestName,
                isActive: true,
                withBlacklist: true,
                pageIndex,
                pageSize,
            }).unwrap();
            convertVehiclesToTable(getResult.result);
        } else {
            setVehicles([]);
        }
    };

    const loadAuthor = async (id) => {
        const getResult = await getGuestAuthor(id).unwrap();
        setSelectedAuthor(getResult.result);
    };

    const convertToTable = (guestArray) => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        const allowShowAuthor =
            context?.isRoot || actions?.includes("guest_show_owner");

        // Создаем новый массив с помощью map
        const guestResult = guestArray.map((item) => {
            let actionBtn = (
                <button className="btn av-btn-simple">Загрузка...</button>
            );

            // Создаем новый объект вместо изменения существующего
            const newItem = {
                ...item,
                carNumber: item.carNumber + " " + item.carRegion,
                status: "---",
            };

            if (item.blacklist) {
                newItem.status = (
                    <span className="badge badge-sm bg-light-danger rounded-pill">
                        Чёрный список
                    </span>
                );
                actionBtn = (
                    <button className="btn btn-secondary" disabled>
                        Действия запрещены
                    </button>
                );
            } else if (item.carDateOut) {
                newItem.status = (
                    <span className="badge badge-sm bg-light-secondary rounded-pill">
                        Уехал
                    </span>
                );
                actionBtn = (
                    <button
                        className="btn av-btn-primary"
                        onClick={() => openBarrierModalHandle(item, "in")}
                    >
                        Впустить посетителя
                    </button>
                );
            } else if (item.carDateIn) {
                newItem.status = (
                    <span className="badge badge-sm bg-light-success rounded-pill">
                        Приехал
                    </span>
                );
                actionBtn = (
                    <button
                        className="btn av-btn-danger"
                        onClick={() => openBarrierModalHandle(item, "out")}
                    >
                        Выпустить посетителя
                    </button>
                );
                if (item.needExitConfirmation) {
                    actionBtn = (
                        <span className="badge badge-sm bg-light-secondary rounded-pill">
                            Нет разрешения на выезд
                        </span>
                    );
                }
            } else {
                newItem.status = (
                    <span className="badge bg-light text-dark">Новая</span>
                );
                actionBtn = (
                    <button
                        className="btn av-btn-primary"
                        onClick={() => openBarrierModalHandle(item, "in", true)}
                    >
                        Впустить посетителя
                    </button>
                );
            }

            newItem.actionBtn = actionBtn;
            newItem.userTitle = item.user?.title
                ? HumanPhone(item.user.title, true)
                : "";

            if (allowShowAuthor) {
                newItem.accountTitle = (
                    <span className="cursor-pointer">
                        {item.account.title}{" "}
                        <img
                            src="/media/icons/base/info.svg"
                            width={16}
                            onClick={() => openGuestModalHandle(item)}
                        />
                    </span>
                );
            } else {
                newItem.accountTitle = item.account.title;
            }

            return newItem; // Возвращаем новый объект
        });

        setGuests(guestResult);
    };

    const convertVehiclesToTable = (guestArray) => {
        const vehiclesResult = guestArray.map((item) => ({
            ...item,
            carNumber: item.carNumber + " " + item.carRegion,
            status: item.blacklist ? (
                <span className="badge badge-sm bg-light-danger rounded-pill">
                    Чёрный список
                </span>
            ) : (
                "---"
            ),
            actionBtn: item.blacklist ? (
                <button className="btn btn-secondary" disabled>
                    Действия запрещены
                </button>
            ) : (
                <button
                    className="btn av-btn-primary"
                    onClick={() => openBarrierModalHandle(item, "all", false)}
                >
                    Пропустить автомобиль
                </button>
            ),
            name: item.employee?.title,

            userTitle: item.user?.title
                ? HumanPhone(item.user.title, true)
                : "",

            accountTitle: item.account.title,
        }));

        setVehicles(vehiclesResult);
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(1);
        setPageSize(size);
    };

    const openBarrierModalHandle = (vehicle, direction, isGuest = true) => {
        if (Object.keys(vehicle).length !== 0) {
            setSelectedVehicle({
                id: vehicle.id,
                carNumber: vehicle.carNumber,
                carRegion: vehicle.carRegion,
                isGuest: isGuest,
            });
        } else {
            setSelectedVehicle({});
        }
        setVehicleDirection(direction);
        setIsOpenBarrierModal(true);
    };

    const openGuestModalHandle = (vehicle) => {
        setSelectedGuest(vehicle);
        setSelectedAuthor({});
        loadAuthor(vehicle.id);
        setIsOpenGuestModal(true);
    };

    const okModalHandle = () => {
        loadGuests();
        setIsOpenBarrierModal(false);
    };

    const openLetInModal = (accId) => {
        setChangedAccount(accId);
        setIsOpenLetInModal(true);
    };

    const closeLetInModal = (isSaved) => {
        setIsOpenLetInModal(false);
        if (isSaved) {
            loadGuests();
        }
    };

    return (
        <PermissionPage accountId={accountId} roles={["guest_car_panel"]}>
            <BarrierModal
                accountId={accountId}
                vehicle={selectedVehicle}
                isOpen={isOpenBarrierModal}
                closeHandle={() => setIsOpenBarrierModal(false)}
                handleOk={okModalHandle}
                direction={vehicleDirection}
            />
            <GuestModal
                entity={selectedGuest}
                author={selectedAuthor}
                isOpen={isOpenGuestModal}
                closeHandle={() => setIsOpenGuestModal(false)}
            />
            <LetInModal
                isOpen={isOpenLetInModal}
                closeHandle={(isSaved) => closeLetInModal(isSaved)}
                accountId={accountId}
                preAccountId={changedAccount}
            />
            <div className="row">
                <div className="col-md-12">
                    <h1 className="mb-5">Заявки на транспорт</h1>
                    <div className="row">
                        <div className="col mb-3 d-flex justify-content-end">
                            <input
                                style={{ width: "150px" }}
                                type="date"
                                className="form-control me-2"
                                id="dateReport"
                                value={ToUtcString(dateReport).split("T")[0]}
                                onChange={(e) =>
                                    setDateReport(new Date(e.target.value))
                                }
                            />
                            <FindComponent
                                callback={(name) => {
                                    setPageIndex(1);
                                    setGuestName(name);
                                }}
                            />
                            <button
                                className="btn av-btn-primary ms-2"
                                onClick={() =>
                                    openBarrierModalHandle({}, "all")
                                }
                            >
                                Открыть шлагбаум
                            </button>
                            <Link
                                to={`/account/${accountId}/operatorNightWatch`}
                                className="btn btn-sm av-btn-primary ms-2"
                            >
                                Отчёт "Ночной дозор"
                            </Link>
                            {context?.isRoot ||
                            context?.permissions
                                ?.find((x) => x.id === parseInt(accountId))
                                ?.actions?.includes("guest_car_register") ? (
                                <GuestNewButtons
                                    accountId={accountId}
                                    callback={loadGuests}
                                />
                            ) : null}
                        </div>
                    </div>
                    {accounts?.length > 0 ? (
                        <div className="row mb-2">
                            <div className="col">
                                <div className="card border">
                                    <div className="card-body py-2 px-4">
                                        <h5 className="card-title">
                                            Создать заявку на транспорт
                                        </h5>
                                        {accounts?.map((item) => (
                                            <button
                                                className="btn av-btn-primary me-2"
                                                key={item.id}
                                                onClick={() =>
                                                    openLetInModal(item.id)
                                                }
                                            >
                                                {item.title}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                    <div className="card border">
                        <div className="table-responsive">
                            <SkeletonTableFull
                                cols={{
                                    rKeyItem: "id",
                                    accountTitle: "Аккаунт",
                                    name: "ФИО",
                                    carNumber: "Гос. номер",
                                    status: "Статус",
                                    actionBtn: "",
                                }}
                                rowsCount={5}
                                isLoading={isLoading}
                                classTable="mb-0"
                                tdClasses={{
                                    accountTitle: "ps-6 width-300",
                                    carNumber: "width-150",
                                    status: "width-150",
                                    actionBtn: "width-250 text-center",
                                }}
                                entity={guests}
                            />
                        </div>
                        <div className="card-body py-3 px-5">
                            <Paginator
                                onPageChange={changePage}
                                onPageSizeChange={changePageSize}
                                pageCount={paginator.totalPages}
                                pageSizeArray={[50, 100, 500]}
                                pageSize={pageSize}
                                countCurrent={guests.length}
                                countTotal={paginator.totalCount}
                                forcePage={forcePage}
                            />
                        </div>
                    </div>

                    {vehicles?.length > 0 ? (
                        <>
                            <h1 className="mt-5">Список транспорта</h1>
                            <div className="card border">
                                <div className="table-responsive">
                                    <SkeletonTableFull
                                        cols={{
                                            rKeyItem: "id",
                                            accountTitle: "Аккаунт",
                                            name: "ФИО",
                                            carNumber: "Гос. номер",
                                            status: "Статус",
                                            actionBtn: "",
                                        }}
                                        rowsCount={5}
                                        isLoading={isLoading}
                                        classTable="mb-0"
                                        tdClasses={{
                                            accountTitle: "ps-6 width-300",
                                            carNumber: "width-150",
                                            status: "width-100",
                                            actionBtn: "width-250 text-center",
                                        }}
                                        entity={vehicles}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </PermissionPage>
    );
};

export default OperatorGusetsParkingPage;
