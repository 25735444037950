import { useEffect, useState } from 'react';
import OutsideClickHandler from '../../../components/OutsideClickHandler';

const FilterListSelected = (props) => {
    const { entity, callback, clearTrigger, searchCallback } = props;

    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const [selectedEntities, setSelectedEntities] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        function loadResourcesAndData() {
            callback(selectedEntities);
        }

        loadResourcesAndData();
    }, [selectedEntities]);

    const changeEntity = (e, item) => {
        if (e.target.checked) {
            if (!selectedEntities.some((x) => x.key === item.key)) {
                setSelectedEntities([...selectedEntities, item]);
            }
        } else {
            console.log('this is false');
            removeChanged(item);
        }
    };

    const removeChanged = (item) => {
        setSelectedEntities(selectedEntities.filter((x) => x.key !== item.key));
    };

    useEffect(() => {
        if (clearTrigger) {
            setSelectedEntities([]);
        }
    }, [clearTrigger]);

    const changeSearchHandler = (value) => {
        setSearch(value);
        searchCallback(value);
    };

    return (
        <OutsideClickHandler
            classes='dropdown filter-list-block'
            callback={() => setIsOpenMenu(false)}
        >
            <>
                <div
                    className='form-select'
                    onClick={() => setIsOpenMenu(!isOpenMenu)}
                    data-bs-toggle='dropdown'
                >
                    {selectedEntities.length === 0 ? (
                        <span className='text-muted'>Выбрать</span>
                    ) : (
                        selectedEntities.map((item) => (
                            <div
                                className='border av-border-gray-300 rounded d-inline-block px-2 py-0 me-2 mb-2'
                                key={item.key}
                            >
                                {item.value}{' '}
                                <i
                                    className='ms-2 fas fa-times cursor-pointer text-muted'
                                    onClick={() => removeChanged(item)}
                                />
                            </div>
                        ))
                    )}
                </div>
                <ul
                    className={'dropdown-menu' + (isOpenMenu ? ' show' : '')}
                    aria-labelledby='dropdownMenu2'
                >
                    <li className='px-3'>
                        <input
                            type='text'
                            placeholder='поиск'
                            className='form-control mb-2'
                            value={search}
                            onChange={(e) =>
                                changeSearchHandler(e.target.value)
                            }
                            autoFocus
                        />
                    </li>
                    {entity?.map((item) => (
                        <li key={item.key}>
                            <div className='form-check'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value={item.key}
                                    id={'entity_' + item.key}
                                    onChange={(e) => changeEntity(e, item)}
                                    checked={selectedEntities.some(
                                        (x) => x.key === item.key
                                    )}
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor={'entity_' + item.key}
                                >
                                    {item.value}
                                </label>
                            </div>
                        </li>
                    ))}
                </ul>
            </>
        </OutsideClickHandler>
    );
};

export default FilterListSelected;
