import DashboardList from './DashboardList';
import PermissionPage from '../../components/PermissionPage';
import { useState } from 'react';
import { ACTIONS, STATUS } from 'react-joyride';
import { useHistory } from 'react-router';
import Onboarding from '../../components/onboarding/Onboarding';

const DashboardPage = (props) => {
    const history = useHistory();
    const accountId = props.match.params.accountId;

    const [steps, setSteps] = useState([
        {
            target: '#kt_aside',
            disableBeacon: true,
            placement: 'right',
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>
                        Шаг 2 из 13
                    </div>
                    <div className='mb-1 ob-title'>
                        <b>Панель управления</b>
                    </div>
                    <div className='mb-3 ob-text'>
                        На панели слева находятся все основные разделы системы.
                    </div>
                </div>
            ),
        },
        {
            target: '#kt_content_container',
            disableBeacon: true,
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>
                        Шаг 3 из 13
                    </div>
                    <div className='mb-1 ob-title'>
                        <b>Раздел «Обзор»</b>
                    </div>
                    <div className='mb-3 ob-text'>
                        Это первый раздел, здесь вы сразу можете увидеть сводную
                        информацию о посетителях, сотрудниках и устройствах.
                        Кроме того, справа показаны последние уведомления.
                    </div>
                </div>
            ),
        },
    ]);

    const onboardingCallback = (action, status, index) => {
        console.log('callback', `${action} - ${status} - ${index}`);
        if (
            action === ACTIONS.CLOSE &&
            status === STATUS.FINISHED &&
            index === 1
        ) {
            console.log('goToEmployee');
            history.push(`/account/${accountId}/employees`);
        }
    };

    return (
        <PermissionPage accountId={accountId} roles={['any']}>
            <Onboarding
                steps={steps}
                run={true}
                callback={onboardingCallback}
            />
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='page-header'>Обзор</h1>
                    <DashboardList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default DashboardPage;
