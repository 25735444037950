import EmployeesListTable from './AdminEmployeesListTable';

const AdminEmployeesPage = (props) => {

    return (
        <div className='row mt-4'>
            <div className='col-md-12'>
                <h1 className='mb-5'>Пользователи</h1>
                <EmployeesListTable />
            </div>
        </div>
    );
};

export default AdminEmployeesPage;
