import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';
import { addNotification } from './NotifySlice';

const nameSlice = 'accountSchedules';
const urlSlice = 'schemes/schedules';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    objectsArray: [],
    pageSize: 50,
    pageSelected: 1,
    paginator: [],
};

export const getListAsync = createAsyncThunk(
    `${nameSlice}/getList`,
    async (accountId, thunkAPI) => {
        const getResult = await Http.get(
            `api/${urlSlice}?accountId=${accountId}&pageIndex=${
                thunkAPI.getState().accountSchedules.pageSelected
            }&pageSize=${thunkAPI.getState().accountSchedules.pageSize}`
        );
        console.log('scheduleRes', getResult);
        return getResult;
    }
);

export const createAsync = createAsyncThunk(
    `${nameSlice}/create`,
    async (entity) => {
        const createResult = await Http.post(`api/${urlSlice}`, true, entity);
        return createResult.result;
    }
);

export const updateAsync = createAsyncThunk(
    `${nameSlice}/update`,
    async (entity, thunkAPI) => {
        const updateeResult = await Http.put(
            `api/${urlSlice}/${entity.id}`,
            true,
            entity
        );
        if (updateeResult !== undefined && updateeResult != null) {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Расписание сохранено!',
                    description: 'Данные расписания успешно сохранены!',
                    type: 'success',
                })
            );
        } else {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description:
                        'Не удалось сохранить данные расписания! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }

        return updateeResult.result;
    }
);

export const deleteAsync = createAsyncThunk(
    `${nameSlice}/delete`,
    async (id) => {
        await Http.delete(`api/${urlSlice}/${id}`, true);
        return id;
    }
);

export const accountSchedulesSlice = createSlice({
    name: nameSlice,
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        selectPage: (state, action) => {
            state.pageSelected = action.payload;
        },
    },
    extraReducers: {
        [getListAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getListAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.objectsArray = action.payload.result;
                state.paginator = action.payload.paginator;
            }
            state.isLoading = false;
        },
        [getListAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/getListError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [createAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.objectsArray.push(action.payload);
            }
        },
        [createAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/createError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [updateAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                console.log('updateEmp', action.payload);
                const updateObj = state.objectsArray.map((item) => {
                    if (item.id === action.payload.id) {
                        item = action.payload;
                    }
                    return item;
                });
                state.objectsArray = updateObj;
            }
        },
        [updateAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/updateError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [deleteAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                const updateObj = state.objectsArray.filter(
                    (x) => x.id !== action.payload
                );
                state.objectsArray = updateObj;
            }
        },
        [deleteAsync.rejected]: (state, action) => {
            console.log(nameSlice + '/deleteError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const { setPageSize, selectPage } = accountSchedulesSlice.actions;

export const selectList = (state) => state.accountSchedules.objectsArray;
export const selectIsLoading = (state) => state.accountSchedules.isLoading;
export const selectPaginator = (state) => state.accountSchedules.paginator;

export default accountSchedulesSlice.reducer;
