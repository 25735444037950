import PermissionPage from '../../../components/PermissionPage';
import LocationDevicesPanel from './LocationDevicesPanel';
import LocationEditPanel from './LocationEditPanel';

const LocationCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const locationId = props.match.params.locationId;

    return (
        <PermissionPage accountId={accountId} roles={['devices_list']}>
            <h1 className='mb-5'>Карточка объекта</h1>
            <div className='row'>
                <div className='col-md-6'>
                    <LocationEditPanel
                        accountId={accountId}
                        entityId={locationId}
                    />
                </div>
                <div className='col-6'>
                    <div className='row mb-4'>
                        <div className='col'>
                            <LocationDevicesPanel
                                accountId={accountId}
                                entityId={locationId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default LocationCardPage;
