import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthorize } from '../app/storeReducers/CurrentUserSlice';
import { selectContext } from '../app/storeReducers/AuthSlice';

const PrivateRoute = ({ chObject, ...rest }) => {
    const context = useSelector(selectContext);
    const location = useLocation();
    const isAdmin = location.pathname.startsWith('/admin/');

    return (
        <Route
            {...rest}
            render={(props) =>
                context ? (
                    isAdmin && !context?.isRoot ? (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    ) : (
                        <rest.render {...props} />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
