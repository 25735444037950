import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectBreadcrumbs, setBreadcrumbs } from '../app/storeReducers/AccountBreadcrumbsSlice';
import { useAccount } from './useAccount';
import { useLazyGetAuthNavigationQuery } from '../app/storeReducers/authApiSlice';

export function useBreadCrumbs() {
    const params = useParams();
    const accountId = params.accountId;

    const dispatch = useDispatch();

    const account = useAccount();
    const breadcrumbs = useSelector(selectBreadcrumbs);
    const [getAuthNavigation] = useLazyGetAuthNavigationQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (accountId !== undefined && account?.id !== parseInt(accountId, 10)) {
                const result = await getAuthNavigation(accountId).unwrap();
                dispatch(setBreadcrumbs(result.result));
            }
        }
        loadResourcesAndDataAsync();
    }, [accountId]);

    return breadcrumbs;
}
