import HostEditPanel from './HostEditPanel';

const HostCardPage = (props) => {
    const hostId = props.match.params.hostId;

    return (
        <div className='row'>
            <div className='col-6'>
                <HostEditPanel entityId={hostId} />
            </div>
        </div>
    );
};

export default HostCardPage;
