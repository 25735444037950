import AdminCardsList from './AdminCardsList';

const AdminCardsPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Карты</h1>
                    <AdminCardsList accountId={accountId} />
                </div>
            </div>
        </>
    );
};

export default AdminCardsPage;
