import { useEffect, useState } from 'react';
import { Http } from '../../http';
import { useDispatch, useSelector } from 'react-redux';

import {
    createAsync,
    updateAsync,
    selectActions,
} from '../../app/storeReducers/AccountRolesSlice';

const RoleCreateModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [actions, setActions] = useState([]);

    const [roleActions, setRoleActions] = useState([]);

    useEffect(() => {
        setName('');
        setActions([]);
        setRoleActions([]);
    }, [isOpen])


    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== 0 && entityId !== -1) {
                const getResult = await Http.get('api/roles/' + entityId, true);
                setName(getResult.result.name);
                setActions(getResult.result.actions);
            } else {
                setName('');
                setActions([]);
            }
        }

        loadResourcesAndDataAsync();
        console.log(roleActions);
    }, [entityId]);

    useEffect(() => {
        async function loadActionsAsync() {
            const getResult = await Http.get(
                'api/roles/actions?accountId=' + accountId,
                true
            );
            setRoleActions(getResult.result);
        }

        loadActionsAsync();
    }, [accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            accountId,
            id: entityId,
            actions,
        };
        if (entityId === 0) {
            dispatch(createAsync(entity));
        } else {
            dispatch(updateAsync(entity));
        }
        closeHandle();
    };

    const changeAction = (e, actionName) => {
        if (e.target.checked) {
            setActions([...actions, actionName]);
        } else {
            setActions(actions.filter((x) => x !== actionName));
        }
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-dialog-scrollable'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Добавить новую роль</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='form-group mb-3'>
                                <label htmlFor='name'>Название</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div className='row'>
                                {roleActions?.map((item) => (
                                    <div
                                        className='col-12 mb-2'
                                        key={item.name}
                                    >
                                        <div className='action-title mb-2'>
                                            <b>{item.name}</b>
                                        </div>
                                        {item.actions.map((actionItem) => (
                                            <>
                                                {parseInt(accountId) !== 1 &&
                                                actionItem.key ===
                                                    'root' ? null : (
                                                    <div
                                                        className='action-item ps-10'
                                                        key={actionItem.key}
                                                    >
                                                        <div className='form-group form-check mb-3'>
                                                            <input
                                                                type='checkbox'
                                                                className='form-check-input'
                                                                id={
                                                                    'role_' +
                                                                    actionItem.key
                                                                }
                                                                name='roleActions'
                                                                onChange={(e) =>
                                                                    changeAction(
                                                                        e,
                                                                        actionItem.key
                                                                    )
                                                                }
                                                                checked={actions.includes(
                                                                    actionItem.key
                                                                )}
                                                                disabled={
                                                                    actionItem.disabled
                                                                }
                                                            />
                                                            <label
                                                                className='form-check-label'
                                                                htmlFor={
                                                                    'role_' +
                                                                    actionItem.key
                                                                }
                                                            >
                                                                {
                                                                    actionItem.value
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default RoleCreateModal;
