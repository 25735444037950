import React, { useEffect, useState } from 'react';
import { Http } from '../../http';
import {
    createGroupAsync,
    updateGroupAsync,
} from '../../app/storeReducers/AccountGroupsSlice';
import { useDispatch } from 'react-redux';

const GroupCreateModal = (props) => {
    const { accountId, groupId, isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState('');

    useEffect(() => {
        if(groupId === undefined){
            setName('');
        }        
    }, [isOpen])


    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (groupId !== 0 && groupId !== -1) {
                const emp = await Http.get('api/groups/' + groupId, true);
                setName(emp.result.name);
            } else {
                setName('');
            }
        }

        loadResourcesAndDataAsync();
    }, [groupId]);

    const saveGroup = async () => {
        const group = {
            name,
            account: {
                id: accountId,
            },
            id: groupId,
        };
        if (groupId === 0) {
            dispatch(createGroupAsync(group));
        } else {
            dispatch(updateGroupAsync(group));
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить новую группу
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='form-group mb-3'>
                                <label htmlFor='firstName'>Название</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveGroup}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default GroupCreateModal;
