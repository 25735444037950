import PermissionPage from '../../components/PermissionPage';
import SchedulesList from './SchedulesList';

const SchedulesPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['schemes_list']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Графики доступа</h1>
                    <SchedulesList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default SchedulesPage;
