import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ACTIONS } from 'react-joyride';
import Onboarding from '../../../components/onboarding/Onboarding';
import StartModal from '../../../components/onboarding/StartModal';
import { useSelector } from 'react-redux';
import { selectContext } from '../../../app/storeReducers/AuthSlice';

const PersonalAccountsPage = () => {
    const history = useHistory();
    const context = useSelector(selectContext);

    const openAccount = (accountId) => {
        history.push(`/account/${accountId}/dashboard`);
    };

    const [steps, setSteps] = useState([
        {
            target: '.av-card',
            disableBeacon: true,
            placement: 'right',
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>Шаг 1 из 13</div>
                    <div className='mb-1 ob-title'>
                        <b>Выбор аккаунта</b>
                    </div>
                    <div className='mb-3 ob-text'>
                        После авторизации вы можете выбрать аккаунт для работы. Обычно это один из объектов,
                        оборудованных СКУД Avanguard. Например, ваш офис, склад или коттеджный посёлок.
                    </div>
                </div>
            ),
        },
    ]);
    const [run, setRun] = useState(false);
    const [isOpenStartModal, setIsOpenStartModal] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (context?.user?.login && context?.user?.login === '70000000000') {
                const hasTutorial = localStorage.getItem('tutorial') ? true : false;
                setIsOpenStartModal(!hasTutorial);
            }
        }

        loadResourcesAndDataAsync();
    }, [context]);

    function GetImgClasses(props) {
        const category = props.category;
        if (category === 'company') {
            return (
                <div className='title-img-block title-img-block-info me-3 align-self-center'>
                    <img src='/media/icons/base/briefcase.svg' height='20' />
                </div>
            );
        } else if (category === 'restaurant') {
            return (
                <div className='title-img-block title-img-block-success me-3 align-self-center'>
                    <img src='/media/icons/base/coffee.svg' height='20' />
                </div>
            );
        } else if (category === 'group') {
            return (
                <div className='title-img-block title-img-block-danger me-3 align-self-center'>
                    <img src='/media/icons/avanguard/Group2.svg' height='20' />
                </div>
            );
        } else {
            return (
                <div className='title-img-block title-img-block-warning me-3 align-self-center d-flex justify-content-center'>
                    <img src='/media/icons/avanguard/HomeTitleOrange.png' height='20' className='align-self-center' />
                </div>
            );
        }
    }

    const onboardingCallback = (action) => {
        if (action === ACTIONS.CLOSE) {
            history.push(`/account/${context?.accounts[0].id}/dashboard`);
        }
    };

    const startOnboarding = () => {
        setIsOpenStartModal(false);
        setRun(true);
    };

    const skipOnboarding = () => {
        localStorage.setItem('tutorial', 'false');
        setIsOpenStartModal(false);
    };

    return (
        <>
            <StartModal isOpen={isOpenStartModal} okHandle={startOnboarding} closeHandle={skipOnboarding} />
            <Onboarding steps={steps} run={run} callback={onboardingCallback} />
            <div className='row'>
                <div className='col'>
                    <h1 className='mb-4'>Добро пожаловать в Avanguard</h1>
                    <p className='font-size-16' style={{ marginBottom: 32 }}>
                        Выберите аккаунт для управления
                    </p>
                    <div className='row'>
                        {context?.accounts?.map((item) => (
                            <div className='col-md-4' key={item.id}>
                                <div className='av-card mb-24' key={item.id} onClick={() => openAccount(item.id)}>
                                    <div className='h3 d-flex flex-row'>
                                        <GetImgClasses category={item.category} />
                                        <div className='align-self-center'>{item.title}</div>
                                    </div>
                                    {item.parentTitle && (
                                        <div className='h5 d-flex flex-row' style={{ marginTop: -15 }}>
                                            <div className='align-self-center fild-label ms-13'>{item.parentTitle}</div>
                                        </div>
                                    )}
                                    {item.isBlocked ? (
                                        <div className='h3 '>
                                            <div className='align-self-center'>
                                                <span className='badge bg-light-danger ms-13'>БЛОКИРОВКА</span>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PersonalAccountsPage;
