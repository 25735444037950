import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();
const baseQuery = retry(
    fetchBaseQuery({
        baseUrl: process.env.REACT_APP_PLATFORM_URL + 'api/',
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json;charset=UTF-8');
            const token = localStorage.getItem('access_token');
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    {
        maxRetries: 5,
    }
);
const baseQueryWithReauth = async (args, api, extraOptions) => {
    await mutex.waitForUnlock();
    if (!mutex.isLocked()) {
        let result = await baseQuery(args, api, extraOptions);
        if (result.error && result.error.status === 401) {
            const release = await mutex.acquire();
            // try to get a new token
            try {
                const refreshResult = await baseQuery(
                    {
                        url: 'auth/refresh_token',
                        method: 'POST',
                        body: {
                            refresh_token: localStorage.getItem('refresh_token'),
                        },
                    },
                    api,
                    extraOptions
                );
                if (refreshResult.data) {
                    // store the new token
                    localStorage.setItem('access_token', refreshResult.data.result.access_token.value);
                    localStorage.setItem('access_token_time', refreshResult.data.result.access_token.expireAt);
                    localStorage.setItem('refresh_token', refreshResult.data.result.refresh_token.value);
                    localStorage.setItem('refresh_token_time', refreshResult.data.result.refresh_token.expireAt);
                    // retry the initial query
                    result = await baseQuery(args, api, extraOptions);
                } else {
                    window.location.href = '/logout';
                }
            } catch (e) {
                console.log('RefreshTokenError', e);
                retry.fail(result.error);
            } finally {
                // release must be called once the mutex should be released again.
                release();
            }
        }
        return result;
    } else {
        // wait until the mutex is available without locking it
        await mutex.waitForUnlock();
        let result = await baseQuery(args, api, extraOptions);
        return result;
    }
};

export const baseApi = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        'Monitors',
        'Devices',
        'Notifications',
        'Vehicles',
        'Schedules',
        'Schedule',
        'ScheduleDetails',
        'Blacklists',
        'AccessLogs',
    ],
    endpoints: (_) => ({}),
});
