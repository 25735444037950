export const HumanPhone = (phoneNumber, masquerade = false) => {
    const match = phoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        return [
            '+',
            match[1],
            ' ',
            match[2],
            ' ',
            match[3],
            ' ',
            masquerade ? 'XX' : match[4],
            ' ',
            match[5],
        ].join('');
    }
    return phoneNumber;
};
