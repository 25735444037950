import PermissionPage from '../../components/PermissionPage';
import MonitorsList from './MonitorsList';

const MonitorsPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['monitor_list']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Монитор доступа</h1>
                    <MonitorsList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default MonitorsPage;
