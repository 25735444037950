import { useContext, useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Http } from '../../../../http';
import RoleAddEmployeeModal from './RoleAddEmployeeModal';
import RoleEmployeeDeleteModal from './RoleEmployeeDeleteModal';
import SecutiryContext from '../../../../features/securityContext';

const RoleEmployeesPanel = (props) => {
    const { accountId, entityId } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [members, setMembers] = useState([]);
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [entityId, canEdit]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('employees_edit'));
        setCanList(context.isRoot || actions?.includes('employees_list'));
    }, [context, accountId]);

    const loadData = async () => {
        setIsLoading(true);
        const getResult = await Http.get(
            `api/employees?accountId=${accountId}&roleId=${entityId}`,
            true
        );
        setMembers(getResult.result);
        setIsLoading(false);
    };

    const okAddEmployee = () => {
        loadData();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteEmployee = () => {
        loadData();
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <RoleAddEmployeeModal
                accountId={accountId}
                roleId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddEmployee}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <RoleEmployeeDeleteModal
                roleId={entityId}
                employee={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteEmployee}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <h3>
                                Участники{' '}
                                <span className='badge rounded-pill bg-light text-dark'>
                                    {members.length}
                                </span>
                            </h3>
                        </div>
                        <div className='col-md-6 text-end'>
                            {canEdit ? (
                                <button
                                    className='btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0'
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr075.svg'
                                        className='svg-icon-2 me-2'
                                    />
                                    Добавить сотрудника
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col'>
                            {canList ? (
                                members.map((item) => (
                                    <div
                                        className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'
                                        key={item.id}
                                    >
                                        {item.name}{' '}
                                        {canEdit ? (
                                            <i
                                                className='ms-2 fas fa-times cursor-pointer'
                                                onClick={() =>
                                                    openDeleteModal(item)
                                                }
                                            ></i>
                                        ) : null}
                                    </div>
                                ))
                            ) : (
                                <i>У вас нет прав на просмотр содержимого</i>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoleEmployeesPanel;
