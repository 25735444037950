import { useDispatch } from "react-redux";
import { addNotification } from "../../../../../../app/storeReducers/NotifySlice";
import { useUpdateVehicleMutation } from "../../../../../../app/storeReducers/AccountVehiclesAPI";

const RemoveVehicleModal = (props) => {
    const { vehicle, isOpen, okHandle, closeHandle } = props;

    const dispatch = useDispatch();

    const [updateVehicle] = useUpdateVehicleMutation();

    const removeVehicle = async () => {
        const entity = { ...vehicle };
        entity.parkingPlace = null;
        const updateResult = await updateVehicle(entity).unwrap();
        if (updateResult?.result?.id > 0) {
            okHandle();
        } else {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: "Ошибка сохранения!",
                    description:
                        "Не удалось отвязать транспортное средство! Перезагрузите страницу и попробуйте снова!",
                    type: "danger",
                })
            );
        }
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Отвязать транспорт</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Вы действительно хотите отвязать транспортное
                                средство{" "}
                                {vehicle !== null && vehicle !== undefined ? (
                                    <strong>
                                        {vehicle?.carNumber +
                                            vehicle?.carRegion}
                                    </strong>
                                ) : null}
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={removeVehicle}
                            >
                                Отвязать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default RemoveVehicleModal;
