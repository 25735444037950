import { baseApi } from "../baseApi";

export const logsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getLogAccess: builder.query({
            query: (data) =>
                `log/access?accountId=${
                    data.accountId ?? ""
                }&subjectAccountId=${data.subjectAccountId ?? ""}&entityType=${
                    data.entityType
                }&entityId=${data.entityId ?? ""}&deviceId=${
                    data.deviceId ?? ""
                }&deviceUid=${data.deviceUid ?? ""}&export=${
                    data.export ?? "false"
                }&card=${data.card ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}&start=${
                    data.dateStart ?? ""
                }&finish=${data.dateFinish ?? ""}`,
        }),
        getLogCheckIn: builder.query({
            query: (data) =>
                `log/checkin?accountId=${
                    data.accountId ?? ""
                }&date=${data.date ?? ""}&export=false`,
        }),
        exportLogCheckIn: builder.query({
            query: (data) => ({
                url: `log/checkin?accountId=${
                    data.accountId ?? ""
                }&date=${data.date ?? ""}&export=${
                    data.export ?? "true"
                }`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
    }),
});

export const {
    useGetLogAccessQuery,
    useLazyGetLogAccessQuery,
    useGetLogCheckInQuery,
    useLazyGetLogCheckInQuery,
    useExportLogCheckInQuery,
    useLazyExportLogCheckInQuery,
} = logsApiSlice;
