import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const AccountVehiclesAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getVehicles: builder.query({
            query: ({
                accountId = 0,
                employeeId = "",
                search = "",
                pageIndex = 1,
                pageSize = 100,
                number = "",
                showDeleted = false,
                isActive = false,
                withBlacklist = false,
                sort = "",
            }) =>
                `vehicles?accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}` +
                `&search=${search}&employeeId=${employeeId}&number=${number}&showDeleted=${showDeleted}` +
                `&isActive=${isActive}&withBlacklist=${withBlacklist}&sort=${sort}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Vehicles",
                              id,
                          })),
                          { type: "Vehicles", id: "List" },
                      ]
                    : [{ type: "Vehicles", id: "List" }],
            transformResponse: (response) => {
                response?.result.forEach((item, index) => {
                    item.accountTitle = item.account.title;
                    item.fullNumber = item.carNumber + " " + item.carRegion;
                });
                return response;
            },
        }),
        getVehicleById: builder.query({
            query: (id) => `vehicles/${id}`,
            providesTags: (result) => [
                { type: "Vehicle", id: result?.result.id },
            ],
        }),
        createVehicle: builder.mutation({
            query: (body) => ({
                url: "vehicles",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Vehicles", id: "List" }],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка создания!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось создать транспорт! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Транспорт создан!",
                                description: "Транспорт успешно создан!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка создания!",
                            description:
                                "Не удалось создать транспорт! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        updateVehicle: builder.mutation({
            query: (body) => ({
                url: `vehicles/${body.id}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Vehicles", id: "List" },
                { type: "Vehicle", id: result?.result.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить данные транспорта! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Транспорт сохранен!",
                                description:
                                    "Данные транспорта успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить данные транспорта! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        vehicleDelete: builder.mutation({
            query: (id) => ({
                url: `vehicles/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Vehicles", id: "List" }],
        }),
        vehicleReset: builder.mutation({
            query: (vehicleId) => ({
                url: `vehicles/${vehicleId}/auto/reset`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Vehicles", id: "List" }],
        }),
        setVehicleAutoInOut: builder.mutation({
            query: (data) => ({
                url: `vehicles/${data.vehicleId}/auto/${data.direction}?deviceId=${data.deviceId}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Vehicles", id: "List" }],
        }),
        setVehicleAutoReset: builder.mutation({
            query: (vehicleId) => ({
                url: `vehicles/${vehicleId}/auto/reset`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Vehicles", id: "List" }],
        }),
    }),
});

export const {
    useGetVehiclesQuery,
    useLazyGetVehiclesQuery,
    useVehicleResetMutation,
    useVehicleDeleteMutation,
    useCreateVehicleMutation,
    useUpdateVehicleMutation,
    useGetVehicleByIdQuery,
    useLazyGetVehicleByIdQuery,
    useSetVehicleAutoInOutMutation,
    useSetVehicleAutoResetMutation,
} = AccountVehiclesAPI;
