import PermissionPage from '../../../components/PermissionPage';
import GroupEmployeesPanel from './employee/GroupEmployeesPanel';
import GroupEditPanel from './GroupEditPanel';
import GroupSchemesPanel from './scheme/GroupSchemesPanel';

const GroupCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const groupId = props.match.params.groupId;

    return (
        <PermissionPage
            accountId={accountId}
            roles={['groups_edit', 'groups_list']}
        >
            <h1 className='mb-5'>Карточка группы</h1>
            <div className='row'>
                <div className='col-6'>
                    <GroupEditPanel accountId={accountId} entityId={groupId} />
                </div>
                <div className='col-6'>
                    <div className='row mb-2'>
                        <div className='col'>
                            <GroupSchemesPanel
                                accountId={accountId}
                                groupId={groupId}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <GroupEmployeesPanel
                                accountId={accountId}
                                entityId={groupId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default GroupCardPage;
