import { useContext, useEffect, useState } from 'react';
import Paginator from '../../components/Paginator';
import SkeletonTableFull from '../../components/skeleton/SkeletonTableFull';
import { useAccount } from '../../components/useAccount';
import { DateToString, UtcToLocal } from '../../helpers/DateHelpers';
import { Http } from '../../http';
import ScreenView from './ScreenView';
import { saveAs } from 'file-saver';
import FilterComponent from './filters/FilterComponent';
import SecutiryContext from '../../features/securityContext';
import { useLazyGetAccessLogQuery } from '../../app/storeReducers/AccountAccessLogAPI';

const AccesslogList = (props) => {
    const { accountId } = props;
    const account = useAccount();
    const { context } = useContext(SecutiryContext);
    const [getAccLogs] = useLazyGetAccessLogQuery();

    const [isLoading, setIsLoading] = useState(true);
    const [logs, setLogs] = useState([]);
    const [dateStart, setDateStart] = useState(new Date());
    const [dateFinish, setDateFinish] = useState(new Date());
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(0);
    const [paginator, setPaginator] = useState({});
    const [forcePage, setForcePage] = useState(1);

    const [screenIsOpen, setScreenIsOpen] = useState(false);
    const [selectedScreen, setSelectedScreen] = useState('');

    const [filterDevices, setFilterDevices] = useState([]);

    const [filterEvents, setFilterEvents] = useState([]);

    const [filterAccount, setFilterAccount] = useState(0);

    const [filterCard, setFilterCard] = useState('');

    const [subjectSelected, setSubjectSelected] = useState(0);

    const [filterEmployees, setFilterEmployees] = useState([]);

    const [filterGuests, setFilterGuests] = useState([]);

    const [filterVehicles, setFilterVehicles] = useState([]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (account && context) {
                await loadData();
            }
        }

        loadResourcesAndDataAsync();
    }, [account, pageIndex, pageSize]);

    const loadData = async () => {
        console.log('isSeparateSubaccount', account.isSeparateSubaccount);
        setIsLoading(true);
        let subjAccId = filterAccount;
        let separateSubaccount = false;
        if (filterAccount === 0) {
            subjAccId = '';
        }

        let accId = '';
        if (account.type !== 'subaccount') {
            accId = accountId;
        } else if (account.type === 'subaccount' && account.isSeparateSubaccount === true) {
            accId = accountId;
            separateSubaccount = true;
        } else {
            subjAccId = accountId;
        }
        let entityType = '';
        let entityArray = [];
        switch (parseInt(subjectSelected)) {
            case 0:
                entityType = '';
                entityArray = [];
                break;
            case 1:
                entityType = 'employee';
                entityArray = filterEmployees;
                break;
            case 2:
                entityType = 'guest';
                entityArray = filterGuests;
                break;
            case 3:
                entityType = 'vehicle';
                entityArray = filterVehicles;
                break;

            default:
                break;
        }
        let otherFilters = '';
        filterDevices.map((item) => (otherFilters += '&deviceIds=' + item.key));
        entityArray.map((item) => (otherFilters += '&entityIds=' + item.key));
        filterEvents.map((item) => (otherFilters += '&accessTypes=' + item.key));
        const getLogs = await getAccLogs({
            accountId: accId,
            pageIndex,
            pageSize,
            subjectAccountId: subjAccId,
            dateStart: `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}T00:00:00`,
            dateFinish: `${dateFinish.getFullYear()}-${dateFinish.getMonth() + 1}-${dateFinish.getDate()}T23:59:59`,
            card: filterCard,
            entityType,
            separateSubaccount,
            otherFilters,
        }).unwrap();
        console.log('getLogs', getLogs);
        console.log(getUrl(false));
        //const getLogs = await Http.get(getUrl(false));
        if (getLogs.paginator.totalPages < pageIndex) {
            setPageIndex(getLogs.paginator.totalPages);
        }
        let newData = getLogs.result.map((item, index) =>
            Object.assign({}, item, {
                id: index,
                cardTitle: item.card?.title ?? '---',
                entityTitle: item.entity ? <GetEntity type={item.entity?.type} title={item.entity?.title} /> : '---',
                dtString: DateToString(UtcToLocal(item.time), true),
                screen: item.screenId ? (
                    <i
                        className='fas fa-image fa-lg'
                        style={{ cursor: 'pointer' }}
                        onClick={() => openScreenModal(item.screenId)}
                    ></i>
                ) : null,
                accountTitle: item.account?.title ?? '---',
                deviceTitle: item.device?.title ? (
                    <span data-toggle='tooltip' data-placement='top' title={item.device.uid}>
                        {item.device.title}
                    </span>
                ) : null,
            })
        );
        setForcePage(getLogs.paginator.pageIndex - 1);
        setLogs(newData);
        setPaginator(getLogs.paginator);
        setIsLoading(false);
    };

    const exportData = async () => {
        const response = await Http.getMedia(getUrl(true));
        var blob = new Blob([response], {
            type: 'text/csv',
        });
        saveAs(blob, 'access_logs.csv');
    };

    function getUrl(isExport) {
        let accId = '';
        let subjAccId = filterAccount;
        let separateSubaccount = false;
        if (filterAccount === 0) {
            subjAccId = '';
        }

        if (account.type !== 'subaccount') {
            accId = accountId;
        } else if (account.type === 'subaccount' && account.isSeparateSubaccount === true) {
            accId = accountId;
            separateSubaccount = true;
        } else {
            subjAccId = accountId;
        }

        const dtStart = dateStart;
        const dtStartString = `${dtStart.getFullYear()}-${dtStart.getMonth() + 1}-${dtStart.getDate()}T00:00:00`;
        const dtFinish = dateFinish;
        const dtFinishString = `${dtFinish.getFullYear()}-${dtFinish.getMonth() + 1}-${dtFinish.getDate()}T23:59:59`;

        let entityType = '';
        let entityArray = [];
        switch (parseInt(subjectSelected)) {
            case 0:
                entityType = '';
                entityArray = [];
                break;
            case 1:
                entityType = 'employee';
                entityArray = filterEmployees;
                break;
            case 2:
                entityType = 'guest';
                entityArray = filterGuests;
                break;
            case 3:
                entityType = 'vehicle';
                entityArray = filterVehicles;
                break;

            default:
                break;
        }

        var exportArg = '';
        if (isExport) exportArg = 'export=true&';
        if (subjAccId === undefined) subjAccId = '';
        let url =
            `api/log/access?${exportArg}accountId=${accId}&subjectAccountId=${subjAccId}&start=${dtStartString}&finish=${dtFinishString}&pageIndex=${pageIndex}&pageSize=${pageSize}` +
            `&card=${filterCard}&entityType=${entityType}&separateSubaccount=${separateSubaccount}`;
        filterDevices.map((item) => (url += '&deviceIds=' + item.key));
        entityArray.map((item) => (url += '&entityIds=' + item.key));
        filterEvents.map((item) => (url += '&accessTypes=' + item.key));
        return url;
    }

    const changePage = (index) => {
        setPageIndex(index);
    };
    const changePageSize = async (size) => {
        setPageSize(size);
    };

    const openScreenModal = (screenId) => {
        setSelectedScreen(screenId);
        setScreenIsOpen(true);
    };

    const closeScreenModal = () => {
        setScreenIsOpen(false);
        setSelectedScreen('');
    };

    function GetEntity(props) {
        const type = props.type;
        const title = props.title;
        if (type === 'admin') {
            return (
                <span data-toggle='tooltip' data-placement='top' title='Администратор сервиса'>
                    <i className='fas fa-toolbox'></i> {title}
                </span>
            );
        } else if (type === 'employee') {
            return (
                <span data-toggle='tooltip' data-placement='top' title='Сотрудник'>
                    <i className='fas fa-user'></i> {title}
                </span>
            );
        } else if (type === 'guest') {
            return (
                <span data-toggle='tooltip' data-placement='top' title='Посетитель'>
                    <i className='fas fa-user-secret'></i> {title}
                </span>
            );
        } else if (type === 'vehicle') {
            return (
                <span data-toggle='tooltip' data-placement='top' title='Автомобиль'>
                    <i className='fas fa-car'></i> {title}
                </span>
            );
        }
        return <span>{title}</span>;
    }

    return (
        <>
            <ScreenView screenId={selectedScreen} isOpen={screenIsOpen} closeHandle={closeScreenModal} />
            <div className='row'>
                <div className='col mb-3 d-flex justify-content-end'>
                    <FilterComponent
                        accountId={accountId}
                        account={account}
                        dateStart={dateStart}
                        setDateStart={setDateStart}
                        dateFinish={dateFinish}
                        setDateFinish={setDateFinish}
                        filterCard={filterCard}
                        setFilterCard={setFilterCard}
                        filterAccount={filterAccount}
                        setFilterAccount={setFilterAccount}
                        subjectSelected={subjectSelected}
                        setSubjectSelected={setSubjectSelected}
                        setFilterDevices={setFilterDevices}
                        setFilterEvents={setFilterEvents}
                        setFilterEmployees={setFilterEmployees}
                        setFilterGuests={setFilterGuests}
                        setFilterVehicles={setFilterVehicles}
                        loadData={loadData}
                    />
                    <button className='btn av-btn-primary btn-sm' onClick={loadData}>
                        <i className='fas fa-redo'></i> Обновить
                    </button>

                    <button className='btn av-btn-primary btn-sm mx-3' onClick={exportData}>
                        <i className='fas fa-file-csv'></i> Экспорт
                    </button>
                </div>
            </div>
            <div className='card border'>
                <div className='table-responsive'>
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: 'id',
                            dtString: 'Дата',
                            accountTitle: 'Аккаунт',
                            entityTitle: 'Субъект',
                            deviceTitle: 'Устройство',
                            cardTitle: 'Идентификатор',
                            eventName: 'Событие',
                            screen: '',
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='mb-0'
                        entity={logs}
                        tdClasses={{ dtString: 'ps-6' }}
                    />
                </div>
                <div className='card-body py-3 px-5'>
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={logs.length}
                        countTotal={paginator.totalCount}
                        forcePage={forcePage}
                    />
                </div>
            </div>
        </>
    );
};

export default AccesslogList;
