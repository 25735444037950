const EndModal = (props) => {
    const { isOpen, closeHandle } = props;

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header pb-2 align-items-start'>
                            <div className='modal-title'>
                                <h5 className='mb-3'>Оставить заявку</h5>
                            </div>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body av-text-gray-500'>
                            Ваша заявка успешно отправлена. Мы свяжемся с вами в
                            ближайшее время.
                        </div>
                        <div className='modal-footer d-block py-3'>
                            <div className='d-grid mb-5'>
                                <button
                                    type='button'
                                    className='btn av-btn-primary'
                                    onClick={closeHandle}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default EndModal;
