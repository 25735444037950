import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonInput from "../../../../../components/skeleton/SkeletonInput";
import ParkingPlaceDeleteModal from "../ParkingPlaceDeleteModal";
import { useHistory } from "react-router";
import { selectContext } from "../../../../../app/storeReducers/AuthSlice";
import {
    useGetParkingPlaceByIdQuery,
    useUpdateParkingPlaceMutation,
} from "../../../../../app/storeReducers/parkingPlacesApiSlice";

const PlaceEditPanel = (props) => {
    const { accountId, parkingId, entityId, placeLoaded } = props;

    const history = useHistory();

    const [number, setNumber] = useState("");

    const context = useSelector(selectContext);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { data: place, isLoading } = useGetParkingPlaceByIdQuery(entityId, {
        skip: !entityId,
    });

    const [updatePlaceAsync] = useUpdateParkingPlaceMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (place) {
                setNumber(place.result.number);
                placeLoaded(place.result);
            }
        }

        loadResourcesAndDataAsync();
    }, [place]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("parking_place_edit"));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            number,
            id: entityId,
        };
        await updatePlaceAsync(entity);
    };

    const deletePlace = () => {
        history.push(`/account/${accountId}/parking/${parkingId}/places`);
    };

    return (
        <>
            <ParkingPlaceDeleteModal
                entity={{ id: entityId, number }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                deleteHandle={deletePlace}
            />
            <div className="card border mb-3">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col">
                            <h3>Парковочное место</h3>
                        </div>
                    </div>
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="number"
                            label="Номер"
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <button
                                className="btn av-btn-danger fw-boldest"
                                onClick={() => setDeleteModalIsOpen(true)}
                            >
                                <i className="far fa-trash-alt me-2" /> Удалить
                            </button>
                        </div>
                        <div className="col-md-6 text-end">
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PlaceEditPanel;
