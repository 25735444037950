import { useEffect, useState } from 'react';
import { createAsync } from '../../../app/storeReducers/AdminAccountsSlice';
import { useDispatch } from 'react-redux';
import { Http } from '../../../http';

const AccountCreateModal = (props) => {
    const { accountId, isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [hosts, setHosts] = useState([]);
    const [hostSelected, setHostSelected] = useState(-1);
    const [typeSelected, setTypeSelected] = useState(0);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const hostsResult = await Http.get('api/hosts', true);
            setHosts(hostsResult.result);
            if (hostsResult.result.length > 0) {
                setHostSelected(hostsResult.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [dispatch, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            host: { id: hostSelected },
            type: typeSelected,
        };
        dispatch(createAsync(entity));
        setName('');
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить новый аккаунт
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-3'>
                                <label htmlFor='name' className='form-label'>
                                    Название
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='mb-3'>
                                <label className='form-label'>Хост</label>
                                <select
                                    className='form-select'
                                    value={hostSelected}
                                    onChange={(e) =>
                                        setHostSelected(e.target.value)
                                    }
                                >
                                    {hosts.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='mb-3'>
                                <label className='form-label'>Тип</label>
                                <select
                                    className='form-select'
                                    value={typeSelected}
                                    onChange={(e) =>
                                        setTypeSelected(e.target.value)
                                    }
                                >
                                    <option key='basic' value='basic'>
                                        Простой
                                    </option>
                                    <option key='business' value='business'>
                                        Расширенный
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn btn-primary'
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default AccountCreateModal;
