import AccountEditPanel from './AccountEditPanel';

const AccountCardPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <>
            <h1 className='mb-5'>Настройки аккаунта</h1>
            <div className='row'>
                <div className='col-md-6'>
                    <AccountEditPanel accountId={accountId} />
                </div>
            </div>
        </>
    );
};

export default AccountCardPage;
