import { useEffect, useState } from 'react';

const SearchableList = (props) => {
    const { value, list, searchCallback, selectCallback } = props;

    const [searchString, setSearchString] = useState('');
    useEffect(() => {
        if (searchString === '' || searchString.length > 0) {
            if (searchCallback) {
                searchCallback(searchString);
            }
        }
    }, [searchString]);

    const selectValue = (value) => {
        selectCallback(value);
    };

    return (
        <>
            <div className='dropdown-input p-3'>
                <input
                    value={searchString}
                    onChange={(e) => setSearchString(e.target.value)}
                    placeholder={'Поиск'}
                    autoFocus={true}
                    className='form-control'
                />
            </div>
            <div className='dropdown-scroll'>
                {list?.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className={
                                'cursor-pointer p-3' +
                                (item.id === value ? ' active' : '')
                            }
                            onClick={() => selectValue(item.id)}
                        >
                            <span>{item.name}</span>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default SearchableList;
