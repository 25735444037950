import { useEffect, useState } from 'react';
import { useAccount } from '../../../components/useAccount';
import { Http } from '../../../http';
import OutsideClickHandler from '../../../components/OutsideClickHandler';

const FilterAccountSelector = (props) => {
    const { callback, clearTrigger } = props;

    const account = useAccount();

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(0);
    const [accountsList, setAccountsList] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        async function loadResourcesAndData() {
            await loadAccounts();
        }

        loadResourcesAndData();
    }, [account]);

    useEffect(() => {
        function loadResourcesAndData() {
            loadAccounts();
        }

        loadResourcesAndData();
    }, [search]);

    useEffect(() => {
        function loadResourcesAndData() {
            callback(selectedEntity);
        }

        loadResourcesAndData();
    }, [selectedEntity]);

    useEffect(() => {
        if (clearTrigger) {
            setSelectedEntity(0);
        }
    }, [clearTrigger]);

    const loadAccounts = async () => {
        let sAcc = [];
        sAcc.push({ id: account.id, name: account.name });
        if (account.parent === null && account.type !== 'basic') {
            const getResult = await Http.get(
                `api/accounts/${account.id}/subaccounts?sort=name&search=${search}`
            );
            getResult.result.map((item) =>
                sAcc.push({ id: item.id, name: item.name })
            );
        }
        if (search !== '') {
            sAcc = sAcc.filter(
                (x) => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1
            );
        }
        setAccountsList(sAcc);
    };

    const changeSearchHandler = (value) => {
        setSearch(value);
    };

    const changeAccount = (accountId) => {
        setSelectedEntity(accountId);
        setIsOpenMenu(false);
    };

    return (
        <OutsideClickHandler
            classes='dropdown filter-list-block'
            callback={() => setIsOpenMenu(false)}
        >
            <>
                <div
                    className='form-select'
                    onClick={() => setIsOpenMenu(!isOpenMenu)}
                    data-bs-toggle='dropdown'
                >
                    <span className='text-muted'>
                        {accountsList.find((x) => x.id === selectedEntity)
                            ?.name ?? '--Не выбран--'}
                    </span>
                </div>
                <ul
                    className={
                        'dropdown-menu px-4 py-2' + (isOpenMenu ? ' show' : '')
                    }
                >
                    <li>
                        <input
                            type='text'
                            placeholder='поиск'
                            className='form-control mb-2'
                            value={search}
                            onChange={(e) =>
                                changeSearchHandler(e.target.value)
                            }
                            autoFocus
                        />
                    </li>
                    <li
                        className='cursor-pointer'
                        onClick={() => changeAccount(0)}
                    >
                        --Не выбран--
                    </li>
                    {accountsList?.map((item) => (
                        <li
                            className='cursor-pointer'
                            key={item.id}
                            onClick={() => changeAccount(item.id)}
                        >
                            {item.name}
                        </li>
                    ))}
                </ul>
            </>
        </OutsideClickHandler>
    );
};

export default FilterAccountSelector;
