import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import currentUserReducer from './storeReducers/CurrentUserSlice';
import logsReducer from './storeReducers/LogSlice';
import notifynReducer from './storeReducers/NotifySlice';
import accountEmployeesReducer from './storeReducers/AccountEmployeesSlice';
import accountGroupsReducer from './storeReducers/AccountGroupsSlice';
import accountRolesReducer from './storeReducers/AccountRolesSlice';
import accountDevicesReducer from './storeReducers/AccountDevicesSlice';
import accountSchemesReducer from './storeReducers/AccountSchemesSlice';
import accountParkingAreasReducer from './storeReducers/AccountParkingAreasSlice';
import accountVehiclesReducer from './storeReducers/AccountVehiclesSlice';
import accountTariffsReducer from './storeReducers/AccountTariffsSlice';
import accountServicesReducer from './storeReducers/AccountServicesSlice';
import accountSchedulesReducer from './storeReducers/AccountSchedulesSlice';
import accountGuestsReducer from './storeReducers/AccountGuestsSlice';
import accountReducer from './storeReducers/AccountSlice';
import accountSubaccountsReduser from './storeReducers/AccountSubaccountsSlice';
import authReduser from './storeReducers/AuthSlice';
import adminAccountsReduser from './storeReducers/AdminAccountsSlice';
import adminHostsReduser from './storeReducers/AdminHostsSlice';
import adminDevicesReduser from './storeReducers/AdminDevicesSlice';
import adminCardsReduser from './storeReducers/AdminCardsSlice';
import supportReducer from './storeReducers/SupportSlice';
import accountCamerasReducer from './storeReducers/AccountCamerasSlice'
import accountParkingPlacesResucer from './storeReducers/AccountParkingPlacesSlice'
import accountParkingImagesReducer from './storeReducers/AccountParkingImagesSlice'
import breadcrumbsReducer from './storeReducers/AccountBreadcrumbsSlice';
import { AccountMonitorAPI } from './storeReducers/AccountMonitorAPI';
import accountLocationsReduser from './storeReducers/AccountLocationsSlice';
import { baseApi } from './baseApi';
import headerNotificationsReduser from './storeReducers/HeaderNotificationsSlice';

export const store: any = configureStore({
    reducer: {
        counter: counterReducer,
        currentUser: currentUserReducer,
        logs: logsReducer,
        notify: notifynReducer,
        accountEmployees: accountEmployeesReducer,
        accountGroups: accountGroupsReducer,
        accountRoles: accountRolesReducer,
        accountDevices: accountDevicesReducer,
        accountSchemes: accountSchemesReducer,
        accountParkingAreas: accountParkingAreasReducer,
        accountVehicles: accountVehiclesReducer,
        accountTariffs: accountTariffsReducer,
        accountServices: accountServicesReducer,
        accountSchedules: accountSchedulesReducer,
        accountGuests: accountGuestsReducer,
        account: accountReducer,
        accountSubaccounts: accountSubaccountsReduser,
        auth: authReduser,
        adminAccounts: adminAccountsReduser,
        adminDevices: adminDevicesReduser,
        adminHosts: adminHostsReduser,
        adminCards: adminCardsReduser,
        support: supportReducer,
        accountCameras: accountCamerasReducer,
        accountParkingPlaces: accountParkingPlacesResucer,
        accountParkingImages: accountParkingImagesReducer,
        breadcrumbs: breadcrumbsReducer,
        accountLocations: accountLocationsReduser,
        headerNotifications: headerNotificationsReduser,
        [baseApi.reducerPath]: baseApi.reducer
    },
    middleware: (gDM) => gDM().concat(baseApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch