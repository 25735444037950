import { useEffect, useState } from 'react';
import OutsideClickHandler from '../../components/OutsideClickHandler';
import { Http } from '../../http';

const EmployeesDropDownInput = (props) => {
    const { selectEmployee, accountId, groupId } = props;

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [inputValue, setInputValue] = useState('Все');
    const [employees, setEmployees] = useState([]);
    const [findText, setFindText] = useState('');

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const employeeResponse = await Http.get(
                `api/employees?sort=last_name&accountId=${accountId}&groupId=${
                    groupId > 0 ? groupId : ''
                }&search=${findText}`,
                true
            );
            setEmployees(employeeResponse.result);
        }

        loadResourcesAndDataAsync();
    }, [accountId, findText, groupId]);

    const changeEmployee = (empId) => {
        setInputValue(employees.find((x) => x.id === empId).name);
        setMenuIsOpen(false);
        selectEmployee(empId);
    };

    const breakEmployee = () => {
        setInputValue('Все');
        setMenuIsOpen(false);
        setFindText('');
        selectEmployee(0);
    };

    const changeValue = (e) => {
        setInputValue(e.target.value);
        setFindText(e.target.value);
    };

    const onFocus = (e) => {
        setMenuIsOpen(true);
        e.target.select();
    };

    return (
        <OutsideClickHandler
            classes='input-group me-2 mb-3 employees-dropdown'
            callback={() => setMenuIsOpen(false)}
        >
            <>
                <span className='input-group-text'>Сотрудник</span>
                <div className='dropdown'>
                    <input
                        className='form-control'
                        onFocus={onFocus}
                        value={inputValue}
                        onChange={changeValue}
                    />
                    <ul
                        className={
                            'dropdown-menu' + (menuIsOpen ? ' show' : '')
                        }
                    >
                        <li>
                            <button
                                className='dropdown-item'
                                onClick={breakEmployee}
                            >
                                <i>Все</i>
                            </button>
                        </li>
                        {employees?.map((item) => (
                            <li key={item.id}>
                                <button
                                    className='dropdown-item'
                                    onClick={() => changeEmployee(item.id)}
                                >
                                    {item.name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        </OutsideClickHandler>
    );
};

export default EmployeesDropDownInput;
