import { useEffect, useState } from 'react';
import { Http } from '../../../../http';
import SkeletonSelect from '../../../../components/skeleton/SkeletonSelect';

const SchemeAddDeviceModal = (props) => {
    const { accountId, schemeId, isOpen, okHandle, closeHandle } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [deviceSelected, setDeviceSelected] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            setDeviceSelected({});
            const deviceResult = await Http.get(
                `api/devices?sort=name&accountId=${accountId}`
            );
            deviceResult.result.map((item) => {
                item.description = item.name;
                item.name = item.id;
            });
            setDevices(deviceResult.result);
            if (deviceResult.result.length > 0) {
                setDeviceSelected(deviceResult.result[0].id);
            }
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [schemeId]);

    const saveSchemeDevice = async () => {
        const addResult = await Http.put(
            `api/schemes/${schemeId}/devices/${deviceSelected}`
        );

        okHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить устройство в схему
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-4'>
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label='Устройство'
                                    value={deviceSelected}
                                    onChange={(e) =>
                                        setDeviceSelected(e.target.value)
                                    }
                                    itemsArray={devices}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveSchemeDevice}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default SchemeAddDeviceModal;
