import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDevice } from '../../app/storeReducers/AccountDevicesSlice';
import DeviceQrCodeEditModal from './DeviceQrCodeEditModal';

const DeviceQrCodePanel = (props) => {
    const { accountId, deviceId, context } = props;

    const device = useSelector(selectDevice);

    const [editModalIsOpen, setEditModalIsOpen] = useState(false);

    console.log(device);

    return (
        <>
            <DeviceQrCodeEditModal
                isOpen={editModalIsOpen}
                closeHandle={() => setEditModalIsOpen(false)}
            />
            <div className='card border'>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <h3>QR-код</h3>
                        </div>
                    </div>
                    {device?.qrCode !== null ? (
                        <>
                            <div className='row mb-3'>
                                <div className='col-md-6'>
                                    <div className='d-grid gap-2'>
                                        <a
                                            className='btn av-btn-primary'
                                            href={`https://qr.avanguard.pro/label?uid=${device.uid}&qrcode=${device.qrCode}-1&device=${device.name}`}
                                            target='_blank'
                                        >
                                            QR-код на вход
                                        </a>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='d-grid gap-2'>
                                        <a
                                            className='btn av-btn-primary'
                                            href={`https://qr.avanguard.pro/label?uid=${device.uid}&qrcode=${device.qrCode}-2&device=${device.name}`}
                                            target='_blank'
                                        >
                                            QR-код на выход
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='d-grid gap-2'>
                                {context.isRoot ? (
                                    <button
                                        className='btn av-btn-simple'
                                        type='button'
                                        onClick={() => setEditModalIsOpen(true)}
                                    >
                                        Редактировать QR-код
                                    </button>
                                ):''}

                            </div>
                        </>
                    ) : (
                        <>
                            <p>
                                <i>К устройству не привязан QR-код</i>
                            </p>
                            <div className='d-grid gap-2'>
                                {context.isRoot ? (
                                    <button
                                        className='btn av-btn-primary'
                                        type='button'
                                        onClick={() => setEditModalIsOpen(true)}
                                    >
                                        Добавить QR-код
                                    </button>
                                ):''}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default DeviceQrCodePanel;
