import { Link } from 'react-router-dom';

const LinkWidget1 = (props) => {
    const { 
        url,
        className,
        color,
        iconClass,
        title,
        description } = props;


    return (
        <Link to={url} className={`card bg-${color} hoverable ${className}`}>
            {/* begin::Body */}
            <div className='card-body'>
            <i className={iconClass}></i>
    
            <div className={`text-inverse-${color} fw-bolder fs-2 mb-2 mt-5`}>{title}</div>
    
            <div className={`fw-bold text-inverse-${color} fs-7`}>{description}</div>
            </div>
            {/* end::Body */}
        </Link>
      );
};

export default LinkWidget1;

