import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLazyGetSchedulesQuery } from "../../app/storeReducers/SchedulesAPI";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import Paginator from "../../components/Paginator";
import { useHistory } from "react-router-dom";
import ScheduleCreateModal from "./ScheduleCreateModal";
import { selectContext } from "../../app/storeReducers/AuthSlice";

const SchedulesList = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [getList, { data, isLoading }] = useLazyGetSchedulesQuery();

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const context = useSelector(selectContext);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("schemes_edit"));
    }, [context, accountId]);

    useEffect(() => {
        async function loadDataAsync() {
            await getList({
                accountId,
                sort: "name",
                pageSize,
                pageIndex,
            });
        }
        loadDataAsync();
    }, [accountId, pageSize, pageIndex]);

    const openEntityCard = (entityId) => {
        history.push(`/account/${accountId}/admin/schedules/${entityId}`);
    };

    const openCreateModal = (entityId) => {
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    return (
        <>
            <ScheduleCreateModal
                accountId={accountId}
                entity={{ id: 0 }}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />

            <div className="row">
                <div className="col-md-4 mb-3">
                    <button
                        className="btn av-btn-primary fw-boldest"
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className="fas fa-plus me-3" />
                        Новое расписание
                    </button>
                </div>
            </div>
            <div className="card border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={["Название", ""]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <th className="ps-6 border-bottom">
                                        Название
                                    </th>
                                    <th className="ps-6 border-bottom">
                                        Описание
                                    </th>
                                    <th className="ps-6 border-bottom">
                                        Статус
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className="cursor-pointer"
                                        style={{ verticalAlign: "middle" }}
                                    >
                                        <td className="ps-6">{item.name}</td>
                                        <td className="ps-6">
                                            {item?.description}
                                        </td>
                                        <td className="ps-6">
                                            {item.isActive ? (
                                                <span
                                                    className="badge bg-success"
                                                    style={{ width: 100 }}
                                                >
                                                    Включено
                                                </span>
                                            ) : (
                                                <span
                                                    className="badge bg-secondary text-dark"
                                                    style={{ width: 100 }}
                                                >
                                                    Выключено
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className="card-body py-3">
                    <Paginator
                        onPageChange={(e) => setPageIndex(e)}
                        onPageSizeChange={(e) => setPageSize(e)}
                        pageCount={data?.paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={data?.result?.length ?? 0}
                        countTotal={data?.paginator?.totalCount ?? 0}
                    />
                </div>
            </div>
        </>
    );
};

export default SchedulesList;
