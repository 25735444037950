import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notifications: {},
};

export const headerNotificationsSlice = createSlice({
    name: 'headerNotifications',
    initialState,

    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
    },
});

export const { setNotifications } = headerNotificationsSlice.actions;

export const selectHeaderNotifications = (state) =>
    state.headerNotifications.notifications;

export default headerNotificationsSlice.reducer;
