import { useEffect, useState } from 'react';
import { Http } from '../../../../http';
import SkeletonSelect from '../../../../components/skeleton/SkeletonSelect';

const GroupAddSchemeModal = (props) => {
    const { accountId, groupId, isOpen, okHandle, closeHandle } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [schemes, setSchemes] = useState([]);
    const [schemeSelected, setSchemeSelected] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            setSchemeSelected({});
            const schemesResult = await Http.get(
                `api/schemes?accountId=${accountId}&sort=name`
            );
            schemesResult.result.map((item) => {
                item.description = item.name;
                item.name = item.id;
            });
            setSchemes(
                schemesResult.result.filter((item) => item.type !== 'guest')
            );
            if (schemesResult.result.length > 0) {
                setSchemeSelected(schemesResult.result[0].id);
            }
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [groupId]);

    const saveMemberScheme = async () => {
        const member = { isGroup: true, info: { id: groupId } };
        const addResult = await Http.put(
            `api/schemes/${schemeSelected}/members`,
            true,
            member
        );

        okHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить схему группе
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-4'>
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label='Роль'
                                    value={schemeSelected}
                                    onChange={(e) =>
                                        setSchemeSelected(e.target.value)
                                    }
                                    itemsArray={schemes}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveMemberScheme}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default GroupAddSchemeModal;
