import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RolesArray from './RolesArray';
import RoleCreateModal from './RoleCreateModal';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { useHistory } from 'react-router-dom';
import { selectContext } from '../../app/storeReducers/AuthSlice';
import { useGetRolesQuery } from '../../app/storeReducers/rolesApiSlice';

const RolesList = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const context = useSelector(selectContext);

    const { data: entityList, isLoading } = useGetRolesQuery({ accountId }, { skip: !accountId });

    useEffect(() => {
        const actions = context?.permissions?.find((x) => x.id === parseInt(accountId))?.actions;
        setCanEdit(context.isRoot || actions?.includes('roles_edit'));
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey) window.open(`/account/${accountId}/admin/roles/${entityId}`, '_blank');
        else history.push(`/account/${accountId}/admin/roles/${entityId}`);
    };

    function GetStatusIcon(props) {
        const isReadonly = props.readonly;
        const isLinked = props.linked;
        if (isReadonly) {
            return (
                <span data-toggle='tooltip' data-placement='top' title='Эту роль нельзя редактировать!'>
                    &nbsp;<i className='fas fa-lock'></i>
                </span>
            );
        } else if (isLinked) {
            return (
                <span data-toggle='tooltip' data-placement='top' title='Связанная роль. Эту роль нельзя редактировать!'>
                    &nbsp;<i className='fas fa-link'></i>
                </span>
            );
        }
        return '';
    }

    return (
        <>
            <RoleCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className='row'>
                <div className='col mb-3'>
                    <button className='btn av-btn-primary' onClick={() => openCreateModal(0)} disabled={!canEdit}>
                        <i className='fas fa-plus' /> Новая роль
                    </button>
                </div>
            </div>
            <div className='card border'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Название', 'Действия']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>Название</th>
                                    <th className='border-bottom'>Действия</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) => openEntityCard(item.id, e)}
                                        className='cursor-pointer'
                                    >
                                        <td className='ps-6'>
                                            {item.name}
                                            <GetStatusIcon readonly={item.readonly} linked={item.linked} />
                                        </td>
                                        <td>
                                            {item.actions
                                                .slice(0, 2)
                                                .map((itemA) => {
                                                    return RolesArray.find((x) => x.name === itemA)?.description;
                                                })
                                                .join(', ')}
                                            {item.actions.length > 2 ? (
                                                <i>
                                                    {'... '}и ещё {item.actions.length - 2}
                                                </i>
                                            ) : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default RolesList;
