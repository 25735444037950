import { baseApi } from "../baseApi";

export const parkingPlacesApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getParkingPlaces: builder.query({
            query: (areaId) => `parking/places?areaId=${areaId}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "ParkingPlaces",
                              id,
                          })),
                          { type: "ParkingPlaces", id: "List" },
                      ]
                    : [{ type: "ParkingPlaces", id: "List" }],
        }),
        getParkingPlaceById: builder.query({
            query: (id) => `parking/places/${id}`,
            providesTags: (result) => [
                { type: "ParkingPlace", id: result?.result.id },
            ],
        }),
        createParkingPlace: builder.mutation({
            query: (body) => ({
                url: "parking/places",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "ParkingPlaces", id: "List" }],
        }),
        updateParkingPlace: builder.mutation({
            query: (body) => ({
                url: "parking/places/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "ParkingPlaces", id: "List" },
                { type: "ParkingPlace", id: result?.result.id },
            ],
        }),
        deleteParkingPlace: builder.mutation({
            query: (id) => ({
                url: "parking/places/" + id,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "ParkingPlaces", id: "List" }],
        }),
    }),
});

export const {
    useGetParkingPlacesQuery,
    useLazyGetParkingPlacesQuery,
    useGetParkingPlaceByIdQuery,
    useLazyGetParkingPlaceByIdQuery,
    useCreateParkingPlaceMutation,
    useUpdateParkingPlaceMutation,
    useDeleteParkingPlaceMutation,
} = parkingPlacesApiSlice;
