const notifyTypes = [
    { id: 'device_up', title: 'Устройство включилось' },
    { id: 'device_down', title: 'Устройство отключилось' },
    { id: 'ticket_new', title: 'Новая заявка в техподдержке' },
    { id: 'ticket_reply', title: 'Новый ответ в техподдержке' },
    { id: 'ticket_complete', title: 'Закрытие заявки в техподдержке' },


    { id: 'ticket_escalation', title: 'Эскалация заявки' },
    { id: 'host_up', title: 'Отключение сервера' },
    { id: 'host_down', title: 'Включение сервера' },
    { id: 'simple', title: 'Уведомление системы' },
];
export default notifyTypes;
