import { useEffect, useState } from "react";
import { useLazyGetScreenshotQuery } from "../../app/storeReducers/screenshotsApiSlice";

const ScreenView = (props) => {
    const { screenId, isOpen, closeHandle } = props;

    const [logs, setLogs] = useState([]);

    const [getScreenshot] = useLazyGetScreenshotQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (screenId) {
                const getScreens = await getScreenshot(screenId).unwrap();
                const screens = getScreens?.result.map((item) => ({
                    ...item,
                    data: "data:image/jpg;base64," + item.data,
                }));

                setLogs(screens);
            } else {
                setLogs([]);
            }
        }

        loadResourcesAndDataAsync();
    }, [screenId]);

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Изображение с камеры
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div
                            className="modal-body"
                            style={{ maxHeight: 500, overflowY: "scroll" }}
                        >
                            {logs.map((item, index) => (
                                <div className="mb-4 text-center" key={index}>
                                    <a download="picture.jpg" href={item.data}>
                                        <img
                                            className="img-fluid"
                                            src={item.data}
                                            style={{
                                                maxHeight: 400,
                                            }}
                                            alt="screen view"
                                        />
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default ScreenView;
