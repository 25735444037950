import { baseApi } from '../baseApi';

export const AccountBlacklistAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getBlacklists: builder.query({
            query: (data) =>
                `blacklist?accountId=${data.accountId}&category=${data.category}&pageIndex=${data.pageIndex}&pageSize=${data.pageSize}&search=${data.search}&sort=${data.sort}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result?.result?.map(({ id }) => ({
                              type: 'Blacklists',
                              id,
                          })),
                          { type: 'Blacklists', id: 'List' },
                      ]
                    : [{ type: 'Blacklists', id: 'List' }],
        }),
        getBlackListById: builder.query({
            query: (entityId) => `blacklist/${entityId}`,
        }),
        getBlackListDetails: builder.query({
            query: (entityId) => `blacklist/${entityId}/details`,
        }),
        addBlock: builder.mutation({
            query: (body) => ({
                url: 'blacklist',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Blacklists', id: 'List' }],
        }),
        goToBlock: builder.mutation({
            query: (data) => ({
                url: `blacklist/${data.id}/block`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: [{ type: 'Blacklists', id: 'List' }],
        }),
        amnesty: builder.mutation({
            query: (data) => ({
                url: `blacklist/${data.id}/amnesty`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: [{ type: 'Blacklists', id: 'List' }],
        }),
        updateBlock: builder.mutation({
            query: (body) => ({
                url: 'blacklist/' + body.id,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'Blacklists', id: 'List' }],
        }),
    }),
});

export const {
    useLazyGetBlacklistsQuery,
    useGetBlackListByIdQuery,
    useLazyGetBlackListByIdQuery,
    useLazyGetBlackListDetailsQuery,
    useAddBlockMutation,
    useAmnestyMutation,
    useGoToBlockMutation,
    useUpdateBlockMutation,
} = AccountBlacklistAPI;
