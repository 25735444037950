import { useEffect, useState } from 'react';
import PermissionPage from '../../../components/PermissionPage';
import { Http } from '../../../http';
import SchemeDevicesPanel from './device/SchemeDevicesPanel';
import SchemeEmployeesPanel from './employee/SchemeEmployeesPanel';
import SchemeEditPanel from './SchemeEditPanel';
import SchemeSharesPanel from './shared/SchemeSharesPanel';

const SchemeCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const schemeId = props.match.params.schemeId;

    const [isSubaccount, setIsSubaccount] = useState(true);
    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const getResult = await Http.get('api/schemes/' + schemeId, true);
            console.log(getResult);
            setIsSubaccount(
                parseInt(accountId) !== getResult.result?.account?.id
            );
            const accResult = await Http.get('api/accounts/' + getResult.result?.account?.id, true);
            
            // если открыли в расширенном аккаунте схему этого аккаунта, то могем шарить
            if (accResult.result.type === 'business' && parseInt(accountId) === getResult.result?.account?.id){
                setCanShare(true);                
            } else if (accResult.result.type === 'subaccount' && parseInt(accountId) === getResult.result?.account?.id){
                // если в субаккаунте открыли локальную схему
                setCanShare(true);
            }
            console.log(canShare);
        }

        loadResourcesAndDataAsync();
    }, [schemeId]);

    return (
        <PermissionPage accountId={accountId} roles={['schemes_list']}>
            <div className='row'>
                <div className='col-6'>
                    <SchemeEditPanel
                        accountId={accountId}
                        entityId={schemeId}
                    />
                </div>
                <div className='col-6'>
                    <div className='row mb-4'>
                        <div className='col'>
                            <SchemeEmployeesPanel
                                accountId={accountId}
                                entityId={schemeId}
                                isSubaccount={isSubaccount}
                            />
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <SchemeDevicesPanel
                                accountId={accountId}
                                entityId={schemeId}
                                isSubaccount={isSubaccount}
                            />
                        </div>
                    </div>
                    {canShare ? (
                        <div className='row'>
                            <div className='col'>
                                <SchemeSharesPanel
                                    accountId={accountId}
                                    entityId={schemeId}
                                    isSubaccount={isSubaccount}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </PermissionPage>
    );
};

export default SchemeCardPage;
