import { useEffect, useState } from "react";
import { KTSVG } from "../../_metronic/helpers";
import DeviceSchemeCreateModal from "./DeviceSchemeCreateModal";
import DeviceSchemeDeleteModal from "./DeviceSchemeDeleteModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import {
    useAddSchemeDeviceMutation,
    useGetSchemesQuery,
    useRemoveSchemeDeviceMutation,
} from "../../app/storeReducers/schemesApiSlice";

const DeviceSchemesPanel = (props) => {
    const { accountId, deviceId } = props;

    const context = useSelector(selectContext);

    const { data: schemes, refetch } = useGetSchemesQuery(
        { accountId, deviceId },
        { skip: !deviceId }
    );
    const [addSchemeDevice] = useAddSchemeDeviceMutation();
    const [removeSchemeDevice] = useRemoveSchemeDeviceMutation();

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("schemes_edit"));
        setCanList(context.isRoot || actions?.includes("schemes_list"));
    }, [context, accountId]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedScheme, setDeletedScheme] = useState([]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const openDeleteModal = (scheme) => {
        setDeletedScheme(scheme);
        setDeleteModalIsOpen(true);
    };

    const deleteScheme = async () => {
        await removeSchemeDevice({
            schemeId: deletedScheme.id,
            deviceId,
        }).unwrap();
        refetch();
        setDeleteModalIsOpen(false);
    };

    const createScheme = async (scheme) => {
        await addSchemeDevice({ schemeId: scheme.id, deviceId }).unwrap();
        refetch();
        setCreateModalIsOpen(false);
    };

    return (
        <>
            <DeviceSchemeDeleteModal
                entity={deletedScheme}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteScheme}
            />
            <DeviceSchemeCreateModal
                accountId={accountId}
                isOpen={createModalIsOpen}
                closeHandle={() => setCreateModalIsOpen(false)}
                okHandle={createScheme}
            />
            <div className="row mb-2">
                <div className="col-md-6">
                    <h3>
                        Схемы{" "}
                        <span className="badge rounded-pill bg-light text-dark">
                            {schemes?.result?.length}
                        </span>
                    </h3>
                </div>
                <div className="col-md-6 text-end">
                    {canEdit ? (
                        <button
                            className="btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0"
                            onClick={() => setCreateModalIsOpen(true)}
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr075.svg"
                                className="svg-icon-2 me-2"
                            />
                            Добавить схему
                        </button>
                    ) : null}
                </div>
            </div>
            <div className="row mb-5">
                <div className="col">
                    {canList ? (
                        schemes?.result?.map((item) => (
                            <div
                                className="border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2"
                                key={item.id}
                            >
                                {item.name}{" "}
                                {canEdit ? (
                                    <i
                                        className="ms-2 fas fa-times cursor-pointer"
                                        onClick={() => openDeleteModal(item)}
                                    ></i>
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                </div>
            </div>
        </>
    );
};

export default DeviceSchemesPanel;
