import { useContext, useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import {
    useDownMonitorDeviceMutation,
    useGetMonitorDevicesQuery,
    useUpMonitorDeviceMutation,
} from '../../../../app/storeReducers/AccountMonitorAPI';
import MonitorDeviceDeleteModal from './MonitorDeviceDeleteModal';
import MonitorAddDeviceModal from './MonitorAddDeviceModal';
import SkeletonTable from '../../../../components/skeleton/SkeletonTable';
import SecutiryContext from '../../../../features/securityContext';

const MonitorDevicesPanel = (props) => {
    const { accountId, entityId } = props;

    const { data, isLoading } = useGetMonitorDevicesQuery(entityId);
    let sortData = [];
    if (data?.result?.length > 0) {
        sortData = [...data?.result];
    }
    sortData?.sort((a, b) => parseInt(a.orderIndex) - parseInt(b.orderIndex));

    const [upDevice] = useUpMonitorDeviceMutation();
    const [downDevice] = useDownMonitorDeviceMutation();

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('monitor_edit'));
        setCanList(context.isRoot || actions?.includes('monitor_list'));
    }, [context, accountId]);

    const okAddDevice = () => {
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteDevice = () => {
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <MonitorAddDeviceModal
                accountId={accountId}
                monitorId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddDevice}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <MonitorDeviceDeleteModal
                monitorId={entityId}
                device={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteDevice}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <h3>
                                Устройства{' '}
                                <span className='badge rounded-pill bg-light text-dark'>
                                    {data?.result?.length}
                                </span>
                            </h3>
                        </div>
                        <div className='col-md-6 text-end'>
                            {canEdit ? (
                                <button
                                    className='btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0'
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr075.svg'
                                        className='svg-icon-2 me-2'
                                    />
                                    Добавить устройство
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>

                {canList ? (
                    <SkeletonTable
                        cols={['Индекс', 'Название', 'UID']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Индекс
                                    </th>
                                    <th className='border-bottom'>Название</th>
                                    <th className='border-bottom'>UID</th>
                                    <th className='border-bottom'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortData?.map((item) => (
                                    <tr key={item.id}>
                                        <td className='ps-6'>
                                            <button
                                                className='btn btn-sm'
                                                onClick={() =>
                                                    upDevice({
                                                        monitorId: entityId,
                                                        deviceId:
                                                            item?.device?.id,
                                                    })
                                                }
                                            >
                                                <i className='fas fa-chevron-up' />
                                            </button>
                                            <div
                                                className='d-inline-block text-center'
                                                style={{ width: 20 }}
                                            >
                                                {item.orderIndex}
                                            </div>
                                            <button
                                                className='btn btn-sm'
                                                onClick={() =>
                                                    downDevice({
                                                        monitorId: entityId,
                                                        deviceId:
                                                            item?.device?.id,
                                                    })
                                                }
                                            >
                                                <i className='fas fa-chevron-down' />
                                            </button>
                                        </td>
                                        <td>{item.device.title}</td>
                                        <td>{item.device.uid}</td>
                                        <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'></div>

                                            {canEdit ? (
                                                <div className='d-flex justify-content-end flex-shrink-0'>
                                                    <button
                                                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                        onClick={() =>
                                                            openDeleteModal(
                                                                item.device
                                                            )
                                                        }
                                                    >
                                                        <i className='fas fa-trash-alt' />
                                                    </button>
                                                </div>
                                            ) : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                ) : (
                    <div className='card-body'>
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default MonitorDevicesPanel;
