import { useState } from 'react';
import { useCreateHostMutation } from '../../../app/storeReducers/hostsApiSlice';

const HostCreateModal = (props) => {
    const { isOpen, closeHandle } = props;

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [port, setPort] = useState('');
    const [apiKey, setApiKey] = useState('');

    const [createHost] = useCreateHostMutation();

    const saveEntity = async () => {
        const entity = {
            name,
            address,
            port,
            apiKey,
        };
        createHost(entity);
        setName('');
        setAddress('');
        setPort('');
        setApiKey('');
        closeHandle();
    };

    return (
        <>
            <div className={'modal fade' + (isOpen ? ' show' : '')} tabIndex='-1'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Добавить новый хост</h5>
                            <button type='button' className='btn-close' onClick={closeHandle}></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-3'>
                                <label htmlFor='name' className='form-label'>
                                    Название
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-9'>
                                    <label htmlFor='address' className='form-label'>
                                        Адрес
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='address'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                                <div className='col-md-3'>
                                    <label htmlFor='port' className='form-label'>
                                        Порт
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        id='port'
                                        value={port}
                                        onChange={(e) => setPort(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <label htmlFor='apiKey' className='form-label'>
                                    Ключ API
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='apiKey'
                                    value={apiKey}
                                    onChange={(e) => setApiKey(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-secondary' onClick={closeHandle}>
                                Закрыть
                            </button>
                            <button type='button' className='btn btn-primary' onClick={saveEntity}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default HostCreateModal;
