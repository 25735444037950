import { useEffect, useState } from 'react';
import { Http } from '../../../http';

const AvatarView = (props) => {
    const { employeeId } = props;

    const [imageSource, setImageSource] = useState('/images/no-user.jpg');

    useEffect(async () => {
        console.log('employeeId:' + employeeId);
        if (parseInt(employeeId, 10) > 0) {
            console.log('request image');
            const response = await Http.get(
                `api/employees/${employeeId}/photo`
            );
            console.log('responseResult', response);
            if (response.result !== undefined) {
                if (response.result.image !== undefined) {
                    setImageSource(
                        'data:image/jpg;base64,' + response.result.image
                    );
                }
                else {
                    setImageSource('/images/no-user.jpg');
                }
            }
            else {
                setImageSource('/images/no-user.jpg');
            }
        }
    }, [employeeId]);

    return (
        <>
            <div style={{ textAlign: 'center' }} className='mb-6'>
                <img
                    alt='Logo'
                    src={imageSource}
                    style={{
                        maxWidth: '200px',
                        maxHeight: '200px',
                        textAlign: 'center',
                    }}
                />
            </div>
        </>
    );
};

export default AvatarView;
