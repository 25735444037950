import { CRUDReducer } from './CRUDReducer';

export const baseReducer = new CRUDReducer('adminDevices', 'devices');

export const { getListAsync } =
    baseReducer;

export const adminDevicesSlice = baseReducer.reducerSlice;

export const { setPageSize, selectPage } = adminDevicesSlice.actions;

export const selectList = (state) => state.adminDevices.objectsArray;
export const selectIsLoading = (state) => state.adminDevices.isLoading;
export const selectPaginator = (state) => state.adminDevices.paginator;

export default adminDevicesSlice.reducer;
