import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const cardsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCards: builder.query({
            query: (data) =>
                `cards?userId=${data?.userId ?? ""}&employeeId=${
                    data?.employeeId ?? ""
                }&vehicleId=${data?.vehicleId ?? ""}&accountId=${
                    data?.accountId ?? ""
                }` +
                `&sort=${data?.sort ?? ""}&login=${
                    data?.login ?? ""
                }&pageIndex=${data?.pageIndex ?? 1}&pageSize=${
                    data?.pageSize ?? 100
                }&number=${data?.number ?? ""}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result?.result?.map(({ id }) => ({
                              type: "Cards",
                              id,
                          })),
                          { type: "Cards", id: "List" },
                      ]
                    : [{ type: "Cards", id: "List" }],
        }),
        getCardById: builder.query({
            query: (id) => `cards/${id}`,
            providesTags: (result) => [{ type: "Card", id: result.result.id }],
        }),
        createCard: builder.mutation({
            query: (body) => ({
                url: "cards",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Cards", id: "List" }],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка добавления!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось добавить карту! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Карта добавлена!",
                                description: "Карта успешно добавлена!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка добавления!",
                            description:
                                "Не удалось добавить карту! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        updateCard: builder.mutation({
            query: (body) => ({
                url: "cards/" + body.id,
                method: "PUT",
                body: { ...body },
            }),
            invalidatesTags: (result) => [
                { type: "Cards", id: "List" },
                { type: "Card", id: result?.result.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить данные карты! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Карта сохранена!",
                                description: "Данные карты успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить данные карты! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteCard: builder.mutation({
            query: (id) => ({
                url: "cards/" + id,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Cards", id: "List" }],
        }),
    }),
});

export const {
    useGetCardsQuery,
    useGetCardByIdQuery,
    useLazyGetCardByIdQuery,
    useCreateCardMutation,
    useUpdateCardMutation,
    useDeleteCardMutation,
} = cardsApiSlice;
