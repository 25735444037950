import { useState } from "react";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { DateToString, UtcToLocal } from "../../helpers/DateHelpers";
import ServicesTypeArray from "./ServicesTypeArray";
import Paginator from "../../components/Paginator";
import { useGetServicesQuery } from "../../app/storeReducers/servicesApiSlice";

const ServicesList = (props) => {
    const { accountId } = props;

    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);

    const { data: entityList, isLoading } = useGetServicesQuery({
        accountId,
        pageIndex,
        pageSize,
    });

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
    };

    return (
        <div className="card">
            <div className="table-responsive">
                <SkeletonTable
                    cols={[
                        "Аккаунт",
                        "Ресурс",
                        "Тариф",
                        "Дата начала",
                        "Дата окончания",
                    ]}
                    rowsCount={15}
                    isLoading={isLoading}
                    classTable="table-striped"
                >
                    <table className="table mb-0">
                        <thead>
                            <tr className="fw-bold av-text-gray-500 border-bottom">
                                <th className="border-bottom ps-6">Аккаунт</th>
                                <th className="border-bottom">Ресурс</th>
                                <th className="border-bottom">Тариф</th>
                                <th className="border-bottom">Дата начала</th>
                                <th className="border-bottom">
                                    Дата окончания
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {entityList?.result?.map((item) => (
                                <tr key={item.id}>
                                    <td className="ps-6">
                                        {item?.consumer?.title}
                                    </td>
                                    <td>
                                        {item.resource != null
                                            ? ServicesTypeArray.find(
                                                  (x) =>
                                                      x.name ===
                                                      item.resource.type
                                              ).description
                                            : ""}
                                        : {item.resource?.name}
                                    </td>
                                    <td>{item?.tariff?.title}</td>
                                    <td>
                                        {item.dateStart !== null
                                            ? DateToString(
                                                  UtcToLocal(item.dateStart)
                                              )
                                            : ""}
                                    </td>
                                    <td>
                                        {item.dateFinish !== null
                                            ? DateToString(
                                                  UtcToLocal(item.dateFinish)
                                              )
                                            : " "}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </SkeletonTable>

                <div className="card-body py-3 px-5">
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={entityList?.paginator?.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList?.result?.length}
                        countTotal={entityList?.paginator?.totalCount}
                    />
                </div>
            </div>
        </div>
    );
};

export default ServicesList;
