const AccountWidget = (props) => {
    const { accountCountTotal, accountCountOnline, accountCountOffline } =
        props;

    return (
        <div className='card border widget-card mb-6'>
            <div className='card-body p-6'>
                <div className='widget-title mb-3'>
                    <div className='widget-icon me-4'>
                        <img
                            className='img-fluid'
                            src='/media/icons/avanguard/JustUser-white.png'
                        />
                    </div>
                    Аккаунты
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {accountCountTotal}
                    </div>
                    <div className='widget-count-title'>Всего</div>
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {accountCountOnline}
                    </div>
                    <div className='widget-count-title'>Активных</div>
                </div>
                <div className='widget-count-block d-inline-block me-5 text-danger'>
                    <div className='widget-count-number'>
                        {accountCountOffline}
                    </div>
                    <div className='widget-count-title'>Заблокированно</div>
                </div>
            </div>
        </div>
    );
};

export default AccountWidget;
