import { useEffect, useState } from 'react';
import DeviceProvideModal from './DeviceProvideModal';
import DeviceReturnModal from './DeviceReturnModal';
import { useGetDeviceByIdQuery } from '../../../app/storeReducers/devicesApiSlice';

const DeviceControlPanel = (props) => {
    const { accountId, deviceId } = props;

    const {data: device} = useGetDeviceByIdQuery(deviceId, {skip: !deviceId});

    const [isOpenProvideModal, setIsOpenProvideModal] = useState(false);
    const [isOpenReturnModal, setIsOpenReturnModal] = useState(false);
    const [isShared, setIsShared] = useState(false);
    const [sharedAccount, setSharedAccount] = useState('');

    const providedDevice = (accountName) => {
        setIsOpenProvideModal(false);
        setIsShared(true);
        setSharedAccount(accountName);
    };

    const returnedDevice = () => {
        setIsOpenReturnModal(false);
        setIsShared(false);
    };

    useEffect(() => {
        function loadResourcesAndData() {
            setIsShared(device?.result?.account?.id !== device?.result?.holder?.id);
            setSharedAccount(device?.result?.holder?.title);
        }

        loadResourcesAndData();
    }, [device]);

    return (
        <>
            <DeviceProvideModal
                accountId={accountId}
                deviceId={deviceId}
                isOpen={isOpenProvideModal}
                okHandle={(accountName) => providedDevice(accountName)}
                closeHandle={() => setIsOpenProvideModal(false)}
            />
            <DeviceReturnModal
                deviceName={device?.result?.name}
                deviceId={deviceId}
                isOpen={isOpenReturnModal}
                okHandle={returnedDevice}
                closeHandle={() => setIsOpenReturnModal(false)}
            />
            <div className='card border'>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <h3>Управление</h3>
                        </div>
                    </div>
                    {isShared ? (
                        <>
                            <p>
                                Устройство под управлением аккаунта{' '}
                                <b>{sharedAccount}</b>
                            </p>
                            <div className='d-grid gap-2'>
                                <button
                                    className='btn av-btn-simple'
                                    type='button'
                                    onClick={() => setIsOpenReturnModal(true)}
                                >
                                    Вернуть устройство
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>
                                <i>
                                    Устройство под управлением текущего акаунта
                                </i>
                            </p>
                            <div className='d-grid gap-2'>
                                <button
                                    className='btn av-btn-simple'
                                    type='button'
                                    onClick={() => setIsOpenProvideModal(true)}
                                >
                                    Передать устройство
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default DeviceControlPanel;
