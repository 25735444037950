const DeviceReaderArray = [
    {
        id: 0,
        description: 'Wiegand 26',
        name: 'wiegand26',
    },
    {
        id: 1,
        description: 'Wiegand 34',
        name: 'wiegand34',
    },
];

export default DeviceReaderArray;
