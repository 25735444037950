import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import currentUserReducer from './storeReducers/CurrentUserSlice';
import notifynReducer from './storeReducers/NotifySlice';
import authReduser from './storeReducers/AuthSlice';
import breadcrumbsReducer from './storeReducers/AccountBreadcrumbsSlice';
import { baseApi } from './baseApi';
import headerNotificationsReduser from './storeReducers/HeaderNotificationsSlice';

export const store: any = configureStore({
    reducer: {
        counter: counterReducer,
        currentUser: currentUserReducer,
        notify: notifynReducer,
        auth: authReduser,
        breadcrumbs: breadcrumbsReducer,
        headerNotifications: headerNotificationsReduser,
        [baseApi.reducerPath]: baseApi.reducer
    },
    middleware: (gDM) => gDM().concat(baseApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch