import HostsList from './HostsList';

const HostsPage = () => {
    return (
        <>
            <div className='row mt-4 mb-4'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Серверы</h1>
                    <HostsList />
                </div>
            </div>
        </>
    );
};

export default HostsPage;
