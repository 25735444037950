import { useEffect, useState } from 'react';
import { Http } from '../../http';
import channelTypes from './channelTypes';
import EditChannelModal from './EditChannelModal';
import notifyTypes from './notifyTypes';

const EventNotifyComponent = (props) => {
    const { accountId } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [settings, setSettings] = useState([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [typeUpdated, setTypeUpdated] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            console.log('accId', accountId);
            const getResult = await Http.get(
                'api/notifications/settings?accountId=' + accountId,
                true
            );
            setSettings(getResult);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    const onOffNotify = async (type) => {
        const typeUpdate = settings.find((x) => x.type === type);
        typeUpdate.active = !typeUpdate.active;
        updateNotify(typeUpdate);
    };

    const channelsUpdate = (channels) => {
        setIsEditModalOpen(false);
        typeUpdated.channels = channels;
        updateNotify(typeUpdated);
    };

    const updateNotify = async (notify) => {
        await Http.post('api/notifications/settings', true, notify);
        setSettings(
            settings.map((item) => {
                if (item.type === notify.type) {
                    item = notify;
                }
                return item;
            })
        );
    };

    return (
        <>
            <EditChannelModal
                isOpen={isEditModalOpen}
                closeHandle={() => setIsEditModalOpen(false)}
                okHandle={(channels) => channelsUpdate(channels)}
                titleType={
                    notifyTypes.find((x) => x.id === typeUpdated.type)?.title
                }
                channelsChanged={typeUpdated.channels}
            />
            <table className='table mb-0'>
                <thead>
                    <tr className='fw-bold av-text-gray-500 border-bottom'>
                        <th className='border-bottom ps-6'>Тип уведомления</th>
                        <th className='border-bottom'>Канал</th>
                        <th className='border-bottom'>Состояние</th>
                    </tr>
                </thead>
                <tbody>
                    {settings?.map((item) => (
                        <tr key={item.type}>
                            <td className='ps-6'>
                                {
                                    notifyTypes.find((x) => x.id === item.type)
                                        .title
                                }
                            </td>
                            <td className='position-relative'>
                                {item.channels
                                    .map(
                                        (chn) =>
                                            channelTypes.find(
                                                (x) => x.id === chn
                                            ).title
                                    )
                                    .join(', ')}
                                <i
                                    className='fas fa-pen td-edit'
                                    onClick={() => {
                                        setIsEditModalOpen(true);
                                        setTypeUpdated(item);
                                    }}
                                />
                            </td>
                            <td>
                                <div className='form-check form-switch form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        value=''
                                        id='flexSwitchDefault'
                                        checked={item.active}
                                        onChange={() => onOffNotify(item.type)}
                                    />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

export default EventNotifyComponent;
