import { useEffect, useState } from "react";
import PermissionPage from "../../../components/PermissionPage";
import SchemeDevicesPanel from "./device/SchemeDevicesPanel";
import SchemeEmployeesPanel from "./employee/SchemeEmployeesPanel";
import SchemeEditPanel from "./SchemeEditPanel";
import SchemeSharesPanel from "./shared/SchemeSharesPanel";
import { useGetSchemeByIdQuery } from "../../../app/storeReducers/schemesApiSlice";
import { useGetAccountByIdQuery } from "../../../app/storeReducers/acountsApiSlice";

const SchemeCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const schemeId = props.match.params.schemeId;

    const [isSubaccount, setIsSubaccount] = useState(true);
    const [canShare, setCanShare] = useState(false);

    const { data: scheme } = useGetSchemeByIdQuery(schemeId);
    const { data: account } = useGetAccountByIdQuery(
        scheme?.result?.account?.id,
        { skip: !scheme?.result?.account?.id }
    );

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (scheme && account) {
                setIsSubaccount(
                    parseInt(accountId) !== scheme.result?.account?.id
                );

                // если открыли в расширенном аккаунте схему этого аккаунта, то могем шарить
                if (
                    account.result.type === "business" &&
                    parseInt(accountId) === scheme.result?.account?.id
                ) {
                    setCanShare(true);
                } else if (
                    account.result.type === "subaccount" &&
                    parseInt(accountId) === scheme.result?.account?.id
                ) {
                    // если в субаккаунте открыли локальную схему
                    setCanShare(true);
                }
            }
        }

        loadResourcesAndDataAsync();
    }, [scheme, account]);

    return (
        <PermissionPage accountId={accountId} roles={["schemes_list"]}>
            <div className="row">
                <div className="col-6">
                    <SchemeEditPanel
                        accountId={accountId}
                        entityId={schemeId}
                    />
                </div>
                <div className="col-6">
                    <div className="row mb-4">
                        <div className="col">
                            <SchemeEmployeesPanel
                                accountId={accountId}
                                entityId={schemeId}
                                isSubaccount={isSubaccount}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col">
                            <SchemeDevicesPanel
                                accountId={accountId}
                                entityId={schemeId}
                                isSubaccount={isSubaccount}
                            />
                        </div>
                    </div>
                    {canShare ? (
                        <div className="row">
                            <div className="col">
                                <SchemeSharesPanel
                                    accountId={accountId}
                                    entityId={schemeId}
                                    isSubaccount={isSubaccount}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </PermissionPage>
    );
};

export default SchemeCardPage;
