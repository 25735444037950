import { baseApi } from '../baseApi';

export const hostsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getHosts: builder.query({
            query: (query) =>
                `hosts?search=${query?.search ?? ''}&sort=${query?.sort ?? ''}&pageIndex=${
                    query?.pageIndex ?? 1
                }&pageSize=${query?.pageSize ?? 50}&withSummary=${query?.withSummary ?? false}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Hosts',
                              id,
                          })),
                          { type: 'Hosts', id: 'List' },
                      ]
                    : [{ type: 'Hosts', id: 'List' }],
        }),
        getHostById: builder.query({
            query: (id) => `hosts/${id}`,
            providesTags: (result) => [{ type: 'Host', id: result?.result.id }],
        }),
        createHost: builder.mutation({
            query: (body) => ({
                url: 'hosts',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Hosts', id: 'List' }],
        }),
        updateHost: builder.mutation({
            query: (body) => ({
                url: 'hosts/' + body.id,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result) => [
                { type: 'Hosts', id: 'List' },
                { type: 'Host', id: result?.result.id },
            ],
        }),
        deleteHost: builder.mutation({
            query: (id) => ({
                url: 'hosts/' + id,
                method: 'DELETE',
                body: undefined,
            }),
            invalidatesTags: [{ type: 'Hosts', id: 'List' }],
        }),
        syncHost: builder.mutation({
            query: (id) => ({
                url: 'hosts/' + id + '/sync',
                method: 'PUT',
                body: undefined,
            }),
        }),
    }),
});

export const {
    useGetHostsQuery,
    useGetHostByIdQuery,
    useCreateHostMutation,
    useUpdateHostMutation,
    useDeleteHostMutation,
    useSyncHostMutation,
} = hostsApiSlice;
