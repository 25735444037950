import 'bootstrap/dist/js/bootstrap.min.js';

const FaceWarnings = (props) => {
    const { warnings } = props;

    return (
        <>
            {warnings?.includes('a') ? (
                <div>
                    При загрузке фотографии в биометрический терминал произошла
                    ошибка.
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default FaceWarnings;
