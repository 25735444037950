import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormatDateToHuman, UtcToLocal } from '../../src/helpers/DateHelpers';
import { getMyLogsAsync } from '../app/storeReducers/LogSlice';

const LogPage = (props) => {
    const records = useSelector((state) => state.logs.items);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMyLogsAsync());
    });

    return (
        <div>
            <h3>История действий</h3>
            <table className="table table-striped table-sm">
                <thead>
                    <tr>
                        <th>Время</th>
                        <th>Пользователь</th>
                        <th>Действие</th>
                        <th>Объект 1</th>
                        <th>Объект 2</th>
                    </tr>
                </thead>
                <tbody>
                    {records.map((item) => (
                        <tr key={item.id}>
                            <td>
                                {FormatDateToHuman(
                                    UtcToLocal(item.time)
                                ).toString()}
                            </td>
                            <td>{item.login}</td>
                            <td>{item.description}</td>
                            <td>
                                {item.object != null ? item.object.name : ''}
                            </td>
                            <td>
                                {item.subObject != null
                                    ? item.subObject.name
                                    : ''}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default LogPage;
