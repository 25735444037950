import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../app/storeReducers/NotifySlice';
import SkeletonTableFull from '../../../components/skeleton/SkeletonTableFull';
import { AddDays, DateToString, IsSameDate, ToUtcString, UtcToLocal } from '../../../helpers/DateHelpers';
import EmployeesDropDownInput from '../EmployeesDropDownInput';
import Paginator from '../../../components/Paginator';
import { useGetLocationsQuery } from '../../../app/storeReducers/locationsApiSlice';
import { useGetGroupsQuery } from '../../../app/storeReducers/groupsApiSlice';
import {
    useLazyExportLogTimeTrackingsQuery,
    useLazyGetLogTimeTrackingsQuery,
} from '../../../app/storeReducers/logsApiSlice';
import { useLocation } from 'react-router';
import {
    useLazyExportLogTimeTrackingDaysQuery,
    useLazyGetLogTimeTrackingDaysQuery,
} from '../../../app/storeReducers/logTimetrackingApiSlice';
import { FaExclamationTriangle } from 'react-icons/fa';

const TimesheetList = (props) => {
    const { accountId } = props;

    const dispatch = useDispatch();

    const [dateStart, setDateStart] = useState(new Date());
    const [dateFinish, setDateFinish] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [report, setReport] = useState([]);
    const [paginator, setPaginator] = useState({});
    const [locationId, setLocationId] = useState(0);
    const [employeeId, setEmployeeId] = useState(0);
    const [groupId, setGroupId] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(0);

    const { data: locations } = useGetLocationsQuery({
        accountId,
        withParents: true,
    });

    const { data: groups } = useGetGroupsQuery({
        accountId,
    });

    const [getLogTimeTrackingDays] = useLazyGetLogTimeTrackingDaysQuery();
    const [exportLogTimeTrackingDays] = useLazyExportLogTimeTrackingDaysQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(false);
            const dtStartString = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`;
            const dtFinishString = `${dateFinish.getFullYear()}-${
                dateFinish.getMonth() + 1
            }-${dateFinish.getDate()}T23:59:59`;
            try {
                const getResult = await getLogTimeTrackingDays({
                    accountId,
                    start: dtStartString,
                    finish: dtFinishString,
                    locationId,
                    employeeId: employeeId > 0 ? employeeId : '',
                    pageSize,
                    pageIndex,
                    groupId: groupId > 0 ? groupId : '',
                }).unwrap();
                const tableResult = getResult?.result?.map((item, index) => ({
                    ...item,
                    keyItem: index,
                    locationName: item.location?.title,
                    employeeName: item.employee?.title,
                    dateString: DateToString(new Date(item.date)),
                    dateInString: item.dateIn ? DateToString(UtcToLocal(item.dateIn), true) : null,
                    dateOutString: item.dateOut ? DateToString(UtcToLocal(item.dateOut), true) : null,
                }));
                setReport(tableResult);
                setPaginator(getResult.paginator);
                setForcePage(pageIndex - 1);
            } catch (error) {
                console.log('error', error);
                dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка!',
                        description: 'Перезагрузите страницу и попробуйте снова! ' + error.message,
                        type: 'danger',
                    })
                );
            }

            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [dateStart, dateFinish, locationId, employeeId, groupId, pageSize, pageIndex]);

    const exportData = async () => {
        const dtStartString = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`;
        const dtFinishString = `${dateFinish.getFullYear()}-${
            dateFinish.getMonth() + 1
        }-${dateFinish.getDate()}T23:59:59`;
        const response = await exportLogTimeTrackingDays({
            accountId,
            employeeId: employeeId > 0 ? employeeId : '',
            groupId: groupId > 0 ? groupId : '',
            locationId,
            start: dtStartString,
            finish: dtFinishString,
            export: 'timesheet',
        }).unwrap();
        const a = document.createElement('a');
        a.href = response.url;
        a.download = 'Отчет Табель.xlsx';
        a.click();
        URL.revokeObjectURL(response.url);
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
    };

    return (
        <>
            <div className='card border'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    <div className='card-toolbar'>
                        <div className='input-group me-2 mb-3' style={{ width: '400px' }}>
                            <span className='input-group-text'>Объект</span>
                            <select
                                className='form-select'
                                value={locationId}
                                onChange={(e) => {
                                    setLocationId(e.target.value);
                                    setPageIndex(1);
                                }}
                            >
                                <option value={0}>Все</option>
                                {locations?.result.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name} ({item.account.title})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='input-group me-2 mb-3' style={{ width: '400px' }}>
                            <span className='input-group-text'>Группа</span>
                            <select
                                className='form-select'
                                value={groupId}
                                onChange={(e) => {
                                    setGroupId(e.target.value);
                                    setPageIndex(1);
                                }}
                            >
                                <option value={0}>Все</option>
                                {groups?.result.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <EmployeesDropDownInput
                            accountId={accountId}
                            selectEmployee={(e) => {
                                setEmployeeId(e);
                                setPageIndex(1);
                            }}
                            groupId={groupId}
                        />
                        <div className='input-group me-2 mb-3' style={{ width: '400px' }}>
                            <span className='input-group-text'>С</span>
                            <input
                                type='date'
                                className='form-control'
                                value={ToUtcString(dateStart).split('T')[0]}
                                onChange={(e) => setDateStart(new Date(e.target.value))}
                            />
                            <span className='input-group-text'>ПО</span>
                            <input
                                type='date'
                                className='form-control'
                                value={ToUtcString(dateFinish).split('T')[0]}
                                onChange={(e) => setDateFinish(new Date(e.target.value))}
                            />
                        </div>
                        <button className='btn av-btn-simple ms-3 mb-3' onClick={exportData}>
                            <i className='far fa-file-excel me-3'></i> Экспорт в Excel
                        </button>
                    </div>
                    {IsSameDate(dateFinish, new Date()) && (
                        <div className=''>
                            <FaExclamationTriangle className='me-1' /> Данные за сегодняшний день будут сформированы в
                            01:00 {DateToString(AddDays(new Date(), 1))}
                        </div>
                    )}
                </div>
                <div className='table-responsive'>
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: 'keyItem',
                            locationName: 'Объект',
                            dateString: 'Дата',
                            employeeName: 'Сотрудник',
                            occupation: 'Должность',
                            durationString: 'Время',
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='mb-0'
                        entity={report}
                        tdClasses={{
                            locationName: 'ps-6',
                            occupation: 'report-occupation',
                        }}
                    />
                </div>
                <div className='card-body py-3 px-5'>
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={report.length}
                        countTotal={paginator.totalCount}
                        forcePage={forcePage}
                    />
                </div>
            </div>
        </>
    );
};

export default TimesheetList;
