import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useGetParkingTariffsQuery } from "../../app/storeReducers/parkingTariffsApiSlice";
import {
    useCreateServiceMutation,
    useLazyGetServiceByIdQuery,
    useUpdateServiceMutation,
} from "../../app/storeReducers/servicesApiSlice";

const VehicleServiceCreateModal = (props) => {
    const { accountId, entityId, consumerId, vehicleId, isOpen, closeHandle } =
        props;

    const [dateStart, setDateStart] = useState("");
    const [dateFinish, setDateFinish] = useState("");
    const [serviceSelected, setServiceSelected] = useState(0);

    const { data: tariffs } = useGetParkingTariffsQuery({ accountId });
    const [getServiceById] = useLazyGetServiceByIdQuery();
    const [createAsync] = useCreateServiceMutation();
    const [updateAsync] = useUpdateServiceMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setServiceSelected(tariffs?.result[0].id);
        }

        loadResourcesAndDataAsync();
    }, [tariffs]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== 0 && entityId !== -1) {
                const getResult = await getServiceById(entityId).unwrap();
                if (getResult.result.dateStart !== null) {
                    setDateStart(
                        getResult.result.dateStart.substr(
                            0,
                            getResult.result.dateStart.length - 9
                        )
                    );
                } else {
                    setDateStart("");
                }
                if (getResult.result.dateFinish !== null) {
                    setDateFinish(
                        getResult.result.dateFinish.substr(
                            0,
                            getResult.result.dateFinish.length - 9
                        )
                    );
                } else {
                    setDateFinish("");
                }
                if (getResult.result.tariff != null) {
                    setServiceSelected(getResult.result.tariff.id);
                } else {
                    setServiceSelected(tariffs?.result[0].id);
                }
            } else {
                if (tariffs?.result.length > 0) {
                    setServiceSelected(tariffs?.result[0].id);
                }
                setDateStart("");
                setDateFinish("");
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId, tariffs]);

    const saveEntity = async () => {
        const entity = {
            supplier: {
                id: accountId,
            },
            consumer: {
                id: consumerId,
            },
            resource: {
                id: vehicleId,
                type: "vehicle",
            },
            tariff: {
                id: serviceSelected,
            },
            dateStart: dateStart.length > 0 ? dateStart + "T00:00:00" : "",
            dateFinish: dateFinish.length > 0 ? dateFinish + "T00:00:00" : "",
            id: entityId,
        };
        if (entityId === 0) {
            createAsync(entity);
        } else {
            updateAsync(entity);
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {entityId > 0
                                    ? "Изменить услугу"
                                    : "Добавить услугу"}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body py-3">
                            <div className="mb-3">
                                Создать тариф и схемы парковки можно в разделе{" "}
                                <Link
                                    to={`/account/${accountId}/parking`}
                                    className="btn-link av-text-primary fw-bolder"
                                >
                                    администрирование парковки
                                </Link>
                            </div>
                            <div className="form-group mb-3">
                                <label className="form-label">Тариф</label>
                                <select
                                    className="form-select"
                                    value={serviceSelected}
                                    onChange={(e) =>
                                        setServiceSelected(e.target.value)
                                    }
                                >
                                    {tariffs?.result.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label
                                    htmlFor="dateStart"
                                    className="form-label"
                                >
                                    Дата начала действия
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="dateStart"
                                    value={dateStart}
                                    onChange={(e) =>
                                        setDateStart(e.target.value)
                                    }
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label
                                    htmlFor="dateFinish"
                                    className="form-label"
                                >
                                    Дата окончания действия
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="dateFinish"
                                    value={dateFinish}
                                    onChange={(e) =>
                                        setDateFinish(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="modal-footer py-2">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default VehicleServiceCreateModal;
