import { useEffect, useState } from "react";
import SkeletonSelect from "../../../../components/skeleton/SkeletonSelect";
import { useGetDevicesQuery } from "../../../../app/storeReducers/devicesApiSlice";
import { useAddSchemeDeviceMutation } from "../../../../app/storeReducers/schemesApiSlice";

const SchemeAddDeviceModal = (props) => {
    const { accountId, schemeId, isOpen, okHandle, closeHandle } = props;

    const [deviceSelected, setDeviceSelected] = useState({});

    const { data: devices, isLoading } = useGetDevicesQuery(
        { accountId, sort: "name" },
        { skip: !accountId }
    );
    const [addSchemeDevice] = useAddSchemeDeviceMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setDeviceSelected({});
            if (devices?.result?.length > 0) {
                setDeviceSelected(devices.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [devices]);

    const saveSchemeDevice = async () => {
        await addSchemeDevice({
            schemeId,
            deviceId: deviceSelected,
        });

        okHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить устройство в схему
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-4">
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label="Устройство"
                                    value={deviceSelected}
                                    onChange={(e) =>
                                        setDeviceSelected(e.target.value)
                                    }
                                    itemsArray={devices?.result?.map(
                                        (item) => ({
                                            id: item.id,
                                            name: item.id,
                                            description: item.name,
                                        })
                                    )}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveSchemeDevice}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default SchemeAddDeviceModal;
