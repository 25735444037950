import { useContext, useEffect, useState } from 'react';
import SkeletonTableFull from '../../../components/skeleton/SkeletonTableFull';
import { Http } from '../../../http';
import SecutiryContext from '../../../features/securityContext';

const LocationDevicesPanel = (props) => {
    const { accountId, entityId } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState([]);

    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [entityId, canEdit]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanList(context.isRoot || actions?.includes('devices_list'));
    }, [context, accountId]);

    const loadData = async () => {
        setIsLoading(true);
        const getResult = await Http.get(
            `api/devices?accountId=${accountId}&locationId=${entityId}`,
            true
        );
        setDevices(getResult.result);
        setIsLoading(false);
    };

    return (
        <>
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            <h3>
                                Устройства{' '}
                                <span className='badge rounded-pill bg-light text-dark'>
                                    {devices?.length}
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
                {canList ? (
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: 'id',
                            name: 'Название',
                            uid: 'UID',
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='mb-0'
                        entity={devices}
                        tdClasses={{ name: 'ps-6' }}
                    />
                ) : (
                    <div className='card-body'>
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default LocationDevicesPanel;
