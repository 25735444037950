import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAsync } from '../../../app/storeReducers/AccountSchedulesSlice';
import SkeletonInput from '../../../components/skeleton/SkeletonInput';
import { Http } from '../../../http';
import ScheduleDeleteModal from '../ScheduleDeleteModal';
import SecutiryContext from '../../../features/securityContext';

const ScheduleEditPanel = (props) => {
    const { accountId, entityId } = props;

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');
    const [day1From, setDay1From] = useState('');
    const [day1To, setDay1To] = useState('');
    const [day2From, setDay2From] = useState('');
    const [day2To, setDay2To] = useState('');
    const [day3From, setDay3From] = useState('');
    const [day3To, setDay3To] = useState('');
    const [day4From, setDay4From] = useState('');
    const [day4To, setDay4To] = useState('');
    const [day5From, setDay5From] = useState('');
    const [day5To, setDay5To] = useState('');
    const [day6From, setDay6From] = useState('');
    const [day6To, setDay6To] = useState('');
    const [day7From, setDay7From] = useState('');
    const [day7To, setDay7To] = useState('');

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getResult = await Http.get(
                'api/schemes/schedules/' + entityId,
                true
            );
            setIsLoading(true);
            setName(getResult.result.name);
            setDay1From(getResult.result.day1From);
            setDay1To(getResult.result.day1To);
            setDay2From(getResult.result.day2From);
            setDay2To(getResult.result.day2To);
            setDay3From(getResult.result.day3From);
            setDay3To(getResult.result.day3To);
            setDay4From(getResult.result.day4From);
            setDay4To(getResult.result.day4To);
            setDay5From(getResult.result.day5From);
            setDay5To(getResult.result.day5To);
            setDay6From(getResult.result.day6From);
            setDay6To(getResult.result.day6To);
            setDay7From(getResult.result.day7From);
            setDay7To(getResult.result.day7To);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('schemes_edit'));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            id: entityId,
            account: { id: accountId },
            name,
            day1From,
            day1To,
            day2From,
            day2To,
            day3From,
            day3To,
            day4From,
            day4To,
            day5From,
            day5To,
            day6From,
            day6To,
            day7From,
            day7To,
        };
        dispatch(updateAsync(entity));
    };

    return (
        <>
            <ScheduleDeleteModal
                entity={{ id: entityId, name, account: { id: accountId } }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <h3>График доступа</h3>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='name'
                            label='Название'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='row mb-3'>
                        <div className='col-2 d-flex align-items-center'>
                            ПН
                        </div>
                        <div className='col-10'>
                            <div className='input-group'>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='С'
                                    value={day1From}
                                    onChange={(e) =>
                                        setDay1From(e.target.value)
                                    }
                                />
                                <span className='input-group-text'>-</span>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='ПО'
                                    value={day1To}
                                    onChange={(e) => setDay1To(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-2 d-flex align-items-center'>
                            ВТ
                        </div>
                        <div className='col-10'>
                            <div className='input-group'>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='С'
                                    value={day2From}
                                    onChange={(e) =>
                                        setDay2From(e.target.value)
                                    }
                                />
                                <span className='input-group-text'>-</span>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='ПО'
                                    value={day2To}
                                    onChange={(e) => setDay2To(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-2 d-flex align-items-center'>
                            СР
                        </div>
                        <div className='col-10'>
                            <div className='input-group'>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='С'
                                    value={day3From}
                                    onChange={(e) =>
                                        setDay3From(e.target.value)
                                    }
                                />
                                <span className='input-group-text'>-</span>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='ПО'
                                    value={day3To}
                                    onChange={(e) => setDay3To(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-2 d-flex align-items-center'>
                            ЧТ
                        </div>
                        <div className='col-10'>
                            <div className='input-group'>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='С'
                                    value={day4From}
                                    onChange={(e) =>
                                        setDay4From(e.target.value)
                                    }
                                />
                                <span className='input-group-text'>-</span>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='ПО'
                                    value={day4To}
                                    onChange={(e) => setDay4To(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-2 d-flex align-items-center'>
                            ПТ
                        </div>
                        <div className='col-10'>
                            <div className='input-group'>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='С'
                                    value={day5From}
                                    onChange={(e) =>
                                        setDay5From(e.target.value)
                                    }
                                />
                                <span className='input-group-text'>-</span>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='ПО'
                                    value={day5To}
                                    onChange={(e) => setDay5To(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-2 d-flex align-items-center'>
                            СБ
                        </div>
                        <div className='col-10'>
                            <div className='input-group'>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='С'
                                    value={day6From}
                                    onChange={(e) =>
                                        setDay6From(e.target.value)
                                    }
                                />
                                <span className='input-group-text'>-</span>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='ПО'
                                    value={day6To}
                                    onChange={(e) => setDay6To(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-2 d-flex align-items-center'>
                            ВС
                        </div>
                        <div className='col-10'>
                            <div className='input-group'>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='С'
                                    value={day7From}
                                    onChange={(e) =>
                                        setDay7From(e.target.value)
                                    }
                                />
                                <span className='input-group-text'>-</span>
                                <input
                                    type='time'
                                    className='form-control'
                                    placeholder='ПО'
                                    value={day7To}
                                    onChange={(e) => setDay7To(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            <button
                                className='btn av-btn-danger fw-boldest'
                                onClick={() => setDeleteModalIsOpen(true)}
                                disabled={!canEdit}
                            >
                                <i className='far fa-trash-alt me-2' /> Удалить
                            </button>
                        </div>
                        <div className='col text-end'>
                            <button
                                className='btn av-btn-simple fw-boldest'
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScheduleEditPanel;
