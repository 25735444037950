import React, { useContext } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsAuthorize } from '../app/storeReducers/CurrentUserSlice';
import SecutiryContext from '../features/securityContext';

const PrivateRoute = ({ chObject, ...rest }) => {
    const { context } = useContext(SecutiryContext);
    const isAuthorize = useSelector(selectIsAuthorize);
    const location = useLocation();
    const isAdmin = location.pathname.startsWith('/admin/');

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthorize && context ? (
                    isAdmin && !context?.isRoot ? (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    ) : (
                        <rest.render {...props} />
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
