import { DateToString, UtcToLocal } from '../../../helpers/DateHelpers';
import AvatarView from './AvatarView';

const MonitorWidget = (props) => {
    const { device, record, color, className } = props;

    const getEntityTypeName = (entityType) => {
        let typeName = '---';
        switch (entityType) {
            case 'employee':
                typeName = 'Сотрудник';
                break;
            case 'guest':
                typeName = 'Посетитель';
                break;
            case 'vehicle':
                typeName = 'Транспорт';
                break;
            default:
                typeName = '---';
                break;
        }
        return typeName;
    };

    return (
        <>
            <div className={`card border ${className}`}>
                {/* begin::Body */}
                <div className='card-body p-0'>
                    {/* begin::Header */}
                    <div
                        className={`px-6 d-flex align-items-center card-rounded h-65px w-100 av-bg-${color}`}
                    >
                        {/* begin::Heading */}
                        <h3 className='m-0 text-white fw-bolder fs-3'>
                            {device?.title}
                        </h3>
                        {/* end::Heading */}
                    </div>
                    {/* end::Header */}
                    {/* begin::Items */}
                    <div className='card-rounded px-6 py-3 position-relative z-index-1 bg-white'>
                        {/* begin::Item */}

                        <div className='d-flex align-items-center flex-wrap w-100 mb-2'>
                            <div className='mb-1 pe-3 flex-grow-1'>
                                <div className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                    {record?.eventName ?? <div>&nbsp;</div>}
                                </div>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                                    {record
                                        ? DateToString(
                                              UtcToLocal(record.time),
                                              true
                                          )
                                        : ' '}
                                </div>
                            </div>
                        </div>

                        <div style={{ textAlign: 'center' }} className='mb-6'>
                            {record?.entity?.type === 'employee' ? (
                                <AvatarView employeeId={record.entity.id} />
                            ) : (
                                <img
                                    alt='Logo'
                                    src='/images/no-user.jpg'
                                    style={{
                                        maxWidth: '200px',
                                        textAlign: 'center',
                                    }}
                                />
                            )}
                        </div>
                        <div>
                            <div className='d-flex align-items-center flex-wrap w-100'>
                                <div className='mb-1 pe-3 flex-grow-1'>
                                    <div className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                        {getEntityTypeName(
                                            record?.entity?.type
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                                        {record?.entity?.title ?? '---'}
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center flex-wrap w-100'>
                                <div className='mb-1 pe-3 flex-grow-1'>
                                    <div className='fs-5 text-gray-800 text-hover-primary fw-bolder'>
                                        Аккаунт
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='fw-bolder fs-5 text-gray-800 pe-1'>
                                        {record?.account?.title ?? '---'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::Item */}
                    </div>
                    {/* end::Items */}
                </div>
                {/* end::Body */}
            </div>
        </>
    );
};

export default MonitorWidget;
