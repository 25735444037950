import { useState } from 'react';
import SkeletonTable from '../../../components/skeleton/SkeletonTable';
import FindComponent from '../../../components/FindComponent';
import { KTSVG } from '../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import HostCreateModal from './HostCreateModal';
import HostDeleteModal from './HostDeleteModal';
import Paginator from '../../../components/Paginator';
import ThSortable from '../../../components/table/ThSortable';
import { useGetHostsQuery } from '../../../app/storeReducers/hostsApiSlice';

const HostsList = () => {
    const [accountName, setAccountName] = useState('');
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedEntity, setDeletedEntity] = useState(0);

    const [pageSize, setPageSize] = useState(50);
    const [forcePage, setForcePage] = useState(0);

    const [sort, setSort] = useState('name');
    const [sortDest, setSortDest] = useState('asc');
    const [sortEntity, setSortEntity] = useState('name');

    const { data: entityList, isLoading } = useGetHostsQuery({
        search: accountName,
        sort: sortEntity,
        pageIndex: 1,
        pageSize: pageSize,
        withSummary: true,
    });

    const openCreateModal = () => {
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const selectDeleteEntity = (entityId) => {
        setDeletedEntity(entityId);
        setDeleteModalIsOpen(true);
    };

    const columnFilter = (colName) => {
        const dest = colName === sort ? (sortDest === 'asc' ? 'desc' : 'asc') : 'asc';
        setSort(colName);
        setSortDest(dest);
        setSortEntity(colName + (dest === 'desc' ? '_desc' : ''));
    };

    function StatusLabel(props) {
        const isOnline = props.isOnline;
        if (isOnline) {
            return <span className='badge badge-sm bg-light-success rounded-pill'>online</span>;
        }
        return <span className='badge badge-sm bg-light-secondary rounded-pill'>offline</span>;
    }

    const changePage = async (pageIndex) => {
        setForcePage(pageIndex - 1);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
        setForcePage(0);
    };

    return (
        <>
            <HostCreateModal isOpen={createModalIsOpen} closeHandle={closeCreateModal} />
            <HostDeleteModal
                entity={deletedEntity}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    <div className='card-toolbar'>
                        <FindComponent callback={(name) => setAccountName(name)} />
                        <button className='btn btn-sm btn-light-primary ms-2' onClick={openCreateModal}>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                            Новый хост
                        </button>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <SkeletonTable
                            cols={['Название', 'Адрес', 'Порт', '']}
                            rowsCount={5}
                            isLoading={isLoading}
                            classTable='table-striped gy-7 gs-7'
                        >
                            <table className='table table-striped gy-7 gs-7'>
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='id'
                                            colTitle='ID'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='name'
                                            colTitle='Название'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <th className='border-bottom'>Комментарий</th>
                                        <th className='border-bottom'>Адрес</th>
                                        <th className='border-bottom'>Аккаунты / Устройства</th>
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='status'
                                            colTitle='Статус'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <th className='border-bottom'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entityList?.result?.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>{item.name}</td>
                                            <td>{item.comments}</td>
                                            <td>
                                                {item.address}:{item.port}
                                            </td>
                                            <td>
                                                {item.summary?.accounts} / {item.summary?.devices}
                                            </td>
                                            <td>
                                                <StatusLabel isOnline={item.isOnline} />
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/admin/hosts/${item.id}`}
                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/art/art005.svg'
                                                        className='svg-icon-3'
                                                    />
                                                </Link>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                    onClick={() => selectDeleteEntity(item)}
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen027.svg'
                                                        className='svg-icon-3'
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </SkeletonTable>
                    </div>
                </div>
            </div>
            <Paginator
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                pageCount={entityList?.paginator?.totalPages}
                pageSizeArray={[50, 100, 500]}
                pageSize={pageSize}
                countCurrent={entityList?.paginator?.length}
                countTotal={entityList?.paginator?.totalCount}
                forcePage={forcePage}
            />
        </>
    );
};

export default HostsList;
