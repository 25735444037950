import React, { useEffect, useState } from 'react';
import { Http } from '../../http';
import {
    createAsync,
    updateAsync,
} from '../../app/storeReducers/AccountParkingAreasSlice';
import { useDispatch } from 'react-redux';

const ParkingAreaCreateModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState('');

    useEffect(() => {
        setName('');
    }, [isOpen])


    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== 0 && entityId !== -1) {
                const getResult = await Http.get(
                    'api/parking/areas/' + entityId,
                    true
                );
                setName(getResult.result.name);
            } else {
                setName('');
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    const saveEntity = async () => {
        const entity = {
            name,
            account: { id: accountId },
            id: entityId,
        };
        if (entityId === 0) {
            dispatch(createAsync(entity));
        } else {
            dispatch(updateAsync(entity));
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить новую парковку
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-3'>
                                <label htmlFor='name' className='form-label'>
                                    Название
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default ParkingAreaCreateModal;
