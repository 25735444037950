import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const groupsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getGroups: builder.query({
            query: (data) =>
                `groups?accountId=${data.accountId ?? ""}&employeeId=${
                    data.employeeId ?? ""
                }&withChilds=${data.withChilds ?? false}&sort=${
                    data.sort ?? ""
                }&pageIndex=${data.pageIndex ?? 1}&pageSize=${
                    data.pageSize ?? 100
                }`,
            providesTags: (result) =>
                result
                    ? [
                          ...result?.result?.map(({ id }) => ({
                              type: "Groups",
                              id,
                          })),
                          { type: "Groups", id: "LIST" },
                      ]
                    : [{ type: "Groups", id: "LIST" }],
        }),
        getGroupById: builder.query({
            query: (id) => `groups/${id}`,
            providesTags: (result) => [
                { type: "Group", id: result?.result?.id },
            ],
        }),
        createGroup: builder.mutation({
            query: (body) => ({
                url: "groups",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Groups", id: "LIST" }],
        }),
        updateGroup: builder.mutation({
            query: (body) => ({
                url: "groups/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Groups", id: "LIST" },
                { type: "Group", id: result?.result.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить группу! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Группа сохранена!",
                                description: "Данные группы успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить группу! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteGroup: builder.mutation({
            query: (id) => ({
                url: `groups/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result) => [{ type: "Groups", id: "LIST" }],
        }),
        getGroupMembers: builder.query({
            query: (id) => `groups/${id}/members`,
            providesTags: (result) =>
                result
                    ? [
                          ...result?.result?.map(({ id }) => ({
                              type: "GroupMembers",
                              id,
                          })),
                          { type: "GroupMembers", id: "LIST" },
                      ]
                    : [{ type: "GroupMembers", id: "LIST" }],
        }),
        addGroupMember: builder.mutation({
            query: (body) => ({
                url: `groups/${body.groupId}/members/${body.employeeId}`,
                method: "PUT",
            }),
            invalidatesTags: (result) => [
                { type: "GroupMembers", id: "LIST" },
                { type: "GroupMember", id: result?.result.id },
            ],
        }),
        removeGroupMember: builder.mutation({
            query: (body) => ({
                url: `groups/${body.groupId}/members/${body.employeeId}`,
                method: "DELETE",
            }),
            invalidatesTags: (result) => [{ type: "GroupMembers", id: "LIST" }],
        }),
        getGroupSchemes: builder.query({
            query: (id) => ({
                url: `groups/${id}/schemes`,
                method: "GET",
            }),
        }),
    }),
});

export const {
    useGetGroupsQuery,
    useGetGroupByIdQuery,
    useCreateGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useAddGroupMemberMutation,
    useRemoveGroupMemberMutation,
    useGetGroupSchemesQuery,
    useGetGroupMembersQuery,
} = groupsApiSlice;
