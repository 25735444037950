import { useParams } from "react-router";
import { useGetAccountByIdQuery } from "../app/storeReducers/acountsApiSlice";

export function useAccount() {
    const params = useParams();
    const accountId = params.accountId;

    const { data } = useGetAccountByIdQuery(accountId, { skip: !accountId });

    return data?.result;
}
