const MqttProperties = (props) => {
    const { device } = props;

    return (
        <>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='fild-label'>UID</div>
                    <div className='fild-text'>{device?.uid}</div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='fild-label'>Направление</div>
                    <div className='fild-text'>
                        {device?.defaultDirection ? 'Вход' : 'Выход'}
                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='fild-label'>Открытие по расписанию</div>
                    <div className='fild-text'>
                        {device?.schedule?.title ?? 'Не установлено'}
                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='fild-label'>Объект</div>
                    <div className='fild-text'>{device.location?.title}</div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-12'>
                    {device?.managedByMobile ? (
                        <div className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'>
                            Управление через мобильное приложение
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default MqttProperties;
