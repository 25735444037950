import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../http';
import { useDispatch } from 'react-redux';
import { updateGroupAsync } from '../../../app/storeReducers/AccountGroupsSlice';
import SkeletonInput from '../../../components/skeleton/SkeletonInput';
import GroupDeleteModal from '../GroupDeleteModal';
import SecutiryContext from '../../../features/securityContext';

const GroupEditPanel = (props) => {
    const { accountId, entityId } = props;

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getResult = await Http.get('api/groups/' + entityId, true);
            setName(getResult.result.name);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('groups_edit'));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            account: { id: accountId },
            id: entityId,
        };
        dispatch(updateGroupAsync(entity));
    };

    return (
        <>
            <GroupDeleteModal
                group={{ id: entityId, name: name, account: { id: accountId } }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row'>
                        <div className='col mb-2'>
                            <h3>Группа</h3>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='name'
                            label='Название'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            {canEdit ? (
                                <button
                                    className='btn av-btn-danger fw-boldest'
                                    onClick={() => setDeleteModalIsOpen(true)}
                                >
                                    <i className='far fa-trash-alt me-2' />{' '}
                                    Удалить
                                </button>
                            ) : null}
                        </div>
                        <div className='col text-end'>
                            <button
                                type='button'
                                className='btn av-btn-simple fw-boldest'
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GroupEditPanel;
