import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
    selectPaginator,
    resetVehicleAsync,
} from '../../app/storeReducers/AccountVehiclesSlice';
import VehicleCreateModal from './VehicleCreateModal';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import Paginator from '../../components/Paginator';
import { useHistory } from 'react-router-dom';
import FindComponent from '../../components/FindComponent';
import ThSortable from '../../components/table/ThSortable';
import { DateToString, UtcToLocal } from '../../helpers/DateHelpers';
import { HumanPhone } from '../../helpers/PhoneHelper';
import { ACTIONS, STATUS } from 'react-joyride';
import Onboarding from '../../components/onboarding/Onboarding';
import SecutiryContext from '../../features/securityContext';
import VehicleResetModal from './VehicleResetModal';

const RolesList = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);
    const paginator = useSelector(selectPaginator);

    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [sort, setSort] = useState('');
    const [sortDest, setSortDest] = useState('asc');
    const [vehSort, setVehSort] = useState('');

    const [searchString, setSearchString] = useState('');
    const [forcePage, setForcePage] = useState(0);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [canReset, setCanReset] = useState(false);
    const [isOpenResetModal, setIsOpenResetModal] = useState(false);
    const [resetVehicleId, setResetVehicleId] = useState(0);

    const { context } = useContext(SecutiryContext);
    const [steps, setSteps] = useState([
        {
            target: '#vehiclePanel',
            disableBeacon: true,
            placement: 'top-start',
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>Шаг 11 из 13</div>
                    <div className='mb-1 ob-title'>
                        <b>Раздел транспорт</b>
                    </div>
                    <div className='mb-3 ob-text'>
                        В этом разделе вы управляете доступом транспорта на свой объект. В таблице вы сразу видите
                        информацию об автомобиле, закрепленным за ним сотрудником и парковках.
                    </div>
                </div>
            ),
        },
    ]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(
                getListAsync({
                    accountId,
                    searchString,
                    sort: vehSort,
                    pageSize,
                    pageIndex,
                    withBlacklist: true,
                })
            );
            setForcePage(pageIndex - 1);
        }

        loadResourcesAndDataAsync();
    }, [dispatch, accountId, searchString, vehSort, pageSize, pageIndex]);

    useEffect(() => {
        const actions = context?.permissions?.find((x) => x.id === parseInt(accountId))?.actions;
        setCanEdit(context.isRoot || actions?.includes('vehicles_edit'));
        setCanReset(context.isRoot || actions?.includes('vehicles_reset_parking'));
    }, [context, accountId]);

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey) window.open(`/account/${accountId}/vehicles/${entityId}`, '_blank');
        else history.push(`/account/${accountId}/vehicles/${entityId}`);
    };

    const columnFilter = (colName) => {
        const dest = colName === sort ? (sortDest === 'asc' ? 'desc' : 'asc') : 'asc';
        setSort(colName);
        setSortDest(dest);
        setVehSort(colName + (dest === 'desc' ? '_desc' : ''));
    };

    const onboardingCallback = (action, status, index) => {
        if (action === ACTIONS.CLOSE && status === STATUS.FINISHED && index === 0) {
            history.push(`/account/${accountId}/vehicles/${entityList[0].id}`);
        }
    };

    const stateHtml = (state, entityId) => {
        let html = '';
        switch (state) {
            case 'out':
                html = '';
                break;
            case 'in':
                html = (
                    <span className='badge bg-success' onClick={(e) => handleReset(e, entityId)}>
                        На парковке
                    </span>
                );
                break;
            case 'by_quota':
                html = (
                    <span className='badge bg-success' onClick={(e) => handleReset(e, entityId)}>
                        На парковке по квоте
                    </span>
                );
                break;
            case 'over_quota':
                html = (
                    <span className='badge bg-warning' onClick={(e) => handleReset(e, entityId)}>
                        На парковке сверх квоты
                    </span>
                );
                break;
        }

        return html;
    };

    const handleReset = (e, entityId) => {
        e.stopPropagation();
        if (canReset) {
            setResetVehicleId(entityId);
            setIsOpenResetModal(true);
        }
    };

    const resetVehicle = (isAccept) => {
        setIsOpenResetModal(false);
        if (isAccept === true) {
            dispatch(resetVehicleAsync(resetVehicleId));
        }
    };

    return (
        <>
            <Onboarding steps={steps} run={!isLoading} callback={onboardingCallback} />
            <VehicleCreateModal
                accountId={accountId}
                entityId={-1}
                isOpen={createModalIsOpen}
                closeHandle={() => setCreateModalIsOpen(false)}
            />
            <VehicleResetModal
                isOpen={isOpenResetModal}
                closeHandle={resetVehicle}
                vehicleId={resetVehicleId}
                carNumber={
                    entityList.find((x) => x.id === resetVehicleId)?.carNumber +
                    entityList.find((x) => x.id === resetVehicleId)?.carRegion
                }
            />
            <div className='row'>
                <div className='col-md-4 mb-3'>
                    <button
                        className='btn av-btn-primary fw-boldest'
                        onClick={() => setCreateModalIsOpen(true)}
                        disabled={!canEdit}
                    >
                        <img src='/media/icons/avanguard/Application4.svg' className='h-25px svg-img me-2' />
                        Добавить транспорт
                    </button>
                </div>
                <div className='col-md-8 mb-3 d-flex justify-content-end'>
                    <FindComponent
                        callback={(name) => {
                            setSearchString(name);
                            changePage(1);
                        }}
                        placeholder='Поиск по транспорту'
                    />
                </div>
            </div>
            <div className='card mb-4 border' id='vehiclePanel'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={[
                            'Аккаунт',
                            'Гос. номер',
                            'Модель',
                            'Сотрудник',
                            'Парковка',
                            'Место',
                            'Дата начала',
                            'Дата окончания',
                            'Состояние',
                        ]}
                        rowsCount={15}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <ThSortable
                                        className='border-bottom ps-6'
                                        colName='account'
                                        colTitle='Аккаунт'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='carNumber'
                                        colTitle='Гос. номер'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='model'
                                        colTitle='Модель'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='employee'
                                        colTitle='Сотрудник'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='parkingArea'
                                        colTitle='Парковка'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='parkingPlace'
                                        colTitle='Место'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='dateStart'
                                        colTitle='Дата начала'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='dateFinish'
                                        colTitle='Дата окончания'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='state'
                                        colTitle='Состояние'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) => openEntityCard(item.id, e)}
                                        className='cursor-pointer'
                                    >
                                        <td className='ps-6 av-text-gray-500 align-middle'>
                                            {item.account != null ? item.account.title : ''}
                                        </td>
                                        <td className='fw-bolder align-middle'>
                                            {item.carNumber + ' ' + item.carRegion}
                                        </td>
                                        <td className='av-text-gray-500 align-middle'>{item.model}</td>
                                        <td>
                                            {item.employee !== null ? (
                                                <>
                                                    <div className='fw-bolder'>{item.employee.title}</div>
                                                    <div className='av-text-gray-500'>
                                                        {item.employee.phone
                                                            ? HumanPhone(item.employee.phone, true)
                                                            : ''}
                                                    </div>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                        <td className='av-text-gray-500 align-middle'>{item.parkingArea.title}</td>
                                        <td className='av-text-gray-500 align-middle'>
                                            {item.parkingPlace !== null ? item.parkingPlace.title : '-'}
                                        </td>
                                        <td className='av-text-gray-500 align-middle'>
                                            {item.service !== null && item.service.dateStart
                                                ? DateToString(UtcToLocal(item.service.dateStart))
                                                : '-'}
                                        </td>
                                        <td className='av-text-gray-500 align-middle'>
                                            {item.blacklist ? (
                                                <span className='badge badge-sm bg-light-danger rounded-pill'>
                                                    Чёрный список
                                                </span>
                                            ) : item.service !== null && item.service.dateFinish ? (
                                                DateToString(UtcToLocal(item.service.dateFinish))
                                            ) : (
                                                '-'
                                            )}
                                        </td>
                                        <td className='av-text-gray-500 align-middle'>
                                            {stateHtml(item.state, item.id)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className='card-body py-3 px-5'>
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList.length}
                        countTotal={paginator.totalCount}
                        forcePage={forcePage}
                    />
                </div>
            </div>
        </>
    );
};

export default RolesList;
