import { DateToString, UtcToLocal } from '../../../helpers/DateHelpers';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import { useEffect } from 'react';

const ReportParkingDetailsModal = (props) => {
    const { entity, isOpen, closeHandle } = props;

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            //init tooltip
            Array.from(
                document.querySelectorAll('[data-bs-toggle="tooltip"]')
            ).forEach((tooltipNode) => new Tooltip(tooltipNode));
        }

        loadResourcesAndDataAsync();
    }, [entity]);

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Подробная информация
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Номер
                                    </th>
                                    <th className='border-bottom'>Имя</th>
                                    <th className='border-bottom'>Начало</th>
                                    <th className='border-bottom'>Окончание</th>
                                    <th className='border-bottom'>Стоимость</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entity.map((item, index) => (
                                    <tr key={index}>
                                        <td className='ps-6'>
                                            {item.warning ? (
                                                <span
                                                    className='fas fa-exclamation-triangle me-2 text-warning'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title='Номер распознан автоматически'
                                                />
                                            ) : null}
                                            {item.carNumber}
                                        </td>
                                        <td>{item.name}</td>
                                        <td>
                                            {DateToString(
                                                UtcToLocal(item.start),
                                                true
                                            )}
                                        </td>
                                        <td>
                                            {DateToString(
                                                UtcToLocal(item.finish),
                                                true
                                            )}
                                        </td>
                                        <td className='text-end'>
                                            {item.cost}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default ReportParkingDetailsModal;
