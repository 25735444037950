/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { Link } from "react-router-dom";
import { KTSVG } from "../../_metronic/helpers";
import { FormatDateToHuman, UtcToLocal } from "../../helpers/DateHelpers";
import { useEffect, useState } from "react";
import {
    selectHeaderNotifications,
    setNotifications,
} from "../../app/storeReducers/HeaderNotificationsSlice";
import { useDispatch, useSelector } from "react-redux";
import {
    useGetNotificationsQuery,
    useReadAllNotificationMutation,
} from "../../app/storeReducers/NotificationsAPI";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { selectContext } from "../../app/storeReducers/AuthSlice";

const HeaderNotificationsMenu = (props) => {
    const { checkHandle } = props;
    const dispatch = useDispatch();
    const context = useSelector(selectContext);
    const data = useSelector(selectHeaderNotifications);

    const { data: notifications, refetch } = useGetNotificationsQuery({
        accountId: 0,
        onlyNew: true,
        pageIndex: 1,
        pageSize: 10,
    });
    const [readAllNotification] = useReadAllNotificationMutation();

    const [connection, setConnection] = useState(null);

    useEffect(() => {
        dispatch(setNotifications(data?.result));
        if (data?.result?.length > 0) {
            checkHandle(1);
        } else {
            checkHandle(0);
        }
    }, [notifications]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (context) {
                const newConnection = new HubConnectionBuilder()
                    .withUrl(
                        `${process.env.REACT_APP_PLATFORM_URL}ws/notifications`
                    )
                    .withAutomaticReconnect()
                    .build();

                setConnection(newConnection);
                refetch();
            }
        }

        loadResourcesAndDataAsync();
    }, [context]);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then(() => {
                    connection
                        .invoke("JoinGroup", `user_${context.user.login}`)
                        .catch((err) => {
                            console.log(err);
                        });

                    connection.on("ReceiveMessage", (message) => {
                        refetch();
                    });
                })
                .catch((e) => console.log("Connection failed: ", e));
        }
    }, [connection]);

    const SetAllRead = async () => {
        await readAllNotification().unwrap();
        checkHandle(0);
    };

    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column w-400px notify-menu border"
            data-kt-menu="true"
        >
            <div className="d-flex bgi-no-repeat rounded-top justify-content-between border-bottom">
                <span className="fw-boldest px-4 my-3 font-size-18">
                    Уведомления
                </span>
                <span className="px-5 my-3 av-btn fw-bold" onClick={SetAllRead}>
                    Отметить прочитанными
                </span>
            </div>
            <div className="scroll-y mh-325px mt-3 notify-menu-panel">
                {data?.result?.map((alert, index) => (
                    <div
                        key={`alert${index}`}
                        className="d-flex flex-stack notify-message mb-3 border-bottom px-4"
                    >
                        <div className="d-flex">
                            <div className="symbol symbol-48px me-4">
                                <span
                                    className={clsx(
                                        "symbol-label",
                                        `bg-light-${alert.state}`
                                    )}
                                >
                                    {" "}
                                    <KTSVG
                                        path={`/media/${alert.icon}`}
                                        className={`svg-icon-2 svg-icon-${alert.state}`}
                                    />
                                </span>
                            </div>
                            <div className="mb-0">
                                <b>
                                    {alert.subject}
                                    <span className="badge badge-new ms-2">
                                        Новое
                                    </span>
                                </b>
                                <div className="text-gray-600 mb-3">
                                    {alert.message}
                                </div>
                                <div className="notify-date mb-3">
                                    {FormatDateToHuman(
                                        UtcToLocal(alert.time),
                                        true
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className="text-center pb-3">
                <Link
                    to="/personal/notifications"
                    className="btn btn-color-gray-600 btn-active-color-primary"
                >
                    Посмотреть все{" "}
                    <KTSVG
                        path="/media/icons/duotune/arrows/arr064.svg"
                        className="svg-icon-5"
                    />
                </Link>
            </div>
        </div>
    );
};

export default HeaderNotificationsMenu;
