import { FormatDateToHuman, UtcToLocal } from '../../helpers/DateHelpers';
import { baseApi } from '../baseApi';

export const SchedulesAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSchedules: builder.query({
            query: (data) =>
                `schedules?accountId=${data.accountId}` +
                `&sort=${data.sort ?? ''}` +
                `&search=${data.search ?? ''}` +
                `&pageIndex=${data.pageIndex ?? 1}` +
                `&pageSize=${data.pageSize ?? 100}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Schedules',
                              id,
                          })),
                          { type: 'Schedules', id: 'List' },
                      ]
                    : [{ type: 'Schedules', id: 'List' }],
        }),

        getSchedule: builder.query({
            query: (id) => `schedules/${id}`,
            providesTags: (result) => [
                { type: 'Schedule', id: result.result.id },
            ],
        }),

        updateSchedule: builder.mutation({
            query: (body) => ({
                url: 'schedules/' + body.id,
                method: 'PUT',
                body: {...body},
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Schedules', id: 'List' },
                { type: 'Schedule', id: result.result.id },
            ],
        }),

        createSchedule: builder.mutation({
            query: (body) => ({
                url: 'schedules',
                method: 'POST',
                body: {...body},
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Schedules', id: 'List' },
            ],
        }),

        deleteSchedule: builder.mutation({
            query: (body) => ({
                url: 'schedules/' + body.id,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Schedules', id: 'List' },
            ],
        }),


        getScheduleDetails: builder.query({
            query: (id) =>
                `schedules/${id}/details`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'ScheduleDetails',
                              id,
                          })),
                          { type: 'ScheduleDetails', id: 'List' },
                      ]
                    : [{ type: 'ScheduleDetails', id: 'List' }],
        }),

        createScheduleDetail: builder.mutation({
            query: (body) => ({
                url: `schedules/${body.scheduleId}/details`,
                method: 'POST',
                body: {...body},
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ScheduleDetails', id: 'List' },
            ],
        }),

        updateScheduleDetail: builder.mutation({
            query: (body) => ({
                url: `schedules/details/${body.id}`,
                method: 'PUT',
                body: {...body},
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ScheduleDetails', id: 'List' },
                { type: 'ScheduleDetail', id: result.result.id },
            ],
        }),

        deleteScheduleDetail: builder.mutation({
            query: (body) => ({
                url: `schedules/details/${body.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ScheduleDetails', id: 'List' },
            ],
        }),

        addDevice: builder.mutation({
            query: (body) => ({
                url: `schedules/${body.id}/device/${body.deviceId}`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Devices', id: 'List' },
            ],
        }),

        deleteDevice: builder.mutation({
            query: (body) => ({
                url: `schedules/${body.id}/device/${body.deviceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Devices', id: 'List' },
            ],
        }),



    }),
});

export const {
    useGetSchedulesQuery,
    useGetScheduleQuery,
    useLazyGetSchedulesQuery,
    useLazyGetScheduleQuery,
    useCreateScheduleMutation,
    useCreateScheduleDetailMutation,
    useUpdateScheduleMutation,
    useUpdateScheduleDetailMutation,
    useDeleteScheduleMutation,
    useDeleteScheduleDetailMutation,
    useAddDeviceMutation,
    useDeleteDeviceMutation,
    useGetScheduleDetailsQuery,
} = SchedulesAPI;
