import { baseApi } from '../baseApi';

export const locationsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getLocations: builder.query({
            query: (query) =>
                `locations?accountId=${query?.accountId ?? ''}&sort=${query?.sort ?? ''}&pageIndex=${query?.pageIndex ?? 1}&pageSize=${
                    query?.pageSize ?? 50
                }&withParents=${query?.withParents ?? false}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Locations',
                              id,
                          })),
                          { type: 'Locations', id: 'List' },
                      ]
                    : [{ type: 'Locations', id: 'List' }],
        }),
        getLocationsById: builder.query({
            query: (id) => `locations/${id}`,
            providesTags: (result) => [{ type: 'Location', id: result?.result.id }],
        }),
        createLocation: builder.mutation({
            query: (body) => ({
                url: 'locations',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Locations', id: 'List' }],
        }),
        updateLocation: builder.mutation({
            query: (body) => ({
                url: 'locations/' + body.id,
                method: 'PUT',
                body,
            }),
            invalidatesTags: (result) => [
                { type: 'Locations', id: 'List' },
                { type: 'Location', id: result?.result.id },
            ],
        }),
        deleteLocation: builder.mutation({
            query: (id) => ({
                url: 'locations/' + id,
                method: 'DELETE',
                body: undefined,
            }),
            invalidatesTags: [{ type: 'Locations', id: 'List' }],
        }),
    }),
});

export const {
    useGetLocationsQuery,
    useLazyGetLocationsQuery,
    useGetLocationsByIdQuery,
    useCreateLocationMutation,
    useUpdateLocationMutation,
    useDeleteLocationMutation,
} = locationsApiSlice;
