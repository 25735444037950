/* eslint-disable react/jsx-no-target-blank */
import { MenuItem } from './MenuItem';
import { useRouteMatch } from 'react-router';

interface LayoutParams {
    accountId: string;
}

export function MenuUser() {
    const matchParams = useRouteMatch().params as LayoutParams;

    return (
        <>
            <MenuItem
                to={`/personal/overview`}
                title='Обзор'
                imageIcon='/media/icons/avanguard/Home1.png'
            />
            <MenuItem
                to={`/personal/profile`}
                title='Профиль'
                imageIcon='/media/icons/avanguard/JustUser.png'
            />
            {/*<MenuItem
                to={`/personal/accesslogs`}
                title='Журнал доступа'
                fontIcon='fas fa-newspaper'
            />*/}
            <MenuItem
                to={`/personal/notifications`}
                title='Уведомления'
                imageIcon='/media/icons/avanguard/bell.png'
            />
        </>
    );
}
