import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const parkingTariffsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getParkingTariffs: builder.query({
            query: (data) =>
                `parking/tariffs?accountId=${data.accountId ?? ""}&sort=${
                    data.sort ?? ""
                }&pageIndex=${data.pageIndex ?? 1}&pageSize=${
                    data.pageSize ?? 100
                }`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "ParkingTariffs",
                              id,
                          })),
                          { type: "ParkingTariffs", id: "List" },
                      ]
                    : [{ type: "ParkingTariffs", id: "List" }],
        }),
        getParkingTariffById: builder.query({
            query: (id) => `parking/tariffs/${id}`,
            providesTags: (result) => [
                { type: "ParkingTariff", id: result?.result.id },
            ],
        }),
        createParkingTariff: builder.mutation({
            query: (body) => ({
                url: "parking/tariffs",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "ParkingTariffs", id: "List" }],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка создания!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось создать тариф! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Тариф создан!",
                                description: "Тариф успешно создан!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка создания!",
                            description:
                                "Не удалось создать тариф! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        updateParkingTariff: builder.mutation({
            query: (body) => ({
                url: "parking/tariffs/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "ParkingTariffs", id: "List" },
                { type: "ParkingTariff", id: result?.result.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить данные тарифа! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Тариф сохранен!",
                                description: "Данные тарифа успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить данные тарифа! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteParkingTariff: builder.mutation({
            query: (id) => ({
                url: "parking/tariffs/" + id,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "ParkingTariffs", id: "List" }],
        }),
    }),
});

export const {
    useGetParkingTariffsQuery,
    useLazyGetParkingTariffsQuery,
    useGetParkingTariffByIdQuery,
    useLazyGetParkingTariffByIdQuery,
    useCreateParkingTariffMutation,
    useUpdateParkingTariffMutation,
    useDeleteParkingTariffMutation,
} = parkingTariffsApiSlice;
