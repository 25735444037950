import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
} from '../../app/storeReducers/AccountLocationsSlice';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { useHistory } from 'react-router-dom';
import LocationCreateModal from './LocationCreateModal';
import SecutiryContext from '../../features/securityContext';

const LocationsList = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);

    const { context } = useContext(SecutiryContext);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);

    useEffect(() => {
        dispatch(getListAsync([{ key: 'accountId', value: accountId }]));
    }, [accountId, dispatch]);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(
                `/account/${accountId}/admin/locations/${entityId}`,
                '_blank'
            );
        else history.push(`/account/${accountId}/admin/locations/${entityId}`);
    };

    return (
        <>
            <LocationCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className='row'>
                <div className='col-md-4 mb-3'>
                    <button
                        className='btn av-btn-primary fw-boldest'
                        onClick={() => openCreateModal(0)}
                        disabled={!context.isRoot}
                    >
                        <i className='fas fa-plus me-3' />
                        Новый объект
                    </button>
                </div>
            </div>
            <div className='card border'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Название', 'Адрес', 'Порт', 'Путь']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Название
                                    </th>
                                    <th className='border-bottom ps-6'>
                                        Адрес
                                    </th>
                                    <th className='border-bottom ps-6'>
                                        Кол-во устройств
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className='cursor-pointer'
                                    >
                                        <td className='ps-6'>{item.name}</td>
                                        <td className='ps-6'>{item.address}</td>
                                        <td className='ps-6'>
                                            {item.devicesCount}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default LocationsList;
