import { baseApi } from '../baseApi';

export const AccountVehiclesAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getVehicles: builder.query({
            query: ({
                accountId = 0,
                employeeId = null,
                search = '',
                pageIndex = 1,
                pageSize = 100,
            }) =>
                `vehicles?accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}&employeeId=${employeeId}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Vehicles',
                              id,
                          })),
                          { type: 'Vehicles', id: 'List' },
                      ]
                    : [{ type: 'Vehicles', id: 'List' }],
            transformResponse: (response) => {
                response?.result.forEach((item, index) => {
                    item.accountTitle = item.account.title;
                    item.fullNumber = item.carNumber + ' ' + item.carRegion;
                });
                console.log(response);
                return response;
            },
        }),
    }),
});

export const { useGetVehiclesQuery } = AccountVehiclesAPI;
