import React from 'react';
import NotificationItem from './NotificationItem';
import { selectNotifications } from '../../app/storeReducers/NotifySlice';
import { useSelector } from 'react-redux';

const NotificationsPanel = () => {
    const notifyArray = useSelector(selectNotifications);

    return (
        <div className="notifications-panel">
            {notifyArray !== undefined
                ? notifyArray.map((item) => (
                      <NotificationItem key={item.dateNotify} notify={item} />
                  ))
                : null}
        </div>
    );
};

export default NotificationsPanel;
