import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonInput from "../../components/skeleton/SkeletonInput";
import { ToUtcString } from "../../helpers/DateHelpers";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import GuestDeleteModal from "./GuestDeleteModal";
import { useHistory } from "react-router";
import {
    useArchiveGuestMutation,
    useGetGuestByIdQuery,
    useUnarchiveGuestMutation,
    useUpdateGuestMutation,
} from "../../app/storeReducers/guestsApiSlice";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetAccountByIdQuery } from "../../app/storeReducers/acountsApiSlice";

const GuestEditPanel = (props) => {
    const { accountId, guestId } = props;

    const context = useSelector(selectContext);

    const history = useHistory();

    const [name, setName] = useState("");
    const [carNumber, setCarNumber] = useState("");
    const [carRegion, setCarRegion] = useState("");
    const [guestDateStart, setGuestDateStart] = useState(new Date());
    const [guestDateFinish, setGuestDateFinish] = useState(new Date());
    const [login, setLogin] = useState("");
    const [guid, setGuid] = useState("");
    const [fieldRestrictions, setFieldRestrictions] = useState("");
    const [accountName, setAccountName] = useState("");
    const [needExitConfirmation, setNeedExitConfirmation] = useState(false);
    const [pinCode, setPinCode] = useState("");

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const [canEdit, setCanEdit] = useState(false);

    const { data: guest, isLoading } = useGetGuestByIdQuery(guestId, {
        skip: !guestId,
    });
    const { data: account } = useGetAccountByIdQuery(accountId, {
        skip: !accountId,
    });
    const [updateAsync] = useUpdateGuestMutation();
    const [archiveAsync] = useArchiveGuestMutation();
    const [unarchiveAsync] = useUnarchiveGuestMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            Date.prototype.addHours = function (h) {
                this.setTime(this.getTime() + h * 60 * 60 * 1000);
                return this;
            };
            setName(guest.result.name);
            setLogin(guest.result.user?.title ?? "");
            setGuestDateStart(new Date(guest.result.dateStart).addHours(3));
            setGuestDateFinish(new Date(guest.result.dateFinish).addHours(3));
            setCarNumber(guest.result.carNumber);
            setCarRegion(guest.result.carRegion);
            setGuid(guest.result.guid);
            setFieldRestrictions(guest.result.fieldRestrictions ?? "");
            setAccountName(guest.result.account.title);
            setNeedExitConfirmation(guest.result.needExitConfirmation);
            Array.from(
                document.querySelectorAll('[data-bs-toggle="tooltip"]')
            ).forEach((tooltipNode) => new Tooltip(tooltipNode));
            if (account?.result.guestPinCode && guest.result.cardNumber) {
                setPinCode(guest.result.cardNumber);
            }
        }
        if (guest && account) {
            loadResourcesAndDataAsync();
        }
    }, [guest, account]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("guest_edit"));
    }, [context, accountId]);

    const saveEntity = async () => {
        let entity = { ...guest.result };
        entity.name = name;
        entity.dateStart = new Date(
            guestDateStart.getFullYear(),
            guestDateStart.getMonth(),
            guestDateStart.getDate(),
            0,
            0,
            0
        );
        entity.dateFinish = new Date(
            guestDateFinish.getFullYear(),
            guestDateFinish.getMonth(),
            guestDateFinish.getDate(),
            23,
            59,
            59
        );
        entity.carNumber = carNumber;
        entity.carRegion = carRegion;
        entity.needExitConfirmation = needExitConfirmation;

        updateAsync(entity);
    };

    const archive = async () => {
        await archiveAsync(guestId);
        history.goBack();
    };

    const unarchive = async () => {
        await unarchiveAsync(guestId);
        history.goBack();
    };

    function GetArchiveButton() {
        if (guest?.result.isArchived) {
            return (
                <button
                    className="btn av-btn-danger fw-boldest"
                    onClick={() => unarchive()}
                    disabled={!canEdit}
                >
                    <i className="far fa-trash-alt me-2" /> Из архива
                </button>
            );
        } else {
            return (
                <button
                    className="btn av-btn-danger fw-boldest"
                    onClick={() => archive()}
                    disabled={!canEdit}
                >
                    <i className="far fa-trash-alt me-2" /> В архив
                </button>
            );
        }
    }

    const changeDateFrom = (dt) => {
        setGuestDateStart(new Date(dt));
        if (account?.result?.oneDayTicket) {
            setGuestDateFinish(new Date(dt));
        }
    };

    const changeAcceptExit = (e) => {
        setNeedExitConfirmation(e.target.checked);
        if (e.target.checked === false) {
            setGuestDateFinish(new Date());
        }
    };

    return (
        <>
            <GuestDeleteModal
                entity={guest?.result}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <h1 className="mb-5">Карточка посетителя</h1>
            <div className="card border">
                <div className="card-body pb-3">
                    <div className="row mb-3">
                        <div className="col">
                            <h3>Посетитель</h3>
                        </div>
                    </div>
                    <div className="mb-4">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="accountName"
                            label="Аккаунт"
                            value={accountName}
                            readOnly
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="name" className="form-label">
                            Период посещения
                        </label>
                        <div className="input-group mb-3">
                            <span className="input-group-text">С</span>
                            <input
                                type="date"
                                className="form-control"
                                value={
                                    ToUtcString(guestDateStart).split("T")[0]
                                }
                                onChange={(e) => changeDateFrom(e.target.value)}
                                readOnly={fieldRestrictions.includes("s")}
                            />
                            <span className="input-group-text">ПО</span>
                            <input
                                type="date"
                                className="form-control"
                                value={
                                    ToUtcString(guestDateFinish).split("T")[0]
                                }
                                onChange={(e) =>
                                    setGuestDateFinish(new Date(e.target.value))
                                }
                                disabled={account?.result?.oneDayTicket}
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="name"
                            label="ФИО"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="login"
                            label="Телефон"
                            value={login}
                            readOnly
                        />
                    </div>
                    {!account?.result?.banGuestParking ? (
                        <div className="form-group mb-4">
                            <label className="form-label">
                                Номер автомобиля
                            </label>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="x000xx"
                                    value={carNumber}
                                    readOnly={fieldRestrictions.includes("c")}
                                    onChange={(e) =>
                                        setCarNumber(e.target.value)
                                    }
                                />
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="000"
                                    value={carRegion}
                                    readOnly={fieldRestrictions.includes("c")}
                                    onChange={(e) =>
                                        setCarRegion(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ) : null}
                    {guid !== "" && guid !== null ? (
                        <div className="mb-3 position-relative">
                            <label htmlFor="name" className="form-label">
                                QR код
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="qrcode"
                                value={`https://qr.avanguard.pro/${guid}`}
                                readOnly
                            />
                            <span
                                className="fas fa-copy cursor-pointer password-eye"
                                data-bs-toggle="tooltip"
                                title="Скопировать ссылку"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `https://qr.avanguard.pro/${guid}`
                                    );
                                }}
                            />
                        </div>
                    ) : null}
                    {pinCode !== "" && pinCode !== null ? (
                        <div className="mb-3 position-relative">
                            <label htmlFor="name" className="form-label">
                                PIN-код
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="qrcode"
                                value={pinCode}
                                readOnly
                            />
                            <span
                                className="fas fa-copy cursor-pointer password-eye"
                                data-bs-toggle="tooltip"
                                title="Скопировать PIN-код"
                                onClick={() => {
                                    navigator.clipboard.writeText(pinCode);
                                }}
                            />
                        </div>
                    ) : null}
                    {guest?.result?.needExitConfirmation ? (
                        <div className="form-check form-check-inline form-group my-2">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="needExitConfirmation"
                                checked={needExitConfirmation}
                                onChange={changeAcceptExit}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="needExitConfirmation"
                            >
                                Запрет выезда с парковки
                            </label>
                        </div>
                    ) : null}
                    <div className="row mb-4">
                        <div className="col-md-8">
                            <GetArchiveButton />
                        </div>
                        <div className="col text-end">
                            <button
                                type="button"
                                className="btn av-btn-simple fw-boldest"
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GuestEditPanel;
