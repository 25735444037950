import { useEffect, useState } from "react";
import EmployeeGroupDeleteModal from "./EmployeeGroupDeleteModal";
import EmployeeAddGroupModal from "./EmployeeAddGroupModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import { useGetGroupsQuery } from "../../../../app/storeReducers/groupsApiSlice";

const EmployeeGroupsPanel = (props) => {
    const { accountId, employeeId } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [deleteGroup, setDeleteGroup] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const { data: groups, refetch } = useGetGroupsQuery(
        { accountId, employeeId },
        { skip: !employeeId }
    );

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("employees_edit"));
        setCanList(context.isRoot || actions?.includes("employees_list"));
    }, [context, accountId]);

    const okAddMember = () => {
        refetch();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (grp) => {
        setDeleteGroup(grp);
        setDeleteModalIsOpen(true);
    };

    const deleteMemeber = () => {
        refetch();
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <EmployeeAddGroupModal
                accountId={accountId}
                employeeId={employeeId}
                isOpen={addModalIsOpen}
                okHandle={okAddMember}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <EmployeeGroupDeleteModal
                employeeId={employeeId}
                group={deleteGroup}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteMemeber}
            />
            <div className="row mb-5">
                <div className="col-md-6">
                    <div className="av-card-title">
                        Группы
                        <span className="badge rounded-pill bg-light text-dark ms-2 align-text-top">
                            {groups?.result?.length}
                        </span>
                    </div>
                </div>
                <div className="col-md-6 text-end">
                    {canEdit ? (
                        <button
                            className="btn btn-sm btn-light-primary fw-boldest av-text-gray-600 pe-0 py-1"
                            onClick={() => setAddModalIsOpen(true)}
                        >
                            <img
                                src="/media/icons/avanguard/plus-gray-500.png"
                                className="me-2 align-top"
                                height={20}
                            />
                            Присвоить группы
                        </button>
                    ) : null}
                </div>
            </div>
            <div className="row mb-10">
                <div className="col">
                    {canList ? (
                        groups?.result?.map((item) => (
                            <div
                                className="border av-border-gray-300 rounded d-inline-block px-4 py-2 me-2 mb-2 fw-bolder"
                                key={item.id}
                            >
                                {item.name}{" "}
                                {canEdit ? (
                                    <img
                                        src="/media/icons/avanguard/x-gray-400.png"
                                        className="ms-2 cursor-pointer align-top"
                                        onClick={() => openDeleteModal(item)}
                                        height={20}
                                    />
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                </div>
            </div>
        </>
    );
};

export default EmployeeGroupsPanel;
