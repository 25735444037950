import { FC, useEffect, useState } from 'react';
import { useAccount } from '../../../components/useAccount';
import { useHistory } from 'react-router-dom';
import { useBreadCrumbs } from '../../useBreadCrumbs';

export interface AccountLink {
    id: number;
    title: string;
}

const AccountBreadcrumbs: FC = () => {
    const history = useHistory();
    const account = useAccount();
    const breadcrumbs = useBreadCrumbs();

    const [leftTitle, setLeftTitle] = useState('');
    const [rightTitle, setRightTitle] = useState('');
    const [leftMenuIsOpen, setLeftMenuIsOpen] = useState(false);
    const [rightMenuIsOpen, setRightMenuIsOpen] = useState(false);
    const [canSelectSubaccounts, setCanSelectSubaccounts] = useState(false);

    useEffect(() => {
        if (account.parent) {
            setLeftTitle(account.parent.title);
            setRightTitle(account.name);
        } else {
            setLeftTitle(account.name);
            setRightTitle('.');
        }
    }, [account]);

    useEffect(() => {
        if (breadcrumbs.rightLinks?.length > 1) {
            setCanSelectSubaccounts(true);
        } else {
            setCanSelectSubaccounts(false);
        }
    }, [breadcrumbs]);

    const openAccount = (accountId: any) => {
        history.push(`/account/${accountId}/dashboard`);
        setLeftMenuIsOpen(false);
        setRightMenuIsOpen(false);
    };

    return (
        <>
            <div className='dropdown d-inline-block'>
                <button
                    className='btn btn-xs av-btn-dropdown fw-bolder p-0 shadow-none'
                    type='button'
                    id='dropdownMenuButton1'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                    onClick={() => {
                        setLeftMenuIsOpen(!leftMenuIsOpen);
                        setRightMenuIsOpen(false);
                    }}
                >
                    <span className='menu-title av-toolbar-menu me-2'>
                        {leftTitle}
                    </span>
                    <img
                        src='/media/icons/avanguard/chevron-down.png'
                        height='20px'
                        className='align-top'
                    />
                </button>
                <ul
                    className={
                        'dropdown-menu scrollable-menu border' +
                        (leftMenuIsOpen ? ' show' : '')
                    }
                    aria-labelledby='dropdownMenuButton1'
                >
                    {breadcrumbs.leftLinks.map((item: any, key: any) => {
                        return (
                            <li key={key}>
                                <a
                                    onClick={() => openAccount(item.id)}
                                    className={
                                        'btn btn-link btn-sm dropdown-item d-flex justify-content-between' +
                                        (account.id === item.id ||
                                        account.parent?.id === item.id
                                            ? ' active'
                                            : '')
                                    }
                                >
                                    {item.title}{' '}
                                    {account.id === item.id ||
                                    account.parent?.id === item.id ? (
                                        <img
                                            src='/media/icons/avanguard/check.png'
                                            height='20px'
                                            className='ms-2'
                                        />
                                    ) : null}
                                </a>
                            </li>
                        );
                    })}
                </ul>
            </div>

            {rightTitle &&
                canSelectSubaccounts == false &&
                account?.type !== 'basic' && (
                    <span className='menu-title av-toolbar-menu'>
                        <img
                            src='/media/icons/avanguard/slash-divider.png'
                            className='ms-5 me-5'
                            height='24px'
                        />
                        {rightTitle}
                    </span>
                )}

            {rightTitle && canSelectSubaccounts == true && (
                <div className='dropdown d-inline-block'>
                    <button
                        className='btn btn-xs av-btn-dropdown fw-bolder p-0 shadow-none'
                        type='button'
                        id='dropdownMenuButton2'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                        onClick={() => {
                            setRightMenuIsOpen(!rightMenuIsOpen);
                            setLeftMenuIsOpen(false);
                        }}
                    >
                        <span className='menu-title av-toolbar-menu me-2'>
                            <img
                                src='/media/icons/avanguard/slash-divider.png'
                                className='ms-2 me-2 align-top'
                                height='24px'
                            />
                            {rightTitle}
                        </span>
                        <img
                            src='/media/icons/avanguard/chevron-down.png'
                            height='20px'
                            className='align-top'
                        />
                    </button>
                    <ul
                        className={
                            'dropdown-menu scrollable-menu border' +
                            (rightMenuIsOpen ? ' show' : '')
                        }
                        aria-labelledby='dropdownMenuButton2'
                    >
                        {breadcrumbs.rightLinks.map((item: any, key: any) => {
                            return (
                                <li key={key}>
                                    <a
                                        onClick={() => openAccount(item.id)}
                                        className={
                                            'btn btn-link btn-sm dropdown-item d-flex justify-content-between' +
                                            (account.id === item.id
                                                ? ' active'
                                                : '')
                                        }
                                    >
                                        {item.title}{' '}
                                        {account.id === item.id ? (
                                            <img
                                                src='/media/icons/avanguard/check.png'
                                                height='20px'
                                                className='ms-2'
                                            />
                                        ) : null}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

            {account?.isBlocked === true && (
                <span
                    className='menu-title av-toolbar-menu'
                    style={{ color: 'red' }}
                >
                    &nbsp;- ЗАБЛОКИРОВАН
                </span>
            )}
        </>
    );
};

export { AccountBreadcrumbs };
