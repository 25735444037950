import { useEffect, useState } from 'react';
import { Http } from '../../../../http';
import { useDispatch } from 'react-redux';
import { updateAsync } from '../../../../app/storeReducers/AdminHostsSlice';
import SkeletonInput from '../../../../components/skeleton/SkeletonInput';
import { addNotification } from '../../../../app/storeReducers/NotifySlice';

const HostEditPanel = (props) => {
    const { entityId } = props;

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [comments, setComments] = useState('');
    const [port, setPort] = useState('');
    const [apiKey, setApiKey] = useState('');

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);

            const getResult = await Http.get('api/hosts/' + entityId, true);
            const hostResult = getResult.result;

            setName(hostResult.name);
            setComments(hostResult.comments);
            setAddress(hostResult.address);
            setPort(hostResult.port);
            setApiKey(hostResult.apiKey);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    const saveEntity = async () => {
        const entity = {
            id: entityId,
            name,
            comments,
            address,
            port,
        };
        console.log('hostUpd', entity);
        dispatch(updateAsync(entity));
    };

    const syncHost = async () => {
        const syncResult = await Http.put(`api/hosts/${entityId}/sync`, true, null);
        dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: 'Обновление конфигурации!',
                description: 'Обновление запущено.',
                type: 'success',
            })
        );

    };


    return (
        <div className='card'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                        Аккаунт
                    </span>
                </h3>
            </div>
            <div className='card-body py-3'>
                <div className='mb-3'>
                    <SkeletonInput
                        isLoading={isLoading}
                        id='name'
                        label='Название'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div className='row mb-3'>
                    <div className='col-md-9'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='address'
                            label='Адрес'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className='col-md-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='port'
                            label='Порт'
                            value={port}
                            onChange={(e) => setPort(e.target.value)}
                        />
                    </div>
                </div>
                <div className='mb-3'>
                    <label htmlFor='comments' style={{fontWeight: 600}}>Комментарий</label>
                    <textarea
                        id='comments'
                        className='form-control mt-2'
                        value={comments}
                        onChange={(e) =>
                            setComments(e.target.value)
                        }
                    />
                </div>
                <div className='mb-3'>
                    <SkeletonInput
                        isLoading={isLoading}
                        id='apiKey'
                        label='Ключ API'
                        value={apiKey}
                        readOnly
                    />
                </div>
                <div className='d-grid mb-4'>
                    <button
                        type='button'
                        className='btn btn-success'
                        onClick={saveEntity}
                    >
                        Сохранить
                    </button>
                </div>
                <div className='d-grid mt-4'>
                    <button
                        type='button'
                        className='btn btn-info'
                        onClick={syncHost}
                    >
                        <i className="fas fa-sync"></i> Обновить конфигурацию
                    </button>
                </div>
            </div>
        </div>
    );
};

export default HostEditPanel;
