const FullScreenModal = (props) => {
    const { image, isOpen, closeHandle } = props;

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
                style={{ zIndex: 9999 }}
            >
                <div
                    className='modal-dialog modal-dialog-centered'
                    style={{ maxWidth: 'none' }}
                >
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Полноэкранный просмотр
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body text-center'>
                            <img className='img-fluid' src={image} />
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default FullScreenModal;
