import { useEffect, useState } from "react";
import AddVehicleModal from "./AddVehicleModal";
import RemoveVehicleModal from "./RemoveVehicleModal";
import { useGetServicesQuery } from "../../../../../../app/storeReducers/servicesApiSlice";
import { useGetVehicleByIdQuery } from "../../../../../../app/storeReducers/AccountVehiclesAPI";

const VehicleControlPanel = (props) => {
    const { accountId, placeId, vehicleId, addHandle } = props;

    const [isOpenAddModal, setIsOpenAddModal] = useState(false);
    const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
    const [serviceAccountId, setServiceAccountId] = useState(0);

    const { data: servicesList } = useGetServicesQuery(
        { accountId, placeId },
        {
            skip: !accountId,
        }
    );

    const { data: vehicle } = useGetVehicleByIdQuery(vehicleId, {
        skip: !vehicleId,
    });

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            let selectedAccountId = 0;
            if (servicesList?.result.length > 0) {
                selectedAccountId = servicesList.result[0].consumer.id;
            }
            setServiceAccountId(selectedAccountId);
        }

        loadResourcesAndDataAsync();
    }, [servicesList]);

    const addVehicle = (vehicleChanged) => {
        setIsOpenAddModal(false);
        addHandle(vehicleChanged);
    };

    const removeVehicle = () => {
        setIsOpenRemoveModal(false);
        addHandle(null);
    };

    return (
        <>
            <AddVehicleModal
                accountId={accountId}
                placeId={placeId}
                selectedAccount={serviceAccountId}
                isOpen={isOpenAddModal}
                okHandle={(vehicleChanged) => addVehicle(vehicleChanged)}
                closeHandle={() => setIsOpenAddModal(false)}
            />
            <RemoveVehicleModal
                vehicle={vehicle?.result}
                isOpen={isOpenRemoveModal}
                okHandle={removeVehicle}
                closeHandle={() => setIsOpenRemoveModal(false)}
            />
            <div className="card border mb-3">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col-md-6 align-self-center">
                            <h3>Транспортное средство</h3>
                        </div>
                    </div>
                    {vehicleId ? (
                        <>
                            <div className="row mb-3">
                                <div className="col-md-3">Номер</div>
                                <div className="col-md-9">
                                    <b>
                                        {vehicle?.result.carNumber +
                                            vehicle?.result.carRegion}
                                    </b>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Модель</div>
                                <div className="col-md-9">
                                    <b>{vehicle?.result.model}</b>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Аккаунт</div>
                                <div className="col-md-9">
                                    <b>{vehicle?.result.account?.title}</b>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">Сотрудник</div>
                                <div className="col-md-9">
                                    <b>{vehicle?.result.employee?.title}</b>
                                </div>
                            </div>
                            <div className="d-grid gap-2">
                                <button
                                    className="btn av-btn-simple"
                                    type="button"
                                    onClick={() => setIsOpenRemoveModal(true)}
                                >
                                    Отвязать транспортное средство
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>
                                <i>Транспортное средство не привязано</i>
                            </p>
                            <div className="d-grid gap-2">
                                <button
                                    className="btn av-btn-simple"
                                    type="button"
                                    onClick={() => setIsOpenAddModal(true)}
                                >
                                    Привязать транспортное средство
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default VehicleControlPanel;
