import { baseApi } from '../baseApi';
import { addNotification } from './NotifySlice';

export const AccountMonitorAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getMonitors: builder.query({
            query: (accountId) => `monitors?accountId=${accountId}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Monitors',
                              id,
                          })),
                          { type: 'Monitors', id: 'List' },
                      ]
                    : [{ type: 'Monitors', id: 'List' }],
        }),
        addMonitor: builder.mutation({
            query: (body) => ({
                url: 'monitors',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'Monitors', id: 'List' }],
        }),
        updateMonitor: builder.mutation({
            query: (body) => ({
                url: 'monitors/' + body.id,
                method: 'PUT',
                body,
            }),
            invalidatesTags: [{ type: 'Monitors', id: 'List' }],
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // `onSuccess` side-effect
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Сохранено!',
                            description: 'Данные успешно сохранены!',
                            type: 'success',
                        })
                    );
                } catch (err) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Ошибка сохранения!',
                            description:
                                'Не удалось сохранить данные! Перезагрузите страницу и попробуйте снова!',
                            type: 'danger',
                        })
                    );
                }
            },
        }),
        deleteMonitor: builder.mutation({
            query: (monitorId) => ({
                url: 'monitors/' + monitorId,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Monitors', id: 'List' }],
        }),
        getMonitorDevices: builder.query({
            query: (monitorId) => `monitors/${monitorId}/devices`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Devices',
                              id,
                          })),
                          { type: 'Devices', id: 'List' },
                      ]
                    : [{ type: 'Devices', id: 'List' }],
        }),
        addMonitorDevice: builder.mutation({
            query: ({ monitorId, deviceId }) => ({
                url: `monitors/${monitorId}/devices/${deviceId}`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Devices', id: 'List' }],
        }),
        deleteMonitorDevice: builder.mutation({
            query: ({ monitorId, deviceId }) => ({
                url: `monitors/${monitorId}/devices/${deviceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Devices', id: 'List' }],
        }),
        downMonitorDevice: builder.mutation({
            query: ({ monitorId, deviceId }) => ({
                url: `monitors/${monitorId}/devices/${deviceId}/forward`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Devices', id: 'List' }],
        }),
        upMonitorDevice: builder.mutation({
            query: ({ monitorId, deviceId }) => ({
                url: `monitors/${monitorId}/devices/${deviceId}/back`,
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Devices', id: 'List' }],
        }),
    }),
});

export const {
    useGetMonitorsQuery,
    useAddMonitorMutation,
    useUpdateMonitorMutation,
    useDeleteMonitorMutation,
    useGetMonitorDevicesQuery,
    useAddMonitorDeviceMutation,
    useDeleteMonitorDeviceMutation,
    useUpMonitorDeviceMutation,
    useDownMonitorDeviceMutation,
} = AccountMonitorAPI;
