import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../../../http';
import { useDispatch } from 'react-redux';
import { updateAsync } from '../../../../../app/storeReducers/AccountParkingPlacesSlice';
import SkeletonInput from '../../../../../components/skeleton/SkeletonInput';
import ParkingPlaceDeleteModal from '../ParkingPlaceDeleteModal';
import { useHistory } from 'react-router';
import SecutiryContext from '../../../../../features/securityContext';

const PlaceEditPanel = (props) => {
    const { accountId, parkingId, entityId, placeLoaded } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [number, setNumber] = useState('');

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getResult = await Http.get(
                'api/parking/places/' + entityId,
                true
            );
            setNumber(getResult.result.number);
            placeLoaded(getResult.result);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('parking_place_edit'));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            number,
            id: entityId,
        };
        dispatch(updateAsync(entity));
    };

    const deletePlace = () => {
        history.push(`/account/${accountId}/parking/${parkingId}/places`);
    };

    return (
        <>
            <ParkingPlaceDeleteModal
                entity={{ id: entityId, number }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                deleteHandle={deletePlace}
            />
            <div className='card border mb-3'>
                <div className='card-body pb-2'>
                    <div className='row mb-3'>
                        <div className='col'>
                            <h3>Парковочное место</h3>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='number'
                            label='Номер'
                            value={number}
                            onChange={(e) => setNumber(e.target.value)}
                        />
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <button
                                className='btn av-btn-danger fw-boldest'
                                onClick={() => setDeleteModalIsOpen(true)}
                            >
                                <i className='far fa-trash-alt me-2' /> Удалить
                            </button>
                        </div>
                        <div className='col-md-6 text-end'>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PlaceEditPanel;
