import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DeviceCreateModal from "./DeviceCreateModal";
import { KTSVG } from "../../_metronic/helpers";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { useHistory } from "react-router-dom";
import ThSortable from "../../components/table/ThSortable";
import FindComponent from "../../components/FindComponent";
import Paginator from "../../components/Paginator";
import { useAccount } from "../../components/useAccount";
import UniubiDeviceCreateModal from "./UniubiDeviceCreateModal";
import { BsTooltip } from "../../components/BsTooltip";
import TimeTrackingDeviceCreateModal from "./TimeTrackingDeviceCreateModal";
import MqttDeviceCreateModal from "./MqttDeviceCreateModal";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetDevicesQuery } from "../../app/storeReducers/devicesApiSlice";
import { useGetLocationsQuery } from "../../app/storeReducers/locationsApiSlice";

const DevicesList = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const context = useSelector(selectContext);

    const [sort, setSort] = useState("name");
    const [sortDest, setSortDest] = useState("asc");
    const [sortEntity, setSortEntity] = useState("name");
    const [searchString, setSearchString] = useState("");
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);

    const [showButtons, setShowButtons] = useState(false);

    const account = useAccount();

    const { data: entityList, isLoading } = useGetDevicesQuery(
        {
            accountId,
            search: searchString,
            sort: sortEntity,
            pageSize,
            pageIndex,
        },
        { skip: !accountId }
    );

    const { data: locations } = useGetLocationsQuery(
        { accountId },
        { skip: !accountId }
    );

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const actions = context?.permissions?.find(
                (x) => x.id === parseInt(accountId)
            )?.actions;
            setCanEdit(context?.isRoot || actions?.includes("devices_edit"));
        }
        loadResourcesAndDataAsync();
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [uniubiCreateModalIsOpen, setUniubiCreateModalIsOpen] =
        useState(false);
    const [timetrackingCreateModalIsOpen, setTimetrackingCreateModalIsOpen] =
        useState(false);
    const [mqttCreateModalIsOpen, setMqttCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);
    const [isLite, setIsLite] = useState(false);

    const openCreateModal = (entityId, lite) => {
        setEditedEntity(entityId);
        setIsLite(lite);
        setCreateModalIsOpen(true);
    };
    const openUniubiCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setUniubiCreateModalIsOpen(true);
    };
    const openMqttCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setMqttCreateModalIsOpen(true);
    };
    const openTimetrackingCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setTimetrackingCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };
    const closeUniubiCreateModal = () => {
        setUniubiCreateModalIsOpen(false);
        setEditedEntity(-1);
    };
    const closeTimetrackingCreateModal = () => {
        setTimetrackingCreateModalIsOpen(false);
        setEditedEntity(-1);
    };
    const closeMqttCreateModal = () => {
        setMqttCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(`/account/${accountId}/devices/${entityId}`, "_blank");
        else history.push(`/account/${accountId}/devices/${entityId}`);
    };

    const openSourceLog = (e, entityId) => {
        e.stopPropagation();
        history.push(`/account/${accountId}/devices/${entityId}/source_log`);
    };
    function StatusLabel(props) {
        const isOnline = props.isOnline;
        const provider = props.provider;
        if (provider === "timetracking") {
            return "";
        }
        if (isOnline) {
            return (
                <span className="badge badge-sm bg-light-success rounded-pill">
                    online
                </span>
            );
        }
        return (
            <span className="badge badge-sm bg-light-secondary rounded-pill">
                offline
            </span>
        );
    }

    const columnFilter = (colName) => {
        const dest =
            colName === sort ? (sortDest === "asc" ? "desc" : "asc") : "asc";
        setSort(colName);
        setSortDest(dest);
        setSortEntity(colName + (dest === "desc" ? "_desc" : ""));
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
    };

    return (
        <>
            <DeviceCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
                isLite={isLite}
                locations={locations?.result}
            />
            <UniubiDeviceCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={uniubiCreateModalIsOpen}
                closeHandle={closeUniubiCreateModal}
                locations={locations?.result}
            />
            <TimeTrackingDeviceCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={timetrackingCreateModalIsOpen}
                closeHandle={closeTimetrackingCreateModal}
                locations={locations?.result}
            />
            <MqttDeviceCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={mqttCreateModalIsOpen}
                closeHandle={closeMqttCreateModal}
                locations={locations?.result}
            />
            <div className="row">
                <div className="col-md-4 mb-3">
                    <div className="btn-group" role="group">
                        <button
                            id="btnGroupDrop1"
                            type="button"
                            className="btn av-btn-primary fw-boldest dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                                setShowButtons(!showButtons);
                            }}
                            disabled={!context?.isRoot}
                        >
                            <i className="fas fa-plus me-3" />
                            Добавить устройство
                        </button>
                        <ul
                            className={
                                "dropdown-menu" + (showButtons ? " show" : "")
                            }
                            aria-labelledby="btnGroupDrop1"
                        >
                            <li>
                                <a
                                    className="dropdown-item fw-bolder"
                                    href="#"
                                    onClick={() => {
                                        openCreateModal(0, false);
                                        setShowButtons(false);
                                    }}
                                >
                                    Контроллер Avanguard
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item fw-bolder"
                                    href="#"
                                    onClick={() => {
                                        openCreateModal(0, true);
                                        setShowButtons(false);
                                    }}
                                >
                                    Контроллер Avanguard Light
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item fw-bolder"
                                    href="#"
                                    onClick={() => {
                                        openTimetrackingCreateModal(0);
                                        setShowButtons(false);
                                    }}
                                >
                                    Контроллер УРВ
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item fw-bolder"
                                    href="#"
                                    onClick={() => {
                                        openMqttCreateModal(0);
                                        setShowButtons(false);
                                    }}
                                >
                                    Контроллер MQTT
                                </a>
                            </li>
                            <li>
                                <a
                                    className="dropdown-item fw-bolder"
                                    href="#"
                                    onClick={() => {
                                        openUniubiCreateModal(0);
                                        setShowButtons(false);
                                    }}
                                >
                                    Терминал Uni-Ubi
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-8 mb-3 d-flex justify-content-end">
                    <FindComponent
                        callback={(name) => setSearchString(name)}
                        placeholder="Поиск по устройству"
                    />
                </div>
            </div>
            <div className="card mb-4 border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={["ID", "Название", "UID", "Управление", "Статус"]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped gy-7 gs-7"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <ThSortable
                                        className="border-bottom ps-6"
                                        colName="id"
                                        colTitle="ID"
                                        colChanged={(name) =>
                                            columnFilter(name)
                                        }
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className="border-bottom"
                                        colName="name"
                                        colTitle="Название"
                                        colChanged={(name) =>
                                            columnFilter(name)
                                        }
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <th className="border-bottom">UID</th>
                                    {account?.type !== "basic" ? (
                                        <th className="border-bottom">
                                            Управление
                                        </th>
                                    ) : null}
                                    <th className="border-bottom">Объект</th>
                                    <th className="border-bottom">Статус</th>
                                    <th className="border-bottom"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className="cursor-pointer"
                                    >
                                        <td className="ps-6 av-text-gray-500 align-middle">
                                            {item.id}
                                        </td>
                                        <td className="fw-bolder align-middle">
                                            {item.name}
                                        </td>
                                        <td className="fw-bolder align-middle">
                                            {item.uid}
                                        </td>
                                        {account?.type !== "basic" ? (
                                            <td className="av-text-gray-500 align-middle">
                                                {item.account.id ===
                                                item.holder.id ? (
                                                    <span className="badge bg-light text-dark">
                                                        {item.holder.title}
                                                    </span>
                                                ) : (
                                                    <span className="badge bg-info">
                                                        {item.holder.title}
                                                    </span>
                                                )}
                                            </td>
                                        ) : null}
                                        <td className="fw-bolder align-middle">
                                            {item.location?.title}
                                        </td>
                                        <td className="av-text-gray-500 align-middle">
                                            <StatusLabel
                                                isOnline={item.isOnline}
                                                provider={item.provider}
                                            />
                                            {item?.openLongTime ? (
                                                <BsTooltip text="Открыто надолго">
                                                    <div className="ms-1 badge badge-sm bg-warning rounded-pill d-inline">
                                                        <img
                                                            src="/media/icons/base/time-unlock.svg"
                                                            width={16}
                                                        />
                                                    </div>
                                                </BsTooltip>
                                            ) : null}
                                            {item?.emergencyUnblock ? (
                                                <BsTooltip text="Аварийная разблокировка">
                                                    <span className="ms-1 badge badge-sm bg-danger rounded-pill">
                                                        авария
                                                    </span>
                                                </BsTooltip>
                                            ) : null}
                                        </td>
                                        <td>
                                            {canEdit ? (
                                                <div className="d-flex justify-content-end flex-shrink-0">
                                                    <button
                                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                                                        onClick={(e) =>
                                                            openSourceLog(
                                                                e,
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        <KTSVG
                                                            path="/media/icons/duotune/coding/cod002.svg"
                                                            className="svg-icon-3"
                                                        />
                                                    </button>
                                                </div>
                                            ) : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className="card-body py-3 px-5">
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={entityList?.paginator?.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList?.result.length}
                        countTotal={entityList?.paginator?.totalCount}
                    />
                </div>
            </div>
        </>
    );
};

export default DevicesList;
