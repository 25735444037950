import React, { useState, useEffect, useRef } from 'react';
import { Http } from '../../http';
import { useHistory } from 'react-router';

const DeviceReplaceModal = (props) => {
    const { isOpen, closeHandle, accountId, device } = props;
    const inputElement = useRef(null);
    const [uid, setUid] = useState('');
    const [provider, setProvider] = useState('atlas');
    const history = useHistory();

    const close = () => {
        setUid('');
        setProvider('atlas');
        closeHandle();
    };

    useEffect(() => {
        if (inputElement.current) {
          inputElement.current.focus();
        }
    }, [isOpen]);
    useEffect(() => {
        setProvider(device.provider ?? 'atlas');
    }, [device]);

    const replaceDevice = async () => {
        const result = await Http.put(
            'api/devices/' + device.id + '/replace?uid=' + uid + '&provider=' + provider
        );
        if (result.result) {
            setUid('');
            history.push(`/account/${device.account.id}/devices`);
        } else {
            alert(result.error);
        }
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Замена устройства</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={close}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Тип устройства
                                </label>
                                <select id="name" className='form-control'
                                    value={provider}
                                    onChange={(e) => setProvider(e.target.value)}
                                >
                                    <option value={'atlas'}>Avanguard</option>
                                    <option value={'lite'}>Avanguard Lite</option>
                                </select>
                            </div>

                            <div className='mb-3'>
                                <label htmlFor="uid" className="form-label">
                                    UID устройства
                                </label>
                                <input
                                    type='text'
                                    id='uid'
                                    className='form-control'
                                    placeholder='Введите UID нового контроллера'
                                    value={uid}
                                    onChange={(e) =>
                                        setUid(e.target.value)
                                    }
                                    ref={inputElement}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={close}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={replaceDevice}
                            >
                                Заменить устройство
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeviceReplaceModal;
