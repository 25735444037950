import LinkWidget1 from '../../components/LinkWidget1';
import PermissionPage from '../../components/PermissionPage';
import ParkingAreasList from './ParkingAreasList';

const ParkingAreasPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['parking_area_list']}>
            <div className='row g-5 g-xl-8'>
                <div className='col-xl-4'>
                    <LinkWidget1
                        url='parkingTariffs'
                        className='card-xl-stretch mb-xl-8'
                        iconClass='fas fa-ruble-sign text-white fa-3x'
                        color='danger'
                        title='Тарифы'
                        description='Резиденты, посетители, машиноместа'
                    />
                </div>
                <div className='col-xl-4'>
                    <LinkWidget1
                        url='parkingServices'
                        className='card-xl-stretch mb-xl-8'
                        iconClass='fas fa-list text-white fa-3x'
                        color='success'
                        title='Услуги'
                        description='На текущий момент'
                    />
                </div>
                <div className='col-xl-4'>
                    <LinkWidget1
                        url='parkingReport'
                        className='card-xl-stretch mb-xl-8'
                        iconClass='fas fa-money-bill text-white fa-3x'
                        color='info'
                        title='Отчет по услугам'
                        description='История начислений'
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col'>
                    <h1 className='mb-5'>Парковка</h1>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <ParkingAreasList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default ParkingAreasPage;
