import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const parkingAreasApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getParkingAreas: builder.query({
            query: (accountId) => `parking/areas?accountId=${accountId}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "ParkingAreas",
                              id,
                          })),
                          { type: "ParkingAreas", id: "List" },
                      ]
                    : [{ type: "ParkingAreas", id: "List" }],
        }),
        getParkingAreaById: builder.query({
            query: (id) => `parking/areas/${id}`,
            providesTags: (result) => [
                { type: "ParkingArea", id: result?.result.id },
            ],
        }),
        createParkingArea: builder.mutation({
            query: (body) => ({
                url: "parking/areas",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "ParkingAreas", id: "List" }],
        }),
        updateParkingArea: builder.mutation({
            query: (body) => ({
                url: "parking/areas/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "ParkingAreas", id: "List" },
                { type: "ParkingArea", id: result?.result.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить данные парковки! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Парковка сохранена!",
                                description:
                                    "Данные парковки успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить данные парковки! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteParkingArea: builder.mutation({
            query: (id) => ({
                url: "parking/areas/" + id,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "ParkingAreas", id: "List" }],
        }),
        getParkingAreaDevices: builder.query({
            query: (areaId) => `parking/areas/${areaId}/devices`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "ParkingAreaDevices",
                              id,
                          })),
                          { type: "ParkingAreaDevices", id: "List" },
                      ]
                    : [{ type: "ParkingAreaDevices", id: "List" }],
        }),
        addParkingAreaDevice: builder.mutation({
            query: (data) => ({
                url:
                    "parking/areas/" +
                    data.areaId +
                    "/devices/" +
                    data.deviceId,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "ParkingAreaDevices", id: "List" }],
        }),
        deleteParkingAreaDevice: builder.mutation({
            query: (data) => ({
                url:
                    "parking/areas/" +
                    data.areaId +
                    "/devices/" +
                    data.deviceId,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "ParkingAreaDevices", id: "List" }],
        }),
    }),
});

export const {
    useGetParkingAreasQuery,
    useLazyGetParkingAreasQuery,
    useGetParkingAreaByIdQuery,
    useLazyGetParkingAreaByIdQuery,
    useCreateParkingAreaMutation,
    useUpdateParkingAreaMutation,
    useDeleteParkingAreaMutation,
    useGetParkingAreaDevicesQuery,
    useAddParkingAreaDeviceMutation,
    useDeleteParkingAreaDeviceMutation,
} = parkingAreasApiSlice;
