import { CRUDReducer } from './CRUDReducer';

export const baseReducer = new CRUDReducer('adminCards', 'cards');

export const { getListAsync, createAsync, updateAsync, deleteAsync } =
    baseReducer;

export const adminCardsSlice = baseReducer.reducerSlice;

export const { setPageSize, selectPage } = adminCardsSlice.actions;

export const selectList = (state) => state.adminCards.objectsArray;
export const selectIsLoading = (state) => state.adminCards.isLoading;
export const selectPaginator = (state) => state.adminCards.paginator;

export default adminCardsSlice.reducer;
