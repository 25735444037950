import { useRemoveSchemeMemberMutation } from "../../../../app/storeReducers/schemesApiSlice";

const GroupSchemeDeleteModal = (props) => {
    const { groupId, member, isOpen, closeHandle, okHandle } = props;

    const [deleteAsync] = useRemoveSchemeMemberMutation();

    const deleteScheme = async () => {
        await deleteAsync({
            employeeId: groupId,
            schemeId: member.id,
            isGroup: true,
        });

        okHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Удалить схему</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Вы действительно хотите удалить схему{" "}
                                <strong className="text-danger">
                                    {member.title}
                                </strong>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-danger"
                                onClick={deleteScheme}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default GroupSchemeDeleteModal;
