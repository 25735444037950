import PermissionPage from '../../components/PermissionPage';
import CamerasList from './CamerasList';

const CamerasPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['camera_list']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Камеры</h1>
                    <CamerasList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default CamerasPage;
