import { CRUDReducer } from './CRUDReducer';

const imageReducer = new CRUDReducer('accountParkingImages', 'parking/images');

export const { getListAsync, createAsync, updateAsync, deleteAsync } =
    imageReducer;

const accountParkingImagesSlice = imageReducer.reducerSlice;

export default accountParkingImagesSlice.reducer;
