import { useEffect, useState } from 'react';
import PermissionPage from '../../../../../components/PermissionPage';
import { Http } from '../../../../../http';
import PlaceEditPanel from './PlaceEditPanel';
import ServicesPanel from './services/ServicesPanel';
import VehicleControlPanel from './vehicle/VehicleControlPanel';

const PlaceCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const parkingId = props.match.params.parkingId;
    const placeId = props.match.params.placeId;

    const [vehicleId, setVehicleId] = useState(null);
    const [parkingName, setParkingName] = useState('');

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const getResult = await Http.get('api/parking/areas/' + parkingId);
            setParkingName(getResult.result.name);
        }

        loadResourcesAndDataAsync();
    }, [parkingId]);

    const vehicleAdded = (vehicle) => {
        setVehicleId(vehicle?.id);
    };

    return (
        <PermissionPage accountId={accountId} roles={['parking_place_list']}>
            <h1 className='mb-5'>Карточка парковочного места</h1>
            <div className='row'>
                <div className='col-md-6'>
                    <PlaceEditPanel
                        accountId={accountId}
                        parkingId={parkingId}
                        entityId={placeId}
                        placeLoaded={(plc) => setVehicleId(plc?.vehicle?.id)}
                    />
                </div>
                <div className='col-md-6'>
                    <ServicesPanel
                        accountId={accountId}
                        placeId={placeId}
                        consumerId={placeId}
                    />
                    <VehicleControlPanel
                        accountId={accountId}
                        vehicleId={vehicleId}
                        placeId={placeId}
                        addHandle={(vehicle) => vehicleAdded(vehicle)}
                    />
                </div>
            </div>
        </PermissionPage>
    );
};

export default PlaceCardPage;
