import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ToUtcString } from "../../helpers/DateHelpers";
import InputMask from "react-input-mask";
import {
    useCreateGuestMutation,
    useLazyGetGuestByIdQuery,
    useUpdateGuestMutation,
} from "../../app/storeReducers/guestsApiSlice";
import { addNotification } from "../../app/storeReducers/NotifySlice";
import { useGetAccountByIdQuery } from "../../app/storeReducers/acountsApiSlice";

const GuestCreateModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle, okHandle } = props;

    const dispatch = useDispatch();
    const [createAsync] = useCreateGuestMutation();
    const [updateAsync] = useUpdateGuestMutation();
    const { data: account } = useGetAccountByIdQuery(accountId);
    const [getGuestById] = useLazyGetGuestByIdQuery();

    const [guest, setGuest] = useState({});
    const [name, setName] = useState("");
    const [carNumber, setCarNumber] = useState("");
    const [carRegion, setCarRegion] = useState("");
    const [guestDateStart, setGuestDateStart] = useState(new Date());
    const [guestDateFinish, setGuestDateFinish] = useState(new Date());
    const [login, setLogin] = useState("");
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isNameError, setIsNameError] = useState(false);
    const [isCarNumberError, setIsCarNumberError] = useState(false);
    const [isCarNumberNotFullError, setIsCarNumberNotFullError] =
        useState(false);
    const [standartNumber, setStandartNumber] = useState(true);

    useEffect(() => {
        setName("");
        setGuest({});
        setCarNumber("");
        setCarRegion("");
        setGuestDateStart(new Date());
        setGuestDateFinish(new Date());
        setLogin("");
    }, [isOpen]);

    useEffect(() => {
        if (guestDateStart > guestDateFinish) {
            setGuestDateFinish(guestDateStart);
        }
    }, [guestDateStart]);
    useEffect(() => {
        if (guestDateFinish < guestDateStart) {
            setGuestDateStart(guestDateFinish);
        }
    }, [guestDateFinish]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsPhoneError(false);
            if (entityId !== 0 && entityId !== -1) {
                const getResult = await getGuestById(entityId).unwrap();
                setGuest(getResult.result);
                setName(getResult.result.name);
                setGuestDateStart(new Date(getResult.result.dateStart));
                setGuestDateFinish(new Date(getResult.result.dateFinish));
                setCarNumber(getResult.result.carNumber);
                setCarRegion(getResult.result.carRegion);
            } else {
                setName("");
                setLogin("");
                setCarNumber("");
                setCarRegion("");
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    const saveEntity = async () => {
        if (name.length <= 0) {
            setIsNameError(true);
            return;
        } else {
            setIsNameError(false);
        }
        if (account?.result?.guestCarNumberRequired && carNumber.length <= 0) {
            setIsCarNumberError(true);
            return;
        } else {
            setIsCarNumberError(false);
        }
        if (
            standartNumber &&
            carNumber?.replace("_", "").length > 0 &&
            (carNumber?.replace("_", "").length < 6 || carRegion.length === 0)
        ) {
            setIsCarNumberNotFullError(true);
            return;
        } else {
            setIsCarNumberNotFullError(false);
        }
        if (!account?.result?.allowAnonymousGuest && login.length < 10) {
            setIsPhoneError(true);
            return;
        } else {
            setIsPhoneError(false);
        }

        let entity = guest;
        guest.accountId = accountId;
        guest.name = name;
        guest.type = "full";
        guest.carNumber = carNumber;
        guest.carRegion = carRegion;
        guest.dateStart = new Date(
            guestDateStart.getFullYear(),
            guestDateStart.getMonth(),
            guestDateStart.getDate(),
            0,
            0,
            0
        );
        guest.dateFinish = new Date(
            guestDateFinish.getFullYear(),
            guestDateFinish.getMonth(),
            guestDateFinish.getDate(),
            23,
            59,
            59
        );
        guest.login = login?.replace(/\D/g, "");
        if (entityId === 0) {
            await createAsync(entity).unwrap();
        } else {
            await updateAsync(entity);
        }
        okHandle();
    };

    const changeDateFrom = (dt) => {
        setGuestDateStart(new Date(dt));
        if (account?.result?.oneDayTicket) {
            setGuestDateFinish(new Date(dt));
        }
    };

    console.log("account", account);

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить нового посетителя
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="name">Период посещения</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text">С</span>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={
                                            ToUtcString(guestDateStart).split(
                                                "T"
                                            )[0]
                                        }
                                        onChange={(e) =>
                                            changeDateFrom(e.target.value)
                                        }
                                    />
                                    <span className="input-group-text">ПО</span>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={
                                            ToUtcString(guestDateFinish).split(
                                                "T"
                                            )[0]
                                        }
                                        onChange={(e) =>
                                            setGuestDateFinish(
                                                new Date(e.target.value)
                                            )
                                        }
                                        disabled={account?.result?.oneDayTicket}
                                    />
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="name">
                                    ФИО <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {isNameError ? (
                                    <div className="form-text text-danger">
                                        Необходимо указать ФИО посетителя
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="login">
                                    Телефон{" "}
                                    {!account?.result?.allowAnonymousGuest ? (
                                        <span className="text-danger">*</span>
                                    ) : (
                                        <span> (необязательно)</span>
                                    )}
                                </label>
                                <InputMask
                                    mask="+7(999)999-99-99"
                                    className={
                                        "form-control" +
                                        (isPhoneError ? " bg-danger" : "")
                                    }
                                    id="login"
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                    placeholder="+7(___)___-__-__"
                                />
                                {isPhoneError ? (
                                    <div className="form-text text-danger">
                                        Необходимо указать номер телефона
                                        посетителя
                                    </div>
                                ) : null}
                            </div>
                            {!account?.result?.banGuestParking ? (
                                <div className="form-group">
                                    <label>
                                        Номер автомобиля{" "}
                                        {account?.result
                                            ?.guestCarNumberRequired ? (
                                            <span className="text-danger">
                                                *
                                            </span>
                                        ) : (
                                            <span> (необязательно)</span>
                                        )}
                                    </label>
                                    <div className="input-group">
                                        {standartNumber ? (
                                            <InputMask
                                                mask="a999aa"
                                                className={"form-control"}
                                                value={carNumber}
                                                onChange={(e) =>
                                                    setCarNumber(e.target.value)
                                                }
                                                placeholder="X999XX"
                                                formatChars={{
                                                    9: "[0-9]",
                                                    a: "[XxCcBmMkKHAaEeTYyoOPpсСмМтТвВаАрРоОуУкКеЕнНхХ]",
                                                }}
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="x000xx"
                                                value={carNumber}
                                                onChange={(e) =>
                                                    setCarNumber(e.target.value)
                                                }
                                            />
                                        )}
                                        <input
                                            type="number"
                                            className="form-control"
                                            placeholder="000"
                                            value={carRegion}
                                            onChange={(e) =>
                                                setCarRegion(e.target.value)
                                            }
                                        />
                                    </div>
                                    {isCarNumberError ? (
                                        <div className="form-text text-danger">
                                            Необходимо указать номер автомобиля
                                        </div>
                                    ) : null}
                                    {isCarNumberNotFullError ? (
                                        <div className="form-text text-danger">
                                            Необходимо указать полный номер
                                            автомобиля
                                        </div>
                                    ) : null}

                                    <div className="form-check form-check-inline form-group mt-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="standartNumber"
                                            checked={!standartNumber}
                                            onChange={(e) =>
                                                setStandartNumber(
                                                    !e.target.checked
                                                )
                                            }
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="standartNumber"
                                        >
                                            Использовать специальный номер
                                        </label>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default GuestCreateModal;
