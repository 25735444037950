import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
    selectPage,
    setPageSize,
    selectPaginator,
} from '../../app/storeReducers/AccountSubaccountsSlice';
import SubaccountsDeleteModal from './SubaccountsDeleteModal';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { useHistory } from 'react-router-dom';
import Paginator from '../../components/Paginator';
import SubaccountsCreateModal from './SubaccountsCreateModal';
import FindComponent from '../../components/FindComponent';
import SecutiryContext from '../../features/securityContext';

const SubaccountsList = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);
    const paginator = useSelector(selectPaginator);
    const pageSize = useSelector((state) => state.accountSubaccounts.pageSize);
    const [searchString, setSearchString] = useState('');

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(getListAsync({ accountId, searchString }));
        }

        loadResourcesAndDataAsync();
    }, [dispatch, accountId, searchString]);

    useEffect(() => {
        const actions = context?.permissions?.find((x) => x.id === parseInt(accountId))?.actions;
        setCanEdit(context.isRoot || actions?.includes('subaccount_edit'));
    }, [context, accountId]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedEntity, setDeletedEntity] = useState(0);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const selectDeleteEntity = (e, entityId) => {
        e.stopPropagation();
        setDeletedEntity(entityId);
        setDeleteModalIsOpen(true);
    };

    const changePage = async (pageIndex) => {
        dispatch(selectPage(pageIndex));
        dispatch(getListAsync({ accountId, searchString }));
    };

    const changePageSize = async (size) => {
        dispatch(setPageSize(size));
        dispatch(getListAsync({ accountId, searchString }));
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey) window.open(`/account/${entityId}/admin/settings`, '_blank');
        else history.push(`/account/${entityId}/admin/settings`);
    };

    const openSubaccount = (e, subaccountId) => {
        e.stopPropagation();
        history.push(`/account/${subaccountId}/dashboard`);
    };

    const getCarGusetStatus = (modeId) => {
        let status = <span className='badge badge-sm rounded-pill bg-light-secondary'>Выкл</span>;
        switch (modeId) {
            case 0:
                status = <span className='badge badge-sm rounded-pill bg-light-secondary'>Выкл</span>;
                break;
            case 1:
                status = <span className='badge badge-sm rounded-pill bg-light-success'>Вкл</span>;
                break;
            case 2:
                status = (
                    <span className='badge badge-sm rounded-pill bg-light-success'>
                        Вкл <i className='fas fa-plus' />
                    </span>
                );
                break;
            case 3:
                status = <span className='badge badge-sm rounded-pill bg-light-success'>Кнопка</span>;
                break;
            case 4:
                status = (
                    <span className='badge badge-sm rounded-pill bg-light-success'>
                        Кнопка <i className='fas fa-plus' />
                    </span>
                );
                break;

            default:
                break;
        }
        return status;
    };

    console.log('subaccounts', entityList);

    return (
        <>
            <SubaccountsCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <SubaccountsDeleteModal
                entity={deletedEntity}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='row'>
                <div className='col-md-4 mb-3'>
                    <button
                        className='btn av-btn-primary fw-boldest'
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className='fas fa-plus me-3' />
                        Новый субаккаунт
                    </button>
                </div>
                <div className='col-md-8 mb-3 d-flex justify-content-end'>
                    <FindComponent callback={(name) => setSearchString(name)} placeholder='Поиск по субаккаунту' />
                </div>
            </div>
            <div className='card border'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={[
                            'Название',
                            'Запрет парковки посетителей',
                            'Создание заявки на транспорт охраной',
                            'Разрешить посетителей без телефона',
                        ]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>Название</th>
                                    <th className='border-bottom' width='250'>
                                        Запрет парковки посетителей
                                    </th>
                                    <th className='border-bottom' width='250'>
                                        Создание заявки на транспорт охраной
                                    </th>
                                    <th className='border-bottom'>Квота</th>
                                    <th className='border-bottom'> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) => openEntityCard(item.id, e)}
                                        className='cursor-pointer'
                                    >
                                        <td>
                                            <button
                                                className='btn btn-link'
                                                onClick={(e) => openSubaccount(e, item.id)}
                                            >
                                                {item.name}
                                            </button>
                                        </td>
                                        <td className='text-center'>
                                            <input
                                                type='checkbox'
                                                className='form-check-input'
                                                checked={item.banGuestParking}
                                                readOnly
                                                disabled
                                            />
                                        </td>
                                        <td>{getCarGusetStatus(item.carGuestOrderMode)}</td>
                                        <td>{item.vehicleQuota}</td>
                                        <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                    onClick={(e) => selectDeleteEntity(e, item)}
                                                >
                                                    <i className='fas fa-trash-alt' />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className='card-body py-3 px-5'>
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList.length}
                        countTotal={paginator.totalCount}
                    />
                </div>
            </div>
        </>
    );
};

export default SubaccountsList;
