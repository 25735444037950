import { useContext, useEffect, useState } from 'react';
import { useAccount } from '../../../../components/useAccount';
import { Http } from '../../../../http';
import SecutiryContext from '../../../../features/securityContext';

const SchemeShareModal = (props) => {
    const { accountId, shares, schemeId, isOpen, okHandle, closeHandle } =
        props;

    const account = useAccount();
    const { context } = useContext(SecutiryContext);

    const [isLoading, setIsLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [accountsSelected, setAccountsSelected] = useState([]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setAccountsSelected([]);
            if (isLoading) {
                let url = `api/accounts/${accountId}/subaccounts?sort=name&pageSize=1000`;
                if (
                    account?.parent !== null &&
                    account?.isSeparateSubaccount &&
                    context.isRoot
                ) {
                    url = `api/accounts/${account?.parent?.id}/subaccounts?sort=name&pageSize=1000`;
                }
                console.log('urlAccounts', url);
                const requestAccounts = await Http.get(url);
                setAccounts(requestAccounts.result);
                setIsLoading(false);
            }
        }

        loadResourcesAndDataAsync();
    }, [schemeId]);

    const shareScheme = async () => {
        await Http.put(`api/schemes/${schemeId}/shares`, true, {
            list: accountsSelected,
        });

        okHandle();
    };

    const changeAccount = (e, actionId) => {
        if (e.target.checked) {
            setAccountsSelected([...accountsSelected, actionId]);
        } else {
            setAccountsSelected(accountsSelected.filter((x) => x !== actionId));
        }
    };

    const checkAll = () => {
        if (
            accounts.filter((x) => !shares.some((s) => s.id === x.id))
                .length === accountsSelected.length
        ) {
            setAccountsSelected([]);
        } else {
            setAccountsSelected(
                accounts
                    .filter((x) => !shares.some((s) => s.id === x.id))
                    .map((item) => item.id)
            );
        }
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Поделиться схемой с субаккаунтом
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-4'>
                                <div className='col'>
                                    <div className='form-group form-check mb-3'>
                                        {accounts.filter(
                                            (x) =>
                                                !shares.some(
                                                    (s) => s.id === x.id
                                                )
                                        ).length === accountsSelected.length ? (
                                            <a
                                                id='checkAll'
                                                className='btn btn-sm'
                                                onClick={checkAll}
                                            >
                                                <img
                                                    src='/media/icons/base/uncheck_all.png'
                                                    className='h-25px'
                                                    style={{ marginLeft: -40 }}
                                                />{' '}
                                                сбросить все
                                            </a>
                                        ) : (
                                            <a
                                                id='checkAll'
                                                className='btn btn-sm'
                                                onClick={checkAll}
                                            >
                                                <img
                                                    src='/media/icons/base/check_all.png'
                                                    className='h-25px'
                                                    style={{ marginLeft: -40 }}
                                                />{' '}
                                                выбрать все
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                {accounts
                                    .filter(
                                        (x) =>
                                            !shares.some((s) => s.id === x.id)
                                    )
                                    .map((item) => (
                                        <div className='col-md-6' key={item.id}>
                                            <div className='form-group form-check mb-3'>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id={'acc_' + item.id}
                                                    name='accounts'
                                                    onChange={(e) =>
                                                        changeAccount(
                                                            e,
                                                            item.id
                                                        )
                                                    }
                                                    checked={accountsSelected.includes(
                                                        item.id
                                                    )}
                                                />
                                                <label
                                                    className='form-check-label'
                                                    htmlFor={'acc_' + item.id}
                                                >
                                                    {item.name}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={shareScheme}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default SchemeShareModal;
