import { CRUDReducer } from "./CRUDReducer";

export const baseReducer = new CRUDReducer("accountGuests", "guests");

export const { getListAsync, createAsync, updateAsync, deleteAsync } =
    baseReducer;

export const accountGuestsSlice = baseReducer.reducerSlice;

export const { setPageSize, selectPage } = accountGuestsSlice.actions;

export const selectList = (state) => state.accountGuests.objectsArray;
export const selectIsLoading = (state) => state.accountGuests.isLoading;
export const selectPaginator = (state) => state.accountGuests.paginator;

export default accountGuestsSlice.reducer;
