import PermissionPage from '../../components/PermissionPage';
import AccesslogList from './AccesslogList';

const AccesslogPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['access_log_view']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Журнал доступа</h1>
                    <AccesslogList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default AccesslogPage;
