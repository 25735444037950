import { useEffect, useState } from 'react';
import { DateToString, UtcToLocal } from '../../helpers/DateHelpers';
import DeviceOpenLongTimeModal from './DeviceOpenLongTimeModal';
import { BsTooltip } from '../../components/BsTooltip';
import { useSelector } from 'react-redux';
import { selectContext } from '../../app/storeReducers/AuthSlice';
import { useLazyGetDeviceByIdQuery } from '../../app/storeReducers/devicesApiSlice';

const DeviceStatusPanel = (props) => {
    const context = useSelector(selectContext);

    const { deviceId } = props;
    const [getDeviceById] = useLazyGetDeviceByIdQuery();

    const [isLoading, setIsLoading] = useState(true);
    const [firmware, setFirmware] = useState('---');
    const [isOnline, setIsOnline] = useState(false);
    const [lastUpdate, setLastUpdate] = useState('');
    const [ipAddress, setIpAddress] = useState('---');
    const [peerIpAddress, setPeerIpAddress] = useState('---');
    const [peerPortAddress, setPeerPortAddress] = useState('---');
    const [peerPortP, setPeerPortP] = useState('---');
    const [openLongTime, setOpenLongTime] = useState(false);
    const [openLongTimeBySchedule, setOpenLongTimeBySchedule] = useState(false);
    const [provider, setProvider] = useState('');

    const [openLongTimeModal, setOpenLongTimeModal] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [deviceId]);

    const loadData = async () => {
        setIsLoading(true);
        const response = await getDeviceById(deviceId).unwrap();
        if (response.result !== null) {
            setFirmware(
                response.result.firmware !== null
                    ? response.result.firmware
                    : '---'
            );
            setIsOnline(
                response.result.isOnline !== null
                    ? response.result.isOnline
                    : '---'
            );
            setLastUpdate(
                response.result.last_link_datetime !== '0001-01-01T00:00:00'
                    ? DateToString(
                          UtcToLocal(response.result.last_link_datetime),
                          true
                      )
                    : '---'
            );
            setIpAddress(
                response.result.ip !== null ? response.result.ip : '---'
            );
            setPeerIpAddress(
                response.result.peer_ip !== null
                    ? response.result.peer_ip
                    : '---'
            );
            setPeerPortAddress(
                response.result.peer_port !== null
                    ? response.result.peer_port
                    : '---'
            );
            setPeerPortP(
                response.result.peer_portP !== null
                    ? response.result.peer_portP
                    : '---'
            );
            setOpenLongTime(response.result.openLongTime);
            setOpenLongTimeBySchedule(response.result.openLongTimeBySchedule);
            setProvider(response.result.provider);
        }
        setIsLoading(false);
    };

    const changeLongTime = () => {
        setOpenLongTimeModal(true);
    };

    const saveLongTime = () => {
        setOpenLongTime(!openLongTime);
        setOpenLongTimeModal(false);
    };

    const getBlockText = () => {
        let txt = 'Редактирование заблокированно';
        if (
            context.permissions
                .find((x) => x.id == props.accountId)
                .actions.indexOf('device_block') === -1 &&
            !context.isRoot
        ) {
            txt = 'У вас нет прав для редактирования этого параметра';
        }
        if (!isOnline) {
            txt =
                'Устройство Offline. Редактирование данного параметра недоступно';
        }
        return txt;
    };

    return (
        <>
            <DeviceOpenLongTimeModal
                isOpen={openLongTimeModal}
                closeHandle={() => setOpenLongTimeModal(false)}
                okHandle={saveLongTime}
                deviceId={deviceId}
                direction={openLongTime ? 'off' : 'on'}
            />
            {!isLoading ? (
                <div className='card border'>
                    <div className='card-body'>
                        <div className='row mb-3'>
                            <div className='col-md-6'>
                                <h3>Состояние</h3>
                            </div>
                        </div>
                        <div>
                            Статус:{' '}
                            {isOnline ? (
                                <span className='badge badge-sm bg-light-success rounded-pill'>
                                    online
                                </span>
                            ) : (
                                <span className='badge badge-sm bg-light-secondary rounded-pill'>
                                    offline
                                </span>
                            )}
                        </div>
                        {provider !== 'mqtt' ? (
                            <>
                                <div>Прошивка: {firmware}</div>
                                <div>Последнее обновление: {lastUpdate}</div>
                                <div>IP-адрес: {ipAddress}</div>
                                {context.isRoot ? (
                                    <>
                                        <div>
                                            Внешний IP-адрес: {peerIpAddress}
                                        </div>
                                        <div>
                                            Внешний порт: {peerPortAddress}
                                        </div>
                                        <div>Порт обновления: {peerPortP}</div>
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : null}
                        <div>
                            <div className='d-inline-block me-1'>
                                Открыть надолго
                            </div>
                            <div className='form-check form-switch form-check-inline form-check-reverse'>
                                {(context.permissions
                                    .find((x) => x.id == props.accountId)
                                    ?.actions?.indexOf('device_block') === -1 &&
                                    !context.isRoot) ||
                                !isOnline ? (
                                    <BsTooltip text={getBlockText}>
                                        <input
                                            className='form-check-input cursor-pointer disabled'
                                            type='checkbox'
                                            role='switch'
                                            id='flexSwitchCheckDefault'
                                            checked={openLongTime}
                                            readOnly
                                        />
                                    </BsTooltip>
                                ) : (
                                    <input
                                        className='form-check-input cursor-pointer'
                                        type='checkbox'
                                        role='switch'
                                        id='flexSwitchCheckDefault'
                                        checked={openLongTime}
                                        onChange={changeLongTime}
                                    />
                                )}
                                <label
                                    htmlFor='flexSwitchCheckDefault'
                                    className='form-check-label'
                                ></label>
                            </div>
                            {openLongTime && openLongTimeBySchedule ? (
                                <div className='d-inline-block'>
                                    по расписанию
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default DeviceStatusPanel;
