import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';
import { addNotification } from './NotifySlice';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    employees: [],
    pageSize: 50,
    pageSelected: 1,
    paginator: [],
    selectedEmployee: {},
    lastInsertId: undefined,
};

export const getEmployeeAsync = createAsyncThunk(
    'accountEmployees/getEmployee',
    async (data) => {
        const employeeResult = await Http.get(
            'api/employees/' + data.employeeId + '?thumbnail=true'
        );

        return employeeResult;
    }
);

export const getEmployeesAsync = createAsyncThunk(
    'accountEmployees/getEmployees',
    async (specs) => {
        const employeesResult = await Http.get(
            `api/employees?thumbnail=true&accountId=${
                specs.accountId ? specs.accountId : ''
            }&pageIndex=${specs.pageIndex}&pageSize=${specs.pageSize}&search=${
                specs.searchString
            }&sort=${specs.sort}`
        );

        return employeesResult;
    }
);

export const createEmployeeAsync = createAsyncThunk(
    'accountEmployees/createEmployee',
    async (data, thunkAPI) => {
        const response = await Http.post('api/employees', true, data.employee);
        if (data.photo?.image) {
            const result = await Http.post(
                `api/employees/${response.result.id}/photo`,
                true,
                data.photo
            );
        }
        if (response !== undefined && response != null) {
            if (
                response.error !== undefined &&
                response.error !== null &&
                response.error !== ''
            ) {
                thunkAPI.dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка сохранения!',
                        description: response.errorDescription
                            ? response.errorDescription
                            : 'Не удалось сохранить изменения! Перезагрузите страницу и попробуйте снова!',
                        type: 'danger',
                    })
                );
            } else {
                thunkAPI.dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Сотрудник добавлен!',
                        description: 'Данные сотрудника успешно сохранены!',
                        type: 'success',
                    })
                );
            }
        } else {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description:
                        'Не удалось добавить сотрудника! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }

        return response;
    }
);

export const updateEmployeeAsync = createAsyncThunk(
    'accountEmployees/updateEmployee',
    async (data, thunkAPI) => {
        const updateeResult = await Http.put(
            'api/employees/' + data.employee.id,
            true,
            data.employee
        );
        if (updateeResult !== undefined && updateeResult != null) {
            if (data.photo?.image) {
                console.log(data);
                const result = await Http.post(
                    `api/employees/${updateeResult.result.id}/photo`,
                    true,
                    data.photo
                );
                updateeResult.result.thumbnail = result.result.thumbnail;
            }
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Сотрудник сохранён!',
                    description: 'Данные сотрудника успешно сохранены!',
                    type: 'success',
                })
            );
        } else {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description:
                        'Не удалось сохранить данные сотрудника! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }

        return updateeResult.result;
    }
);

export const deleteEmployeeAsync = createAsyncThunk(
    'accountEmployees/deleteEmployee',
    async (id) => {
        await Http.delete('api/employees/' + id, true);

        return id;
    }
);

export const accountEmployeesSlice = createSlice({
    name: 'accountEmployees',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        selectPage: (state, action) => {
            state.pageSelected = action.payload;
        },
        clearLastInsertId: (state, action) => {
            state.lastInsertId = undefined;
            console.log('Clear last insert ID');
        },
    },
    extraReducers: {
        [getEmployeeAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getEmployeeAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.selectedEmployee = action.payload.result;
            }
            state.isLoading = false;
        },
        [getEmployeeAsync.rejected]: (state, action) => {
            console.error('getEmployeeError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [getEmployeesAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getEmployeesAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.employees = action.payload.result;
                state.paginator = action.payload.paginator;
            }
            state.isLoading = false;
        },
        [getEmployeesAsync.rejected]: (state, action) => {
            console.error('getEmployeesError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [createEmployeeAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.employees.push(action.payload.result);
                state.lastInsertId = action.payload.result.id;
            }
        },
        [createEmployeeAsync.rejected]: (state, action) => {
            console.error('createEmployeesError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [updateEmployeeAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.selectedEmployee = action.payload;
                const updateEmps = state.employees.map((item) => {
                    if (item.id === action.payload.id) {
                        item = action.payload;
                    }
                    return item;
                });
                state.employees = updateEmps;
            }
        },
        [updateEmployeeAsync.rejected]: (state, action) => {
            console.error('updateEmployeesError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [deleteEmployeeAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                console.log('deleteEmp', action.payload);
                const updateEmps = state.employees.filter(
                    (x) => x.id !== action.payload
                );
                state.employees = updateEmps;
            }
        },
        [deleteEmployeeAsync.rejected]: (state, action) => {
            console.error('deleteEmployeesError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const { setPageSize, selectPage, clearLastInsertId } =
    accountEmployeesSlice.actions;

export const selectEmployee = (state) =>
    state.accountEmployees.selectedEmployee;
export const selectEmployees = (state) => state.accountEmployees.employees;
export const selectLastInsertId = (state) =>
    state.accountEmployees.lastInsertId;
export const selectIsLoading = (state) => state.accountEmployees.isLoading;
export const selectPaginator = (state) => state.accountEmployees.paginator;

export default accountEmployeesSlice.reducer;
