import { useContext, useEffect, useState } from 'react';
import { Http } from '../../http';
import DeviceDeletePhoneModal from './DeviceDeletePhoneModal';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDeviceAsync,
    selectDevice,
} from '../../app/storeReducers/AccountDevicesSlice';
import SecutiryContext from '../../features/securityContext';

const DevicePhonePanel = (props) => {
    const { accountId, deviceId } = props;
    const dispatch = useDispatch();

    const { context } = useContext(SecutiryContext);
    const device = useSelector(selectDevice);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot);
        setCanList(context.isRoot || actions?.includes('devices_list'));
    }, [context, accountId]);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);
    const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);

    const setPhone = async () => {
        const request = await Http.put('api/phones/tie?deviceId=' + deviceId);
        dispatch(getDeviceAsync(deviceId));
    };

    const deletePhone = async () => {
        const request = await Http.put('api/phones/untie?deviceId=' + deviceId);
        dispatch(getDeviceAsync(deviceId));
        setIsDeleteOpenModal(false);
    };

    let phoneBlock = (
        <i>
            К устройству не подключен телефон. Для подключения обратитесь в
            техподдержку.
        </i>
    );
    if (device.phone !== null) {
        phoneBlock = <h4>{device.phone}</h4>;
    }

    console.log('device', device);

    return (
        <>
            <DeviceDeletePhoneModal
                phone={device?.phone}
                isOpen={isDeleteOpenModal}
                closeHandle={() => setIsDeleteOpenModal(false)}
                okHandle={deletePhone}
            />
            <div className='card border'>
                <div className='card-body'>
                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <h3>Телефон</h3>
                        </div>
                    </div>
                    {canList ? (
                        phoneBlock
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                    {canEdit ? (
                        <div className='row mt-3'>
                            <div className='col'>
                                {device.phone !== null ? (
                                    <button
                                        className='btn av-btn-danger btn-sm'
                                        onClick={() =>
                                            setIsDeleteOpenModal(true)
                                        }
                                    >
                                        Удалить
                                    </button>
                                ) : (
                                    <div className='d-grid gap-2'>
                                        <button
                                            className='btn av-btn-simple btn-sm me-2'
                                            onClick={setPhone}
                                        >
                                            Добавить
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default DevicePhonePanel;
