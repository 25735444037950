import clsx from 'clsx';
import { FC, useState } from 'react';
import HeaderUserMenu from '../../../components/HeaderUserMenu';
import { toAbsoluteUrl } from '../../../helpers';
import HeaderNotificationsMenu from '../../../pages/notifications/HeaderNotificationsMenu';

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-32px';

const Topbar: FC = () => {
    const [checkNewHandle, setCheckNewHandle] = useState(false);

    return (
        <div className='d-flex align-items-stretch flex-shrink-0'>
            {/* NOTIFICATIONS */}
            <div
                className={clsx(
                    'd-flex align-items-center',
                    toolbarButtonMarginClass
                )}
            >
                {/* begin::Menu- wrapper */}
                <div
                    className='position-relative d-flex justify-content-center'
                    data-kt-menu-trigger='hover'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                    id='toolbarBellNotify'
                >
                    <img
                        src='/media/icons/avanguard/bellNotify.png'
                        height='16px'
                        className='align-self-center text-center'
                    />
                    {checkNewHandle ? (
                        <div className='point-new-message position-absolute' />
                    ) : null}
                </div>
                <HeaderNotificationsMenu checkHandle={setCheckNewHandle} />
                {/* end::Menu wrapper */}
            </div>

            {/* begin::User */}
            <div
                className={clsx(
                    'd-flex align-items-center',
                    toolbarButtonMarginClass
                )}
                id='kt_header_user_menu_toggle'
            >
                {/* begin::Toggle */}
                <div
                    className={clsx(
                        'cursor-pointer symbol',
                        toolbarUserAvatarHeightClass
                    )}
                    data-kt-menu-trigger='hover'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='bottom'
                >
                    <img
                        src={toAbsoluteUrl('/images/no-user.jpg')}
                        alt='metronic'
                    />
                </div>
                <HeaderUserMenu />
                {/* end::Toggle */}
            </div>
            {/* end::User */}
        </div>
    );
};

export { Topbar };
