import { useEffect, useState } from 'react';
import SkeletonSelect from '../../../../components/skeleton/SkeletonSelect';
import { useGetRolesQuery } from '../../../../app/storeReducers/rolesApiSlice';
import { useAddEmployeeRoleMutation } from '../../../../app/storeReducers/employeesApiSlice';

const EmployeeAddRoleModal = (props) => {
    const { accountId, employeeId, isOpen, okHandle, closeHandle } = props;

    const [roleSelected, setRoleSelected] = useState(0);

    const { data: roles, isLoading } = useGetRolesQuery({ accountId, sort: 'name' }, { skip: !accountId });
    const [addRole] = useAddEmployeeRoleMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setRoleSelected(0);
            if (roles?.result?.length > 0) {
                setRoleSelected(roles?.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [roles]);

    const saveMemberRole = async () => {
        await addRole({ employeeId, roleId: roleSelected });
        okHandle();
    };

    return (
        <>
            <div className={'modal fade' + (isOpen ? ' show' : '')} tabIndex='-1'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Добавить роль сотруднику</h5>
                            <button type='button' className='btn-close' onClick={closeHandle}></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-4'>
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label='Роль'
                                    value={roleSelected}
                                    onChange={(e) => setRoleSelected(e.target.value)}
                                    itemsArray={
                                        roles?.result.map((item) => ({
                                            id: item.id,
                                            name: item.id,
                                            description: item.name,
                                        })) || []
                                    }
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn av-btn-simple' onClick={closeHandle}>
                                Закрыть
                            </button>
                            <button type='button' className='btn av-btn-primary' onClick={saveMemberRole}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default EmployeeAddRoleModal;
