import { useEffect, useState } from 'react';
import { Http } from '../../http';
import InputMask from 'react-input-mask';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import { ACTIONS, STATUS } from 'react-joyride';
import Onboarding from '../onboarding/Onboarding';

const CardCreateModal = (props) => {
    const { entityId, ownerId, ownerType, isOpen, closeHandle, okHandle } =
        props;

    const [name, setName] = useState('Новая карта');
    const [type, setType] = useState('proximity');
    const [number, setNumber] = useState('');

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        );
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    });

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== 0 && entityId !== -1) {
                const getResult = await Http.get('api/cards/' + entityId, true);
                setName(getResult.result.name);
                setType(getResult.result.type);
                setNumber(getResult.result.number);
            } else {
                setName('Новая карта');
                setType('proximity');
                setNumber('');
            }
            //init tooltip
            Array.from(
                document.querySelectorAll('[data-bs-toggle="tooltip"]')
            ).forEach((tooltipNode) => new Tooltip(tooltipNode));
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    const saveEntity = async () => {
        const entity = {
            id: entityId,
            name,
            type,
            number,
            owner: {
                id: ownerId,
                type: ownerType.name,
            },
        };
        okHandle(entity);
        setName('Новая карта');
        setType('proximity');
        setNumber('');
    };

    const generateNumber = () => {
        const nmbr = Math.floor(Math.random() * (999999 - 100000)) + 100000;
        setNumber(nmbr.toString());
    };

    const [steps, setSteps] = useState([
        {
            target: '#cardCreateModal',
            disableBeacon: true,
            placement: 'top-end',
			isFixed: true,
			offset: -100,
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>
                        Шаг 8 из 13
                    </div>
                    <div className='mb-1'>
                        <b>Добавление карты доступа</b>
                    </div>
                    <div className='mb-3'>
                        Чтобы добавить сотруднику карту доступа, просто выберите
                        тип карты и укажите ее номер.
                    </div>
                </div>
            ),
        },
    ]);

    const onboardingCallback = (action, status, index) => {
        if (
            action === ACTIONS.CLOSE &&
            status === STATUS.FINISHED &&
            index === 0
        ) {
            closeHandle(1);
        }
    };

    return (
        <>
            <Onboarding
                steps={steps}
                run={isOpen}
                callback={onboardingCallback}
            />
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content' id='cardCreateModal'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить новую карту доступа
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='form-group'>
                                        <label>Тип карты</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-md-3 mb-3'>
                                    <div
                                        className={
                                            'rounded p-4 card-type d-flex position-relative h-100 cursor-pointer' +
                                            (type === 'proximity'
                                                ? ' active'
                                                : '')
                                        }
                                        onClick={() =>
                                            entityId > 0
                                                ? null
                                                : setType('proximity')
                                        }
                                    >
                                        <div>
                                            <div className='card-icon-block me-3 rounded-circle'>
                                                <img
                                                    src='/media/icons/avanguard/CardsProximity.svg'
                                                    className='add-profile-img'
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>Proximity</h5>
                                            Стандартная карта, которую
                                            прикладывают к считывателю
                                        </div>
                                        <div className='position-absolute check-icon'>
                                            {type === 'proximity' ? (
                                                <i className='fas fa-check-circle av-text-primary'></i>
                                            ) : (
                                                <i className='far fa-circle text-muted'></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-3'>
                                    <div
                                        className={
                                            'rounded p-4 card-type d-flex position-relative h-100 cursor-pointer' +
                                            (type === 'uhf' ? ' active' : '')
                                        }
                                        onClick={() =>
                                            entityId > 0 ? null : setType('uhf')
                                        }
                                    >
                                        <div>
                                            <div className='card-icon-block me-3 rounded-circle'>
                                                <img
                                                    src='/media/icons/avanguard/Cards1.svg'
                                                    className='add-profile-img'
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>UHF/BLE</h5>
                                            Карта дальнего действия,
                                            используется в автомобилях
                                        </div>
                                        <div className='position-absolute check-icon'>
                                            {type === 'uhf' ? (
                                                <i className='fas fa-check-circle av-text-primary'></i>
                                            ) : (
                                                <i className='far fa-circle text-muted'></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-3'>
                                    <div
                                        className={
                                            'rounded p-4 card-type d-flex position-relative h-100 cursor-pointer' +
                                            (type === 'qr' ? ' active' : '')
                                        }
                                        onClick={() =>
                                            entityId > 0 ? null : setType('qr')
                                        }
                                    >
                                        <div>
                                            <div className='card-icon-block me-3 rounded-circle'>
                                                <img
                                                    src='/media/icons/avanguard/QR.svg'
                                                    className='add-profile-img'
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>QR-код</h5>
                                            Генерирует код, который можно
                                            отправить
                                        </div>
                                        <div className='position-absolute check-icon'>
                                            {type === 'qr' ? (
                                                <i className='fas fa-check-circle av-text-primary'></i>
                                            ) : (
                                                <i className='far fa-circle text-muted'></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-3'>
                                    <div
                                        className={
                                            'rounded p-4 card-type d-flex position-relative h-100 cursor-pointer' +
                                            (type === 'pin' ? ' active' : '')
                                        }
                                        onClick={() =>
                                            entityId > 0 ? null : setType('pin')
                                        }
                                    >
                                        <div>
                                            <div className='card-icon-block me-3 rounded-circle'>
                                                <img
                                                    src='/media/icons/avanguard/PIN.svg'
                                                    className='add-profile-img'
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>PIN-код</h5>
                                            Номер для набора
                                        </div>
                                        <div className='position-absolute check-icon'>
                                            {type === 'pin' ? (
                                                <i className='fas fa-check-circle av-text-primary'></i>
                                            ) : (
                                                <i className='far fa-circle text-muted'></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>
                                            Название карты
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='name'
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='form-group position-relative'>
                                        <label htmlFor='nmbr'>Номер</label>
                                        {type === 'pin' ? (
                                            <>
                                                <InputMask
                                                    mask='999999'
                                                    className='form-control'
                                                    id='nmbr'
                                                    value={number}
                                                    onChange={(e) =>
                                                        setNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={entityId > 0}
                                                />
                                                {entityId > 0 ? null : (
                                                    <span
                                                        className='fas fa-dice password-eye cursor-pointer'
                                                        onClick={generateNumber}
                                                        data-bs-toggle='tooltip'
                                                        data-bs-html='true'
                                                        data-bs-custom-class='av-tooltip'
                                                        data-bs-placement='top'
                                                        title='Нажмите для генерации номера карты'
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <InputMask
                                                    mask='9999999999'
                                                    className='form-control'
                                                    id='number'
                                                    placeholder='0000000000'
                                                    value={number}
                                                    onChange={(e) =>
                                                        setNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={
                                                        entityId > 0 ||
                                                        type === 'qr'
                                                    }
                                                />
                                                {type !== 'qr' ? (
                                                    <span
                                                        className='far fa-question-circle password-eye cursor-pointer text-muted'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-html='true'
                                                        data-bs-custom-class='av-tooltip'
                                                        data-bs-placement='top'
                                                        title='<b>Укажите номер карты</b><br />Номер карты указан на обратной стороне. Он состоит из набора цифр. В поле укажите только первые 10 цифр<br /><img src="/images/key-scheme.png" class="mt-2 img-fluid" />'
                                                    />
                                                ) : (
                                                    <span
                                                        className='far fa-question-circle password-eye cursor-pointer text-muted'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-html='true'
                                                        data-bs-custom-class='av-tooltip'
                                                        data-bs-placement='top'
                                                        title='QR-код не требует указания номера карты'
                                                    />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Отменить
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                Создать карту
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default CardCreateModal;
