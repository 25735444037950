import { baseApi } from '../baseApi';

export const AccountAccessLogAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAccessLog: builder.query({
            query: ({
                accountId = 0,
                pageIndex = 1,
                pageSize = 100,
                subjectAccountId = 0,
                dateStart = '',
                dateFinish = '',
                card = '',
                entityType = '',
                separateSubaccount = false,
                otherFilters = '',
            }) =>
                `log/access?accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}&subjectAccountId=${subjectAccountId}&start=${dateStart}&finish=${dateFinish}` +
                `&card=${card}&entityType=${entityType}&separateSubaccount=${separateSubaccount}` +
                otherFilters,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'AccessLogs',
                              id,
                          })),
                          { type: 'AccessLogs', id: 'List' },
                      ]
                    : [{ type: 'AccessLogs', id: 'List' }],
        }),
    }),
});

export const { useGetAccessLogQuery, useLazyGetAccessLogQuery } = AccountAccessLogAPI;
