import PermissionPage from '../../components/PermissionPage';
import SchemesList from './SchemesList';

const SchemesPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['schemes_list']}>
            <div className='row'>
                <div className='col-md-12'>
                <h1 className='mb-5'>Схемы доступа</h1>
                    <SchemesList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default SchemesPage;
