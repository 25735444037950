import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectIsAuthorize,
    saveUserInfo,
} from '../../../app/storeReducers/CurrentUserSlice';
import BtnStatused from '../../../components/BtnStatused';

const UserInfo = () => {
    const dispatch = useDispatch();

    const isAuthorize = useSelector(selectIsAuthorize);
    const user = useSelector((state) => state.currentUser.user);
    const isSavingError = useSelector(
        (state) => state.currentUser.isSavingError
    );
    const isSavingUser = useSelector((state) => state.currentUser.isSavingUser);

    const [editedEmail, setEditedEmail] = useState('');
    const [editedFirstName, setEditedFirstName] = useState('');
    const [editedLastName, setEditedLastName] = useState('');
    const [editedSecondName, setEditedSecondName] = useState('');

    useEffect(() => {
        if (isAuthorize) {
            setEditedEmail(user.email !== null ? user.email : '');
            setEditedFirstName(user.firstName !== null ? user.firstName : '');
            setEditedLastName(user.lastName !== null ? user.lastName : '');
            setEditedSecondName(
                user.secondName !== null ? user.secondName : ''
            );
        }
    }, [isAuthorize, user]);

    const saveUser = () => {
        const userInfo = {
            id: user.id,
            login: user.login,
            email: editedEmail,
            firstName: editedFirstName,
            secondName: editedSecondName,
            lastName: editedLastName,
        };
        dispatch(saveUserInfo(userInfo));
    };

    return (
        <form>
            <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                    Телефон
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="phone"
                    disabled={true}
                    defaultValue={user.login}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">
                    Email
                </label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={editedEmail}
                    onChange={(e) => setEditedEmail(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="secondName" className="form-label">
                    Фамилия
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="secondName"
                    value={editedLastName}
                    onChange={(e) => setEditedLastName(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                    Имя
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={editedFirstName}
                    onChange={(e) => setEditedFirstName(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                    Отчество
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={editedSecondName}
                    onChange={(e) => setEditedSecondName(e.target.value)}
                />
            </div>
            <div className="d-grid">
                <BtnStatused
                    label={'Сохранить'}
                    handleClick={saveUser}
                    btnColor="primary"
                    isLoading={isSavingUser}
                    isFailed={isSavingError}
                    loadingLabel="Сохранение..."
                />
            </div>
        </form>
    );
};

export default UserInfo;
