import { useEffect, useState } from "react";
import DeviceDeletePhoneModal from "./DeviceDeletePhoneModal";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetDeviceByIdQuery } from "../../app/storeReducers/devicesApiSlice";
import {
    useSetPhoneTieMutation,
    useSetPhoneUntieMutation,
} from "../../app/storeReducers/phoneApiSlice";
import { useSelector } from "react-redux";

const DevicePhonePanel = (props) => {
    const { accountId, deviceId } = props;

    const context = useSelector(selectContext);

    const { data: device, refetch } = useGetDeviceByIdQuery(deviceId, {
        skip: !deviceId,
    });
    const [tiePhone] = useSetPhoneTieMutation();
    const [untiePhone] = useSetPhoneUntieMutation();

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot);
        setCanList(context.isRoot || actions?.includes("devices_list"));
    }, [context, accountId]);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);
    const [isDeleteOpenModal, setIsDeleteOpenModal] = useState(false);

    const setPhone = async () => {
        await tiePhone(deviceId);
        refetch();
    };

    const deletePhone = async () => {
        await untiePhone(deviceId);
        refetch();
        setIsDeleteOpenModal(false);
    };

    let phoneBlock = (
        <i>
            К устройству не подключен телефон. Для подключения обратитесь в
            техподдержку.
        </i>
    );
    if (device?.result?.phone !== null) {
        phoneBlock = <h4>{device.result.phone}</h4>;
    }

    return (
        <>
            <DeviceDeletePhoneModal
                phone={device?.result?.phone}
                isOpen={isDeleteOpenModal}
                closeHandle={() => setIsDeleteOpenModal(false)}
                okHandle={deletePhone}
            />
            <div className="card border">
                <div className="card-body">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <h3>Телефон</h3>
                        </div>
                    </div>
                    {canList ? (
                        phoneBlock
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                    {canEdit ? (
                        <div className="row mt-3">
                            <div className="col">
                                {device?.result?.phone !== null ? (
                                    <button
                                        className="btn av-btn-danger btn-sm"
                                        onClick={() =>
                                            setIsDeleteOpenModal(true)
                                        }
                                    >
                                        Удалить
                                    </button>
                                ) : (
                                    <div className="d-grid gap-2">
                                        <button
                                            className="btn av-btn-simple btn-sm me-2"
                                            onClick={setPhone}
                                        >
                                            Добавить
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default DevicePhonePanel;
