import { useState } from "react";
import SkeletonTable from "../../../components/skeleton/SkeletonTable";
import FindComponent from "../../../components/FindComponent";
import { Link } from "react-router-dom";
import { DateToString, UtcToLocal } from "../../../helpers/DateHelpers";
import Paginator from "../../../components/Paginator";
import { useGetCardsQuery } from "../../../app/storeReducers/cardsApiSlice";

const AdminCardsList = (props) => {
    const { accountId } = props;

    const [searchString, setSearchString] = useState("");
    const [pageSize, setPageSize] = useState(50);
    const [forcePage, setForcePage] = useState(0);

    const { data: entityList, isLoading } = useGetCardsQuery(
        {
            accountId,
            number: searchString,
            pageIndex: forcePage + 1,
            pageSize,
        },
        { skip: searchString === "" }
    );

    function GetOwnerType(props) {
        var value = props.value;
        if (value === "user") return "Пользователь";
        else if (value === "employee") return "Сотрудник";
        else if (value === "vehicle") return "Автомобиль";
        else return "---";
    }
    function GetOwnerLink(props) {
        var value = props.value;
        console.log(value);
        if (value !== null && value !== undefined) {
            if (value.type === "user") return value.name;
            else if (value.type === "employee")
                return (
                    <Link
                        to={`/account/${value.accountId}/employees/${value.id}`}
                        className="text-link"
                    >
                        {value.name}
                    </Link>
                );
            else if (value.type === "vehicle")
                return (
                    <Link
                        to={`/account/${value.accountId}/vehicles/${value.id}`}
                        className="text-link"
                    >
                        {value.name}
                    </Link>
                );
            else return "---";
        } else return "---";
    }

    const changePage = async (pageIndex) => {
        setForcePage(pageIndex - 1);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
        setForcePage(0);
    };
    return (
        <>
            <div className="row">
                <div className="col-md-4 mb-3"></div>
                <div className="col-md-8 mb-3 d-flex justify-content-end">
                    <FindComponent
                        callback={(search) => setSearchString(search)}
                        placeholder="Поиск по картам"
                    />
                </div>
            </div>
            <div className="card">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={[
                            "ID",
                            "Дата",
                            "Аккаунт",
                            "Тип",
                            "Владелец",
                            "Номер",
                        ]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <th className="border-bottom ps-6">ID</th>
                                    <th className="border-bottom">Дата</th>
                                    <th className="border-bottom">Аккаунт</th>
                                    <th className="border-bottom">Тип</th>
                                    <th className="border-bottom">Владелец</th>
                                    <th className="border-bottom">Номер</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result?.map((item) => (
                                    <tr key={item.id}>
                                        <td className="ps-6">{item.id}</td>
                                        <td>
                                            {item.createdAt
                                                ? DateToString(
                                                      UtcToLocal(
                                                          item.createdAt
                                                      ),
                                                      true
                                                  )
                                                : ""}
                                        </td>
                                        <td>
                                            {item.account !== null ? (
                                                <Link
                                                    to={`/account/${item.owner?.accountId}/dashboard`}
                                                    className="text-link"
                                                >
                                                    {item.owner?.account}
                                                </Link>
                                            ) : (
                                                "--"
                                            )}
                                        </td>
                                        <td>
                                            <GetOwnerType
                                                value={item.owner?.type}
                                            />
                                        </td>
                                        <td>
                                            <GetOwnerLink value={item.owner} />
                                        </td>
                                        <td>{item.number}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className="card-body py-3 px-5">
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={entityList?.paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList?.result?.length}
                        countTotal={entityList?.paginator.totalCount}
                        forcePage={forcePage}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminCardsList;
