import { useState } from "react";
import { useGetSchemeSchedulesQuery } from "../../app/storeReducers/schemeScheduleApiSlice";
import { useCreateSchemeMutation } from "../../app/storeReducers/schemesApiSlice";

const SchemeCreateModal = (props) => {
    const { accountId, isOpen, closeHandle } = props;

    const [name, setName] = useState("");
    const [schedule, setSchedule] = useState("");

    const { data: schedulesList } = useGetSchemeSchedulesQuery({
        accountId,
    });

    const [createAsync] = useCreateSchemeMutation();

    const saveEntity = async () => {
        let scheduleNew = null;
        if (schedule !== "") {
            scheduleNew = { id: schedule };
        }
        const entity = {
            name,
            account: { id: accountId },
            schedule: scheduleNew,
        };
        await createAsync(entity);
        setName("");
        setSchedule("");
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить новую схему
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-3">
                                <label htmlFor="name">Название</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label>График доступа</label>
                                <select
                                    className="form-select"
                                    value={schedule}
                                    onChange={(e) =>
                                        setSchedule(e.target.value)
                                    }
                                >
                                    <option value="0">--Без графика--</option>
                                    {schedulesList?.result.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default SchemeCreateModal;
