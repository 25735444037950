import { baseApi } from "../baseApi";

export const logTimetrackingApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getLogTimeTrackingDays: builder.query({
            query: (data) =>
                `log/timetracking/days?accountId=${
                    data.accountId ?? ""
                }&employeeId=${data.employeeId ?? ""}&groupId=${
                    data.groupId ?? ""
                }&locationId=${data.locationId ?? ""}&start=${
                    data.start ?? ""
                }&finish=${data.finish ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}&export=false`,
        }),
        exportLogTimeTrackingDays: builder.query({
            query: (data) => ({
                url: `log/timetracking/days?accountId=${
                    data.accountId ?? ""
                }&employeeId=${data.employeeId ?? ""}&groupId=${
                    data.groupId ?? ""
                }&locationId=${data.locationId ?? ""}&start=${
                    data.start ?? ""
                }&finish=${data.finish ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}&export=${
                    data.export ?? "v1"
                }`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
        getLogTimeTrackingVisits: builder.query({
            query: (data) =>
                `log/timetracking/visits?accountId=${
                    data.accountId ?? ""
                }&employeeId=${data.employeeId ?? ""}&groupId=${
                    data.groupId ?? ""
                }&locationId=${data.locationId ?? ""}&start=${
                    data.start ?? ""
                }&finish=${data.finish ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}&export=false`,
        }),
        exportLogTimeTrackingVisits: builder.query({
            query: (data) => ({
                url: `log/timetracking/visits?accountId=${
                    data.accountId ?? ""
                }&employeeId=${data.employeeId ?? ""}&groupId=${
                    data.groupId ?? ""
                }&locationId=${data.locationId ?? ""}&start=${
                    data.start ?? ""
                }&finish=${data.finish ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}&export=${
                    data.export ?? "v1"
                }`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
    }),
});

export const {
    useGetLogTimeTrackingDaysQuery,
    useLazyGetLogTimeTrackingDaysQuery,
    useGetLogTimeTrackingVisitsQuery,
    useLazyGetLogTimeTrackingVisitsQuery,
    useExportLogTimeTrackingDaysQuery,
    useLazyExportLogTimeTrackingDaysQuery,
    useExportLogTimeTrackingVisitsQuery,
    useLazyExportLogTimeTrackingVisitsQuery,
} = logTimetrackingApiSlice;
