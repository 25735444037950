import { RootState } from '../store';
import { CRUDReducer } from './CRUDReducer';

const baseReducer = new CRUDReducer(
    'accountParkingPlaces',
    'parking/places'
);

export const { getListAsync, createAsync, updateAsync, deleteAsync } =
    baseReducer;

const accountParkingPlacesSlice: any = baseReducer.reducerSlice;

export const selectList = (state: RootState) => state.accountParkingPlaces.objectsArray;
export const selectIsLoading = (state: RootState ) => state.accountParkingPlaces.isLoading;

export default accountParkingPlacesSlice.reducer;
