import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectIsAuthorize,
    logoutUser,
} from '../app/storeReducers/CurrentUserSlice';
import { Http } from '../http';

const LogoutPage = () => {
    const dispatch = useDispatch();

    const isAuthorize = useSelector(selectIsAuthorize);
    const user = useSelector((state) => state.currentUser.user);

    useEffect(async () => {
        if (isAuthorize) {
            await Http.delete(`api/auth/security`, true);
            dispatch(logoutUser());
        }
        if (user.length === 0) {
            window.location.href = '/';
        }
    });

    return null;
};

export default LogoutPage;
