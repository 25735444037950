import { useEffect, useState } from 'react';
import { createAsync } from '../../app/storeReducers/AccountSchemesSlice';
import { useDispatch } from 'react-redux';
import { Http } from '../../http';

const SchemeCreateModal = (props) => {
    const { accountId, isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [type, setType] = useState('user');
    const [schedule, setSchedule] = useState('');
    const [schedulesList, setSchedulesList] = useState([]);

    useEffect(() => {
        setName('');
        setType('user');
        setSchedule('');
        setSchedulesList([]);
    }, [isOpen])


    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const getSchedules = await Http.get(
                'api/schemes/schedules?accountId=' + accountId,
                true
            );
            let schedules = [];
            schedules.push({
                id: '',
                name: '',
                description: '--Без графика--',
            });
            getSchedules.result.map((item) => {
                schedules.push({
                    id: item.id,
                    name: item.id,
                    description: item.name,
                });
            });
            setSchedulesList(schedules);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    const saveEntity = async () => {
        let scheduleNew = null;
        if (schedule !== '') {
            scheduleNew = { id: schedule };
        }
        const entity = {
            name,
            account: { id: accountId },
            schedule: scheduleNew,
        };
        console.log(entity);
        dispatch(createAsync(entity));
        setName('');
        setSchedule('');
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить новую схему
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='form-group mb-3'>
                                <label htmlFor='name'>Название</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='form-group mb-3'>
                                <label>График доступа</label>
                                <select
                                    className='form-select'
                                    value={schedule}
                                    onChange={(e) =>
                                        setSchedule(e.target.value)
                                    }
                                >
                                    {schedulesList.map((item) => (
                                        <option key={item.id} value={item.name}>
                                            {item.description}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default SchemeCreateModal;
