import { useEffect, useState } from 'react';
import { DateToString, UtcToLocal } from '../../../helpers/DateHelpers';
import SkeletonTable from '../../../components/skeleton/SkeletonTable';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import Paginator from '../../../components/Paginator';
import { useHistory } from 'react-router-dom';
import FindComponent from '../../../components/FindComponent';
import { useGetEmployeesQuery } from '../../../app/storeReducers/employeesApiSlice';

const AdminEmployeesListTable = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);

    const [searchString, setSearchString] = useState('');

    const { data: employees, isLoading } = useGetEmployeesQuery({
        accountId,
        search: searchString,
        sort: 'last_name',
        pageSize,
        pageIndex,
    });

    useEffect(() => {
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
            (tooltipNode) => new Tooltip(tooltipNode)
        );
    }, [isLoading]);

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(0);
        setPageSize(size);
    };

    const openEntityCard = (accountId, entityId) => {
        history.push(`/account/${accountId}/employees/${entityId}`);
    };

    return (
        <>
            <div className='card mb-4'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    <div className='card-toolbar'>
                        <FindComponent callback={(name) => setSearchString(name)} />
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <SkeletonTable
                            cols={['Имя', 'Дата начала', 'Дата окончания', '', '']}
                            rowsCount={15}
                            isLoading={isLoading}
                            classTable='table-striped gy-7 gs-7'
                        >
                            <table className='table table-striped gy-7 gs-7'>
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th>Дата</th>
                                        <th>Имя</th>
                                        <th>Логин</th>
                                        <th>Аккаунт</th>
                                        <th>Роль</th>
                                        <th>Статус</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employees?.result?.map((item) => (
                                        <tr
                                            key={item.id}
                                            onClick={() => openEntityCard(item.account.id, item.id)}
                                            className='cursor-pointer'
                                        >
                                            <td className='ps-4'>{DateToString(UtcToLocal(item.createdAt))}</td>
                                            <td className='ps-4'>{item.name}</td>
                                            <td>{item.user?.title}</td>
                                            <td>{item.account?.title}</td>
                                            <td>{item.roles.join(', ')}</td>
                                            <td>
                                                {item.blocked ? (
                                                    <i
                                                        className='fas fa-ban text-danger me-2'
                                                        data-bs-toggle='tooltip'
                                                        data-bs-placement='top'
                                                        title='Заблокирован'
                                                    />
                                                ) : (
                                                    <span className='badge badge-success'>OK</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </SkeletonTable>
                    </div>
                </div>
            </div>
            <Paginator
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                pageCount={employees?.paginator?.totalPages}
                pageSizeArray={[50, 100, 500]}
                pageSize={pageSize}
                countCurrent={employees?.result?.length}
                countTotal={employees?.paginator?.totalCount}
            />
        </>
    );
};

export default AdminEmployeesListTable;
