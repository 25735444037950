import { useEffect, useState } from 'react';
import { Http } from '../../../../../../http';
import AddVehicleModal from './AddVehicleModal';
import RemoveVehicleModal from './RemoveVehicleModal';
import { selectList } from '../../../../../../app/storeReducers/AccountServicesSlice';
import { useSelector } from 'react-redux';

const VehicleControlPanel = (props) => {
    const { accountId, placeId, vehicleId, addHandle } = props;

    const servicesList = useSelector(selectList);

    const [isOpenAddModal, setIsOpenAddModal] = useState(false);
    const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
    const [vehicle, setVehicle] = useState(null);
    const [serviceAccountId, setServiceAccountId] = useState(0);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (vehicleId !== undefined && vehicleId !== null) {
                console.log('vehicleId', vehicleId);
                const getResult = await Http.get(
                    'api/vehicles/' + vehicleId,
                    true
                );
                console.log('vehicle', getResult);
                setVehicle(getResult.result);
            } else setVehicle(null);
        }

        loadResourcesAndDataAsync();
    }, [vehicleId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            let selectedAccountId = 0;
            if (servicesList.length > 0) {
                selectedAccountId = servicesList[0].consumer.id;
            }
            setServiceAccountId(selectedAccountId);
        }

        loadResourcesAndDataAsync();
    }, [servicesList]);

    const addVehicle = (vehicleChanged) => {
        setIsOpenAddModal(false);
        addHandle(vehicleChanged);
    };

    const removeVehicle = () => {
        setIsOpenRemoveModal(false);
        addHandle(null);
    };

    console.log('vehicleId', vehicleId);

    return (
        <>
            <AddVehicleModal
                accountId={accountId}
                placeId={placeId}
                selectedAccount={serviceAccountId}
                isOpen={isOpenAddModal}
                okHandle={(vehicleChanged) => addVehicle(vehicleChanged)}
                closeHandle={() => setIsOpenAddModal(false)}
            />
            <RemoveVehicleModal
                vehicle={vehicle}
                isOpen={isOpenRemoveModal}
                okHandle={removeVehicle}
                closeHandle={() => setIsOpenRemoveModal(false)}
            />
            <div className='card border mb-3'>
                <div className='card-body pb-2'>
                    <div className='row mb-3'>
                        <div className='col-md-6 align-self-center'>
                            <h3>Транспортное средство</h3>
                        </div>
                    </div>
                    {vehicle !== null ? (
                        <>
                            <div className='row mb-3'>
                                <div className='col-md-3'>Номер</div>
                                <div className='col-md-9'>
                                    <b>
                                        {vehicle.carNumber + vehicle.carRegion}
                                    </b>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-3'>Модель</div>
                                <div className='col-md-9'>
                                    <b>{vehicle.model}</b>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-3'>Аккаунт</div>
                                <div className='col-md-9'>
                                    <b>{vehicle.account?.title}</b>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-3'>Сотрудник</div>
                                <div className='col-md-9'>
                                    <b>{vehicle.employee?.title}</b>
                                </div>
                            </div>
                            <div className='d-grid gap-2'>
                                <button
                                    className='btn av-btn-simple'
                                    type='button'
                                    onClick={() => setIsOpenRemoveModal(true)}
                                >
                                    Отвязать транспортное средство
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>
                                <i>Транспортное средство не привязано</i>
                            </p>
                            <div className='d-grid gap-2'>
                                <button
                                    className='btn av-btn-simple'
                                    type='button'
                                    onClick={() => setIsOpenAddModal(true)}
                                >
                                    Привязать транспортное средство
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default VehicleControlPanel;
