import { useEffect, useState } from "react";
import SkeletonSelect from "../../../../components/skeleton/SkeletonSelect";
import { useGetDevicesQuery } from "../../../../app/storeReducers/devicesApiSlice";
import { useAddScheduleDeviceMutation } from "../../../../app/storeReducers/SchedulesAPI";

const ScheduleAddDeviceModal = (props) => {
    const { accountId, entityId, isOpen, okHandle, closeHandle } = props;

    const { data, isLoading } = useGetDevicesQuery({
        accountId,
    });
    const [addEntity, { r }] = useAddScheduleDeviceMutation();

    const [deviceSelected, setDeviceSelected] = useState({});
    const [otherSchedule, setOtherSchedule] = useState("");

    useEffect(() => {
        if (isOpen) {
            setDeviceSelected(data?.result[0]?.id ?? {});
        }
    }, [isOpen]);

    useEffect(() => {
        if (deviceSelected) {
            let o = data?.result?.find((x) => x.id == deviceSelected);
            if (o) {
                if (o?.schedule?.id != entityId) {
                    setOtherSchedule(o?.schedule?.title ?? "");
                } else {
                    setOtherSchedule("");
                }
            } else {
                setOtherSchedule("");
            }
        }
    }, [deviceSelected]);

    const save = async () => {
        await addEntity({ id: entityId, deviceId: deviceSelected });
        okHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить устройство в расписание
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-4">
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label="Устройство"
                                    value={deviceSelected}
                                    onChange={(e) =>
                                        setDeviceSelected(e.target.value)
                                    }
                                    itemsArray={data?.result?.map((item) => ({
                                        id: item.id,
                                        name: item.id,
                                        description: item.name,
                                    }))}
                                />
                            </div>
                            {otherSchedule != "" ? (
                                <div style={{ color: "red" }}>
                                    Устройство привязано к другому расписанию "
                                    {otherSchedule}".
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={save}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default ScheduleAddDeviceModal;
