const HostWidget = (props) => {
    const { hostCountTotal, hostCountOnline, hostCountOffline } = props;

    return (
        <div className='card border widget-card mb-6'>
            <div className='card-body p-6'>
                <div className='widget-title mb-3'>
                    <div className='widget-icon me-4'>
                        <img src='/media/icons/avanguard/server-white.png' />
                    </div>
                    Серверы
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>{hostCountTotal}</div>
                    <div className='widget-count-title'>Всего</div>
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>{hostCountOnline}</div>
                    <div className='widget-count-title'>В сети</div>
                </div>
                <div className='widget-count-block d-inline-block me-5 text-danger'>
                    <div className='widget-count-number'>
                        {hostCountOffline}
                    </div>
                    <div className='widget-count-title'>Не в сети</div>
                </div>
            </div>
        </div>
    );
};

export default HostWidget;
