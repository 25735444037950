import React, { useContext } from 'react';
import { MenuDefault } from '../menu/MenuDefault';
import { Footer } from '../../_metronic/layout/components/Footer';
import { HeaderWrapper } from './header/HeaderWrapper';
import { Toolbar } from '../../_metronic/layout/components/toolbar/Toolbar';
import { ScrollTop } from '../../_metronic/layout/components/ScrollTop';
import { Content } from '../../_metronic/layout/components/Content';
import { MasterInit } from '../../_metronic/layout/MasterInit';
import { PageDataProvider } from './core/PageData';
import { useHistory, useLocation } from 'react-router';
import SecutiryContext from '../../features/securityContext';

const MasterLayout: React.FC<{ mode: string; noToolbar: boolean }> = ({
    children,
    mode,
    noToolbar,
}) => {
    const history = useHistory();
    const location = useLocation();
    const { context } = useContext(SecutiryContext) as any;
    const startOnboarding = () => {
        (window as any).reachGoal('demoobzor');
        localStorage.removeItem('tutorial');
        console.log(location.pathname);
        if (location.pathname === '/personal/overview') {
            window.location.href = '/personal/overview';
        }
        history.push('/personal/overview');
    };
    return (
        <PageDataProvider>
            <div className='page d-flex flex-row flex-column-fluid'>
                {/* временно отключил */}
                {context?.user?.login === '70000000000' ? (
                    <button
                        className='btn av-btn-primary position-fixed'
                        id='onboardingStartBtn'
                        onClick={startOnboarding}
                    >
                        <img
                            src='/media/icons/avanguard/Support1-white.png'
                            width={20}
                            className='me-1'
                        />{' '}
                        Обзор продукта
                    </button>
                ) : null}
                <MenuDefault mode={mode} />
                <div
                    className='wrapper d-flex flex-column flex-row-fluid'
                    id='kt_wrapper'
                >
                    <HeaderWrapper mode={mode} />

                    <div
                        id='kt_content'
                        className={
                            'content d-flex flex-column flex-column-fluid pt-0' +
                            (noToolbar ? ' no-toolbar' : '')
                        }
                    >
                        {noToolbar ? null : <Toolbar />}
                        <div
                            className='post d-flex flex-column-fluid'
                            id='kt_post'
                        >
                            <Content>{children}</Content>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>

            <MasterInit />
            <ScrollTop />
        </PageDataProvider>
    );
};

export { MasterLayout };
