import { useEffect, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CropImageView = (props) => {
    const { file, closeHandle, saveHandle } = props;

    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState();
    const [output, setOutput] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);

    useEffect(() => {
        if (file) {
            setSrc(URL.createObjectURL(file));
        }
    }, [file]);

    useEffect(() => {
        if (thumbnail) {
            saveHandle(output, thumbnail);
            closeHandle();
        }
    }, [thumbnail]);

    const savePhoto = async () => {
        cropImageNow();
    };

    function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: 'px',
                    width: 200,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );
    }

    const cropImageNow = () => {
        const image = document.getElementById('imageCrop');
        const canvas = document.getElementById('canvasCrop');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio;
        canvas.height = crop.height * pixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // Converting to base64
        const base64Image = canvas.toDataURL('image/jpeg');
        //console.log(base64Image);
        setOutput(base64Image);

        var img = document.createElement('img');
        img.setAttribute('src', base64Image);
        img.onload = () => {
            var canvasThumbnail = document.getElementById('canvasThumbnailCrop');
            var ctxThumb = canvasThumbnail.getContext('2d');
            canvasThumbnail.width = 64;
            canvasThumbnail.height = 64;
            ctxThumb.drawImage(img, 0, 0, canvasThumbnail.width, canvasThumbnail.height);
            const base64ImageThumbnail = canvasThumbnail.toDataURL('image/jpeg');
            //console.log(base64ImageThumbnail);
            setThumbnail(base64ImageThumbnail);
        };
    };

    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        const crop = centerAspectCrop(width, height, 1 / 1);
        setCrop(crop);
    }

    return (
        <>
            <div className='card'>
                <div className='modal-content'>
                    <div>
                        <h5 className='modal-title'>Редактирование фотографии</h5>
                        <div>Выделите требуемую часть изображения.</div>
                    </div>
                    <div className='card-body'>
                        <div className='row justify-content-between'>
                            <div className='col'>
                                <div className='d-flex mx-auto'>
                                    <div>
                                        {src && (
                                            <div>
                                                <ReactCrop
                                                    crop={crop}
                                                    onChange={(x) => {
                                                        setCrop(x);
                                                    }}
                                                >
                                                    <img
                                                        id='imageCrop'
                                                        src={src}
                                                        onLoad={onImageLoad}
                                                        style={{ maxWidth: 500, maxHeight: 500 }}
                                                    />
                                                </ReactCrop>
                                                {crop && (
                                                    <>
                                                        <canvas
                                                            hidden
                                                            id='canvasCrop'
                                                            style={{
                                                                border: '3px solid black',
                                                                objectFit: 'contain',
                                                                width: crop.width,
                                                                height: crop.height,
                                                            }}
                                                        />
                                                        <canvas
                                                            hidden
                                                            id='canvasThumbnailCrop'
                                                            style={{
                                                                border: '3px solid green',
                                                                objectFit: 'contain',
                                                                width: 64,
                                                                height: 64,
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='justify-content-between pb-3'>
                        <div>
                            <button type='button' className='btn av-btn-simple me-3' onClick={closeHandle}>
                                Отменить
                            </button>
                            <button type='button' className='btn av-btn-primary' onClick={savePhoto}>
                                Применить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CropImageView;
