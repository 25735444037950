import { useState } from "react";
import InputMask from "react-input-mask";
import { useSetUserDemoOrderMutation } from "../../app/storeReducers/usersApiSlice";

const CallMeModal = (props) => {
    const { isOpen, okHandle, closeHandle } = props;

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const [setDemoOrder] = useSetUserDemoOrderMutation();

    const sendOrder = async () => {
        const result = await setDemoOrder({
            name,
            phone,
        }).unwrap();
        window.reachGoal("demosend");
        console.log("sendResult", result);
        okHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header pb-0 align-items-start">
                            <div className="modal-title">
                                <h5 className="mb-3">Оставить заявку</h5>
                                <p className="av-text-gray-500">
                                    Заполните форму и наши менеджеры свяжутся с
                                    вами чтобы обсудить ваш проект
                                </p>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-5">
                                <label htmlFor="name">Имя</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Телефон</label>
                                <InputMask
                                    mask="+7(999)999-99-99"
                                    className="form-control"
                                    id="phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder="+7(___)___-__-__"
                                />
                            </div>
                        </div>
                        <div className="modal-footer d-block py-3">
                            <div className="d-grid mb-5">
                                <button
                                    type="button"
                                    className="btn av-btn-primary"
                                    onClick={sendOrder}
                                >
                                    Отправить
                                </button>
                            </div>
                            <p
                                className="text-center av-text-gray-500"
                                style={{ fontSize: 12 }}
                            >
                                Нажимая на кнопку, вы соглашаетесь с нашей
                                <br />
                                <a
                                    target="_blank"
                                    href="/media/politika.pdf"
                                    className="av-text-primary"
                                >
                                    Политикой конфиденциальности
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default CallMeModal;
