import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../app/storeReducers/NotifySlice';
import SkeletonTableFull from '../../../components/skeleton/SkeletonTableFull';
import { ToUtcString } from '../../../helpers/DateHelpers';
import { Http } from '../../../http';
import ReportParkingDetailsModal from './ReportParkingDetailsModal';
import { saveAs } from 'file-saver';
import { GetMonthName } from '../../../helpers/DateHelpers';

const ReportParkingList = (props) => {
    const { accountId } = props;

    const dispatch = useDispatch();

    const [dateReport, setDateReport] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [report, setReport] = useState([]);
    const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
    const [currentDetails, setCurrentDetails] = useState([]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(false);

            const dtStart = dateReport;
            const dtStartString = `${dtStart.getFullYear()}-${
                dtStart.getMonth() + 1
            }-01`;
            try {
                const getResult = await Http.get(
                    `api/parking/billing?accountId=${accountId}&date=${dtStartString}`,
                    true
                );
                console.log('parkingReport', getResult.result);
                getResult?.result?.map((item, index) => {
                    item.keyItem = index;
                    item.accountName = item.account?.title;
                    item.btn = (
                        <button
                            className='btn av-btn-simple'
                            onClick={() => openDetails(item.details)}
                        >
                            Подробно
                        </button>
                    );
                });
                setReport(getResult.result);
            } catch (error) {
                console.log('error', error);
                dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка!',
                        description:
                            'Перезагрузите страницу и попробуйте снова! ' +
                            error.message,
                        type: 'danger',
                    })
                );
            }

            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [accountId, dateReport]);

    const openDetails = (details) => {
        setCurrentDetails(details);
        setIsModalDetailOpen(true);
    };

    const exportData = async () => {
        const dtStart = dateReport;
        const dtStartString = `${dtStart.getFullYear()}-${
            dtStart.getMonth() + 1
        }-01`;
        const response = await Http.getMedia(
            `api/parking/billing?accountId=${accountId}&date=${dtStartString}&export=true`
        );
        var blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const monthName = GetMonthName(dateReport);
        const filename = `Отчет по услугам парковки - ${monthName} ${dtStart.getFullYear()}.xlsx`;
        saveAs(blob, filename);
    };

    return (
        <>
            <ReportParkingDetailsModal
                isOpen={isModalDetailOpen}
                entity={currentDetails}
                closeHandle={() => setIsModalDetailOpen(false)}
            />
            <div className='card border'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    <div className='card-toolbar'>
                        <div className='input-group' style={{ width: 'auto' }}>
                            <input
                                type='month'
                                className='form-control'
                                value={ToUtcString(dateReport)
                                    .split('T')[0]
                                    .slice(0, -3)}
                                onChange={(e) =>
                                    setDateReport(new Date(e.target.value))
                                }
                            />
                        </div>
                        <button
                            className='btn av-btn-simple ms-3'
                            onClick={exportData}
                        >
                            <i className='far fa-file-excel me-3'></i> Экспорт
                            в Excel
                        </button>
                    </div>
                </div>
                <div className='table-responsive'>
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: 'keyItem',
                            accountName: 'Аккаунт',
                            service: 'Название',
                            total: 'Стоимость',
                            btn: '',
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='mb-0'
                        entity={report}
                        tdClasses={{ accountName: 'ps-6', total: 'text-end' }}
                    />
                </div>
            </div>
        </>
    );
};

export default ReportParkingList;
