/* eslint-disable react/jsx-no-target-blank */
import { MenuItem } from './MenuItem';
import { useRouteMatch } from 'react-router';
import { useAccount } from '../../components/useAccount';

interface LayoutParams {
    accountId: string;
}

export function MenuAdmin() {
    const matchParams = useRouteMatch().params as LayoutParams;
    const account = useAccount();

    return (
        <>
            <MenuItem
                to={`/admin/dashboard`}
                title='Обзор'
                imageIcon='/media/icons/avanguard/Home1.png'
            />
            <MenuItem
                to={`/admin/hosts`}
                title='Серверы'
                icon='/media/icons/base/server.svg'
            />
            <MenuItem
                to={`/admin/accounts`}
                title='Аккаунты'
                icon='/media/icons/base/user.svg'
            />
            <MenuItem
                to={`/admin/devices`}
                title='Устройства'
                icon='/media/icons/base/cpu.svg'
            />
            <MenuItem
                to={`/admin/employees`}
                title='Пользователи'
                icon='/media/icons/base/users.svg'
            />
            <MenuItem
                to={`/admin/cards`}
                title='Карты'
                icon='/media/icons/avanguard/CardsProximity.svg'
            />
            <MenuItem
                to={`/admin/support`}
                title='Техподдержка'
                icon='/media/icons/avanguard/Support1.svg'
            />
        </>
    );
}
