import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    account: {},
};

export const getAccountAsync = createAsyncThunk(
    'account/getAccount',
    async (accountId) => {
        const accResult = await Http.get('api/accounts/' + accountId);
        return accResult.result;
    }
);

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: {
        [getAccountAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getAccountAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.account = action.payload;
            }
            state.isLoading = false;
        },
        [getAccountAsync.rejected]: (state, action) => {
            console.error('getAccountError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const selectAccount = (state) => state.account.account;
export const selectIsLoading = (state) => state.account.isLoading;

export default accountSlice.reducer;
