import { useContext, useEffect, useState } from 'react';
import { Http } from '../../http';

import PermissionPage from '../../components/PermissionPage';
import DeviceCamerasPanel from './cameras/DeviceCamerasPanel';
import DeviceControlPanel from './control/DeviceControlPanel';
import DevicePanel from './DevicePanel';
import DevicePhonePanel from './DevicePhonePanel';
import DeviceQrCodePanel from './DeviceQrCodePanel';
import DeviceSchemesPanel from './DeviceSchemesPanel';
import DeviceStatusPanel from './DeviceStatusPanel';
import SecutiryContext from '../../features/securityContext';

const DeviceCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const deviceId = props.match.params.deviceId;

    const [isBasicAccount, setIsBasicAccount] = useState(false);
    const [isSubAccount, setIsSubAccount] = useState(false);
    const { context } = useContext(SecutiryContext);

    const [accountActions, setAccountActions] = useState([]);

    const [showStatusPanel, setShowStatusPanel] = useState(false);
    const [showPhonePanel, setShowPhonePanel] = useState(false);
    const [showControlPanel, setShowControlPanel] = useState(false);


    useEffect(() => {
        setAccountActions(
            context?.permissions?.find((x) => x.id === accountId)?.actions
        );
    }, [context, accountId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (deviceId > 0) {
                const deviceResult = await Http.get(
                    'api/devices/' + deviceId,
                    true
                );
                console.log(deviceResult);
                const accResult = await Http.get(
                    'api/accounts/' + deviceResult?.result?.account?.id,
                    true
                );
                setIsBasicAccount(accResult?.result?.type === 'basic');
                setIsSubAccount(accResult?.result?.type === 'subaccount');
                setShowStatusPanel(deviceResult?.result?.provider !== 'timetracking');
                setShowPhonePanel(deviceResult?.result?.provider !== 'timetracking');
                setShowControlPanel(deviceResult?.result?.provider !== 'timetracking');
            }
        }

        loadResourcesAndDataAsync();
        return () => {
            setIsBasicAccount(false);
            setIsSubAccount(false);
        };
    }, [deviceId]);

    return (
        <PermissionPage accountId={accountId} roles={['devices_list']}>
            <h1 className='mb-5'>Карточка устройства</h1>
            <div className='row'>
                <div className='col-6'>
                    <DevicePanel accountId={accountId} deviceId={deviceId} />
                </div>
                <div className='col-6'>
                    {showStatusPanel ? (
                        <div className='row mb-4'>
                            <div className='col'>
                                <DeviceStatusPanel
                                    deviceId={deviceId}
                                    accountId={accountId}
                                />
                            </div>
                        </div>
                    ) : ''}
                    {!isBasicAccount &&
                    !isSubAccount &&
                    showControlPanel &&
                    (context?.isRoot ||
                        accountActions?.includes('devices_edit')) ? (
                        <div className='row mb-4'>
                            <div className='col'>
                                <DeviceControlPanel
                                    accountId={accountId}
                                    deviceId={deviceId}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='card border'>
                                <div className='card-body pb-2'>
                                    <DeviceSchemesPanel
                                        accountId={accountId}
                                        deviceId={deviceId}
                                    />
                                    <DeviceCamerasPanel
                                        accountId={accountId}
                                        entityId={deviceId}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {showPhonePanel ? (
                        <div className='row mb-4'>
                            <div className='col'>
                                <DevicePhonePanel
                                    accountId={accountId}
                                    deviceId={deviceId}
                                />
                            </div>
                        </div>
                    ) : ''}
                    <div className='row mb-4'>
                        <div className='col'>
                            <DeviceQrCodePanel
                                context={context}
                                accountId={accountId}
                                deviceId={deviceId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default DeviceCardPage;
