import React from 'react';

const WaitLoading = (props) => {
    return (
        <React.Fragment>
            {!props.isLoading ? (
                <React.Fragment>{props.children}</React.Fragment>
            ) : (
                <div className="loader" />
            )}
        </React.Fragment>
    );
};

export default WaitLoading;
