import { useEffect, useState } from "react";
import { useLazyGetEmployeePhotoQuery } from "../../../app/storeReducers/employeesApiSlice";

const AvatarView = (props) => {
    const { employeeId } = props;

    const [imageSource, setImageSource] = useState("/images/no-user.jpg");
    const [getEmployeePhoto] = useLazyGetEmployeePhotoQuery();

    useEffect(async () => {
        console.log("employeeId:" + employeeId);
        if (parseInt(employeeId, 10) > 0) {
            console.log("request image");
            const response = await getEmployeePhoto(employeeId).unwrap();
            if (response.result !== undefined) {
                if (response.result.image !== undefined) {
                    setImageSource(
                        "data:image/jpg;base64," + response.result.image
                    );
                } else {
                    setImageSource("/images/no-user.jpg");
                }
            } else {
                setImageSource("/images/no-user.jpg");
            }
        }
    }, [employeeId]);

    return (
        <>
            <div style={{ textAlign: "center" }} className="mb-6">
                <img
                    alt="Logo"
                    src={imageSource}
                    style={{
                        maxWidth: "200px",
                        maxHeight: "200px",
                        textAlign: "center",
                    }}
                />
            </div>
        </>
    );
};

export default AvatarView;
