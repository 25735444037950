import WaitLoading from "../../../../../components/WaitLoading";
import { useGetParkingImageByIdQuery } from "../../../../../app/storeReducers/parkingImagesApiSlice";

const ImageModal = (props) => {
    const { imageId, isOpen, closeHandle } = props;

    const { data: image, isLoading } = useGetParkingImageByIdQuery(imageId, {
        skip: !imageId,
    });

    const fullImage = () => {
        var img = new Image();
        img.src = "data:image/png;base64, " + image?.result?.base64Image;

        var w = window.open("");
        w.document.write(img.outerHTML);
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Просмотр изображения
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <WaitLoading isLoading={isLoading}>
                                <img
                                    src={
                                        "data:image/png;base64, " +
                                        image?.result?.base64Image
                                    }
                                    className="img-fluid"
                                />
                            </WaitLoading>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={fullImage}
                            >
                                <i className="fas fa-external-link-alt" />{" "}
                                Оригинальный размер
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default ImageModal;
