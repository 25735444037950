import { useContext, useEffect, useState } from 'react';
import { Http } from '../../http';
import {
    createAsync,
    updateAsync,
} from '../../app/storeReducers/AccountDevicesSlice';
import { useDispatch } from 'react-redux';
import DeviceModeArray from './DeviceModeArray';
import EmergencyInputArray from './EmergencyInputArray';
import SecutiryContext from '../../features/securityContext';
import { useGetSchedulesQuery } from '../../app/storeReducers/SchedulesAPI';

const DeviceCreateModal = (props) => {
    const {
        accountId,
        entityId,
        isOpen,
        closeHandle,
        locations,
        isLite = false,
    } = props;

    const dispatch = useDispatch();

    const { context } = useContext(SecutiryContext);
    const [name, setName] = useState('');
    const [uid, setUid] = useState('');
    const [type, setType] = useState('door');
    const [mode, setMode] = useState('door-magneto');
    const [openTimeout, setOpenTimeout] = useState(0);
    const [gercon, setGercon] = useState(false);
    const [beeper, setBeeper] = useState(false);
    const [emergencyInput, setEmergencyInput] = useState('none');
    const [readerType, setReaderType] = useState('wiegand26');
    const [managedByMobile, setManagedByMobile] = useState(false);
    const [timeTracking, setTimeTracking] = useState(false);
    const [isCardPicker, setIsCardPicker] = useState(false);
    const [locationId, setLocationId] = useState(0);
    const [scheduleId, setScheduleId] = useState(0);
    const [deviceTypesFilter, setDeviceTypesFilter] = useState(
        DeviceModeArray.filter((x) => x.id === 0 || x.id === 1 || x.id === 4)
    );

    const { data: schedules, isLoading } = useGetSchedulesQuery({ accountId });

    useEffect(() => {
        if (entityId <= 0) {
            setName('');
            setUid('');
            setType('door');
            if (isLite) {
                setMode('door-relay');
            } else {
                setMode('door-magneto');
            }
            setOpenTimeout(0);
            setGercon(false);
            setBeeper(false);
            setEmergencyInput('none');
            setReaderType('wiegand26');
            setManagedByMobile(false);
            setTimeTracking(false);
            setIsCardPicker(false);
            setLocationId(0);
            setScheduleId(0);
        }
    }, [isOpen]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== undefined && entityId !== 0 && entityId !== -1) {
                const getResult = await Http.get(
                    'api/devices/' + entityId,
                    true
                );
                setName(getResult.result?.name);
                setUid(getResult.result?.uid);
                setType(getResult.result?.type);
                filterMode(getResult.result?.type);
                setMode(getResult.result?.mode);
                setReaderType(getResult.result?.readerType);
                setManagedByMobile(getResult.result?.managedByMobile);
                setTimeTracking(getResult.result?.timeTracking);
                setIsCardPicker(getResult.result?.isCardPicker);
                setOpenTimeout(getResult.result?.openTimeout);
                setGercon(getResult.result?.gercon);
                setBeeper(getResult.result?.beeper);
                setEmergencyInput(getResult.result?.emergencyInput);
                setLocationId(getResult.result?.location?.id);
                setScheduleId(getResult.result?.schedule?.id ?? 0);
            } else {
                setName('');
                setUid('');
                setType('door');
                if (isLite) {
                    setMode('door-relay');
                } else {
                    setMode('door-magneto');
                }
                setOpenTimeout(0);
                setGercon(false);
                setBeeper(false);
                setEmergencyInput('none');
                setReaderType('wiegand26');
                setManagedByMobile(false);
                setTimeTracking(false);
                setIsCardPicker(false);
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    const saveEntity = async () => {
        const entity = {
            provider: isLite ? 'lite' : 'atlas',
            name,
            mode,
            type,
            uid,
            account: { id: accountId },
            openTimeout,
            gercon,
            beeper,
            emergencyInput,
            id: entityId,
            readerType,
            managedByMobile,
            timeTracking,
            isCardPicker,
            location: { id: locationId },
            schedule: { id: scheduleId },
        };
        if (entityId > 0) {
            dispatch(updateAsync(entity));
        } else {
            dispatch(createAsync(entity));
        }
        closeHandle();
    };

    const changeType = (selected) => {
        setType(selected);
        filterMode(selected);
    };

    const filterMode = (typeSelected) => {
        switch (typeSelected) {
            case 'door':
                console.log('typeSelected', DeviceModeArray[2].name);
                setMode(
                    isLite === true
                        ? DeviceModeArray[4].name
                        : DeviceModeArray[0].name
                );
                setDeviceTypesFilter(
                    DeviceModeArray.filter(
                        (x) => x.id === 0 || x.id === 1 || x.id === 4
                    )
                );
                break;
            case 'registrator':
                setMode(DeviceModeArray[0].name);
                setDeviceTypesFilter(
                    DeviceModeArray.filter(
                        (x) => x.id === 0 || x.id === 1 || x.id === 4
                    )
                );
                break;
            case 'autogate':
                setMode(DeviceModeArray[2].name);
                setDeviceTypesFilter(DeviceModeArray.filter((x) => x.id === 2));
                break;
            case 'autogate2':
                setMode(DeviceModeArray[2].name);
                setDeviceTypesFilter(DeviceModeArray.filter((x) => x.id === 2));
                break;
            case 'turnstile':
                setMode(DeviceModeArray[3].name);
                setDeviceTypesFilter(DeviceModeArray.filter((x) => x.id === 3));
                break;
            default:
                setMode(DeviceModeArray[0].name);
                setDeviceTypesFilter(
                    DeviceModeArray.filter(
                        (x) => x.id === 0 || x.id === 1 || x.id === 4
                    )
                );
                break;
        }
    };
    const isDisabledEmergencyOption = (name) => {
        if (context.isRoot) {
            return '';
        }
        if (name === '' || name === 'nc' || name === 'no') {
            return '';
        }

        return 'disabled';
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                {entityId > 0
                                    ? 'Редактировать'
                                    : 'Добавить новое'}{' '}
                                устройство
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body pb-2'>
                            <div className='row'>
                                <div className='col'>
                                    <div className='form-group'>
                                        <label>Тип устройства</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-md-3 mb-3'>
                                    <div
                                        className={
                                            'rounded p-4 card-type d-flex position-relative h-100 cursor-pointer' +
                                            (type === 'door' ? ' active' : '')
                                        }
                                        onClick={() => changeType('door')}
                                    >
                                        <div>
                                            <div className='card-icon-block me-3 rounded-circle'>
                                                <img
                                                    src='/media/icons/avanguard/Door.svg'
                                                    className='add-profile-img'
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>Дверь</h5>
                                        </div>
                                        <div className='position-absolute check-icon'>
                                            {type === 'door' ? (
                                                <i className='fas fa-check-circle av-text-primary'></i>
                                            ) : (
                                                <i className='far fa-circle text-muted'></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-3'>
                                    <div
                                        className={
                                            'rounded p-4 card-type d-flex position-relative h-100 cursor-pointer' +
                                            (type === 'autogate'
                                                ? ' active'
                                                : '')
                                        }
                                        onClick={() => changeType('autogate')}
                                    >
                                        <div>
                                            <div className='card-icon-block me-3 rounded-circle'>
                                                <img
                                                    src='/media/icons/avanguard/Gate1.svg'
                                                    className='add-profile-img'
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>Шлагбаум</h5>
                                        </div>
                                        <div className='position-absolute check-icon'>
                                            {type === 'autogate' ? (
                                                <i className='fas fa-check-circle av-text-primary'></i>
                                            ) : (
                                                <i className='far fa-circle text-muted'></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-3'>
                                    <div
                                        className={
                                            'rounded p-4 card-type d-flex position-relative h-100 cursor-pointer' +
                                            (type === 'autogate2'
                                                ? ' active'
                                                : '')
                                        }
                                        onClick={() => changeType('autogate2')}
                                    >
                                        <div>
                                            <div className='card-icon-block me-3 rounded-circle'>
                                                <img
                                                    src='/media/icons/avanguard/Gate2.svg'
                                                    className='add-profile-img'
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>2Х шлагбаум</h5>
                                            Двойной шлагбаум
                                        </div>
                                        <div className='position-absolute check-icon'>
                                            {type === 'autogate2' ? (
                                                <i className='fas fa-check-circle av-text-primary'></i>
                                            ) : (
                                                <i className='far fa-circle text-muted'></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3 mb-3'>
                                    <div
                                        className={
                                            'rounded p-4 card-type d-flex position-relative h-100 cursor-pointer' +
                                            (type === 'turnstile'
                                                ? ' active'
                                                : '')
                                        }
                                        onClick={() => changeType('turnstile')}
                                    >
                                        <div>
                                            <div className='card-icon-block me-3 rounded-circle'>
                                                <img
                                                    src='/media/icons/avanguard/Turnstile.svg'
                                                    className='add-profile-img'
                                                    style={{
                                                        width: 20,
                                                        height: 20,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <h5>Турникет</h5>
                                        </div>
                                        <div className='position-absolute check-icon'>
                                            {type === 'turnstile' ? (
                                                <i className='fas fa-check-circle av-text-primary'></i>
                                            ) : (
                                                <i className='far fa-circle text-muted'></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>
                                            Название устройства
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='name'
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='uid'>
                                            UID - идентификатор устройства
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='uid'
                                            value={uid}
                                            onChange={(e) =>
                                                setUid(e.target.value)
                                            }
                                            disabled={entityId > 0}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='openTimeout'>
                                            Время открытия в{' '}
                                            {mode === 'door-magneto'
                                                ? 'секундах'
                                                : 'миллисекундах'}
                                        </label>
                                        <input
                                            type='number'
                                            className='form-control'
                                            id='openTimeout'
                                            value={openTimeout}
                                            onChange={(e) =>
                                                setOpenTimeout(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Тип считывателя</label>
                                        <select
                                            className='form-select'
                                            value={readerType}
                                            onChange={(e) =>
                                                setReaderType(e.target.value)
                                            }
                                            disabled={isLite}
                                        >
                                            {isLite ? (
                                                <option value=''>
                                                    --Отсутствует--
                                                </option>
                                            ) : (
                                                [
                                                    {
                                                        id: 0,
                                                        description:
                                                            'Wiegand 26',
                                                        name: 'wiegand26',
                                                    },
                                                    {
                                                        id: 1,
                                                        description:
                                                            'Wiegand 34',
                                                        name: 'wiegand34',
                                                    },
                                                ].map((item) => (
                                                    <option
                                                        key={item.id}
                                                        value={item.name}
                                                    >
                                                        {item.description}
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Режим работы</label>
                                        <select
                                            className='form-select'
                                            value={mode}
                                            onChange={(e) =>
                                                setMode(e.target.value)
                                            }
                                            disabled={isLite && type === 'door'}
                                        >
                                            {deviceTypesFilter.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.name}
                                                >
                                                    {item.description}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Аварийный вход</label>
                                        <select
                                            className='form-select'
                                            value={emergencyInput}
                                            onChange={(e) =>
                                                setEmergencyInput(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            {EmergencyInputArray.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.name}
                                                    disabled={isDisabledEmergencyOption(
                                                        item.name
                                                    )}
                                                >
                                                    {item.description}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Объект</label>
                                        <select
                                            className='form-select'
                                            value={locationId}
                                            onChange={(e) =>
                                                setLocationId(e.target.value)
                                            }
                                        >
                                            {locations?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Открытие по расписанию</label>
                                        <select
                                            className='form-select'
                                            value={scheduleId}
                                            onChange={(e) =>
                                                setScheduleId(e.target.value)
                                            }
                                        >
                                            <option value={0}>
                                                Не установлено
                                            </option>
                                            {schedules?.result?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className='row mb-3'>
                                <div className='col mb-2'>
                                    <div className='form-check form-check-inline form-group'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id='gercon'
                                            checked={gercon}
                                            onChange={(e) =>
                                                setGercon(e.target.checked)
                                            }
                                            disabled={isLite}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='gercon'
                                        >
                                            Геркон
                                        </label>
                                    </div>
                                    <div className='form-check form-check-inline form-group'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id='beeper'
                                            checked={beeper}
                                            onChange={(e) =>
                                                setBeeper(e.target.checked)
                                            }
                                            disabled={isLite}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='beeper'
                                        >
                                            Звуковой сигнал
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col mb-2'>
                                    <div className='form-check form-check-inline form-group'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id='managedByMobile'
                                            checked={managedByMobile}
                                            onChange={(e) =>
                                                setManagedByMobile(
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='managedByMobile'
                                        >
                                            Управление через мобильное
                                            приложение
                                        </label>
                                    </div>
                                    <div className='form-check form-check-inline form-group'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id='timeTracking'
                                            checked={timeTracking}
                                            onChange={(e) =>
                                                setTimeTracking(
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='timeTracking'
                                        >
                                            Учет рабочего времени
                                        </label>
                                    </div>
                                    <div className='form-check form-check-inline form-group'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id='isCardPicker'
                                            checked={isCardPicker}
                                            onChange={(e) =>
                                                setIsCardPicker(
                                                    e.target.checked
                                                )
                                            }
                                            disabled={isLite}
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='isCardPicker'
                                        >
                                            Картоприемник
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer py-2'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Отменить
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                {entityId > 0
                                    ? 'Сохранить изменения'
                                    : 'Добавить устройство'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeviceCreateModal;
