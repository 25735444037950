import DeviceModeArray from './DeviceModeArray';
import DeviceReaderArray from './DeviceReaderArray';
import EmergencyInputArray from './EmergencyInputArray';

const AvanguardProperties = (props) => {
    const { device } = props;

    return (
        <>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='fild-label'>UID</div>
                    <div className='fild-text'>{device?.uid}</div>
                </div>
                <div className='col-md-6'>
                    <div className='fild-label'>
                        Время открытия (
                        {device?.mode === 'door-magneto' ? 'сек.' : 'мс'})
                    </div>
                    <div className='fild-text'>{device?.openTimeout}</div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='fild-label'>Тип считывателя</div>
                    <div className='fild-text'>
                        {device?.provider === 'atlas'
                            ? DeviceReaderArray.find(
                                  (x) => x.name === device?.readerType
                              )?.description
                            : 'Отсутствует'}
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='fild-label'>Режим работы</div>
                    <div className='fild-text'>
                        {
                            DeviceModeArray.find((x) => x.name === device?.mode)
                                ?.description
                        }
                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='fild-label'>Аварийный вход</div>
                    <div className='fild-text'>
                        {
                            EmergencyInputArray.find(
                                (x) => x.name === device?.emergencyInput
                            )?.description
                        }
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='fild-label'>Открытие по расписанию</div>
                    <div className='fild-text'>
                        {
                            device?.schedule?.title ?? 'Не установлено'
                        }
                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='fild-label'>Объект</div>
                    <div className='fild-text'>{device.location?.title}</div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-md-12'>
                    {device?.gercon ? (
                        <div className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'>
                            Геркон
                        </div>
                    ) : null}
                    {device?.beeper ? (
                        <div className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'>
                            Звуковой сигнал
                        </div>
                    ) : null}
                    {device?.managedByMobile ? (
                        <div className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'>
                            Управление через мобильное приложение
                        </div>
                    ) : null}
                    {device?.timeTracking ? (
                        <div className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'>
                            Учет рабочего времени
                        </div>
                    ) : null}
                    {device?.isCardPicker ? (
                        <div className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'>
                            Картоприемник
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default AvanguardProperties;
