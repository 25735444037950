import { useSetVehicleAutoInOutMutation } from "../../../../app/storeReducers/AccountVehiclesAPI";
import {
    useGetDevicesQuery,
    useOpenDeviceMutation,
} from "../../../../app/storeReducers/devicesApiSlice";
import {
    useSetGuestAutoInMutation,
    useSetGuestAutoOutMutation,
} from "../../../../app/storeReducers/guestsApiSlice";

const BarrierModal = (props) => {
    const { accountId, vehicle, isOpen, closeHandle, direction, handleOk } =
        props;

    const { data: barriers } = useGetDevicesQuery(
        {
            accountId,
            isAutogate: true,
            sort: "name",
        },
        { skip: !accountId }
    );
    const [setGuestAutoIn] = useSetGuestAutoInMutation();
    const [setGuestAutoOut] = useSetGuestAutoOutMutation();
    const [setVehicleAutoInOut] = useSetVehicleAutoInOutMutation();
    const [openDevice] = useOpenDeviceMutation();

    const openBarrier = async (deviceId, dir) => {
        if (Object.keys(vehicle).length !== 0) {
            if (vehicle?.isGuest) {
                if (dir === "in") {
                    await setGuestAutoIn({
                        guestId: vehicle.id,
                        deviceId,
                    });
                } else {
                    await setGuestAutoOut({
                        guestId: vehicle.id,
                        deviceId,
                    });
                }
            } else {
                await setVehicleAutoInOut({
                    vehicleId: vehicle.id,
                    deviceId,
                    direction: dir,
                });
            }
        } else {
            await openDevice({
                id: deviceId,
                direction: dir,
            });
        }
        handleOk();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Управление шлагбаумом
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            {vehicle !== undefined &&
                            vehicle != null &&
                            vehicle?.id !== undefined ? (
                                <div className="row mb-2">
                                    <div className="col">
                                        <h4>
                                            {vehicle.isGuest
                                                ? "Посетитель"
                                                : "Транспорт"}{" "}
                                            {vehicle.carNumber}
                                        </h4>
                                    </div>
                                </div>
                            ) : null}
                            {barriers?.result?.map((item) => (
                                <div
                                    key={item.id}
                                    className="border rounded mb-3 p-2"
                                >
                                    <div className="row">
                                        <div className="col-md-6 d-flex align-items-center">
                                            {item.name}
                                        </div>
                                        <div className="col-md-3">
                                            {direction === "in" ||
                                            direction === "all" ? (
                                                <button
                                                    className="btn av-btn-primary"
                                                    onClick={() =>
                                                        openBarrier(
                                                            item.id,
                                                            "in"
                                                        )
                                                    }
                                                >
                                                    Въезд
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn av-btn-simple"
                                                    disabled
                                                >
                                                    Въезд
                                                </button>
                                            )}
                                        </div>
                                        <div className="col-md-3">
                                            {direction === "out" ||
                                            direction === "all" ? (
                                                <button
                                                    className="btn av-btn-primary"
                                                    onClick={() =>
                                                        openBarrier(
                                                            item.id,
                                                            "out"
                                                        )
                                                    }
                                                >
                                                    Выезд
                                                </button>
                                            ) : (
                                                <button
                                                    className="btn av-btn-simple"
                                                    disabled
                                                >
                                                    Выезд
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default BarrierModal;
