import PermissionPage from '../../components/PermissionPage';
import RolesList from './RolesList';

const RolesPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['roles_list']}>
            <div className='row'>
                <h1 className='mb-5'>Роли сотрудников</h1>
                <div className='col-md-12'>
                    <RolesList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default RolesPage;
