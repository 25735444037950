import { useContext, useEffect, useState } from 'react';
import { useGetScheduleDetailsQuery } from '../../../app/storeReducers/SchedulesAPI';
import SkeletonTable from '../../../components/skeleton/SkeletonTable';
import SecutiryContext from '../../../features/securityContext';
import { KTSVG } from '../../../helpers';
import ScheduleDetailCreateModal from './ScheduleDetailCreateModal';
import ScheduleDetailDeleteModal from './ScheduleDetailDeleteModal';
import { GetDayName } from '../../../helpers/DateHelpers';

const ScheduleDetailsPanel = (props) => {
    const { entityId, accountId } = props;
    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(0);

    const { data, isLoading } = useGetScheduleDetailsQuery(entityId);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [deleteDetailModalIsOpen, setDeleteDetailModalIsOpen] =
        useState(false);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('devices_edit'));
    }, [context, accountId]);

    function DayOfWeek(props) {
        return GetDayName(props.index);
    }

    return (
        <>
            <ScheduleDetailCreateModal
                scheduleId={entityId}
                entity={selectedEntity}
                isOpen={createModalIsOpen}
                closeHandle={() => setCreateModalIsOpen(false)}
            />
            <ScheduleDetailDeleteModal
                entity={selectedEntity}
                isOpen={deleteDetailModalIsOpen}
                closeHandle={() => setDeleteDetailModalIsOpen(false)}
            />

            <div className='card border' id='accessLogPanel'>
                <div className='card-body pb-5'>
                    <div className='row'>
                        <div className='col'>
                            <h3>Детали</h3>
                        </div>
                        <div className='col-md-8 text-end'>
                            {canEdit ? (
                                <button
                                    className='btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0'
                                    onClick={() => {
                                        setSelectedEntity({ id: 0 });
                                        setCreateModalIsOpen(true);
                                    }}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr075.svg'
                                        className='svg-icon-2 me-2'
                                    />
                                    Добавить правило
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['День', 'Время', 'Статус']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0 border-top'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-9'>День</th>
                                    <th className='border-bottom ps-6'>
                                        Время
                                    </th>
                                    <th className='border-bottom ps-6'>
                                        Статус
                                    </th>
                                    <th className='border-bottom ps-6'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        className='cursor-pointer'
                                        style={{ verticalAlign: 'middle' }}
                                    >
                                        <td
                                            className='ps-9 av-text-gray-500'
                                            onClick={() => {
                                                setSelectedEntity(item);
                                                setCreateModalIsOpen(true);
                                            }}
                                        >
                                            <DayOfWeek index={item.dayOfWeek} />
                                        </td>
                                        <td
                                            className='av-text-gray-500'
                                            onClick={() => {
                                                setSelectedEntity(item);
                                                setCreateModalIsOpen(true);
                                            }}
                                        >
                                            {item.timeFrom} - {item.timeTo}
                                        </td>
                                        <td
                                            className='av-text-gray-500'
                                            onClick={() => {
                                                setSelectedEntity(item);
                                                setCreateModalIsOpen(true);
                                            }}
                                        >
                                            {item.isActive ? (
                                                <span
                                                    className='badge bg-success'
                                                    style={{ width: 100 }}
                                                >
                                                    Включено
                                                </span>
                                            ) : (
                                                <span
                                                    className='badge bg-secondary text-dark'
                                                    style={{ width: 100 }}
                                                >
                                                    Выключено
                                                </span>
                                            )}
                                        </td>
                                        <td className='av-text-gray-500'>
                                            {canEdit ? (
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm'
                                                    style={{
                                                        padding: '1px 5px',
                                                    }}
                                                    onClick={() => {
                                                        setSelectedEntity(item);
                                                        setDeleteDetailModalIsOpen(
                                                            item
                                                        );
                                                    }}
                                                >
                                                    <i className='fas fa-trash-alt' />
                                                </button>
                                            ) : (
                                                ''
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default ScheduleDetailsPanel;
