import { baseApi } from "../baseApi";

export const phoneApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        setPhoneTie: builder.mutation({
            query: (deviceId) => ({
                url: "phones/tie?deviceId=" + deviceId,
                method: "PUT",
            }),
        }),
        setPhoneUntie: builder.mutation({
            query: (deviceId) => ({
                url: "phones/untie?deviceId=" + deviceId,
                method: "PUT",
            }),
        }),
    }),
});

export const { useSetPhoneTieMutation, useSetPhoneUntieMutation } =
    phoneApiSlice;
