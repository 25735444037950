const RolesArray = [
    { id: 0, description: 'Доступ супер-пользователя', name: 'root' },
    { id: 1, description: 'Просмотр списка ролей', name: 'roles_list' },
    { id: 2, description: 'Редактирование ролей', name: 'roles_edit' },
    {
        id: 3,
        description: 'Просмотр списка сотрудников',
        name: 'employees_list',
    },
    {
        id: 4,
        description: 'Редактирование карточки сотрудника',
        name: 'employees_edit',
    },
    {
        id: 5,
        description: 'Просмотр истории действий пользователей',
        name: 'user_log_show',
    },
    { id: 6, description: 'Просмотр списка устройств', name: 'devices_list' },
    {
        id: 7,
        description: 'Редактирование карточки устройства',
        name: 'devices_edit',
    },
    {
        id: 8,
        description: 'Просмотр списка схем доступа',
        name: 'schemes_list',
    },
    {
        id: 9,
        description: 'Редактирование карточки схемы доступа',
        name: 'schemes_edit',
    },
    {
        id: 10,
        description: 'Просмотр списка парковок',
        name: 'parking_area_list',
    },
    {
        id: 11,
        description: 'Редактирование карточки парковки',
        name: 'parking_area_edit',
    },
    {
        id: 12,
        description: 'Просмотр списка машиномест',
        name: 'parking_place_list',
    },
    {
        id: 13,
        description: 'Редактирование карточки машиноместа',
        name: 'parking_place_edit',
    },
    { id: 14, description: 'Просмотр списка карт доступа', name: 'cards_list' },
    { id: 15, description: 'Редактирование карты доступа', name: 'cards_edit' },
    { id: 16, description: 'Просмотр списка тарифов', name: 'tariff_list' },
    { id: 17, description: 'Редактирование тарифа', name: 'tariff_edit' },
    {
        id: 18,
        description: 'Просмотр списка групп сотрудников',
        name: 'groups_list',
    },
    {
        id: 19,
        description: 'Редактирование карточки группы',
        name: 'groups_edit',
    },
    {
        id: 20,
        description: 'Просмотр списка транспортных средств',
        name: 'vehicles_list',
    },
    {
        id: 21,
        description: 'Редактирование транспортного средства',
        name: 'vehicles_edit',
    },
    {
        id: 22,
        description: 'Просмотр списка посетителей',
        name: 'guest_list',
    },
    {
        id: 23,
        description: 'Редактирование посетителей',
        name: 'guest_edit',
    },
    {
        id: 24,
        description: 'Просмотр списка субаккаунта',
        name: 'subaccount_list',
    },
    {
        id: 25,
        description: 'Редактирование субаккаунта',
        name: 'subaccount_edit',
    },
    {
        id: 26,
        description: 'Просмотр списка услуг',
        name: 'services_list',
    },
    {
        id: 27,
        description: 'Редактирование услуги',
        name: 'services_edit',
    },
    {
        id: 28,
        description: 'Просмотр журнала доступа',
        name: 'access_log_view',
    },
    {
        id: 29,
        description: 'Просмотр списка камер',
        name: 'camera_list',
    },
    {
        id: 30,
        description: 'Редактирование камеры',
        name: 'camera_edit',
    },
    {
        id: 31,
        description: 'Заявки на посетителей',
        name: 'guest_panel',
    },
    {
        id: 32,
        description: 'Заявки на транспорт',
        name: 'guest_car_panel',
    },
    {
        id: 33,
        description: 'Управление устройством через мобильное приложение',
        name: 'device_mobile_access',
    },
    {
        id: 34,
        description: 'Просмотр полного списка посетителей',
        name: 'guest_list_all',
    },
    {
        id: 35,
        description: 'Просмотр отчета по услугам парковки',
        name: 'parking_billing',
    },
    {
        id: 36,
        description: 'Просмотр заявок',
        name: 'tickets_list',
    },
    {
        id: 37,
        description: 'Обработка заявок',
        name: 'tickets_resolve',
    },
    {
        id: 38,
        description: 'Просмотр мониторов доступа',
        name: 'monitor_list',
    },
    {
        id: 39,
        description: 'Редактирование мониторов доступа',
        name: 'monitor_edit',
    },
    {
        id: 40,
        description: 'Управление устройством через сайт',
        name: 'device_web_access',
    },
    {
        id: 41,
        description: 'Редактирование объектов',
        name: 'location_edit',
    },
    {
        id: 42,
        description: 'Доступ к отчету "Приход-уход"',
        name: 'report_timetracking',
    },
    {
        id: 43,
        description: 'Назначение роли сотруднику',
        name: 'employees_set_role',
    },
    {
        id: 44,
        description: 'Открыть устройство надолго',
        name: 'device_block',
    },
    {
        id: 45,
        description: 'Показывать заказчика пропуска',
        name: 'guest_show_owner',
    },
];

export default RolesArray;
