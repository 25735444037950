import React from 'react';
import { deleteAsync } from '../../app/storeReducers/AccountCamerasSlice';
import { useDispatch } from 'react-redux';
import { useDeleteMonitorMutation } from '../../app/storeReducers/AccountMonitorAPI';
import { useHistory } from 'react-router';

const MonitorDeleteModal = (props) => {
    const { entity, isOpen, closeHandle } = props;

    const history = useHistory();

    const [deleteMonitor, { isError }] = useDeleteMonitorMutation();

    const deleteEntity = async () => {
        deleteMonitor(entity.id);
        closeHandle();
        history.push(`/account/${entity.account.id}/monitors`);
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Удалить монитор</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Вы действительно хотите удалить монитор{' '}
                                <strong className='text-danger'>
                                    {entity.name}
                                </strong>
                            </p>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-danger'
                                onClick={deleteEntity}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default MonitorDeleteModal;
