const DeviceModeArray = [
    {
        id: 0,
        description: 'Электромагнитный замок',
        name: 'door-magneto',
    },
    {
        id: 1,
        description: 'Электромеханический замок',
        name: 'door-mech',
    },
    {
        id: 2,
        description: 'Шлагбаум',
        name: 'shlagbaum',
    },
    { id: 3, description: 'Турникет', name: 'turniket' },
    { id: 4, description: 'Релейный выход', name: 'door-relay' },

];

export default DeviceModeArray;
