import { createSlice } from '@reduxjs/toolkit';

const nameSlice = 'auth';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    securityContext: null,
};

const authSlice = createSlice({
    name: nameSlice,
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setSecurityContext: (state, action) => {
            state.securityContext = action.payload;
        },
    },
});

export const { setSecurityContext } = authSlice.actions;

export const selectContext = (state) => state.auth.securityContext;
export const selectIsLoading = (state) => state.auth.isLoading;

export default authSlice.reducer;
