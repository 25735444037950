/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import clsx from 'clsx';
import { useLayout } from '../../_metronic/layout/core';
import { KTSVG, toAbsoluteUrl } from '../../helpers';
import { Menu } from './Menu';
import { MenuItem } from './MenuItem';
import ImportScript from '../ImportScript';

interface LayoutParams {
    accountId: string;
}

const MenuDefault: FC<{ mode: string }> = ({ mode }) => {
    const { config, classes } = useLayout();
    const { aside } = config;
    const matchParams = useRouteMatch().params as LayoutParams;
    const accountId: number = parseInt(matchParams.accountId);

    return (
        <div
            id='kt_aside'
            className={clsx('aside', classes.aside.join(' '))}
            data-kt-drawer='true'
            data-kt-drawer-name='aside'
            data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width="{default:'200px', '300px': '250px'}"
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_aside_mobile_toggle'
        >
            {/* begin::Brand */}
            <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
                {/* begin::Logo */}
                {aside.theme === 'dark' && (
                    <Link to='/personal/overview'>
                        <img
                            alt='Logo'
                            className='logo'
                            src={toAbsoluteUrl('/images/logo.svg')}
                            height='32px'
                        />
                    </Link>
                )}
                {aside.theme === 'light' && (
                    <Link to='/personal/overview'>
                        <img
                            alt='Logo'
                            className='h-25px logo'
                            src={toAbsoluteUrl('/media/logos/logo-1.svg')}
                        />
                    </Link>
                )}
                {/* end::Logo */}
            </div>
            {/* end::Brand */}

            {/* begin::Aside menu */}
            <div className='aside-menu flex-column-fluid'>
                <Menu menuCSSClasses={classes.asideMenu} mode={mode} />
            </div>
            {/* end::Aside menu */}
            {/* begin::Footer */}
            <div
                className='aside-footer flex-column-auto pt-5 pb-4 px-2 menu'
                id='kt_aside_footer'
            >
                <div className='mb-3'>
                    {accountId === 3 ? (
                        <div
                            className='text-white help-block rounded p-4 mx-2 mb-3'
                            style={{ fontSize: 12 }}
                        >
                            {ImportScript(
                                'https://code.jivo.ru/widget/daRaseOhYQ'
                            )}
                            <div style={{ marginBottom: 2 }}>
                                <b>Остались вопросы?</b>
                            </div>
                            <div style={{ fontSize: 10, marginBottom: 2 }}>
                                Позвоните нам и мы подробно вас проконсультируем
                            </div>
                            <div style={{ fontSize: 12 }}>
                                <b>
                                    <a
                                        style={{ color: '#ffffff' }}
                                        href='tel:+74996783808'
                                    >
                                        +7 (499) 678-38-08
                                    </a>
                                </b>
                            </div>
                        </div>
                    ) : null}
                    {accountId ? (
                        <MenuItem
                            to={`/account/${accountId}/support`}
                            title='Техподдержка'
                            imageIcon='/media/icons/avanguard/life-buoy.png'
                        />
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {/* end::Footer */}
        </div>
    );
};

export { MenuDefault };
