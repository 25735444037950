import { useState, useEffect, useRef } from "react";
import { useGuestDeleteCardMutation } from "../../../../app/storeReducers/guestsApiSlice";

const DeleteCardModal = (props) => {
    const { isOpen, handleClose, accountId } = props;
    const inputElement = useRef(null);
    const [cardNumber, setCardNumber] = useState("");

    const [deleteCardAsync] = useGuestDeleteCardMutation();

    const close = () => {
        setCardNumber("");
        handleClose();
    };

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    }, [isOpen]);

    const deleteCard = async () => {
        const result = await deleteCardAsync({
            accountId,
            number: cardNumber,
        }).unwrap();
        if (result?.result) {
            setCardNumber("");
            handleClose();
        } else {
            alert(result.error);
        }
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Возврат пропуска</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={close}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="input-group mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Введите номер пропуска"
                                    value={cardNumber}
                                    onChange={(e) =>
                                        setCardNumber(e.target.value)
                                    }
                                    ref={inputElement}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={close}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={deleteCard}
                            >
                                Вернуть пропуск
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default DeleteCardModal;
