import React from 'react';

const SkeletonInput = (props) => {
    const {
        isLoading,
        label,
        inputId = 'textInput',
        type = 'text',
        value,
        onChange,
        readOnly = false,
    } = props;
    return (
        <div className='form-group'>
            <label htmlFor={inputId}>{label}</label>
            <input
                type={type}
                className={'form-control' + (isLoading ? ' skeleton' : '')}
                disabled={isLoading}
                id={inputId}
                value={value}
                onChange={(e) => onChange(e)}
                readOnly={readOnly}
            />
        </div>
    );
};

export default SkeletonInput;
