import { useEffect, useState } from 'react';
import {
    useAmnestyMutation,
    useLazyGetBlackListByIdQuery,
} from '../../../app/storeReducers/AccountBlacklistApi';
import { ToUtcString } from '../../../helpers/DateHelpers';

const AmnestyModal = (props) => {
    const { entity, isOpen, closeHandle } = props;

    const [amnesty] = useAmnestyMutation();
    const [getBLock, { data }] = useLazyGetBlackListByIdQuery();

    const [comments, setComments] = useState('');

    useEffect(() => {
        if (entity?.id > 0) {
            getBLock(entity?.id);
        }
    }, [entity]);

    const deleteEntity = () => {
        amnesty({ ...entity, comments, blockEndDate: ToUtcString(new Date()) });
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                {entity?.category === 'candidate'
                                    ? 'Удалить'
                                    : 'Разблокировать'}{' '}
                                транспорт
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Вы действительно хотите{' '}
                                {entity?.category === 'candidate'
                                    ? 'удалить '
                                    : 'разблокировать '}
                                транспорт с номером{' '}
                                <strong>
                                    {entity?.carNumber} {entity?.carRegion}
                                </strong>
                            </p>
                            <b>Комментарии</b>
                            <br />
                            {data?.result?.messages?.length > 0 ? (
                                <div className='block-messages-block'>
                                    {data?.result?.messages?.map(
                                        (item, index) => (
                                            <div className='mb-2' key={index}>
                                                {item}
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : (
                                <span className='text-muted'>
                                    Комментарии отсутствуют
                                </span>
                            )}
                            <input
                                type='text'
                                className='form-control mt-2'
                                placeholder='Новый комментарий'
                                value={comments}
                                onChange={(e) => setComments(e.target.value)}
                            />
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={deleteEntity}
                            >
                                {entity?.category === 'candidate'
                                    ? 'Удалить'
                                    : 'Разблокировать'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default AmnestyModal;
