import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../../http';
import ParkingAddDeviceModal from './ParkingAddDeviceModal';
import ParkingDeviceDeleteModal from './ParkingDeviceDeleteModal';
import SecutiryContext from '../../../../features/securityContext';

const ParkingDevicesPanel = (props) => {
    const { accountId, entityId } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [devices, setDevices] = useState([]);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [entityId, canEdit]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('devices_edit'));
        setCanList(context.isRoot || actions?.includes('devices_list'));
    }, [context, accountId]);

    const loadData = async () => {
        const getResult = await Http.get(
            `api/parking/areas/${entityId}/devices`,
            true
        );
        setDevices(getResult.result);
    };

    const okAddDevice = () => {
        loadData();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteDevice = () => {
        loadData();
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <ParkingAddDeviceModal
                accountId={accountId}
                parkingId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddDevice}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <ParkingDeviceDeleteModal
                parkingId={entityId}
                device={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteDevice}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-3'>
                        <div className='col-md-6 align-self-center'>
                            <h3>
                                Устройства{' '}
                                <span className='badge rounded-pill bg-light text-dark'>
                                    {devices.length}
                                </span>
                            </h3>
                        </div>
                        <div className='col-md-6 text-end'>
                            {canEdit ? (
                                <button
                                    className='btn av-btn-primary'
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <i className='fas fa-plus me-2' />
                                    Добавить устройство
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {canList ? (
                    <table className='table mb-0 border-top'>
                        <tbody>
                            {devices.map((item) => (
                                <tr key={item.id}>
                                    <td className='ps-6'>
                                        <div>
                                            <div className='fw-boldest'>
                                                {item.name}
                                            </div>
                                            <div className='av-text-gray-500'>
                                                {item.uid}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='text-end'>
                                        {canEdit ? (
                                            <>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1 px-2'
                                                    onClick={() =>
                                                        openDeleteModal(item)
                                                    }
                                                >
                                                    <i className='far fa-trash-alt text-muted' />
                                                </button>
                                            </>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className='card-body'>
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default ParkingDevicesPanel;
