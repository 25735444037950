import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../http';
import { useDispatch } from 'react-redux';
import { updateAsync } from '../../../app/storeReducers/AccountCamerasSlice';
import SkeletonInput from '../../../components/skeleton/SkeletonInput';
import FrameModal from './FrameModal';
import CameraDeleteModal from '../CameraDeleteModal';
import SecutiryContext from '../../../features/securityContext';

const CameraEditPanel = (props) => {
    const { accountId, entityId } = props;

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [protocol, setProtocol] = useState('rtsp');
    const [name, setName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [address, setAddress] = useState('');
    const [port, setPort] = useState(0);
    const [path, setPath] = useState('');
    const [isRtspProxy, setIsRtspProxy] = useState(false);
    const [modalCameraId, setModalCameraId] = useState('');

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);
    const [isHidePass, setIsHidePass] = useState(true);

    const [isOpenImageModal, setIsOpenImageModal] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getResult = await Http.get('api/cameras/' + entityId, true);
            setProtocol(getResult.result.protocol);
            setName(getResult.result.name);
            setUserName(getResult.result.username);
            setPassword(getResult.result.password);
            setAddress(getResult.result.address);
            setPort(getResult.result.port);
            setPath(getResult.result.path);
            setIsRtspProxy(getResult.result.isRtspProxy);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('camera_edit'));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            protocol,
            userName,
            password,
            address,
            port,
            path,
            account: { id: accountId },
            id: entityId,
            isRtspProxy,
        };
        dispatch(updateAsync(entity));
    };

    const showHidePassword = () => {
        setIsHidePass(!isHidePass);
    };

    const closeFrameModal = () => {
        setIsOpenImageModal(false);
        setModalCameraId(0);
    };

    return (
        <>
            <CameraDeleteModal
                entity={{ name, id: entityId, account: { id: accountId } }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <FrameModal
                cameraId={modalCameraId}
                isOpen={isOpenImageModal}
                closeHandle={closeFrameModal}
                isRtspProxy={isRtspProxy}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <h3>Камера</h3>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <label>Протокол</label>
                        <select
                            className='form-select'
                            value={protocol}
                            onChange={(e) => setProtocol(e.target.value)}
                        >
                            {[
                                {
                                    key: 'rtsp',
                                    value: 'RTSP',
                                },
                                {
                                    key: 'http',
                                    value: 'HTTP',
                                },
                            ].map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='name'
                            label='Название'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='userName'
                            label='Имя пользователя'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className='mb-3 position-relative'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='password'
                            label='Пароль'
                            value={password}
                            type={isHidePass ? 'password' : 'text'}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {canEdit ? (
                            <span
                                className={`fas ${
                                    isHidePass ? 'fa-eye' : 'fa-low-vision'
                                } password-eye cursor-pointer`}
                                onClick={showHidePassword}
                            />
                        ) : null}
                    </div>
                    <div className='mb-3'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <SkeletonInput
                                    isLoading={isLoading}
                                    id='address'
                                    label='Адрес'
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <SkeletonInput
                                    isLoading={isLoading}
                                    id='port'
                                    label='Порт'
                                    value={port}
                                    onChange={(e) => setPort(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='path'
                            label='Путь'
                            value={path}
                            onChange={(e) => setPath(e.target.value)}
                        />
                    </div>
                    {protocol === 'rtsp' ? (
                        <div className='mb-3'>
                            <div className='form-check form-check-inline form-group'>
                                <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='rtspProxy'
                                    checked={isRtspProxy}
                                    onChange={(e) =>
                                        setIsRtspProxy(e.target.checked)
                                    }
                                    disabled={!context.isRoot}
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor='rtspProxy'
                                >
                                    Использовать трансляцию
                                </label>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            <button
                                className='btn av-btn-danger fw-boldest'
                                onClick={() => setDeleteModalIsOpen(true)}
                                disabled={!canEdit}
                            >
                                <i className='far fa-trash-alt me-2' /> Удалить
                            </button>
                        </div>
                        <div className='col text-end'>
                            {canEdit ? (
                                <button
                                    className='btn btn-sm av-btn-primary me-2'
                                    onClick={() => {
                                        setModalCameraId(entityId);
                                        setIsOpenImageModal(true);
                                    }}
                                >
                                    {isRtspProxy ? (
                                        <span className='fas fa-video' />
                                    ) : (
                                        <span className='fas fa-camera' />
                                    )}
                                </button>
                            ) : null}
                            <button
                                className='btn av-btn-simple fw-boldest'
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CameraEditPanel;
