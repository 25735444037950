import { useEffect, useState } from 'react';
import { DateToString, UtcToLocal } from '../../helpers/DateHelpers';
import { Link } from 'react-router-dom';
import ThSortable from '../table/ThSortable';
import SkeletonTable from '../skeleton/SkeletonTable';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import { useLazyGetLogAccessQuery } from '../../app/storeReducers/logsApiSlice';

const AccessLogComponent = (props) => {
    const { guestId, employeeId, vehicleId, accountId } = props;

    const [logs, setLogs] = useState([]);

    const [getLogAccess, { isLoading }] = useLazyGetLogAccessQuery();

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    });

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const inEnum = [1, 3, 5, 7, 9, 11, 21, 23, 25, 27, 29, 31, 33, 35, 37];
            const outEnum = [2, 4, 6, 8, 10, 12, 22, 24, 26, 28, 30, 32, 34, 36, 38];
            let entityId = 0;
            let entityType = 0;
            if (employeeId > 0) {
                entityId = employeeId;
                entityType = 'employee';
            }
            if (vehicleId > 0) {
                entityId = vehicleId;
                entityType = 'vehicle';
            }
            if (guestId > 0) {
                entityId = guestId;
                entityType = 'guest';
            }
            if (guestId > 0 || employeeId > 0 || vehicleId > 0) {
                const res = await getLogAccess({ entityId, entityType }).unwrap();

                const getResult = res.result?.map((item, index) => ({
                    ...item,
                    cardTitle: item?.card?.title ?? '---',
                    entityTitle:
                        item.entity !== null ? <GetEntity type={item.entity.type} title={item.entity.title} /> : '---',
                    keyItem: index,
                    deviceTitle: (
                        <>
                            <span
                                data-bs-toggle='tooltip'
                                data-bs-placement='top'
                                data-bs-custom-class='av-tooltip'
                                title={item.device.uid}
                            >
                                {item.device.title}
                            </span>
                        </>
                    ),
                    deviceTitleString: item.device.title,
                    dtString: DateToString(UtcToLocal(item.time), true),

                    eventIcon:
                        inEnum.includes(item.event) || outEnum.includes(item.event) ? (
                            inEnum.includes(item.event) ? (
                                <i className='fas fa-sign-in-alt me-2' />
                            ) : (
                                <i className='fas fa-sign-out-alt me-2' />
                            )
                        ) : (
                            ''
                        ),
                }));
                console.log('accessLog', getResult);

                setLogs(getResult || []);
            }
        }

        loadResourcesAndDataAsync();
    }, [guestId, employeeId, vehicleId]);

    const [sort, setSort] = useState('dtString');
    const [sortDest, setSortDest] = useState('desc');

    function GetEntity(props) {
        const type = props.type;
        const title = props.title;
        if (type === 'admin') {
            return (
                <span data-toggle='tooltip' data-placement='top' title='Администратор сервиса'>
                    <i className='fas fa-toolbox'></i> {title}
                </span>
            );
        } else if (type === 'employee') {
            return (
                <span data-bs-toggle='tooltip' data-bs-placement='top' title='Сотрудник'>
                    <i className='fas fa-user'></i> {title}
                </span>
            );
        } else if (type === 'guest') {
            return (
                <span data-bs-toggle='tooltip' data-bs-placement='top' title='Посетитель'>
                    <i className='fas fa-user-secret'></i> {title}
                </span>
            );
        } else if (type === 'vehicle') {
            return (
                <span data-bs-toggle='tooltip' data-bs-placement='top' title='Автомобиль'>
                    <i className='fas fa-car'></i> {title}
                </span>
            );
        }
        return <span>{title}</span>;
    }

    const columnFilter = (colName) => {
        const dest = colName === sort ? (sortDest === 'asc' ? 'desc' : 'asc') : 'asc';
        setSort(colName);
        setSortDest(dest);

        let sortArray = logs || [];
        sortArray.sort(function (a, b) {
            if (a[colName] > b[colName]) {
                return 1;
            }
            if (a[colName] < b[colName]) {
                return -1;
            }
            return 0;
        });
        if (dest === 'desc') {
            sortArray.reverse();
        }
        setLogs(sortArray);
    };

    return (
        <>
            <div className='card border' id='accessLogPanel'>
                <div className='card-body p-6 pb-5'>
                    <div className='row'>
                        <div className='col'>
                            <div className='av-card-title mb-1'>Журнал доступа</div>
                            Последние двадцать доступов сотрудника. Больше записей в основном{' '}
                            <Link className='av-text-primary' to={`/account/${accountId}/accesslogs`}>
                                журнале доступа.
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Дата и время', 'Устройство', 'Событие']}
                        rowsCount={15}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0 border-top'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom av-bg-gray-50'>
                                    <ThSortable
                                        className='border-bottom ps-6'
                                        colName='dtString'
                                        colTitle='Дата и время'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='deviceTitleString'
                                        colTitle='Устройство'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className='border-bottom'
                                        colName='eventName'
                                        colTitle='Событие'
                                        colChanged={(name) => columnFilter(name)}
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                </tr>
                            </thead>
                            <tbody>
                                {logs?.map((item, keyItem) => (
                                    <tr key={keyItem}>
                                        <td className='ps-6 av-text-gray-500'>{item.dtString}</td>
                                        <td className='av-text-gray-500'>{item.deviceTitle}</td>
                                        <td className='av-text-gray-500'>
                                            {item.eventIcon} {item.eventName}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default AccessLogComponent;
