import CardListTableComponent from '../../../components/cards/CardsListTableComponent';
import EmployeeGroupsPanel from './group/EmployeeGroupsPanel';
import EmployeeRolesPanel from './role/EmployeeRolesPanel';
import EmployeeSchemesPanel from './scheme/EmployeeSchemesPanel';
import PermissionPage from '../../../components/PermissionPage';
import AccessLogComponent from '../../../components/accesslogs/AccessLogComponent';
import { useAccount } from '../../../components/useAccount';
import CardsListTableBlockedComponent from '../../../components/cards/CardsListTableBlockedComponent';
import EmployeeVehiclesPanel from './EmployeeVehiclesPanel';
import EmployeePanel from './EmployeePanel';
import { ACTIONS, STATUS } from 'react-joyride';
import { useHistory } from 'react-router';
import { useRef, useState } from 'react';
import Onboarding from '../../../components/onboarding/Onboarding';

const EmployeeCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const employeeId = props.match.params.employeeId;
    const history = useHistory();

    const account = useAccount();
    const cardRef = useRef();

    const [steps, setSteps] = useState([
        {
            target: '#employeePanel',
            disableBeacon: true,
            placement: 'bottom-end',
            isFixed: true,
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>Шаг 6 из 13</div>
                    <div className='mb-1 ob-title'>
                        <b>Карточка сотрудника</b>
                    </div>
                    <div className='mb-3 ob-text'>
                        В этом блоке вы видите основную информацию о сотруднике, а также можете выполнить основные
                        действия: заблокировать доступ, удалить его или изменить информацию.
                    </div>
                </div>
            ),
        },
        {
            target: '#cardPanel',
            disableBeacon: true,
            placement: 'bottom-start',
            isFixed: true,
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>Шаг 7 из 13</div>
                    <div className='mb-1 ob-title'>
                        <b>Карты доступа</b>
                    </div>
                    <div className='mb-3 ob-text'>Создавайте или редактируйте карты доступа сотрудника.</div>
                </div>
            ),
        },
    ]);

    const [steps2, setSteps2] = useState([
        {
            target: '#accessLogPanel',
            disableBeacon: true,
            placement: 'left',
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>Шаг 9 из 13</div>
                    <div className='mb-1 ob-title'>
                        <b>Журнал доступа</b>
                    </div>
                    <div className='mb-3 ob-text'>В журнале доступа хранятся последние 50 записей о сотруднике.</div>
                </div>
            ),
        },
        {
            target: '#groupsRolesChemesPanel',
            disableBeacon: true,
            placement: 'right',
            content: (
                <div className='text-start'>
                    <div className='text-muted mb-2 step-panel'>Шаг 10 из 13</div>
                    <div className='mb-1 ob-title'>
                        <b>Группы, роли и схемы</b>
                    </div>
                    <div className='mb-3 ob-text'>
                        В этом блоке вы можете присвоить сотруднику группы, выбрать его роли и назначить ему схемы. Эти
                        параметры определяют категории доступа его пропусков и уровень доступа в личном кабинете
                        Avanguard.
                    </div>
                </div>
            ),
        },
    ]);
    const [run, setRun] = useState(false);

    const onboardingCallback = (action, status, index) => {
        if (action === ACTIONS.CLOSE && status === STATUS.FINISHED && index === 1) {
            cardRef.current.OpenCreateModal();
        }
    };

    const onboardingCallback2 = (action, status, index) => {
        if (action === ACTIONS.CLOSE && status === STATUS.FINISHED && index === 1) {
            history.push(`/account/${accountId}/vehicles`);
        }
    };

    return (
        <PermissionPage accountId={accountId} roles={['employees_edit', 'employees_list']}>
            <Onboarding steps={steps} run={true} callback={onboardingCallback} />
            <Onboarding steps={steps2} run={run} callback={onboardingCallback2} />
            <h1 className='page-header'>Карточка сотрудника</h1>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row mb-5'>
                        <div className='col'>
                            <EmployeePanel employeeId={employeeId} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='card border mb-5' id='groupsRolesChemesPanel'>
                                <div className='card-body p-6'>
                                    <EmployeeGroupsPanel accountId={accountId} employeeId={employeeId} />
                                    <EmployeeRolesPanel accountId={accountId} employeeId={employeeId} />
                                    <EmployeeSchemesPanel accountId={accountId} employeeId={employeeId} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <EmployeeVehiclesPanel accountId={accountId} employeeId={employeeId} />
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row mb-5'>
                        <div className='col'>
                            {account?.type !== 'business' ? (
                                <CardListTableComponent
                                    accountId={accountId}
                                    userId=''
                                    employeeId={employeeId}
                                    vehicleId=''
                                    ref={cardRef}
                                    closeCreateModalCallback={() => setRun(true)}
                                />
                            ) : (
                                <CardsListTableBlockedComponent
                                    message={'Добавление карт возможно только сотрудникам субаккаунтов.'}
                                />
                            )}
                        </div>
                    </div>
                    <AccessLogComponent accountId={accountId} employeeId={employeeId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default EmployeeCardPage;
