export function GetToday() {
    let t = new Date();
    return new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0);
}

export function AddDays(date, incrementDay) {
    return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + incrementDay
    );
}

export function ToUtcString(date) {
    var mm = date.getMonth() + 1;
    var dd = date.getDate();
    var hh = date.getHours();
    var min = date.getMinutes();
    var sec = date.getSeconds();
    return `${date.getFullYear()}-${(mm > 9 ? '' : '0') + mm}-${
        (dd > 9 ? '' : '0') + dd
    }T${(hh > 9 ? '' : '0') + hh}:${(min > 9 ? '' : '0') + min}:${
        (sec > 9 ? '' : '0') + sec
    }Z`;
}

export function UtcToLocal(date) {
    if (date.endsWith('Z')) {
        date = date.substr(0, date.length - 1);
    }
    return new Date(ToUtcString(new Date(date)));
}

export function DateToString(date, withTime = false) {
    const mm = date.getMonth() + 1;
    const dd = date.getDate();
    let dt = `${(dd > 9 ? '' : '0') + dd}.${
        (mm > 9 ? '' : '0') + mm
    }.${date.getFullYear()}`;
    if (withTime) {
        dt += ` ${(date.getHours() > 9 ? '' : '0') + date.getHours()}:${
            (date.getMinutes() > 9 ? '' : '0') + date.getMinutes()
        }`;
    }
    return dt;
}

export function IsSameDate(date1, date2) {
    if (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
    ) {
        return true;
    }
    return false;
}

export function RuToJsDate(ruDate) {
    var st = ruDate;
    if (st.endsWith('Z')) {
        st = st.substr(0, st.length - 1);
    }
    var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    return new Date(st.replace(pattern, '$3-$2-$1'));
}

export function GetMonthName(date1) {
    const monthNames = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];

    return monthNames[date1.getMonth()];
}

export const FormatDateToHuman = (innerDate) => {
    let jsDate = new Date(innerDate);
    var nowDate = new Date();
    var yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    var dd = jsDate.getDate().toString();
    var mm = (jsDate.getMonth() + 1).toString();
    var hh = jsDate.getHours().toString();
    var minutes = jsDate.getMinutes().toString();
    var seconds = jsDate.getSeconds().toString();
    var yyyy = jsDate.getFullYear();
    if (jsDate.getDate() < 10) {
        dd = '0' + dd;
    }
    if (jsDate.getMonth() + 1 < 10) {
        mm = '0' + mm;
    }
    if (jsDate.getHours() < 10) {
        hh = '0' + hh;
    }
    if (jsDate.getMinutes() < 10) {
        minutes = '0' + minutes;
    }
    if (jsDate.getSeconds() < 10) {
        seconds = '0' + seconds;
    }

    var dateFormat = dd + '.' + mm + '.' + yyyy;
    var timeFormat = hh + ':' + minutes + ':' + seconds;
    if (
        nowDate.getFullYear() === jsDate.getFullYear() &&
        nowDate.getMonth() === jsDate.getMonth() &&
        nowDate.getDate() === jsDate.getDate()
    ) {
        dateFormat = 'сегодня в';
    } else if (
        yesterdayDate.getFullYear() === jsDate.getFullYear() &&
        yesterdayDate.getMonth() === jsDate.getMonth() &&
        yesterdayDate.getDate() === jsDate.getDate()
    ) {
        dateFormat = 'вчера в';
    } else if (nowDate.getFullYear() === jsDate.getFullYear()) {
        dateFormat = dd + '.' + mm + ' в';
    }
    return dateFormat + ' ' + timeFormat;
};

export function GetTime(date) {
    return `${(date.getHours() > 9 ? '' : '0') + date.getHours()}:${
        (date.getMinutes() > 9 ? '' : '0') + date.getMinutes()
    }`;
}

export function GetDayName(dayOfWeek) {
    if (dayOfWeek === 0) {
        return 'Воскресенье';
    } else if (dayOfWeek === 1) {
        return 'Понедельник';
    } else if (dayOfWeek === 2) {
        return 'Вторник';
    } else if (dayOfWeek === 3) {
        return 'Среда';
    } else if (dayOfWeek === 4) {
        return 'Четверг';
    } else if (dayOfWeek === 5) {
        return 'Пятница';
    } else if (dayOfWeek === 6) {
        return 'Суббота';
    }
}

export function MinutesToHours(minutes) {
    let h = (minutes / 60) ^ 0;
    let m = minutes;
    if (h) {
        m = minutes % 60;
    } else {
        h = 0;
    }
    return (h < 10 ? '0' : '') + h + ':' + (m < 10 ? '0' : '') + m;
}
