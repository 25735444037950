import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const guestsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getGuests: builder.query({
            query: (data) =>
                `guests?accountId=${data.accountId ?? ""}&dateStart=${
                    data.dateStart ?? ""
                }&dateFinish=${data.dateFinish ?? ""}&search=${
                    data.search ?? ""
                }&mine=${data.mine ?? false}&onlyCars=${
                    data.onlyCars ?? false
                }&showArchived=${data.showArchived ?? false}&sort=${
                    data.sort ?? ""
                }&pageIndex=${data.pageIndex ?? 1}&pageSize=${
                    data.pageSize ?? 100
                }`,
            transformResponse: (response) => {
                // Проверяем, есть ли ошибка в ответе
                if (response.error) {
                    throw new Error(response.errorDescription);
                }
                // Если ошибки нет, возвращаем данные
                return response;
            },
            providesTags: (result) =>
                result
                    ? [
                          ...result?.result?.map(({ id }) => ({
                              type: "Guests",
                              id,
                          })),
                          { type: "Guests", id: "List" },
                      ]
                    : [{ type: "Guests", id: "List" }],
        }),
        getGuestById: builder.query({
            query: (id) => `/guests/${id}`,
            providesTags: (result) => [
                { type: "Guest", id: result?.result.id },
            ],
        }),
        createGuest: builder.mutation({
            query: (body) => ({
                url: "guests",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка создания!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось создать посетителя! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Посетитель создан!",
                                description:
                                    "Данные посетителя успешно созданы!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка создания!",
                            description:
                                "Не удалось создать посетителя! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        updateGuest: builder.mutation({
            query: (body) => ({
                url: `guests/${body.id}`,
                method: "PUT",
                body: { ...body },
            }),
            invalidatesTags: (result) => [
                { type: "Guest", id: result?.result.id },
                { type: "Guests", id: "List" },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить посетителя! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Посетитель сохранен!",
                                description:
                                    "Данные посетителя успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить посетителя! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteGuest: builder.mutation({
            query: (id) => ({
                url: `guests/${id}`,
                method: "DELETE",
                body: undefined,
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка удаления!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось удалить посетителя! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Посетитель удалён!",
                                description:
                                    "Данные посетителя успешно удалены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка удаления!",
                            description:
                                "Не удалось удалить посетителя! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        archiveGuest: builder.mutation({
            query: (id) => ({
                url: `guests/${id}/archive`,
                method: "PUT",
            }),
            invalidatesTags: (result) => [
                { type: "Guest", id: result?.result.id },
                { type: "Guests", id: "List" },
            ],
        }),
        unarchiveGuest: builder.mutation({
            query: (id) => ({
                url: `guests/${id}/unarchive`,
                method: "PUT",
                body: undefined,
            }),
            invalidatesTags: (result) => [
                { type: "Guest", id: result?.result.id },
                { type: "Guests", id: "List" },
            ],
        }),
        guestChangeCard: builder.mutation({
            query: (data) => ({
                url: `guests/${data.guestId}/card?number=${data.number}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
        }),
        guestDeleteCard: builder.mutation({
            query: (data) => ({
                url: `guests/card?accountId=${data.accountId}&number=${data.number}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
        }),
        guestReturnCard: builder.mutation({
            query: (guestId) => ({
                url: `guests/${guestId}/card`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
        }),
        getGuestAutoButtons: builder.query({
            query: (accountId) => `guests/auto/buttons?accountId=${accountId}`,
        }),
        getGuestAuthor: builder.query({
            query: (guestId) => `guests/${guestId}/author`,
        }),
        setGuestAutoIn: builder.mutation({
            query: (data) => ({
                url: `guests/${data.guestId}/auto/in?deviceId=${data.deviceId}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
        }),
        setGuestAutoOut: builder.mutation({
            query: (data) => ({
                url: `guests/${data.guestId}/auto/out?deviceId=${data.deviceId}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
        }),
        getGuestFormData: builder.query({
            query: (accountId) =>
                `guests/auto/form-data?accountId=${accountId}`,
        }),
        guestAutoRegister: builder.mutation({
            query: (body) => ({
                url: "guests/auto/register",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
        }),
        getGuestAutoControl: builder.query({
            query: (data) =>
                `guests/auto/control?accountId=${
                    data.accountId ?? ""
                }&pageIndex=${data.pageIndex ?? 1}&pageSize=${
                    data.pageSize ?? 100
                }&sort=${data.vehSort ?? ""}&subAccountId=${
                    data.subAccountId ?? ""
                }&export=false`,
        }),
        exportGuestAutoControl: builder.query({
            query: (data) => ({
                url: `guests/auto/control?accountId=${
                    data.accountId ?? ""
                }&pageIndex=${data.pageIndex ?? 1}&pageSize=${
                    data.pageSize ?? 100
                }&sort=${data.vehSort ?? ""}&subAccountId=${
                    data.subAccountId ?? ""
                }&export=true`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
        setGuestAutoReset: builder.mutation({
            query: (data) => ({
                url: `guests/${data.guestId}/auto/reset?dateOut=${data.dateOut}`,
                method: "PUT",
            }),
            invalidatesTags: [{ type: "Guests", id: "List" }],
        }),
    }),
});

export const {
    useGetGuestsQuery,
    useLazyGetGuestsQuery,
    useGetGuestByIdQuery,
    useLazyGetGuestByIdQuery,
    useCreateGuestMutation,
    useUpdateGuestMutation,
    useDeleteGuestMutation,
    useArchiveGuestMutation,
    useUnarchiveGuestMutation,
    useGuestChangeCardMutation,
    useGuestDeleteCardMutation,
    useGuestReturnCardMutation,
    useGetGuestAutoButtonsQuery,
    useLazyGetGuestAutoButtonsQuery,
    useGetGuestAuthorQuery,
    useLazyGetGuestAuthorQuery,
    useSetGuestAutoInMutation,
    useSetGuestAutoOutMutation,
    useGetGuestFormDataQuery,
    useLazyGetGuestFormDataQuery,
    useGuestAutoRegisterMutation,
    useGetGuestAutoControlQuery,
    useLazyGetGuestAutoControlQuery,
    useExportGuestAutoControlQuery,
    useLazyExportGuestAutoControlQuery,
    useSetGuestAutoResetMutation,
} = guestsApiSlice;
