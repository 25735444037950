import { Br, Cut, Line, Printer, Text, Row, render, QRCode } from 'react-thermal-printer';
import { PrintExample } from './PrintExample';

const ThermalPrinter = (props) => {

    const print = async () => {
        try{
            const data = await render(
                <Printer type="epson">
                  <Text align='center'>Avanguard</Text>
                  <QRCode align='center' content='9263823369' />
                  <Cut />
                </Printer>
              );
            const port1 = await window.navigator.usb.getDevices();
            console.log(port1);

            const port = await window.navigator.serial.requestPort();
            await port.open({ baudRate: 9600 });
            
            const writer = port.writable?.getWriter();
            if (writer != null) {
              await writer.write(data);
              writer.releaseLock();
            }
    
        } catch (error){
            console.log(error);
        }
    }

    return (
        <>
            
                            <button
                                type='button'
                                className='btn av-btn-simple fw-boldest'
                                onClick={print}
                            >
                                Печать
                            </button>
                            <br />

                            <PrintExample text={'9263823369'} />
        </>
    );
};

export default ThermalPrinter;
