const CardsListTableBlockedComponent = (props) => {
    const { message } = props;

    return (
        <>
            <div className='card border'>
                <div className='card-body p-6'>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='av-card-title'>Карты</div>
                        </div>
                    </div>
                    <div>
                        <i className='fas fa-bell text-primary'></i> {message}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardsListTableBlockedComponent;
