const SupportWidget = (props) => {
    const { supportCountTotal, supportCountNew, supportCountOpen } = props;

    return (
        <div className='card border widget-card mb-6'>
            <div className='card-body p-6'>
                <div className='widget-title mb-3'>
                    <div className='widget-icon me-4'>
                        <img src='/media/icons/avanguard/support-white.png' />
                    </div>
                    Техподдержка
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {supportCountTotal}
                    </div>
                    <div className='widget-count-title'>Всего</div>
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>{supportCountNew}</div>
                    <div className='widget-count-title'>Новых</div>
                </div>
                <div className='widget-count-block d-inline-block me-5 text-danger'>
                    <div className='widget-count-number'>
                        {supportCountOpen}
                    </div>
                    <div className='widget-count-title'>Открытых</div>
                </div>
            </div>
        </div>
    );
};

export default SupportWidget;
