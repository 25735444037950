import { Http } from '../../../../http';

const ReturnPassModal = (props) => {
    const { isOpen, handleClose, handleOk, guest } = props;

    const deleteCard = async () => {
        const result = await Http.delete(`api/guests/${guest.id}/card`);
        handleOk(guest.id);
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Возврат пропуска</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={handleClose}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            Вы действительно хотите вернуть пропуск №{' '}
                            <b>{guest.cardNumber}</b> посетителя <b>{guest.name}</b>?
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={handleClose}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={deleteCard}
                            >
                                Вернуть пропуск
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default ReturnPassModal;
