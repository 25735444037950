import { useContext, useEffect, useState } from 'react';
import SkeletonInput from '../../components/skeleton/SkeletonInput';
import { Http } from '../../http';
import { addNotification } from '../../app/storeReducers/NotifySlice';
import { useDispatch } from 'react-redux';
import SecutiryContext from '../../features/securityContext';

const VehicleEditCard = (props) => {
    const { accountId, vehicleId, loadingComplete } = props;
    const dispatch = useDispatch();

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getVehicle = await Http.get(
                'api/vehicles/' + vehicleId,
                true
            );
            loadingComplete(getVehicle.result);
            console.log('vehicle', getVehicle);
            let accounts = [];
            const getCurrentAccouunt = await Http.get(
                'api/accounts/' + accountId
            );
            accounts.push(getCurrentAccouunt.result);
            const getSubAccounts = await Http.get(
                `api/accounts/${accountId}/subaccounts?sort=name`,
                true
            );
            getSubAccounts.result?.map((item) => accounts.push(item));
            const currentEmps = await Http.get(
                'api/employees?sort=last_name&accountId=' +
                    (getVehicle.result.service !== null &&
                    getVehicle.result.service.consumer !== null
                        ? getVehicle.result.service.consumer.id
                        : accounts[0].id)
            );
            const getParkingAreas = await Http.get(
                'api/parking/areas?sort=name&accountId=' + accountId
            );

            setCarNumber(getVehicle.result.carNumber);
            setCarRegion(getVehicle.result.carRegion);
            setModel(getVehicle.result.model);
            setAccountsList(accounts);
            setSelectedAccount(
                getVehicle.result.service !== null &&
                    getVehicle.result.service.consumer !== null
                    ? getVehicle.result.service.consumer.id
                    : 0
            );
            setEmployeesList(currentEmps.result);
            if (getVehicle.result.employee != null) {
                setSelectedEmployee(getVehicle.result.employee.id);
            }
            setParkingAreasList(getParkingAreas.result);
            if (getParkingAreas.result.length > 0) {
                const getParkingPlaces = await Http.get(
                    'api/parking/places?areaId=' + getParkingAreas.result[0].id
                );
                setParkingPlacesList(getParkingPlaces.result);
                setParkingAreaSelected(getVehicle.result.parkingArea.id);
                if (
                    getParkingPlaces.result?.length > 0 &&
                    getVehicle.result.parkingPlace !== null
                ) {
                    setParkingPlaceSelected(getVehicle.result.parkingPlace.id);
                } else {
                    setParkingPlaceSelected(0);
                }
            } else {
                setParkingAreaSelected(0);
                setParkingPlaceSelected(0);
            }
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [accountId, vehicleId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('vehicles_edit'));
    }, [context, accountId]);

    const [carNumber, setCarNumber] = useState('');
    const [carRegion, setCarRegion] = useState('');
    const [model, setModel] = useState('');
    const [accountsList, setAccountsList] = useState([]);
    const [employeesList, setEmployeesList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(0);
    const [parkingAreasList, setParkingAreasList] = useState([]);
    const [parkingAreaSelected, setParkingAreaSelected] = useState(0);
    const [parkingPlacesList, setParkingPlacesList] = useState([]);
    const [parkingPlaceSelected, setParkingPlaceSelected] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedAccount, setSelectedAccount] = useState(0);

    const changeAccount = async (accId) => {
        setSelectedAccount(accId);
        const accEmps = await Http.get('api/employees?accountId=' + accId);
        setEmployeesList(accEmps.result);
        setSelectedEmployee(0);
    };

    const changeParkingArea = async (areaId) => {
        setParkingAreaSelected(areaId);
        const pPlaces = await Http.get('api/parking/places?areaId=' + areaId);
        setParkingPlacesList(pPlaces.result);
        setParkingPlaceSelected(0);
    };

    const saveVehicle = async () => {
        const entity = {
            carNumber,
            carRegion,
            model,
            account: { id: accountId },
            parkingArea: { id: parkingAreaSelected },
            parkingPlace:
                parseInt(parkingPlaceSelected) !== 0
                    ? { id: parseInt(parkingPlaceSelected) }
                    : null,
            id: vehicleId,
            employee:
                parseInt(selectedEmployee) !== 0
                    ? {
                          id: selectedEmployee,
                      }
                    : null,
        };
        console.log('updEntity', entity);
        const updateResult = await Http.put(
            'api/vehicles/' + vehicleId,
            true,
            entity
        );
        if (updateResult?.result?.id > 0) {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Транспорт сохранён!',
                    description: 'Данные транспорта успешно сохранены!',
                    type: 'success',
                })
            );
        } else {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description:
                        'Не удалось сохранить данные транспорта! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }
    };

    return (
        <div className='card'>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                        Транспорт
                    </span>
                </h3>
            </div>
            <div className='card-body py-3'>
                <div className='mb-3'>
                    <label className='form-label'>Аккаунт</label>
                    <select
                        disabled={true}
                        className={
                            'form-select' + (isLoading ? ' skeleton' : '')
                        }
                        value={selectedAccount}
                        onChange={(e) => changeAccount(e.target.value)}
                    >
                        {accountsList.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='mb-3'>
                    <label className='form-label'>Сотрудники</label>
                    <select
                        className={
                            'form-select' + (isLoading ? ' skeleton' : '')
                        }
                        disabled={isLoading}
                        value={selectedEmployee}
                        onChange={(e) => setSelectedEmployee(e.target.value)}
                    >
                        <option value={0}>--Без привязки к сотруднику--</option>
                        {employeesList.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='row'>
                    <div className='col-md-8'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='carModel'
                            label='Номер'
                            value={carNumber}
                            onChange={(e) => setCarNumber(e.target.value)}
                        />
                    </div>
                    <div className='col-md-4'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='carRegion'
                            label='Регион'
                            value={carRegion}
                            type='number'
                            onChange={(e) => setCarRegion(e.target.value)}
                        />
                    </div>
                </div>
                <div className='mb-3'>
                    <SkeletonInput
                        isLoading={isLoading}
                        id='carModel'
                        label='Модель'
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                    />
                </div>
                <div className='mb-3'>
                    <label className='form-label'>Парковка</label>
                    <select
                        className={
                            'form-select' + (isLoading ? ' skeleton' : '')
                        }
                        disabled={isLoading}
                        value={parkingAreaSelected}
                        onChange={(e) => changeParkingArea(e.target.value)}
                    >
                        {parkingAreasList.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='mb-3'>
                    <label className='form-label'>Парковочное место</label>
                    <select
                        className={
                            'form-select' + (isLoading ? ' skeleton' : '')
                        }
                        disabled={isLoading}
                        value={parkingPlaceSelected}
                        onChange={(e) =>
                            setParkingPlaceSelected(e.target.value)
                        }
                    >
                        <option value={0}>--Без парковочного места--</option>
                        {parkingPlacesList.map((item) => (
                            <option key={item.id} value={item.id}>
                                {item.number}
                            </option>
                        ))}
                    </select>
                </div>
                <div className='d-grid mb-4'>
                    <button
                        type='button'
                        className='btn btn-success'
                        onClick={saveVehicle}
                        disabled={isLoading || !canEdit}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VehicleEditCard;
