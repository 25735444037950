import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    breadcrumbs: { leftLinks: [], rightLinks: []},
};

export const getBreadcrumbsAsync = createAsyncThunk(
    'account/getBreadCrumbs',
    async (accountId) => {        
        const url = `api/auth/navigation?accountId=${accountId}`;
        const response = await Http.get(url);
        return response.result;
    }
);

export const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState,
    reducers: {},
    extraReducers: {
        [getBreadcrumbsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getBreadcrumbsAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.breadcrumbs = action.payload;
            }
            state.isLoading = false;
        },
        [getBreadcrumbsAsync.rejected]: (state, action) => {
            console.error('getBreadcrumbsError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const selectBreadcrumbs = (state) => state.breadcrumbs.breadcrumbs;
export const selectIsLoading = (state) => state.breadcrumbs.isLoading;

export default breadcrumbsSlice.reducer;
