import EventNotifyComponent from '../../components/eventNotify/EventNotifyComponent';

const AccountNotificationSettingsPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <div className='row'>
            <div className='col-md-12'>
                <h1 className='mb-5'>Настройки уведомлений</h1>
                <div className='card border'>
                    <div className='card-body pb-2'>
                        <div className='row'>
                            <div className='col mb-2'>
                                <h3>Уведомления аккаунта</h3>
                            </div>
                        </div>
                    </div>
                    <EventNotifyComponent accountId={accountId} />
                </div>
            </div>
        </div>
    );
};

export default AccountNotificationSettingsPage;
