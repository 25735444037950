import React, { useState } from 'react';

const SkeletonTableFull = (props) => {
    const {
        cols,
        rowsCount,
        isLoading,
        classTable,
        entity,
        tdClasses = [],
        sortCol,
        sortDirection,
    } = props;

    const tr = () => {
        let tr = [];
        for (let index = 0; index < rowsCount; index++) {
            tr.push(
                <tr key={index} className='skeleton skeleton-tr'>
                    {Object.entries(cols).map((item, index) => {
                        if (item[0] !== 'rKeyItem') {
                            return <td key={index}></td>;
                        }
                    })}
                </tr>
            );
        }
        return tr;
    };

    return (
        <>
            {isLoading ? (
                <table className={'table ' + classTable}>
                    <thead>
                        <tr className='fw-bold av-text-gray-500 border-bottom'>
                            {Object.entries(cols).map((item, index) => {
                                if (item[0] !== 'rKeyItem') {
                                    return <td key={index}>{item[1]}</td>;
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>{tr()}</tbody>
                </table>
            ) : (
                <table className={'table ' + classTable}>
                    <thead>
                        <tr className='fw-bold av-text-gray-500 border-bottom'>
                            {Object.entries(cols).map((item, index) => {
                                if (item[0] !== 'rKeyItem') {
                                    let colValue = item[1];
                                    if (item[0] === sortCol) {
                                        colValue += (
                                            <span className='fas fa-chevron-up' />
                                        );
                                    }
                                    return (
                                        <td
                                            key={index}
                                            className={
                                                'border-bottom ' +
                                                tdClasses[
                                                    Object.keys(tdClasses).find(
                                                        (x) => x === item[0]
                                                    )
                                                ]
                                            }
                                        >
                                            {colValue}
                                        </td>
                                    );
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {entity?.map((item, index) => {
                            let tdArr = [];
                            for (const key in cols) {
                                if (key !== 'rKeyItem') {
                                    const element = item[key];
                                    tdArr.push(
                                        <td
                                            key={key}
                                            className={
                                                'align-middle ' +
                                                tdClasses[
                                                    Object.keys(tdClasses).find(
                                                        (x) => x === key
                                                    )
                                                ]
                                            }
                                        >
                                            {element}
                                        </td>
                                    );
                                }
                            }
                            return <tr key={index}>{tdArr}</tr>;
                        })}
                    </tbody>
                </table>
            )}
        </>
    );
};

export default SkeletonTableFull;
