import { useEffect, useState } from 'react';
import {
    useGetBlackListByIdQuery,
    useLazyGetBlackListByIdQuery,
    useLazyGetBlackListDetailsQuery,
    useUpdateBlockMutation,
} from '../../../app/storeReducers/AccountBlacklistApi';
import BanNewModal from './BanNewModal';
import AmnestyModal from './AmnestyModal';
import {
    DateToString,
    MinutesToHours,
    ToUtcString,
    UtcToLocal,
} from '../../../helpers/DateHelpers';

const BanDetailsModal = (props) => {
    const { accountId, entity, isOpen, closeHandle } = props;

    const [getDetails, { data }] = useLazyGetBlackListDetailsQuery();
    const [updateBlock] = useUpdateBlockMutation();
    const [getBLock, { data: block }] = useLazyGetBlackListByIdQuery();

    const [isOpenBanModal, setIsOpenBanModal] = useState(false);
    const [unblockModalIsOpen, setUnblockModalIsOpen] = useState(false);

    const [blockEndDate, setBlockEndDate] = useState(new Date());

    useEffect(() => {
        if (entity?.id > 0) {
            getBLock(entity?.id);
            getDetails(entity?.id);
            setBlockEndDate(new Date(entity?.blockEndDate));
        }
    }, [entity]);

    const unblock = () => {
        setUnblockModalIsOpen(true);
        closeHandle();
    };

    const blockCandidate = () => {
        setIsOpenBanModal(true);
        closeHandle();
    };

    const save = () => {
        updateBlock({ ...entity, blockEndDate: ToUtcString(blockEndDate) });
        closeHandle();
    };

    const ManageButtons = (category) => {
        if(category === 'candidate'){
            return (
                <>
                    <button
                        type='button'
                        className='btn av-btn-danger me-2'
                        onClick={blockCandidate}
                    >
                        Заблокировать
                    </button>
                    <button
                        type='button'
                        className='btn av-btn-simple'
                        onClick={unblock}
                    >
                        Удалить
                    </button>
                </>
            );
        }
        else if(category === 'block'){
            return (
                <>
                    <button type='button' className='btn av-btn-simple' onClick={unblock} >Разблокировать</button>
                </>
            );
        }
        else {
            return '';
        }
    }

    return (
        <>
            <BanNewModal
                accountId={accountId}
                isOpen={isOpenBanModal}
                closeHandle={() => setIsOpenBanModal(false)}
                entity={entity}
            />
            <AmnestyModal
                isOpen={unblockModalIsOpen}
                closeHandle={() => setUnblockModalIsOpen(false)}
                entity={entity}
            />
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Информация по автомобилю
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            <h2>
                                                {entity?.carNumber +
                                                    ' ' +
                                                    entity?.carRegion}
                                            </h2>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col-md-6'>
                                            <div className='fild-label'>
                                                Дата разблокировки
                                            </div>
                                            <div className='fild-text text-danger date-block'>
                                                {entity?.blockEndDate ? (
                                                    <input
                                                        type='date'
                                                        className='form-control text-danger'
                                                        value={
                                                            ToUtcString(
                                                                blockEndDate
                                                            ).split('T')[0]
                                                        }
                                                        onChange={(e) =>
                                                            setBlockEndDate(
                                                                new Date(
                                                                    e.target.value
                                                                )
                                                            )
                                                        }
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            {ManageButtons(entity?.category)}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            <div className='fild-label'>
                                                Кол-во нарушений всего
                                            </div>
                                            <div className='fild-text'>
                                                {entity?.violationCount}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-3'>
                                        <div className='col'>
                                            <div className='fild-label'>
                                                Кол-во внесений в ЧС
                                            </div>
                                            <div className='fild-text'>
                                                {entity?.blockCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='fild-label'>История</div>
                                    <div className='block-messages-block'>
                                        {block?.result?.messages?.map(
                                            (item, index) => (
                                                <div
                                                    className='mb-2'
                                                    key={index}
                                                >
                                                    {item}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table className='table'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Аккаунт
                                    </th>
                                    <th className='border-bottom'>
                                        ФИО сотрудника
                                    </th>
                                    <th className='border-bottom'>
                                        Телефон сотрудника
                                    </th>
                                    <th className='border-bottom'>
                                        ФИО посетителя
                                    </th>
                                    <th className='border-bottom'>
                                        Дата въезда
                                    </th>
                                    <th className='border-bottom'>
                                        Дата выезда
                                    </th>
                                    <th className='border-bottom text-end'>
                                        Длительность
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.map((item) => (
                                    <tr key={item.id}>
                                        <td className='ps-6'>
                                            {item.account.title}
                                        </td>
                                        <td>{item.authorName}</td>
                                        <td>{item.authorPhone}</td>
                                        <td>{item.name}</td>
                                        <td>
                                            {DateToString(
                                                UtcToLocal(item.dateIn),
                                                true
                                            )}
                                        </td>
                                        <td>
                                            {DateToString(
                                                UtcToLocal(item.dateOut),
                                                true
                                            )}
                                        </td>
                                        <td className='text-end'>
                                            {MinutesToHours(item.duration)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className='modal-footer py-2'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={save}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default BanDetailsModal;
