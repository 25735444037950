import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { HubConnectionBuilder } from "@microsoft/signalr";
import MonitorWidget from "./MonitorWidget";
import { DateToString, UtcToLocal } from "../../../helpers/DateHelpers";
import SkeletonTableFull from "../../../components/skeleton/SkeletonTableFull";
import { addNotification } from "../../../app/storeReducers/NotifySlice";
import {
    useGetMonitorByIdQuery,
    useLazyGetMonitorDevicesQuery,
} from "../../../app/storeReducers/AccountMonitorAPI";

const MonitorPanel = (props) => {
    const { accountId, monitorId } = props;
    const dispatch = useDispatch();

    const [monitorItems, setMonitorItems] = useState([]);

    const [events, setEvents] = useState([]);

    const [connection, setConnection] = useState(null);

    const { data: monitor, isLoading } = useGetMonitorByIdQuery(monitorId);

    const [getMonitorDevices] = useLazyGetMonitorDevicesQuery();

    const latestEvents = useRef(null);
    latestEvents.current = events;
    const latestMonitorItems = useRef(null);
    latestMonitorItems.current = monitorItems;

    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_PLATFORM_URL}ws/monitor`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);
    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    console.log("Connected!");
                    connection
                        .invoke("JoinGroup", `access_logs_${accountId}`) //JoinGroup is C# method name
                        .catch((err) => {
                            console.log(err);
                        });

                    connection.on("ReceiveMessage", (message) => {
                        message.id = message.time;
                        if (message.card !== null) {
                            message.cardTitle = message.card.title;
                        } else {
                            message.cardTitle = "---";
                        }
                        if (message.entity !== null) {
                            message.entityTitle = (
                                <GetEntity
                                    type={message.entity.type}
                                    title={message.entity.title}
                                />
                            );
                        } else {
                            message.entityTitle = "---";
                        }
                        message.accountTitle = message.account.title;
                        message.deviceTitle = (
                            <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title={message.device.uid}
                            >
                                {message.device.title}
                            </span>
                        );
                        message.dtString = DateToString(
                            UtcToLocal(message.time),
                            true
                        );
                        console.log(message);
                        const actualEvents = [...latestEvents.current];
                        actualEvents.unshift(message);
                        setEvents(actualEvents);

                        const actualItems = [...latestMonitorItems.current];
                        actualItems.map((item) => {
                            if (item.device.device.uid === message.device.uid) {
                                item.lastEvent = message;
                            }
                        });
                        setMonitorItems(actualItems);
                    });
                    connection.onreconnecting(function () {
                        console.log("Connection has been onreconnecting!");
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка подключения!",
                                description:
                                    "Произошла ошибка подключения к серверу. Дождитесь перезагрузки страницы или обновите страницу самостоятельно.",
                                type: "danger",
                            })
                        );
                    });
                    connection.onreconnected(function () {
                        window.location.reload();
                    });
                })
                .catch((e) => console.log("Connection failed: ", e));
        }
    }, [connection]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (monitorId) {
                const monitorItemsResponse = await getMonitorDevices(
                    monitorId
                ).unwrap();
                if (monitorItemsResponse.result !== undefined) {
                    let items = [];
                    monitorItemsResponse.result.map((item) => {
                        items.push({ device: item, lastEvent: null });
                    });
                    setMonitorItems(items);
                }
            }
        }

        loadResourcesAndDataAsync();
    }, [monitorId]);

    function GetEntity(props) {
        const type = props.type;
        const title = props.title;
        if (type === "admin") {
            return (
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Администратор сервиса"
                >
                    <i className="fas fa-toolbox"></i> {title}
                </span>
            );
        } else if (type === "employee") {
            return (
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Сотрудник"
                >
                    <i className="fas fa-user"></i> {title}
                </span>
            );
        } else if (type === "guest") {
            return (
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Посетитель"
                >
                    <i className="fas fa-user-secret"></i> {title}
                </span>
            );
        } else if (type === "vehicle") {
            return (
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Автомобиль"
                >
                    <i className="fas fa-car"></i> {title}
                </span>
            );
        }
        return <span>{title}</span>;
    }

    return (
        <>
            <div className="row g-5 g-xl-8 mt-4">
                {monitorItems.map((item, key) => {
                    return (
                        <div className="col-xl-4 mt-0" key={key}>
                            <MonitorWidget
                                device={item.device.device}
                                record={item.lastEvent}
                                color={"primary"}
                                className={"card-xl-stretch mb-xl-8"}
                            />
                        </div>
                    );
                })}
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <h3>{monitor?.result.name}</h3>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: "id",
                            dtString: "Дата",
                            accountTitle: "Аккаунт",
                            entityTitle: "Субъект",
                            deviceTitle: "Устройство",
                            cardTitle: "Идентификатор",
                            eventName: "Событие",
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="mb-0"
                        entity={events}
                        tdClasses={{ dtString: "ps-6" }}
                    />
                </div>
            </div>
        </>
    );
};

export default MonitorPanel;
