import { useContext, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    getCurrentAsync,
    selectObject,
    updateAsync,
} from '../../../app/storeReducers/SupportSlice';
import {
    DateToString,
    GetTime,
    UtcToLocal,
} from '../../../helpers/DateHelpers';
import { Http } from '../../../http';
import SupportTaskCreateModal from '../SupportTaskCreateModal';
import { saveAs } from 'file-saver';
import { HubConnectionBuilder } from '@microsoft/signalr';
import SecutiryContext from '../../../features/securityContext';

const SupportCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const ticketId = props.match.params.ticketId;

    const dispatch = useDispatch();

    const ticket = useSelector(selectObject);

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const { context } = useContext(SecutiryContext);
    const [accountActions, setAccountActions] = useState([]);

    const [connection, setConnection] = useState(null);
    const latestMessages = useRef(null);
    latestMessages.current = messages;
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_PLATFORM_URL}ws/tickets`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);
    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    console.log('Connected!');
                    connection
                        .invoke('JoinGroup', `tickets_${ticketId}`) //JoinGroup is C# method name
                        .catch((err) => {
                            console.log(err);
                        });

                    connection.on('ReceiveMessage', (message) => {
                        const actualMessages = [...latestMessages.current];
                        actualMessages.push(message);
                        setMessages(actualMessages);
                    });
                })
                .catch((e) => console.log('Connection failed: ', e));
        }
    }, [connection]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(getCurrentAsync(ticketId));
        }

        loadResourcesAndDataAsync();
    }, [ticketId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const getResult = await Http.get(
                'api/tickets/' + ticketId + '/messages',
                true
            );
            getResult.result.forEach((item) => {
                item = wrapMessage(item);
            });
            setMessages(getResult.result);
        }

        loadResourcesAndDataAsync();
    }, [ticketId]);

    useEffect(() => {
        const actions = context?.accounts?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setAccountActions(actions);
    }, [context, accountId]);

    function wrapMessage(item) {
        if (item.attachment) {
            let text = item.text;
            item.text = (
                <span>
                    Скачать{' '}
                    <a
                        href='#'
                        onClick={() => {
                            downloadFile(item.id, text);
                        }}
                    >
                        {text}
                    </a>
                </span>
            );
        }
        return item;
    }
    const sendMessage = async () => {
        const data = { text: newMessage };
        const getResult = await Http.post(
            'api/tickets/' + ticketId + '/messages',
            true,
            data
        );
        setMessages([...messages, getResult.result]);
        setNewMessage('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.ctrlKey) {
            sendMessage();
        }
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const applySupport = () => {
        let updatedTicket = { ...ticket };
        updatedTicket.isEscalated = true;
        console.log('updEnt', updatedTicket);
        dispatch(updateAsync(updatedTicket));
    };

    const closeTicket = async () => {
        await Http.put(`api/tickets/${ticketId}/done`);
        dispatch(getCurrentAsync(ticketId));
    };

    const handleSetFile = async (event) => {
        const { files } = event.target;
        var data = new FormData();
        for (const file of files) {
            data.append('files', file, file.name);
        }
        const result = await Http.postMedia(
            `api/tickets/${ticketId}/messages/attachment`,
            data
        );
        setMessages([...messages, wrapMessage(result.result)]);
    };

    const downloadFile = async (id, name) => {
        const response = await Http.getMedia(
            `api/tickets/messages/${id}/attachment`
        );
        var blob = new Blob([response], {
            type: 'application/octet-stream',
        });
        saveAs(blob, name);
    };

    let ticketStatus = '';

    switch (ticket.status) {
        case 'new':
            ticketStatus = 'Новая';
            break;
        case 'inprogress':
            ticketStatus = 'Открыта';
            break;
        case 'done':
            ticketStatus = 'Закрыта';
            break;

        default:
            break;
    }

    let lastDateMessage = new Date('1970-01-01').toDateString();

    function GetTextBlock(props) {
        const message = props.message;
        if (message.type !== 'span') {
            let items = message?.split('\n');
            return (
                <div>
                    {items.map((item) => {
                        return <div>{item}</div>;
                    })}
                </div>
            );
        } else return message;
    }

    return (
        <>
            <SupportTaskCreateModal
                accountId={accountId}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
                ticketId={ticketId}
            />
            <h1 className='mb-5'>{ticket.subject}</h1>
            <div className='row mb-5'>
                <div className='col-md-8 align-self-center'>
                    <div className='d-inline me-3'>
                        <span className='text-muted'>Номер: </span> {ticket.id}
                    </div>
                    <div className='d-inline me-3'>
                        <span className='text-muted'>Статус: </span>{' '}
                        {ticketStatus}
                    </div>
                    <div className='d-inline me-3'>
                        <span className='text-muted'>Аккаунт и автор: </span>{' '}
                        {ticket.account?.title}{' '}
                        {accountActions?.includes('tickets_resolve') ? (
                            <Link
                                className='av-text-primary'
                                to={`/account/${accountId}/employees/${ticket.author?.id}`}
                            >
                                {ticket.author?.title}
                            </Link>
                        ) : (
                            ticket.author?.title
                        )}
                    </div>
                </div>
                <div className='col-md-4 text-end'>
                    <button
                        className='btn av-btn-outline-success me-3'
                        onClick={applySupport}
                        disabled={!(ticket.canEscalate && !ticket.isEscalated)}
                    >
                        {ticket.canEscalate && !ticket.isEscalated
                            ? 'Подключить техподдержку'
                            : 'Техподдержка подключена'}
                    </button>
                    {ticket.status !== 'done' ? (
                        <button
                            className='btn av-btn-outline-primary'
                            onClick={closeTicket}
                        >
                            Закрыть заявку
                        </button>
                    ) : null}
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <div className='card border'>
                        <div className='card-body scroll-y h-500px'>
                            <div
                                className={
                                    'd-flex mb-10 ' +
                                    (ticket.author?.id === context?.user?.id
                                        ? 'justify-content-end'
                                        : 'justify-content-start')
                                }
                            >
                                <div className='messageBox d-flex flex-column align-items-start'>
                                    <div className='message-author d-flex mb-1'>
                                        {ticket.author?.title}
                                        <span className='text-muted ms-2 text-end'>
                                            {DateToString(
                                                UtcToLocal(
                                                    ticket?.createdAt ??
                                                        '0001.01.01 00:00:00Z00'
                                                ),
                                                true
                                            )}
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            'p-3 text-dark fw-bold text-start' +
                                            (ticket.author?.id ===
                                            context?.user?.id
                                                ? ' message-my'
                                                : ' message-other')
                                        }
                                    >
                                        {ticket.description}
                                    </div>
                                </div>
                            </div>
                            {messages.map((item) => {
                                let isNewDate = false;
                                if (
                                    lastDateMessage !==
                                    new Date(item.time).toDateString()
                                ) {
                                    lastDateMessage = new Date(
                                        item.time
                                    ).toDateString();
                                    isNewDate = true;
                                }
                                return (
                                    <div key={item.id}>
                                        {isNewDate ? (
                                            <div className='mb-5 text-center text-muted'>
                                                {DateToString(
                                                    UtcToLocal(item.time)
                                                )}
                                            </div>
                                        ) : null}
                                        <div
                                            className={
                                                'd-flex mb-5 ' +
                                                (item.user.id ===
                                                context.user.id
                                                    ? 'justify-content-end'
                                                    : 'justify-content-start')
                                            }
                                        >
                                            {item.user?.id ===
                                            context?.user?.id ? null : (
                                                <div className='symbol symbol-40p me-3'>
                                                    <img
                                                        src='/images/no-user.jpg'
                                                        className='rounded-circle'
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            <div
                                                className={
                                                    'messageBox d-flex flex-column ' +
                                                    (item.user?.id ===
                                                    context?.user?.id
                                                        ? 'align-items-end'
                                                        : 'align-items-start')
                                                }
                                            >
                                                <div
                                                    className='message-author d-flex mb-1 justify-content-between'
                                                    style={{ width: '100%' }}
                                                >
                                                    {item.user?.id ===
                                                    context?.user?.id ? (
                                                        <div>
                                                            Вы
                                                            <span className='text-muted ms-1'>
                                                                (
                                                                {
                                                                    item.user
                                                                        .title
                                                                }
                                                                )
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {item.user.title}
                                                        </div>
                                                    )}
                                                    <div className='text-muted ms-2'>
                                                        {GetTime(
                                                            UtcToLocal(
                                                                item.time
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={`px-3 py-2 ${
                                                        item.user.id ===
                                                        context.user.id
                                                            ? 'message-my'
                                                            : 'message-other'
                                                    } text-dark fw-bold`}
                                                >
                                                    <GetTextBlock
                                                        message={item.text}
                                                    />
                                                    {/*item.text.split('\n').map(x=>{ <p>{x}</p> })*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className='card-footer py-5'>
                            {ticket.status !== 'done' ? (
                                <div className='d-flex'>
                                    <div className='flex-grow-1 me-3'>
                                        <textarea
                                            className='form-control'
                                            placeholder='Ваш ответ'
                                            value={newMessage}
                                            onChange={(e) =>
                                                setNewMessage(e.target.value)
                                            }
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                    <div className=''>
                                        <label className='btn av-btn-simple me-3'>
                                            <i
                                                className='fas fa-paperclip'
                                                style={{ cursor: 'pointer' }}
                                            ></i>
                                            <input
                                                type='file'
                                                name='files'
                                                placeholder='Image'
                                                onChange={(e) =>
                                                    handleSetFile(e)
                                                }
                                                style={{ display: 'none' }}
                                            />
                                        </label>
                                        <button
                                            className='btn av-btn-primary'
                                            onClick={sendMessage}
                                        >
                                            <i className='far fa-paper-plane me-2' />
                                            Отправить
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SupportCardPage;
