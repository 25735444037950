import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';

const nameSlice = 'auth';
const urlSlice = 'auth';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    securityContext: {},
};

export const getSecurityContextAsync = createAsyncThunk(
    `${nameSlice}/security`,
    async (important = false, { getState }) => {
        const { isLoading, securityContext } = getState().auth;
        if (Object.keys(securityContext).length === 0 || important) {
            const getResult = await Http.post(`api/${urlSlice}/security`, true);
            return getResult.result;
        }
        return securityContext;
    }
);

export const authSlice = createSlice({
    name: nameSlice,
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: {
        [getSecurityContextAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getSecurityContextAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.securityContext = action.payload;
            }
            state.isLoading = false;
        },
        [getSecurityContextAsync.rejected]: (state, action) => {
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const selectContext = (state) => state.auth.securityContext;
export const selectIsLoading = (state) => state.auth.isLoading;

export default authSlice.reducer;
