import SkeletonTableFull from '../../../components/skeleton/SkeletonTableFull';
import { DateToString, UtcToLocal } from '../../../helpers/DateHelpers';

const EventsList = (props) => {
    const { events, isLoading } = props;
    let skeletonEntity = events;
    skeletonEntity.forEach((item) => {
        item.rKeyItem = item.uid + '-' + item.time;
        item.dtString = DateToString(UtcToLocal(item.time), true);
    });
    return (
        <>
            <div className='card'>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <SkeletonTableFull
                            cols={{
                                rKeyItem: 'rKeyItem',
                                dtString: 'Дата',
                                uid: 'UID',
                                card: 'Карта',
                                type: 'Тип',
                            }}
                            rowsCount={5}
                            isLoading={isLoading}
                            classTable='table-striped gy-7 gs-7'
                            entity={skeletonEntity}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventsList;
