import { useEffect, useState } from "react";
import PermissionPage from "../../components/PermissionPage";
import DeviceCamerasPanel from "./cameras/DeviceCamerasPanel";
import DeviceControlPanel from "./control/DeviceControlPanel";
import DevicePanel from "./DevicePanel";
import DevicePhonePanel from "./DevicePhonePanel";
import DeviceQrCodePanel from "./DeviceQrCodePanel";
import DeviceSchemesPanel from "./DeviceSchemesPanel";
import DeviceStatusPanel from "./DeviceStatusPanel";
import { useSelector } from "react-redux";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetDeviceByIdQuery } from "../../app/storeReducers/devicesApiSlice";
import { useLazyGetAccountByIdQuery } from "../../app/storeReducers/acountsApiSlice";

const DeviceCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const deviceId = props.match.params.deviceId;

    const [isBasicAccount, setIsBasicAccount] = useState(false);
    const [isSubAccount, setIsSubAccount] = useState(false);
    const context = useSelector(selectContext);

    const [accountActions, setAccountActions] = useState([]);

    const [showStatusPanel, setShowStatusPanel] = useState(false);
    const [showPhonePanel, setShowPhonePanel] = useState(false);
    const [showControlPanel, setShowControlPanel] = useState(false);

    const { data: device } = useGetDeviceByIdQuery(deviceId, {
        skip: !deviceId,
    });
    const [getAccountById] = useLazyGetAccountByIdQuery();

    useEffect(() => {
        setAccountActions(
            context?.permissions?.find((x) => x.id === accountId)?.actions
        );
    }, [context, accountId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (device) {
                const accResult = await getAccountById(
                    device?.result?.account?.id
                ).unwrap();
                setIsBasicAccount(accResult?.result?.type === "basic");
                setIsSubAccount(accResult?.result?.type === "subaccount");
                setShowStatusPanel(device?.result?.provider !== "timetracking");
                setShowPhonePanel(device?.result?.provider !== "timetracking");
                setShowControlPanel(
                    device?.result?.provider !== "timetracking"
                );
            }
        }

        loadResourcesAndDataAsync();
        return () => {
            setIsBasicAccount(false);
            setIsSubAccount(false);
        };
    }, [device]);

    return (
        <PermissionPage accountId={accountId} roles={["devices_list"]}>
            <h1 className="mb-5">Карточка устройства</h1>
            <div className="row">
                <div className="col-6">
                    <DevicePanel accountId={accountId} deviceId={deviceId} />
                </div>
                <div className="col-6">
                    {showStatusPanel ? (
                        <div className="row mb-4">
                            <div className="col">
                                <DeviceStatusPanel
                                    deviceId={deviceId}
                                    accountId={accountId}
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {!isBasicAccount &&
                    !isSubAccount &&
                    showControlPanel &&
                    (context?.isRoot ||
                        accountActions?.includes("devices_edit")) ? (
                        <div className="row mb-4">
                            <div className="col">
                                <DeviceControlPanel
                                    accountId={accountId}
                                    deviceId={deviceId}
                                />
                            </div>
                        </div>
                    ) : null}
                    <div className="row mb-4">
                        <div className="col">
                            <div className="card border">
                                <div className="card-body pb-2">
                                    <DeviceSchemesPanel
                                        accountId={accountId}
                                        deviceId={deviceId}
                                    />
                                    <DeviceCamerasPanel
                                        accountId={accountId}
                                        entityId={deviceId}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {showPhonePanel ? (
                        <div className="row mb-4">
                            <div className="col">
                                <DevicePhonePanel
                                    accountId={accountId}
                                    deviceId={deviceId}
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="row mb-4">
                        <div className="col">
                            <DeviceQrCodePanel
                                context={context}
                                device={device?.result}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default DeviceCardPage;
