import { baseApi } from "../baseApi";

export const schemesApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSchemes: builder.query({
            query: (data) =>
                `schemes?accountId=${data.accountId ?? ""}&deviceId=${
                    data.deviceId ?? ""
                }&search=${data.search ?? ""}&sort=${
                    data.sort ?? ""
                }&pageIndex=${data.pageIndex ?? 1}&pageSize=${
                    data.pageSize ?? 100
                }`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Schemes",
                              id,
                          })),
                          { type: "Schemes", id: "List" },
                      ]
                    : [{ type: "Schemes", id: "List" }],
        }),
        getSchemeById: builder.query({
            query: (id) => `schemes/${id}`,
            providesTags: (result) => [
                { type: "Scheme", id: result?.result.id },
            ],
        }),
        createScheme: builder.mutation({
            query: (body) => ({
                url: "schemes",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Schemes", id: "List" }],
        }),
        updateScheme: builder.mutation({
            query: (body) => ({
                url: "schemes/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Schemes", id: "List" },
                { type: "Scheme", id: result?.result.id },
            ],
        }),
        deleteScheme: builder.mutation({
            query: (id) => ({
                url: `schemes/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result) => [{ type: "Schemes", id: "List" }],
        }),
        getSchemeMembers: builder.query({
            query: (data) =>
                `schemes/${data.id}/members?forAccount=${
                    data.accountId ?? "0"
                }`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "SchemeMembers",
                              id,
                          })),
                          { type: "SchemeMembers", id: "List" },
                      ]
                    : [{ type: "SchemeMembers", id: "List" }],
        }),
        addSchemeMember: builder.mutation({
            query: (data) => ({
                url: `schemes/${data.schemeId}/members`,
                method: "PUT",
                body: { ...data.body },
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "SchemeMembers",
                              id,
                          })),
                          { type: "SchemeMembers", id: "List" },
                      ]
                    : [{ type: "SchemeMembers", id: "List" }],
        }),
        removeSchemeMember: builder.mutation({
            query: (data) => ({
                url: `schemes/${data.schemeId}/members?isGroup=${
                    data.isGroup ?? false
                }&member=${data.employeeId}`,
                method: "DELETE",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "SchemeMembers",
                              id,
                          })),
                          { type: "SchemeMembers", id: "List" },
                      ]
                    : [{ type: "SchemeMembers", id: "List" }],
        }),
        getSchemeDevices: builder.query({
            query: (schemeId) => `schemes/${schemeId}/devices`,
        }),
        addSchemeDevice: builder.mutation({
            query: (data) => ({
                url: `schemes/${data.schemeId}/devices/${data.deviceId}`,
                method: "PUT",
            }),
        }),
        removeSchemeDevice: builder.mutation({
            query: (data) => ({
                url: `schemes/${data.schemeId}/devices/${data.deviceId}`,
                method: "DELETE",
            }),
        }),
        getSchemesShares: builder.query({
            query: (schemeId) => `schemes/${schemeId}/shares`,
        }),
        createSchemeShare: builder.mutation({
            query: (data) => ({
                url: `schemes/${data.schemeId}/shares/${data.accountId}`,
                method: "PUT",
            }),
        }),
        createSchemesShareArray: builder.mutation({
            query: (data) => ({
                url: `schemes/${data.schemeId}/shares`,
                method: "PUT",
                body: data.body,
            }),
        }),
        deleteSchemeShare: builder.mutation({
            query: (data) => ({
                url: `schemes/${data.schemeId}/shares/${data.accountId}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useGetSchemesQuery,
    useGetSchemeByIdQuery,
    useCreateSchemeMutation,
    useUpdateSchemeMutation,
    useDeleteSchemeMutation,
    useGetSchemeMembersQuery,
    useAddSchemeMemberMutation,
    useRemoveSchemeMemberMutation,
    useGetSchemeDevicesQuery,
    useLazyGetSchemeDevicesQuery,
    useAddSchemeDeviceMutation,
    useRemoveSchemeDeviceMutation,
    useGetSchemesSharesQuery,
    useCreateSchemeShareMutation,
    useDeleteSchemeShareMutation,
    useCreateSchemesShareArrayMutation,
} = schemesApiSlice;
