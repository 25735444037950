import { useEffect, useState } from 'react';
import { Http } from '../../../../http';

const BarrierModal = (props) => {
    const { accountId, 
        vehicle, 
        isOpen, 
        closeHandle, 
        direction, 
        handleOk
    } = props;

    const [barriers, setBarriers] = useState([]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const getResult = await Http.get(
                `api/devices?accountId=${accountId}&isAutogate=true&sort=name`
            );
            setBarriers(getResult.result);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    const openBarrier = async (deviceId, dir) => {
        console.log('open barrier', vehicle, vehicle?.isGuest);
        if (Object.keys(vehicle).length !== 0) {

            if(vehicle?.isGuest) {
                const result = await Http.put(
                    `api/guests/${vehicle.id}/auto/${dir}?deviceId=${deviceId}`,
                    true
                );    
            }
            else {
                const result = await Http.put(
                    `api/vehicles/${vehicle.id}/auto/${dir}?deviceId=${deviceId}`,
                    true
                );    
            }
        } else {
            const result = await Http.post(
                `api/devices/open?id=${deviceId}&direction=${dir}`,
                true
            );
            console.log('carResult', result);
        }
        handleOk();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Управление шлагбаумом
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            {vehicle !== undefined && vehicle != null && vehicle?.id !==undefined ? (
                                <div className='row mb-2'>
                                    <div className='col'>
                                        <h4>{vehicle.isGuest ? 'Посетитель' : 'Транспорт'} {vehicle.carNumber}</h4>
                                    </div>
                                </div>
                            ) : null}
                            {barriers.map((item) => (
                                <div
                                    key={item.id}
                                    className='border rounded mb-3 p-2'
                                >
                                    <div className='row'>
                                        <div className='col-md-6 d-flex align-items-center'>
                                            {item.name}
                                        </div>
                                        <div className='col-md-3'>
                                            {direction === 'in' ||
                                            direction === 'all' ? (
                                                <button
                                                    className='btn av-btn-primary'
                                                    onClick={() =>
                                                        openBarrier(
                                                            item.id,
                                                            'in'
                                                        )
                                                    }
                                                >
                                                    Въезд
                                                </button>
                                            ) : (
                                                <button
                                                    className='btn av-btn-simple'
                                                    disabled
                                                >
                                                    Въезд
                                                </button>
                                            )}
                                        </div>
                                        <div className='col-md-3'>
                                            {direction === 'out' ||
                                            direction === 'all' ? (
                                                <button
                                                    className='btn av-btn-primary'
                                                    onClick={() =>
                                                        openBarrier(
                                                            item.id,
                                                            'out'
                                                        )
                                                    }
                                                >
                                                    Выезд
                                                </button>
                                            ) : (
                                                <button
                                                    className='btn av-btn-simple'
                                                    disabled
                                                >
                                                    Выезд
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default BarrierModal;
