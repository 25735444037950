import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const devicesApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getDevices: builder.query({
            query: (data) =>
                `devices?accountId=${data.accountId ?? ""}` +
                `&hostId=${data.hostId ?? ""}` +
                `&locationId=${data.locationId ?? ""}` +
                `&scheduleId=${data.scheduleId ?? ""}` +
                `&isAutogate=${data.isAutogate ?? false}` +
                `&sort=${data.sort ?? ""}` +
                `&search=${data.search ?? ""}` +
                `&pageIndex=${data.pageIndex ?? 1}` +
                `&pageSize=${data.pageSize ?? 100}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Devices",
                              id,
                          })),
                          { type: "Devices", id: "List" },
                      ]
                    : [{ type: "Devices", id: "List" }],
        }),
        getDeviceById: builder.query({
            query: (id) => `devices/${id}`,
            providesTags: (result) => [
                { type: "Device", id: result.result.id },
            ],
        }),
        createDevice: builder.mutation({
            query: (body) => ({
                url: "devices",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Devices", id: "List" }],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка добавления!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось добавить устройство! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Устройство добавлено!",
                                description: "Устройство добавлено успешно!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка добавления!",
                            description:
                                "Не удалось добавить устройство! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        updateDevice: builder.mutation({
            query: (body) => ({
                url: "devices/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Devices", id: "List" },
                { type: "Device", id: result?.result?.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка обновления!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось обновить устройство! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Устройство обновлено!",
                                description: "Устройство обновлено успешно!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка обновления!",
                            description:
                                "Не удалось обновить устройство! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteDevice: builder.mutation({
            query: (id) => ({
                url: "devices/" + id,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Devices", id: "List" }],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка удаления!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось удалить устройство! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Устройство удалено!",
                                description: "Устройство удалено успешно!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка удаления!",
                            description:
                                "Не удалось удалить устройство! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        getDeviceByUid: builder.query({
            query: (uid) => `devices/uid/${uid}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Devices",
                              id,
                          })),
                          { type: "Devices", id: "List" },
                      ]
                    : [{ type: "Devices", id: "List" }],
        }),
        updateDeviceQrcode: builder.mutation({
            query: (id) => ({
                url: "devices/" + id + "/qrcode",
                method: "PUT",
            }),
            invalidatesTags: (result) => [
                { type: "Devices", id: "List" },
                { type: "Device", id: result?.result?.id },
            ],
        }),
        openDevice: builder.mutation({
            query: (data) => ({
                url: `devices/open?id=${data.id}&direction=${data.direction}`,
                method: "POST",
            }),
        }),
        replaceDevice: builder.mutation({
            query: (data) => ({
                url: `devices/${data.id}/replace?uid=${data.uid}&provider=${data.provider}`,
                method: "PUT",
            }),
            invalidatesTags: (result) => [
                { type: "Devices", id: "List" },
                { type: "Device", id: result?.result?.id },
            ],
        }),
        longTimeOpenDevice: builder.mutation({
            query: (data) => ({
                url: `devices/${data.id}/longtime/${data.value}`,
                method: "POST",
            }),
            invalidatesTags: (result) => [
                { type: "Devices", id: "List" },
                { type: "Device", id: result?.result?.id },
            ],
        }),
        provideDevice: builder.mutation({
            query: (data) => ({
                url: `devices/provide?accountId=${data.accountId}`,
                method: "PUT",
                body: { ...data.body },
            }),
            invalidatesTags: [{ type: "Devices", id: "List" }],
        }),
        returnDevice: builder.mutation({
            query: (data) => ({
                url: "devices/return",
                method: "PUT",
                body: { ...data },
            }),
            invalidatesTags: [{ type: "Devices", id: "List" }],
        }),
        setDeviceQrCode: builder.mutation({
            query: (data) => ({
                url: "devices/" + data.id + "/qrcode",
                method: "PUT",
                body: data.qrCode,
            }),
            invalidatesTags: (result) => [
                { type: "Devices", id: "List" },
                { type: "Device", id: result?.result?.id },
            ],
        }),
    }),
});

export const {
    useGetDevicesQuery,
    useLazyGetDevicesQuery,
    useGetDeviceByIdQuery,
    useLazyGetDeviceByIdQuery,
    useCreateDeviceMutation,
    useUpdateDeviceMutation,
    useDeleteDeviceMutation,
    useGetDeviceByUidQuery,
    useUpdateDeviceQrcodeMutation,
    useOpenDeviceMutation,
    useReplaceDeviceMutation,
    useLongTimeOpenDeviceMutation,
    useProvideDeviceMutation,
    useReturnDeviceMutation,
    useSetDeviceQrCodeMutation,
} = devicesApiSlice;
