import { FormatDateToHuman, UtcToLocal } from '../../helpers/DateHelpers';
import { baseApi } from '../baseApi';

export const DevicesAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getDevices: builder.query({
            query: (data) =>
                `devices?accountId=${data.accountId ?? ''}` +
                `&hostId=${data.hostId ?? ''}` +
                `&locationId=${data.locationId ?? ''}` +
                `&scheduleId=${data.scheduleId ?? ''}` +
                `&isAutogate=${data.isAutogate ?? false}` +
                `&sort=${data.sort ?? ''}` +
                `&search=${data.search ?? ''}` +
                `&pageIndex=${data.pageIndex ?? 1}` +
                `&pageSize=${data.pageSize ?? 100}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Devices',
                              id,
                          })),
                          { type: 'Devices', id: 'List' },
                      ]
                    : [{ type: 'Devices', id: 'List' }],
        }),

    }),
});

export const {
    useGetDevicesQuery,
    useLazyGetDevicesQuery,
} = DevicesAPI;
