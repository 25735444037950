import { useState } from 'react';
import SkeletonTable from '../../../components/skeleton/SkeletonTable';
import FindComponent from '../../../components/FindComponent';
import { useHistory } from 'react-router-dom';
import Paginator from '../../../components/Paginator';
import ThSortable from '../../../components/table/ThSortable';
import { useGetDevicesQuery } from '../../../app/storeReducers/devicesApiSlice';

const AdminDevices = () => {
    const [pageSize, setPageSize] = useState(10);
    const [forcePage, setForcePage] = useState(0);
    const [search, setSearch] = useState('');
    const history = useHistory();

    const [sort, setSort] = useState('uid');
    const [sortDest, setSortDest] = useState('asc');
    const [sortEntity, setSortEntity] = useState('uid');

    const { data: entityList, isLoading } = useGetDevicesQuery({
        search,
        sort: sortEntity,
        pageIndex: forcePage + 1,
        pageSize,
    });

    const openEntityCard = (accountId, entityId) => {
        history.push(`/account/${accountId}/devices/${entityId}`);
    };

    const changePage = async (pageIndex) => {
        setForcePage(pageIndex - 1);
    };

    const columnFilter = (colName) => {
        const dest = colName === sort ? (sortDest === 'asc' ? 'desc' : 'asc') : 'asc';
        setSort(colName);
        setSortDest(dest);
        setSortEntity(colName + (dest === 'desc' ? '_desc' : ''));
    };

    function StatusLabel(props) {
        const isOnline = props.isOnline;
        const provider = props.provider;
        if (provider === 'timetracking') {
            return '';
        }
        if (isOnline) {
            return <span className='badge badge-sm bg-light-success rounded-pill'>online</span>;
        }
        return <span className='badge badge-sm bg-light-secondary rounded-pill'>offline</span>;
    }

    const changePageSize = async (size) => {
        setPageSize(size);
        setForcePage(0);
    };

    return (
        <>
            <div className='card'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    <div className='card-toolbar'>
                        <FindComponent callback={(name) => setSearch(name)} />
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <SkeletonTable
                            cols={['ID', 'Аккаунт', 'Название', 'UID', 'Статус']}
                            rowsCount={5}
                            isLoading={isLoading}
                            classTable='table-striped gy-7 gs-7'
                        >
                            <table className='table table-striped gy-7 gs-7'>
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='id'
                                            colTitle='ID'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='account'
                                            colTitle='Аккаунт'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='name'
                                            colTitle='Название'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='uid'
                                            colTitle='UID'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='status'
                                            colTitle='Статус'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                    </tr>
                                </thead>
                                <tbody>
                                    {entityList?.result?.map((item) => (
                                        <tr
                                            key={item.id}
                                            onClick={() => openEntityCard(item.account.id, item.id)}
                                            className='cursor-pointer'
                                        >
                                            <td>{item.id}</td>
                                            <td>{item?.account?.title}</td>
                                            <td>{item.name}</td>
                                            <td>{item.uid}</td>
                                            <td>
                                                <StatusLabel isOnline={item.isOnline} provider={item.provider} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </SkeletonTable>
                    </div>
                </div>
            </div>
            <Paginator
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                pageCount={entityList?.paginator?.totalPages}
                pageSizeArray={[50, 100, 500]}
                pageSize={pageSize}
                countCurrent={entityList?.result?.length}
                countTotal={entityList?.paginator?.totalCount}
                forcePage={forcePage}
            />
        </>
    );
};

export default AdminDevices;
