import { useEffect, useState } from 'react';
import { Http } from '../../../../http';
import SelectWithFindInput from '../../../../components/SelectWithFindInput';
import { addNotification } from '../../../../app/storeReducers/NotifySlice';
import { useDispatch } from 'react-redux';
import FullScreenModal from '../../../../components/FullScreenModal';

const LetInModal = (props) => {
    const { isOpen, closeHandle, accountId, preAccountId } = props;

    const dispatch = useDispatch();

    const [data, setData] = useState({});

    const [accountSelected, setAccountSelected] = useState(0);
    const [deviceSelected, setDeviceSelected] = useState(0);
    const [carNumber, setCarNumber] = useState('');
    const [carRegion, setCarRegion] = useState('');
    const [screen, setScreen] = useState('');
    const [screenId, setScreenId] = useState(0);
    const [findText, setFindText] = useState('');

    const [isLoading, setIsLoading] = useState(true);

    const [isOpenFullscreen, setIsOpenFullScreen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (isOpen === true) {
                const request = await Http.get(
                    'api/guests/auto/form-data?accountId=' + accountId,
                    true
                );
                if (request?.result !== null) {
                    setData(request.result);
                    if (request.result.devices?.length > 0) {
                        const savedDeviceId = localStorage.getItem(
                            'operator_guest_device'
                        );
                        let deviceId = request.result.devices[0].id;
                        if (
                            savedDeviceId !== null &&
                            request.result.devices.find(
                                (x) => x.id === parseInt(savedDeviceId)
                            ) !== undefined
                        ) {
                            deviceId = parseInt(savedDeviceId);
                        }
                        setDeviceSelected(deviceId);
                    }
                    if (request.result.accounts?.length > 0) {
                        if (preAccountId > 0) {
                            setAccountSelected(preAccountId);
                        } else {
                            setAccountSelected(request.result.accounts[0].id);
                        }
                    }
                }
                console.log('form-data', request?.result);
            }
        }

        loadResourcesAndDataAsync();
    }, [accountId, isOpen]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const recognition = data.recognitions?.find(
                (x) =>
                    x.uid ===
                    data.devices?.find((d) => d.id === parseInt(deviceSelected))
                        ?.uid
            );
            setCarNumber(recognition?.carNumber ?? '');
            setCarRegion(recognition?.carRegion ?? '');
            setScreenId(recognition?.screenId ?? 0);
            if (recognition?.screenId !== null) {
                const requestScreen = await Http.get(
                    `api/screenshots?key=${recognition?.screenId}`
                );
                setScreen(requestScreen?.result[0]?.data);
            } else {
                setScreen('');
            }
        }

        loadResourcesAndDataAsync();
    }, [deviceSelected]);

    const saveEntity = async () => {
        localStorage.setItem('operator_guest_device', deviceSelected);
        const saveGuest = await Http.post('api/guests/auto/register', true, {
            accountId: accountSelected,
            carNumber,
            carRegion,
            deviceId: deviceSelected,
            screenId,
        });
        if (saveGuest.error) {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка добавления посетителя!',
                    description: saveGuest.errorDescription
                        ? saveGuest.errorDescription
                        : 'Не удалось добавить посетителя! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }
        closeModal(true);
    };

    const closeModal = (isSaved = false) => {
        setDeviceSelected(0);
        setScreen('');
        setScreenId(0);
        closeHandle(isSaved);
    };

    const openBase64 = () => {
        setIsOpenFullScreen(true);
    };

    return (
        <>
            <FullScreenModal
                image={'data:image/jpg;base64,' + screen}
                isOpen={isOpenFullscreen}
                closeHandle={() => setIsOpenFullScreen(false)}
            />
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Впустить посетителя</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeModal}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='form-group mb-2'>
                                        <label>Аккаунт</label>
                                        <SelectWithFindInput
                                            list={data?.accounts
                                                ?.filter((x) =>
                                                    x.title
                                                        .toLowerCase()
                                                        .includes(
                                                            findText.toLowerCase()
                                                        )
                                                )
                                                ?.map((item) => {
                                                    item.name = item.title;
                                                    return item;
                                                })}
                                            value={accountSelected}
                                            selectCallback={(value) =>
                                                setAccountSelected(value)
                                            }
                                            textChanged={(value) =>
                                                setFindText(value)
                                            }
                                        />
                                    </div>
                                    <div className='form-group mb-2'>
                                        <label>Устройство</label>
                                        <select
                                            className='form-select'
                                            value={deviceSelected}
                                            onChange={(e) =>
                                                setDeviceSelected(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            {data?.devices?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='form-group'>
                                        <label>Номер автомобиля</label>
                                        <div className='input-group'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='x000xx'
                                                value={carNumber}
                                                onChange={(e) =>
                                                    setCarNumber(e.target.value)
                                                }
                                            />
                                            <input
                                                type='number'
                                                className='form-control'
                                                placeholder='000'
                                                value={carRegion}
                                                onChange={(e) =>
                                                    setCarRegion(e.target.value)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='col-md-8 text-center align-self-center'
                                    style={{ height: 410 }}
                                >
                                    {screenId === 0 ? (
                                        <i>Изображение отсутствует</i>
                                    ) : screen?.length > 0 ? (
                                        <img
                                            src={
                                                'data:image/jpg;base64,' +
                                                screen
                                            }
                                            className='img-fluid cursor-pointer'
                                            style={{ maxHeight: 410 }}
                                            onClick={openBase64}
                                        />
                                    ) : (
                                        <i>Загрузка</i>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeModal}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                                disabled={data?.accounts?.length === 0}
                            >
                                Создать посетителя и впустить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default LetInModal;
