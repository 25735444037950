import { useState } from 'react';
import ReactPaginate from 'react-paginate';

const Paginator = (props) => {
    const {
        pageCount,
        onPageChange,
        countCurrent,
        countTotal,
        pageSize,
        pageSizeArray,
        onPageSizeChange,
        forcePage,
    } = props;

    const [currentPage, setCurrentPage] = useState(1);

    const changePage = (data) => {
        setCurrentPage(data.selected + 1);
        onPageChange(data.selected + 1);
    };

    let sizeArr = [20, 50, 100];
    if (
        pageSizeArray !== undefined &&
        pageSizeArray != null &&
        pageSizeArray.length > 0
    ) {
        sizeArr = pageSizeArray;
    }

    let size = 100;
    if (pageSize !== undefined && pageSize != null) {
        size = pageSize;
    }

    return (
        <div className='row'>
            <div className='col-md-6'>
                <ReactPaginate
                    previousLabel={
                        <>
                            <i className='fas fa-arrow-left me-2' /> Назад
                        </>
                    }
                    nextLabel={
                        <>
                            Вперёд <i className='fas fa-arrow-right ms-2' />
                        </>
                    }
                    breakLabel={'...'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(data) => changePage(data)}
                    containerClassName={'pagination d-inline-flex'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    forcePage={forcePage}
                />
            </div>
            {countCurrent !== undefined && countCurrent != null ? (
                <div className='col-md-2 pt-2'>
                    <span
                        className='paginator-count-info'
                        id='paginatorCountInfoText'
                    >
                        {(currentPage - 1) * pageSize + countCurrent}/
                        {countTotal}
                    </span>
                </div>
            ) : null}
            {onPageSizeChange !== undefined && onPageSizeChange != null ? (
                <div className='col-md-4 text-end d-flex justify-content-end text-end'>
                    <div className='d-inline-block align-self-center'>
                        Показывать строк:
                    </div>
                    <div
                        className='btn-group float-end page-size-block ms-2'
                        role='group'
                    >
                        {sizeArr.map((item) => (
                            <button
                                key={item}
                                className={
                                    'btn' + (item === size ? ' active' : '')
                                }
                                onClick={() => onPageSizeChange(item)}
                            >
                                {item}
                            </button>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Paginator;
