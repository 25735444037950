import { useEffect, useState } from "react";
import {
    useAddBlockMutation,
    useGoToBlockMutation,
    useLazyGetBlackListByIdQuery,
} from "../../../app/storeReducers/AccountBlacklistApi";
import { useAccount } from "../../../components/useAccount";
import { AddDays, ToUtcString } from "../../../helpers/DateHelpers";
import InputMask from "react-input-mask";

const BanNewModal = (props) => {
    const { accountId, entity, isOpen, closeHandle } = props;

    const [createBan] = useAddBlockMutation();
    const [candidateBan] = useGoToBlockMutation();
    const [getBLock, { data: block }] = useLazyGetBlackListByIdQuery();
    const account = useAccount();

    const [carNumber, setCarNumber] = useState("");
    const [carRegion, setCarRegion] = useState("");
    const [comments, setComments] = useState("");
    const [blockEndDate, setBlockEndDate] = useState(new Date());
    const [standartNumber, setStandartNumber] = useState(true);
    const [isCarNumberError, setIsCarNumberError] = useState(false);

    useEffect(() => {
        setBlockEndDate(AddDays(new Date(), account?.blacklistBlockDuration));
        if (entity !== undefined) {
            setCarNumber(entity.carNumber);
            setCarRegion(entity.carRegion);
            setComments(entity.comments);
            getBLock(entity.id);
        } else {
            setCarNumber("");
            setCarRegion("");
            setComments("");
        }
    }, [account, entity]);

    const addToBan = () => {
        if (carNumber.length <= 0 || (carRegion.length < 2 && standartNumber)) {
            setIsCarNumberError(true);
            return;
        }
        if (entity !== undefined) {
            candidateBan({
                id: entity.id,
                date: ToUtcString(blockEndDate),
                comments,
            });
        } else {
            createBan({
                account: { id: accountId },
                carNumber,
                carRegion,
                comments,
                category: 2,
                blockEndDate: ToUtcString(blockEndDate),
            });
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавление в чёрный список
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-3">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="carNumber">
                                            Регистрационный номер
                                        </label>
                                        <div className="input-group">
                                            {standartNumber ? (
                                                <InputMask
                                                    mask="a999aa"
                                                    className={"form-control"}
                                                    value={carNumber}
                                                    onChange={(e) =>
                                                        setCarNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="А000АА"
                                                    formatChars={{
                                                        9: "[0-9]",
                                                        a: "[XxCcBmMkKHAaEeTYyoOPpсСмМтТвВаАрРоОуУкКеЕнНхХ]",
                                                    }}
                                                    disabled={
                                                        entity !== undefined
                                                    }
                                                />
                                            ) : (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="carNumber"
                                                    value={carNumber}
                                                    onChange={(e) =>
                                                        setCarNumber(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder="А000АА"
                                                    disabled={
                                                        entity !== undefined
                                                    }
                                                />
                                            )}
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="carRegion"
                                                value={carRegion}
                                                onChange={(e) =>
                                                    setCarRegion(e.target.value)
                                                }
                                                style={{ maxWidth: "100px" }}
                                                placeholder="000"
                                                disabled={entity !== undefined}
                                            />
                                        </div>
                                    </div>
                                    {isCarNumberError ? (
                                        <div className="form-text text-danger">
                                            Необходимо указать номер автомобиля
                                        </div>
                                    ) : null}
                                    <div className="form-check form-check-inline form-group mt-2">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="standartNumber"
                                            checked={!standartNumber}
                                            onChange={(e) =>
                                                setStandartNumber(
                                                    !e.target.checked
                                                )
                                            }
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="standartNumber"
                                        >
                                            Использовать специальный номер
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="name">
                                            Дата окончании блокировки
                                        </label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={
                                                ToUtcString(blockEndDate).split(
                                                    "T"
                                                )[0]
                                            }
                                            onChange={(e) =>
                                                setBlockEndDate(
                                                    new Date(e.target.value)
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label htmlFor="name">
                                            Комментарий
                                        </label>
                                        <div className="block-messages-block">
                                            {entity
                                                ? block?.result?.messages?.map(
                                                      (item, index) => (
                                                          <div
                                                              className="mb-2"
                                                              key={index}
                                                          >
                                                              {item}
                                                          </div>
                                                      )
                                                  )
                                                : null}
                                        </div>
                                        <textarea
                                            className="form-control mt-2"
                                            value={comments}
                                            onChange={(e) =>
                                                setComments(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer py-2">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={addToBan}
                            >
                                Добавить в ЧС
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default BanNewModal;
