import {
    forwardRef,
    useContext,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import { Http } from '../../http';
import CardDeleteModal from './CardDeleteModal';
import CardCreateModal from './CardCreateModal';
import CardOwnerTypeArray from './CardOwnerTypeArray';
import { addNotification } from '../../app/storeReducers/NotifySlice';
import { useDispatch } from 'react-redux';
import SecutiryContext from '../../features/securityContext';

const CardListTableComponent = forwardRef((props, ref) => {
    const {
        userId,
        employeeId,
        vehicleId,
        accountId,
        closeCreateModalCallback,
    } = props;

    const dispatch = useDispatch();
    const { context } = useContext(SecutiryContext);

    useImperativeHandle(ref, () => ({
        OpenCreateModal() {
            setCreateModalIsOpen(true);
        },
    }));

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            let _ownerId = userId;
            let _ownerType = 0;
            if (employeeId > 0) {
                _ownerId = employeeId;
                _ownerType = 1;
            }
            if (vehicleId > 0) {
                _ownerId = vehicleId;
                _ownerType = 2;
            }
            setOwnerId(_ownerId);
            setOwnerType(_ownerType);
            if (userId > 0 || employeeId > 0 || vehicleId > 0) {
                const getResult = await Http.get(
                    `api/cards?userId=${userId}&employeeId=${employeeId}&vehicleId=${vehicleId}`,
                    true
                );
                setCards(getResult.result || []);
            }
        }

        loadResourcesAndDataAsync();
    }, [userId, employeeId, vehicleId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => parseInt(x.id) === parseInt(accountId)
        )?.actions;
        setCanEdit(
            context.isRoot ||
                (actions?.includes('cards_edit') && vehicleId === '') ||
                (vehicleId > 0 &&
                    actions?.includes('cards_edit') &&
                    actions?.includes('vehicles_edit'))
        );
        setCanList(
            context.isRoot ||
                actions?.includes('cards_list') ||
                actions?.includes('employees_list')
        );
    }, [context, accountId]);

    const [ownerId, setOwnerId] = useState(0);
    const [ownerType, setOwnerType] = useState(0);

    const [cards, setCards] = useState([]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedCard, setDeletedCard] = useState([]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedCard, setEditedCard] = useState(0);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const openDeleteModal = (card) => {
        setDeletedCard(card);
        setDeleteModalIsOpen(true);
    };

    const deleteCard = async () => {
        await Http.delete('api/cards/' + deletedCard.id, true);
        const updateObj = cards.filter((x) => x.id !== deletedCard.id);
        setCards(updateObj);
        setDeleteModalIsOpen(false);
    };

    const openEditCard = (cardId) => {
        setEditedCard(cardId);
        setCreateModalIsOpen(true);
    };

    const saveCard = async (card) => {
        if (card.id === 0) {
            const createResult = await Http.post('api/cards', true, card);
            console.log('createCardResult', createResult);
            if (
                createResult.error === undefined ||
                createResult.error === null ||
                createResult.error === ''
            ) {
                setCards([...cards, createResult.result]);
            } else {
                dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка добавления!',
                        description: createResult.errorDescription,
                        type: 'danger',
                    })
                );
            }
        } else {
            const updateeResult = await Http.put(
                `api/cards/${card.id}`,
                true,
                card
            );
            if (
                updateeResult.error === undefined ||
                updateeResult.error === null ||
                updateeResult.error === ''
            ) {
                const updateObj = cards.map((item) => {
                    if (item.id === updateeResult.result.id) {
                        item = updateeResult.result;
                    }
                    return item;
                });
                setCards(updateObj);
            } else {
                dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка обновления!',
                        description: updateeResult.errorDescription,
                        type: 'danger',
                    })
                );
            }
        }
        setEditedCard(0);
        setCreateModalIsOpen(false);
    };

    const GetTypeIcon = (cardType) => {
        let icon = '/media/icons/avanguard/CardsProximity.svg';
        switch (cardType) {
            case 'proximity':
                icon = '/media/icons/avanguard/CardsProximity.svg';
                break;
            case 'uhf':
                icon = '/media/icons/avanguard/Cards1.svg';
                break;
            case 'qr':
                icon = '/media/icons/avanguard/QR.svg';
                break;
            case 'pin':
                icon = '/media/icons/avanguard/PIN.svg';
                break;
            default:
                icon = '/media/icons/avanguard/CardsProximity.svg';
                break;
        }
        return icon;
    };

    const closeCreateModal = (mode) => {
        setCreateModalIsOpen(false);
        setEditedCard(0);
        if (mode === 1) {
            closeCreateModalCallback();
        }
    };

    return (
        <>
            <CardDeleteModal
                entity={deletedCard}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteCard}
            />
            <CardCreateModal
                entityId={editedCard}
                ownerId={ownerId}
                ownerType={CardOwnerTypeArray.find((x) => x.id === ownerType)}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
                okHandle={saveCard}
            />
            <div className='card border' id='cardPanel'>
                <div className='card-body p-6'>
                    <div className='row mb-3'>
                        <div className='col-md-6 align-self-center'>
                            <div className='av-card-title'>
                                Карты доступа{' '}
                                <span className='badge rounded-pill bg-light text-dark ms-2 align-text-top'>
                                    {cards.length}
                                </span>
                            </div>
                        </div>
                        <div className='col-md-6 text-end'>
                            {canEdit ? (
                                <button
                                    className='btn av-btn-primary'
                                    onClick={() => setCreateModalIsOpen(true)}
                                >
                                    <i className='fas fa-plus me-2' />
                                    Добавить карту доступа
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {canList ? (
                    <table className='table mb-0 border-top'>
                        <tbody>
                            {cards.map((item) => (
                                <tr key={item.id}>
                                    <td className='ps-6 d-flex align-self-center'>
                                        <div className='rounded-circle av-bg-primary-50 symbol me-2 p-3'>
                                            <img
                                                src={GetTypeIcon(item.type)}
                                                className='add-profile-img'
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                }}
                                            />
                                        </div>
                                        <div>
                                            <div className='fw-boldest'>
                                                {item.name}
                                            </div>
                                            <div className='av-text-gray-500'>
                                                {item.number}
                                            </div>
                                        </div>
                                    </td>
                                    <td className='text-end'>
                                        {canEdit ? (
                                            <>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 px-2'
                                                    onClick={() =>
                                                        openEditCard(item.id)
                                                    }
                                                >
                                                    <i className='fas fa-pen text-muted' />
                                                </button>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1 px-2'
                                                    onClick={() =>
                                                        openDeleteModal(item)
                                                    }
                                                >
                                                    <i className='far fa-trash-alt text-muted' />
                                                </button>
                                            </>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className='card-body'>
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
});

export default CardListTableComponent;
