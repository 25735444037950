import { useContext, useEffect, useState } from 'react';
import SecutiryContext from '../features/securityContext';

const PermissionPage = (props) => {
    const { accountId, roles } = props;
    const { context } = useContext(SecutiryContext);

    const [isLoading, setIsLoading] = useState(true);
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        if (context !== null) {
            const permissions = context?.permissions?.find(
                (x) => x.id === parseInt(accountId)
            );
            const actions = permissions?.actions;
            if (
                context.isRoot === false &&
                !actions?.some((x) => roles.includes(x)) &&
                !roles?.some((x) => x === 'any')
            ) {
                window.location.href = '/errors/403';
            } else {
                setIsLoading(false);
            }
            if (context.isRoot === false && permissions?.isBlocked) {
                setIsBlocked(true);
            }
        }
    }, [context, accountId]);

    return (
        <>
            {isLoading ? (
                <div className='row'>
                    <div className='col'>
                        <div className='loader' />
                    </div>
                </div>
            ) : isBlocked ? (
                <h3>
                    Аккаунт заблокирован. Для разблокировки обратитесь по номеру
                    телефона <a href='tel:+74957555552'>+7 (495) 755-55-52</a>
                </h3>
            ) : (
                props.children
            )}
        </>
    );
};

export default PermissionPage;
