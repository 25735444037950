import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';
import { addNotification } from './NotifySlice';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    groups: [],
};

export const getGroupsAsync = createAsyncThunk(
    'accountGroups/getGroups',
    async (accountId) => {
        const groupsResult = await Http.get(
            'api/groups?sort=name&accountId=' + accountId
        );

        return groupsResult.result;
    }
);

export const createGroupAsync = createAsyncThunk(
    'accountGroups/createGroup',
    async (group) => {
        const createResult = await Http.post('api/groups', true, group);
        return createResult.result;
    }
);

export const updateGroupAsync = createAsyncThunk(
    'accountGroups/updateGroup',
    async (group, thunkAPI) => {
        const updateeResult = await Http.put(
            'api/groups/' + group.id,
            true,
            group
        );
        if (updateeResult !== undefined && updateeResult != null) {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Группа сохранена!',
                    description: 'Данные группы успешно сохранены!',
                    type: 'success',
                })
            );
        } else {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description:
                        'Не удалось сохранить данные группы! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }

        return updateeResult.result;
    }
);

export const deleteGroupAsync = createAsyncThunk(
    'accountGroups/deleteGroup',
    async (id) => {
        await Http.delete('api/groups/' + id, true);

        return id;
    }
);

export const accountGroupsSlice = createSlice({
    name: 'accountGroups',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: {
        [getGroupsAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getGroupsAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.groups = action.payload;
            }
            state.isLoading = false;
        },
        [getGroupsAsync.rejected]: (state, action) => {
            console.error('getGroupsError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [createGroupAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.groups.push(action.payload);
            }
        },
        [createGroupAsync.rejected]: (state, action) => {
            console.error('createGroupError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [updateGroupAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                const updatedGroup = state.groups.map((item) => {
                    if (item.id === action.payload.id) {
                        item = action.payload;
                    }
                    return item;
                });
                state.groups = updatedGroup;
            }
        },
        [updateGroupAsync.rejected]: (state, action) => {
            console.error('updateGroupError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [deleteGroupAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                const deletedGroup = state.groups.filter(
                    (x) => x.id !== action.payload
                );
                state.groups = deletedGroup;
            }
        },
        [deleteGroupAsync.rejected]: (state, action) => {
            console.error('deleteGroupsError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const selectGroups = (state) => state.accountGroups.groups;
export const selectIsLoading = (state) => state.accountGroups.isLoading;

export default accountGroupsSlice.reducer;
