import { Link } from 'react-router-dom';
import PermissionPage from '../../../components/PermissionPage';
import ParkingDevicesPanel from './device/ParkingDevicesPanel';
import ParkingEditPanel from './ParkingEditPanel';

const ParkingCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const parkingId = props.match.params.parkingId;

    return (
        <PermissionPage accountId={accountId} roles={['parking_area_list']}>
            <h1 className='mb-5'>Карточка парковки</h1>
            <div className='row'>
                <div className='col-6'>
                    <ParkingEditPanel
                        accountId={accountId}
                        entityId={parkingId}
                    />
                </div>
                <div className='col-6'>
                    <div className='row mb-2'>
                        <div className='col'>
                            <ParkingDevicesPanel
                                accountId={accountId}
                                entityId={parkingId}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <div className='col'>
                            <div className='card'>
                                <div className='card-body pb-3'>
                                    <div className='row mb-3'>
                                        <div className='col-md-6 align-self-center'>
                                            <h3>Парковочные места</h3>
                                        </div>
                                    </div>
                                    <div className='d-grid gap-2'>
                                        <Link
                                            className='btn av-btn-simple'
                                            to={`/account/${accountId}/parking/${parkingId}/places`}
                                        >
                                            Октрыть
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default ParkingCardPage;
