import { useContext, useEffect, useState } from 'react';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { useHistory } from 'react-router-dom';
import MonitorCreateModal from './MonitorCreateModal';
import { useGetMonitorsQuery } from '../../app/storeReducers/AccountMonitorAPI';
import SecutiryContext from '../../features/securityContext';

const MonitorsList = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const { context } = useContext(SecutiryContext);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const { data, isLoading } = useGetMonitorsQuery(accountId);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('monitor_edit'));
    }, [context, accountId]);

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const openEntityCard = (entityId, e) => {
        e.stopPropagation();
        history.push(`/account/${accountId}/monitors/${entityId}/card`);
    };
    const openEntityMonitor = (entityId, e) => {
        e.stopPropagation();
        window.open(
            `/account/${accountId}/monitors/${entityId}/panel`,
            '_blank'
        );
    };

    return (
        <>
            <MonitorCreateModal
                accountId={accountId}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
                monitorsCount={data?.result?.length}
            />
            <div className='row'>
                <div className='col-md-3 mb-3'>
                    <button
                        className='btn av-btn-primary'
                        onClick={() => setCreateModalIsOpen(true)}
                        disabled={!canEdit}
                    >
                        <i className='fas fa-plus me-3' />
                        Новый монитор
                    </button>
                </div>
            </div>
            <div className='card border'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Название', '']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Название
                                    </th>
                                    <th className='border-bottom'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className='cursor-pointer'
                                    >
                                        <td className='ps-6'>{item.name}</td>
                                        <td>
                                            <label
                                                className='btn link-primary'
                                                onClick={(e) =>
                                                    openEntityMonitor(
                                                        item.id,
                                                        e
                                                    )
                                                }
                                            >
                                                <i className='fas fa-desktop'></i>{' '}
                                                Просмотр
                                            </label>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default MonitorsList;
