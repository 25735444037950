import { useEffect, useState } from "react";
import SkeletonSelect from "../../../components/skeleton/SkeletonSelect";
import { addNotification } from "../../../app/storeReducers/NotifySlice";
import { useDispatch } from "react-redux";
import { useGetSubaccountsQuery } from "../../../app/storeReducers/acountsApiSlice";
import { useProvideDeviceMutation } from "../../../app/storeReducers/devicesApiSlice";

const DeviceProvideModal = (props) => {
    const { accountId, deviceId, isOpen, okHandle, closeHandle } = props;

    const dispatch = useDispatch();

    const [accountSelected, setAccountsSelected] = useState({});

    const { data: accounts, isLoading } = useGetSubaccountsQuery(
        { accountId },
        {
            skip: !accountId,
        }
    );
    const [provideDeviceAsync] = useProvideDeviceMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setAccountsSelected({});
            if (accounts?.result?.length > 0) {
                setAccountsSelected(accounts.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [accounts]);

    const provideDevice = async () => {
        const object = { list: [deviceId] };
        const provide = await provideDeviceAsync({
            accountId: accountSelected,
            body: object,
        }).unwrap();
        if (provide.result) {
            const sharedAccount = accounts?.result?.find(
                (x) => x.id == accountSelected
            );
            okHandle(sharedAccount.description);
        } else {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: "Ошибка передачи устройства!",
                    description: provide.errorDescription,
                    type: "danger",
                })
            );
        }
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Передать устройство аккаунту
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-4">
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label="Аккаунты"
                                    value={accountSelected}
                                    onChange={(e) =>
                                        setAccountsSelected(e.target.value)
                                    }
                                    itemsArray={
                                        accounts?.result?.map((item) => ({
                                            id: item.id,
                                            name: item.id,
                                            description: item.name,
                                        })) || []
                                    }
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={provideDevice}
                            >
                                Передать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default DeviceProvideModal;
