import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectIsAuthorize,
    loginUserAsync,
    getPhoneCodeAsync,
} from '../app/storeReducers/CurrentUserSlice';
import { Link } from 'react-router-dom';
import { Http } from '../http';
import InputMask from 'react-input-mask';

const RegistrationPage = (props) => {
    const dispatch = useDispatch();

    const isAuthorize = useSelector(selectIsAuthorize);
    const errorDescription = useSelector(
        (state) => state.currentUser.errorDescription
    );
    const errorType = useSelector((state) => state.currentUser.errorType);
    const isSendCode = useSelector((state) => state.currentUser.isSendCode);

    const [login, setLogin] = useState('');
    const [phoneCode, setPhoneCode] = useState('');
    const [passNew, setPassNew] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [isPassError, setIsPassError] = useState(false);
    const [passError, setPassError] = useState('');
    const [isPassCompareError, setIsPassCompareError] = useState(false);

    const handleGetCode = async () => {
        dispatch(getPhoneCodeAsync(login));
    };

    const handelConfirmCode = async () => {
        const authParam = {
            auth: { login, code: phoneCode.replace('-', '') },
            typeLogin: 2,
        };
        dispatch(loginUserAsync(authParam));
    };

    const handleChangePassword = async () => {
        setIsPassError(false);
        if (passNew !== confirmPass) {
            setIsPassCompareError(true);
            setPassError('Пароли не совпадают');
        } else if (
            !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}$/.test(
                passNew
            )
        ) {
            setIsPassCompareError(false);
            setIsPassError(true);
            setPassError(
                'Пароль должен быть больше 8 символов, иметь хотя бы одну цифру, а так же большую и маленькую букву!'
            );
        } else {
            await Http.post('api/auth/change_password', true, {
                password: passNew,
            });
            window.location.href = '/personal/overview';
        }
    };

    return (
        <div className='row ms-0 me-0'>
            <div
                className='col-md-6 login-left-block d-flex flex-column justify-content-between'
                style={{
                    backgroundImage: 'url(/images/login-background.png)',
                }}
            >
                <div>
                    <img
                        src='/images/logo-inverted.svg'
                        className='h-30px'
                        alt='avanguard logo'
                    />
                    <p className='login-left-block-text'>
                        Облачная система контроля
                        <br />и управления доступом
                    </p>
                </div>
            </div>
            <div className='col-md-6 login-right-block d-flex justify-content-between flex-column'>
                <div></div>
                <div className='row'>
                    <div className='col-lg-8 offset-lg-2'>
                        <form>
                            <div className='pb-5 pt-lg-0'>
                                <h1 className='font-size-h1-lg fw-boldest'>
                                    {props.isForgot
                                        ? 'Сброс пароля'
                                        : 'Регистрация'}
                                </h1>
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Номер телефона</label>
                                <InputMask
                                    mask='+7(999)999-99-99'
                                    className={
                                        'form-control' +
                                        (errorType === 'invalid_login' ||
                                        errorType ===
                                            'invalid_username_or_password'
                                            ? ' is-invalid'
                                            : '')
                                    }
                                    id='login'
                                    value={login}
                                    onChange={(e) => setLogin(e.target.value)}
                                    placeholder='+7(___)___-__-__'
                                    disabled={isSendCode}
                                />
                                <div className='invalid-feedback'>
                                    {errorDescription}
                                </div>
                            </div>
                            {!isSendCode ? (
                                <div className='d-grid'>
                                    <button
                                        className='btn av-btn-primary'
                                        onClick={handleGetCode}
                                        type='button'
                                    >
                                        Получить код по СМС
                                    </button>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div className='form-group mb-5'>
                                        <label htmlFor='phone-code'>
                                            Код из СМС
                                        </label>
                                        <InputMask
                                            mask='999-999'
                                            className={
                                                'form-control' +
                                                (errorType ===
                                                'invalid_verification_code'
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            id='phone-code'
                                            value={phoneCode}
                                            onChange={(e) =>
                                                setPhoneCode(e.target.value)
                                            }
                                            placeholder='___-___'
                                            disabled={isAuthorize}
                                        />
                                        <div className='invalid-feedback'>
                                            {errorDescription}
                                        </div>
                                    </div>
                                    <div className='d-grid mb-5'>
                                        <button
                                            className='btn av-btn-primary'
                                            onClick={handelConfirmCode}
                                            type='button'
                                            disabled={isAuthorize}
                                        >
                                            Подтвердить
                                        </button>
                                    </div>
                                </React.Fragment>
                            )}
                            {isAuthorize ? (
                                <React.Fragment>
                                    <div className='form-group mb-5'>
                                        <label htmlFor='passNew'>Пароль</label>
                                        <input
                                            type='password'
                                            className={
                                                'form-control' +
                                                (isPassError ||
                                                isPassCompareError
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            id='passNew'
                                            value={passNew}
                                            onChange={(e) =>
                                                setPassNew(e.target.value)
                                            }
                                        />
                                        <div className='invalid-feedback'>
                                            {passError}
                                        </div>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label htmlFor='confirmPass'>
                                            Повторить пароль
                                        </label>
                                        <input
                                            type='password'
                                            className={
                                                'form-control' +
                                                (isPassCompareError
                                                    ? ' is-invalid'
                                                    : '')
                                            }
                                            id='confirmPass'
                                            value={confirmPass}
                                            onChange={(e) =>
                                                setConfirmPass(e.target.value)
                                            }
                                        />
                                        <div className='invalid-feedback'>
                                            {passError}
                                        </div>
                                    </div>
                                    <div className='d-grid'>
                                        <button
                                            className='btn av-btn-primary'
                                            onClick={handleChangePassword}
                                            type='button'
                                        >
                                            Задать пароль
                                        </button>
                                    </div>
                                </React.Fragment>
                            ) : null}
                        </form>
                        <div className='row mt-10'>
                            <div className='col text-center'>
                                Уже зарегистрированны?{' '}
                                <Link
                                    to={`/login`}
                                    className='btn-link av-text-primary fw-bolder'
                                >
                                    Вход
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex justify-content-between'>
                            <div>© Связь-холдинг, 2022</div>
                            <div>
                                <a
                                    href='mailto:help@avanguard.pro'
                                    className='text-gray-700'
                                >
                                    <i className='far fa-envelope pe-2' />
                                    help@avanguard.pro
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationPage;
