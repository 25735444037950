import AdminDevicesList from './AdminDevicesList';

const AdminDevicesPage = (props) => {
    return (
        <>
            <div className='row mt-4 mb-4'>
                <h1 className='mb-5'>Устройства</h1>
                <div className='col-md-12'>
                    <AdminDevicesList />
                </div>
            </div>
        </>
    );
};

export default AdminDevicesPage;
