import { FormatDateToHuman, UtcToLocal } from '../../helpers/DateHelpers';
import { baseApi } from '../baseApi';

export const NotificationsAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: ({ accountId = 0, onlyNew = false, pageIndex = 1, pageSize = 100, rnd = 0 }) =>
                `notifications/web?accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}&isNew=${onlyNew}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Notifications',
                              id,
                          })),
                          { type: 'Notifications', id: 'List' },
                      ]
                    : [{ type: 'Notifications', id: 'List' }],
            transformResponse: (response) => {
                response?.result.forEach((item, index) => {
                    item.dtString = FormatDateToHuman(UtcToLocal(item.time), true);
                    item.accountTitle = item.account.title;
                });
                return response;
            },
        }),

        readNotification: builder.mutation({
            query: (id) => ({
                url: 'notifications/web/read/' + id,
                method: 'PUT',
                body: null,
            }),
            invalidatesTags: [{ type: 'Notifications', id: 'List' }],
        }),

        readAllNotification: builder.mutation({
            query: () => ({
                url: 'notifications/web/read/all',
                method: 'PUT',
            }),
            invalidatesTags: [{ type: 'Notifications', id: 'List' }],
        }),
        getNotificationSettings: builder.query({
            query: (accountId) => 'notifications/settings?accountId=' + (accountId ?? ''),
            providesTags: [{ type: 'NotificationSettnigs', id: 'List' }],
        }),
        updateNotificationSettings: builder.mutation({
            query: (body) => ({
                url: 'notifications/settings',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'NotificationSettnigs', id: 'List' }],
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useReadNotificationMutation,
    useReadAllNotificationMutation,
    useGetNotificationSettingsQuery,
    useUpdateNotificationSettingsMutation,
} = NotificationsAPI;
