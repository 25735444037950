import { baseApi } from '../baseApi';

export const authApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCurrentUser: builder.query({
            query: () => 'profile',
        }),
        updateCurrentUser: builder.mutation({
            query: (body) => ({
                url: 'profile',
                method: 'PUT',
                body,
            }),
        }),
    }),
});

export const { useGetCurrentUserQuery, useLazyGetCurrentUserQuery, useUpdateCurrentUserMutation } = authApiSlice;
