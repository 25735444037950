import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../../http';
import EmployeeAddRoleModal from './EmployeeAddRoleModal';
import EmployeeRoleDeleteModal from './EmployeeRoleDeleteModal';
import SecutiryContext from '../../../../features/securityContext';

const EmployeeRolesPanel = (props) => {
    const { accountId, employeeId } = props;

    const { context } = useContext(SecutiryContext);

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [deleteRole, setDeleteRole] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [employeeId, canEdit]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('employees_set_role'));
        setCanList(context.isRoot || actions?.includes('employees_list'));
    }, [context, accountId]);

    const loadData = async () => {
        setIsLoading(true);
        const getResult = await Http.get(
            `api/roles?accountId=${accountId}&employeeId=${employeeId}`,
            true
        );
        setRoles(getResult.result || []);
        setIsLoading(false);
    };

    const okAddRole = () => {
        loadData();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (role) => {
        setDeleteRole(role);
        setDeleteModalIsOpen(true);
    };

    const deleteEmpRole = () => {
        loadData();
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <EmployeeAddRoleModal
                accountId={accountId}
                employeeId={employeeId}
                isOpen={addModalIsOpen}
                okHandle={okAddRole}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <EmployeeRoleDeleteModal
                employeeId={employeeId}
                role={deleteRole}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteEmpRole}
            />
            <div className='row mb-5'>
                <div className='col-md-6'>
                    <div className='av-card-title'>
                        Роли
                        <span className='badge rounded-pill bg-light text-dark ms-2 align-text-top'>
                            {roles.length}
                        </span>
                    </div>
                </div>
                <div className='col-md-6 text-end'>
                    {canEdit ? (
                        <button
                            className='btn btn-sm btn-light-primary fw-boldest av-text-gray-600 pe-0 py-1'
                            onClick={() => setAddModalIsOpen(true)}
                        >
                            <img
                                src='/media/icons/avanguard/plus-gray-500.png'
                                className='me-2 align-top'
                                height={20}
                            />
                            Выбрать роли
                        </button>
                    ) : null}
                </div>
            </div>
            <div className='row mb-10'>
                <div className='col'>
                    {canList ? (
                        roles.map((item) => (
                            <div
                                className='border av-border-gray-300 rounded d-inline-block px-4 py-2 me-2 mb-2 fw-bolder'
                                key={item.id}
                            >
                                {item.name}{' '}
                                {canEdit ? (
                                    <img
                                        src='/media/icons/avanguard/x-gray-400.png'
                                        className='ms-2 cursor-pointer align-top'
                                        onClick={() => openDeleteModal(item)}
                                        height={20}
                                    />
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                </div>
            </div>
        </>
    );
};

export default EmployeeRolesPanel;
