import React, { useEffect, useRef } from 'react';
import { Tooltip } from 'bootstrap';

export const BsTooltip = (props) => {
    const childRef = useRef(undefined);

    useEffect(() => {
        const t = new Tooltip(childRef.current, {
            title: props.text,
            trigger: 'hover',
        });
        return () => t.dispose();
    }, [props.text]);

    return React.cloneElement(props.children, { ref: childRef });
};
