import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonInput from "../../../components/skeleton/SkeletonInput";
import ParkingAreaDeleteModal from "../ParkingAreaDeleteModal";
import { useHistory } from "react-router";
import { selectContext } from "../../../app/storeReducers/AuthSlice";
import {
    useGetParkingAreaByIdQuery,
    useUpdateParkingAreaMutation,
} from "../../../app/storeReducers/parkingAreasApiSlice";

const ParkingEditPanel = (props) => {
    const { accountId, entityId } = props;

    const history = useHistory();

    const [name, setName] = useState("");
    const [isForGuest, setIsForGuest] = useState(false);
    const [capacity, setCapacity] = useState(0);

    const context = useSelector(selectContext);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { data: parkingArea, isLoading } = useGetParkingAreaByIdQuery(
        entityId,
        { skip: !entityId }
    );
    const [updateParkingArea] = useUpdateParkingAreaMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (!parkingArea) {
                return;
            }
            setName(parkingArea.result.name);
            setIsForGuest(parkingArea.result.allowForGuest);
            setCapacity(parkingArea.result.capacity);
        }

        loadResourcesAndDataAsync();
    }, [parkingArea]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("parking_area_edit"));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            account: { id: accountId },
            allowForGuest: isForGuest,
            id: entityId,
            capacity,
        };
        await updateParkingArea(entity);
    };

    const deleteParking = () => {
        history.push(`/account/${accountId}/parking`);
    };

    return (
        <>
            <ParkingAreaDeleteModal
                entity={{ id: entityId, name }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                deleteHandle={deleteParking}
            />
            <div className="card border">
                <div className="card-body pb-3">
                    <div className="row mb-3">
                        <div className="col-md-6 align-self-center">
                            <h3>Парковка</h3>
                        </div>
                    </div>
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="name"
                            label="Название"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="form-group form-check mb-4">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="isForGuest"
                            checked={isForGuest}
                            onChange={(e) => setIsForGuest(e.target.checked)}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="isForGuest"
                        >
                            Разрешена для посетителей
                        </label>
                    </div>
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="capacity"
                            label="Вместимость"
                            value={capacity}
                            onChange={(e) => setCapacity(e.target.value)}
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <button
                                className="btn av-btn-danger fw-boldest"
                                onClick={() => setDeleteModalIsOpen(true)}
                            >
                                <i className="far fa-trash-alt me-2" /> Удалить
                            </button>
                        </div>
                        <div className="col-md-6 text-end">
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ParkingEditPanel;
