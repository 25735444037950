import { baseApi } from "../baseApi";

export const schemeScheduleApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSchemeSchedules: builder.query({
            query: (query) =>
                `schemes/schedules?accountId=${query.accountId ?? ""}&sort=${
                    query?.sort ?? ""
                }&pageIndex=${query?.pageIndex ?? 1}&pageSize=${
                    query?.pageSize ?? 50
                }`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "SchemeSchedules",
                              id,
                          })),
                          { type: "SchemeSchedules", id: "List" },
                      ]
                    : [{ type: "SchemeSchedules", id: "List" }],
        }),
        getSchemeScheduleById: builder.query({
            query: (id) => `schemes/schedules/${id}`,
            providesTags: (result) => [
                { type: "SchemeSchedule", id: result?.result.id },
            ],
        }),
        createSchemeSchedule: builder.mutation({
            query: (body) => ({
                url: "schemes/schedules",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "SchemeSchedules", id: "List" }],
        }),
        updateSchemeSchedule: builder.mutation({
            query: (body) => ({
                url: "schemes/schedules/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "SchemeSchedules", id: "List" },
                { type: "SchemeSchedule", id: result?.result.id },
            ],
        }),
        deleteSchemeSchedule: builder.mutation({
            query: (id) => ({
                url: "schemes/schedules/" + id,
                method: "DELETE",
                body: undefined,
            }),
            invalidatesTags: [{ type: "SchemeSchedules", id: "List" }],
        }),
    }),
});

export const {
    useGetSchemeSchedulesQuery,
    useLazyGetSchemeSchedulesQuery,
    useGetSchemeScheduleByIdQuery,
    useCreateSchemeScheduleMutation,
    useUpdateSchemeScheduleMutation,
    useDeleteSchemeScheduleMutation,
} = schemeScheduleApiSlice;
