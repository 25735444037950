import { useEffect, useState } from 'react';
import { Http } from '../../http';
import {
    createAsync,
    updateAsync,
} from '../../app/storeReducers/AccountDevicesSlice';
import { useDispatch } from 'react-redux';
import { MakeID } from '../../helpers/StringHelper';
import { useGetSchedulesQuery } from '../../app/storeReducers/SchedulesAPI';

const MqttDeviceCreateModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle, locations } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [uid, setUid] = useState(MakeID(8));
    const [mode, setMode] = useState('door-magneto');
    const [openTimeout, setOpenTimeout] = useState(0);
    const [gercon, setGercon] = useState(false);
    const [beeper, setBeeper] = useState(false);
    const [emergencyInput, setEmergencyInput] = useState('none');
    const [readerType, setReaderType] = useState('wiegand26');
    const [managedByMobile, setManagedByMobile] = useState(false);
    const [timeTracking, setTimeTracking] = useState(false);
    const [isCardPicker, setIsCardPicker] = useState(false);
    const [locationId, setLocationId] = useState(0);
    const [peerIp, setPeerIp] = useState('');
    const [apiPassword, setApiPassword] = useState('');
    const [defaultDirection, setDefaultDirection] = useState(true);
    const [scheduleId, setScheduleId] = useState(0);

    const { data: schedules, isLoading } = useGetSchedulesQuery({ accountId });

    useEffect(() => {
        if (entityId <= 0) {
            setName('');
            setUid(MakeID(8));
            setMode('door-magneto');
            setOpenTimeout(0);
            setGercon(false);
            setBeeper(false);
            setEmergencyInput('none');
            setReaderType('wiegand26');
            setManagedByMobile(false);
            setTimeTracking(false);
            setIsCardPicker(false);
            setLocationId(0);
            setPeerIp('');
            setApiPassword('');
            setDefaultDirection(true);
            setScheduleId(0);
        }
    }, [isOpen]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== undefined && entityId !== 0 && entityId !== -1) {
                const getResult = await Http.get(
                    'api/devices/' + entityId,
                    true
                );
                setName(getResult.result?.name);
                setUid(getResult.result?.uid);
                setMode(getResult.result?.mode);
                setReaderType(getResult.result?.readerType);
                setManagedByMobile(getResult.result?.managedByMobile);
                setTimeTracking(getResult.result?.timeTracking);
                setIsCardPicker(getResult.result?.isCardPicker);
                setOpenTimeout(getResult.result?.openTimeout);
                setGercon(getResult.result?.gercon);
                setBeeper(getResult.result?.beeper);
                setEmergencyInput(getResult.result?.emergencyInput);
                setLocationId(getResult.result?.location?.id);
                setPeerIp(getResult.result?.peer_ip);
                setApiPassword(getResult.result?.apiPassword);
                setDefaultDirection(getResult.result?.defaultDirection);
                setScheduleId(getResult.result?.schedule?.id ?? 0);
            } else {
                setName('');
                setUid(MakeID(8));
                setMode('door-magneto');
                setOpenTimeout(0);
                setGercon(false);
                setBeeper(false);
                setEmergencyInput('none');
                setReaderType('wiegand26');
                setManagedByMobile(false);
                setTimeTracking(false);
                setIsCardPicker(false);
                setPeerIp('');
                setApiPassword('');
                setDefaultDirection(true);
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    const saveEntity = async () => {
        const entity = {
            provider: 'mqtt',
            name,
            mode,
            type: 'door',
            uid,
            account: { id: accountId },
            openTimeout,
            gercon,
            beeper,
            emergencyInput,
            id: entityId,
            readerType,
            managedByMobile,
            timeTracking,
            isCardPicker,
            location: { id: locationId },
            peer_ip: peerIp,
            peer_port: 0,
            apiPassword,
            defaultDirection,
            schedule: { id: scheduleId },
        };
        if (entityId > 0) {
            dispatch(updateAsync(entity));
        } else {
            dispatch(createAsync(entity));
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                {entityId > 0
                                    ? 'Редактировать'
                                    : 'Добавить новое'}{' '}
                                устройство
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body pb-2'>
                            <div className='row mb-3'>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>
                                            Название устройства
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='name'
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='uid'>
                                            UID - идентификатор устройства
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='uid'
                                            value={uid}
                                            onChange={(e) =>
                                                setUid(e.target.value)
                                            }
                                            disabled={entityId > 0}
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Направление</label>
                                        <select
                                            className='form-select'
                                            value={defaultDirection}
                                            onChange={(e) =>
                                                setDefaultDirection(
                                                    e.target.value
                                                )
                                            }
                                            style={{
                                                paddingTop: 7,
                                                paddingBottom: 7,
                                            }}
                                        >
                                            <option value={true}>Вход</option>
                                            <option value={false}>Выход</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Объект</label>
                                        <select
                                            className='form-select'
                                            value={locationId}
                                            onChange={(e) =>
                                                setLocationId(e.target.value)
                                            }
                                        >
                                            {locations?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Открытие по расписанию</label>
                                        <select
                                            className='form-select'
                                            value={scheduleId}
                                            onChange={(e) =>
                                                setScheduleId(e.target.value)
                                            }
                                        >
                                            <option value={0}>
                                                Не установлено
                                            </option>
                                            {schedules?.result?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col mb-2'>
                                    <div className='form-check form-check-inline form-group'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id='managedByMobile1'
                                            checked={managedByMobile}
                                            onChange={(e) =>
                                                setManagedByMobile(
                                                    e.target.checked
                                                )
                                            }
                                        />
                                        <label
                                            className='form-check-label'
                                            htmlFor='managedByMobile1'
                                        >
                                            Управление через мобильное
                                            приложение
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer py-2'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Отменить
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                {entityId > 0
                                    ? 'Сохранить изменения'
                                    : 'Добавить устройство'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default MqttDeviceCreateModal;
