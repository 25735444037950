import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import ScheduleCreateModal from "./ScheduleCreateModal";
import Paginator from "../../components/Paginator";
import { useHistory } from "react-router-dom";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetSchemeSchedulesQuery } from "../../app/storeReducers/schemeScheduleApiSlice";

const colNames = ["Название", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

const SchedulesList = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);

    const context = useSelector(selectContext);

    const { data: entityList, isLoading } = useGetSchemeSchedulesQuery({
        accountId,
        pageIndex,
        pageSize,
    });

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("schemes_edit"));
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
        setPageIndex(1);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(
                `/account/${accountId}/admin/schemeSchedules/${entityId}`,
                "_blank"
            );
        else
            history.push(
                `/account/${accountId}/admin/schemeSchedules/${entityId}`
            );
    };

    return (
        <>
            <ScheduleCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className="row">
                <div className="col-md-4 mb-3">
                    <button
                        className="btn av-btn-primary fw-boldest"
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className="fas fa-plus me-3" />
                        Новое расписание
                    </button>
                </div>
            </div>
            <div className="card border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={["Название", ""]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    {colNames.map((item) => (
                                        <th
                                            className="border-bottom"
                                            key={item}
                                        >
                                            {item}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className="cursor-pointer"
                                    >
                                        <td>{item.name}</td>
                                        <td>
                                            {item.day1From} -{item.day1To}
                                        </td>
                                        <td>
                                            {item.day2From} -{item.day2To}
                                        </td>
                                        <td>
                                            {item.day3From} -{item.day3To}
                                        </td>
                                        <td>
                                            {item.day4From} -{item.day4To}
                                        </td>
                                        <td>
                                            {item.day5From} -{item.day5To}
                                        </td>
                                        <td>
                                            {item.day6From} -{item.day6To}
                                        </td>
                                        <td>
                                            {item.day7From} -{item.day7To}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className="card-body py-3">
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={entityList?.paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList?.result.length}
                        countTotal={entityList?.paginator.totalCount}
                    />
                </div>
            </div>
        </>
    );
};

export default SchedulesList;
