import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
    selectPaginator,
} from '../../app/storeReducers/AccountSchemesSlice';
import SchemeDeleteModal from './SchemeDeleteModal';
import { KTSVG } from '../../_metronic/helpers';
import SchemeCreateModal from './SchemeCreateModal';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import Paginator from '../../components/Paginator';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import FindComponent from '../../components/FindComponent';
import SecutiryContext from '../../features/securityContext';

const SchemesList = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);
    const paginator = useSelector(selectPaginator);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [searchString, setSearchString] = useState('');

    const { context } = useContext(SecutiryContext);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(
                getListAsync({
                    accountId,
                    searchString,
                    pageSize,
                    pageIndex,
                })
            );
        }

        loadResourcesAndDataAsync();
    }, [dispatch, accountId, searchString, pageIndex, pageSize]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('schemes_edit'));
    }, [context, accountId]);

    useEffect(() => {
        //init tooltip
        Array.from(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    }, [entityList]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(
                `/account/${accountId}/admin/schemes/${entityId}`,
                '_blank'
            );
        else history.push(`/account/${accountId}/admin/schemes/${entityId}`);
    };

    return (
        <>
            <SchemeCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className='row'>
                <div className='col-md-4 mb-3'>
                    {canEdit ? (
                        <button
                            className='btn av-btn-primary fw-boldest'
                            onClick={() => openCreateModal(0)}
                        >
                            <i className='fas fa-plus' /> Новая схема
                        </button>
                    ) : null}
                </div>
                <div className='col-md-8 mb-3 d-flex justify-content-end'>
                    <FindComponent
                        callback={(name) => setSearchString(name)}
                        placeholder='Поиск по схемам'
                    />
                </div>
            </div>
            <div className='card mb-4 border'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Название', 'Владелец']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped gy-7 gs-7'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Название
                                    </th>
                                    <th className='border-bottom ps-6'>
                                        Владелец
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className='cursor-pointer'
                                    >
                                        <td className='ps-6 fw-bolder align-middle'>
                                            {item.name}{' '}
                                            {item.isForGuest ? (
                                                <span
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title={
                                                        'Схема разрешена для посетителей'
                                                    }
                                                >
                                                    <KTSVG
                                                        path='/media/icons/avanguard/visitors1.svg'
                                                        className='svg-icon-3 ms-3'
                                                    />
                                                </span>
                                            ) : null}
                                            {item.shared ? (
                                                <i
                                                    className='ms-3 fas fa-share-alt text-muted'
                                                    data-bs-toggle='tooltip'
                                                    data-bs-placement='top'
                                                    title={
                                                        'Схема предоставлена другому аккаунту'
                                                    }
                                                />
                                            ) : null}
                                        </td>
                                        <td className='align-middle'>
                                            {item.account?.title}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className='card-body py-3 px-5'>
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList.length}
                        countTotal={paginator.totalCount}
                    />
                </div>
            </div>
        </>
    );
};

export default SchemesList;
