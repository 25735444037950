import PermissionPage from '../../../components/PermissionPage';
import TariffEditPanel from './TariffEditPanel';

const TariffCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const tariffId = props.match.params.tariffId;

    return (
        <PermissionPage accountId={accountId} roles={['tariff_list']}>
            <h1 className='mb-5'>Карточка тарифа</h1>
            <div className='row'>
                <div className='col-6'>
                    <TariffEditPanel
                        accountId={accountId}
                        entityId={tariffId}
                    />
                </div>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col'></div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default TariffCardPage;
