import { useEffect, useState } from 'react';
import DeviceWidget from './DeviceWidget';
import { Http } from '../../../http';
import AccountWidget from './AccountWidget';
import HostWidget from './HostWidget';
import SupportWidget from './SupportWidget';

const DashboardList = (props) => {
    const [summary, setSummary] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const response = await Http.get(`api/admin/summary`);
            if (response.result !== undefined) setSummary(response.result);
        }

        loadResourcesAndDataAsync();
    }, []);

    return (
        <div className='row g-5 g-xl-8'>
            <div className='col-md-6'>
                <DeviceWidget
                    deviceCountTotal={summary.devices_count_total}
                    deviceCountOnline={
                        (summary?.devices_count_total ?? 0) -
                        (summary?.devices_count_offline ?? 0)
                    }
                    deviceCountOffline={summary.devices_count_offline}
                />
                <AccountWidget
                    accountCountTotal={summary.accounts_count_total}
                    accountCountOnline={
                        (summary?.accounts_count_total ?? 0) -
                        (summary?.accounts_count_banned ?? 0)
                    }
                    accountCountOffline={summary.accounts_count_banned}
                />
                <HostWidget
                    hostCountTotal={summary.hosts_count_total}
                    hostCountOnline={
                        (summary?.hosts_count_total ?? 0) -
                        (summary?.hosts_count_offline ?? 0)
                    }
                    hostCountOffline={summary.hosts_count_offline}
                />
                <SupportWidget
                    supportCountTotal={summary.support_count_total}
                    supportCountNew={summary.support_count_new}
                    supportCountOpen={summary.support_count_open}
                />
            </div>
        </div>
    );
};

export default DashboardList;
