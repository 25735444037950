import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectIsAuthorize,
    loginUserAsync,
    getCurrentUserAsync,
    setIsLoading,
} from '../app/storeReducers/CurrentUserSlice';
import { Link, useHistory } from 'react-router-dom';
import WaitLoading from '../components/WaitLoading';
import InputMask from 'react-input-mask';

const DemoPage = (props) => {
    const history = useHistory();

    const isLoading = useSelector((state) => state.currentUser.isLoading);
    const isAuthorize = useSelector(selectIsAuthorize);
    const user = useSelector((state) => state.currentUser.user);
    const errorDescription = useSelector(
        (state) => state.currentUser.errorDescription
    );
    const errorType = useSelector((state) => state.currentUser.errorType);
    const dispatch = useDispatch();

    const loginHandle = () => {
        const loginParams = {
            auth: {
                login: '70000000000',
                password: '123456',
            },
            typeLogin: 1,
        };
        dispatch(loginUserAsync(loginParams));
    };


    useEffect(() => {
        let redirectUrl = '/personal/overview';
        if (props.location.state !== undefined) {
            redirectUrl = props.location.state.from;
        }
        if (isAuthorize && user.length === 0) {
            dispatch(getCurrentUserAsync());
        } else {
            dispatch(setIsLoading(false));
        }
        if (user !== undefined && user != null && user.login !== undefined) {
            history.push(redirectUrl);
        }
        loginHandle();        
        history.push('/personal/overview');
    });

    return (
        <WaitLoading isLoading={isLoading}>
        </WaitLoading>
    );
};

export default DemoPage;
