import React from 'react';
import { deleteEmployeeAsync } from '../../app/storeReducers/AccountEmployeesSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

const EmployeeDeleteModal = (props) => {
    const { employee, isOpen, closeHandle } = props;
    const history = useHistory();

    const dispatch = useDispatch();

    const deleteEmployee = async () => {
        dispatch(deleteEmployeeAsync(employee.id));
        history.push(`/account/${employee.account.id}/employees`);
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Удалить сотрудника</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Вы действительно хотите удалить сотрудника{' '}
                                <strong className='text-danger'>
                                    {employee?.name}
                                </strong>
                            </p>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-danger'
                                onClick={deleteEmployee}
                            >
                                <i className='far fa-trash-alt me-2' /> Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default EmployeeDeleteModal;
