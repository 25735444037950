import { useEffect, useState } from "react";
import { useCreateCameraMutation } from "../../app/storeReducers/camerasApiSlice";

const CameraCreateModal = (props) => {
    const { accountId, isOpen, closeHandle, cloneEntity } = props;

    const [protocol, setProtocol] = useState("rtsp");
    const [name, setName] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [address, setAddress] = useState("");
    const [port, setPort] = useState(0);
    const [path, setPath] = useState("");
    const [direction, setDirection] = useState("none");

    const [createAsync] = useCreateCameraMutation();

    useEffect(() => {
        if (cloneEntity !== undefined) {
            setName(cloneEntity["name"] + " - копия");
            setUserName(cloneEntity["username"]);
            setPassword(cloneEntity["password"]);
            setAddress(cloneEntity["address"]);
            setPort(cloneEntity["port"]);
            setPath(cloneEntity["path"]);
            setProtocol(cloneEntity["protocol"]);
            setDirection(cloneEntity["direction"]);
        } else {
            setName("");
            setUserName("");
            setPassword("");
            setAddress("");
            setPort(0);
            setPath("");
            setProtocol("rtsp");
            setDirection("none");
        }
    }, [isOpen]);

    const saveEntity = async () => {
        const entity = {
            name,
            protocol,
            userName,
            password,
            address,
            port,
            path,
            account: { id: accountId },
            direction,
        };
        await createAsync(entity);
        setName("");
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить новую камеру
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 form-group">
                                <label>Протокол</label>
                                <select
                                    className="form-select"
                                    value={protocol}
                                    onChange={(e) =>
                                        setProtocol(e.target.value)
                                    }
                                >
                                    {[
                                        {
                                            key: "rtsp",
                                            value: "RTSP",
                                        },
                                        {
                                            key: "http",
                                            value: "HTTP",
                                        },
                                    ].map((item) => (
                                        <option key={item.key} value={item.key}>
                                            {item.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 form-group">
                                <label htmlFor="name">Название</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3 form-group">
                                <label>Направление</label>
                                <select
                                    className="form-select"
                                    value={direction}
                                    onChange={(e) =>
                                        setDirection(e.target.value)
                                    }
                                >
                                    {[
                                        {
                                            key: "none",
                                            value: "-- Не выбрано --",
                                        },
                                        {
                                            key: "incoming",
                                            value: "Вход",
                                        },
                                        {
                                            key: "outgoing",
                                            value: "Выход",
                                        },
                                    ].map((item) => (
                                        <option key={item.key} value={item.key}>
                                            {item.value}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3 form-group">
                                <label htmlFor="userName">
                                    Имя пользователя
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="userName"
                                    value={userName}
                                    onChange={(e) =>
                                        setUserName(e.target.value)
                                    }
                                />
                            </div>
                            <div className="mb-3 form-group">
                                <label htmlFor="password">Пароль</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col-md-8 form-group">
                                        <label htmlFor="address">Адрес</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="address"
                                            value={address}
                                            onChange={(e) =>
                                                setAddress(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="col-md-4 form-group">
                                        <label htmlFor="port">Порт</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="port"
                                            value={port}
                                            onChange={(e) =>
                                                setPort(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="mb-3 form-group">
                                <label htmlFor="path">Путь</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="path"
                                    value={path}
                                    onChange={(e) => setPath(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default CameraCreateModal;
