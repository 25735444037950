import { useRemoveSchemeDeviceMutation } from "../../../../app/storeReducers/schemesApiSlice";

const SchemeDeviceDeleteModal = (props) => {
    const { schemeId, device, isOpen, closeHandle, okHandle } = props;

    const [deleteDeviceAsync] = useRemoveSchemeDeviceMutation();

    const deleteDevice = async () => {
        await deleteDeviceAsync({ schemeId, deviceId: device.id });
        okHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Удалить устройство в схеме
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Вы действительно хотите удалить устройство{" "}
                                <strong className="text-danger">
                                    {device.name}
                                </strong>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-danger"
                                onClick={deleteDevice}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default SchemeDeviceDeleteModal;
