import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { logoutUser } from "./storeReducers/CurrentUserSlice";
import { addNotification } from "./storeReducers/NotifySlice";

let isRefreshing = false; // Flag to indicate if token refresh is in progress
let refreshPromise = null; // To hold the refresh token promise

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PLATFORM_URL + "api/",
    credentials: "include",
    prepareHeaders: (headers) => {
        const token = localStorage.getItem("access_token");
        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        headers.set("Accept", "application/json");
        headers.set("Cache-Control", "no-cache");
        headers.set("Pragma", "no-cache");
        headers.set("Expires", "0");
        return headers;
    },
});
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result?.error?.status !== 401) {
        const errorMessage =
            result.error.data?.toString() || "Произошла ошибка";
        api.dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: "Ошибка!",
                description: errorMessage,
                type: "danger",
            })
        );
    }
    if (result?.error?.status === 401) {
        if (!isRefreshing) {
            isRefreshing = true;
            refreshPromise = Promise.resolve(
                baseQuery(
                    {
                        url: `auth/refresh_token`,
                        method: "POST",
                        body: {
                            refresh_token:
                                localStorage.getItem("refresh_token"),
                        },
                    },
                    api,
                    extraOptions
                )
            )
                .then((refreshResult) => {
                    const response = refreshResult?.data;
                    if (refreshResult.data && response.error === undefined) {
                        // Store the new tokens
                        localStorage.setItem(
                            "access_token",
                            response.result.access_token.value
                        );
                        localStorage.setItem(
                            "access_token_time",
                            response.result.access_token.expireAt.toString()
                        );
                        localStorage.setItem(
                            "refresh_token",
                            response.result.refresh_token.value
                        );
                        localStorage.setItem(
                            "refresh_token_time",
                            response.result.refresh_token.expireAt.toString()
                        );
                        isRefreshing = false; // Reset the flag

                        return response.result;
                    } else {
                        // Handle refresh error
                        isRefreshing = false; // Ensure flag is reset for future requests
                        api.dispatch(logoutUser());
                        window.location.reload();

                        return null;
                    }
                })
                .catch((error) => {
                    console.error("Error refreshing token", error);
                    isRefreshing = false; // Reset flag on error
                });
        }
        await refreshPromise; // Wait for the refresh token request to complete
        result = await baseQuery(args, api, extraOptions);
    }
    return result;
};

export const baseApi = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: [
        "Monitors",
        "Monitor",
        "MonitorDevices",
        "Devices",
        "Device",
        "Notifications",
        "NotificationSettnigs",
        "Vehicles",
        "Vehicle",
        "Schedules",
        "Schedule",
        "ScheduleDetails",
        "Blacklists",
        "AccessLogs",
        "Hosts",
        "Host",
        "Accounts",
        "Account",
        "Employees",
        "Employee",
        "Cards",
        "Card",
        "Tickets",
        "Ticket",
        "Messages",
        "Groups",
        "Group",
        "GroupMembers",
        "GroupMember",
        "Roles",
        "Role",
        "Schemes",
        "Scheme",
        "SchemeMembers",
        "SchemeSchedules",
        "SchemeSchedule",
        "Locations",
        "Location",
        "Cameras",
        "Camera",
        "ParkingPlaces",
        "ParkingPlace",
        "ParkingAreas",
        "ParkingArea",
        "ParkingAreaDevices",
        "ParkingTariffs",
        "ParkingTariff",
        "ParkingImages",
        "Services",
        "Service",
        "Guests",
        "Guest",
    ],
    endpoints: (_) => ({}),
});
