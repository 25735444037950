import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
} from '../../app/storeReducers/AccountServicesSlice';
import VehicleServiceDeleteModal from './VehicleServiceDeleteModal';
import { DateToString, UtcToLocal } from '../../helpers/DateHelpers';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import SecutiryContext from '../../features/securityContext';

const VehicleServicesList = (props) => {
    const { accountId, vehicleId, selectEditEntity } = props;
    const dispatch = useDispatch();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);
    const { context } = useContext(SecutiryContext);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(getListAsync({ accountId, vehicleId }));
        }

        loadResourcesAndDataAsync();
    }, [dispatch, accountId, vehicleId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('services_edit'));
    }, [context, accountId]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedEntity, setDeletedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const selectDeleteEntity = (entityId) => {
        setDeletedEntity(entityId);
        setDeleteModalIsOpen(true);
    };

    return (
        <>
            <VehicleServiceDeleteModal
                entity={deletedEntity}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <SkeletonTable
                cols={['']}
                rowsCount={1}
                isLoading={isLoading}
                classTable='table-striped gy-7 gs-7'
            >
                <table className='table mb-0 border-top'>
                    <tbody>
                        {entityList?.map((item) => (
                            <tr key={item.id}>
                                <td className='ps-6 d-flex align-self-center'>
                                    <div className='rounded-circle av-bg-primary-50 symbol me-2 p-3'>
                                        <img
                                            src='/media/icons/avanguard/Parking.svg'
                                            className='add-profile-img'
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className='fw-boldest'>
                                            {item.tariff != null
                                                ? item.tariff.title
                                                : ''}
                                        </div>
                                        <div className='av-text-gray-500'>
                                            c{' '}
                                            {item.dateStart != null
                                                ? DateToString(
                                                      UtcToLocal(item.dateStart)
                                                  )
                                                : ''}{' '}
                                            по{' '}
                                            {item.dateFinish != null
                                                ? DateToString(
                                                      UtcToLocal(
                                                          item.dateFinish
                                                      )
                                                  )
                                                : ' '}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex justify-content-end flex-shrink-0'>
                                        {canEdit ? (
                                            <>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 px-2'
                                                    onClick={() =>
                                                        selectEditEntity(
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    <i className='fas fa-pen text-muted' />
                                                </button>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1 px-2'
                                                    onClick={() =>
                                                        selectDeleteEntity(item)
                                                    }
                                                >
                                                    <i className='far fa-trash-alt text-muted' />
                                                </button>
                                            </>
                                        ) : null}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </SkeletonTable>
        </>
    );
};

export default VehicleServicesList;
