import { useEffect, useState } from "react";
import {
    useCreateScheduleMutation,
    useUpdateScheduleMutation,
} from "../../app/storeReducers/SchedulesAPI";

const ScheduleCreateModal = (props) => {
    const { accountId, entity, isOpen, closeHandle } = props;

    const [createEntity] = useCreateScheduleMutation();
    const [updateEntity] = useUpdateScheduleMutation();

    const [name, setName] = useState("Новое расписание");
    const [comments, setComments] = useState("");
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entity?.id > 0) {
                setName(entity?.name ?? "");
                setComments(entity?.comments ?? "");
                setIsActive(entity?.isActive ?? true);
            } else {
                setName("Новое расписание");
                setComments("");
                setIsActive(true);
            }
        }

        loadResourcesAndDataAsync();
    }, [entity]);

    const saveEntity = async () => {
        const record = {
            id: entity.id,
            account: { id: accountId },
            name,
            comments,
            isActive,
        };
        if (entity?.id === 0) {
            await createEntity(record);
        } else {
            await updateEntity(record);
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {entity?.id > 0
                                    ? "Редактировать"
                                    : "Добавить новое"}{" "}
                                расписание
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Название
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="comments"
                                    className="form-label"
                                >
                                    Комментарий
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="comments"
                                    value={comments}
                                    onChange={(e) =>
                                        setComments(e.target.value)
                                    }
                                />
                            </div>
                            <div className="mb-3">
                                <div className="form-check form-switch">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        name="isActive"
                                        checked={isActive}
                                        onChange={(e) =>
                                            setIsActive(e.target.checked)
                                        }
                                    />
                                    <label className="form-check-label">
                                        Активно
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default ScheduleCreateModal;
