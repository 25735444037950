import { useContext, useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import SkeletonTableFull from '../../../../components/skeleton/SkeletonTableFull';
import { Http } from '../../../../http';
import CameraAddDeviceModal from './CameraAddDeviceModal';
import CameraDeviceDeleteModal from './CameraDeviceDeleteModal';
import SecutiryContext from '../../../../features/securityContext';

const CameraDevicesPanel = (props) => {
    const { accountId, entityId } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [entityId, canEdit]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('camera_edit'));
        setCanList(context.isRoot || actions?.includes('camera_list'));
    }, [context, accountId]);

    const loadData = async () => {
        setIsLoading(true);
        const getResult = await Http.get(
            `api/cameras/${entityId}/devices`,
            true
        );
        getResult.result.map((item) => {
            if (canEdit) {
                item.btns = (
                    <button
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                        onClick={() => openDeleteModal(item)}
                    >
                        <i className='fas fa-trash-alt' />
                    </button>
                );
            }
        });
        setDevices(getResult.result);
        setIsLoading(false);
    };

    const okAddDevice = () => {
        loadData();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteDevice = () => {
        loadData();
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <CameraAddDeviceModal
                accountId={accountId}
                cameraId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddDevice}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <CameraDeviceDeleteModal
                cameraId={entityId}
                device={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteDevice}
            />
            <div className='card'>
                <div className='card-body pb-2'>
                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <h3>
                                Устройства{' '}
                                <span className='badge rounded-pill bg-light text-dark'>
                                    {devices.length}
                                </span>
                            </h3>
                        </div>
                        <div className='col-md-6 text-end'>
                            {canEdit ? (
                                <button
                                    className='btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0'
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr075.svg'
                                        className='svg-icon-2 me-2'
                                    />
                                    Добавить устройство
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {canList ? (
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: 'id',
                            name: 'Название',
                            uid: 'UID',
                            btns: '',
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='mb-0'
                        entity={devices}
                        tdClasses={{ name: 'ps-6' }}
                    />
                ) : (
                    <div className='card-body'>
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default CameraDevicesPanel;
