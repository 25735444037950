import GuestsList from './GuestsList';
import PermissionPage from '../../components/PermissionPage';

const GuestsPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['guest_list']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Посетители</h1>
                    <GuestsList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default GuestsPage;
