import { useEffect, useRef, useState } from "react";
import {
    useCreateVehicleMutation,
    useLazyGetVehicleByIdQuery,
    useUpdateVehicleMutation,
} from "../../app/storeReducers/AccountVehiclesAPI";
import { useLazyGetEmployeesQuery } from "../../app/storeReducers/employeesApiSlice";
import { useLazyGetParkingAreasQuery } from "../../app/storeReducers/parkingAreasApiSlice";
import { useLazyGetParkingPlacesQuery } from "../../app/storeReducers/parkingPlacesApiSlice";
import {
    useLazyGetAccountByIdQuery,
    useLazyGetSubaccountsQuery,
} from "../../app/storeReducers/acountsApiSlice";
import { useLazyGetParkingTariffsQuery } from "../../app/storeReducers/parkingTariffsApiSlice";

const VehicleCreateModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle } = props;
    const dtNow = new Date();
    const dtNowDateString = `${dtNow.getFullYear()}-${
        (dtNow.getMonth() + 1 > 9 ? "" : "0") + (dtNow.getMonth() + 1)
    }-${(dtNow.getDate() > 9 ? "" : "0") + dtNow.getDate()}`;

    const [carNumber, setCarNumber] = useState("");
    const [carRegion, setCarRegion] = useState("");
    const [model, setModel] = useState("");
    const [dateStart, setDateStart] = useState(dtNowDateString);
    const [dateFinish, setDateFinish] = useState("");
    const [accountsList, setAccountsList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(0);
    const [employeesList, setEmployeesList] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(0);
    const [parkingAreasList, setParkingAreasList] = useState([]);
    const [parkingAreaSelected, setParkingAreaSelected] = useState(0);
    const [parkingPlacesList, setParkingPlacesList] = useState([]);
    const [parkingPlaceSelected, setParkingPlaceSelected] = useState(0);
    const [servicesList, setServicesList] = useState([]);
    const [serviceSelected, setServiceSelected] = useState(0);
    const inputCarNumberRef = useRef(null);

    const [createAsync] = useCreateVehicleMutation();
    const [updateAsync] = useUpdateVehicleMutation();

    const [getEmployees] = useLazyGetEmployeesQuery();
    const [getParkingAreas] = useLazyGetParkingAreasQuery();
    const [getParkingPlaces] = useLazyGetParkingPlacesQuery();
    const [getVehicleById] = useLazyGetVehicleByIdQuery();
    const [getAccountById] = useLazyGetAccountByIdQuery();
    const [getSubaccounts] = useLazyGetSubaccountsQuery();
    const [getParkingTariffs] = useLazyGetParkingTariffsQuery();

    useEffect(() => {
        if (entityId <= 0) {
            setCarNumber("");
            setCarRegion("");
            setModel("");
            setDateStart(dtNowDateString);
            setDateFinish("");
            setSelectedEmployee(0);
            setParkingPlaceSelected(0);
        }
        if (isOpen) {
            inputCarNumberRef.current.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            let accounts = [];
            const getCurrentAccouunt = await getAccountById(accountId).unwrap();
            if (getCurrentAccouunt.result.type !== "business") {
                accounts.push(getCurrentAccouunt.result);
            }
            const getSubAccounts = await getSubaccounts({
                accountId,
                sort: "name",
                pageSize: 10000,
            }).unwrap();
            getSubAccounts.result?.map((item) => accounts.push(item));
            if (entityId === undefined || entityId === null || entityId < 1) {
                const currentEmps = await getEmployees({
                    accountId: accounts[0]?.id,
                    sort: "last_name",
                    pageSize: 10000,
                }).unwrap();
                setEmployeesList(currentEmps.result);
            }
            const parkingAreasRequest = await getParkingAreas(
                accountId
            ).unwrap();
            const getTariffs = await getParkingTariffs({
                accountId,
                sort: "name",
                pageSize: 10000,
            }).unwrap();

            setAccountsList(accounts);
            setSelectedAccount(accounts[0]?.id);
            setSelectedEmployee(0);
            setParkingAreasList(parkingAreasRequest.result);
            if (parkingAreasRequest.result.length > 0) {
                const parkingPlacesRequest = await getParkingPlaces(
                    parkingAreasRequest?.result[0]?.id
                ).unwrap();
                setParkingAreasList(parkingAreasRequest.result);
                setParkingAreaSelected(parkingAreasRequest?.result[0]?.id);
                setParkingPlacesList(parkingPlacesRequest.result);
            } else {
                setParkingAreaSelected(0);
                setParkingPlacesList([]);
                setParkingPlaceSelected(0);
            }
            setServicesList(getTariffs.result);
            setServiceSelected(getTariffs?.result[0]?.id);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== undefined && entityId !== 0 && entityId !== -1) {
                const getResult = await getVehicleById(entityId).unwrap();
                setCarNumber(getResult.result.carNumber);
                setCarRegion(getResult.result.carRegion);
                setSelectedAccount(getResult.result.account.id);
                const currentEmps = await getEmployees({
                    accountId: getResult.result.account.id,
                    sort: "last_name",
                    pageSize: 10000,
                }).unwrap();
                setEmployeesList(currentEmps.result);
                setSelectedEmployee(getResult.result.employee?.id);
                setModel(getResult.result.model);
                setParkingAreaSelected(getResult.result.parkingArea.id);
                const pPlaces = await getParkingPlaces(
                    getResult.result.parkingArea.id
                ).unwrap();
                setParkingPlacesList(pPlaces.result);
                setParkingPlaceSelected(getResult.result?.parkingPlace?.id);
                if (getResult.result?.service?.dateStart !== null) {
                    setDateStart(
                        getResult.result?.service?.dateStart.substr(
                            0,
                            getResult.result.service.dateStart.length - 13
                        )
                    );
                } else {
                    setDateStart(dtNowDateString);
                }
                if (getResult.result?.service?.dateFinish !== null) {
                    setDateFinish(
                        getResult.result?.service?.dateFinish.substr(
                            0,
                            getResult.result.service?.dateFinish?.length - 13
                        )
                    );
                } else {
                    setDateFinish("");
                }
            } else {
                setCarNumber("");
                setCarRegion("");
                setModel("");
                setDateStart(dtNowDateString);
                setDateFinish("");
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId, dtNowDateString, servicesList]);

    const saveEntity = async () => {
        const entity = {
            carNumber,
            carRegion,
            model,
            account: { id: selectedAccount },
            parkingArea: { id: parkingAreaSelected },
            parkingPlace:
                parkingPlaceSelected !== 0
                    ? { id: parkingPlaceSelected }
                    : null,
            id: entityId,
            employee:
                parseInt(selectedEmployee) !== 0
                    ? {
                          id: selectedEmployee,
                      }
                    : null,
        };
        if (entityId > 0) {
            updateAsync(entity);
        } else {
            entity.service = {
                dateStart: dateStart.length > 0 ? dateStart + "T00:00:00" : "",
                dateFinish:
                    dateFinish.length > 0 ? dateFinish + "T00:00:00" : "",
                consumer: { id: selectedAccount },
                tariff: { id: serviceSelected },
            };
            createAsync(entity);
        }
        closeHandle();
    };

    const changeAccount = async (accId) => {
        setSelectedAccount(accId);
        const accEmps = await getEmployees({
            accountId: accId,
            sort: "last_name",
            pageSize: 10000,
        }).unwrap();
        setEmployeesList(accEmps.result);
        setSelectedEmployee(0);
    };

    const changeParkingArea = async (areaId) => {
        setParkingAreaSelected(areaId);
        const pPlaces = await getParkingPlaces(areaId).unwrap();
        setParkingPlacesList(pPlaces.result);
        setParkingPlaceSelected(0);
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {entityId > 0
                                    ? "Редактировать информацию"
                                    : "Добавить новый транспорт"}
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-3">
                                <div
                                    className="col-md-6"
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div className="form-group">
                                        <label htmlFor="carNumber">
                                            Регистрационный номер
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="carNumber"
                                                value={carNumber}
                                                onChange={(e) =>
                                                    setCarNumber(e.target.value)
                                                }
                                                placeholder="А000АА"
                                                tabIndex={1}
                                                autoFocus
                                                ref={inputCarNumberRef}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="carRegion">
                                            Регион
                                        </label>
                                        <div className="input-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="carRegion"
                                                value={carRegion}
                                                onChange={(e) =>
                                                    setCarRegion(e.target.value)
                                                }
                                                style={{ maxWidth: "100px" }}
                                                tabIndex={2}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label htmlFor="model">Модель</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="model"
                                            value={model}
                                            onChange={(e) =>
                                                setModel(e.target.value)
                                            }
                                            tabIndex={3}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Аккаунт</label>
                                        <select
                                            className="form-select"
                                            value={selectedAccount}
                                            onChange={(e) =>
                                                changeAccount(e.target.value)
                                            }
                                            disabled={entityId > 0}
                                        >
                                            {accountsList.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Сотрудник</label>
                                        <select
                                            className="form-select"
                                            value={selectedEmployee}
                                            onChange={(e) =>
                                                setSelectedEmployee(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value={0}>
                                                --Без привязки к сотруднику--
                                            </option>
                                            {employeesList?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Парковка</label>
                                        <select
                                            className="form-select"
                                            value={parkingAreaSelected}
                                            onChange={(e) =>
                                                changeParkingArea(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            {parkingAreasList.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2">
                                    <div className="form-group">
                                        <label>Парковочное место</label>
                                        <select
                                            className="form-select"
                                            value={parkingPlaceSelected}
                                            onChange={(e) =>
                                                setParkingPlaceSelected(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value={0}>
                                                --Без парковочного места--
                                            </option>
                                            {parkingPlacesList
                                                .filter(
                                                    (x) =>
                                                        !x.inactive &&
                                                        x.service?.consumer !==
                                                            null
                                                )
                                                .map((item) => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.number}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {entityId > 0 ? null : (
                                <>
                                    <div className="row mb-3">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Тариф</label>
                                                <select
                                                    className="form-select"
                                                    value={serviceSelected}
                                                    onChange={(e) =>
                                                        setServiceSelected(
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    {servicesList.map(
                                                        (item) => (
                                                            <option
                                                                key={item.id}
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="dateStart">
                                                    Дата начала действия
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="dateStart"
                                                    value={dateStart}
                                                    onChange={(e) =>
                                                        setDateStart(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="form-group">
                                                <label htmlFor="dateFinish">
                                                    Дата окончания действия
                                                </label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="dateFinish"
                                                    value={dateFinish}
                                                    onChange={(e) =>
                                                        setDateFinish(
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                {entityId > 0
                                    ? "Сохранить изменения"
                                    : "Добавить транспорт"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default VehicleCreateModal;
