import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import ParkingAreaCreateModal from "./ParkingAreaCreateModal";
import { useHistory } from "react-router-dom";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetParkingAreasQuery } from "../../app/storeReducers/parkingAreasApiSlice";

const ParkingAreasList = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const { data: entityList, isLoading } = useGetParkingAreasQuery(accountId);

    const context = useSelector(selectContext);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("employees_edit"));
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const openEntityCard = (entityId) => {
        history.push(`/account/${accountId}/parking/${entityId}`);
    };

    return (
        <>
            <ParkingAreaCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className="row mb-3">
                <div className="col">
                    <button
                        className="btn av-btn-primary"
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className="fas fa-plus me-2"></i> Новая парковка
                    </button>
                </div>
            </div>
            <div className="card border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={["Название", ""]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <th className="border-bottom ps-6">
                                        Название
                                    </th>
                                    <th className="border-bottom">
                                        Вместимость
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={() => openEntityCard(item.id)}
                                        className="cursor-pointer"
                                    >
                                        <td className="ps-6 align-middle">
                                            {item.name}
                                        </td>
                                        <td className="align-middle">
                                            {item.capacity}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default ParkingAreasList;
