import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG } from '../../helpers';
import { useLayout } from '../../_metronic/layout/core';

type Props = {
    to: string;
    title: string;
    icon?: string;
    fontIcon?: string;
    imageIcon?: string;
    hasBullet?: boolean;
};

const MenuItem: React.FC<Props> = ({
    children,
    to,
    title,
    icon,
    fontIcon,
    imageIcon,
    hasBullet = false,
}) => {
    const { pathname } = useLocation();
    const isActive = checkIsActive(pathname, to);
    const { config } = useLayout();
    const { aside } = config;

    return (
        <div className='menu-item'>
            <Link
                className={clsx('menu-link without-sub', { active: isActive })}
                to={to}
            >
                {hasBullet && (
                    <span className='menu-bullet'>
                        <span className='bullet bullet-dot'></span>
                    </span>
                )}
                {icon && (
                    <span className='menu-icon filter-menu-left'>
                        <KTSVG path={icon} className='svg-icon-2' />
                    </span>
                )}
                {imageIcon && (
                    <span className='menu-icon'>
                        <img src={imageIcon} height='24px' />
                    </span>
                )}
                {fontIcon && aside.menuIcon === 'font' && (
                    <i
                        className={clsx('bi fs-3', fontIcon)}
                        style={{ width: '22px' }}
                    ></i>
                )}
                <span className='menu-title'>{title}</span>
            </Link>
            {children}
        </div>
    );
};

export { MenuItem };
