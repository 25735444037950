import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GroupCreateModal from "./GroupCreateModal";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { useHistory } from "react-router-dom";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetGroupsQuery } from "../../app/storeReducers/groupsApiSlice";

const GroupsListTable = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const { data: groups, isLoading } = useGetGroupsQuery(
        { accountId },
        { skip: !accountId }
    );

    const context = useSelector(selectContext);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("groups_edit"));
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedGroup, setEditedGroup] = useState(0);

    const [canEdit, setCanEdit] = useState(false);

    const openCreateModal = (employeeId) => {
        setEditedGroup(employeeId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedGroup(-1);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(
                `/account/${accountId}/admin/groups/${entityId}`,
                "_blank"
            );
        else history.push(`/account/${accountId}/admin/groups/${entityId}`);
    };

    return (
        <>
            <GroupCreateModal
                accountId={accountId}
                groupId={editedGroup}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className="row">
                <div className="col mb-3">
                    <button
                        className="btn av-btn-primary"
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className="fas fa-plus" /> Новая группа
                    </button>
                </div>
            </div>
            <div className="card border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={["Название группы", ""]}
                        rowsCount={15}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <th className="border-bottom ps-6">
                                        Название группы
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {groups?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className="cursor-pointer"
                                    >
                                        <td className="ps-6">{item.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default GroupsListTable;
