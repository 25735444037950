import { CRUDReducer } from './CRUDReducer';

export const baseReducer = new CRUDReducer('accountLocations', 'locations');

export const { getListAsync, createAsync, updateAsync, deleteAsync } =
    baseReducer;

export const accountLocationsSlice = baseReducer.reducerSlice;

export const { setPageSize, selectPage } = accountLocationsSlice.actions;

export const selectList = (state) => state.accountLocations.objectsArray;
export const selectIsLoading = (state) => state.accountLocations.isLoading;
export const selectPaginator = (state) => state.accountLocations.paginator;

export default accountLocationsSlice.reducer;
