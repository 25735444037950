import PermissionPage from '../../../components/PermissionPage';
import ScheduleEditPanel from './ScheduleEditPanel';

const ScheduleCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const scheduleId = props.match.params.scheduleId;

    return (
        <PermissionPage accountId={accountId} roles={['schemes_list']}>
            <h1 className='mb-5'>Карточка графика доступа</h1>
            <div className='row'>
                <div className='col-6'>
                    <ScheduleEditPanel
                        accountId={accountId}
                        entityId={scheduleId}
                    />
                </div>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col'></div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default ScheduleCardPage;
