import DashboardList from './DashboardList';

const DashboardPage = () => {
    return (
        <div className='row'>
            <div className='col-md-12'>
                <h1 className='page-header'>Обзор</h1>
                <DashboardList />
            </div>
        </div>
    );
};

export default DashboardPage;
