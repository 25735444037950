import { useEffect, useState } from "react";
import BtnStatused from "../../../components/BtnStatused";
import {
    useGetCurrentUserQuery,
    useUpdateCurrentUserMutation,
} from "../../../app/storeReducers/profileApiSlice";
import { Link } from "react-router-dom";

const UserInfo = () => {
    const [updateCurrentUser, { isFetching, isError }] =
        useUpdateCurrentUserMutation();
    const { data: user } = useGetCurrentUserQuery();

    const [editedEmail, setEditedEmail] = useState("");
    const [editedFirstName, setEditedFirstName] = useState("");
    const [editedLastName, setEditedLastName] = useState("");
    const [editedSecondName, setEditedSecondName] = useState("");

    useEffect(() => {
        setEditedEmail(user?.result?.email);
        setEditedFirstName(user?.result?.firstName);
        setEditedLastName(user?.result?.lastName);
        setEditedSecondName(user?.result?.secondName);
    }, [user]);

    const saveUser = () => {
        const userInfo = {
            id: user.result.id,
            login: user.result.login,
            email: editedEmail,
            firstName: editedFirstName,
            secondName: editedSecondName,
            lastName: editedLastName,
        };
        updateCurrentUser(userInfo);
    };

    return (
        <form>
            <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                    Телефон
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="phone"
                    disabled={true}
                    defaultValue={user?.result?.login}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">
                    Email
                </label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={editedEmail}
                    onChange={(e) => setEditedEmail(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="secondName" className="form-label">
                    Фамилия
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="secondName"
                    value={editedLastName}
                    onChange={(e) => setEditedLastName(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                    Имя
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    value={editedFirstName}
                    onChange={(e) => setEditedFirstName(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                    Отчество
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={editedSecondName}
                    onChange={(e) => setEditedSecondName(e.target.value)}
                />
            </div>
            <div className="d-grid mb-3">
                <BtnStatused
                    label={"Сохранить"}
                    handleClick={saveUser}
                    btnColor="primary"
                    isLoading={isFetching}
                    isFailed={isError}
                    loadingLabel="Сохранение..."
                />
            </div>
            <div className="d-grid">
                <Link
                    to="/changePassword"
                    className="btn av-btn-simple fw-boldest"
                    onClick={saveUser}
                >
                    Изменить пароль
                </Link>
            </div>
        </form>
    );
};

export default UserInfo;
