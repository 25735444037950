import { useEffect, useState } from 'react';
import SkeletonSelect from '../../../components/skeleton/SkeletonSelect';
import { useAddCameraDeviceMutation, useGetCamerasQuery } from '../../../app/storeReducers/camerasApiSlice';

const DeviceAddCameraModal = (props) => {
    const { accountId, deviceId, isOpen, okHandle, closeHandle } = props;

    const [cameraSelected, setCameraSelected] = useState({});

    const { data: cameras, isLoading } = useGetCamerasQuery({ accountId, sort: 'name' }, {
        skip: !accountId,
    });
    const [addCameraDevice] = useAddCameraDeviceMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setCameraSelected({});
            if (cameras?.result?.length > 0) {
                setCameraSelected(cameras?.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [deviceId]);

    const saveCameraDevice = async () => {
        await addCameraDevice({
            cameraId: cameraSelected,
            deviceId,
        });

        okHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить камеру к устройству
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-4'>
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label='Камера'
                                    value={cameraSelected}
                                    onChange={(e) =>
                                        setCameraSelected(e.target.value)
                                    }
                                    itemsArray={cameras?.result?.map((item) => ({
                                        id: item.id,
                                        name: item.id,
                                        description: item.name,
                                    }))}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveCameraDevice}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeviceAddCameraModal;
