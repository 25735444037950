import EventNotifyComponent from '../../components/eventNotify/EventNotifyComponent';

const PersonalNotificationSettingsPage = () => {
    return (
        <div className='row mt-4'>
            <div className='col-md-12'>
                <h1 className='mb-5'>Личные уведомления</h1>
                <EventNotifyComponent accountId='' />
            </div>
        </div>
    );
};

export default PersonalNotificationSettingsPage;
