import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import VehicleServiceDeleteModal from "./VehicleServiceDeleteModal";
import { DateToString, UtcToLocal } from "../../helpers/DateHelpers";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { selectContext } from "../../app/storeReducers/AuthSlice";

const VehicleServicesList = (props) => {
    const { accountId, services, isLoading, selectEditEntity } = props;

    const context = useSelector(selectContext);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("services_edit"));
    }, [context, accountId]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedEntity, setDeletedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const selectDeleteEntity = (entityId) => {
        setDeletedEntity(entityId);
        setDeleteModalIsOpen(true);
    };

    return (
        <>
            <VehicleServiceDeleteModal
                entity={deletedEntity}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <SkeletonTable
                cols={[""]}
                rowsCount={1}
                isLoading={isLoading}
                classTable="table-striped gy-7 gs-7"
            >
                <table className="table mb-0 border-top">
                    <tbody>
                        {services?.result?.map((item) => (
                            <tr key={item.id}>
                                <td className="ps-6 d-flex align-self-center">
                                    <div className="rounded-circle av-bg-primary-50 symbol me-2 p-3">
                                        <img
                                            src="/media/icons/avanguard/Parking.svg"
                                            className="add-profile-img"
                                            style={{
                                                width: 20,
                                                height: 20,
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className="fw-boldest">
                                            {item.tariff != null
                                                ? item.tariff.title
                                                : ""}
                                        </div>
                                        <div className="av-text-gray-500">
                                            c{" "}
                                            {item.dateStart != null
                                                ? DateToString(
                                                      UtcToLocal(item.dateStart)
                                                  )
                                                : ""}{" "}
                                            по{" "}
                                            {item.dateFinish != null
                                                ? DateToString(
                                                      UtcToLocal(
                                                          item.dateFinish
                                                      )
                                                  )
                                                : " "}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex justify-content-end flex-shrink-0">
                                        {canEdit ? (
                                            <>
                                                <button
                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 px-2"
                                                    onClick={() =>
                                                        selectEditEntity(
                                                            item.id
                                                        )
                                                    }
                                                >
                                                    <i className="fas fa-pen text-muted" />
                                                </button>
                                                <button
                                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1 px-2"
                                                    onClick={() =>
                                                        selectDeleteEntity(item)
                                                    }
                                                >
                                                    <i className="far fa-trash-alt text-muted" />
                                                </button>
                                            </>
                                        ) : null}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </SkeletonTable>
        </>
    );
};

export default VehicleServicesList;
