import { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import SchemeAddEmployeeModal from "./SchemeAddEmployeeModal";
import SchemeEmployeeDeleteModal from "./SchemeEmployeeDeleteModal";
import SchemeAddGroupModal from "./SchemeAddGroupModal";
import SchemeGroupDeleteModal from "./SchemeGroupDeleteModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import { useGetSchemeMembersQuery } from "../../../../app/storeReducers/schemesApiSlice";

const SchemeEmployeesPanel = (props) => {
    const { accountId, entityId, isSubaccount } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [addModalGroupIsOpen, setAddModalGroupIsOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deleteGroupItem, setDeleteGroupItem] = useState({});
    const [deleteGroupModalIsOpen, setDeleteGroupModalIsOpen] = useState(false);

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const { data: members, refetch } = useGetSchemeMembersQuery({
        accountId: accountId,
        id: entityId,
    });

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("employees_edit"));
        setCanList(context.isRoot || actions?.includes("employees_list"));
    }, [context, accountId]);

    const okAddEmployee = () => {
        refetch();
        setAddModalIsOpen(false);
    };

    const okAddGroup = () => {
        refetch();
        setAddModalGroupIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const openDeleteGroupModal = (itm) => {
        setDeleteGroupItem(itm);
        setDeleteGroupModalIsOpen(true);
    };

    const deleteEmployee = () => {
        setDeleteModalIsOpen(false);
        refetch();
    };

    const deleteGroup = () => {
        setDeleteGroupModalIsOpen(false);
        refetch();
    };

    return (
        <>
            <SchemeAddEmployeeModal
                accountId={accountId}
                schemeId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddEmployee}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <SchemeAddGroupModal
                accountId={accountId}
                schemeId={entityId}
                isOpen={addModalGroupIsOpen}
                okHandle={okAddGroup}
                closeHandle={() => setAddModalGroupIsOpen(false)}
            />
            <SchemeEmployeeDeleteModal
                schemeId={entityId}
                employee={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteEmployee}
            />
            <SchemeGroupDeleteModal
                schemeId={entityId}
                group={deleteGroupItem}
                isOpen={deleteGroupModalIsOpen}
                closeHandle={() => setDeleteGroupModalIsOpen(false)}
                okHandle={deleteGroup}
            />
            <div className="card border">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <h3>
                                Участники{" "}
                                <span className="badge rounded-pill bg-light text-dark">
                                    {members?.result.length}
                                </span>
                            </h3>
                        </div>
                        <div className="col-md-8 text-end">
                            {canEdit && !isSubaccount ? (
                                <>
                                    <button
                                        className="btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0"
                                        onClick={() => setAddModalIsOpen(true)}
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr075.svg"
                                            className="svg-icon-2 me-2"
                                        />
                                        Сотрудник
                                    </button>
                                    <button
                                        className="btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0"
                                        onClick={() =>
                                            setAddModalGroupIsOpen(true)
                                        }
                                    >
                                        <KTSVG
                                            path="/media/icons/duotune/arrows/arr075.svg"
                                            className="svg-icon-2 me-2"
                                        />
                                        Группа
                                    </button>
                                </>
                            ) : null}
                        </div>
                    </div>
                    {canList ? (
                        members?.result?.map((item) => (
                            <div
                                className="border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2"
                                key={item.id}
                            >
                                <i
                                    className={
                                        "fas " +
                                        (item.isGroup
                                            ? "fa-users"
                                            : "fas fa-user-tie")
                                    }
                                ></i>{" "}
                                {item.info.title}
                                {canEdit ? (
                                    <i
                                        className="ms-2 fas fa-times cursor-pointer"
                                        onClick={() =>
                                            item.isGroup
                                                ? openDeleteGroupModal(item)
                                                : openDeleteModal(item)
                                        }
                                    ></i>
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                </div>
            </div>
        </>
    );
};

export default SchemeEmployeesPanel;
