import { useCallback, useEffect, useState } from 'react';
import { ToUtcString } from '../../helpers/DateHelpers';
import InputMask from 'react-input-mask';
import { useDropzone } from 'react-dropzone';
import { HumanFileSize } from '../../helpers/FileHelpers';
import CropImageView from '../../components/CropImageView';
import {
    useCreateEmployeeMutation,
    useDeleteEmployeePhotoMutation,
    useLazyGetEmployeeByIdQuery,
    useUpdateEmployeeMutation,
    useUploadEmployeePhotoMutation,
} from '../../app/storeReducers/employeesApiSlice';
import { useHistory } from 'react-router';

const EmployeeCreateModal = (props) => {
    const { accountId, employeeId, isOpen, closeHandle } = props;
    const history = useHistory();

    const [firstName, setFirstName] = useState('');
    const [secondName, setSecondName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [occupation, setOccupation] = useState('');
    const [persNumber, setPersNumber] = useState('');
    const [dateStart, setDateStart] = useState(ToUtcString(new Date()).split('T')[0]);
    const [dateFinish, setDateFinish] = useState('');
    const [blocked, setBlocked] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    const [filename, setFilename] = useState(null);
    const [photoImage, setPhotoImage] = useState(null);
    const [photoThumbnail, setPhotoThumbnail] = useState(null);
    const [photoModalIsOpen, setPhotoModalIsOpen] = useState(false);
    const [comments, setComments] = useState('');

    const [createEmployeeAsync] = useCreateEmployeeMutation();
    const [updateEmployeeAsync] = useUpdateEmployeeMutation();
    const [getEmployee] = useLazyGetEmployeeByIdQuery();
    const [deleteEmployeePhoto] = useDeleteEmployeePhotoMutation();
    const [uploadEmployeePhoto] = useUploadEmployeePhotoMutation();

    const [avatar, setAvatar] = useState(
        <div className='add-profile-avatar me-3 rounded-circle'>
            <img
                src='/media/icons/avanguard/User.svg'
                className='add-profile-img'
                style={{
                    width: 32,
                    height: 32,
                }}
            />
        </div>
    );

    useEffect(async () => {
        async function loadResourcesAndDataAsync() {
            if (employeeId) {
                const empRequest = await getEmployee({ id: employeeId, thumbnail: true }).unwrap();
                const emp = empRequest.result;
                setFirstName(emp.firstName);
                setSecondName(emp.secondName);
                setLastName(emp.lastName);
                setPhone(emp?.user?.title ?? '');
                setOccupation(emp.occupation);
                setPersNumber(emp?.persNumber ?? '');
                setDateStart(emp.dateStart != null ? ToUtcString(new Date(emp.dateStart)).split('T')[0] : '');
                setDateFinish(emp.dateFinish != null ? ToUtcString(new Date(emp.dateFinish)).split('T')[0] : '');
                setPhotoThumbnail(emp.thumbnail);
                setBlocked(emp.blocked);
                setComments(emp.comments);
                if (emp.thumbnail) {
                    setAvatar(
                        <div className='employee-avatar position-relative'>
                            <img
                                src={emp.thumbnail}
                                className='rounded-circle me-3'
                                style={{
                                    width: 64,
                                    height: 64,
                                    opacity: '70%',
                                }}
                            />
                            <i className='fas fa-trash-alt position-absolute cursor-pointer' onClick={resetFile} />
                        </div>
                    );
                }
            } else {
                setFirstName('');
                setSecondName('');
                setLastName('');
                setPhone('');
                setOccupation('');
                setPersNumber('');
                setDateStart(ToUtcString(new Date()).split('T')[0]);
                setDateFinish('');
                setBlocked(false);
                setAvatar(
                    <div className='add-profile-avatar me-3 rounded-circle'>
                        <img
                            src='/media/icons/avanguard/user.svg'
                            className='add-profile-img'
                            style={{
                                width: 32,
                                height: 32,
                            }}
                        />
                    </div>
                );
                setComments('');
            }
        }

        loadResourcesAndDataAsync();
    }, [employeeId]);

    const saveEmployee = async () => {
        let employee = {
            firstName,
            lastName,
            secondName,
            occupation,
            persNumber,
            dateStart: dateStart.length > 0 ? dateStart + 'T00:00:00' : '',
            dateFinish: dateFinish.length > 0 ? dateFinish + 'T00:00:00' : '',
            blocked,
            id: employeeId,
            account: { id: accountId },
            comments,
        };
        if (employeeId) {
            employee.id = employeeId;
            if (phone) {
                employee.user = { title: phone };
            }
        } else {
            employee.user = { title: phone };
        }

        let emp = null;
        if (employeeId) {
            emp = await updateEmployeeAsync(employee).unwrap();
        } else {
            emp = await createEmployeeAsync(employee).unwrap();
        }
        if (photoImage) {
            await uploadEmployeePhoto({
                employeeId: emp.result.id,
                photo: {
                    image: photoImage,
                    thumbnail: photoThumbnail,
                },
            }).unwrap();
        }
        if (employeeId) {
            closeHandle();
        } else {
            history.push(`/account/${accountId}/employees/${emp.result.id}`);
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        setSelectedFile(acceptedFiles);
        setFilename(acceptedFiles[0]);
        setPhotoModalIsOpen(true);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 1,
    });

    const changePhoto = (img, th) => {
        //
        setPhotoImage(img);
        setPhotoThumbnail(th);
        //
        setAvatar(
            <div className='employee-avatar position-relative'>
                <img
                    src={th}
                    className='rounded-circle me-3'
                    style={{
                        width: 64,
                        height: 64,
                        opacity: '70%',
                    }}
                />
                <i className='fas fa-trash-alt position-absolute cursor-pointer' onClick={resetFile} />
            </div>
        );
    };

    const resetFile = async () => {
        const result = await deleteEmployeePhoto(employeeId).unwrap();
        if (result.result) {
            setAvatar(
                <div className='add-profile-avatar me-3 rounded-circle'>
                    <img
                        src='/media/icons/avanguard/user.svg'
                        className='add-profile-img'
                        style={{
                            width: 32,
                            height: 32,
                        }}
                    />
                </div>
            );
        }
    };

    const closePhotoModal = () => {
        setPhotoModalIsOpen(false);
    };

    return (
        <>
            <div className={'modal fade' + (isOpen ? ' show' : '')} tabIndex='-1'>
                <div className='modal-dialog modal-dialog-centered modal-lg' style={{ zIndex: 1 }}>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                {employeeId ? 'Редактировать информацию' : 'Добавить нового сотрудника'}
                            </h5>
                            <button type='button' className='btn-close' onClick={closeHandle}></button>
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-5'>
                                <div className='col'>
                                    <div className='d-flex'>
                                        <div>{avatar}</div>
                                        <div className='flex-fill'>
                                            <div
                                                className={
                                                    'border rounded text-center p-3 mb-3' +
                                                    (selectedFile.length > 0
                                                        ? ' av-bg-light-primary av-border-primary-300'
                                                        : '')
                                                }
                                            >
                                                {photoModalIsOpen && (
                                                    <CropImageView
                                                        file={filename}
                                                        closeHandle={closePhotoModal}
                                                        saveHandle={changePhoto}
                                                    />
                                                )}
                                                {!photoModalIsOpen && (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                        {isDragActive ? (
                                                            <>
                                                                <div className='cloud-img-block d-inline-block rounded-circle mb-3 active'>
                                                                    <img
                                                                        src='/media/icons/base/upload-cloud.svg'
                                                                        className='add-profile-img'
                                                                    />
                                                                </div>
                                                                <div>Отпустите файл для подготовки к загрузке...</div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    className={
                                                                        'cloud-img-block d-inline-block rounded-circle mb-3' +
                                                                        (selectedFile.length > 0 ? ' active' : '')
                                                                    }
                                                                >
                                                                    <img
                                                                        src='/media/icons/base/upload-cloud.svg'
                                                                        className='add-profile-img'
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <span className='btn-link av-text-primary fw-bolder cursor-pointer'>
                                                                        Нажмите для выбора фото
                                                                    </span>{' '}
                                                                    или перетяните изображение в эту область PNG или JPG
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {selectedFile.length > 0 ? (
                                                <div className='card border round'>
                                                    <div className='card-body d-flex p-3'>
                                                        <div className='cloud-img-block d-inline-block rounded-circle active me-3'>
                                                            <img
                                                                src='/media/icons/base/file.svg'
                                                                className='add-profile-img'
                                                                style={{
                                                                    width: 22,
                                                                    height: 22,
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className='av-text-gray-700 fw-bolder'>
                                                                {selectedFile[0].name}
                                                            </div>
                                                            <div className='av-text-gray-500'>
                                                                {HumanFileSize(selectedFile[0].size)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='lastName'>Фамилия</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='lastName'
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            tabIndex={1}
                                        />
                                    </div>
                                </div>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='phone'>Номер телефона</label>
                                        <InputMask
                                            mask='+7(999)999-99-99'
                                            className='form-control'
                                            id='phone'
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            placeholder='+7(___)___-__-__'
                                            tabIndex={4}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='firstName'>Имя</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='firstName'
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            tabIndex={2}
                                        />
                                    </div>
                                </div>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='occupation'>Должность</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='occupation'
                                            value={occupation}
                                            onChange={(e) => setOccupation(e.target.value)}
                                            tabIndex={5}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='secondName'>Отчество</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='secondName'
                                            value={secondName}
                                            onChange={(e) => setSecondName(e.target.value)}
                                            tabIndex={3}
                                        />
                                    </div>
                                </div>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='persNumber'>Табельный номер</label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='persNumber'
                                            value={persNumber}
                                            onChange={(e) => setPersNumber(e.target.value)}
                                            tabIndex={6}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='dateStart'>Дата начала действия</label>
                                        <input
                                            type='date'
                                            className='form-control'
                                            id='dateStart'
                                            value={dateStart}
                                            onChange={(e) => setDateStart(e.target.value)}
                                            tabIndex={7}
                                        />
                                    </div>
                                </div>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='dateFinish'>Дата окончания действия</label>
                                        <input
                                            type='date'
                                            className='form-control'
                                            id='dateFinish'
                                            value={dateFinish}
                                            onChange={(e) => setDateFinish(e.target.value)}
                                            tabIndex={8}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-5'>
                                <div className='col-md'>
                                    <div className='form-group'>
                                        <label htmlFor='comments'>Комментарий</label>
                                        <textarea
                                            className='form-control'
                                            id='comments'
                                            value={comments}
                                            onChange={(e) => setComments(e.target.value)}
                                            tabIndex={9}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer justify-content-between py-3'>
                            <div className='form-group form-check pull-left'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id='blocked'
                                    checked={blocked}
                                    onChange={(e) => setBlocked(e.target.checked)}
                                />
                                <label className='form-check-label' htmlFor='blocked'>
                                    Заблокирован
                                </label>
                            </div>
                            <div>
                                <button type='button' className='btn av-btn-simple me-3' onClick={closeHandle}>
                                    Отменить
                                </button>
                                <button type='button' className='btn av-btn-primary' onClick={saveEmployee}>
                                    {employeeId ? 'Сохранить изменения' : 'Добавить сотрудника'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default EmployeeCreateModal;
