import { useEffect, useState } from 'react';
import { useGetSchemesQuery } from '../../app/storeReducers/schemesApiSlice';

const DeviceSchemeCreateModal = (props) => {
    const { accountId, isOpen, closeHandle, okHandle } = props;

    const { data: schemes } = useGetSchemesQuery(
        { accountId: accountId },
        { skip: !accountId }
    );
    const [schemeSelected, setSchemeSelected] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (schemes?.result?.length > 0) {
                setSchemeSelected(schemes.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [schemes]);

    const saveEntity = async () => {
        okHandle(schemes?.result?.find((x) => x.id === parseInt(schemeSelected, 10)));
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить новую схему
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-3'>
                                <label className='form-label'>Тип</label>
                                <select
                                    className='form-select'
                                    value={schemeSelected}
                                    onChange={(e) =>
                                        setSchemeSelected(e.target.value)
                                    }
                                >
                                    {schemes?.result?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeviceSchemeCreateModal;
