import { baseApi } from "../baseApi";

export const eventsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getEvents: builder.query({
            query: (query) =>
                `events?accountIds=${query?.accountIds ?? ""}&entityType=${
                    query?.entityType ?? ""
                }&entityId=${query?.entityId ?? ""}&start=${
                    query?.start ?? ""
                }&finish=${query?.finish ?? ""}&withChilds=${
                    query?.withChilds ?? false
                }&pageIndex=${query?.pageIndex ?? 1}&pageSize=${
                    query?.pageSize ?? 50
                }`,
        }),
    }),
});

export const { useGetEventsQuery, useLazyGetEventsQuery } = eventsApiSlice;
