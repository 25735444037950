import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addNotification } from "../../../app/storeReducers/NotifySlice";
import SkeletonTableFull from "../../../components/skeleton/SkeletonTableFull";
import { ToUtcString } from "../../../helpers/DateHelpers";
import ReportParkingDetailsModal from "./ReportParkingDetailsModal";
import { GetMonthName } from "../../../helpers/DateHelpers";
import {
    useLazyExportParkingBillingQuery,
    useLazyGetParkingBillingQuery,
} from "../../../app/storeReducers/parkingBillingApiSlice";

const ReportParkingList = (props) => {
    const { accountId } = props;

    const dispatch = useDispatch();

    const [dateReport, setDateReport] = useState(new Date());
    const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
    const [currentDetails, setCurrentDetails] = useState([]);
    const [report, setReport] = useState([]);

    const [getParkingBilling, { isLoading }] = useLazyGetParkingBillingQuery();
    const [exportParkingBilling] = useLazyExportParkingBillingQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const dtStart = dateReport;
            const dtStartString = `${dtStart.getFullYear()}-${
                dtStart.getMonth() + 1
            }-01`;
            try {
                const getResult = await getParkingBilling({
                    accountId,
                    date: dtStartString,
                }).unwrap();
                const aaa = getResult?.result?.map((item, index) => ({
                    ...item,
                    keyItem: index,
                    accountName: item.account?.title,
                    btn: (
                        <button
                            className="btn av-btn-simple"
                            onClick={() => openDetails(item.details)}
                        >
                            Подробно
                        </button>
                    ),
                }));
                setReport(aaa);
            } catch (error) {
                dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: "Ошибка!",
                        description:
                            "Перезагрузите страницу и попробуйте снова! " +
                            error.message,
                        type: "danger",
                    })
                );
            }
        }

        loadResourcesAndDataAsync();
    }, [accountId, dateReport]);

    const openDetails = (details) => {
        setCurrentDetails(details);
        setIsModalDetailOpen(true);
    };

    const exportData = async () => {
        const dtStart = dateReport;
        const dtStartString = `${dtStart.getFullYear()}-${
            dtStart.getMonth() + 1
        }-01`;
        const response = await exportParkingBilling({
            accountId,
            date: dtStartString,
        });
        const monthName = GetMonthName(dateReport);
        const a = document.createElement("a");
        a.href = response.data.url;
        a.download = `Отчет по услугам парковки - ${monthName} ${dtStart.getFullYear()}.xlsx`;
        a.click();
        URL.revokeObjectURL(response.data.url);
    };

    return (
        <>
            <ReportParkingDetailsModal
                isOpen={isModalDetailOpen}
                entity={currentDetails}
                closeHandle={() => setIsModalDetailOpen(false)}
            />
            <div className="card border">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column"></h3>
                    <div className="card-toolbar">
                        <div className="input-group" style={{ width: "auto" }}>
                            <input
                                type="month"
                                className="form-control"
                                value={ToUtcString(dateReport)
                                    .split("T")[0]
                                    .slice(0, -3)}
                                onChange={(e) =>
                                    setDateReport(new Date(e.target.value))
                                }
                            />
                        </div>
                        <button
                            className="btn av-btn-simple ms-3"
                            onClick={exportData}
                        >
                            <i className="far fa-file-excel me-3"></i> Экспорт в
                            Excel
                        </button>
                    </div>
                </div>
                <div className="table-responsive">
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: "keyItem",
                            accountName: "Аккаунт",
                            service: "Название",
                            total: "Стоимость",
                            btn: "",
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="mb-0"
                        entity={report}
                        tdClasses={{ accountName: "ps-6", total: "text-end" }}
                    />
                </div>
            </div>
        </>
    );
};

export default ReportParkingList;
