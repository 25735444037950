const RequestEditModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle } = props;

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Редактировать анкету
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>
                                    Выберите участок/квартиру
                                </label>
                                <input className='form-select' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Фамилия</label>
                                <input className='form-control' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Имя</label>
                                <input className='form-control' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Отчество</label>
                                <input className='form-control' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>Номер телефона</label>
                                <input className='form-control' />
                            </div>
                            <div className='form-group mb-5'>
                                <label htmlFor='login'>
                                    Номера автомобилей
                                </label>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='input-group'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='carNumber'
                                                placeholder='А000АА'
                                            />
                                            <input
                                                type='number'
                                                className='form-control'
                                                id='carRegion'
                                                style={{ maxWidth: '80px' }}
                                                placeholder='777'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='input-group'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='carNumber'
                                                placeholder='А000АА'
                                            />
                                            <input
                                                type='number'
                                                className='form-control'
                                                id='carRegion'
                                                style={{ maxWidth: '80px' }}
                                                placeholder='777'
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='input-group'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                id='carNumber'
                                                placeholder='А000АА'
                                            />
                                            <input
                                                type='number'
                                                className='form-control'
                                                id='carRegion'
                                                style={{ maxWidth: '80px' }}
                                                placeholder='777'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer py-2'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                            >
                                Разрешить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default RequestEditModal;
