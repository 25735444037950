import { baseApi } from "../baseApi";

export const screenshotsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getScreenshot: builder.query({
            query: (key) => `screenshots?key=${key}`,
        }),
    }),
});

export const { useGetScreenshotQuery, useLazyGetScreenshotQuery } =
    screenshotsApiSlice;
