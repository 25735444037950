import PermissionPage from '../../components/PermissionPage';
import SubaccountsList from './SubaccountsList';

const SubaccountsPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['subaccount_list']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Субаккаунты</h1>
                    <SubaccountsList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default SubaccountsPage;
