import { useEffect, useState } from "react";
import SkeletonInput from "../../../components/skeleton/SkeletonInput";
import {
    useGetMonitorByIdQuery,
    useUpdateMonitorMutation,
} from "../../../app/storeReducers/AccountMonitorAPI";
import MonitorDeleteModal from "../MonitorDeleteModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../../app/storeReducers/AuthSlice";

const MonitorEditPanel = (props) => {
    const { accountId, entityId } = props;

    const [updateMonitor] = useUpdateMonitorMutation();

    const [name, setName] = useState("");

    const context = useSelector(selectContext);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { data: monitor, isLoading } = useGetMonitorByIdQuery(entityId, {
        skip: !entityId,
    });

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (monitor) {
                setName(monitor?.result.name);
            }
        }

        loadResourcesAndDataAsync();
    }, [monitor]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("monitor_edit"));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            account: { id: accountId },
            id: entityId,
        };
        updateMonitor(entity);
    };

    return (
        <>
            <MonitorDeleteModal
                entity={{ id: entityId, name, account: { id: accountId } }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className="card border">
                <div className="card-body pb-2">
                    <div className="row">
                        <div className="col mb-2">
                            <h3>Монитор</h3>
                        </div>
                    </div>
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="name"
                            label="Название"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            {canEdit ? (
                                <button
                                    className="btn av-btn-danger fw-boldest"
                                    onClick={() => setDeleteModalIsOpen(true)}
                                >
                                    <i className="far fa-trash-alt me-2" />{" "}
                                    Удалить
                                </button>
                            ) : null}
                        </div>
                        <div className="col text-end">
                            <button
                                type="button"
                                className="btn av-btn-simple fw-boldest"
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MonitorEditPanel;
