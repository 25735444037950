import React, { useEffect, useState } from 'react';
import { Http } from '../../../../../../http';
import {
    createAsync,
    updateAsync,
} from '../../../../../../app/storeReducers/AccountServicesSlice';
import { useDispatch } from 'react-redux';
import SkeletonSelect from '../../../../../../components/skeleton/SkeletonSelect';

const ServiceCreateModal = (props) => {
    const { accountId, entityId, consumerId, placeId, isOpen, closeHandle } =
        props;

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [dateStart, setDateStart] = useState('');
    const [dateFinish, setDateFinish] = useState('');
    const [servicesList, setServicesList] = useState([]);
    const [serviceSelected, setServiceSelected] = useState(0);
    const [accounts, setAccounts] = useState([]);
    const [accountSelected, setAccountsSelected] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getTariffs = await Http.get(
                'api/parking/tariffs?accountId=' + accountId
            );
            setServicesList(getTariffs.result);
            setServiceSelected(getTariffs.result[0].id);

            setAccountsSelected('');
            const accountsResult = await Http.get(
                `api/accounts/${accountId}/subaccounts`
            );
            accountsResult.result.map((item) => {
                item.description = item.name;
                item.name = item.id;
            });
            setAccounts(accountsResult.result);
            if (accountsResult.result.length > 0) {
                setAccountsSelected(accountsResult.result[0].id);
            }
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== 0 && entityId !== -1) {
                const getResult = await Http.get(
                    'api/services/' + entityId,
                    true
                );
                if (getResult.result.dateStart !== null) {
                    setDateStart(
                        getResult.result.dateStart.substr(
                            0,
                            getResult.result.dateStart.length - 9
                        )
                    );
                } else {
                    setDateStart('');
                }
                if (getResult.result.dateFinish !== null) {
                    setDateFinish(
                        getResult.result.dateFinish.substr(
                            0,
                            getResult.result.dateFinish.length - 9
                        )
                    );
                } else {
                    setDateFinish('');
                }
                if (getResult.result.tariff != null) {
                    setServiceSelected(getResult.result.tariff.id);
                } else {
                    setServiceSelected(servicesList[0].id);
                }
                if (getResult.result.consumer != null) {
                    setAccountsSelected(getResult.result.consumer.id);
                } else {
                    setAccountsSelected(accounts[0].id);
                }
            } else {
                if (servicesList.length > 0) {
                    setServiceSelected(servicesList[0].id);
                }
                setDateStart('');
                setDateFinish('');
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId, servicesList]);

    const saveEntity = async () => {
        const entity = {
            supplier: {
                id: accountId,
            },
            consumer: {
                id: accountSelected,
            },
            resource: {
                id: placeId,
                type: 'place',
            },
            tariff: {
                id: serviceSelected,
            },
            dateStart: dateStart.length > 0 ? dateStart + 'T00:00:00' : '',
            dateFinish: dateFinish.length > 0 ? dateFinish + 'T00:00:00' : '',
            id: entityId,
        };
        if (entityId === 0) {
            dispatch(createAsync(entity));
        } else {
            dispatch(updateAsync(entity));
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить новую услугу
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-3'>
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label='Аккаунты'
                                    value={accountSelected}
                                    onChange={(e) =>
                                        setAccountsSelected(e.target.value)
                                    }
                                    itemsArray={accounts}
                                />
                            </div>
                            <div className='mb-3'>
                                <div className='form-group'>
                                    <label>Тариф</label>
                                    <select
                                        className='form-select'
                                        value={serviceSelected}
                                        onChange={(e) =>
                                            setServiceSelected(e.target.value)
                                        }
                                    >
                                        {servicesList.map((item) => (
                                            <option
                                                key={item.id}
                                                value={item.id}
                                            >
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='dateStart'>
                                        Дата начала действия
                                    </label>
                                    <input
                                        type='date'
                                        className='form-control'
                                        id='dateStart'
                                        value={dateStart}
                                        onChange={(e) =>
                                            setDateStart(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className='mb-3'>
                                <div className='form-group'>
                                    <label htmlFor='dateFinish'>
                                        Дата окончания действия
                                    </label>
                                    <input
                                        type='date'
                                        className='form-control'
                                        id='dateFinish'
                                        value={dateFinish}
                                        onChange={(e) =>
                                            setDateFinish(e.target.value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default ServiceCreateModal;
