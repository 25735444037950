import { useEffect, useState } from "react";
import SkeletonTableFull from "../../../components/skeleton/SkeletonTableFull";
import { useSelector } from "react-redux";
import { selectContext } from "../../../app/storeReducers/AuthSlice";
import { useGetDevicesQuery } from "../../../app/storeReducers/devicesApiSlice";

const LocationDevicesPanel = (props) => {
    const { accountId, entityId } = props;

    const context = useSelector(selectContext);

    const [canList, setCanList] = useState(false);

    const { data: devices, isLoading } = useGetDevicesQuery(
        { accountId, locationId: entityId },
        { skip: !accountId || !entityId }
    );

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanList(context.isRoot || actions?.includes("devices_list"));
    }, [context, accountId]);

    return (
        <>
            <div className="card border">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <h3>
                                Устройства{" "}
                                <span className="badge rounded-pill bg-light text-dark">
                                    {devices?.result.length}
                                </span>
                            </h3>
                        </div>
                    </div>
                </div>
                {canList ? (
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: "id",
                            name: "Название",
                            uid: "UID",
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="mb-0"
                        entity={devices?.result}
                        tdClasses={{ name: "ps-6" }}
                    />
                ) : (
                    <div className="card-body">
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default LocationDevicesPanel;
