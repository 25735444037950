import React from 'react';

const SkeletonTable = (props) => {
    const { cols, rowsCount, isLoading, classTable, children } = props;

    const tr = () => {
        let td = [];
        for (let index = 0; index < cols.length; index++) {
            td.push(<td key={index}></td>);
        }

        let tr = [];
        for (let index = 0; index < rowsCount; index++) {
            tr.push(
                <tr key={index} className='skeleton skeleton-tr'>
                    {td}
                </tr>
            );
        }
        return tr;
    };

    return (
        <>
            {isLoading ? (
                <table className={'table ' + classTable}>
                    <thead>
                        <tr className='fw-bold text-gray-800 border-bottom border-gray-200'>
                            {cols.map((item, index) => {
                                return <th key={index}>{item}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>{tr()}</tbody>
                </table>
            ) : (
                <>{children}</>
            )}
        </>
    );
};

export default SkeletonTable;
