import { useEffect, useState } from "react";
import {
    useCreateGroupMutation,
    useGetGroupByIdQuery,
    useUpdateGroupMutation,
} from "../../app/storeReducers/groupsApiSlice";

const GroupCreateModal = (props) => {
    const { accountId, groupId, isOpen, closeHandle } = props;

    const [name, setName] = useState("");

    const { data: group } = useGetGroupByIdQuery(groupId, { skip: !groupId });
    const [createGroupAsync] = useCreateGroupMutation();
    const [updateGroupAsync] = useUpdateGroupMutation();

    useEffect(() => {
        if (groupId === undefined) {
            setName("");
        }
    }, [isOpen]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (group) {
                setName(group.result.name);
            } else {
                setName("");
            }
        }

        loadResourcesAndDataAsync();
    }, [group]);

    const saveGroup = async () => {
        const group = {
            name,
            account: {
                id: accountId,
            },
            id: groupId,
        };
        if (groupId === 0) {
            await createGroupAsync(group);
        } else {
            await updateGroupAsync(group);
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить новую группу
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-3">
                                <label htmlFor="firstName">Название</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveGroup}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default GroupCreateModal;
