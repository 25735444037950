import { useEffect, useState } from 'react';
import { MakeID } from '../../helpers/StringHelper';
import { useCreateDeviceMutation, useLazyGetDeviceByIdQuery, useUpdateDeviceMutation } from '../../app/storeReducers/devicesApiSlice';

const TimeTrackingDeviceCreateModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle, locations } = props;

    const [name, setName] = useState('');
    const [uid, setUid] = useState(MakeID(8));
    const [type, setType] = useState('turnstile');
    const [mode, setMode] = useState('door-magneto');
    const [openTimeout, setOpenTimeout] = useState(0);
    const [gercon, setGercon] = useState(false);
    const [beeper, setBeeper] = useState(false);
    const [emergencyInput, setEmergencyInput] = useState('none');
    const [readerType, setReaderType] = useState('wiegand26');
    const [managedByMobile, setManagedByMobile] = useState(false);
    const [timeTracking, setTimeTracking] = useState(false);
    const [isCardPicker, setIsCardPicker] = useState(false);
    const [locationId, setLocationId] = useState(0);
    const [peerIp, setPeerIp] = useState('');
    const [peerPort, setPeerPort] = useState(0);
    const [apiPassword, setApiPassword] = useState('');
    const [defaultDirection, setDefaultDirection] = useState(true);

    const [getDeviceById] = useLazyGetDeviceByIdQuery()
    const [createDeviceAsync] = useCreateDeviceMutation();
    const [updateDeviceAsync] = useUpdateDeviceMutation();

    useEffect(() => {
        if (entityId <= 0) {
            setName('');
            setUid(MakeID(8));
            setType('turnstile');
            setMode('door-magneto');
            setOpenTimeout(0);
            setGercon(false);
            setBeeper(false);
            setEmergencyInput('none');
            setReaderType('wiegand26');
            setManagedByMobile(false);
            setTimeTracking(false);
            setIsCardPicker(false);
            setLocationId(0);
            setPeerIp('');
            setPeerPort(0);
            setApiPassword('');
            setDefaultDirection(true);
        }
    }, [isOpen]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== undefined && entityId !== 0 && entityId !== -1) {
                const getResult = await getDeviceById(entityId).unwrap();
                setName(getResult.result?.name);
                setUid(getResult.result?.uid);
                setLocationId(getResult.result?.location?.id);
            } else {
                setName('');
                setUid(MakeID(8));
                setType('turnstile');
                setMode('door-magneto');
                setOpenTimeout(0);
                setGercon(false);
                setBeeper(false);
                setEmergencyInput('none');
                setReaderType('wiegand26');
                setManagedByMobile(false);
                setTimeTracking(false);
                setIsCardPicker(false);
                setPeerIp('');
                setPeerPort(0);
                setApiPassword('');
                setDefaultDirection(true);
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    const saveEntity = async () => {
        const entity = {
            provider: 'timetracking',
            name,
            mode,
            type,
            uid,
            account: { id: accountId },
            openTimeout,
            gercon,
            beeper,
            emergencyInput,
            id: entityId,
            readerType,
            managedByMobile,
            timeTracking,
            isCardPicker,
            location: { id: locationId },
            peer_ip: peerIp,
            peer_port: peerPort,
            apiPassword,
            defaultDirection,
        };
        if (entityId > 0) {
            updateDeviceAsync(entity);
        } else {
            createDeviceAsync(entity);
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                {entityId > 0
                                    ? 'Редактировать'
                                    : 'Добавить новое'}{' '}
                                устройство УРВ
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body pb-2'>
                            <div className='row mb-3'>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='name'>
                                            Название устройства
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='name'
                                            value={name}
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='uid'>
                                            UID - идентификатор устройства
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='uid'
                                            value={uid}
                                            onChange={(e) =>
                                                setUid(e.target.value)
                                            }
                                            disabled={entityId > 0}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-3'>
                                <div className='col-md-4 mb-2'>
                                    <div className='form-group'>
                                        <label>Объект</label>
                                        <select
                                            className='form-select'
                                            value={locationId}
                                            onChange={(e) =>
                                                setLocationId(e.target.value)
                                            }
                                        >
                                            {locations?.map((item) => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer py-2'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Отменить
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                {entityId > 0
                                    ? 'Сохранить изменения'
                                    : 'Добавить устройство'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default TimeTrackingDeviceCreateModal;
