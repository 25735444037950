import { CRUDReducer } from './CRUDReducer';

export const baseReducer = new CRUDReducer('adminAccounts', 'accounts');

export const { getListAsync, createAsync, updateAsync, deleteAsync } =
    baseReducer;

export const adminAccountsSlice = baseReducer.reducerSlice;

export const { setPageSize, selectPage } = adminAccountsSlice.actions;

export const selectList = (state) => state.adminAccounts.objectsArray;
export const selectIsLoading = (state) => state.adminAccounts.isLoading;
export const selectPaginator = (state) => state.adminAccounts.paginator;

export default adminAccountsSlice.reducer;
