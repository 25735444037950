import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    DateToString,
    UtcToLocal,
} from "../../../../../../helpers/DateHelpers";
import SkeletonTable from "../../../../../../components/skeleton/SkeletonTable";
import ServiceDeleteModal from "./ServiceDeleteModal";
import { selectContext } from "../../../../../../app/storeReducers/AuthSlice";
import { useGetServicesQuery } from "../../../../../../app/storeReducers/servicesApiSlice";

const ServicesList = (props) => {
    const { accountId, placeId, selectEditEntity } = props;

    const { data: entityList, isLoading } = useGetServicesQuery({
        accountId,
        placeId,
    });

    const context = useSelector(selectContext);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("services_edit"));
    }, [context, accountId]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedEntity, setDeletedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const selectDeleteEntity = (entityId) => {
        setDeletedEntity(entityId);
        setDeleteModalIsOpen(true);
    };

    return (
        <>
            <ServiceDeleteModal
                entity={deletedEntity}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className="table-responsive">
                <SkeletonTable
                    cols={["Тариф", "Дата начала", "Дата окончания", ""]}
                    rowsCount={1}
                    isLoading={isLoading}
                    classTable="table-striped"
                >
                    <table className="table mb-0">
                        <thead>
                            <tr className="fw-bold av-text-gray-500 border-bottom">
                                <th className="border-bottom ps-6">Аккаунт</th>
                                <th className="border-bottom">Тариф</th>
                                <th className="border-bottom">Дата начала</th>
                                <th className="border-bottom">
                                    Дата окончания
                                </th>
                                <th className="border-bottom"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {entityList?.result?.map((item) => (
                                <tr key={item.id}>
                                    <td className="ps-6">
                                        {item.consumer?.title}
                                    </td>
                                    <td>
                                        {item.tariff != null
                                            ? item.tariff.title
                                            : ""}
                                    </td>
                                    <td>
                                        {item.dateStart != null
                                            ? DateToString(
                                                  UtcToLocal(item.dateStart)
                                              )
                                            : ""}
                                    </td>
                                    <td>
                                        {item.dateFinish != null
                                            ? DateToString(
                                                  UtcToLocal(item.dateFinish)
                                              )
                                            : " "}
                                    </td>
                                    <td>
                                        <div className="d-flex justify-content-end flex-shrink-0">
                                            {canEdit ? (
                                                <>
                                                    <button
                                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 px-2"
                                                        onClick={() =>
                                                            selectEditEntity(
                                                                item.id
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-pen text-muted" />
                                                    </button>
                                                    <button
                                                        className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 px-2"
                                                        onClick={() =>
                                                            selectDeleteEntity(
                                                                item
                                                            )
                                                        }
                                                    >
                                                        <i className="fas fa-trash-alt text-muted" />
                                                    </button>
                                                </>
                                            ) : null}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </SkeletonTable>
            </div>
        </>
    );
};

export default ServicesList;
