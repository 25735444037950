import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    DateToString,
    GetTime,
    UtcToLocal,
} from "../../../helpers/DateHelpers";
import SupportTaskCreateModal from "../SupportTaskCreateModal";
import { HubConnectionBuilder } from "@microsoft/signalr";
import {
    useAttachTicketFileMutation,
    useCloseTicketMutation,
    useCreateTicketMessageMutation,
    useGetTicketByIdQuery,
    useGetTicketMessagesQuery,
    useLazyGetTicketFileQuery,
    useUpdateTicketMutation,
} from "../../../app/storeReducers/ticketsApiSlice";
import { selectContext } from "../../../app/storeReducers/AuthSlice";

const SupportCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const ticketId = props.match.params.ticketId;

    const { data: ticket } = useGetTicketByIdQuery(ticketId, {
        skip: !ticketId,
    });
    const { data: messages } = useGetTicketMessagesQuery(ticketId, {
        skip: !ticketId,
    });

    const [updateAsync] = useUpdateTicketMutation();
    const [sendMessageAsync] = useCreateTicketMessageMutation();
    const [closeTicketAsync] = useCloseTicketMutation();
    const [attachTicketFileAsync] = useAttachTicketFileMutation();
    const [getTicketFileAsync] = useLazyGetTicketFileQuery();

    const [newMessage, setNewMessage] = useState("");
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [socketMessages, setSocketMessages] = useState([]);

    const context = useSelector(selectContext);
    const [accountActions, setAccountActions] = useState([]);

    const [connection, setConnection] = useState(null);
    useEffect(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_PLATFORM_URL}ws/tickets`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection
                .start()
                .then((result) => {
                    console.log("Connected!");
                    connection
                        .invoke("JoinGroup", `tickets_${ticketId}`) //JoinGroup is C# method name
                        .catch((err) => {
                            console.log(err);
                        });

                    connection.on("ReceiveMessage", (message) => {
                        setSocketMessages((actualMessages) => [
                            ...actualMessages,
                            message,
                        ]);
                    });
                })
                .catch((e) => console.log("Connection failed: ", e));
        }
    }, [connection]);

    useEffect(() => {
        const actions = context?.accounts?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setAccountActions(actions);
    }, [context, accountId]);

    const sendMessage = async () => {
        const data = { ticketId, body: { text: newMessage } };
        sendMessageAsync(data);
        setNewMessage("");
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter" && event.ctrlKey) {
            sendMessage();
        }
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const applySupport = () => {
        let updatedTicket = { ...ticket };
        updatedTicket.isEscalated = true;
        updateAsync(updatedTicket);
    };

    const closeTicket = async () => {
        closeTicketAsync(ticketId);
    };

    const handleSetFile = async (event) => {
        const { files } = event.target;
        var data = new FormData();
        for (const file of files) {
            data.append("files", file, file.name);
        }
        attachTicketFileAsync({ ticketId, body: data });
    };

    const downloadFile = async (id, name) => {
        const response = await getTicketFileAsync(id);
        const a = document.createElement("a");
        a.href = response.data.url;
        a.download = name;
        a.click();
        URL.revokeObjectURL(response.data.url);
    };

    let ticketStatus = "";

    switch (ticket?.result?.status) {
        case "new":
            ticketStatus = "Новая";
            break;
        case "inprogress":
            ticketStatus = "Открыта";
            break;
        case "done":
            ticketStatus = "Закрыта";
            break;

        default:
            break;
    }

    let lastDateMessage = new Date("1970-01-01").toDateString();

    function GetTextBlock(props) {
        const message = props.message;
        if (message.type !== "span") {
            let items = message?.split("\n");
            return (
                <div>
                    {items.map((item, index) => {
                        return <div key={index}>{item}</div>;
                    })}
                </div>
            );
        } else return message;
    }

    const allMessages = [...(messages?.result ?? []), ...socketMessages];

    return (
        <>
            <SupportTaskCreateModal
                accountId={accountId}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
                ticketId={ticketId}
            />
            <h1 className="mb-5">{ticket?.result?.subject}</h1>
            <div className="row mb-5">
                <div className="col-md-8 align-self-center">
                    <div className="d-inline me-3">
                        <span className="text-muted">Номер: </span>{" "}
                        {ticket?.result?.id}
                    </div>
                    <div className="d-inline me-3">
                        <span className="text-muted">Статус: </span>{" "}
                        {ticketStatus}
                    </div>
                    <div className="d-inline me-3">
                        <span className="text-muted">Аккаунт и автор: </span>{" "}
                        {ticket?.result?.account?.title}{" "}
                        {accountActions?.includes("tickets_resolve") ? (
                            <Link
                                className="av-text-primary"
                                to={`/account/${accountId}/employees/${ticket?.result?.author?.id}`}
                            >
                                {ticket?.result?.author?.title}
                            </Link>
                        ) : (
                            ticket?.result?.author?.title
                        )}
                    </div>
                </div>
                <div className="col-md-4 text-end">
                    <button
                        className="btn av-btn-outline-success me-3"
                        onClick={applySupport}
                        disabled={
                            !(
                                ticket?.result?.canEscalate &&
                                !ticket?.result?.isEscalated
                            )
                        }
                    >
                        {ticket?.result?.canEscalate &&
                        !ticket?.result?.isEscalated
                            ? "Подключить техподдержку"
                            : "Техподдержка подключена"}
                    </button>
                    {ticket?.result?.status !== "done" ? (
                        <button
                            className="btn av-btn-outline-primary"
                            onClick={closeTicket}
                        >
                            Закрыть заявку
                        </button>
                    ) : null}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="card border">
                        <div className="card-body scroll-y h-500px">
                            <div
                                className={
                                    "d-flex mb-10 " +
                                    (ticket?.result?.author?.id ===
                                    context?.user?.id
                                        ? "justify-content-end"
                                        : "justify-content-start")
                                }
                            >
                                <div className="messageBox d-flex flex-column align-items-start">
                                    <div className="message-author d-flex mb-1">
                                        {ticket?.result?.author?.title}
                                        <span className="text-muted ms-2 text-end">
                                            {DateToString(
                                                UtcToLocal(
                                                    ticket?.result?.createdAt ??
                                                        "0001.01.01 00:00:00Z00"
                                                ),
                                                true
                                            )}
                                        </span>
                                    </div>
                                    <div
                                        className={
                                            "p-3 text-dark fw-bold text-start" +
                                            (ticket?.result?.author?.id ===
                                            context?.user?.id
                                                ? " message-my"
                                                : " message-other")
                                        }
                                    >
                                        {ticket?.result?.description}
                                    </div>
                                </div>
                            </div>
                            {allMessages?.map((item) => {
                                let isNewDate = false;
                                if (
                                    lastDateMessage !==
                                    new Date(item.time).toDateString()
                                ) {
                                    lastDateMessage = new Date(
                                        item.time
                                    ).toDateString();
                                    isNewDate = true;
                                }
                                return (
                                    <div key={item.id}>
                                        {isNewDate ? (
                                            <div className="mb-5 text-center text-muted">
                                                {DateToString(
                                                    UtcToLocal(item.time)
                                                )}
                                            </div>
                                        ) : null}
                                        <div
                                            className={
                                                "d-flex mb-5 " +
                                                (item.user.id ===
                                                context.user.id
                                                    ? "justify-content-end"
                                                    : "justify-content-start")
                                            }
                                        >
                                            {item.user?.id ===
                                            context?.user?.id ? null : (
                                                <div className="symbol symbol-40p me-3">
                                                    <img
                                                        src="/images/no-user.jpg"
                                                        className="rounded-circle"
                                                        style={{
                                                            width: 40,
                                                            height: 40,
                                                        }}
                                                    />
                                                </div>
                                            )}

                                            <div
                                                className={
                                                    "messageBox d-flex flex-column " +
                                                    (item.user?.id ===
                                                    context?.user?.id
                                                        ? "align-items-end"
                                                        : "align-items-start")
                                                }
                                            >
                                                <div
                                                    className="message-author d-flex mb-1 justify-content-between"
                                                    style={{ width: "100%" }}
                                                >
                                                    {item.user?.id ===
                                                    context?.user?.id ? (
                                                        <div>
                                                            Вы
                                                            <span className="text-muted ms-1">
                                                                (
                                                                {
                                                                    item.user
                                                                        .title
                                                                }
                                                                )
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            {item.user.title}
                                                        </div>
                                                    )}
                                                    <div className="text-muted ms-2">
                                                        {GetTime(
                                                            UtcToLocal(
                                                                item.time
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                                <div
                                                    className={`px-3 py-2 ${
                                                        item.user.id ===
                                                        context.user.id
                                                            ? "message-my"
                                                            : "message-other"
                                                    } text-dark fw-bold`}
                                                >
                                                    <GetTextBlock
                                                        message={
                                                            item.attachment ? (
                                                                <span>
                                                                    Скачать{" "}
                                                                    <a
                                                                        href="#"
                                                                        onClick={() => {
                                                                            downloadFile(
                                                                                item.id,
                                                                                item.text
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.text
                                                                        }
                                                                    </a>
                                                                </span>
                                                            ) : (
                                                                item.text
                                                            )
                                                        }
                                                    />
                                                    {/*item.text.split('\n').map(x=>{ <p>{x}</p> })*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="card-footer py-5">
                            {ticket?.result?.status !== "done" ? (
                                <div className="d-flex">
                                    <div className="flex-grow-1 me-3">
                                        <textarea
                                            className="form-control"
                                            placeholder="Ваш ответ"
                                            value={newMessage}
                                            onChange={(e) =>
                                                setNewMessage(e.target.value)
                                            }
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                    <div className="">
                                        <label className="btn av-btn-simple me-3">
                                            <i
                                                className="fas fa-paperclip"
                                                style={{ cursor: "pointer" }}
                                            ></i>
                                            <input
                                                type="file"
                                                name="files"
                                                placeholder="Image"
                                                onChange={(e) =>
                                                    handleSetFile(e)
                                                }
                                                style={{ display: "none" }}
                                            />
                                        </label>
                                        <button
                                            className="btn av-btn-primary"
                                            onClick={sendMessage}
                                        >
                                            <i className="far fa-paper-plane me-2" />
                                            Отправить
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SupportCardPage;
