import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';
import { addNotification } from './NotifySlice';

const nameSlice = 'accountDevices';
const urlSlice = 'devices';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    objectsArray: [],
    pageSize: 50,
    pageSelected: 1,
    paginator: [],
    sortCol: '',
    sortDestination: 'asc',
    selectedDevice: {},
};

export const getDeviceAsync = createAsyncThunk(
    `${nameSlice}/getDevice`,
    async (deviceId) => {
        const employeeResult = await Http.get('api/devices/' + deviceId);

        return employeeResult;
    }
);

export const getListAsync = createAsyncThunk(
    `${nameSlice}/getList`,
    async (specs) => {
        const getResult = await Http.get(
            `api/${urlSlice}?accountId=${specs.accountId}&pageIndex=${specs.pageIndex}&pageSize=${specs.pageSize}&search=${specs.searchString}&sort=${specs.sort}`
        );

        return getResult;
    }
);

export const createAsync = createAsyncThunk(
    `${nameSlice}/create`,
    async (entity) => {
        const createResult = await Http.post(`api/${urlSlice}`, true, entity);
        return createResult;
    }
);

export const updateAsync = createAsyncThunk(
    `${nameSlice}/update`,
    async (entity, thunkAPI) => {
        const response = await Http.put(
            `api/${urlSlice}/${entity.id}`,
            true,
            entity
        );

        if (response !== undefined && response != null) {
            if (
                response.error !== undefined &&
                response.error !== null &&
                response.error !== ''
            ) {
                thunkAPI.dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка сохранения!',
                        description: response.errorDescription
                            ? response.errorDescription
                            : 'Не удалось сохранить изменения! Перезагрузите страницу и попробуйте снова!',
                        type: 'danger',
                    })
                );
            } else {
                thunkAPI.dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Устройство сохранено!',
                        description: 'Данные устройства успешно сохранены!',
                        type: 'success',
                    })
                );
            }
        } else {
            thunkAPI.dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка сохранения!',
                    description:
                        'Не удалось сохранить данные устройства! Перезагрузите страницу и попробуйте снова!',
                    type: 'danger',
                })
            );
        }

        return response;
    }
);

export const deleteAsync = createAsyncThunk(
    `${nameSlice}/delete`,
    async (id) => {
        await Http.delete(`api/${urlSlice}/${id}`, true);
        return id;
    }
);

export const accountDevicesSlice = createSlice({
    name: nameSlice,
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        selectPage: (state, action) => {
            state.pageSelected = action.payload;
        },
        setSortCol: (state, action) => {
            state.sortCol = action.payload;
        },
        setSortDestination: (state, action) => {
            state.sortDestination = action.payload;
        },
    },
    extraReducers: {
        [getDeviceAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getDeviceAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.selectedDevice = action.payload.result;
            }
            state.isLoading = false;
        },
        [getDeviceAsync.rejected]: (state, action) => {
            console.error('getDeviceError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [getListAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getListAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.objectsArray = action.payload.result;
                state.paginator = action.payload.paginator;
            }
            state.isLoading = false;
        },
        [getListAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/getListError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [createAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.objectsArray.push(action.payload.result);
            }
        },
        [createAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/createError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [updateAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.selectedDevice = action.payload.result;
                const updateObj = state.objectsArray.map((item) => {
                    if (item.id === action.payload.result.id) {
                        item = action.payload.result;
                    }
                    return item;
                });
                state.objectsArray = updateObj;
            }
        },
        [updateAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/updateError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [deleteAsync.fulfilled]: (state, action) => {
            if (
                action.payload.error !== undefined &&
                action.payload.error != null
            ) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                const updateObj = state.objectsArray.filter(
                    (x) => x.id !== action.payload
                );
                state.objectsArray = updateObj;
            }
        },
        [deleteAsync.rejected]: (state, action) => {
            console.log(nameSlice + '/deleteError', action);
            state.isErrors = true;
            state.errorDescription =
                'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const { setPageSize, selectPage, setSortCol, setSortDestination } =
    accountDevicesSlice.actions;

export const selectDevice = (state) => state.accountDevices.selectedDevice;
export const selectList = (state) => state.accountDevices.objectsArray;
export const selectIsLoading = (state) => state.accountDevices.isLoading;
export const selectPaginator = (state) => state.accountDevices.paginator;

export default accountDevicesSlice.reducer;
