import { baseApi } from "../baseApi";

export const usersApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        setUserDemoOrder: builder.mutation({
            query: (body) => ({
                url: "users/demo_order",
                method: "POST",
                body,
            }),
        }),
    }),
});

export const { useSetUserDemoOrderMutation } = usersApiSlice;
