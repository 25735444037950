import { baseApi } from "../baseApi";

export const camerasApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getCameras: builder.query({
            query: (query) =>
                `cameras?accountId=${query?.accountId ?? ""}&deviceId=${
                    query?.deviceId ?? ""
                }&search=${query?.search ?? ""}&sort=${
                    query?.sort ?? ""
                }&pageIndex=${query?.pageIndex ?? 1}&pageSize=${
                    query?.pageSize ?? 50
                }`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Cameras",
                              id,
                          })),
                          { type: "Cameras", id: "List" },
                      ]
                    : [{ type: "Cameras", id: "List" }],
        }),
        getCameraById: builder.query({
            query: (id) => `cameras/${id}`,
            providesTags: (result) => [
                { type: "Camera", id: result?.result.id },
            ],
        }),
        createCamera: builder.mutation({
            query: (body) => ({
                url: "cameras",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Cameras", id: "List" }],
        }),
        updateCamera: builder.mutation({
            query: (body) => ({
                url: "cameras/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Camera", id: result?.result.id },
                { type: "Cameras", id: "List" },
            ],
        }),
        deleteCamera: builder.mutation({
            query: (id) => ({
                url: `cameras/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result) => [{ type: "Cameras", id: "List" }],
        }),
        getCameraDevice: builder.query({
            query: (id) => `cameras/${id}/devices`,
        }),
        addCameraDevice: builder.mutation({
            query: (data) => ({
                url: `cameras/${data.cameraId}/devices/${data.deviceId}`,
                method: "PUT",
            }),
        }),
        deleteCameraDevice: builder.mutation({
            query: (data) => ({
                url: `cameras/${data.cameraId}/devices/${data.deviceId}`,
                method: "DELETE",
            }),
        }),
        getCameraTranslations: builder.query({
            query: (id) => `cameras/${id}/translations`,
        }),
        getCameraImage: builder.query({
            query: (id) => ({
                url: `cameras/${id}/image`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
    }),
});

export const {
    useGetCamerasQuery,
    useGetCameraByIdQuery,
    useCreateCameraMutation,
    useUpdateCameraMutation,
    useDeleteCameraMutation,
    useAddCameraDeviceMutation,
    useDeleteCameraDeviceMutation,
    useGetCameraTranslationsQuery,
    useLazyGetCameraTranslationsQuery,
    useGetCameraImageQuery,
    useLazyGetCameraImageQuery,
    useGetCameraDeviceQuery,
} = camerasApiSlice;
