import { useEffect, useState } from "react";
import { DateToString, UtcToLocal } from "../../helpers/DateHelpers";
import { useDispatch, useSelector } from "react-redux";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import EmployeeCreateModal from "./EmployeeCreateModal";
import "bootstrap/dist/js/bootstrap.min.js";
import { Tooltip } from "bootstrap/dist/js/bootstrap.esm.min.js";
import Paginator from "../../components/Paginator";
import { useHistory } from "react-router-dom";
import FindComponent from "../../components/FindComponent";
import EmployeeImportModal from "./EmployeeImportModal";
import { HumanPhone } from "../../helpers/PhoneHelper";
import ThSortable from "../../components/table/ThSortable";
import { ACTIONS, STATUS } from "react-joyride";
import Onboarding from "../../components/onboarding/Onboarding";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import {
    useGetEmployeesQuery,
    useLazyExportEmployeesQuery,
} from "../../app/storeReducers/employeesApiSlice";

const EmployeesListTable = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const context = useSelector(selectContext);

    const [searchString, setSearchString] = useState("");
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [sort, setSort] = useState("last_name");
    const [sortDest, setSortDest] = useState("asc");
    const [empSort, setEmpSort] = useState("last_name");
    const [forcePage, setForcePage] = useState(0);

    const {
        data: employees,
        isLoading,
        refetch,
    } = useGetEmployeesQuery({
        accountId,
        search: searchString,
        sort: empSort,
        pageSize,
        pageIndex,
        thumbnail: true,
    });
    const [exportEmployees] = useLazyExportEmployeesQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [accountId, searchString, empSort, pageSize, pageIndex, dispatch]);

    const loadData = () => {
        setForcePage(pageIndex - 1);
    };

    useEffect(() => {
        Array.from(
            document.querySelectorAll('[data-bs-toggle="tooltip"]')
        ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    }, [isLoading]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("employees_edit"));
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [canEdit, setCanEdit] = useState(false);

    const [isOpenImportModal, setIsOpenImportModal] = useState(false);

    const [dropdownActionIsOpen, setDropdownActionIsOpen] = useState(false);

    const [steps, setSteps] = useState([
        {
            target: ".menu-link.active",
            disableBeacon: true,
            placement: "right",
            content: (
                <div className="text-start">
                    <div className="text-muted mb-2 step-panel">
                        Шаг 4 из 13
                    </div>
                    <div className="mb-1 ob-title">
                        <b>Раздел сотрудники</b>
                    </div>
                    <div className="mb-3 ob-text">
                        В этом разделе вы можете добавлять сотрудников в
                        систему, выдавать им пропуски и категории доступа.
                    </div>
                </div>
            ),
        },
        {
            target: "tr:nth-child(2)",
            disableBeacon: true,
            placement: "top",
            content: (
                <div className="text-start">
                    <div className="text-muted mb-2 step-panel">
                        Шаг 5 из 13
                    </div>
                    <div className="mb-1 ob-title">
                        <b>Список сотрудников</b>
                    </div>
                    <div className="mb-3 ob-text">
                        Основная информация о сотруднике видна в списке: имя,
                        телефон, время действия пропуска и статус сотрудника в
                        системе.
                    </div>
                </div>
            ),
        },
    ]);

    const openCreateModal = () => {
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(
                `/account/${accountId}/employees/${entityId}`,
                "_blank"
            );
        else history.push(`/account/${accountId}/employees/${entityId}`);
    };

    const downloadFile = async () => {
        setDropdownActionIsOpen(false);
        const response = await exportEmployees(accountId);
        const a = document.createElement("a");
        a.href = response.data.url;
        a.download = "Сотрудники.xlsx";
        a.click();
        URL.revokeObjectURL(response.data.url);
    };

    const okImport = () => {
        setIsOpenImportModal(false);
        loadData();
        refetch();
    };

    const columnFilter = (colName) => {
        const dest =
            colName === sort ? (sortDest === "asc" ? "desc" : "asc") : "asc";
        setSort(colName);
        setSortDest(dest);
        setEmpSort(colName + (dest === "desc" ? "_desc" : ""));
    };

    const onboardingCallback = (action, status, index) => {
        if (
            action === ACTIONS.CLOSE &&
            status === STATUS.FINISHED &&
            index === 1
        ) {
            history.push(
                `/account/${accountId}/employees/${employees?.result[1]?.id}`
            );
        }
    };

    return (
        <>
            {employees?.result?.length > 0 ? (
                <Onboarding
                    steps={steps}
                    run={!isLoading}
                    callback={onboardingCallback}
                />
            ) : null}
            <EmployeeCreateModal
                accountId={accountId}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <EmployeeImportModal
                accountId={accountId}
                isOpen={isOpenImportModal}
                closeHandle={() => setIsOpenImportModal(false)}
                okHandle={okImport}
            />
            <div className="row">
                <div className="col-md-4 mb-3">
                    <button
                        className="btn av-btn-primary"
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <img
                            src="/media/icons/avanguard/Application2.svg"
                            className="h-25px svg-img me-2"
                        />
                        Добавить сотрудника
                    </button>
                </div>
                <div className="col-md-8 mb-3 d-flex justify-content-end">
                    <FindComponent
                        callback={(name) => {
                            setSearchString(name);
                            changePage(1);
                        }}
                        placeholder="Поиск по сотрудникам"
                    />
                    {canEdit ? (
                        <>
                            <div className="dropdown d-inline-block ms-3">
                                <button
                                    className="btn av-btn-simple dropdown-toggle fw-bolder"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() =>
                                        setDropdownActionIsOpen(
                                            !dropdownActionIsOpen
                                        )
                                    }
                                >
                                    Действия
                                </button>
                                <ul
                                    className={
                                        "dropdown-menu" +
                                        (dropdownActionIsOpen ? " show" : "")
                                    }
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    <li>
                                        <button
                                            className="btn btn-link btn-sm dropdown-item"
                                            onClick={() => {
                                                setIsOpenImportModal(true);
                                                setDropdownActionIsOpen(false);
                                            }}
                                        >
                                            Импорт из Excel
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            className="btn btn-link btn-sm dropdown-item"
                                            onClick={downloadFile}
                                        >
                                            Экспорт в Excel
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="card mb-4 border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={[
                            "Сотрудник",
                            "Должность",
                            "Начало действия",
                            "Окончание действия",
                            "Статус",
                        ]}
                        rowsCount={15}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <ThSortable
                                        className="border-bottom ps-6"
                                        colName="last_name"
                                        colTitle="Сотрудник"
                                        colChanged={(name) =>
                                            columnFilter(name)
                                        }
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <th className="border-bottom pe-6">
                                        Должность
                                    </th>
                                    <ThSortable
                                        className="border-bottom"
                                        colName="start"
                                        colTitle="Начало действия"
                                        colChanged={(name) =>
                                            columnFilter(name)
                                        }
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <ThSortable
                                        className="border-bottom"
                                        colName="finish"
                                        colTitle="Окончание действия"
                                        colChanged={(name) =>
                                            columnFilter(name)
                                        }
                                        sortCol={sort}
                                        sortDestinaton={sortDest}
                                    />
                                    <th className="border-bottom pe-6">
                                        Статус
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className="cursor-pointer"
                                    >
                                        <td className="ps-6 d-flex align-self-center">
                                            <div className="symbol symbol-40p me-2">
                                                <img
                                                    src={
                                                        item.thumbnail ??
                                                        "/images/no-user.jpg"
                                                    }
                                                    className="rounded-circle"
                                                    style={{
                                                        width: 40,
                                                        height: 40,
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <div className="fw-boldest">
                                                    {item.name}
                                                </div>
                                                <div className="av-text-gray-500">
                                                    {item.user
                                                        ? HumanPhone(
                                                              item.user?.title,
                                                              true
                                                          )
                                                        : ""}
                                                </div>
                                            </div>
                                            {item.warnings ? (
                                                <div className="ms-4 mt-2">
                                                    <img
                                                        src={
                                                            "/media/icons/avanguard/alert-triangle.svg"
                                                        }
                                                        width={30}
                                                        alt="Есть ошибки"
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                        <td className="av-text-gray-500">
                                            {item.occupation}
                                        </td>
                                        <td className="av-text-gray-500 align-middle">
                                            {item.dateStart != null
                                                ? DateToString(
                                                      UtcToLocal(item.dateStart)
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="av-text-gray-500 align-middle">
                                            {item.dateFinish != null
                                                ? DateToString(
                                                      UtcToLocal(
                                                          item.dateFinish
                                                      )
                                                  )
                                                : "-"}
                                        </td>
                                        <td className="align-middle">
                                            {item.blocked ? (
                                                <span className="badge bg-light-danger rounded-pill">
                                                    Заблокирован
                                                </span>
                                            ) : item.roles.length > 0 ? (
                                                item.roles.some((x) =>
                                                    x
                                                        .toLowerCase()
                                                        .startsWith("адми")
                                                ) > 0 ? (
                                                    <span className="badge badge-sm bg-light-success rounded-pill">
                                                        Admin
                                                    </span>
                                                ) : (
                                                    <span className="badge badge-sm bg-light-secondary rounded-pill">
                                                        www
                                                    </span>
                                                )
                                            ) : null}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className="card-body py-3 px-5">
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={employees?.paginator?.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={employees?.result?.length}
                        countTotal={employees?.paginator?.totalCount}
                        forcePage={forcePage}
                    />
                </div>
            </div>
        </>
    );
};

export default EmployeesListTable;
