import { useEffect, useState } from "react";
import SearchableList from "./SearchableList";
import OutsideClickHandler from "./OutsideClickHandler";

const SelectWithFindInput = (props) => {
    const { selectCallback, list, value, textChanged } = props;

    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        setIsEdit(false);
    }, [value]);

    const selectValue = (value) => {
        selectCallback(value);
    };

    return (
        <div className="position-relative" onClick={() => setIsEdit(true)}>
            <input
                type="text"
                className="form-select"
                value={list?.find((x) => x.id === value)?.name}
            />
            {isEdit ? (
                <OutsideClickHandler
                    callback={() => setIsEdit(false)}
                    classes={"select-with-find border rounded shadow-sm"}
                >
                    <>
                        <SearchableList
                            value={value}
                            list={list}
                            searchCallback={(search) => textChanged(search)}
                            selectCallback={(value) => selectValue(value)}
                        />
                    </>
                </OutsideClickHandler>
            ) : null}
        </div>
    );
};

export default SelectWithFindInput;
