import { FormatDateToHuman, UtcToLocal } from '../../helpers/DateHelpers';
import { baseApi } from '../baseApi';

export const NotificationsAPI = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query({
            query: ({
                accountId = 0,
                onlyNew = false,
                pageIndex = 1,
                pageSize = 100,
                rnd = 0,
            }) =>
                `notifications/web?accountId=${accountId}&pageIndex=${pageIndex}&pageSize=${pageSize}&isNew=${onlyNew}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: 'Notifications',
                              id,
                          })),
                          { type: 'Notifications', id: 'List' },
                      ]
                    : [{ type: 'Notifications', id: 'List' }],
            transformResponse: (response) => {
                response?.result.forEach((item, index) => {
                    item.dtString = FormatDateToHuman(
                        UtcToLocal(item.time),
                        true
                    );
                    item.accountTitle = item.account.title;
                });
                console.log(response);
                return response;
            },
        }),

        readNotification: builder.mutation({
            query: (id) => ({
                url: 'notifications/web/read/' + id,
                method: 'PUT',
                body: null,
            }),
            invalidatesTags: [{ type: 'Notifications', id: 'List' }],
        }),

        readAllNotification: builder.mutation({
            query: () => ({
                url: 'notifications/web/read/all',
                method: 'PUT',
                body: null,
            }),
            invalidatesTags: [{ type: 'Notifications', id: 'List' }],
        }),
    }),
});

export const {
    useGetNotificationsQuery,
    useReadNotificationMutation,
    useReadAllNotificationMutation,
} = NotificationsAPI;
