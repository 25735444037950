import { CRUDReducer } from './CRUDReducer';

export const baseReducer = new CRUDReducer('support', 'tickets');

export const {
    getListAsync,
    getCurrentAsync,
    createAsync,
    updateAsync,
    deleteAsync,
} = baseReducer;

export const supportSlice = baseReducer.reducerSlice;

export const { setPageSize, selectPage } = supportSlice.actions;

export const selectList = (state) => state.support.objectsArray;
export const selectObject = (state) => state.support.currentObject;
export const selectIsLoading = (state) => state.support.isLoading;
export const selectIsCurrentLoading = (state) => state.support.isCurrentLoading;
export const selectPaginator = (state) => state.support.paginator;

export default supportSlice.reducer;
