import { useState, useEffect, useRef } from "react";
import { useGuestChangeCardMutation } from "../../../../app/storeReducers/guestsApiSlice";

const GuestModal = (props) => {
    const { isOpen, handleClose, handleOk, guest } = props;
    const inputElement = useRef(null);
    const [cardNumber, setCardNumber] = useState("");

    const [changeCard] = useGuestChangeCardMutation();

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    }, [isOpen]);

    const bindCard = async () => {
        const result = await changeCard({
            guestId: guest.id,
            number: cardNumber,
        }).unwrap();
        if (result.result) {
            handleOk(guest.id, cardNumber);
            setCardNumber("");
        } else {
            alert(result.error);
        }
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Выдача пропуска</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={handleClose}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <h4>{guest.name}</h4>
                            <div className="form-group">
                                <label htmlFor="pass">Номер пропуска</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="pass"
                                    autoFocus
                                    value={cardNumber}
                                    onChange={(e) =>
                                        setCardNumber(e.target.value)
                                    }
                                    ref={inputElement}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={handleClose}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={bindCard}
                            >
                                Выдать пропуск
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default GuestModal;
