import { useHistory } from "react-router";
import { useDeleteRoleMutation } from "../../app/storeReducers/rolesApiSlice";

const RoleDeleteModal = (props) => {
    const { entity, isOpen, closeHandle } = props;

    const history = useHistory();

    const [deleteAsync] = useDeleteRoleMutation();

    const deleteEntity = async () => {
        deleteAsync(entity.id);
        closeHandle();
        history.push(`/account/${entity.accountId}/admin/roles`);
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Удалить роль</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <p>
                                Вы действительно хотите удалить роль{" "}
                                <strong className="text-danger">
                                    {entity?.name}
                                </strong>
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-danger"
                                onClick={deleteEntity}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default RoleDeleteModal;
