import { useSelector } from "react-redux";
import { selectContext } from "../../app/storeReducers/AuthSlice";

const UniubiProperties = (props) => {
    const { device } = props;

    const context = useSelector(selectContext);
    return (
        <>
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="fild-label">UID</div>
                    <div className="fild-text">{device?.uid}</div>
                </div>
                <div className="col-md-6">
                    {context.isRoot ? (
                        <>
                            <div className="fild-label">Пароль API</div>
                            <div className="fild-text">
                                {device?.apiPassword}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="fild-label">Адрес</div>
                    <div className="fild-text">{device.peer_ip}</div>
                </div>
                <div className="col-md-6">
                    <div className="fild-label">Порт</div>
                    <div className="fild-text">{device?.peer_port}</div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="fild-label">Направление</div>
                    <div className="fild-text">
                        {device?.defaultDirection ? "Вход" : "Выход"}
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="fild-label">Объект</div>
                    <div className="fild-text">{device.location?.title}</div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-12">
                    {device?.managedByMobile ? (
                        <div className="border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2">
                            Управление через мобильное приложение
                        </div>
                    ) : null}
                    {device?.timeTracking ? (
                        <div className="border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2">
                            Учет рабочего времени
                        </div>
                    ) : null}
                    {device?.timeTrackingsButtons ? (
                        <div className="border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2">
                            Распознавание по кнопке
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default UniubiProperties;
