import { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import GroupAddSchemeModal from "./GroupAddSchemeModal";
import GroupSchemeDeleteModal from "./GroupSchemeDeleteModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import { useGetGroupSchemesQuery } from "../../../../app/storeReducers/groupsApiSlice";

const GroupSchemesPanel = (props) => {
    const { accountId, groupId } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [deleteScheme, setDeleteScheme] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const { data: schemes, refetch } = useGetGroupSchemesQuery(groupId);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("schemes_edit"));
        setCanList(context.isRoot || actions?.includes("schemes_list"));
    }, [context, accountId]);

    const okAddScheme = () => {
        refetch();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (scheme) => {
        setDeleteScheme(scheme);
        setDeleteModalIsOpen(true);
    };

    const deleteEmpScheme = () => {
        refetch();
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <GroupAddSchemeModal
                accountId={accountId}
                groupId={groupId}
                isOpen={addModalIsOpen}
                okHandle={okAddScheme}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <GroupSchemeDeleteModal
                groupId={groupId}
                member={deleteScheme}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteEmpScheme}
            />
            <div className="card border">
                <div className="card-body pb-2">
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <h3>
                                Схемы{" "}
                                <span className="badge rounded-pill bg-light text-dark">
                                    {schemes?.result.length}
                                </span>
                            </h3>
                        </div>
                        <div className="col-md-6 text-end">
                            {canEdit ? (
                                <button
                                    className="btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0"
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr075.svg"
                                        className="svg-icon-2 me-2"
                                    />
                                    Добавить схему
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col">
                            {canList ? (
                                schemes?.result.map((item) => (
                                    <div
                                        className="border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2"
                                        key={item.id}
                                    >
                                        {item.title}{" "}
                                        {canEdit ? (
                                            <i
                                                className="ms-2 fas fa-times cursor-pointer"
                                                onClick={() =>
                                                    openDeleteModal(item)
                                                }
                                            ></i>
                                        ) : null}
                                    </div>
                                ))
                            ) : (
                                <i>У вас нет прав на просмотр содержимого</i>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GroupSchemesPanel;
