import AccountsList from './AccountsList';

const AccountsPage = (props) => {
    return (
        <>
            <div className='row mt-4 mb-4'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Аккаунты</h1>
                    <AccountsList />
                </div>
            </div>
        </>
    );
};

export default AccountsPage;
