import { useContext, useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import SecutiryContext from '../../../../features/securityContext';
import ScheduleAddDeviceModal from './ScheduleAddDeviceModal';
import ScheduleDeleteDeviceModal from './ScheduleDeleteDeviceModal';
import { useGetDevicesQuery } from '../../../../app/storeReducers/DevicesAPI';
import SkeletonTable from '../../../../components/skeleton/SkeletonTable';

const ScheduleDevicesPanel = (props) => {
    const { accountId, entityId, isSubaccount } = props;

    const { data, isLoading } = useGetDevicesQuery({
        accountId,
        scheduleId: entityId
    });


    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('devices_edit'));
        setCanList(context.isRoot || actions?.includes('devices_list'));
    }, [context, accountId]);

    const okAddDevice = () => {
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteDevice = () => {
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <ScheduleAddDeviceModal
                accountId={accountId}
                entityId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddDevice}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <ScheduleDeleteDeviceModal
                entityId={entityId}
                device={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteDevice}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            <h3>
                                Устройства{' '}
                                <span className='badge rounded-pill bg-light text-dark'>
                                    {data?.result?.length}
                                </span>
                            </h3>
                        </div>
                        <div className='col-md-8 text-end'>
                            {canEdit && !isSubaccount ? (
                                <button
                                    className='btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0'
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <KTSVG
                                        path='/media/icons/duotune/arrows/arr075.svg'
                                        className='svg-icon-2 me-2'
                                    />
                                    Добавить устройство
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {canList ? (
                    <SkeletonTable
                        cols={['Название', 'UID', '']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='mb-0'
                    >
                        <table className='table mb-0 border-top'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>Название</th>
                                    <th className='border-bottom ps-6'>UID</th>
                                    <th className='border-bottom ps-6'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.result?.map((item) => (
                                    <tr key={item.id} style={{ verticalAlign: 'middle'}}>
                                        <td className='ps-6 av-text-gray-500'>
                                            {item.name}
                                        </td>
                                        <td className='ps-6 av-text-gray-500'>
                                            {item.uid}
                                        </td>
                                        <td className='ps-6 av-text-gray-500'>
                                            {canEdit && !isSubaccount ? (
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                    onClick={() => openDeleteModal(item)}
                                                >
                                                    <i className='fas fa-trash-alt' />
                                                </button>
                                            ): ''}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    </SkeletonTable>
                ) : (
                    <div className='card-body'>
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default ScheduleDevicesPanel;
