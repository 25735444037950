import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../http';
import { useDispatch } from 'react-redux';
import { updateAsync } from '../../../app/storeReducers/AccountParkingAreasSlice';
import SkeletonInput from '../../../components/skeleton/SkeletonInput';
import ParkingAreaDeleteModal from '../ParkingAreaDeleteModal';
import { useHistory } from 'react-router';
import SecutiryContext from '../../../features/securityContext';

const ParkingEditPanel = (props) => {
    const { accountId, entityId } = props;

    const dispatch = useDispatch();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');
    const [isForGuest, setIsForGuest] = useState(false);
    const [capacity, setCapacity] = useState(0);

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getResult = await Http.get('api/parking/areas/' + entityId, true);

            console.log('result', getResult.result);
            setName(getResult.result.name);
            setIsForGuest(getResult.result.allowForGuest);
            setCapacity(getResult.result.capacity);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    useEffect(() => {
        const actions = context?.permissions?.find((x) => x.id === parseInt(accountId))?.actions;
        setCanEdit(context.isRoot || actions?.includes('parking_area_edit'));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            account: { id: accountId },
            allowForGuest: isForGuest,
            id: entityId,
            capacity,
        };
        dispatch(updateAsync(entity));
    };

    const deleteParking = () => {
        history.push(`/account/${accountId}/parking`);
    };

    return (
        <>
            <ParkingAreaDeleteModal
                entity={{ id: entityId, name }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                deleteHandle={deleteParking}
            />
            <div className='card border'>
                <div className='card-body pb-3'>
                    <div className='row mb-3'>
                        <div className='col-md-6 align-self-center'>
                            <h3>Парковка</h3>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='name'
                            label='Название'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='form-group form-check mb-4'>
                        <input
                            type='checkbox'
                            className='form-check-input'
                            id='isForGuest'
                            checked={isForGuest}
                            onChange={(e) => setIsForGuest(e.target.checked)}
                        />
                        <label className='form-check-label' htmlFor='isForGuest'>
                            Разрешена для посетителей
                        </label>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='capacity'
                            label='Вместимость'
                            value={capacity}
                            onChange={(e) => setCapacity(e.target.value)}
                        />
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <button className='btn av-btn-danger fw-boldest' onClick={() => setDeleteModalIsOpen(true)}>
                                <i className='far fa-trash-alt me-2' /> Удалить
                            </button>
                        </div>
                        <div className='col-md-6 text-end'>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ParkingEditPanel;
