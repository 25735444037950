import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useDeleteScheduleDetailMutation } from '../../../app/storeReducers/SchedulesAPI';
import { GetDayName } from '../../../helpers/DateHelpers';

const ScheduleDetailDeleteModal = (props) => {
    const { entity, isOpen, closeHandle } = props;

    const history = useHistory();

    const [deleteScheduleDetail, { r }] = useDeleteScheduleDetailMutation();

    const deleteEntity = async () => {
        await deleteScheduleDetail(entity);
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Удалить правило</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Вы действительно хотите удалить правило?
                            </p>
                            <p>{GetDayName(entity.dayOfWeek)} {entity.timeFrom}-{entity.timeTo}</p>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-danger'
                                onClick={deleteEntity}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default ScheduleDetailDeleteModal;
