import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
    selectPaginator,
} from '../../../app/storeReducers/AdminAccountsSlice';
import SkeletonTable from '../../../components/skeleton/SkeletonTable';
import FindComponent from '../../../components/FindComponent';
import { KTSVG } from '../../../_metronic/helpers';
import { Link } from 'react-router-dom';
import AccountCreateModal from './AccountCreateModal';
import AccountDeleteModal from './AccountDeleteModal';
import { DateToString, UtcToLocal } from '../../../helpers/DateHelpers';
import Paginator from '../../../components/Paginator';
import ThSortable from '../../../components/table/ThSortable';

const AccountsList = () => {
    const dispatch = useDispatch();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);
    const paginator = useSelector(selectPaginator);

    const [accountName, setAccountName] = useState('');
    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedEntity, setDeletedEntity] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [forcePage, setForcePage] = useState(0);

    const [sort, setSort] = useState('name');
    const [sortDest, setSortDest] = useState('asc');
    const [sortEntity, setSortEntity] = useState('name');

    useEffect(() => {
        setForcePage(0);
        dispatch(
            getListAsync([
                { key: 'sort', value: sortEntity },
                { key: 'name', value: accountName },
                { key: 'onlyRoot', value: true },
                { key: 'pageIndex', value: 1 },
                { key: 'pageSize', value: pageSize },
            ])
        );
    }, [accountName, dispatch, sortEntity]);

    const openCreateModal = (entityId) => {
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const selectDeleteEntity = (entityId) => {
        setDeletedEntity(entityId);
        setDeleteModalIsOpen(true);
    };

    const columnFilter = (colName) => {
        const dest = colName === sort ? (sortDest === 'asc' ? 'desc' : 'asc') : 'asc';
        setSort(colName);
        setSortDest(dest);
        setSortEntity(colName + (dest === 'desc' ? '_desc' : ''));
    };
    const changePage = async (pageIndex) => {
        setForcePage(pageIndex - 1);
        dispatch(
            getListAsync([
                { key: 'sort', value: sortEntity },
                { key: 'name', value: accountName },
                { key: 'onlyRoot', value: true },
                { key: 'pageIndex', value: pageIndex },
                { key: 'pageSize', value: pageSize },
            ])
        );
    };

    const changePageSize = async (size) => {
        setPageSize(size);
        setForcePage(0);
        dispatch(
            getListAsync([
                { key: 'sort', value: sortEntity },
                { key: 'name', value: accountName },
                { key: 'onlyRoot', value: true },
                { key: 'pageIndex', value: 1 },
                { key: 'pageSize', value: size },
            ])
        );
    };

    function GetTypeName(props) {
        let t = props.value;
        if (t === 'basic') {
            return 'Простой';
        } else if (t === 'business') {
            return 'Расширенный';
        } else if (t === 'subaccount') {
            return 'Субаккаунт';
        } else {
            return '?';
        }
    }

    return (
        <>
            <AccountCreateModal isOpen={createModalIsOpen} closeHandle={closeCreateModal} />
            <AccountDeleteModal
                entity={deletedEntity}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    <div className='card-toolbar'>
                        <FindComponent callback={(name) => setAccountName(name)} />
                        <button className='btn btn-sm av-btn-primary ms-2' onClick={() => openCreateModal(0)}>
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                            Новый аккаунт
                        </button>
                    </div>
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <SkeletonTable
                            cols={['Название', 'Хост', '']}
                            rowsCount={5}
                            isLoading={isLoading}
                            classTable='table-striped gy-7 gs-7'
                        >
                            <table className='table table-striped gy-7 gs-7'>
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <ThSortable
                                            className='border-bottom ps-6'
                                            colName='id'
                                            colTitle='ID'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <th className='border-bottom'>Дата создания</th>
                                        <ThSortable
                                            className='border-bottom'
                                            colName='name'
                                            colTitle='Название'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <ThSortable
                                            className='border-bottom'
                                            colName='host'
                                            colTitle='Хост'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <th className='border-bottom'>Тип</th>
                                        <ThSortable
                                            className='border-bottom'
                                            colName='status'
                                            colTitle='Статус'
                                            colChanged={(name) => columnFilter(name)}
                                            sortCol={sort}
                                            sortDestinaton={sortDest}
                                        />
                                        <th className='border-bottom'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {entityList.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.id}</td>
                                            <td>
                                                {item.createdAt ? DateToString(UtcToLocal(item.createdAt), true) : ''}
                                            </td>
                                            <td>
                                                <Link to={`/account/${item.id}/dashboard`} className='btn btn-link'>
                                                    {item.name}
                                                </Link>
                                            </td>
                                            <td>{item.host?.title}</td>
                                            <td>
                                                <GetTypeName value={item.type} />
                                            </td>
                                            <td>
                                                {item.isBlocked === true ? (
                                                    <span class='badge bg-danger'>Блокировка</span>
                                                ) : (
                                                    <span class='badge bg-success'>OK</span>
                                                )}
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/admin/accounts/${item.id}`}
                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/art/art005.svg'
                                                        className='svg-icon-3'
                                                    />
                                                </Link>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                    onClick={() => selectDeleteEntity(item)}
                                                >
                                                    <KTSVG
                                                        path='/media/icons/duotune/general/gen027.svg'
                                                        className='svg-icon-3'
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </SkeletonTable>
                    </div>
                </div>
            </div>
            <Paginator
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                pageCount={paginator.totalPages}
                pageSizeArray={[50, 100, 500]}
                pageSize={pageSize}
                countCurrent={entityList.length}
                countTotal={paginator.totalCount}
                forcePage={forcePage}
            />
        </>
    );
};

export default AccountsList;
