const SkeletonSelect = (props) => {
    const {
        isLoading,
        label,
        inputId = 'formSelect',
        value,
        onChange,
        itemsArray,
        disabled,
    } = props;
    return (
        <div className='form-group'>
            <label htmlFor={inputId}>{label}</label>
            <select
                className={'form-select' + (isLoading ? ' skeleton' : '')}
                value={value}
                disabled={isLoading || disabled}
                onChange={(e) => onChange(e)}
            >
                {isLoading
                    ? null
                    : itemsArray?.map((item) => (
                          <option key={item.id} value={item.name}>
                              {item.description}
                          </option>
                      ))}
            </select>
        </div>
    );
};

export default SkeletonSelect;
