const EmergencyInputArray = [
    {
        id: 0,
        description: 'Не используется',
        name: '',
    },
    {
        id: 1,
        description: 'Нормально-открытый',
        name: 'no',
    },
    {
        id: 2,
        description: 'Нормально-закрытый',
        name: 'nc',
    },
    {
        id: 3,
        description: 'Нормально-открытый (soft)',
        name: 'no-soft',
    },
    {
        id: 4,
        description: 'Нормально-закрытый (soft)',
        name: 'nc-soft',
    },
    {
        id: 5,
        description: 'Программная разблокировка',
        name: 'slave-soft',
    },
];

export default EmergencyInputArray;
