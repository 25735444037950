import { useEffect, useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';

const FindComponent = (props) => {
    const { callback, placeholder, componentStyle, clear=0 } = props;

    const [findText, setFindText] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            callback(findText);
        }
    };

    useEffect(() => {
        clearText();
    }, [clear])

    const clearText = () => {
        setFindText('');
        callback('');
    };

    return (
        <div className='find-component' style={componentStyle}>
            <button
                className='btn btn-link'
                style={styles.findButton}
                onClick={() => callback(findText)}
            >
                <FaSearch />
            </button>
            <input
                type='text'
                value={findText}
                onChange={(e) => setFindText(e.target.value)}
                onKeyDown={handleKeyDown}
                className='form-control'
                placeholder={placeholder}
            />
            {findText.length > 0 ? (
                <button
                    className='btn btn-link text-muted'
                    style={styles.cancelButton}
                    onClick={clearText}
                >
                    <FaTimes />
                </button>
            ) : null}
        </div>
    );
};

const styles = {
    findButton: {
        position: 'absolute',
        top: '-2px',
        right: '5px',
        padding: '12px 5px',
    },
    cancelButton: {
        position: 'absolute',
        top: '-3px',
        right: '30px',
        padding: '12px 5px',
    },
};

export default FindComponent;
