import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CreateModal from "./CreateModal";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { useHistory } from "react-router-dom";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetParkingTariffsQuery } from "../../app/storeReducers/parkingTariffsApiSlice";

const TariffsList = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const { data: entityList, isLoading } = useGetParkingTariffsQuery({
        accountId,
    });

    const context = useSelector(selectContext);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("employees_edit"));
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(
                `/account/${accountId}/parkingTariffs/${entityId}`,
                "_blank"
            );
        else history.push(`/account/${accountId}/parkingTariffs/${entityId}`);
    };

    const typeName = (tariff) => {
        if (tariff.forGuest) {
            if (tariff.priceType === "hour") {
                return "Для посетителей (почасовая)";
            } else if (tariff.priceType === "entrance") {
                return "Для посетителей (за въезд)";
            } else {
                return "Для посетителей";
            }
        } else if (tariff.forQuota) {
            if (tariff.priceType === "hour") {
                return "Для транспорта сверх квоты (почасовая)";
            } else if (tariff.priceType === "entrance") {
                return "Для транспорта сверх квоты (за въезд)";
            } else {
                return "Для транспорта сверх квоты";
            }
        } else {
            return "Для транспорта";
        }
    };

    return (
        <>
            <CreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            {canEdit ? (
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <button
                            className="btn av-btn-primary"
                            onClick={() => openCreateModal(0)}
                            disabled={!canEdit}
                        >
                            <i className="fas fa-plus me-3" />
                            Новый тариф
                        </button>
                    </div>
                </div>
            ) : null}
            <div className="card">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={[
                            "Название",
                            "Стоимость, руб.",
                            "Для аккаунта",
                            "",
                        ]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <th className="border-bottom ps-6">
                                        Название
                                    </th>
                                    <th className="border-bottom ps-6">
                                        Стоимость, руб.
                                    </th>
                                    <th className="border-bottom ps-6">
                                        Для аккаунта
                                    </th>
                                    <th className="border-bottom ps-6">Тип</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className="cursor-pointer"
                                    >
                                        <td className="ps-6 aling-middle">
                                            {item.name}
                                        </td>
                                        <td className="ps-6 aling-middle">
                                            {item.price}
                                        </td>
                                        <td className="ps-6 aling-middle">
                                            {item.forAccount?.title}
                                        </td>
                                        <td className="ps-6 aling-middle">
                                            {typeName(item)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default TariffsList;
