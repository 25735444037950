import { useEffect, useState } from 'react';
import channelTypes from './channelTypes';

const EditChannelModal = (props) => {
    const { isOpen, closeHandle, okHandle, titleType, channelsChanged } = props;

    const [channels, setChannels] = useState([]);

    useEffect(() => {
        function loadResourcesAndDataAsync() {
            setChannels(channelsChanged || []);
        }

        loadResourcesAndDataAsync();
    }, [channelsChanged]);

    const changeAction = (e, channelType) => {
        if (e.target.checked) {
            setChannels([...channels, channelType]);
        } else {
            setChannels(channels.filter((x) => x !== channelType));
        }
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Выбрать каналы для уведомления
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <h3 className='mb-4'>{titleType}</h3>
                            {channelTypes.map((item) => (
                                <div
                                    className='form-group form-check mb-3'
                                    key={item.id}
                                >
                                    <input
                                        type='checkbox'
                                        className='form-check-input'
                                        id={item.id}
                                        onChange={(e) =>
                                            changeAction(e, item.id)
                                        }
                                        checked={channels?.includes(item.id)}
                                        value={item.id}
                                    />
                                    <label
                                        className='form-check-label'
                                        htmlFor={item.id}
                                    >
                                        {item.title}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={() => okHandle(channels)}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default EditChannelModal;
