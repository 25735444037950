import { useEffect, useState } from "react";
import ServiceCreateModal from "./ServiceCreateModal";
import ServicesList from "./ServicesList";
import { useSelector } from "react-redux";
import { selectContext } from "../../../../../../app/storeReducers/AuthSlice";

const ServicesPanel = (props) => {
    const { accountId, placeId } = props;

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("services_edit"));
        setCanList(context.isRoot || actions?.includes("services_list"));
    }, [context, accountId]);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    return (
        <>
            <ServiceCreateModal
                accountId={accountId}
                entityId={editedEntity}
                placeId={placeId}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className="card border mb-3">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col-md-6 align-self-center">
                            <h3>Услуги</h3>
                        </div>
                        <div className="col-md-6 text-end">
                            {canEdit ? (
                                <button
                                    className="btn av-btn-primary"
                                    onClick={() => openCreateModal(0)}
                                >
                                    <i className="fas fa-plus me-2" />
                                    Новая услуга
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {canList ? (
                    <ServicesList
                        accountId={accountId}
                        placeId={placeId}
                        selectEditEntity={openCreateModal}
                    />
                ) : (
                    <div className="card-body">
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default ServicesPanel;
