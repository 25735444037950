import React from 'react';

const BtnStatused = (props) => {
    const {
        label,
        handleClick,
        btnColor,
        isLoading,
        isFailed,
        loadingLabel = 'Загрузка...',
    } = props;

    let labelBtn = label;

    if (isLoading) {
        labelBtn = loadingLabel;
    }

    if (isFailed) {
        labelBtn = 'Ошибка!';
    }

    return (
        <button
            type='button'
            className={`btn btn-${isFailed ? 'danger' : btnColor}`}
            onClick={handleClick}
            disabled={isLoading}
        >
            {labelBtn}
        </button>
    );
};

export default BtnStatused;
