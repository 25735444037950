import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getByIdAsync,
    selectIsLoading,
    selectObject,
    updateAsync,
    getListActionsAsync,
    selectActions,
} from '../../../app/storeReducers/AccountRolesSlice';
import RoleDeleteModal from '../RoleDeleteModal';
import SecutiryContext from '../../../features/securityContext';

const RoleEditPanel = (props) => {
    const { accountId, roleId } = props;

    const dispatch = useDispatch();

    const isLoading = useSelector(selectIsLoading);
    const role = useSelector(selectObject);
    const roleActions = useSelector(selectActions);
    const [name, setName] = useState('');
    const [actions, setActions] = useState([]);

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(getListActionsAsync(roleId));
            dispatch(getByIdAsync(roleId));
        }

        loadResourcesAndDataAsync();
    }, [roleId]);

    useEffect(() => {
        async function loadResourcesAndData() {
            setName(role.name !== undefined ? role?.name : '');
            setActions(role.actions !== undefined ? role?.actions : []);
        }

        loadResourcesAndData();
    }, [role]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(
            (context.isRoot || actions?.includes('roles_edit')) &&
                !role.readonly
        );
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            accountId,
            id: roleId,
            actions,
        };
        dispatch(updateAsync(entity));
    };

    const changeAction = (e, actionName) => {
        if (e.target.checked) {
            setActions([...actions, actionName]);
        } else {
            setActions(actions.filter((x) => x !== actionName));
        }
    };
    function GetStatus(props) {
        const isReadonly = props.readonly;
        const isLinked = props.linked;
        if (isReadonly) {
            return (
                <div className='mb-3'>
                    <i className='fas fa-lock'></i> - у вас не хватает прав,
                    чтобы редактировать эту роль
                </div>
            );
        } else if (isLinked) {
            return (
                <div className='mb-3'>
                    <i className='fas fa-link'></i> - эта роль связана с
                    аккаунтом администратора.
                </div>
            );
        }
        return '';
    }

    return (
        <>
            <RoleDeleteModal
                entity={role}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row'>
                        <div className='col mb-2'>
                            <h3>Роль</h3>
                        </div>
                    </div>
                    <div className='mb-3 form-group'>
                        <label htmlFor='name'>Название</label>
                        <input
                            type='text'
                            className='form-control'
                            id='name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <GetStatus readonly={role.readonly} linked={role.linked} />
                    <div className='row mb-4'>
                        {roleActions.map((item) => (
                            <div className='col-12 mb-2' key={item.name}>
                                <div className='action-title mb-2'>
                                    <b>{item.name}</b>
                                </div>
                                {item.actions.map((actionItem, index) => (
                                    <>
                                        {parseInt(accountId) !== 1 &&
                                        actionItem.key === 'root' ? null : (
                                            <div
                                                className='action-item ps-10'
                                                key={
                                                    item.name +
                                                    '_' +
                                                    actionItem.key +
                                                    '_' +
                                                    index
                                                }
                                            >
                                                <div className='form-group form-check mb-3'>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input'
                                                        id={
                                                            'role_' +
                                                            actionItem.key
                                                        }
                                                        name='roleActions'
                                                        onChange={(e) =>
                                                            changeAction(
                                                                e,
                                                                actionItem.key
                                                            )
                                                        }
                                                        checked={actions.includes(
                                                            actionItem.key
                                                        )}
                                                        disabled={
                                                            actionItem.disabled
                                                        }
                                                    />
                                                    <label
                                                        className='form-check-label'
                                                        htmlFor={
                                                            'role_' +
                                                            actionItem.key
                                                        }
                                                    >
                                                        {actionItem.value}
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            {canEdit ? (
                                <button
                                    className='btn av-btn-danger fw-boldest'
                                    onClick={() => setDeleteModalIsOpen(true)}
                                >
                                    <i className='far fa-trash-alt me-2' />{' '}
                                    Удалить
                                </button>
                            ) : null}
                        </div>
                        <div className='col text-end'>
                            <button
                                type='button'
                                className='btn av-btn-simple fw-boldest'
                                onClick={saveEntity}
                                disabled={isLoading || !canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoleEditPanel;
