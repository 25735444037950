import { useHistory, useParams } from 'react-router';

const ParkingWidget = (props) => {
    const { total, employees, guests, quotas, currentQuotas, capacity } = props;

    const history = useHistory();

    let params = useParams();
    const { accountId } = params;

    return (
        <div className='card border widget-card mb-6'>
            <div className='card-body p-6'>
                <div className='widget-title mb-3'>
                    <div className='d-inline-block me-4 bg-info rounded-circle' style={{ padding: '5px 8px 8px 8px' }}>
                        <img
                            className='img-fluid'
                            src='/media/icons/avanguard/Transport.svg'
                            style={{
                                width: 25,
                                filter: 'invert(100%) sepia(0%) saturate(17%) hue-rotate(268deg) brightness(102%) contrast(106%)',
                            }}
                        />
                    </div>
                    Парковка транспорта
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>
                        {capacity > 0 ? (
                            <>
                                {total} / {capacity}
                            </>
                        ) : (
                            <>{total}</>
                        )}
                    </div>
                    <div className='widget-count-title'>Всего</div>
                </div>
                <div
                    className='widget-count-block d-inline-block me-5 cursor-pointer'
                    onClick={() => history.push('/account/' + accountId + '/vehicles')}
                >
                    <div className='widget-count-number'>{employees}</div>
                    <div className='widget-count-title'>Сотрудники</div>
                </div>
                <div
                    className='widget-count-block d-inline-block me-5 cursor-pointer'
                    onClick={() => history.push('/account/' + accountId + '/guests')}
                >
                    <div className='widget-count-number'>{guests}</div>
                    <div className='widget-count-title'>Посетители</div>
                </div>
                {quotas > 0 && (
                    <div
                        className={
                            'widget-count-block d-inline-block me-5' + (quotas === currentQuotas && ' text-danger')
                        }
                    >
                        <div className='widget-count-number'>
                            {currentQuotas} из {quotas}
                        </div>
                        <div className='widget-count-title'>Квота</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ParkingWidget;
