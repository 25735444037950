import UserInfo from './UserInfo';

const ProfilePage = () => {
    return (
        <div className='row mt-5'>
            <div className='offset-md-4 col-md-4'>
                <div className='card position-relative'>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col'>
                                <img
                                    alt='user avatar'
                                    height='150'
                                    src='/images/no-user.jpg'
                                    className='rounded-circle border border-2 border-primary d-block mx-auto shadow-neo'
                                />
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col'>
                                <UserInfo />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
