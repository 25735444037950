import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Http } from '../../http';
import { addNotification } from './NotifySlice';

const nameSlice = 'accountParkingAreas';
const urlSlice = 'parking/areas';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    objectsArray: [],
};

export const getListAsync = createAsyncThunk(`${nameSlice}/getList`, async (accountId) => {
    const getResult = await Http.get(`api/${urlSlice}?accountId=${accountId}`);

    return getResult.result;
});

export const createAsync = createAsyncThunk(`${nameSlice}/create`, async (entity) => {
    const createResult = await Http.post(`api/${urlSlice}`, true, entity);
    return createResult.result;
});

export const updateAsync = createAsyncThunk(`${nameSlice}/update`, async (entity, thunkAPI) => {
    console.log('updateEntity', entity);
    const updateeResult = await Http.put(`api/${urlSlice}/${entity.id}`, true, entity);
    if (updateeResult !== undefined && updateeResult != null) {
        thunkAPI.dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: 'Парковка сохранёна!',
                description: 'Данные парковки успешно сохранены!',
                type: 'success',
            })
        );
    } else {
        thunkAPI.dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: 'Ошибка сохранения!',
                description: 'Не удалось сохранить данные парковки! Перезагрузите страницу и попробуйте снова!',
                type: 'danger',
            })
        );
    }

    return updateeResult.result;
});

export const deleteAsync = createAsyncThunk(`${nameSlice}/delete`, async (id) => {
    await Http.delete(`api/${urlSlice}/${id}`, true);
    return id;
});

export const accountParkingAreasSlice = createSlice({
    name: nameSlice,
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    extraReducers: {
        [getListAsync.pending]: (state) => {
            state.isLoading = true;
        },
        [getListAsync.fulfilled]: (state, action) => {
            if (action.payload.error !== undefined && action.payload.error != null) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.objectsArray = action.payload;
            }
            state.isLoading = false;
        },
        [getListAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/getListError', action);
            state.isLoading = false;
            state.isErrors = true;
            state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [createAsync.fulfilled]: (state, action) => {
            if (action.payload.error !== undefined && action.payload.error != null) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                state.objectsArray.push(action.payload);
            }
        },
        [createAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/createError', action);
            state.isErrors = true;
            state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [updateAsync.fulfilled]: (state, action) => {
            if (action.payload.error !== undefined && action.payload.error != null) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                console.log('updateEmp', action.payload);
                const updateObj = state.objectsArray.map((item) => {
                    if (item.id === action.payload.id) {
                        item = action.payload;
                    }
                    return item;
                });
                state.objectsArray = updateObj;
            }
        },
        [updateAsync.rejected]: (state, action) => {
            console.error(nameSlice + '/updateError', action);
            state.isErrors = true;
            state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
        [deleteAsync.fulfilled]: (state, action) => {
            if (action.payload.error !== undefined && action.payload.error != null) {
                state.isErrors = true;
                state.errorDescription = action.payload.errorDescription;
            } else {
                const updateObj = state.objectsArray.filter((x) => x.id !== action.payload);
                state.objectsArray = updateObj;
            }
        },
        [deleteAsync.rejected]: (state, action) => {
            console.log(nameSlice + '/deleteError', action);
            state.isErrors = true;
            state.errorDescription = 'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
        },
    },
});

export const selectList = (state) => state.accountParkingAreas.objectsArray;
export const selectIsLoading = (state) => state.accountParkingAreas.isLoading;

export default accountParkingAreasSlice.reducer;
