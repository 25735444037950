import PermissionPage from '../../../components/PermissionPage';
import MonitorDevicesPanel from './device/MonitorDevicesPanel';
import MonitorEditPanel from './MonitorEditPanel';

const MonitorCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const entityId = props.match.params.monitorId;

    return (
        <PermissionPage accountId={accountId} roles={['monitor_list']}>
            <h1 className='mb-5'>Карточка монитора доступа</h1>
            <div className='row'>
                <div className='col-md-6'>
                    <MonitorEditPanel
                        accountId={accountId}
                        entityId={entityId}
                    />
                </div>
                <div className='col-md-6'>
                    <MonitorDevicesPanel
                        accountId={accountId}
                        entityId={entityId}
                    />
                </div>
            </div>
        </PermissionPage>
    );
};

export default MonitorCardPage;
