import { baseApi } from "../baseApi";

export const servicesApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getServices: builder.query({
            query: (data) =>
                `services?accountId=${data.accountId ?? ""}&vehicleId=${
                    data.vehicleId ?? ""
                }` +
                `&placeId=${data.placeId ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}&search=${data.search ?? ""}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result?.result?.map(({ id }) => ({
                              type: "Services",
                              id,
                          })),
                          { type: "Services", id: "List" },
                      ]
                    : [{ type: "Services", id: "List" }],
        }),
        getServiceById: builder.query({
            query: (id) => `services/${id}`,
            providesTags: (result) => [
                { type: "Service", id: result.result.id },
            ],
        }),
        createService: builder.mutation({
            query: (body) => ({
                url: "services",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Services", id: "List" }],
        }),
        updateService: builder.mutation({
            query: (body) => ({
                url: "services/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Service", id: result?.result?.id },
                { type: "Services", id: "List" },
            ],
        }),
        deleteService: builder.mutation({
            query: (id) => ({
                url: "services/" + id,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Services", id: "List" }],
        }),
    }),
});

export const {
    useGetServicesQuery,
    useGetServiceByIdQuery,
    useLazyGetServiceByIdQuery,
    useCreateServiceMutation,
    useUpdateServiceMutation,
    useDeleteServiceMutation,
} = servicesApiSlice;
