import { useDispatch } from 'react-redux';
import { addNotification } from '../../../app/storeReducers/NotifySlice';
import { useReturnDeviceMutation } from '../../../app/storeReducers/devicesApiSlice';

const DeviceReturnModal = (props) => {
    const { deviceName, deviceId, isOpen, okHandle, closeHandle } = props;

    const dispatch = useDispatch();

    const [returnDeviceAsync] = useReturnDeviceMutation();

    const returnDevice = async () => {
        const object = { list: [deviceId] };
        const returnRequest = await returnDeviceAsync(object).unwrap();

        if (returnRequest.result) {
            okHandle();
        } else {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка возврата устройства!',
                    description: returnRequest.errorDescription,
                    type: 'danger',
                })
            );
        }
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Вернуть устройство</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Вы действительно хотите вернуть устройство{' '}
                                <strong>{deviceName}</strong>
                            </p>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={returnDevice}
                            >
                                Вернуть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeviceReturnModal;
