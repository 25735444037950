import PermissionPage from '../../../components/PermissionPage';
import ScheduleDetailsPanel from './ScheduleDetailsPanel';
import SchedulePanel from './SchedulePanel';
import ScheduleDevicesPanel from './device/ScheduleDevicesPanel';

const ScheduleCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const scheduleId = props.match.params.scheduleId;

    return (
        <PermissionPage accountId={accountId} roles={['schemes_list']}>
            <h1 className='mb-5'>Карточка расписания</h1>
            <div className='row'>
                <div className='col-6'>
                    <SchedulePanel
                        accountId={accountId}
                        entityId={scheduleId}
                    />

                    <ScheduleDetailsPanel
                        accountId={accountId}
                        entityId={scheduleId}
                    />
                </div>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col'>
                            <ScheduleDevicesPanel
                                accountId={accountId}
                                entityId={scheduleId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default ScheduleCardPage;
