import 'bootstrap/dist/js/bootstrap.min.js';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAccount } from '../../components/useAccount';

const EmployeesWarnings = (props) => {
    const account = useAccount();

    const [isWarningRead, setIsWarningRead] = useState(true);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (localStorage.getItem('employeesListWarning') === null) {
                setIsWarningRead(false);
            }
        }

        loadResourcesAndDataAsync();
    }, [account]);

    const readWarning = () => {
        localStorage.setItem('employeesListWarning', true);
        setIsWarningRead(true);
    };

    return (
        <>
            {account?.type === 'business' && !isWarningRead ? (
                <div className='card info-card mb-4 av-bg-warning av-border-warning border'>
                    <div className='card-body py-3 px-4'>
                        <div className='d-flex flex-row'>
                            <div className='info-card-icon info-card-icon-warning me-4 align-self-center'>
                                <img src='/media/icons/base/info.svg' />
                            </div>
                            <div className='align-self-center'>
                                В расширенном аккаунте добавляются только
                                сотрудники-администраторы. Чтобы добавить
                                сотрудников компании или обслуживающей
                                организации зайдите в соответствующий{' '}
                                <Link
                                    className='av-text-primary'
                                    to={`subaccounts`}
                                >
                                    субаккаунт
                                </Link>
                                .
                            </div>
                            <button
                                className='btn btn-sm av-btn-warning d-flex flex-row align-items-center fw-boldest'
                                onClick={readWarning}
                            >
                                Понятно <span className='fas fa-times ms-1' />
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export default EmployeesWarnings;
