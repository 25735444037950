import { useEffect, useState } from 'react';

const emergencyEnum = [
    { id: 1, name: 'common', title: 'Гражданское ТС' },
    { id: 2, name: 'medical', title: 'Скорая' },
    { id: 3, name: 'police', title: 'Полиция и ДПС' },
    { id: 4, name: 'fire', title: 'Пожарная служба' },
    { id: 5, name: 'rescuers', title: 'МЧС' },
    { id: 6, name: 'guards', title: 'Росгвардия' },
    { id: 7, name: 'posts', title: 'Почта России' },
    { id: 8, name: 'courier', title: 'Спецсвязь' },
    { id: 9, name: 'investigators', title: 'Следственный комитет' },
    { id: 10, name: 'taxi', title: 'Такси' },
];

const TransportEmergencySelect = (props) => {
    const { accountEmergences, setAccountEmergences } = props;
    const [showButtons, setShowButtons] = useState(false);

    const [selectedTransport, setSelectedTransport] = useState([]);

    useEffect(() => {
        setSelectedTransport(accountEmergences);
    }, [accountEmergences]);

    useEffect(() => {
        setAccountEmergences(selectedTransport);
    }, [selectedTransport]);

    const changeTransport = (transportId) => {
        if (selectedTransport.some((x) => x === transportId)) {
            setSelectedTransport(
                selectedTransport.filter((x) => x !== transportId)
            );
        } else {
            setSelectedTransport([...selectedTransport, transportId]);
        }
    };

    const selectedName = () => {
        let name = <i>Не установлено</i>;
        if (selectedTransport.length > 0) {
            if (selectedTransport.length > 2) {
                name = (
                    <>
                        {emergencyEnum
                            .filter((x) =>
                                selectedTransport.some((s) => s === x.name)
                            )
                            .slice(0, 2)
                            .map((x) => x.title)
                            .join(', ')}
                        <i> ... ещё {selectedTransport.length - 2}</i>
                    </>
                );
            } else {
                name = emergencyEnum
                    .filter((x) => selectedTransport.some((s) => s === x.name))
                    .map((x) => x.title)
                    .join(', ');
            }
        }
        return name;
    };

    return (
        <div className='mb-3'>
            <label className='form-label'>
                Автоматически пропускать спец транспорт
            </label>
            <div className='btn-group' role='group' style={{ width: '100%' }}>
                <button
                    id='btnGroupDrop1'
                    type='button'
                    className='form-select text-start'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                    onClick={() => {
                        setShowButtons(!showButtons);
                    }}
                >
                    {selectedName()}
                </button>
                <ul
                    className={'dropdown-menu' + (showButtons ? ' show' : '')}
                    aria-labelledby='btnGroupDrop1'
                >
                    {emergencyEnum.map((item) => (
                        <li key={item.id}>
                            <div className='form-group form-check mb-3 me-3'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id={item.name}
                                    checked={selectedTransport.some(
                                        (x) => x === item.name
                                    )}
                                    onChange={(e) => changeTransport(item.name)}
                                />
                                <label
                                    className='form-check-label'
                                    htmlFor={item.name}
                                >
                                    {item.title}
                                </label>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default TransportEmergencySelect;
