import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonInput from "../../../components/skeleton/SkeletonInput";
import DeleteModal from "../DeleteModal";
import { useHistory } from "react-router";
import { selectContext } from "../../../app/storeReducers/AuthSlice";
import {
    useLazyGetAccountByIdQuery,
    useLazyGetSubaccountsQuery,
} from "../../../app/storeReducers/acountsApiSlice";
import {
    useLazyGetParkingTariffByIdQuery,
    useUpdateParkingTariffMutation,
} from "../../../app/storeReducers/parkingTariffsApiSlice";

const TariffEditPanel = (props) => {
    const { accountId, entityId } = props;

    const history = useHistory();

    const context = useSelector(selectContext);
    const [canEdit, setCanEdit] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState("");
    const [freeMinutes, setFreeMinutes] = useState(0);
    const [price, setPrice] = useState(0);
    const [priceType, setPriceType] = useState("hour");
    const [acccounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(0);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [tariffType, setTariffType] = useState("0");
    const [paidFreeTime, setPaidFreeTime] = useState(false);

    const [getAccountById] = useLazyGetAccountByIdQuery();
    const [getSubAccounts] = useLazyGetSubaccountsQuery();
    const [getTariffById] = useLazyGetParkingTariffByIdQuery();
    const [updateAsync] = useUpdateParkingTariffMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            let accounts = [];
            const getCurrentAccouunt = await getAccountById(accountId).unwrap();
            accounts.push(getCurrentAccouunt.result);
            const requestSubaccount = await getSubAccounts({
                accountId,
                sort: "name",
                pageSize: 5000,
            }).unwrap();
            requestSubaccount.result?.map((item) => accounts.push(item));
            setAccounts(accounts);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getResult = await getTariffById(entityId).unwrap();
            setName(getResult.result.name);
            setFreeMinutes(getResult.result.freeMinutes);
            setPrice(getResult.result.price);
            setPriceType(getResult.result.priceType);
            if (getResult.result.forAccount !== null) {
                setSelectedAccount(getResult.result.forAccount.id);
            } else {
                setSelectedAccount(0);
            }
            let tt = "0";
            if (getResult.result.forGuest === true) {
                tt = "1";
            }
            if (getResult.result.forQuota === true) {
                tt = "2";
            }
            setTariffType(tt);
            setIsLoading(false);
            setPaidFreeTime(getResult.result.paidFreeTime);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("tariff_edit"));
    }, [context, accountId]);

    const saveEntity = async () => {
        let forAccount = null;
        if (selectedAccount != 0) {
            forAccount = { id: selectedAccount };
        }
        const entity = {
            name,
            account: { id: accountId },
            id: entityId,
            price,
            freeMinutes,
            forAccount,
            forGuest: tariffType === "1" ? true : false,
            forQuota: tariffType === "2" ? true : false,
            priceType,
            paidFreeTime,
        };
        updateAsync(entity);
    };

    const deleteEntity = () => {
        history.push(`/account/${accountId}/parkingTariffs`);
    };

    return (
        <>
            <DeleteModal
                entity={{ id: entityId, name }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                deleteHandle={deleteEntity}
            />
            <div className="card border">
                <div className="card-body py-3">
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="name"
                            label="Название"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Тип тарифа</label>
                        <select
                            className="form-select"
                            value={tariffType}
                            onChange={(e) => {
                                setTariffType(e.target.value);
                                if (
                                    e.target.value == "1" ||
                                    e.target.value == "2"
                                ) {
                                    setPriceType("hour");
                                } else {
                                    setPriceType("month");
                                }
                            }}
                            disabled
                        >
                            <option value="0">Для транспорта</option>
                            <option value="1">Для посетителей</option>
                            <option value="2">
                                Для транспорта сверх квоты
                            </option>
                        </select>
                    </div>
                    {tariffType === "1" || tariffType === "2" ? (
                        <div className="mb-3">
                            <label className="form-label">Тип оплаты</label>
                            <select
                                className="form-select"
                                value={priceType}
                                onChange={(e) => setPriceType(e.target.value)}
                            >
                                <option value="hour">Почасовая</option>
                                <option value="entrance">За въезд</option>
                            </select>
                        </div>
                    ) : (
                        <div className="mb-3">
                            <label className="form-label">Тип оплаты</label>
                            <select
                                className="form-select"
                                value={priceType}
                                onChange={(e) => setPriceType(e.target.value)}
                            >
                                <option value="month">Помесячная</option>
                                <option value="entrance">За въезд</option>
                            </select>
                        </div>
                    )}

                    <div className="mb-3">
                        <label className="form-label">Для аккаунта</label>
                        <select
                            className="form-select"
                            value={selectedAccount}
                            onChange={(e) => setSelectedAccount(e.target.value)}
                        >
                            <option value={0}>--Для всех аккаунтов--</option>
                            {acccounts.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="price"
                            label="Стоимость, руб."
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            type="number"
                        />
                    </div>
                    {(tariffType === "1" || tariffType === "2") &&
                    priceType.toString() === "hour" ? (
                        <div className="mb-3">
                            <SkeletonInput
                                isLoading={isLoading}
                                id="freeMinutes"
                                label="Кол-во бесплатных минут"
                                value={freeMinutes}
                                onChange={(e) => setFreeMinutes(e.target.value)}
                                type="number"
                            />
                        </div>
                    ) : null}
                    {tariffType === "1" && priceType.toString() === "hour" ? (
                        <div className="form-group form-check pull-left mb-5">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="paidFreeTime"
                                checked={paidFreeTime}
                                onChange={(e) =>
                                    setPaidFreeTime(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="paidFreeTime"
                            >
                                Тарифицировать бесплатное время при его
                                превышении
                            </label>
                        </div>
                    ) : null}
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <button
                                className="btn av-btn-danger fw-boldest"
                                onClick={() => setDeleteModalIsOpen(true)}
                            >
                                <i className="far fa-trash-alt me-2" /> Удалить
                            </button>
                        </div>
                        <div className="col-md-6 text-end">
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                                disabled={isLoading || !canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TariffEditPanel;
