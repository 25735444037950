import { baseApi } from "../baseApi";

export const sourceLogApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSourceLog: builder.query({
            query: (data) => ({
                url: `/source_log?deviceId=${data?.deviceId ?? ""}&start=${
                    data?.start ?? ""
                }&finish=${data?.finish ?? ""}&pageIndex=${
                    data?.pageIndex ?? 1
                }&pageSize=${data?.pageSize ?? 100}`,
                method: "GET",
            }),
        }),
    }),
});

export const { useGetSourceLogQuery, useLazyGetSourceLogQuery } =
    sourceLogApiSlice;
