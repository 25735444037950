import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: true,
    isErrors: false,
    errorDescription: [],
    breadcrumbs: { leftLinks: [], rightLinks: [] },
};

const breadcrumbsSlice = createSlice({
    name: 'breadcrumbs',
    initialState,
    reducers: {
        setBreadcrumbs: (state, action) => {
            state.breadcrumbs = action.payload;
        },
    },
});

export const { setBreadcrumbs } = breadcrumbsSlice.actions;

export const selectBreadcrumbs = (state) => state.breadcrumbs.breadcrumbs;
export const selectIsLoading = (state) => state.breadcrumbs.isLoading;

export default breadcrumbsSlice.reducer;
