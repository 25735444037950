import { useEffect, useState } from 'react';
import { Http } from '../../../http';
import SkeletonSelect from '../../../components/skeleton/SkeletonSelect';
import { addNotification } from '../../../app/storeReducers/NotifySlice';
import { useDispatch } from 'react-redux';

const DeviceProvideModal = (props) => {
    const { accountId, deviceId, isOpen, okHandle, closeHandle } = props;

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [accountSelected, setAccountsSelected] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            setAccountsSelected({});
            const accountsResult = await Http.get(
                `api/accounts/${accountId}/subaccounts?sort=name`
            );
            accountsResult?.result?.map((item) => {
                item.description = item.name;
                item.name = item.id;
            });
            setAccounts(accountsResult?.result);
            if (accountsResult?.result?.length > 0) {
                setAccountsSelected(accountsResult.result[0].id);
            }
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    const provideDevice = async () => {
        const object = { list: [deviceId] };
        const provide = await Http.put(
            `api/devices/provide?accountId=${accountSelected}`,
            true,
            object
        );
        if (provide.result) {
            const sharedAccount = accounts.find(
                (x) => x.name == accountSelected
            );
            okHandle(sharedAccount.description);
        } else {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: 'Ошибка передачи устройства!',
                    description: provide.errorDescription,
                    type: 'danger',
                })
            );
        }
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Передать устройство аккаунту
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-4'>
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label='Аккаунты'
                                    value={accountSelected}
                                    onChange={(e) =>
                                        setAccountsSelected(e.target.value)
                                    }
                                    itemsArray={accounts}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={provideDevice}
                            >
                                Передать
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeviceProvideModal;
