import { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import SchemeShareModal from "./SchemeShareModal";
import SchemeShareDeleteModal from "./SchemeShareDeleteModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import { useGetSchemesSharesQuery } from "../../../../app/storeReducers/schemesApiSlice";

const SchemeSharesPanel = (props) => {
    const { accountId, entityId, isSubaccount } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const { data: shares, refetch } = useGetSchemesSharesQuery(entityId);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("schemes_edit"));
        setCanList(context.isRoot || actions?.includes("schemes_list"));
    }, [context, accountId]);

    const okAddDevice = () => {
        refetch();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteShare = () => {
        refetch();
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <SchemeShareModal
                accountId={accountId}
                schemeId={entityId}
                shares={shares?.result ?? []}
                isSubaccount={isSubaccount}
                isOpen={addModalIsOpen}
                okHandle={okAddDevice}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <SchemeShareDeleteModal
                schemeId={entityId}
                share={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteShare}
            />
            <div className="card border">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <h3>
                                Предоставлен доступ{" "}
                                <span className="badge rounded-pill bg-light text-dark">
                                    {shares?.result.length}
                                </span>
                            </h3>
                        </div>
                        <div className="col-md-6 text-end">
                            {canEdit ? (
                                <button
                                    className="btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0"
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr075.svg"
                                        className="svg-icon-2 me-2"
                                    />
                                    Предоставить доступ
                                </button>
                            ) : null}
                        </div>
                    </div>
                    {canList ? (
                        shares?.result.map((item) => (
                            <div
                                className="border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2"
                                key={item.id}
                            >
                                {item.title}
                                {canEdit ? (
                                    <i
                                        className="ms-2 fas fa-times cursor-pointer"
                                        onClick={() => openDeleteModal(item)}
                                    ></i>
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                </div>
            </div>
        </>
    );
};

export default SchemeSharesPanel;
