import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GuestCreateModal from "./GuestCreateModal";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import FindComponent from "../../components/FindComponent";
import { ToUtcString } from "../../helpers/DateHelpers";
import { useHistory } from "react-router-dom";
import {
    DateToString,
    UtcToLocal,
    IsSameDate,
} from "../../helpers/DateHelpers";
import Paginator from "../../components/Paginator";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import {
    useGetGuestsQuery,
    useUpdateGuestMutation,
} from "../../app/storeReducers/guestsApiSlice";
import { setNotifications } from "../../app/storeReducers/HeaderNotificationsSlice";

const GuestsList = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const context = useSelector(selectContext);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [dateStart, setDateStart] = useState(new Date());
    const [dateFinish, setDateFinish] = useState(new Date());
    const [searchString, setSearchString] = useState("");
    const [canEdit, setCanEdit] = useState(false);
    const [onlyMy, setOnlyMy] = useState(false);
    const [showArchived, setShowArchived] = useState(false);
    const [canViewAll, setCanViewAll] = useState(false);

    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);

    const [forcePage, setForcePage] = useState(0);

    const {
        data: entityList,
        isLoading,
        error,
    } = useGetGuestsQuery({
        accountId,
        dateStart: getQueryDate(dateStart) + "T00:00:00",
        dateFinish: getQueryDate(dateFinish) + "T23:59:59",
        search: searchString,
        mine: onlyMy,
        showArchived,
        pageIndex,
        pageSize,
    });

    const [updateAsync] = useUpdateGuestMutation();

    useEffect(() => {
        setForcePage(pageIndex - 1);
    }, [
        accountId,
        dateStart,
        dateFinish,
        searchString,
        onlyMy,
        showArchived,
        pageSize,
        pageIndex,
        forcePage,
        dispatch,
    ]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("guest_edit"));
        setCanViewAll(actions?.includes("guest_list_all"));
    }, [context, accountId]);

    useEffect(() => {
        if (error) {
            dispatch(
                setNotifications({
                    dateNotify: Date.now(),
                    title: "Ошибка!",
                    description: error?.data?.message,
                    type: "danger",
                })
            );
        }
    }, [error]);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(`/account/${accountId}/guests/${entityId}`, "_blank");
        else history.push(`/account/${accountId}/guests/${entityId}`);
    };

    function GetState(props) {
        const state = props.state;
        const isArchived = props.isArchived;
        if (isArchived) {
            return (
                <span data-toggle="tooltip" data-placement="top" title="Архив">
                    <span className="badge bg-dark">архив</span>
                </span>
            );
        }
        if (state === "in") {
            let statusTag = (
                <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title="На месте"
                >
                    <span className="badge bg-success">на месте</span>
                </span>
            );
            if (props.needExitConfirmation) {
                statusTag = (
                    <button
                        className="btn av-btn-outline-success av-btn-sm"
                        onClick={(e) => acceptExit(e, props.id)}
                    >
                        Разрешить выезд
                    </button>
                );
            }
            return statusTag;
        } else if (state === "out") {
            return (
                <span data-toggle="tooltip" data-placement="top" title="Ушел">
                    <span className="badge bg-light text-dark">ушел</span>
                </span>
            );
        } else {
            return (
                <span data-toggle="tooltip" data-placement="top" title="Новый">
                    <span className="badge bg-light text-dark">новый</span>
                </span>
            );
        }
    }

    function GetPeriod(props) {
        const start = props.start;
        const finish = props.finish;
        if (start && finish) {
            if (IsSameDate(UtcToLocal(start), UtcToLocal(finish))) {
                return DateToString(UtcToLocal(start));
            } else {
                return (
                    DateToString(UtcToLocal(start)) +
                    " - " +
                    DateToString(UtcToLocal(finish))
                );
            }
        } else {
            return "---";
        }
    }

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(1);
        setPageSize(size);
    };

    const createModalOkHandle = () => {
        setCreateModalIsOpen(false);
    };

    const acceptExit = (e, guestId) => {
        e.stopPropagation();
        const guestDateFinish = new Date();
        let entity = { ...entityList?.result.find((x) => x.id === guestId) };
        entity.needExitConfirmation = false;
        entity.dateFinish = new Date(
            guestDateFinish.getFullYear(),
            guestDateFinish.getMonth(),
            guestDateFinish.getDate(),
            23,
            59,
            59
        );
        updateAsync(entity);
    };

    function getQueryDate(date) {
        return (
            date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate()
        );
    }

    return (
        <>
            <GuestCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
                okHandle={createModalOkHandle}
            />
            <div className="row">
                <div className="col-md-2 mb-3">
                    <button
                        className="btn av-btn-primary"
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className="fas fa-plus me-3" />
                        Новый посетитель
                    </button>
                </div>
                <div className="col-md-10 mb-3 d-flex justify-content-end">
                    {canViewAll ? (
                        <div className="form-group form-check me-4 align-self-center">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="onlyMy"
                                checked={onlyMy}
                                onChange={(e) => setOnlyMy(e.target.checked)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="onlyMy"
                            >
                                Только мои
                            </label>
                        </div>
                    ) : null}
                    <div className="form-group form-check me-4 align-self-center">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="showArchived"
                            checked={showArchived}
                            onChange={(e) => setShowArchived(e.target.checked)}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="showArchived"
                        >
                            Показать архивные
                        </label>
                    </div>
                    <div
                        className="input-group me-2"
                        style={{ width: "400px" }}
                    >
                        <span className="input-group-text">С</span>
                        <input
                            type="date"
                            className="form-control"
                            value={ToUtcString(dateStart).split("T")[0]}
                            onChange={(e) => {
                                setDateStart(new Date(e.target.value));
                                if (new Date(e.target.value) > dateFinish) {
                                    setDateFinish(new Date(e.target.value));
                                }
                            }}
                        />
                        <span className="input-group-text">ПО</span>
                        <input
                            type="date"
                            className="form-control"
                            value={ToUtcString(dateFinish).split("T")[0]}
                            onChange={(e) =>
                                setDateFinish(new Date(e.target.value))
                            }
                        />
                    </div>
                    <FindComponent
                        callback={(name) => {
                            changePage(1);
                            setSearchString(name);
                        }}
                    />
                </div>
            </div>
            <div className="card border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={["Название", ""]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <th className="border-bottom ps-6">
                                        Аккаунт
                                    </th>
                                    <th className="border-bottom">ФИО</th>
                                    <th className="border-bottom">
                                        Автомобиль
                                    </th>
                                    <th className="border-bottom">Период</th>
                                    <th className="border-bottom">Статус</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className="cursor-pointer"
                                    >
                                        <td className="ps-6">
                                            {item?.account?.title}
                                        </td>
                                        <td>{item.name}</td>
                                        <td>
                                            {item.carNumber
                                                ? item.carNumber +
                                                  " " +
                                                  item.carRegion
                                                : "---"}
                                        </td>
                                        <td>
                                            <GetPeriod
                                                start={item.dateStart}
                                                finish={item.dateFinish}
                                            />
                                        </td>
                                        <td>
                                            <GetState
                                                state={item.state}
                                                isArchived={item.isArchived}
                                                needExitConfirmation={
                                                    item.needExitConfirmation
                                                }
                                                id={item.id}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className="card-body py-3 px-5">
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={entityList?.paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList?.result.length}
                        countTotal={entityList?.paginator.totalCount}
                        forcePage={forcePage}
                    />
                </div>
            </div>
        </>
    );
};

export default GuestsList;
