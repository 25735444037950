import AccessLogComponent from '../../components/accesslogs/AccessLogComponent';
import PermissionPage from '../../components/PermissionPage';
import GuestEditPanel from './GuestEditPanel';

const GuestCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const guestId = props.match.params.guestId;

    return (
        <PermissionPage accountId={accountId} roles={['guest_list']}>
            <div className='row mb-3'>
                <div className='col-6'>
                    <GuestEditPanel accountId={accountId} guestId={guestId} />
                </div>
            </div>
            <div className='row'>
                <div className='col-6'>
                    <AccessLogComponent
                        accountId={accountId}
                        guestId={guestId}
                    />
                </div>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col'></div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default GuestCardPage;
