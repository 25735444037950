import React from 'react';
import { deleteAsync } from '../../app/storeReducers/AccountTariffsSlice';
import { useDispatch } from 'react-redux';

const DeleteModal = (props) => {
    const { entity, isOpen, closeHandle, deleteHandle } = props;

    const dispatch = useDispatch();

    const deleteEntity = async () => {
        dispatch(deleteAsync(entity.id));
        deleteHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Удалить тариф</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Вы действительно хотите удалить тариф{' '}
                                <strong className='text-danger'>
                                    {entity.name}
                                </strong>
                            </p>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-danger'
                                onClick={deleteEntity}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeleteModal;
