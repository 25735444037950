import { useEffect, useState } from 'react';
import {
    useCreateTicketMutation,
    useGetTicketByIdQuery,
    useUpdateTicketMutation,
} from '../../app/storeReducers/ticketsApiSlice';

const SupportTaskCreateModal = (props) => {
    const { accountId, isOpen, closeHandle, ticketId } = props;

    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');

    const { data: ticket } = useGetTicketByIdQuery(ticketId, {
        skip: !ticketId,
    });
    const [createAsync] = useCreateTicketMutation();
    const [updateAsync] = useUpdateTicketMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (ticket) {
                setSubject(ticket?.result?.subject);
                setDescription(ticket?.result?.description);
            }
        }

        loadResourcesAndDataAsync();
    }, [ticket]);

    const saveEntity = async () => {
        const entity = {
            account: { id: accountId },
            subject,
            description,
        };
        if (ticketId !== null && ticketId !== undefined) {
            entity.id = ticketId;
            updateAsync(entity);
        } else {
            createAsync(entity);
            setSubject('');
            setDescription('');
        }
        closeHandle();
    };

    return (
        <>
            <div className={'modal fade' + (isOpen ? ' show' : '')} tabIndex='-1'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Запрос в техподдержку</h5>
                            <button type='button' className='btn-close' onClick={closeHandle}></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-3 form-group'>
                                <label htmlFor='subject'>Тема</label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='subject'
                                    value={subject}
                                    onChange={(e) => setSubject(e.target.value)}
                                />
                            </div>
                            <div className='mb-3 form-group'>
                                <label htmlFor='subject'>Описание</label>
                                <textarea
                                    className='form-control'
                                    id='description'
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn av-btn-simple' onClick={closeHandle}>
                                Закрыть
                            </button>
                            <button type='button' className='btn av-btn-primary' onClick={saveEntity}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default SupportTaskCreateModal;
