import { useEffect, useState } from "react";
import {
    useCreateRoleMutation,
    useGetRoleByIdQuery,
    useGetRolesActionsQuery,
    useUpdateRoleMutation,
} from "../../app/storeReducers/rolesApiSlice";

const RoleCreateModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle } = props;

    const [name, setName] = useState("");
    const [actions, setActions] = useState([]);

    const { data: role } = useGetRoleByIdQuery(entityId, {
        skip: !entityId || entityId === -1,
    });
    const { data: roleActions } = useGetRolesActionsQuery(accountId, {
        skip: !accountId,
    });
    const [createAsync] = useCreateRoleMutation();
    const [updateAsync] = useUpdateRoleMutation();

    useEffect(() => {
        setName("");
        setActions([]);
    }, [isOpen]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (role) {
                setName(role.result.name);
                setActions(role.result.actions);
            } else {
                setName("");
                setActions([]);
            }
        }

        loadResourcesAndDataAsync();
    }, [role]);

    const saveEntity = async () => {
        const entity = {
            name,
            accountId,
            id: entityId,
            actions,
        };
        if (entityId === 0) {
            createAsync(entity);
        } else {
            updateAsync(entity);
        }
        closeHandle();
    };

    const changeAction = (e, actionName) => {
        if (e.target.checked) {
            setActions([...actions, actionName]);
        } else {
            setActions(actions.filter((x) => x !== actionName));
        }
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Добавить новую роль</h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-3">
                                <label htmlFor="name">Название</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>

                            <div className="row">
                                {roleActions?.result.map((item, index) => (
                                    <div className="col-12 mb-2" key={index}>
                                        <div className="action-title mb-2">
                                            <b>{item.name}</b>
                                        </div>
                                        {item.actions.map((actionItem) => (
                                            <div key={actionItem.key}>
                                                {parseInt(accountId) !== 1 &&
                                                actionItem.key ===
                                                    "root" ? null : (
                                                    <div className="action-item ps-10">
                                                        <div className="form-group form-check mb-3">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={
                                                                    "role_" +
                                                                    actionItem.key
                                                                }
                                                                name="roleActions"
                                                                onChange={(e) =>
                                                                    changeAction(
                                                                        e,
                                                                        actionItem.key
                                                                    )
                                                                }
                                                                checked={actions.includes(
                                                                    actionItem.key
                                                                )}
                                                                disabled={
                                                                    actionItem.disabled
                                                                }
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={
                                                                    "role_" +
                                                                    actionItem.key
                                                                }
                                                            >
                                                                {
                                                                    actionItem.value
                                                                }
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default RoleCreateModal;
