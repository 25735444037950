import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
    getBreadcrumbsAsync,
    selectBreadcrumbs
} from '../app/storeReducers/AccountBreadcrumbsSlice';
import { useAccount } from './useAccount';

export function useBreadCrumbs() {
    const params = useParams();
    const accountId = params.accountId;

    const dispatch = useDispatch();

    const account = useAccount();
    const breadcrumbs = useSelector(selectBreadcrumbs);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (accountId !== undefined && account.id !== parseInt(accountId,10)) {
                dispatch(getBreadcrumbsAsync(accountId));
            }
        }
        loadResourcesAndDataAsync();
    }, [accountId]);
    return breadcrumbs;
}
