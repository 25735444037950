import PersonalNotificationsList from './PersonalNotificationsList';

const PersonalNotificationsListPage = (props) => {

    return (
        <div className='row mt-4'>
            <div className='col-md-12'>
                <h1 className='mb-5'>Уведомления</h1>
                <PersonalNotificationsList accountId='' />
            </div>
        </div>
    );
};

export default PersonalNotificationsListPage;
