import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Http } from "../../http";
import { KeyValuePair } from "../../models/KeyValuePair";
import {RootState} from '../store';
import { addNotification } from './NotifySlice';

interface BaseState {
    isLoading: boolean,
    isErrors: boolean,
    isCurrentLoading: boolean,
    errorDescription: string,
    objectsArray: any,
    currentObject: any,
    paginator: any,
}

// Define the initial state using that type
const initialState: BaseState = {
  isLoading: true,
        isErrors: false,
        errorDescription: "",
        objectsArray: [],
        currentObject: {},
        paginator: [],
        isCurrentLoading: true
}

export class CRUDReducer {
    readonly nameSlice: string = 'default';
    readonly urlSlice: string = 'default';

    constructor(nameSlice: string, url: string) {
        this.nameSlice = nameSlice;
        this.urlSlice = url;
    }

    getListAsync: any = createAsyncThunk<{}, KeyValuePair[], {state: RootState}>(
        `${this.nameSlice}/getList`,
        async (urlParams: KeyValuePair[]) => {
            let url = `api/${this.urlSlice}`;
            if (urlParams.length > 0) {
                url += '?';
                urlParams.map(item => (
                    url += `${item.key}=${item.value}&`
                ));
                url = url.slice(0, -1);
            }
            const getResult = await Http.get(url);

            return getResult;
        }
    )

    getCurrentAsync: any = createAsyncThunk(
        `${this.nameSlice}/getById`,
        async (entityId: number) => {
            let url = `api/${this.urlSlice}/${entityId}`;
            const getResult = await Http.get(url);

            return getResult;
        }
    )

    createAsync: any = createAsyncThunk(
        `${this.nameSlice}/create`,
        async (entity: any, thunkAPI: any) => {
            const createResult = await Http.post(`api/${this.urlSlice}`, true, entity);
            if (createResult !== undefined && createResult != null) {
                if (createResult.error !== undefined && createResult.error !== null && createResult.error !== '') {
                    thunkAPI.dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Ошибка сохранения!',
                            description: createResult.errorDescription ? createResult.errorDescription : 'Не удалось сохранить данные! Перезагрузите страницу и попробуйте снова!',
                            type: 'danger',
                        })
                    );
                } else {
                    thunkAPI.dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Сохранено!',
                            description: 'Данные успешно сохранены!',
                            type: 'success',
                        })
                    );
                }
            } else {
                thunkAPI.dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка сохранения (500)!',
                        description:
                            'Не удалось сохранить данные! Перезагрузите страницу и попробуйте снова!',
                        type: 'danger',
                    })
                );
            }
            return createResult;
        }
    );

    updateAsync: any = createAsyncThunk(
        `${this.nameSlice}/update`,
        async (entity: any, thunkAPI: any) => {
            const result = await Http.put(
                `api/${this.urlSlice}/${entity.id}`,
                true,
                entity
            );

            if (result !== undefined && result != null) {
                if (result.error !== undefined && result.error !== null && result.error !== '') {
                    thunkAPI.dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Ошибка сохранения!',
                            description: result.errorDescription ? result.errorDescription : 'Не удалось сохранить изменения! Перезагрузите страницу и попробуйте снова!',
                            type: 'danger',
                        })
                    );
                } else {
                    thunkAPI.dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Сохранено!',
                            description: 'Изменения сохранены!',
                            type: 'success',
                        })
                    );
                }
            } else {
                thunkAPI.dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка сохранения (500)!',
                        description:
                            'Не удалось сохранить изменения! Перезагрузите страницу и попробуйте снова!',
                        type: 'danger',
                    })
                );
            }


            return result;
        }
    );

    deleteAsync: any = createAsyncThunk(
        `${this.nameSlice}/delete`,
        async (id: number, thunkAPI: any) => {
            const result = await Http.delete(`api/${this.urlSlice}/${id}`, true);

            if (result !== undefined && result != null) {
                if (result.error !== undefined && result.error !== null && result.error !== '') {
                    thunkAPI.dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Ошибка удаления!',
                            description: result.errorDescription ? result.errorDescription : 'Не удалось удалить запись! Перезагрузите страницу и попробуйте снова!',
                            type: 'danger',
                        })
                    );
                } else {
                    thunkAPI.dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Удалено!',
                            description: 'Запись успешно удалена!',
                            type: 'success',
                        })
                    );
                }
            } else {
                thunkAPI.dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка удаления (500)!',
                        description:
                            'Не удалось удалить запись! Перезагрузите страницу и попробуйте снова!',
                        type: 'danger',
                    })
                );
            }


            return id;
        }
    );

    reducerSlice = createSlice({
        name: this.nameSlice,
        initialState,
        // The `reducers` field lets us define reducers and generate associated actions
        reducers: {
        },
        extraReducers: {
            [this.getListAsync.pending]: (state) => {
                state.isLoading = true;
            },
            [this.getListAsync.fulfilled]: (state, action) => {
                if (
                    action.payload.error !== undefined &&
                    action.payload.error != null
                ) {
                    state.isErrors = true;
                    state.errorDescription = action.payload.errorDescription;
                    state.objectsArray = [];
                } else {
                    state.objectsArray = action.payload.result;
                    state.paginator = action.payload.paginator;
                }
                state.isLoading = false;
            },
            [this.getListAsync.rejected]: (state, action) => {
                console.error(this.nameSlice + '/getListError', action);
                state.isLoading = false;
                state.isErrors = true;
                state.errorDescription =
                    'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
            },
            [this.getCurrentAsync.pending]: (state) => {
                state.isCurrentLoading = true;
            },
            [this.getCurrentAsync.fulfilled]: (state, action) => {
                if (
                    action.payload.error !== undefined &&
                    action.payload.error != null
                ) {
                    state.isErrors = true;
                    state.errorDescription = action.payload.errorDescription;
                } else {
                    state.currentObject = action.payload.result;
                }
                state.isCurrentLoading = false;
            },
            [this.getCurrentAsync.rejected]: (state, action) => {
                console.error(this.nameSlice + '/getByIdError', action);
                state.isCurrentLoading = false;
                state.isErrors = true;
                state.errorDescription =
                    'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
            },
            [this.createAsync.pending]: (state) => {
                state.isCurrentLoading = true;
            },
            [this.createAsync.fulfilled]: (state, action) => {
                if (
                    action.payload.error !== undefined &&
                    action.payload.error != null
                ) {
                    state.isErrors = true;
                    state.errorDescription = action.payload.errorDescription;
                } else {
                    state.objectsArray.push(action.payload.result);
                    state.currentObject = action.payload.result;
                    state.isCurrentLoading = false;
                }
            },
            [this.createAsync.rejected]: (state, action) => {
                console.error(this.nameSlice + '/createError', action);
                state.isErrors = true;
                state.errorDescription =
                    'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
            },
            [this.updateAsync.pending]: (state) => {
                state.isLoading = true;
            },
            [this.updateAsync.fulfilled]: (state, action) => {
                if (
                    action.payload.error !== undefined &&
                    action.payload.error != null
                ) {
                    state.isErrors = true;
                    state.errorDescription = action.payload.errorDescription;
                } else {
                    const updateObj = state.objectsArray.map((item: any) => {
                        return (item.id === action.payload.result.id) ? action.payload.result : item;
                    });
                    state.objectsArray = updateObj;
                    state.currentObject = action.payload.result;
                    state.isCurrentLoading = false;
                }
                state.isLoading = false;
            },
            [this.updateAsync.rejected]: (state, action) => {
                console.error(this.nameSlice + '/updateError', action);
                state.isCurrentLoading = false;
                state.isErrors = true;
                state.errorDescription =
                    'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
                state.isLoading = false;
            },
            [this.deleteAsync.fulfilled]: (state, action) => {
                if (
                    action.payload.error !== undefined &&
                    action.payload.error != null
                ) {
                    state.isErrors = true;
                    state.errorDescription = action.payload.errorDescription;
                } else {
                    const updateObj = state.objectsArray.filter(
                        (x: any) => x.id !== action.payload
                    );
                    state.objectsArray = updateObj;
                }
            },
            [this.deleteAsync.rejected]: (state, action) => {
                console.log(this.nameSlice + '/deleteError', action);
                state.isErrors = true;
                state.errorDescription =
                    'На странице произошла ошибка. Перезагрузите страницу и попробуйте снова!';
            },
        },
    });
}