import PermissionPage from '../../../components/PermissionPage';
import CameraEditPanel from './CameraEditPanel';
import CameraDevicesPanel from './device/CameraDevicesPanel';

const CameraCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const cameraId = props.match.params.cameraId;

    return (
        <PermissionPage accountId={accountId} roles={['camera_list']}>
            <h1 className='mb-5'>Карточка камеры</h1>
            <div className='row'>
                <div className='col-md-6'>
                    <CameraEditPanel
                        accountId={accountId}
                        entityId={cameraId}
                    />
                </div>
                <div className='col-md-6'>
                    <CameraDevicesPanel
                        accountId={accountId}
                        entityId={cameraId}
                    />
                </div>
            </div>
        </PermissionPage>
    );
};

export default CameraCardPage;
