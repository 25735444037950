import { useEffect, useState } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import SkeletonTableFull from "../../../../components/skeleton/SkeletonTableFull";
import SchemeAddDeviceModal from "./SchemeAddDeviceModal";
import SchemeDeviceDeleteModal from "./SchemeDeviceDeleteModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import { useLazyGetSchemeDevicesQuery } from "../../../../app/storeReducers/schemesApiSlice";

const SchemeDevicesPanel = (props) => {
    const { accountId, entityId, isSubaccount } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [devices, setDevices] = useState([]);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const [getDevices, { isLoading }] = useLazyGetSchemeDevicesQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [entityId, canEdit]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("schemes_edit"));
        setCanList(context.isRoot || actions?.includes("schemes_list"));
    }, [context, accountId]);

    const loadData = async () => {
        const getResult = await getDevices(entityId).unwrap();
        getResult.result?.map((item) => ({
            ...item,
            btns:
                canEdit && !isSubaccount ? (
                    <button
                        className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
                        onClick={() => openDeleteModal(item)}
                    >
                        <i className="fas fa-trash-alt" />
                    </button>
                ) : null,
        }));
        setDevices(getResult.result);
    };

    const okAddDevice = () => {
        loadData();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteDevice = () => {
        loadData();
        setDeleteModalIsOpen(false);
    };

    const updDevices = devices?.map((item) => ({
        ...item,
        btns:
            canEdit && !isSubaccount ? (
                <button
                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1"
                    onClick={() => openDeleteModal(item)}
                >
                    <i className="fas fa-trash-alt" />
                </button>
            ) : null,
    }));

    return (
        <>
            <SchemeAddDeviceModal
                accountId={accountId}
                schemeId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddDevice}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <SchemeDeviceDeleteModal
                schemeId={entityId}
                device={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteDevice}
            />
            <div className="card border">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <h3>
                                Устройства{" "}
                                <span className="badge rounded-pill bg-light text-dark">
                                    {devices?.length}
                                </span>
                            </h3>
                        </div>
                        <div className="col-md-8 text-end">
                            {canEdit && !isSubaccount ? (
                                <button
                                    className="btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0"
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <KTSVG
                                        path="/media/icons/duotune/arrows/arr075.svg"
                                        className="svg-icon-2 me-2"
                                    />
                                    Добавить устройство
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {canList ? (
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: "id",
                            name: "Название",
                            uid: "UID",
                            btns: "",
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="mb-0"
                        entity={updDevices}
                        tdClasses={{ name: "ps-6" }}
                    />
                ) : (
                    <div className="card-body">
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default SchemeDevicesPanel;
