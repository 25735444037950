import { useEffect, useState } from "react";
import Paginator from "../../components/Paginator";
import SkeletonTableFull from "../../components/skeleton/SkeletonTableFull";
import {
    DateToString,
    ToUtcString,
    UtcToLocal,
} from "../../helpers/DateHelpers";
import { useLazyGetEventsQuery } from "../../app/storeReducers/eventsApiSlice";

const UserlogList = (props) => {
    const { accountId } = props;

    const [logs, setLogs] = useState([]);
    const [dateStart, setDateStart] = useState(new Date());
    const [dateFinish, setDateFinish] = useState(new Date());
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [paginator, setPaginator] = useState({});
    const [forcePage, setForcePage] = useState(1);

    const [getLogsAsync, { isLoading }] = useLazyGetEventsQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            let accId = accountId;

            const dtStart = dateStart;
            const dtStartString = `${dtStart.getFullYear()}-${
                dtStart.getMonth() + 1
            }-${dtStart.getDate()}T00:00:00`;
            const dtFinish = dateFinish;
            const dtFinishString = `${dtFinish.getFullYear()}-${
                dtFinish.getMonth() + 1
            }-${dtFinish.getDate()}T23:59:59`;

            const getLogs = await getLogsAsync({
                accountIds: accId,
                start: dtStartString,
                finish: dtFinishString,
                pageIndex,
                pageSize,
            }).unwrap();
            if (getLogs.paginator.totalPages < pageIndex) {
                setPageIndex(getLogs.paginator.totalPages);
            }
            const table = getLogs.result.map((item) => ({
                ...item,
                objectTitle: item.object?.name ?? "---",
                subObjectTitle: item.subObject?.name ?? "---",
                dtString: DateToString(UtcToLocal(item.time), true),
                user: item.employee?.title ?? item.login,
            }));
            console.log(getLogs);
            setForcePage(getLogs.paginator.pageIndex - 1);
            setLogs(table);
            setPaginator(getLogs.paginator);
        }

        loadResourcesAndDataAsync();
    }, [dateStart, dateFinish, pageIndex, pageSize]);

    const changePage = (index) => {
        setPageIndex(index);
    };
    const changePageSize = async (size) => {
        setPageSize(size);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-4 mb-3"></div>
                <div className="col-md-8 mb-3 d-flex justify-content-end">
                    <div
                        className="input-group me-2"
                        style={{ width: "400px" }}
                    >
                        <span className="input-group-text">С</span>
                        <input
                            type="date"
                            className="form-control"
                            value={ToUtcString(dateStart).split("T")[0]}
                            onChange={(e) =>
                                setDateStart(new Date(e.target.value))
                            }
                        />
                        <span className="input-group-text">ПО</span>
                        <input
                            type="date"
                            className="form-control"
                            value={ToUtcString(dateFinish).split("T")[0]}
                            onChange={(e) =>
                                setDateFinish(new Date(e.target.value))
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="card border">
                <div className="table-responsive">
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: "time",
                            dtString: "Дата",
                            user: "Пользователь",
                            description: "Действие",
                            objectTitle: "Объект 1",
                            subObjectTitle: "Объект 2",
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="mb-0"
                        tdClasses={{ dtString: "ps-6" }}
                        entity={logs}
                    />
                </div>
                <div className="card-body py-3 px-5">
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={logs.length}
                        countTotal={paginator.totalCount}
                        forcePage={forcePage}
                    />
                </div>
            </div>
        </>
    );
};

export default UserlogList;
