import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
} from '../../app/storeReducers/AccountCamerasSlice';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import { useHistory } from 'react-router-dom';
import CameraCreateModal from './CameraCreateModal';
import FrameModal from './cameraCard/FrameModal';
import SecutiryContext from '../../features/securityContext';

const CamerasList = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);

    const { context } = useContext(SecutiryContext);
    const [isOpenImageModal, setIsOpenImageModal] = useState(false);
    const [selectedEntityId, setSelectedEntityId] = useState(0);
    const [selectedEntityName, setSelectedEntityName] = useState(0);
    const [selectedEntityProxy, setSelectedEntityProxy] = useState(false);
    const [entityForClone, setEntityForClone] = useState(undefined);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);

    useEffect(() => {
        dispatch(getListAsync([{ key: 'accountId', value: accountId }]));
    }, [accountId, dispatch]);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
        setEntityForClone(undefined);
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(
                `/account/${accountId}/admin/cameras/${entityId}`,
                '_blank'
            );
        else history.push(`/account/${accountId}/admin/cameras/${entityId}`);
    };
    const openImageModal = (e, entityId, name, isRtspProxy) => {
        e.stopPropagation();
        setSelectedEntityId(entityId);
        setSelectedEntityName(name);
        setIsOpenImageModal(true);
        setSelectedEntityProxy(isRtspProxy);
    };

    const closeFrameModal = () => {
        setIsOpenImageModal(false);
        setSelectedEntityId(0);
    };

    return (
        <>
            <CameraCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
                cloneEntity={entityForClone}
            />
            <FrameModal
                cameraId={selectedEntityId}
                name={selectedEntityName}
                isOpen={isOpenImageModal}
                closeHandle={closeFrameModal}
                isRtspProxy={selectedEntityProxy}
            />
            <div className='row'>
                <div className='col-md-4 mb-3'>
                    <button
                        className='btn av-btn-primary fw-boldest'
                        onClick={() => openCreateModal(0)}
                        disabled={!context.isRoot}
                    >
                        <i className='fas fa-plus me-3' />
                        Новая камера
                    </button>
                </div>
            </div>
            <div className='card border'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Название', 'Адрес', 'Порт', 'Путь']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    <th className='border-bottom ps-6'>
                                        Название
                                    </th>
                                    <th className='border-bottom'>Адрес</th>
                                    <th className='border-bottom'>Порт</th>
                                    <th className='border-bottom'>Путь</th>
                                    <th className='border-bottom'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className='cursor-pointer'
                                    >
                                        <td className='ps-6'>{item.name}</td>
                                        <td>{item.address}</td>
                                        <td>{item.port}</td>
                                        <td>{item.path}</td>
                                        <td>
                                            <button
                                                className='btn btn-sm btn-light-primary ms-2'
                                                onClick={(e) =>
                                                    openImageModal(
                                                        e,
                                                        item.id,
                                                        item.name,
                                                        item.isRtspProxy
                                                    )
                                                }
                                            >
                                                {item.isRtspProxy ? (
                                                    <span className='fas fa-video' />
                                                ) : (
                                                    <span className='fas fa-camera' />
                                                )}
                                            </button>
                                            <button
                                                className='btn btn-sm btn-light-primary ms-2'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setEntityForClone(item);
                                                    openCreateModal(0);
                                                }}
                                            >
                                                <span className='far fa-clone' />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default CamerasList;
