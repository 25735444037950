const apiUrl = process.env.REACT_APP_PLATFORM_URL;

export class Http {
    static HEADERS = { 'Content-Type': 'application/json' };

    static async get(url, isSecure = false) {
        try {
            return await request(url, 'GET', isSecure);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    static async post(url, isSecure = false, data = {}) {
        try {
            return await request(url, 'POST', isSecure, data);
        } catch (e) {
            console.log(e);
        }
    }

    static async delete(url, isSecure = false) {
        try {
            return await request(url, 'DELETE', isSecure);
        } catch (e) {
            console.log(e);
        }
    }

    static async put(url, isSecure = false, data = {}) {
        try {
            return await request(url, 'PUT', isSecure, data);
        } catch (e) {
            console.log(e);
        }
    }

    static async postMedia(url, data) {
        try {
            const mediaHeader = {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            };
            const config = {
                method: 'POST',
                headers: mediaHeader,
            };

            config.body = data;
            const response = await fetch(apiUrl + url, config);
            return await response.json();
        } catch (e) {
            console.log(e);
        }
    }
    static async getMedia(url) {
        try {
            const mediaHeader = {
                Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            };
            const config = {
                method: 'GET',
                headers: mediaHeader,
            };

            const response = await fetch(apiUrl + url, config);
            return await response.blob();
        } catch (e) {
            console.log(e);
        }
    }
}

async function request(url, method = 'GET', isSecure = false, data) {
    const accessToken = localStorage.getItem('access_token');
    if (accessToken !== undefined && accessToken !== null) {
        const dtNow = new Date();
        const dtFall = new Date(dtNow.getTime() + 60000 * 5);
        const dateToken = new Date(localStorage.getItem('access_token_time'));

        const refreshTokenDate = new Date(
            localStorage.getItem('refresh_token_time')
        );

        if (dateToken < dtFall) {
            if (refreshTokenDate < dtNow) {
                localStorage.removeItem('refresh_token');
                localStorage.removeItem('refresh_token_time');
                window.location.href = '/login';
            } else {
                const tokenNew = await fetch(
                    apiUrl + 'api/auth/refresh_token',
                    {
                        method: 'POST',
                        headers: Http.HEADERS,
                        body: JSON.stringify({
                            refresh_token:
                                localStorage.getItem('refresh_token'),
                        }),
                    }
                );
                const tokenResult = await tokenNew.json();
                if (
                    tokenResult.error !== null &&
                    tokenResult.error !== undefined &&
                    tokenResult.error !== ''
                ) {
                    await delay(1000);
                } else {
                    localStorage.setItem(
                        'access_token',
                        tokenResult.result.access_token.value
                    );
                    localStorage.setItem(
                        'access_token_time',
                        tokenResult.result.access_token.expireAt
                    );
                    localStorage.setItem(
                        'refresh_token',
                        tokenResult.result.refresh_token.value
                    );
                    localStorage.setItem(
                        'refresh_token_time',
                        tokenResult.result.refresh_token.expireAt
                    );
                }
            }
        }
    }

    Http.HEADERS.Authorization =
        'Bearer ' + localStorage.getItem('access_token');

    const config = {
        method,
        headers: Http.HEADERS,
    };

    if (method === 'POST' || method === 'PUT') {
        config.body = JSON.stringify(data);
    }

    const response = await fetch(apiUrl + url, config);
    //console.log('httpResponse', response);
    if (response.status === 401) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_time');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('refresh_token_time');
        window.location.href = '/login';
    }

    return await response.json();
}

const delay = (millis) =>
    new Promise((resolve, reject) => {
        setTimeout((_) => resolve(), millis);
    });
