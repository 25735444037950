import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
    getAccountAsync,
    selectAccount,
} from '../app/storeReducers/AccountSlice';

export function useAccount() {
    const params = useParams();
    const accountId = params.accountId;

    const dispatch = useDispatch();

    const account = useSelector(selectAccount);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (accountId !== undefined && account.id !== parseInt(accountId,10)) {
                dispatch(getAccountAsync(accountId));
            }
        }

        loadResourcesAndDataAsync();
    }, [accountId]);
    return account;
}
