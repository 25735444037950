import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';

import { ComponentToPrint } from './ComponentToPrint';

export const PrintExample = (props) => {
    const { text } = props;
    const componentRef = useRef();
  
    return (
      <div>
        <ReactToPrint
          trigger={() => <button>Напечатать QR</button>}
          content={() => componentRef.current}
        />
        <div style={{display: 'none'}} >
            <ComponentToPrint ref={componentRef} text={text} />
        </div>
      </div>
    );
  };