import TimesheetList from './TimesheetList';

const TimesheetPage = (props) => {
    const accountId = props.match.params.accountId;
    return (
        <div className='row'>
            <div className='col-md-12'>
                <h1 className='mb-5'>Отчет "Табель"</h1>
                <TimesheetList accountId={accountId} />
            </div>
        </div>
    );
};

export default TimesheetPage;
