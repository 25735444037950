// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";

// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const ComponentToPrint = React.forwardRef((props, ref) => {
    const { text } = props;
    const url = `https://qr.avanguard.pro/print?text=${text}`;
    return (
      <div ref={ref}>
        <img src={url} />
      </div>
    );
  });