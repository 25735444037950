import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateToString, UtcToLocal } from '../../../helpers/DateHelpers';
import { HumanPhone } from '../../../helpers/PhoneHelper';
import EmployeeCreateModal from '../EmployeeCreateModal';
import EmployeeDeleteModal from '../EmployeeDeleteModal';
import FaceWarnings from './FaceWarnings';
import { selectContext } from '../../../app/storeReducers/AuthSlice';
import { useGetEmployeeByIdQuery, useUpdateEmployeeMutation } from '../../../app/storeReducers/employeesApiSlice';
import WaitLoading from '../../../components/WaitLoading';

const EmployeePanel = (props) => {
    const { employeeId } = props;

    const { data: employee, isLoading } = useGetEmployeeByIdQuery(
        { id: employeeId, thumbnail: true },
        { skip: !employeeId }
    );
    const [updateEmployeeAsync] = useUpdateEmployeeMutation();

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);

    const context = useSelector(selectContext);
    const [canWrite, setCanWrite] = useState(false);

    useEffect(() => {
        function loadResourcesAndData() {
            if (employee && context) {
                const actions = context?.permissions?.find(
                    (x) => x.id === parseInt(employee?.result?.account?.id)
                )?.actions;
                setCanWrite(context?.isRoot || actions?.includes('employees_edit'));
            }
        }

        loadResourcesAndData();
    }, [employee, context]);

    const lockUnlockEmployee = async (isBlock) => {
        let empEdited = { ...employee?.result };
        empEdited.blocked = isBlock;
        const dtNow = new Date();
        if (!isBlock && empEdited.dateFinish && dtNow > new Date(empEdited.dateFinish)) {
            empEdited.dateFinish = null;
        }
        updateEmployeeAsync(empEdited);
    };

    const closeUpdateModal = () => {
        setUpdateModalIsOpen(false);
    };

    return (
        <WaitLoading isLoading={isLoading}>
            <EmployeeCreateModal
                accountId={employee?.result?.account?.id}
                employeeId={employeeId}
                isOpen={updateModalIsOpen}
                closeHandle={closeUpdateModal}
            />
            <EmployeeDeleteModal
                employee={employee?.result}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card border' id='employeePanel'>
                <div className='card-body employee-card p-6'>
                    <div className='row mb-4'>
                        <div className='col d-flex'>
                            <div>
                                {employee?.result?.thumbnail ? (
                                    <img
                                        src={employee?.result?.thumbnail}
                                        className='rounded-circle me-3'
                                        style={{
                                            width: 64,
                                            height: 64,
                                        }}
                                    />
                                ) : (
                                    <div className='avatar-text rounded-circle me-3'>
                                        {`${
                                            employee?.result?.lastName?.length > 0 ? employee?.result?.lastName[0] : ''
                                        }${
                                            employee?.result?.firstName?.length > 0
                                                ? employee?.result?.firstName[0]
                                                : ''
                                        }`.trim()}
                                    </div>
                                )}
                            </div>
                            <div>
                                <div className='employee-fio mb-1'>
                                    {`${employee?.result?.lastName} ${employee?.result?.firstName} ${employee?.result?.secondName}`
                                        .replace('  ', ' ')
                                        .trim()}
                                </div>
                                <div className='employee-phone d-flex align-items-center'>
                                    <img
                                        src='/media/icons/base/smartphone.svg'
                                        className='h-20px me-1'
                                        alt='avanguard logo'
                                    />{' '}
                                    <span>
                                        {employee?.result?.user ? (
                                            HumanPhone(employee?.result?.user?.title)
                                        ) : (
                                            <i>Пользователь не привязан</i>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='fild-label'>Должность</div>
                            <div className='fild-text'>{employee?.result?.occupation}</div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='fild-label'>Табельный номер</div>
                            <div className='fild-text'>{employee?.result?.persNumber || ''}</div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='fild-label'>Период действия</div>
                            <div className='fild-text'>
                                {employee?.result?.dateStart != null
                                    ? DateToString(UtcToLocal(employee?.result?.dateStart))
                                    : '-'}{' '}
                                -{' '}
                                {employee?.result?.dateFinish != null
                                    ? DateToString(UtcToLocal(employee?.result?.dateFinish))
                                    : '-'}
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='fild-label'>Комментарий</div>
                            <div className='fild-text'>{employee?.result?.comments}</div>
                        </div>
                    </div>
                    {canWrite ? (
                        <div className='row mb-4'>
                            <div className='col-md-8'>
                                {employee?.result?.blocked ? (
                                    <button
                                        className='btn av-btn-simple av-border-warning-500 fw-boldest me-3'
                                        onClick={() => lockUnlockEmployee(false)}
                                    >
                                        <i className='fas fa-unlock me-2' />
                                        Разблокировать
                                    </button>
                                ) : (
                                    <button
                                        className='btn av-btn-warning av-border-warning-500 fw-boldest me-3'
                                        onClick={() => lockUnlockEmployee(true)}
                                        disabled={isLoading}
                                    >
                                        <img
                                            src='/media/icons/avanguard/lock.png'
                                            height='20px'
                                            className='align-top me-2'
                                        />
                                        Заблокировать
                                    </button>
                                )}
                                <button
                                    className='btn av-btn-danger fw-boldest'
                                    onClick={() => setDeleteModalIsOpen(true)}
                                    disabled={isLoading}
                                >
                                    <img
                                        src='/media/icons/avanguard/trash-white.png'
                                        height='20px'
                                        className='align-top me-2'
                                    />
                                    Удалить
                                </button>
                            </div>
                            <div className='col text-end'>
                                <button
                                    className='btn av-btn-simple fw-boldest'
                                    onClick={() => setUpdateModalIsOpen(true)}
                                    disabled={isLoading}
                                >
                                    <img src='/media/icons/base/edit-3.svg' height='20px' className='align-top me-2' />{' '}
                                    Изменить
                                </button>
                            </div>
                        </div>
                    ) : null}
                    {employee?.result?.warnings?.includes('a') || employee?.result?.warningDescriptions !== null ? (
                        <div className='card info-card mb-4 av-bg-warning av-border-warning border'>
                            <div className='card-body py-3 px-4'>
                                <div className='d-flex flex-row'>
                                    <div className='info-card-icon info-card-icon-warning me-4 align-self-center'>
                                        <img src='/media/icons/base/info.svg' />
                                    </div>
                                    <div className='align-self-center'>
                                        <FaceWarnings warnings={employee?.result?.warnings} />
                                        {employee?.result?.warningDescriptions?.map((item, index) => (
                                            <div key={index}>{item}</div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </WaitLoading>
    );
};

export default EmployeePanel;
