import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectIsAuthorize,
    loginUserAsync,
    getCurrentUserAsync,
    setIsLoading,
} from '../app/storeReducers/CurrentUserSlice';
import { Link, useHistory } from 'react-router-dom';
import WaitLoading from '../components/WaitLoading';
import InputMask from 'react-input-mask';

const LoginPage = (props) => {
    const history = useHistory();

    const isLoading = useSelector((state) => state.currentUser.isLoading);
    const isAuthorize = useSelector(selectIsAuthorize);
    const user = useSelector((state) => state.currentUser.user);
    const errorDescription = useSelector(
        (state) => state.currentUser.errorDescription
    );
    const errorType = useSelector((state) => state.currentUser.errorType);
    const dispatch = useDispatch();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const loginHandle = () => {
        const loginParams = {
            auth: {
                login,
                password,
            },
            typeLogin: 1,
        };
        dispatch(loginUserAsync(loginParams));
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            loginHandle();
        }
    };

    useEffect(() => {
        let redirectUrl = '/personal/overview';
        if (props.location.state !== undefined) {
            redirectUrl = props.location.state.from;
        }
        if (isAuthorize && user.length === 0) {
            dispatch(getCurrentUserAsync());
        } else {
            dispatch(setIsLoading(false));
        }
        if (user !== undefined && user != null && user.login !== undefined) {
            history.push(redirectUrl);
        }
    });

    return (
        <WaitLoading isLoading={isLoading}>
            <div className='row ms-0 me-0'>
                <div
                    className='col-md-6 login-left-block d-flex flex-column justify-content-between'
                    style={{
                        backgroundImage: 'url(/images/login-background.png)',
                    }}
                >
                    <div>
                        <img
                            src='/images/logo-inverted.svg'
                            className='h-30px'
                            alt='avanguard logo'
                        />
                        <p className='login-left-block-text'>
                            Облачная система контроля
                            <br />и управления доступом
                        </p>
                    </div>
                </div>
                <div className='col-md-6 login-right-block d-flex justify-content-between flex-column'>
                    <div></div>
                    <div className='row'>
                        <div className='col-lg-8 offset-lg-2'>
                            <form>
                                <div className='pb-5 pt-lg-0'>
                                    <h1 className='font-size-h1-lg fw-boldest'>
                                        Вход
                                    </h1>
                                    <p className='text-gray-700 pt-1'>
                                        Добро пожаловать в Avanguard
                                    </p>
                                </div>
                                <div className='form-group mb-5'>
                                    <label htmlFor='login'>
                                        Номер телефона
                                    </label>
                                    <InputMask
                                        mask='+7(999)999-99-99'
                                        className={
                                            'form-control' +
                                            (errorType === 'invalid_login' ||
                                            errorType ===
                                                'invalid_username_or_password'
                                                ? ' is-invalid'
                                                : '')
                                        }
                                        id='login'
                                        value={login}
                                        onChange={(e) =>
                                            setLogin(e.target.value)
                                        }
                                        placeholder='+7(___)___-__-__'
                                    />
                                    <div className='invalid-feedback'>
                                        {errorDescription}
                                    </div>
                                </div>
                                <div className='form-group mb-5 position-relative'>
                                    <div
                                        className='position-absolute show-password cursor-pointer'
                                        onClick={() =>
                                            setShowPassword(!showPassword)
                                        }
                                    >
                                        {showPassword ? (
                                            <i className='fas fa-eye-slash' />
                                        ) : (
                                            <i className='fas fa-eye' />
                                        )}
                                    </div>
                                    <label htmlFor='password'>Пароль</label>
                                    <input
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        className={
                                            'form-control' +
                                            (errorType ===
                                            'invalid_username_or_password'
                                                ? ' is-invalid'
                                                : '')
                                        }
                                        id='password'
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        onKeyPress={handleKeyPress}
                                    />
                                    <div className='invalid-feedback'>
                                        {errorDescription}
                                    </div>
                                    <div className='form-text'>
                                        <Link
                                            to={`/forgotPassword`}
                                            className='btn btn-link text-gray-700 pt-1 ps-0'
                                        >
                                            Забыли пароль?
                                        </Link>
                                    </div>
                                </div>
                                <div className='d-grid'>
                                    <button
                                        type='button'
                                        className='btn av-btn-primary'
                                        onClick={loginHandle}
                                    >
                                        Войти
                                    </button>
                                </div>
                            </form>
                            <div className='row mt-10'>
                                <div className='col text-center'>
                                    Нет учётной записи?{' '}
                                    <Link
                                        to={`/registration`}
                                        className='btn-link av-text-primary fw-bolder'
                                    >
                                        Зарегистрироваться
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='d-flex justify-content-between'>
                                <div>© Связь-холдинг, 2022</div>
                                <div>
                                    <a
                                        href='mailto:help@avanguard.pro'
                                        className='text-gray-700'
                                    >
                                        <i className='far fa-envelope pe-2' />
                                        help@avanguard.pro
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </WaitLoading>
    );
};

export default LoginPage;
