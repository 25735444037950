import { useEffect, useState } from 'react';
import Paginator from '../../../components/Paginator';
import { Http } from '../../../http';
import EventsList from './EventsList';

const EventsPage = (props) => {
    const deviceId = props.match.params.deviceId;

    const [isLoading, setIsLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [dateEvents, setDateEvents] = useState(new Date());
    const [paginator, setPaginator] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(1);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const dtNow = dateEvents;
            const dtString = `${dtNow.getFullYear()}-${
                dtNow.getMonth() + 1
            }-${dtNow.getDate()}`;
            const getResult = await Http.get(
                `api/source_log?deviceId=${deviceId}&start=${dtString}T00:00:00&finish=${dtString}T23:59:59&pageIndex=${pageIndex}&pageSize=${pageSize}`,
                true
            );
            setEvents(getResult.result);
            setPaginator(getResult.paginator);
            setIsLoading(false);
            setForcePage(pageIndex - 1);
        }

        loadResourcesAndDataAsync();
    }, [dateEvents, pageIndex, pageSize]);

    const changePage = async (pageIndex) => {
        console.log(pageIndex);
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(1);
        setPageSize(size);
    };

    const refreshData = async () => {
        const dtNow = dateEvents;
        const dtString = `${dtNow.getFullYear()}-${
            dtNow.getMonth() + 1
        }-${dtNow.getDate()}`;
        const getResult = await Http.get(
            `api/source_log?deviceId=${deviceId}&start=${dtString}T00:00:00&finish=${dtString}T23:59:59&pageIndex=${pageIndex}&pageSize=${pageSize}`,
            true
        );
        console.log('result', getResult);
        setEvents(getResult.result);
        setPaginator(getResult.paginator);
        setIsLoading(false);
        setForcePage(pageIndex - 1);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-2'>
                    <div className='form-group'>
                        <label className='mr-2' htmlFor='eventDate'>
                            Дата события
                        </label>
                        <input
                            type='date'
                            className='form-control mr-2'
                            id='eventDate'
                            value={dateEvents.toISOString().split('T')[0]}
                            onChange={(e) =>
                                setDateEvents(new Date(e.target.value))
                            }
                        />
                    </div>
                </div>
                <div className='col-md-2 mt-auto'>
                    <button className='btn av-btn-simple' onClick={refreshData}>
                        <i className='fas fa-sync-alt' /> Обновить
                    </button>
                </div>
            </div>
            <div className='row mt-4 mb-4'>
                <div className='col-md-12'>
                    <EventsList events={events} isLoading={isLoading} />
                </div>
            </div>
            <Paginator
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                pageCount={paginator.totalPages}
                pageSizeArray={[50, 100, 500]}
                pageSize={pageSize}
                countCurrent={events.length}
                countTotal={paginator.totalCount}
                forcePage={forcePage}
            />
        </>
    );
};

export default EventsPage;
