import { useEffect, useState } from 'react';
import EmployeeAddSchemeModal from './EmployeeAddSchemeModal';
import EmployeeSchemeDeleteModal from './EmployeeSchemeDeleteModal';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import { useSelector } from 'react-redux';
import { selectContext } from '../../../../app/storeReducers/AuthSlice';
import { useGetEmployeeSchemesQuery } from '../../../../app/storeReducers/employeesApiSlice';

const EmployeeSchemesPanel = (props) => {
    const { accountId, employeeId } = props;

    const context = useSelector(selectContext);

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);

    const [deleteScheme, setDeleteScheme] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const { data: schemes, refetch } = useGetEmployeeSchemesQuery(employeeId, { skip: !employeeId });

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            //init tooltip
            Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
                (tooltipNode) => new Tooltip(tooltipNode)
            );
        }

        loadResourcesAndDataAsync();
    }, []);

    useEffect(() => {
        const actions = context?.permissions?.find((x) => x.id === parseInt(accountId))?.actions;
        setCanEdit(context.isRoot || actions?.includes('employees_edit'));
        setCanList(context.isRoot || actions?.includes('employees_list'));
    }, [context, accountId]);

    const okAddScheme = () => {
        refetch();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (scheme) => {
        setDeleteScheme(scheme);
        setDeleteModalIsOpen(true);
    };

    const deleteEmpScheme = () => {
        refetch();
        setDeleteModalIsOpen(false);
    };

    return (
        <>
            <EmployeeAddSchemeModal
                accountId={accountId}
                employeeId={employeeId}
                isOpen={addModalIsOpen}
                okHandle={okAddScheme}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <EmployeeSchemeDeleteModal
                employeeId={employeeId}
                member={deleteScheme}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteEmpScheme}
            />
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <div className='av-card-title'>
                        Схемы{' '}
                        <span className='badge rounded-pill bg-light text-dark ms-2 align-text-top'>
                            {schemes?.result.length}
                        </span>
                    </div>
                </div>
                <div className='col-md-6 text-end'>
                    {canEdit ? (
                        <button
                            className='btn btn-sm btn-light-primary fw-boldest av-text-gray-600 pe-0 py-1'
                            onClick={() => setAddModalIsOpen(true)}
                        >
                            <img
                                src='/media/icons/avanguard/plus-gray-500.png'
                                className='me-2 align-top'
                                height={20}
                            />
                            Назначить схемы
                        </button>
                    ) : null}
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    {canList ? (
                        schemes?.result.map((item) => (
                            <div
                                className='border av-border-gray-300 rounded d-inline-block px-4 py-2 me-2 mb-2 fw-bolder'
                                key={item.id}
                            >
                                {item.title}{' '}
                                {item.description == null ? (
                                    canEdit ? (
                                        <img
                                            src='/media/icons/avanguard/x-gray-400.png'
                                            className='ms-2 cursor-pointer align-top'
                                            onClick={() => openDeleteModal(item)}
                                            height={20}
                                        />
                                    ) : null
                                ) : (
                                    <i
                                        className='ms-2 fas fa-info'
                                        data-bs-toggle='tooltip'
                                        data-bs-placement='top'
                                        title={'Схема группы: ' + item.description}
                                    ></i>
                                )}
                            </div>
                        ))
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                </div>
            </div>
        </>
    );
};

export default EmployeeSchemesPanel;
