import { getRoles } from "@testing-library/react";
import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const rolesApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getRoles: builder.query({
            query: (data) =>
                `/roles?accountId=${data.accountId ?? ""}&employeeId=${
                    data.employeeId ?? ""
                }&sort=${data.sort ?? ""}&pageIndex=${
                    data.pageIndex ?? 1
                }&pageSize=${data.pageSize ?? 100}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Roles",
                              id,
                          })),
                          { type: "Roles", id: "List" },
                      ]
                    : [{ type: "Roles", id: "List" }],
        }),
        getRoleById: builder.query({
            query: (id) => `/roles/${id}`,
            providesTags: (result) => [{ type: "Role", id: result?.result.id }],
        }),
        createRole: builder.mutation({
            query: (body) => ({
                url: "roles",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Roles", id: "List" }],
        }),
        updateRole: builder.mutation({
            query: (body) => ({
                url: "roles/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Roles", id: "List" },
                { type: "Role", id: result?.result.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить данные роли! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Роль сохранена!",
                                description: "Данные роли успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить данные роли! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteRole: builder.mutation({
            query: (id) => ({
                url: "roles/" + id,
                method: "DELETE",
            }),
            invalidatesTags: (result) => [
                { type: "Roles", id: "List" },
                { type: "Role", id: result?.result.id },
            ],
        }),
        getRolesActions: builder.query({
            query: (accountId) => `/roles/actions?accountId=${accountId}`,
        }),
        getRolesActionsById: builder.query({
            query: (id) => `/roles/${id}/actions`,
        }),
    }),
});

export const {
    useGetRolesQuery,
    useGetRoleByIdQuery,
    useCreateRoleMutation,
    useUpdateRoleMutation,
    useDeleteRoleMutation,
    useGetRolesActionsQuery,
    useGetRolesActionsByIdQuery,
} = rolesApiSlice;
