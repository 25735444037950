import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonTable from "../../../../components/skeleton/SkeletonTable";
import { useHistory } from "react-router-dom";
import ParkingPlaceCreateModal from "./ParkingPlaceCreateModal";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import { useGetParkingPlacesQuery } from "../../../../app/storeReducers/parkingPlacesApiSlice";

const ParkingPlacesList = (props) => {
    const { accountId, parkingId } = props;

    const history = useHistory();

    const context = useSelector(selectContext);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const { data: entityList, isLoading } = useGetParkingPlacesQuery(
        parkingId,
        {
            skip: !parkingId,
        }
    );

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("parking_place_edit"));
    }, [context, accountId]);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const openEntityCard = (entityId) => {
        history.push(
            `/account/${accountId}/parking/${parkingId}/places/${entityId}`
        );
    };

    return (
        <>
            <ParkingPlaceCreateModal
                parkingId={parkingId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className="row">
                <div className="col mb-3">
                    <button
                        className="btn av-btn-primary"
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className="fas fa-plus me-3" />
                        Добавить парковочное место
                    </button>
                </div>
            </div>
            <div className="card border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={["Парковка", "Номер", "Владелец", "Транспорт"]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <th className="border-bottom ps-6">
                                        Парковка
                                    </th>
                                    <th className="border-bottom">Номер</th>
                                    <th className="border-bottom">Владелец</th>
                                    <th className="border-bottom">Транспорт</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result?.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={() => openEntityCard(item.id)}
                                        className="cursor-pointer"
                                    >
                                        <td className="ps-6">
                                            {item.area.title}
                                        </td>
                                        <td>{item.number}</td>
                                        <td>{item.service?.consumer?.title}</td>
                                        <td>
                                            {item.vehicle?.carNumber}
                                            {item.vehicle?.carRegion} -{" "}
                                            {item.vehicle?.model}
                                            <br />
                                            {item.vehicle?.account?.title}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default ParkingPlacesList;
