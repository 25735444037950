import { useEffect, useState } from "react";
import { useGetScheduleQuery } from "../../../app/storeReducers/SchedulesAPI";
import ScheduleDeleteModal from "../ScheduleDeleteModal";
import ScheduleCreateModal from "../ScheduleCreateModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../../app/storeReducers/AuthSlice";

const SchedulePanel = (props) => {
    const { accountId, entityId } = props;

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);

    const { data } = useGetScheduleQuery(entityId);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("devices_edit"));
    }, [context, accountId]);

    return (
        <>
            <ScheduleCreateModal
                accountId={accountId}
                entity={data?.result}
                isOpen={updateModalIsOpen}
                closeHandle={() => setUpdateModalIsOpen(false)}
            />
            <ScheduleDeleteModal
                entity={data?.result}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className="card border mb-3">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col d-flex">
                            <div className="align-self-center av-card-title">
                                {data?.result?.name}
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="fild-label">Комментарий</div>
                            <div className="fild-text">
                                {data?.result?.comments}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <div className="fild-text d-inline">
                                {data?.result?.isActive ? (
                                    <span className="badge bg-success">
                                        Включено
                                    </span>
                                ) : (
                                    <span className="badge bg-secondary text-dark">
                                        Выключено
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3 mt-5">
                        <div className="col-md-5">
                            {canEdit ? (
                                <button
                                    className="btn av-btn-danger fw-boldest"
                                    onClick={() => setDeleteModalIsOpen(true)}
                                >
                                    <i className="far fa-trash-alt me-2" />{" "}
                                    Удалить
                                </button>
                            ) : null}
                        </div>
                        <div className="col text-end">
                            {canEdit ? (
                                <button
                                    className="btn av-btn-simple fw-boldest"
                                    onClick={() => setUpdateModalIsOpen(true)}
                                >
                                    <img
                                        src="/media/icons/base/edit-3.svg"
                                        className="align-top"
                                    />{" "}
                                    Изменить
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SchedulePanel;
