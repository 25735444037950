import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../app/storeReducers/NotifySlice';
import SkeletonTableFull from '../../../components/skeleton/SkeletonTableFull';
import { DateToString, ToUtcString, UtcToLocal } from '../../../helpers/DateHelpers';
import { Http } from '../../../http';
import { saveAs } from 'file-saver';
import EmployeesDropDownInput from '../EmployeesDropDownInput';
import Paginator from '../../../components/Paginator';

const PresenceList = (props) => {
    const { accountId } = props;

    const dispatch = useDispatch();

    const [dateStart, setDateStart] = useState(new Date());
    const [dateFinish, setDateFinish] = useState(new Date());
    const [isLoading, setIsLoading] = useState(true);
    const [report, setReport] = useState([]);
    const [paginator, setPaginator] = useState({});
    const [locations, setLocations] = useState([]);
    const [locationId, setLocationId] = useState(0);
    const [employeeId, setEmployeeId] = useState(0);
    const [groups, setGroups] = useState([]);
    const [groupId, setGroupId] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(0);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(false);
            try {
                const locationResponse = await Http.get('api/locations?withParents=true&accountId=' + accountId, true);
                setLocations(locationResponse.result);

                const groupsResponse = await Http.get('api/groups?accountId=' + accountId, true);
                setGroups(groupsResponse.result);
            } catch (error) {
                console.log('error', error);
                dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка!',
                        description: 'Перезагрузите страницу и попробуйте снова! ' + error.message,
                        type: 'danger',
                    })
                );
            }
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(false);
            const dtStartString = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`;
            const dtFinishString = `${dateFinish.getFullYear()}-${
                dateFinish.getMonth() + 1
            }-${dateFinish.getDate()}T23:59:59`;
            try {
                const getResult = await Http.get(
                    `api/log/time_trackings?accountId=${accountId}&start=${dtStartString}&finish=${dtFinishString}&locationId=${locationId}&employeeId=${
                        employeeId > 0 ? employeeId : ''
                    }&pageSize=${pageSize}&pageIndex=${pageIndex}&groupId=${groupId > 0 ? groupId : ''}`,
                    true
                );
                console.log('log', getResult);
                getResult?.result?.map((item, index) => {
                    item.keyItem = index;
                    item.locationName = item.location?.title;
                    item.employeeName = item.employee?.title;
                    item.dateString = DateToString(new Date(item.date));
                    if (item.dateIn !== null) {
                        item.dateInString = DateToString(UtcToLocal(item.dateIn), true);
                    }
                    if (item.dateOut !== null) {
                        item.dateOutString = DateToString(UtcToLocal(item.dateOut), true);
                    }
                });
                setReport(getResult.result);
                setPaginator(getResult.paginator);
                setForcePage(pageIndex - 1);
            } catch (error) {
                console.log('error', error);
                dispatch(
                    addNotification({
                        dateNotify: Date.now(),
                        title: 'Ошибка!',
                        description: 'Перезагрузите страницу и попробуйте снова! ' + error.message,
                        type: 'danger',
                    })
                );
            }

            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [dateStart, dateFinish, locationId, employeeId, groupId, pageSize, pageIndex]);

    const exportData = async () => {
        const dtStartString = `${dateStart.getFullYear()}-${dateStart.getMonth() + 1}-${dateStart.getDate()}`;
        const dtFinishString = `${dateFinish.getFullYear()}-${
            dateFinish.getMonth() + 1
        }-${dateFinish.getDate()}T23:59:59`;
        const response = await Http.getMedia(
            `api/log/time_trackings?accountId=${accountId}&start=${dtStartString}&finish=${dtFinishString}` +
                `&locationId=${locationId}&employeeId=${employeeId > 0 ? employeeId : ''}&groupId=${
                    groupId > 0 ? groupId : ''
                }&export=presence`
        );
        var blob = new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const filename = `Отчет Присутствие.xlsx`;
        saveAs(blob, filename);
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageSize(size);
    };

    return (
        <>
            <div className='card border'>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'></h3>
                    <div className='card-toolbar'>
                        <div className='input-group me-2 mb-3' style={{ width: '400px' }}>
                            <span className='input-group-text'>Объект</span>
                            <select
                                className='form-select'
                                value={locationId}
                                onChange={(e) => {
                                    setLocationId(e.target.value);
                                    setPageIndex(1);
                                }}
                            >
                                <option value={0}>Все</option>
                                {locations?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name} ({item.account.title})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='input-group me-2 mb-3' style={{ width: '400px' }}>
                            <span className='input-group-text'>Группа</span>
                            <select
                                className='form-select'
                                value={groupId}
                                onChange={(e) => {
                                    setGroupId(e.target.value);
                                    setPageIndex(1);
                                }}
                            >
                                <option value={0}>Все</option>
                                {groups?.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <EmployeesDropDownInput
                            accountId={accountId}
                            selectEmployee={(e) => {
                                setEmployeeId(e);
                                setPageIndex(1);
                            }}
                            groupId={groupId}
                        />
                        <div className='input-group me-2 mb-3' style={{ width: '400px' }}>
                            <span className='input-group-text'>С</span>
                            <input
                                type='date'
                                className='form-control'
                                value={ToUtcString(dateStart).split('T')[0]}
                                onChange={(e) => setDateStart(new Date(e.target.value))}
                            />
                            <span className='input-group-text'>ПО</span>
                            <input
                                type='date'
                                className='form-control'
                                value={ToUtcString(dateFinish).split('T')[0]}
                                onChange={(e) => setDateFinish(new Date(e.target.value))}
                            />
                        </div>
                        <button className='btn av-btn-simple ms-3 mb-3' onClick={exportData}>
                            <i className='far fa-file-excel me-3'></i> Экспорт в Excel
                        </button>
                    </div>
                </div>
                <div className='table-responsive'>
                    <SkeletonTableFull
                        cols={{
                            rKeyItem: 'keyItem',
                            locationName: 'Объект',
                            dateString: 'Дата',
                            employeeName: 'Сотрудник',
                            occupation: 'Должность',
                            presenceDuration: 'Время',
                        }}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='mb-0'
                        entity={report}
                        tdClasses={{
                            locationName: 'ps-6',
                            occupation: 'report-occupation',
                        }}
                    />
                </div>
                <div className='card-body py-3 px-5'>
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={report.length}
                        countTotal={paginator.totalCount}
                        forcePage={forcePage}
                    />
                </div>
            </div>
        </>
    );
};

export default PresenceList;
