import React, { useEffect, useState } from 'react';
import { Http } from '../../http';
import {
    createAsync,
    updateAsync,
} from '../../app/storeReducers/AccountSchedulesSlice';
import { useDispatch } from 'react-redux';

const ScheduleCreateModal = (props) => {
    const { accountId, entityId, isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [day1From, setDay1From] = useState('00:00');
    const [day1To, setDay1To] = useState('23:59');
    const [day2From, setDay2From] = useState('00:00');
    const [day2To, setDay2To] = useState('23:59');
    const [day3From, setDay3From] = useState('00:00');
    const [day3To, setDay3To] = useState('23:59');
    const [day4From, setDay4From] = useState('00:00');
    const [day4To, setDay4To] = useState('23:59');
    const [day5From, setDay5From] = useState('00:00');
    const [day5To, setDay5To] = useState('23:59');
    const [day6From, setDay6From] = useState('00:00');
    const [day6To, setDay6To] = useState('23:59');
    const [day7From, setDay7From] = useState('00:00');
    const [day7To, setDay7To] = useState('23:59');

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (entityId !== 0 && entityId !== -1) {
                const getResult = await Http.get(
                    'api/schemes/schedules/' + entityId,
                    true
                );
                setName(getResult.result.name);
                setDay1From(getResult.result.day1From);
                setDay1To(getResult.result.day1To);
                setDay2From(getResult.result.day2From);
                setDay2To(getResult.result.day2To);
                setDay3From(getResult.result.day3From);
                setDay3To(getResult.result.day3To);
                setDay4From(getResult.result.day4From);
                setDay4To(getResult.result.day4To);
                setDay5From(getResult.result.day5From);
                setDay5To(getResult.result.day5To);
                setDay6From(getResult.result.day6From);
                setDay6To(getResult.result.day6To);
                setDay7From(getResult.result.day7From);
                setDay7To(getResult.result.day7To);
                console.log('scheduleResult', getResult.result);
            } else {
                setName('');
                setDay1From('00:00');
                setDay1To('23:59');
                setDay2From('00:00');
                setDay2To('23:59');
                setDay3From('00:00');
                setDay3To('23:59');
                setDay4From('00:00');
                setDay4To('23:59');
                setDay5From('00:00');
                setDay5To('23:59');
                setDay6From('00:00');
                setDay6To('23:59');
                setDay7From('00:00');
                setDay7To('23:59');
            }
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    const saveEntity = async () => {
        const entity = {
            id: entityId,
            account: { id: accountId },
            name,
            day1From,
            day1To,
            day2From,
            day2To,
            day3From,
            day3To,
            day4From,
            day4To,
            day5From,
            day5To,
            day6From,
            day6To,
            day7From,
            day7To,
        };
        if (entityId === 0) {
            dispatch(createAsync(entity));
        } else {
            dispatch(updateAsync(entity));
        }
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить новое расписание
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="name" className="form-label">
                                    Название
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="row mb-3">
                                <div className="col-2 d-flex align-items-center">
                                    ПН
                                </div>
                                <div className="col-10">
                                    <div className="input-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="С"
                                            value={day1From}
                                            onChange={(e) =>
                                                setDay1From(e.target.value)
                                            }
                                        />
                                        <span className="input-group-text">
                                            -
                                        </span>
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="ПО"
                                            value={day1To}
                                            onChange={(e) =>
                                                setDay1To(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2 d-flex align-items-center">
                                    ВТ
                                </div>
                                <div className="col-10">
                                    <div className="input-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="С"
                                            value={day2From}
                                            onChange={(e) =>
                                                setDay2From(e.target.value)
                                            }
                                        />
                                        <span className="input-group-text">
                                            -
                                        </span>
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="ПО"
                                            value={day2To}
                                            onChange={(e) =>
                                                setDay2To(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2 d-flex align-items-center">
                                    СР
                                </div>
                                <div className="col-10">
                                    <div className="input-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="С"
                                            value={day3From}
                                            onChange={(e) =>
                                                setDay3From(e.target.value)
                                            }
                                        />
                                        <span className="input-group-text">
                                            -
                                        </span>
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="ПО"
                                            value={day3To}
                                            onChange={(e) =>
                                                setDay3To(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2 d-flex align-items-center">
                                    ЧТ
                                </div>
                                <div className="col-10">
                                    <div className="input-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="С"
                                            value={day4From}
                                            onChange={(e) =>
                                                setDay4From(e.target.value)
                                            }
                                        />
                                        <span className="input-group-text">
                                            -
                                        </span>
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="ПО"
                                            value={day4To}
                                            onChange={(e) =>
                                                setDay4To(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2 d-flex align-items-center">
                                    ПТ
                                </div>
                                <div className="col-10">
                                    <div className="input-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="С"
                                            value={day5From}
                                            onChange={(e) =>
                                                setDay5From(e.target.value)
                                            }
                                        />
                                        <span className="input-group-text">
                                            -
                                        </span>
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="ПО"
                                            value={day5To}
                                            onChange={(e) =>
                                                setDay5To(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2 d-flex align-items-center">
                                    СБ
                                </div>
                                <div className="col-10">
                                    <div className="input-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="С"
                                            value={day6From}
                                            onChange={(e) =>
                                                setDay6From(e.target.value)
                                            }
                                        />
                                        <span className="input-group-text">
                                            -
                                        </span>
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="ПО"
                                            value={day6To}
                                            onChange={(e) =>
                                                setDay6To(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-2 d-flex align-items-center">
                                    ВС
                                </div>
                                <div className="col-10">
                                    <div className="input-group">
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="С"
                                            value={day7From}
                                            onChange={(e) =>
                                                setDay7From(e.target.value)
                                            }
                                        />
                                        <span className="input-group-text">
                                            -
                                        </span>
                                        <input
                                            type="time"
                                            className="form-control"
                                            placeholder="ПО"
                                            value={day7To}
                                            onChange={(e) =>
                                                setDay7To(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default ScheduleCreateModal;
