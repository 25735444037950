import PermissionPage from '../../components/PermissionPage';
import VehiclesList from './VehiclesList';

const VehiclesPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={['vehicles_list']}>
            <div className='row'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Транспорт</h1>
                    <VehiclesList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default VehiclesPage;
