import { baseApi } from '../baseApi';

export const adminApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getSummary: builder.query({
            query: () => 'admin/summary',
        }),
    }),
});

export const { useGetSummaryQuery } = adminApiSlice;
