import { baseApi } from '../baseApi';
import { addNotification } from './NotifySlice';

export const authApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        loginCode: builder.mutation({
            query: (credentials) => ({
                url: 'auth/login_code',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        getSecurityContext: builder.mutation({
            query: () => ({
                url: 'auth/security',
                method: 'POST',
            }),
        }),
        deleteSecurityContext: builder.mutation({
            query: () => ({
                url: 'auth/security',
                method: 'DELETE',
            }),
        }),
        getLoginCode: builder.mutation({
            query: (data) => ({
                url: 'auth/verification_code',
                method: 'POST',
                body: { ...data },
            }),
        }),
        changePassword: builder.mutation({
            query: (data) => ({
                url: 'auth/change_password',
                method: 'POST',
                body: { ...data },
            }),
        }),
        tokenReceived: builder.mutation({
            query: (body) => ({
                url: 'auth/refresh_token',
                method: 'POST',
                body,
            }),
            async onQueryStarted(id, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    // `onSuccess` side-effect
                    localStorage.setItem('access_token', data.result.access_token.value);
                    localStorage.setItem('access_token_time', data.result.access_token.expireAt);
                    localStorage.setItem('refresh_token', data.result.refresh_token.value);
                    localStorage.setItem('refresh_token_time', data.result.refresh_token.expireAt);
                } catch (err) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: 'Ошибка авторизации!',
                            description:
                                'Не удалось повторно авторизоваться! Перезагрузите страницу и попробуйте снова!',
                            type: 'danger',
                        })
                    );
                }
            },
        }),
        getAuthNavigation: builder.query({
            query: (accountId) => 'auth/navigation?accountId=' + accountId,
        }),
    }),
});

export const {
    useLoginMutation,
    useLoginCodeMutation,
    useGetSecurityContextMutation,
    useGetLoginCodeMutation,
    useChangePasswordMutation,
    useTokenReceivedMutation,
    useDeleteSecurityContextMutation,
    useGetAuthNavigationQuery,
    useLazyGetAuthNavigationQuery,
} = authApiSlice;
