import { useEffect, useState } from "react";
import FindComponent from "../../../../components/FindComponent";
import Paginator from "../../../../components/Paginator";
import PermissionPage from "../../../../components/PermissionPage";
import SkeletonTableFull from "../../../../components/skeleton/SkeletonTableFull";
import { ToUtcString } from "../../../../helpers/DateHelpers";
import { HumanPhone } from "../../../../helpers/PhoneHelper";
import DeleteCardModal from "./DeleteCardModal";
import GuestModal from "./GuestModal";
import ReturnPassModal from "./ReturnPassModal";
import { useLazyGetGuestsQuery } from "../../../../app/storeReducers/guestsApiSlice";

const OperatorGusetsEntrancePage = (props) => {
    const accountId = props.match.params.accountId;

    const [isLoading, setIsLoading] = useState(true);
    const [guests, setGuests] = useState([]);
    const [dateReport, setDateReport] = useState(new Date());
    const [guestName, setGuestName] = useState("");
    // Всё, что относится к пагинатору
    const [paginator, setPaginator] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(1);

    const [isOpenDeleteCardModal, setIsOpenDeleteCardModal] = useState(false);
    const [isOpenGuestModal, setIsOpenGuestModal] = useState(false);
    const [guestSelected, setGuestSelected] = useState({});
    const [isOpenReturnPass, setIsOpenReturnPass] = useState(false);
    const [returnedPass, setReturnedPass] = useState({});

    const [autoreloadChb, setAutoreloadChb] = useState(false);

    const [getGuestsAsync] = useLazyGetGuestsQuery();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            await loadData();
            setIsLoading(false);
            setForcePage(pageIndex - 1);
        }

        loadResourcesAndDataAsync();
        return () => clearInterval(window.interval23);
    }, [dateReport, guestName, pageIndex, pageSize]);

    const loadData = async () => {
        const dtNow = dateReport;
        const dtString = `${dtNow.getFullYear()}-${
            dtNow.getMonth() + 1
        }-${dtNow.getDate()}`;
        const getResult = await getGuestsAsync({
            accountId,
            dateStart: dtString,
            dateFinish: dtString,
            search: guestName,
            pageIndex,
            pageSize,
        }).unwrap();
        convertToTable(getResult?.result);
        setPaginator(getResult.paginator);
    };

    function convertToTable(guestArray) {
        const res = guestArray?.map((item) => ({
            ...item,
            actionBtn: getActionButton(item),
            userTitle: item?.user?.title
                ? HumanPhone(item.user.title, true)
                : "",
            accountTitle: item.account.title,
        }));

        setGuests(res);
    }

    const getActionButton = (item) => {
        if (item.cardNumber != null && item.cardNumber !== "") {
            return (
                <span>
                    Пропуск выдан.{" "}
                    <button
                        className="btn av-btn-primary pt-2"
                        onClick={() => returnPass(item)}
                    >
                        Вернуть
                    </button>
                </span>
            );
        }
        return (
            <button
                className="btn av-btn-simple btn-block"
                onClick={() => openGuestModal(item)}
            >
                Выдать пропуск
            </button>
        );
    };

    const changePage = async (pageIndex) => {
        setPageIndex(pageIndex);
    };

    const changePageSize = async (size) => {
        setPageIndex(1);
        setPageSize(size);
    };

    const openGuestModal = (guest) => {
        setGuestSelected(guest);
        setIsOpenGuestModal(true);
    };

    const guestModalOk = (guestId, cardNumber) => {
        let newArr = [...guests];
        newArr[newArr.findIndex((x) => x.id == guestId)].cardNumber =
            cardNumber;
        convertToTable(newArr);

        setIsOpenGuestModal(false);
    };

    const returnPass = (guest) => {
        setReturnedPass(guest);
        setIsOpenReturnPass(true);
    };

    const okReturnPass = (guestId) => {
        let newArr = [...guests];
        newArr[newArr.findIndex((x) => x.id == guestId)].cardNumber = null;
        convertToTable(newArr);

        setIsOpenReturnPass(false);
    };

    const autoreloadChecked = (e) => {
        setAutoreloadChb(e);
        if (e == true) {
            window.interval23 = setInterval(() => {
                loadData();
            }, 1000 * 60);
        } else {
            clearInterval(window.interval23);
            return;
        }
    };

    return (
        <PermissionPage accountId={accountId} roles={["guest_panel"]}>
            <GuestModal
                isOpen={isOpenGuestModal}
                handleClose={() => setIsOpenGuestModal(false)}
                handleOk={guestModalOk}
                guest={guestSelected}
            />
            <DeleteCardModal
                isOpen={isOpenDeleteCardModal}
                handleClose={() => setIsOpenDeleteCardModal(false)}
                accountId={accountId}
            />
            <ReturnPassModal
                isOpen={isOpenReturnPass}
                handleOk={okReturnPass}
                handleClose={() => setIsOpenReturnPass(false)}
                guest={returnedPass}
            />
            <div className="row">
                <div className="col-md-12">
                    <h1 className="mb-5">Заявки на посетителей</h1>
                    <div className="row">
                        <div className="col mb-3 d-flex justify-content-end">
                            <input
                                style={{ width: "200px" }}
                                type="date"
                                className="form-control me-2"
                                id="dateReport"
                                value={ToUtcString(dateReport).split("T")[0]}
                                onChange={(e) =>
                                    setDateReport(new Date(e.target.value))
                                }
                            />
                            <FindComponent
                                callback={(name) => {
                                    setPageIndex(1);
                                    setGuestName(name);
                                }}
                            />
                            <div className="form-group form-check mx-2 align-self-center">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="autoreloadChb"
                                    checked={autoreloadChb}
                                    onChange={(e) =>
                                        autoreloadChecked(e.target.checked)
                                    }
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="autoreloadChb"
                                    id="autoreloadLabel"
                                >
                                    Автообновление
                                </label>
                            </div>
                            <button
                                className="btn av-btn-primary ms-2"
                                onClick={() => setIsOpenDeleteCardModal(true)}
                            >
                                Вернуть пропуск
                            </button>
                        </div>
                    </div>
                    <div className="card border">
                        <div className="table-responsive">
                            <SkeletonTableFull
                                cols={{
                                    rKeyItem: "id",
                                    name: "ФИО",
                                    userTitle: "Телефон",
                                    accountTitle: "Аккаунт",
                                    actionBtn: "",
                                }}
                                rowsCount={5}
                                isLoading={isLoading}
                                classTable="mb-0"
                                entity={guests}
                                tdClasses={{ name: "ps-6" }}
                            />
                        </div>
                        <div className="card-body py-3 px-5">
                            <Paginator
                                onPageChange={changePage}
                                onPageSizeChange={changePageSize}
                                pageCount={paginator.totalPages}
                                pageSizeArray={[50, 100, 500]}
                                pageSize={pageSize}
                                countCurrent={guests?.length}
                                countTotal={paginator.totalCount}
                                forcePage={forcePage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default OperatorGusetsEntrancePage;
