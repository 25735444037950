import { useEffect, useState } from 'react';
import { Http } from '../../../../http';
import SkeletonSelect from '../../../../components/skeleton/SkeletonSelect';

const GroupAddEmployeeModal = (props) => {
    const { accountId, groupId, isOpen, okHandle, closeHandle } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [employeeSelected, setEmployeeSelected] = useState({});

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            setEmployeeSelected({});
            const getResult = await Http.get(
                `api/employees?sort=last_name&accountId=${accountId}&pageSize=10000`
            );
            getResult.result.forEach((item) => {
                item.description = item.name;
                item.name = item.id;
            });
            setEmployees(getResult.result);
            if (getResult.result.length > 0) {
                setEmployeeSelected(getResult.result[0].id);
            }
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [groupId, accountId]);

    const saveGroupEmployee = async () => {
        await Http.put(`api/groups/${groupId}/members/${employeeSelected}`);

        okHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Добавить сотрудника в группу
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-4'>
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label='Сотрудник'
                                    value={employeeSelected}
                                    onChange={(e) =>
                                        setEmployeeSelected(e.target.value)
                                    }
                                    itemsArray={employees}
                                />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveGroupEmployee}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default GroupAddEmployeeModal;
