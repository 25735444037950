import { useEffect, useState } from "react";
import ParkingAddDeviceModal from "./ParkingAddDeviceModal";
import ParkingDeviceDeleteModal from "./ParkingDeviceDeleteModal";
import { useSelector } from "react-redux";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import { useGetParkingAreaDevicesQuery } from "../../../../app/storeReducers/parkingAreasApiSlice";

const ParkingDevicesPanel = (props) => {
    const { accountId, entityId } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const { data: devices } = useGetParkingAreaDevicesQuery(entityId);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("devices_edit"));
        setCanList(context.isRoot || actions?.includes("devices_list"));
    }, [context, accountId]);

    const okAddDevice = () => {
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteDevice = () => {
        setDeleteModalIsOpen(false);
    };

    console.log(devices);

    return (
        <>
            <ParkingAddDeviceModal
                accountId={accountId}
                parkingId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddDevice}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <ParkingDeviceDeleteModal
                parkingId={entityId}
                device={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteDevice}
            />
            <div className="card border">
                <div className="card-body pb-2">
                    <div className="row mb-3">
                        <div className="col-md-6 align-self-center">
                            <h3>
                                Устройства{" "}
                                <span className="badge rounded-pill bg-light text-dark">
                                    {devices?.result?.length ?? 0}
                                </span>
                            </h3>
                        </div>
                        <div className="col-md-6 text-end">
                            {canEdit ? (
                                <button
                                    className="btn av-btn-primary"
                                    onClick={() => setAddModalIsOpen(true)}
                                >
                                    <i className="fas fa-plus me-2" />
                                    Добавить устройство
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
                {canList ? (
                    <table className="table mb-0 border-top">
                        <tbody>
                            {devices?.result?.map((item) => (
                                <tr key={item.id}>
                                    <td className="ps-6">
                                        <div>
                                            <div className="fw-boldest">
                                                {item.name}
                                            </div>
                                            <div className="av-text-gray-500">
                                                {item.uid}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-end">
                                        {canEdit ? (
                                            <>
                                                <button
                                                    className="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1 px-2"
                                                    onClick={() =>
                                                        openDeleteModal(item)
                                                    }
                                                >
                                                    <i className="far fa-trash-alt text-muted" />
                                                </button>
                                            </>
                                        ) : null}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="card-body">
                        <i>У вас нет прав на просмотр содержимого</i>
                    </div>
                )}
            </div>
        </>
    );
};

export default ParkingDevicesPanel;
