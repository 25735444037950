import { useGetVehiclesQuery } from '../../../app/storeReducers/AccountVehiclesAPI';

const EmployeeVehiclesPanel = (props) => {
    const { accountId, employeeId } = props;

    const { data } = useGetVehiclesQuery({
        accountId,
        employeeId,
        pageIndex: 1,
        pageSize: 10,
    });

    return (
        <>
            <div className='card border mb-3'>
                <div className='card-body p-6'>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='av-card-title'>
                                Автомобили{' '}
                                <span className='badge rounded-pill bg-light text-dark ms-2 align-text-top'>
                                    {data?.result?.length}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col'>
                            {data?.result.map((item) => (
                                <div
                                    className='border av-border-gray-300 rounded d-inline-block px-4 py-2 me-2 mb-2 fw-bolder'
                                    key={item.id}
                                >
                                    {item.fullNumber} ({item.model})
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeeVehiclesPanel;
