const FinishModal = (props) => {
    const { isOpen, okHandle, closeHandle } = props;

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content tutorial-start-modal'>
                        <div className='modal-body pt-0'>
                            <img
                                src='/media/TutorialFinishImg.png'
                                className='img-fluid mb-3'
                            />
                            <h5 className='font-size-16 fw-700 text-center'>
                                Обзор завершен
                            </h5>
                            <p className='text-center mb-10'>
                                Попробуйте демо-кабинет СКУД Avanguard или
                                свяжитесь с нашим менеджером. Он расскажет вам о
                                всех возможностях системы и выгодных условиях
                                подключения.
                            </p>
                            <div className='row'>
                                <div className='col-md'>
                                    <div className='d-grid'>
                                        <button
                                            type='button'
                                            className='btn av-btn-simple'
                                            onClick={closeHandle}
                                        >
                                            Закрыть
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md'>
                                    <div className='d-grid'>
                                        <button
                                            type='button'
                                            className='btn av-btn-primary'
                                            onClick={okHandle}
                                        >
                                            Оставить заявку
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default FinishModal;
