import { useState } from "react";
import { useCreateParkingPlaceMutation } from "../../../../app/storeReducers/parkingPlacesApiSlice";

const ParkingPlaceCreateModal = (props) => {
    const { parkingId, isOpen, closeHandle } = props;

    const [number, setNumber] = useState("");

    const [createAsync] = useCreateParkingPlaceMutation();

    const saveEntity = async () => {
        const entity = {
            number,
            area: { id: parkingId },
        };
        await createAsync(entity);
        setNumber("");
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить новое машиноместо
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="number" className="form-label">
                                    Номер
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="number"
                                    value={number}
                                    onChange={(e) => setNumber(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default ParkingPlaceCreateModal;
