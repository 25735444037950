import { useEffect, useState } from 'react';
import SkeletonTable from '../../../../../components/skeleton/SkeletonTable';
import { Http } from '../../../../../http';
import { KTSVG } from '../../../../../_metronic/helpers';
import ImageCreateModal from './ImageCreateModal';
import ImageDeleteModal from './ImageDeleteModal';
import ImageModal from './ImageModal';

const ParkingPlacesImagesCard = (props) => {
    const { areaId } = props;

    const [images, setImages] = useState([]);
    const [isLoading, setIsloading] = useState(true);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deletedEntity, setDeletedEntity] = useState(0);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

    const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
    const [imageSelected, setImageSelected] = useState(null);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsloading(true);
            const getResult = await Http.get(
                'api/parking/images?areaId=' + areaId,
                true
            );
            setImages(getResult.result);
            setIsloading(false);
        }

        loadResourcesAndDataAsync();
    }, [areaId]);

    const selectDeleteEntity = (entityId) => {
        setDeletedEntity(entityId);
        setDeleteModalIsOpen(true);
    };

    const deleteImage = async () => {
        await Http.delete('api/parking/images/' + deletedEntity.id);
        setImages(images.filter((x) => x !== deletedEntity));
        setDeleteModalIsOpen(false);
    };

    const createImage = async (item) => {
        const createImg = await Http.postMedia('api/parking/images', item);
        setImages([...images, createImg.result]);
        setCreateModalIsOpen(false);
    };

    const showImage = (imageId) => {
        setImageSelected(imageId);
        setImageModalIsOpen(true);
    };

    return (
        <>
            <ImageDeleteModal
                entity={deletedEntity}
                isOpen={deleteModalIsOpen}
                okHandle={deleteImage}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <ImageCreateModal
                parkingId={areaId}
                isOpen={createModalIsOpen}
                okHandle={createImage}
                closeHandle={() => setCreateModalIsOpen(false)}
            />
            <ImageModal
                imageId={imageSelected}
                isOpen={imageModalIsOpen}
                closeHandle={() => setImageModalIsOpen(false)}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-3'>
                        <div className='col-md-8 align-self-center'>
                            <h3>Схемы парковки</h3>
                        </div>
                        <div className='col-md-4 text-end'>
                            <button
                                className='btn av-btn-primary'
                                onClick={() => setCreateModalIsOpen(true)}
                            >
                                <i className='fas fa-plus me-2' />
                                <i className='fas fa-camera' />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Название изображения', '']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0 border-top table-striped'>
                            <tbody>
                                {images?.map((item) => (
                                    <tr
                                        key={item.id}
                                        className='fw-bold av-text-gray-500 border-bottom'
                                    >
                                        <td className='ps-6'>
                                            <button
                                                className='btn btn-link'
                                                onClick={() =>
                                                    showImage(item.id)
                                                }
                                            >
                                                {item.name}
                                            </button>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-end flex-shrink-0'>
                                                <button
                                                    className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                                                    onClick={(e) =>
                                                        selectDeleteEntity(item)
                                                    }
                                                >
                                                    <i className='fas fa-trash-alt text-muted' />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default ParkingPlacesImagesCard;
