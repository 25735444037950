import { useHistory, useParams } from 'react-router';

const EmployeeWidget = (props) => {
    const { employees_count_total, employees_count_banned, vehicles_count_total } = props;

    const history = useHistory();

    let params = useParams();
    const { accountId } = params;

    return (
        <div className='card border widget-card mb-6' id='employeeWidget'>
            <div
                className='card-body p-6 cursor-pointer'
                onClick={() => history.push('/account/' + accountId + '/employees')}
            >
                <div className='widget-title mb-3'>
                    <img className='img-fluid me-4' src='/media/icons/avanguard/widgetEmployeeIcon.png' />
                    Сотрудники
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>{employees_count_total}</div>
                    <div className='widget-count-title'>Всего</div>
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>{vehicles_count_total}</div>
                    <div className='widget-count-title'>На авто</div>
                </div>
                <div className='widget-count-block d-inline-block me-5'>
                    <div className='widget-count-number'>{employees_count_banned}</div>
                    <div className='widget-count-title'>В блоке</div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeWidget;
