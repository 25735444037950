import { Http } from '../../../../http';

const SchemeShareDeleteModal = (props) => {
    const { schemeId, share, isOpen, closeHandle, okHandle } = props;

    const deleteShare = async () => {
        const addResult = await Http.delete(
            `api/schemes/${schemeId}/shares/${share.id}`
        );

        okHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Удалить аккаунт из схемы
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Вы действительно хотите удалить аккаунт{' '}
                                <strong className='text-danger'>
                                    {share.title}
                                </strong>{' '}
                                из расшаренной схемы?
                            </p>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-danger'
                                onClick={deleteShare}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default SchemeShareDeleteModal;
