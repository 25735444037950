import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonInput from "../../../components/skeleton/SkeletonInput";
import SkeletonSelect from "../../../components/skeleton/SkeletonSelect";
import { useHistory } from "react-router";
import SchemeDeleteModal from "../SchemeDeleteModal";
import { selectContext } from "../../../app/storeReducers/AuthSlice";
import {
    useGetSchemeByIdQuery,
    useUpdateSchemeMutation,
} from "../../../app/storeReducers/schemesApiSlice";
import { useGetSchemeSchedulesQuery } from "../../../app/storeReducers/schemeScheduleApiSlice";

const SchemeEditPanel = (props) => {
    const { accountId, entityId } = props;

    const history = useHistory();

    const [name, setName] = useState("");
    const [type, setType] = useState("user");
    const [isForGuest, setIsForGuest] = useState(false);
    const [schedule, setSchedule] = useState("");
    const [schedulesList, setSchedulesList] = useState([]);
    const [schemeAccountId, setSchemeAccountId] = useState(0);

    const context = useSelector(selectContext);
    const [canEdit, setCanEdit] = useState(false);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { data: scheme, isLoading } = useGetSchemeByIdQuery(entityId);
    const { data: schemeSchedules } = useGetSchemeSchedulesQuery({ accountId });
    const [updateAsync] = useUpdateSchemeMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (scheme && schemeSchedules) {
                setName(scheme.result.name);
                setType(scheme.result.type);
                setIsForGuest(scheme.result.isForGuest);
                setSchedule(scheme.result.schedule?.id);
                setSchemeAccountId(scheme.result.account?.id);

                let schedules = [];
                schedules.push({
                    id: "",
                    name: "",
                    description: "--Без графика--",
                });
                if (
                    parseInt(accountId) !== parseInt(scheme.result.account?.id)
                ) {
                    schedules.push({
                        id: "0",
                        name: scheme.result.schedule?.id,
                        description: scheme.result.schedule?.title,
                    });
                } else {
                    schemeSchedules.result.map((item) => {
                        schedules.push({
                            id: item.id,
                            name: item.id,
                            description: item.name,
                        });
                    });
                }
                setSchedulesList(schedules);
            }
        }

        loadResourcesAndDataAsync();
    }, [scheme, schemeSchedules]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("employees_edit"));
    }, [context, accountId]);

    const saveEntity = async () => {
        let scheduleNew = null;
        if (schedule !== "" && schedule !== undefined) {
            scheduleNew = { id: schedule };
        }
        const entity = {
            name,
            account: { id: accountId },
            type,
            id: entityId,
            isForGuest,
            schedule: scheduleNew,
        };
        await updateAsync(entity);
    };

    const deleteEntity = () => {
        history.push(`/account/${accountId}/admin/schemes`);
    };

    return (
        <>
            <SchemeDeleteModal
                entity={{ id: entityId, name }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                deleteHandle={deleteEntity}
            />
            <div className="card border">
                <div className="card-body pb-3">
                    <div className="row mb-3">
                        <div className="col-md-6 align-self-center">
                            <h3>Схема</h3>
                        </div>
                    </div>
                    <div className="mb-4">
                        {type !== "full" ? (
                            <SkeletonInput
                                isLoading={isLoading}
                                id="name"
                                label="Название"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        ) : (
                            <>
                                <label htmlFor="name" className="form-label">
                                    Название
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    disabled
                                />
                            </>
                        )}
                    </div>
                    <div className="mb-4">
                        <SkeletonSelect
                            isLoading={isLoading}
                            label="График доступа"
                            value={schedule}
                            onChange={(e) => setSchedule(e.target.value)}
                            itemsArray={schedulesList}
                            disabled={
                                type === "full" ||
                                !canEdit ||
                                parseInt(accountId) !==
                                    parseInt(schemeAccountId)
                            }
                        />
                    </div>
                    <div className="form-group form-check mb-4">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="isForGuest"
                            checked={isForGuest}
                            onChange={(e) => setIsForGuest(e.target.checked)}
                            disabled={type === "full" || !canEdit}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="isForGuest"
                        >
                            Разрешена для посетителей
                        </label>
                    </div>
                    {type === "full" ? (
                        <div className="row">
                            <div className="col">
                                <i>
                                    Системная схема. Редактирование запрещено.
                                </i>
                            </div>
                        </div>
                    ) : null}
                    <div className="row mb-3">
                        <div className="col-md-6">
                            <button
                                className="btn av-btn-danger fw-boldest"
                                onClick={() => setDeleteModalIsOpen(true)}
                                disabled={
                                    type === "full" ||
                                    !canEdit ||
                                    parseInt(accountId) !==
                                        parseInt(schemeAccountId)
                                }
                            >
                                <i className="far fa-trash-alt me-2" /> Удалить
                            </button>
                        </div>
                        <div className="col-md-6 text-end">
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                                disabled={
                                    type === "full" ||
                                    !canEdit ||
                                    parseInt(accountId) !==
                                        parseInt(schemeAccountId)
                                }
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SchemeEditPanel;
