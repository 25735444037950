import { Http } from '../../../../http';

const EmployeeRoleDeleteModal = (props) => {
    const { employeeId, role, isOpen, closeHandle, okHandle } = props;

    const deleteRole = async () => {
        const deleteResult = await Http.delete(
            `api/employees/${employeeId}/roles/${role.id}`
        );

        okHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Удалить роль</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <p>
                                Вы действительно хотите удалить роль{' '}
                                <strong className='text-danger'>
                                    {role.name}
                                </strong>
                            </p>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-danger'
                                onClick={deleteRole}
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default EmployeeRoleDeleteModal;
