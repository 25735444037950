import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectIsLoading,
    selectList,
    getListAsync,
    selectPage,
    setPageSize,
    selectPaginator,
} from '../../app/storeReducers/AccountSchedulesSlice';
import SkeletonTable from '../../components/skeleton/SkeletonTable';
import ScheduleCreateModal from './ScheduleCreateModal';
import Paginator from '../../components/Paginator';
import { useHistory } from 'react-router-dom';
import SecutiryContext from '../../features/securityContext';

const SchedulesList = (props) => {
    const { accountId } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const entityList = useSelector(selectList);
    const isLoading = useSelector(selectIsLoading);
    const paginator = useSelector(selectPaginator);
    const pageSize = useSelector((state) => state.accountSchedules.pageSize);

    const { context } = useContext(SecutiryContext);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(getListAsync(accountId));
        }

        loadResourcesAndDataAsync();
    }, [accountId, dispatch]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('schemes_edit'));
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
    const [editedEntity, setEditedEntity] = useState(0);
    const [canEdit, setCanEdit] = useState(false);

    const openCreateModal = (entityId) => {
        setEditedEntity(entityId);
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
        setEditedEntity(-1);
    };

    const changePage = async (pageIndex) => {
        dispatch(selectPage(pageIndex));
        dispatch(getListAsync(accountId));
    };

    const changePageSize = async (size) => {
        dispatch(setPageSize(size));
        dispatch(getListAsync(accountId));
    };

    const openEntityCard = (entityId, e) => {
        if (e !== undefined && e.ctrlKey)
            window.open(
                `/account/${accountId}/admin/schemeSchedules/${entityId}`,
                '_blank'
            );
        else history.push(`/account/${accountId}/admin/schemeSchedules/${entityId}`);
    };

    const colNames = ['Название', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];

    return (
        <>
            <ScheduleCreateModal
                accountId={accountId}
                entityId={editedEntity}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className='row'>
                <div className='col-md-4 mb-3'>
                    <button
                        className='btn av-btn-primary fw-boldest'
                        onClick={() => openCreateModal(0)}
                        disabled={!canEdit}
                    >
                        <i className='fas fa-plus me-3' />
                        Новое расписание
                    </button>
                </div>
            </div>
            <div className='card border'>
                <div className='table-responsive'>
                    <SkeletonTable
                        cols={['Название', '']}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable='table-striped'
                    >
                        <table className='table mb-0'>
                            <thead>
                                <tr className='fw-bold av-text-gray-500 border-bottom'>
                                    {colNames.map((item) => (
                                        <th
                                            className='border-bottom'
                                            key={item}
                                        >
                                            {item}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {entityList.map((item) => (
                                    <tr
                                        key={item.id}
                                        onClick={(e) =>
                                            openEntityCard(item.id, e)
                                        }
                                        className='cursor-pointer'
                                    >
                                        <td>{item.name}</td>
                                        <td>
                                            {item.day1From} -{item.day1To}
                                        </td>
                                        <td>
                                            {item.day2From} -{item.day2To}
                                        </td>
                                        <td>
                                            {item.day3From} -{item.day3To}
                                        </td>
                                        <td>
                                            {item.day4From} -{item.day4To}
                                        </td>
                                        <td>
                                            {item.day5From} -{item.day5To}
                                        </td>
                                        <td>
                                            {item.day6From} -{item.day6To}
                                        </td>
                                        <td>
                                            {item.day7From} -{item.day7To}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
                <div className='card-body py-3'>
                    <Paginator
                        onPageChange={changePage}
                        onPageSizeChange={changePageSize}
                        pageCount={paginator.totalPages}
                        pageSizeArray={[50, 100, 500]}
                        pageSize={pageSize}
                        countCurrent={entityList.length}
                        countTotal={paginator.totalCount}
                    />
                </div>
            </div>
        </>
    );
};

export default SchedulesList;
