import { useEffect, useState } from 'react';
import SkeletonInput from '../../../components/skeleton/SkeletonInput';
import GroupDeleteModal from '../GroupDeleteModal';
import { selectContext } from '../../../app/storeReducers/AuthSlice';
import { useGetGroupByIdQuery, useUpdateGroupMutation } from '../../../app/storeReducers/groupsApiSlice';
import { useSelector } from 'react-redux';

const GroupEditPanel = (props) => {
    const { accountId, entityId } = props;

    const [name, setName] = useState('');

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { data: group, isLoading } = useGetGroupByIdQuery(entityId, { skip: !entityId });
    const [updateGroupAsync] = useUpdateGroupMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (group) {
                setName(group.result.name);
            } else {
                setName('');
            }
        }

        loadResourcesAndDataAsync();
    }, [group]);

    useEffect(() => {
        const actions = context?.permissions?.find((x) => x.id === parseInt(accountId))?.actions;
        setCanEdit(context.isRoot || actions?.includes('groups_edit'));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            account: { id: accountId },
            id: entityId,
        };
        updateGroupAsync(entity);
    };

    return (
        <>
            <GroupDeleteModal
                group={{ id: entityId, name: name, account: { id: accountId } }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row'>
                        <div className='col mb-2'>
                            <h3>Группа</h3>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='name'
                            label='Название'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            {canEdit ? (
                                <button
                                    className='btn av-btn-danger fw-boldest'
                                    onClick={() => setDeleteModalIsOpen(true)}
                                >
                                    <i className='far fa-trash-alt me-2' /> Удалить
                                </button>
                            ) : null}
                        </div>
                        <div className='col text-end'>
                            <button
                                type='button'
                                className='btn av-btn-simple fw-boldest'
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GroupEditPanel;
