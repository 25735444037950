import { useEffect, useState } from 'react';
import { useGetHostsQuery } from '../../../app/storeReducers/hostsApiSlice';
import { useCreateAccountMutation } from '../../../app/storeReducers/acountsApiSlice';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../app/storeReducers/NotifySlice';

const AccountCreateModal = (props) => {
    const { isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const { data: hosts } = useGetHostsQuery();
    const [createAsync] = useCreateAccountMutation();

    const [name, setName] = useState('');
    const [hostSelected, setHostSelected] = useState(-1);
    const [typeSelected, setTypeSelected] = useState(0);

    useEffect(() => {
        if (hosts) {
            setHostSelected(hosts?.result[0].id);
        }
    }, [hosts]);

    const saveEntity = async () => {
        const entity = {
            name,
            host: { id: hostSelected },
            type: typeSelected,
        };
        await createAsync(entity).unwrap();
        dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: 'Аккаунт создан!',
                description: 'Данные аккаунта успешно добавлены!',
                type: 'success',
            })
        );
        setName('');
        closeHandle();
    };

    return (
        <>
            <div className={'modal fade' + (isOpen ? ' show' : '')} tabIndex='-1'>
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Добавить новый аккаунт</h5>
                            <button type='button' className='btn-close' onClick={closeHandle}></button>
                        </div>
                        <div className='modal-body'>
                            <div className='mb-3'>
                                <label htmlFor='name' className='form-label'>
                                    Название
                                </label>
                                <input
                                    type='text'
                                    className='form-control'
                                    id='name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='mb-3'>
                                <label className='form-label'>Хост</label>
                                <select
                                    className='form-select'
                                    value={hostSelected}
                                    onChange={(e) => setHostSelected(e.target.value)}
                                >
                                    {hosts?.result.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='mb-3'>
                                <label className='form-label'>Тип</label>
                                <select
                                    className='form-select'
                                    value={typeSelected}
                                    onChange={(e) => setTypeSelected(e.target.value)}
                                >
                                    <option key='basic' value='basic'>
                                        Простой
                                    </option>
                                    <option key='business' value='business'>
                                        Расширенный
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-secondary' onClick={closeHandle}>
                                Закрыть
                            </button>
                            <button type='button' className='btn btn-primary' onClick={saveEntity}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default AccountCreateModal;
