const Error403 = () => {
    return (
        <div className='row'>
            <div className='col-md-12'>
                <h1>Отказано в доступе</h1>
            </div>
        </div>
    );
};

export default Error403;
