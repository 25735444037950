import { CRUDReducer } from './CRUDReducer';

export const baseReducer = new CRUDReducer('adminHosts', 'hosts');

export const { getListAsync, createAsync, updateAsync, deleteAsync } =
    baseReducer;

export const adminHostsSlice = baseReducer.reducerSlice;

export const { setPageSize, selectPage } = adminHostsSlice.actions;

export const selectList = (state) => state.adminHosts.objectsArray;
export const selectIsLoading = (state) => state.adminHosts.isLoading;
export const selectPaginator = (state) => state.adminHosts.paginator;

export default adminHostsSlice.reducer;
