import React, { useState, useEffect, useRef } from 'react';
import { Http } from '../../http';
import { useHistory } from 'react-router';

const DeviceOpenLongTimeModal = (props) => {
    const { isOpen, closeHandle, okHandle, deviceId, direction } = props;

    const close = () => {
        closeHandle();
    };

    const saveChanges = async () => {
        const result = await Http.post(
            `api/devices/${deviceId}/longtime/${direction}`
        );
        okHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Открыть надолго</h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={close}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            Вы действительно хотите{' '}
                            {direction === 'on' ? 'включить' : 'выключить'}{' '}
                            режим "Открыть надолго"?
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={close}
                            >
                                Закрыть
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveChanges}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeviceOpenLongTimeModal;
