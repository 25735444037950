import { useEffect, useState } from "react";
import SkeletonSelect from "../../../../components/skeleton/SkeletonSelect";
import {
    useAddSchemeMemberMutation,
    useGetSchemesQuery,
} from "../../../../app/storeReducers/schemesApiSlice";

const EmployeeAddSchemeModal = (props) => {
    const { accountId, employeeId, isOpen, okHandle, closeHandle } = props;

    const [schemeSelected, setSchemeSelected] = useState(0);

    const { data: schemes, isLoading } = useGetSchemesQuery(
        { accountId, sort: "name" },
        { skip: !accountId }
    );
    const [addScheme] = useAddSchemeMemberMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (schemes?.result?.length > 0) {
                setSchemeSelected(schemes.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [schemes]);

    const saveMemberScheme = async () => {
        const member = { isGroup: false, info: { id: employeeId } };
        await addScheme({ schemeId: schemeSelected, body: member });

        okHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить схему сотруднику
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-4">
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label="Схема"
                                    value={schemeSelected}
                                    onChange={(e) =>
                                        setSchemeSelected(e.target.value)
                                    }
                                    itemsArray={
                                        schemes?.result
                                            .filter(
                                                (item) => item.type !== "guest"
                                            )
                                            .map((item) => ({
                                                id: item.id,
                                                name: item.id,
                                                description: item.name,
                                            })) || []
                                    }
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveMemberScheme}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default EmployeeAddSchemeModal;
