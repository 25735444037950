import { useEffect, useState } from "react";
import SkeletonTable from "../../components/skeleton/SkeletonTable";
import { useHistory } from "react-router-dom";
import SupportTaskCreateModal from "./SupportTaskCreateModal";
import FindComponent from "../../components/FindComponent";
import { DateToString, UtcToLocal } from "../../helpers/DateHelpers";
import { useGetTicketsQuery } from "../../app/storeReducers/ticketsApiSlice";
import { useSelector } from "react-redux";
import { selectContext } from "../../app/storeReducers/AuthSlice";

const SupportTasksList = (props) => {
    const { accountId } = props;
    const history = useHistory();

    const [searchName, setSearchName] = useState("");

    const context = useSelector(selectContext);
    const [accountActions, setAccountActions] = useState([]);
    const [isAdmin, setIsAdmin] = useState(true);

    const { data: entityList, isLoading } = useGetTicketsQuery({
        accountId,
        search: searchName,
    });

    useEffect(() => {
        if (accountId) {
            const actions = context?.permissions?.find(
                (x) => x.id === parseInt(accountId)
            )?.actions;
            setAccountActions(actions);
            setIsAdmin(false);
        }
    }, [context, accountId]);

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

    const openCreateModal = () => {
        setCreateModalIsOpen(true);
    };

    const closeCreateModal = () => {
        setCreateModalIsOpen(false);
    };

    const openEntityCard = (entityId) => {
        if (accountId) {
            history.push(`/account/${accountId}/support/${entityId}`);
        } else {
            history.push(`/admin/support/${entityId}`);
        }
    };

    return (
        <>
            <SupportTaskCreateModal
                accountId={accountId}
                isOpen={createModalIsOpen}
                closeHandle={closeCreateModal}
            />
            <div className="row">
                <div className="col-md-4 mb-3">
                    {accountActions?.includes("tickets_list") ? (
                        <button
                            className="btn av-btn-primary fw-boldest"
                            onClick={openCreateModal}
                        >
                            <i className="fas fa-plus me-3" /> Создать заявку
                        </button>
                    ) : null}
                </div>
                <div className="col-md-8 mb-3 d-flex justify-content-end">
                    {isAdmin ? (
                        <FindComponent
                            callback={(name) => setSearchName(name)}
                        />
                    ) : (
                        ""
                    )}
                </div>
            </div>
            <div className="card border">
                <div className="table-responsive">
                    <SkeletonTable
                        cols={[
                            "Дата",
                            "Тема",
                            "",
                            "Аккаунт и автор",
                            "Статус",
                            "Номер",
                        ]}
                        rowsCount={5}
                        isLoading={isLoading}
                        classTable="table-striped"
                    >
                        <table className="table mb-0">
                            <thead>
                                <tr className="fw-bold av-text-gray-500 border-bottom">
                                    <th className="border-bottom ps-6">Дата</th>
                                    <th className="border-bottom">Тема</th>
                                    <th className="border-bottom">
                                        Аккаунт и автор
                                    </th>
                                    <th className="border-bottom">Статус</th>
                                    <th className="border-bottom">Номер</th>
                                </tr>
                            </thead>
                            <tbody>
                                {entityList?.result?.map((item) => {
                                    let ticketStatus = "";

                                    switch (item.status) {
                                        case "new":
                                            ticketStatus = (
                                                <span className="badge badge-sm bg-light-danger rounded-pill">
                                                    Новая
                                                </span>
                                            );
                                            break;
                                        case "inprogress":
                                            ticketStatus = (
                                                <span className="badge badge-sm bg-light-success rounded-pill">
                                                    Открыта
                                                </span>
                                            );
                                            break;
                                        case "done":
                                            ticketStatus = (
                                                <span className="badge badge-sm bg-light-secondary rounded-pill">
                                                    Закрыта
                                                </span>
                                            );
                                            break;

                                        default:
                                            break;
                                    }
                                    return (
                                        <tr
                                            key={item.id}
                                            onClick={() =>
                                                openEntityCard(item.id)
                                            }
                                            className="cursor-pointer"
                                        >
                                            <td className="ps-6 text-muted">
                                                {item.createdAt
                                                    ? DateToString(
                                                          UtcToLocal(
                                                              item.createdAt
                                                          ),
                                                          false
                                                      )
                                                    : null}
                                            </td>
                                            <td className="text-max">
                                                <b>{item.subject}</b>
                                                <br />
                                                <span className="text-muted">
                                                    {item.description}
                                                </span>
                                            </td>
                                            <td>
                                                <b>{item.account?.title}</b>
                                                <br />
                                                <span className="text-muted">
                                                    {item.author?.title}
                                                </span>
                                            </td>
                                            <td>{ticketStatus}</td>
                                            <td>{item.id}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </SkeletonTable>
                </div>
            </div>
        </>
    );
};

export default SupportTasksList;
