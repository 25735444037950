import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    useLazyGetAccountByIdQuery,
    useLazyGetSubaccountsQuery,
} from "../../app/storeReducers/acountsApiSlice";
import { useCreateParkingTariffMutation } from "../../app/storeReducers/parkingTariffsApiSlice";

const CreateModal = (props) => {
    const { accountId, isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const [name, setName] = useState("");
    const [freeMinutes, setFreeMinutes] = useState(0);
    const [price, setPrice] = useState(0);
    const [priceType, setPriceType] = useState("hour");
    const [acccounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(0);
    const [tariffType, setTariffType] = useState("0");

    const [getAccountById] = useLazyGetAccountByIdQuery();
    const [getSubAccounts] = useLazyGetSubaccountsQuery();
    const [createAsync] = useCreateParkingTariffMutation();

    useEffect(() => {
        setName("");
        setFreeMinutes(0);
        setPrice(0);
        setPriceType("hour");
        setSelectedAccount(0);
        setTariffType("0");
    }, [isOpen]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            let accounts = [];
            const getCurrentAccouunt = await getAccountById(accountId).unwrap();
            accounts.push(getCurrentAccouunt.result);
            const requestSubaccount = await getSubAccounts({
                accountId,
                sort: "name",
                pageSize: 5000,
            }).unwrap();
            requestSubaccount.result?.map((item) => accounts.push(item));
            setAccounts(accounts);
        }

        loadResourcesAndDataAsync();
    }, [accountId]);

    const saveEntity = async () => {
        let forAccount = null;
        if (selectedAccount !== 0) {
            forAccount = { id: selectedAccount };
        }
        const entity = {
            name,
            account: { id: accountId },
            price,
            freeMinutes,
            forAccount,
            forGuest: tariffType === "1" ? true : false,
            forQuota: tariffType === "2" ? true : false,
            priceType,
        };
        createAsync(entity);
        closeHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить новый тариф
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-3">
                                <label htmlFor="name">Название</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Тип тарифа</label>
                                <select
                                    className="form-select"
                                    value={tariffType}
                                    onChange={(e) => {
                                        setTariffType(e.target.value);
                                        if (
                                            e.target.value == "1" ||
                                            e.target.value == "2"
                                        ) {
                                            setPriceType("hour");
                                        } else {
                                            setPriceType("month");
                                        }
                                    }}
                                >
                                    <option value="0">Для транспорта</option>
                                    <option value="1">Для посетителей</option>
                                    <option value="2">
                                        Для транспорта сверх квоты
                                    </option>
                                </select>
                            </div>
                            {tariffType === "1" || tariffType === "2" ? (
                                <div className="mb-3">
                                    <label className="form-label">
                                        Тип оплаты
                                    </label>
                                    <select
                                        className="form-select"
                                        value={priceType}
                                        onChange={(e) =>
                                            setPriceType(e.target.value)
                                        }
                                    >
                                        <option value="hour">Почасовая</option>
                                        <option value="entrance">
                                            За въезд
                                        </option>
                                    </select>
                                </div>
                            ) : (
                                <div className="mb-3">
                                    <label className="form-label">
                                        Тип оплаты
                                    </label>
                                    <select
                                        className="form-select"
                                        value={priceType}
                                        onChange={(e) =>
                                            setPriceType(e.target.value)
                                        }
                                    >
                                        <option value="month">
                                            Помесячная
                                        </option>
                                        <option value="entrance">
                                            За въезд
                                        </option>
                                    </select>
                                </div>
                            )}
                            <div className="form-group mb-3">
                                <label>Для аккаунта</label>
                                <select
                                    className="form-select"
                                    value={selectedAccount}
                                    onChange={(e) =>
                                        setSelectedAccount(e.target.value)
                                    }
                                >
                                    <option value={0}>
                                        --Для всех аккаунтов--
                                    </option>
                                    {acccounts?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="price">Стоимость, руб.</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="price"
                                    value={price}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                            </div>
                            {(tariffType === "1" || tariffType === "2") &&
                            priceType.toString() === "hour" ? (
                                <div className="form-group mb-3">
                                    <label htmlFor="freeMinutes">
                                        Кол-во бесплатных минут
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="freeMinutes"
                                        value={freeMinutes}
                                        onChange={(e) =>
                                            setFreeMinutes(e.target.value)
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveEntity}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default CreateModal;
