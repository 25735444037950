import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: true,
    isAuthorize: false,
    isSendCode: false,
    user: null,
    isAuthErrors: false,
    errorDescription: '',
    errorType: '',
    isSavingUser: false,
    isSavingError: false,
    notifyArray: [],
};

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsAuthorize: (state) => {
            state.isAuthorize = true;
        },
        setCurrentUser: (state, action) => {
            state.user = action.payload;
        },
        logoutUser: (state) => {
            localStorage.removeItem('access_token');
            localStorage.removeItem('access_token_time');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('refresh_token_time');
            state.user = null;
            state.isAuthorize = false;
        },
        setCredentials: (state, action) => {
            localStorage.setItem('access_token', action.payload.result.access_token.value);
            localStorage.setItem('access_token_time', action.payload.result.access_token.expireAt);
            localStorage.setItem('refresh_token', action.payload.result.refresh_token.value);
            localStorage.setItem('refresh_token_time', action.payload.result.refresh_token.expireAt);
            state.isAuthorize = true;
            state.token = action.payload.result.access_token.value;
        },
    },
});

export const { setIsLoading, setIsAuthorize, logoutUser, setCurrentUser, setCredentials } = currentUserSlice.actions;

export const selectUser = (state) => state.currentUser.user;
export const selectIsAuthorize = (state) => state.currentUser.isAuthorize;
export const selectIsLoading = (state) => state.currentUser.isLoading;

export default currentUserSlice.reducer;
