import MonitorPanel from './MonitorPanel';

const MonitorViewPage = (props) => {
    const accountId = props.match.params.accountId;
    const monitorId = props.match.params.monitorId;

    return (
        <div className='row mt-4'>
            <div className='col-md-12'>
                <MonitorPanel accountId={accountId} monitorId={monitorId} />
            </div>
        </div>
    );
};

export default MonitorViewPage;
