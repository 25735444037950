import PermissionPage from "../../components/PermissionPage";
import TariffsList from "./TariffsList";

const TariffsPage = (props) => {
    const accountId = props.match.params.accountId;

    return (
        <PermissionPage accountId={accountId} roles={["tariff_list"]}>
            <div className="row">
                <div className="col-md-12">
                    <h1 className="mb-5">Тарифы</h1>
                    <TariffsList accountId={accountId} />
                </div>
            </div>
        </PermissionPage>
    );
};

export default TariffsPage;
