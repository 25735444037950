import { useEffect, useState } from "react";
import SkeletonSelect from "../../../../components/skeleton/SkeletonSelect";
import { useGetGroupsQuery } from "../../../../app/storeReducers/groupsApiSlice";
import { useAddSchemeMemberMutation } from "../../../../app/storeReducers/schemesApiSlice";

const SchemeAddGroupModal = (props) => {
    const { accountId, schemeId, isOpen, okHandle, closeHandle } = props;

    const [groupSelected, setGroupSelected] = useState({});

    const { data: groups, isLoading } = useGetGroupsQuery({
        accountId,
        sort: "name",
    });
    const [addMember] = useAddSchemeMemberMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setGroupSelected({});
            if (groups?.result?.length > 0) {
                setGroupSelected(groups?.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [groups]);

    const saveSchemeGroup = async () => {
        const item = {
            isGroup: true,
            info: {
                id: groupSelected,
            },
        };
        await addMember({ schemeId, body: item });

        okHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить группу в схему
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-4">
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label="Группа"
                                    value={groupSelected}
                                    onChange={(e) =>
                                        setGroupSelected(e.target.value)
                                    }
                                    itemsArray={
                                        groups?.result?.map((item) => ({
                                            id: item.id,
                                            name: item.id,
                                            description: item.name,
                                        })) || []
                                    }
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveSchemeGroup}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default SchemeAddGroupModal;
