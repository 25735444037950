import { useContext, useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Http } from '../../../../http';
import SchemeAddEmployeeModal from './SchemeAddEmployeeModal';
import SchemeEmployeeDeleteModal from './SchemeEmployeeDeleteModal';
import SchemeAddGroupModal from './SchemeAddGroupModal';
import SchemeGroupDeleteModal from './SchemeGroupDeleteModal';
import SecutiryContext from '../../../../features/securityContext';

const SchemeEmployeesPanel = (props) => {
    const { accountId, entityId, isSubaccount } = props;

    const [members, setMembers] = useState([]);
    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [addModalGroupIsOpen, setAddModalGroupIsOpen] = useState(false);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [deleteGroupItem, setDeleteGroupItem] = useState({});
    const [deleteGroupModalIsOpen, setDeleteGroupModalIsOpen] = useState(false);

    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [entityId, canEdit]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('employees_edit'));
        setCanList(context.isRoot || actions?.includes('employees_list'));
    }, [context, accountId]);

    const loadData = async () => {
        const getResult = await Http.get(
            `api/schemes/${entityId}/members?forAccount=` + accountId,
            true
        );
        getResult.result?.map((item) => {
            item.title = (
                <>
                    <i
                        className={
                            'fas ' +
                            (item.isGroup ? 'fa-users' : 'fas fa-user-tie')
                        }
                    ></i>{' '}
                    {item.info.title}
                </>
            );
        });
        getResult.result?.map((item) => {
            if (canEdit && !isSubaccount) {
                item.btns = (
                    <button
                        className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1'
                        onClick={() =>
                            item.isGroup
                                ? openDeleteGroupModal(item)
                                : openDeleteModal(item)
                        }
                    >
                        <KTSVG
                            path='/media/icons/duotune/general/gen027.svg'
                            className='svg-icon-3'
                        />
                    </button>
                );
            }
        });
        setMembers(getResult.result);
    };

    const okAddEmployee = () => {
        loadData();
        setAddModalIsOpen(false);
    };

    const okAddGroup = () => {
        loadData();
        setAddModalGroupIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const openDeleteGroupModal = (itm) => {
        setDeleteGroupItem(itm);
        setDeleteGroupModalIsOpen(true);
    };

    const deleteEmployee = () => {
        loadData();
        setDeleteModalIsOpen(false);
    };

    const deleteGroup = () => {
        loadData();
        setDeleteGroupModalIsOpen(false);
    };

    return (
        <>
            <SchemeAddEmployeeModal
                accountId={accountId}
                schemeId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddEmployee}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <SchemeAddGroupModal
                accountId={accountId}
                schemeId={entityId}
                isOpen={addModalGroupIsOpen}
                okHandle={okAddGroup}
                closeHandle={() => setAddModalGroupIsOpen(false)}
            />
            <SchemeEmployeeDeleteModal
                schemeId={entityId}
                employee={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteEmployee}
            />
            <SchemeGroupDeleteModal
                schemeId={entityId}
                group={deleteGroupItem}
                isOpen={deleteGroupModalIsOpen}
                closeHandle={() => setDeleteGroupModalIsOpen(false)}
                okHandle={deleteGroup}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            <h3>
                                Участники{' '}
                                <span className='badge rounded-pill bg-light text-dark'>
                                    {members?.length}
                                </span>
                            </h3>
                        </div>
                        <div className='col-md-8 text-end'>
                            {canEdit && !isSubaccount ? (
                                <>
                                    <button
                                        className='btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0'
                                        onClick={() => setAddModalIsOpen(true)}
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr075.svg'
                                            className='svg-icon-2 me-2'
                                        />
                                        Сотрудник
                                    </button>
                                    <button
                                        className='btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0'
                                        onClick={() =>
                                            setAddModalGroupIsOpen(true)
                                        }
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/arrows/arr075.svg'
                                            className='svg-icon-2 me-2'
                                        />
                                        Группа
                                    </button>
                                </>
                            ) : null}
                        </div>
                    </div>
                    {canList ? (
                        members?.map((item) => (
                            <div
                                className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'
                                key={item.id}
                            >
                                <i
                                    className={
                                        'fas ' +
                                        (item.isGroup
                                            ? 'fa-users'
                                            : 'fas fa-user-tie')
                                    }
                                ></i>{' '}
                                {item.info.title}
                                {canEdit ? (
                                    <i
                                        className='ms-2 fas fa-times cursor-pointer'
                                        onClick={() =>
                                            item.isGroup
                                                ? openDeleteGroupModal(item)
                                                : openDeleteModal(item)
                                        }
                                    ></i>
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                </div>
            </div>
        </>
    );
};

export default SchemeEmployeesPanel;
