const DeviceIcon = (props) => {
    const { device } = props;


    const typeIcon = () => {
        let icon = '/media/icons/avanguard/Door.svg';
        switch (device?.type) {
            case 'door':
                icon = '/media/icons/avanguard/Door.svg';
                break;
            case 'autogate':
                icon = '/media/icons/avanguard/Gate1.svg';
                break;
            case 'autogate2':
                icon = '/media/icons/avanguard/Gate2.svg';
                break;
            case 'turnstile':
                icon = '/media/icons/avanguard/Turnstile.svg';
                break;

            default:
                icon = '/media/icons/avanguard/Door.svg';
                break;
        }
        return icon;
    };

    return (
        <img
            src={typeIcon()}
            className='add-profile-img'
            style={{
                width: 20,
                height: 20,
            }}
        />
    );
};

export default DeviceIcon;
