import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../http';
import DeviceAddCameraModal from './DeviceAddCameraModal';
import { KTSVG } from '../../../_metronic/helpers';
import DeviceCameraDeleteModal from './DeviceCameraDeleteModal';
import FrameModal from '../../cameras/cameraCard/FrameModal';
import SecutiryContext from '../../../features/securityContext';

const DeviceCamerasPanel = (props) => {
    const { accountId, entityId } = props;

    const [addModalIsOpen, setAddModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [cameras, setCameras] = useState([]);
    const [deleteItem, setDeleteItem] = useState({});
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canList, setCanList] = useState(false);

    const [isOpenImageModal, setIsOpenImageModal] = useState(false);
    const [selectedCameraId, setSelectedCameraId] = useState(0);
    const [selectedCameraName, setSelectedCameraName] = useState(0);
    const [selectedCameraProxy, setSelectedCameraProxy] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [entityId, canEdit]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('devices_edit'));
        setCanList(context.isRoot || actions?.includes('devices_list'));
    }, [context, accountId]);

    const loadData = async () => {
        setIsLoading(true);
        const getResult = await Http.get(
            `api/cameras?accountId=${accountId}&deviceId=${entityId}`,
            true
        );
        setCameras(getResult.result);
        setIsLoading(false);
    };

    const okAddCamera = () => {
        loadData();
        setAddModalIsOpen(false);
    };

    const openDeleteModal = (itm) => {
        setDeleteItem(itm);
        setDeleteModalIsOpen(true);
    };

    const deleteCamera = () => {
        loadData();
        setDeleteModalIsOpen(false);
    };

    const openImageModal = (e, entityId, name, isRtspProxy) => {
        e.stopPropagation();
        setSelectedCameraId(entityId);
        setSelectedCameraName(name);
        setIsOpenImageModal(true);
        setSelectedCameraProxy(isRtspProxy);
    };

    const closeFrameModal = () => {
        setIsOpenImageModal(false);
        setSelectedCameraId(0);
    };

    return (
        <>
            <DeviceAddCameraModal
                accountId={accountId}
                deviceId={entityId}
                isOpen={addModalIsOpen}
                okHandle={okAddCamera}
                closeHandle={() => setAddModalIsOpen(false)}
            />
            <DeviceCameraDeleteModal
                deviceId={entityId}
                camera={deleteItem}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
                okHandle={deleteCamera}
            />
            <FrameModal
                cameraId={selectedCameraId}
                name={selectedCameraName}
                isOpen={isOpenImageModal}
                closeHandle={closeFrameModal}
                isRtspProxy={selectedCameraProxy}
            />
            <div className='row mb-3'>
                <div className='col-md-6'>
                    <h3>
                        Камеры{' '}
                        <span className='badge rounded-pill bg-light text-dark'>
                            {cameras.length}
                        </span>
                    </h3>
                </div>
                <div className='col-md-6 text-end'>
                    {canEdit ? (
                        <button
                            className='btn btn-sm btn-light-primary fw-bolder av-text-gray-600 pt-0 pe-0'
                            onClick={() => setAddModalIsOpen(true)}
                        >
                            <KTSVG
                                path='/media/icons/duotune/arrows/arr075.svg'
                                className='svg-icon-2 me-2'
                            />
                            Добавить камеру
                        </button>
                    ) : null}
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col'>
                    {canList ? (
                        cameras.map((item) => (
                            <div
                                className='border av-border-gray-300 rounded d-inline-block p-2 me-2 mb-2'
                                key={item.id}
                            >
                                <span
                                    className={
                                        'fas ' +
                                        (item.isRtspProxy
                                            ? 'fa-video'
                                            : 'fa-camera') +
                                        ' me-2'
                                    }
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) =>
                                        openImageModal(
                                            e,
                                            item.id,
                                            item.name,
                                            item.isRtspProxy
                                        )
                                    }
                                />
                                {item.name}{' '}
                                {canEdit ? (
                                    <i
                                        className='ms-2 fas fa-times cursor-pointer'
                                        onClick={() => openDeleteModal(item)}
                                    ></i>
                                ) : null}
                            </div>
                        ))
                    ) : (
                        <i>У вас нет прав на просмотр содержимого</i>
                    )}
                </div>
            </div>
        </>
    );
};

export default DeviceCamerasPanel;
