import { useEffect, useState } from 'react';
import PermissionPage from '../../../components/PermissionPage';
import Paginator from '../../../components/Paginator';
import BanDetailsModal from './BanDetailsModal';
import BanNewModal from './BanNewModal';
import { useLazyGetBlacklistsQuery } from '../../../app/storeReducers/AccountBlacklistApi';
import AmnestyModal from './AmnestyModal';
import FindComponent from '../../../components/FindComponent';
import { MinutesToHours } from '../../../helpers/DateHelpers';
import ThSortable from '../../../components/table/ThSortable';

const ParkingBanPage = (props) => {
    const accountId = props.match.params.accountId;

    const [currentTab, setCurrentTab] = useState(1);
    const [isOpenBanModal, setIsOpenBanModal] = useState(false);
    const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [unblockModalIsOpen, setUnblockModalIsOpen] = useState(false);
    const [currentBlock, setCurrentBlock] = useState(0);
    const [currentEntity, setCurrentEntity] = useState(0);
    const [searchString, setSearchString] = useState('');
    const [clearCount, setClearCount] = useState(0);
    const [sort, setSort] = useState('last_name');
    const [sortDest, setSortDest] = useState('asc');
    const [mainSort, setMainSort] = useState('name');

    const [getBlacklists, { data: blacklists }] = useLazyGetBlacklistsQuery();

    const columnFilter = (colName) => {
        const dest =
            colName === sort ? (sortDest === 'asc' ? 'desc' : 'asc') : 'asc';
        setSort(colName);
        setSortDest(dest);
        setMainSort(colName + (dest === 'desc' ? '_desc' : ''));
    };


    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (currentTab < 3 || searchString.length > 0) {
                getBlacklists({
                    accountId,
                    category: currentTab < 3 ? currentTab : '',
                    pageSize,
                    pageIndex,
                    search: searchString,
                    sort: mainSort
                });
            }
            else if(currentTab === 3) {
                getBlacklists({
                    accountId:-1,
                    category: '',
                    pageSize: 1,
                    pageIndex: 100,
                    search: searchString,
                    sort: mainSort
                });
            }
        }

        loadResourcesAndDataAsync();
    }, [accountId, currentTab, pageSize, pageIndex, searchString, mainSort]);

    const changeTab = (tabIndex) => {
        setCurrentTab(tabIndex);
        setSearchString('');
        setClearCount(Date.now());
        setPageIndex(1);
    };

    const unblock = (e, blockId) => {
        e.stopPropagation();
        setCurrentBlock(blockId);
        setUnblockModalIsOpen(true);
    };

    const blockCandidate = (e, candidateId) => {
        e.stopPropagation();
        setCurrentEntity(candidateId);
        setIsOpenBanModal(true);
    };

    const openDetails = (itemId) => {
        setCurrentEntity(itemId);
        setIsOpenDetailsModal(true);
    };

    const carButtons = (item) => {
        let cssClass = 'text-bg-secondary';
        let textBLock = 'Пусто';
        let component = <></>;
        switch (item.category) {
            case 'none':
                cssClass = 'bg-secondary';
                textBLock = 'Пусто';
                break;
            case 'candidate':
                cssClass = 'bg-warning';
                textBLock = 'Кандидат';
                break;
            case 'block':
                cssClass = 'bg-danger';
                textBLock = 'Заблокирован';
                break;
            default:
                break;
        }
        switch (currentTab) {
            case 1:
                component = (
                    <td className='text-end'>
                        <button
                            className='btn av-btn-danger me-2'
                            onClick={(e) => blockCandidate(e, item.id)}
                        >
                            Заблокировать
                        </button>
                        <button
                            className='btn av-btn-simple'
                            onClick={(e) => unblock(e, item.id)}
                        >
                            Удалить
                        </button>
                    </td>
                );
                break;
            case 2:
                component = (
                    <td className='text-end'>
                        <button
                            className='btn btn-sm av-btn-simple me-2'
                            onClick={(e) => unblock(e, item.id)}
                        >
                            Разблокировать
                        </button>
                    </td>
                );
                break;
            case 3:
                component = (
                    <td>
                        <span className={'badge ' + cssClass}>{textBLock}</span>
                    </td>
                );
                break;
            default:
                break;
        }
        return component;
    };

    return (
        <PermissionPage accountId={accountId} roles={['parking_area_list']}>
            <BanNewModal
                accountId={accountId}
                isOpen={isOpenBanModal}
                closeHandle={() => setIsOpenBanModal(false)}
                entity={blacklists?.result?.find((x) => x.id === currentEntity)}
            />
            <BanDetailsModal
                accountId={accountId}
                entity={blacklists?.result?.find((x) => x.id === currentEntity)}
                isOpen={isOpenDetailsModal}
                closeHandle={() => setIsOpenDetailsModal(false)}
            />
            <AmnestyModal
                isOpen={unblockModalIsOpen}
                closeHandle={() => setUnblockModalIsOpen(false)}
                entity={blacklists?.result?.find((x) => x.id === currentBlock)}
            />
            <div className='row'>
                <div className='col'>
                    <h1 className='mb-5'>Чёрный список</h1>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 mb-3'>
                    <button
                        type='button'
                        className='btn av-btn-primary fw-boldest mb-3'
                        onClick={() => {
                            setCurrentEntity(0);
                            setIsOpenBanModal(true);
                        }}
                    >
                        <i className='fas fa-plus me-3' />
                        Добавить в ЧС
                    </button>
                </div>
                <div className='col-md-8 mb-3 d-flex justify-content-end'>
                    <FindComponent
                        clear={clearCount}
                        callback={(name) => {
                            setSearchString(name);
                            setPageIndex(1);
                        }}
                        placeholder='Поиск'
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <ul className='nav nav-tabs flex-row justify-content-start'>
                        <li className='nav-item'>
                            <button
                                className={
                                    'nav-link' +
                                    (currentTab === 1 ? ' active' : '')
                                }
                                onClick={() => changeTab(1)}
                            >
                                Кандидаты
                            </button>
                        </li>
                        <li className='nav-item'>
                            <button
                                className={
                                    'nav-link' +
                                    (currentTab === 2 ? ' active' : '')
                                }
                                onClick={() => changeTab(2)}
                            >
                                Заблокированные
                            </button>
                        </li>
                        <li className='nav-item'>
                            <button
                                className={
                                    'nav-link' +
                                    (currentTab === 3 ? ' active' : '')
                                }
                                onClick={() => changeTab(3)}
                            >
                                Архив
                            </button>
                        </li>
                    </ul>
                </div>
                {currentTab === 3 && searchString.length === 0 ? (
                    <i className='my-2'>Введите номер авто в строку поиска</i>
                ) : (
                    <table className='table mb-0'>
                        <thead>
                            <tr className='fw-bold av-text-gray-500 border-bottom'>

                                <ThSortable
                                    className='border-bottom ps-6'
                                    colName='name'
                                    colTitle='Гос. номер'
                                    colChanged={(name) =>
                                        columnFilter(name)
                                    }
                                    sortCol={sort}
                                    sortDestinaton={sortDest}
                                />
                                <ThSortable
                                    className='border-bottom ps-6'
                                    colName='violationCount'
                                    colTitle='Кол-во нарушений'
                                    colChanged={(name) =>
                                        columnFilter(name)
                                    }
                                    sortCol={sort}
                                    sortDestinaton={sortDest}
                                />
                                <ThSortable
                                    className='border-bottom ps-6'
                                    colName='blockCount'
                                    colTitle='Кол-во внесений в ЧС'
                                    colChanged={(name) =>
                                        columnFilter(name)
                                    }
                                    sortCol={sort}
                                    sortDestinaton={sortDest}
                                />
                                <ThSortable
                                    className='border-bottom ps-6'
                                    colName='duration'
                                    colTitle='Суммарная длительность нарушений'
                                    colChanged={(name) =>
                                        columnFilter(name)
                                    }
                                    sortCol={sort}
                                    sortDestinaton={sortDest}
                                />
                                <th className='border-bottom'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {blacklists?.result?.map((item) => (
                                <tr
                                    onClick={() => openDetails(item.id)}
                                    className='cursor-pointer'
                                    key={item.id}
                                >
                                    <td className='ps-6'>
                                        {item.carNumber + ' ' + item.carRegion}
                                    </td>
                                    <td className='text-end'>
                                        {item.violationCount}
                                    </td>
                                    <td className='text-end'>
                                        {item.blockCount}
                                    </td>
                                    <td className='text-end'>
                                        {MinutesToHours(item.duration)}
                                    </td>
                                    {carButtons(item)}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <div className='card-body py-3 px-5'>
                <Paginator
                    onPageChange={(e) => setPageIndex(e)}
                    onPageSizeChange={(e) => setPageSize(e)}
                    pageCount={blacklists?.paginator?.totalPages ?? 1}
                    pageSizeArray={[50, 100, 500]}
                    pageSize={pageSize}
                    countCurrent={
                        blacklists?.result?.length ??
                        0 + (pageSize * blacklists?.paginator?.pageIndex - 1)
                    }
                    countTotal={blacklists?.paginator?.totalCount ?? 0}
                    forcePage={blacklists?.paginator?.pageIndex}
                />
            </div>
        </PermissionPage>
    );
};

export default ParkingBanPage;
