import { useEffect, useState } from "react";
import SkeletonSelect from "../../../../components/skeleton/SkeletonSelect";
import { useGetEmployeesQuery } from "../../../../app/storeReducers/employeesApiSlice";
import { useAddGroupMemberMutation } from "../../../../app/storeReducers/groupsApiSlice";

const GroupAddEmployeeModal = (props) => {
    const { accountId, groupId, isOpen, okHandle, closeHandle } = props;

    const [employeeSelected, setEmployeeSelected] = useState({});

    const { data: employees, isLoading } = useGetEmployeesQuery(
        { accountId, sort: "last_name", pageSize: 10000 },
        { skip: !accountId }
    );

    const [addGroupEmployee] = useAddGroupMemberMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setEmployeeSelected({});
            if (employees?.result.length > 0) {
                setEmployeeSelected(employees.result[0].id);
            }
        }

        loadResourcesAndDataAsync();
    }, [employees]);

    const saveGroupEmployee = async () => {
        await addGroupEmployee({ groupId, employeeId: employeeSelected });

        okHandle();
    };

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Добавить сотрудника в группу
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-4">
                                <SkeletonSelect
                                    isLoading={isLoading}
                                    label="Сотрудник"
                                    value={employeeSelected}
                                    onChange={(e) =>
                                        setEmployeeSelected(e.target.value)
                                    }
                                    itemsArray={employees?.result.map((e) => ({
                                        id: e.id,
                                        name: e.id,
                                        description: e.name,
                                    }))}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                type="button"
                                className="btn av-btn-primary"
                                onClick={saveGroupEmployee}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default GroupAddEmployeeModal;
