import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonInput from "../../../components/skeleton/SkeletonInput";
import FrameModal from "./FrameModal";
import CameraDeleteModal from "../CameraDeleteModal";
import { selectContext } from "../../../app/storeReducers/AuthSlice";
import {
    useGetCameraByIdQuery,
    useUpdateCameraMutation,
} from "../../../app/storeReducers/camerasApiSlice";

const CameraEditPanel = (props) => {
    const { accountId, entityId } = props;

    const [protocol, setProtocol] = useState("rtsp");
    const [name, setName] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [address, setAddress] = useState("");
    const [port, setPort] = useState(0);
    const [path, setPath] = useState("");
    const [isRtspProxy, setIsRtspProxy] = useState(false);
    const [modalCameraId, setModalCameraId] = useState("");
    const [direction, setDirection] = useState(0);

    const context = useSelector(selectContext);
    const [canEdit, setCanEdit] = useState(false);
    const [isHidePass, setIsHidePass] = useState(true);

    const [isOpenImageModal, setIsOpenImageModal] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const { data: camera, isLoading } = useGetCameraByIdQuery(entityId, {
        skip: !entityId,
    });
    const [updateAsync] = useUpdateCameraMutation();

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (camera) {
                setProtocol(camera.result.protocol);
                setName(camera.result.name);
                setUserName(camera.result.username);
                setPassword(camera.result.password);
                setAddress(camera.result.address);
                setPort(camera.result.port);
                setPath(camera.result.path);
                setIsRtspProxy(camera.result.isRtspProxy);
                setDirection(camera.result.direction);
            }
        }

        loadResourcesAndDataAsync();
    }, [camera]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("camera_edit"));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            protocol,
            userName,
            password,
            address,
            port,
            path,
            account: { id: accountId },
            id: entityId,
            isRtspProxy,
            direction,
        };
        await updateAsync(entity);
    };

    const showHidePassword = () => {
        setIsHidePass(!isHidePass);
    };

    const closeFrameModal = () => {
        setIsOpenImageModal(false);
        setModalCameraId(0);
    };

    return (
        <>
            <CameraDeleteModal
                entity={{ name, id: entityId, account: { id: accountId } }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <FrameModal
                cameraId={modalCameraId}
                isOpen={isOpenImageModal}
                closeHandle={closeFrameModal}
                isRtspProxy={isRtspProxy}
            />
            <div className="card border">
                <div className="card-body pb-2">
                    <div className="row mb-2">
                        <div className="col-md-6">
                            <h3>Камера</h3>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label>Протокол</label>
                        <select
                            className="form-select"
                            value={protocol}
                            onChange={(e) => setProtocol(e.target.value)}
                        >
                            {[
                                {
                                    key: "rtsp",
                                    value: "RTSP",
                                },
                                {
                                    key: "http",
                                    value: "HTTP",
                                },
                            ].map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="name"
                            label="Название"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label>Направление</label>
                        <select
                            className="form-select"
                            value={direction}
                            onChange={(e) => setDirection(e.target.value)}
                        >
                            {[
                                {
                                    key: "0",
                                    value: "-- Не выбрано --",
                                },
                                {
                                    key: "1",
                                    value: "Вход",
                                },
                                {
                                    key: "2",
                                    value: "Выход",
                                },
                            ].map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="userName"
                            label="Имя пользователя"
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className="mb-3 position-relative">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="password"
                            label="Пароль"
                            value={password}
                            type={isHidePass ? "password" : "text"}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {canEdit ? (
                            <span
                                className={`fas ${
                                    isHidePass ? "fa-eye" : "fa-low-vision"
                                } password-eye cursor-pointer`}
                                onClick={showHidePassword}
                            />
                        ) : null}
                    </div>
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-md-8">
                                <SkeletonInput
                                    isLoading={isLoading}
                                    id="address"
                                    label="Адрес"
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </div>
                            <div className="col-md-4">
                                <SkeletonInput
                                    isLoading={isLoading}
                                    id="port"
                                    label="Порт"
                                    value={port}
                                    onChange={(e) => setPort(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="path"
                            label="Путь"
                            value={path}
                            onChange={(e) => setPath(e.target.value)}
                        />
                    </div>
                    {protocol === "rtsp" ? (
                        <div className="mb-3">
                            <div className="form-check form-check-inline form-group">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="rtspProxy"
                                    checked={isRtspProxy}
                                    onChange={(e) =>
                                        setIsRtspProxy(e.target.checked)
                                    }
                                    disabled={!context.isRoot}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="rtspProxy"
                                >
                                    Использовать трансляцию
                                </label>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <button
                                className="btn av-btn-danger fw-boldest"
                                onClick={() => setDeleteModalIsOpen(true)}
                                disabled={!canEdit}
                            >
                                <i className="far fa-trash-alt me-2" /> Удалить
                            </button>
                        </div>
                        <div className="col text-end">
                            {canEdit ? (
                                <button
                                    className="btn btn-sm av-btn-primary me-2"
                                    onClick={() => {
                                        setModalCameraId(entityId);
                                        setIsOpenImageModal(true);
                                    }}
                                >
                                    {isRtspProxy ? (
                                        <span className="fas fa-video" />
                                    ) : (
                                        <span className="fas fa-camera" />
                                    )}
                                </button>
                            ) : null}
                            <button
                                className="btn av-btn-simple fw-boldest"
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CameraEditPanel;
