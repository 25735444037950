import ReportParkingList from './ReportParkingList';

const ReportParkingPage = (props) => {
    const accountId = props.match.params.accountId;
    return (
        <div className='row'>
            <div className='col-md-12'>
                <h1 className='mb-5'>Отчёт по парковке</h1>
                <ReportParkingList accountId={accountId} />
            </div>
        </div>
    );
};

export default ReportParkingPage;
