import { useEffect, useState } from 'react';
import WaitLoading from '../../../../../components/WaitLoading';
import { Http } from '../../../../../http';

const ImageModal = (props) => {
    const { imageId, isOpen, closeHandle } = props;

    const [image, setImage] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(async () => {
        if (imageId !== null) {
            console.log('imageId', imageId);
            setIsLoading(true);
            const getResult = await Http.get('api/parking/images/' + imageId);
            console.log('media', getResult);
            setImage(getResult.result.base64Image);
        }
        setIsLoading(false);
    }, [imageId]);

    const fullImage = () => {
        var img = new Image();
        img.src = 'data:image/png;base64, ' + image;

        var w = window.open('');
        w.document.write(img.outerHTML);
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered modal-xl'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Просмотр изображения
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body'>
                            <WaitLoading isLoading={isLoading}>
                                <img
                                    src={'data:image/png;base64, ' + image}
                                    className='img-fluid'
                                />
                            </WaitLoading>
                        </div>
                        <div className='modal-footer'>
                            <button
                                type='button'
                                className='btn btn-secondary'
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                            <button
                                className='btn btn-primary'
                                onClick={fullImage}
                            >
                                <i className='fas fa-external-link-alt' />{' '}
                                Оригинальный размер
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default ImageModal;
