import { baseApi } from "../baseApi";
import { addNotification } from "./NotifySlice";

export const accountsApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAccounts: builder.query({
            query: (query) =>
                `accounts?name=${query.name ?? ""}&hostId=${
                    query.hostId ?? ""
                }&sort=${query.sort ?? ""}&pageIndex=${
                    query.pageIndex ?? 1
                }&pageSize=${query.pageSize ?? 100}` +
                `&onlyRoot=${query.onlyRoot ?? false}&showDeleted=${
                    query.showDeleted ?? false
                }`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Accounts",
                              id,
                          })),
                          { type: "Accounts", id: "List" },
                      ]
                    : [{ type: "Accounts", id: "List" }],
        }),
        getAccountById: builder.query({
            query: (id) => `accounts/${id}`,
            providesTags: (result) => [
                { type: "Account", id: result.result.id },
            ],
        }),
        createAccount: builder.mutation({
            query: (body) => ({
                url: "accounts",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Accounts", id: "List" }],
        }),
        updateAccount: builder.mutation({
            query: (body) => ({
                url: "accounts/" + body.id,
                method: "PUT",
                body,
            }),
            invalidatesTags: (result) => [
                { type: "Accounts", id: "List" },
                { type: "Account", id: result?.result?.id },
            ],
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    if (data.error) {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Ошибка сохранения!",
                                description:
                                    data.errorDescription ??
                                    "Не удалось сохранить данные аккаунта! Перезагрузите страницу и попробуйте снова!",
                                type: "danger",
                            })
                        );
                    } else {
                        dispatch(
                            addNotification({
                                dateNotify: Date.now(),
                                title: "Аккаунт сохранен!",
                                description:
                                    "Данные аккаунта успешно сохранены!",
                                type: "success",
                            })
                        );
                    }
                } catch (error) {
                    dispatch(
                        addNotification({
                            dateNotify: Date.now(),
                            title: "Ошибка сохранения!",
                            description:
                                "Не удалось сохранить данные аккаунта! Перезагрузите страницу и попробуйте снова!",
                            type: "danger",
                        })
                    );
                }
            },
        }),
        deleteAccount: builder.mutation({
            query: (id) => ({
                url: "accounts/" + id,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Accounts", id: "List" }],
        }),
        getSubaccounts: builder.query({
            query: (data) =>
                `accounts/${data.accountId ?? ""}/subaccounts?search=${
                    data.search ?? ""
                }&pageIndex=${data.pageIndex ?? 1}&pageSize=${
                    data.pageSize ?? 100
                }&sort=${data.sort ?? ""}`,
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "Accounts",
                              id,
                          })),
                          { type: "Accounts", id: "List" },
                      ]
                    : [{ type: "Accounts", id: "List" }],
        }),
        createSubaccount: builder.mutation({
            query: (body) => ({
                url: "accounts/" + body.parent.id + "/subaccounts",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "Accounts", id: "List" }],
        }),
        getAccountSummary: builder.query({
            query: (id) => `accounts/${id}/summary`,
        }),
        blockAccount: builder.mutation({
            query: (accountId) => ({
                url: "accounts/" + accountId + "/block",
                method: "PUT",
            }),
            invalidatesTags: (result) => [
                { type: "Accounts", id: "List" },
                { type: "Account", id: result?.result?.id },
            ],
        }),
        unblockAccount: builder.mutation({
            query: (accountId) => ({
                url: "accounts/" + accountId + "/unblock",
                method: "PUT",
            }),
            invalidatesTags: (result) => [
                { type: "Accounts", id: "List" },
                { type: "Account", id: result?.result?.id },
            ],
        }),
    }),
});

export const {
    useGetAccountsQuery,
    useLazyGetAccountsQuery,
    useGetAccountByIdQuery,
    useLazyGetAccountByIdQuery,
    useCreateAccountMutation,
    useUpdateAccountMutation,
    useDeleteAccountMutation,
    useGetSubaccountsQuery,
    useLazyGetSubaccountsQuery,
    useCreateSubaccountMutation,
    useGetAccountSummaryQuery,
    useBlockAccountMutation,
    useUnblockAccountMutation,
} = accountsApiSlice;
