import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HumanPhone } from "../../helpers/PhoneHelper";
import VehicleCreateModal from "./VehicleCreateModal";
import VehicleDeleteModal from "./VehicleDeleteModal";
import { selectContext } from "../../app/storeReducers/AuthSlice";
import { useGetVehicleByIdQuery } from "../../app/storeReducers/AccountVehiclesAPI";

const VehiclePanel = (props) => {
    const { accountId, vehicleId } = props;

    const { data: vehicle } = useGetVehicleByIdQuery(vehicleId, {
        skip: !vehicleId,
    });

    const context = useSelector(selectContext);

    const [canEdit, setCanEdit] = useState(false);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes("vehicles_edit"));
    }, [context, accountId]);

    return (
        <>
            <VehicleCreateModal
                accountId={accountId}
                entityId={vehicle?.result?.id}
                isOpen={updateModalIsOpen}
                closeHandle={() => setUpdateModalIsOpen(false)}
            />
            <VehicleDeleteModal
                entity={vehicle?.result}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className="card border mb-3" id="vehiclePanel">
                <div className="card-body pb-2">
                    <div className="row">
                        <div className="col">
                            <h3>{vehicle?.result?.model}</h3>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <img
                                src="/media/icons/avanguard/Transport.svg"
                                className="align-text-bottom h-20px me-1"
                                alt="avanguard logo"
                            />
                            <div className="fild-text d-inline">
                                {vehicle?.result?.carNumber}{" "}
                                {vehicle?.result?.carRegion}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <div className="fild-label">Аккаунт</div>
                            <div className="fild-text">
                                {vehicle?.result?.account?.title}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <div className="fild-label">Сотрудник</div>
                            <div className="fild-text">
                                {vehicle?.result?.employee?.title}
                            </div>
                            {vehicle?.result?.employee?.phone && (
                                <div className="fild-text av-text-gray-500">
                                    {HumanPhone(
                                        vehicle?.result?.employee?.phone
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <div className="fild-label">Парковка</div>
                            <div className="fild-text">
                                {vehicle?.result?.parkingArea?.title}
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <div className="fild-label">Место</div>
                            <div className="fild-text">
                                {vehicle?.result?.parkingPlace?.title}
                            </div>
                        </div>
                    </div>
                    {canEdit ? (
                        <div className="row mb-3">
                            <div className="col-md-8">
                                <button
                                    className="btn av-btn-danger fw-boldest"
                                    onClick={() => setDeleteModalIsOpen(true)}
                                >
                                    <i className="far fa-trash-alt me-2" />{" "}
                                    Удалить
                                </button>
                            </div>
                            <div className="col text-end">
                                <button
                                    className="btn av-btn-simple fw-boldest"
                                    onClick={() => setUpdateModalIsOpen(true)}
                                >
                                    <img
                                        src="/media/icons/base/edit-3.svg"
                                        className="align-top"
                                    />{" "}
                                    Изменить
                                </button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default VehiclePanel;
