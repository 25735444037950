import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getDeviceAsync,
    selectDevice,
} from '../../app/storeReducers/AccountDevicesSlice';
import { Http } from '../../http';
import DeviceCreateModal from './DeviceCreateModal';
import DeviceDeleteModal from './DeviceDeleteModal';
import DeviceModeArray from './DeviceModeArray';
import DeviceReaderArray from './DeviceReaderArray';
import EmergencyInputArray from './EmergencyInputArray';
import DeviceReplaceModal from './DeviceReplaceModal';
import SecutiryContext from '../../features/securityContext';
import DeviceIcon from './DeviceIcon';
import AvanguardProperties from './AvanguardProperties';
import UniubiProperties from './UniubiProperties';
import UniubiDeviceCreateModal from './UniubiDeviceCreateModal';
import TimeTrackingProperties from './TimeTrackingProperties';
import TimeTrackingDeviceCreateModal from './TimeTrackingDeviceCreateModal';
import MqttProperties from './MqttProperties';
import MqttDeviceCreateModal from './MqttDeviceCreateModal';

const DevicePanel = (props) => {
    const { accountId, deviceId } = props;

    const dispatch = useDispatch();
    const device = useSelector(selectDevice);
    const { context } = useContext(SecutiryContext);

    const [canEdit, setCanEdit] = useState(false);
    const [canReplace, setCanReplace] = useState(false);
    const [canOpen, setCanOpen] = useState(false);

    const [replaceModalIsOpen, setReplaceModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            dispatch(getDeviceAsync(deviceId));
        }

        loadResourcesAndDataAsync();
    }, [deviceId]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const actions = context?.permissions?.find(
                (x) => x.id === parseInt(accountId)
            )?.actions;
            setCanEdit(context.isRoot || actions?.includes('devices_edit'));
            setCanReplace(context.isRoot && device.provider !== 'mqtt');
            setCanOpen(
                context.isRoot || actions?.includes('device_web_access')
            );

            if (accountId > 0 && context !== null && context !== undefined) {
                const locationResponse = await Http.get(
                    'api/locations?accountId=' + accountId,
                    true
                );
                setLocations(locationResponse.result);
            }
        }
        loadResourcesAndDataAsync();
    }, [context, accountId, device]);

    const openHandle = async (direction) => {
        await Http.post(
            'api/devices/open?id=' + deviceId + '&direction=' + direction,
            true
        );
    };

    const Properties = () => {
        if (device.provider === 'atlas' || device.provider === 'lite') {
            return <AvanguardProperties device={device} />;
        } else if (device.provider === 'uniubi') {
            return <UniubiProperties device={device} />;
        } else if (device.provider === 'timetracking') {
            return <TimeTrackingProperties device={device} />;
        } else if (device.provider === 'mqtt') {
            return <MqttProperties device={device} />;
        }
        return <></>;
    };

    const EditModal = () => {
        if (device.provider === 'atlas' || device.provider === 'lite') {
            return (
                <DeviceCreateModal
                    accountId={accountId}
                    entityId={device?.id}
                    isOpen={updateModalIsOpen}
                    closeHandle={() => setUpdateModalIsOpen(false)}
                    isLite={device.provider === 'lite'}
                    locations={locations}
                />
            );
        } else if (device.provider === 'uniubi') {
            return (
                <UniubiDeviceCreateModal
                    accountId={accountId}
                    entityId={device?.id}
                    isOpen={updateModalIsOpen}
                    closeHandle={() => setUpdateModalIsOpen(false)}
                    locations={locations}
                />
            );
        } else if (device.provider === 'timetracking') {
            return (
                <TimeTrackingDeviceCreateModal
                    accountId={accountId}
                    entityId={device?.id}
                    isOpen={updateModalIsOpen}
                    closeHandle={() => setUpdateModalIsOpen(false)}
                    locations={locations}
                />
            );
        } else if (device.provider === 'mqtt') {
            return (
                <MqttDeviceCreateModal
                    accountId={accountId}
                    entityId={device?.id}
                    isOpen={updateModalIsOpen}
                    closeHandle={() => setUpdateModalIsOpen(false)}
                    locations={locations}
                />
            );
        }
        return <></>;
    };

    const GetButtonName = (direction) => {
        if (device?.type === 'autogate2') {
            return direction === 'in' ? 'Въезд' : 'Выезд';
        } else if (device?.type === 'turnstile') {
            return direction === 'in' ? 'Вход' : 'Выход';
        } else {
            return 'Открыть';
        }
    };

    const GetOpenButtons = () => {
        if (device?.provider === 'timetracking') {
            return '';
        }
        if (device?.type === 'autogate2' || device?.type === 'turnstile') {
            return (
                <>
                    <button
                        className='btn av-btn-primary btn-sm me-2'
                        onClick={() => openHandle('in')}
                        disabled={!canOpen}
                    >
                        {GetButtonName('in')}
                    </button>
                    <button
                        className='btn av-btn-primary btn-sm me-2'
                        onClick={() => openHandle('out')}
                        disabled={!canOpen}
                    >
                        {GetButtonName('out')}
                    </button>
                </>
            );
        }

        return (
            <button
                className='btn av-btn-primary btn-sm me-2'
                onClick={() => openHandle('in')}
                disabled={!canOpen}
            >
                {GetButtonName('in')}
            </button>
        );
    };

    return (
        <>
            <EditModal />
            <DeviceDeleteModal
                entity={device}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <DeviceReplaceModal
                device={device}
                isOpen={replaceModalIsOpen}
                closeHandle={() => setReplaceModalIsOpen(false)}
            />
            <div className='card border mb-3'>
                <div className='card-body pb-2'>
                    <div className='row mb-3'>
                        <div className='col d-flex'>
                            <div className='card-type border-0'>
                                <div className='card-icon-block me-3 rounded-circle'>
                                    <DeviceIcon device={device} />
                                </div>
                            </div>
                            <h3 className='align-self-center'>
                                {device?.name}
                            </h3>
                        </div>
                    </div>

                    <Properties device={device} />

                    <div className='row mb-3'>
                        <div className='col-md-5'>
                            {canEdit ? (
                                <button
                                    className='btn av-btn-danger fw-boldest'
                                    onClick={() => setDeleteModalIsOpen(true)}
                                >
                                    <i className='far fa-trash-alt me-2' />{' '}
                                    Удалить
                                </button>
                            ) : null}
                            {canReplace ? (
                                <>
                                    <button
                                        className='btn av-btn-simple fw-boldest mt-2'
                                        onClick={() =>
                                            setReplaceModalIsOpen(true)
                                        }
                                    >
                                        <i className='fas fa-exchange-alt'></i>{' '}
                                        Замена
                                    </button>
                                </>
                            ) : null}
                        </div>
                        <div className='col text-end'>
                            {GetOpenButtons()}

                            {canEdit ? (
                                <button
                                    className='btn av-btn-simple fw-boldest'
                                    onClick={() => setUpdateModalIsOpen(true)}
                                >
                                    <img
                                        src='/media/icons/base/edit-3.svg'
                                        className='align-top'
                                    />{' '}
                                    Изменить
                                </button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DevicePanel;
