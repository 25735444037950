import { baseApi } from "../baseApi";

export const parkingImagesApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getParkingImages: builder.query({
            query: (areaId) => ({
                url: `parking/images?areaId=${areaId}`,
                method: "GET",
            }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.result.map(({ id }) => ({
                              type: "ParkingImages",
                              id,
                          })),
                          { type: "ParkingImages", id: "List" },
                      ]
                    : [{ type: "ParkingImages", id: "List" }],
        }),
        getParkingImageById: builder.query({
            query: (id) => `parking/images/${id}`,
            providesTags: (result) => [
                { type: "ParkingImage", id: result?.result.id },
            ],
        }),
        createParkingImage: builder.mutation({
            query: (body) => ({
                url: "parking/images",
                method: "POST",
                body,
            }),
            invalidatesTags: [{ type: "ParkingImages", id: "List" }],
        }),
        deleteParkingImage: builder.mutation({
            query: (id) => ({
                url: `parking/images/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "ParkingImages", id: "List" }],
        }),
    }),
});

export const {
    useGetParkingImagesQuery,
    useGetParkingImageByIdQuery,
    useCreateParkingImageMutation,
    useDeleteParkingImageMutation,
} = parkingImagesApiSlice;
