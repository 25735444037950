import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getEmployeeAsync,
    selectEmployee,
    selectIsLoading,
    updateEmployeeAsync,
} from '../../../app/storeReducers/AccountEmployeesSlice';
import { DateToString, UtcToLocal } from '../../../helpers/DateHelpers';
import { HumanPhone } from '../../../helpers/PhoneHelper';
import { Http } from '../../../http';
import EmployeeCreateModal from '../EmployeeCreateModal';
import EmployeeDeleteModal from '../EmployeeDeleteModal';
import SecutiryContext from '../../../features/securityContext';
import FaceWarnings from './FaceWarnings';

const EmployeePanel = (props) => {
    const { employeeId } = props;

    const dispatch = useDispatch();
    const employee = useSelector(selectEmployee);
    const isLoading = useSelector(selectIsLoading);

    const [avatar, setAvatar] = useState(null);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);

    const { context } = useContext(SecutiryContext);
    const [canWrite, setCanWrite] = useState(false);

    useEffect(() => {
        function loadResourcesAndData() {
            const actions = context?.permissions?.find(
                (x) => x.id === parseInt(employee?.account?.id)
            )?.actions;
            setCanWrite(context.isRoot || actions?.includes('employees_edit'));
        }

        loadResourcesAndData();
    }, [employee, context]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            loadData();
        }

        loadResourcesAndDataAsync();
    }, [employeeId]);

    const loadData = async () => {
        dispatch(
            getEmployeeAsync({
                employeeId,
            })
        );
    };

    const lockUnlockEmployee = async (isBlock) => {
        let emp = await Http.get('api/employees/' + employeeId, true);
        emp.result.blocked = isBlock;
        const dtNow = new Date();
        if (
            !isBlock &&
            emp?.result?.dateFinish &&
            dtNow > new Date(emp.result.dateFinish)
        ) {
            emp.result.dateFinish = null;
        }

        dispatch(updateEmployeeAsync({ employee: emp.result }));
    };

    const closeUpdateModal = () => {
        setUpdateModalIsOpen(false);
    };

    return (
        <>
            <EmployeeCreateModal
                accountId={employee?.account?.id}
                employeeId={employeeId}
                isOpen={updateModalIsOpen}
                closeHandle={closeUpdateModal}
                deletedAvatar={() => setAvatar(null)}
            />
            <EmployeeDeleteModal
                employee={employee}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card border' id='employeePanel'>
                <div className='card-body employee-card p-6'>
                    <div className='row mb-4'>
                        <div className='col d-flex'>
                            <div>
                                {employee.thumbnail ? (
                                    <img
                                        src={employee.thumbnail}
                                        className='rounded-circle me-3'
                                        style={{
                                            width: 64,
                                            height: 64,
                                        }}
                                    />
                                ) : (
                                    <div className='avatar-text rounded-circle me-3'>
                                        {`${
                                            employee?.lastName?.length > 0
                                                ? employee.lastName[0]
                                                : ''
                                        }${
                                            employee?.firstName?.length > 0
                                                ? employee.firstName[0]
                                                : ''
                                        }`.trim()}
                                    </div>
                                )}
                            </div>
                            <div>
                                <div className='employee-fio mb-1'>
                                    {`${employee?.lastName} ${employee?.firstName} ${employee?.secondName}`
                                        .replace('  ', ' ')
                                        .trim()}
                                </div>
                                <div className='employee-phone d-flex align-items-center'>
                                    <img
                                        src='/media/icons/base/smartphone.svg'
                                        className='h-20px me-1'
                                        alt='avanguard logo'
                                    />{' '}
                                    <span>
                                        {employee?.user ? (
                                            HumanPhone(employee?.user?.title)
                                        ) : (
                                            <i>Пользователь не привязан</i>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='fild-label'>Должность</div>
                            <div className='fild-text'>
                                {employee?.occupation}
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='fild-label'>Табельный номер</div>
                            <div className='fild-text'>
                                {employee?.persNumber || ''}
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='fild-label'>Период действия</div>
                            <div className='fild-text'>
                                {employee?.dateStart != null
                                    ? DateToString(
                                          UtcToLocal(employee.dateStart)
                                      )
                                    : '-'}{' '}
                                -{' '}
                                {employee?.dateFinish != null
                                    ? DateToString(
                                          UtcToLocal(employee.dateFinish)
                                      )
                                    : '-'}
                            </div>
                        </div>
                    </div>
                    <div className='row mb-4'>
                        <div className='col'>
                            <div className='fild-label'>Комментарий</div>
                            <div className='fild-text'>
                                {employee?.comments}
                            </div>
                        </div>
                    </div>
                    {canWrite ? (
                        <div className='row mb-4'>
                            <div className='col-md-8'>
                                {employee?.blocked ? (
                                    <button
                                        className='btn av-btn-simple av-border-warning-500 fw-boldest me-3'
                                        onClick={() =>
                                            lockUnlockEmployee(false)
                                        }
                                    >
                                        <i className='fas fa-unlock me-2' />
                                        Разблокировать
                                    </button>
                                ) : (
                                    <button
                                        className='btn av-btn-warning av-border-warning-500 fw-boldest me-3'
                                        onClick={() => lockUnlockEmployee(true)}
                                        disabled={isLoading}
                                    >
                                        <img
                                            src='/media/icons/avanguard/lock.png'
                                            height='20px'
                                            className='align-top me-2'
                                        />
                                        Заблокировать
                                    </button>
                                )}
                                <button
                                    className='btn av-btn-danger fw-boldest'
                                    onClick={() => setDeleteModalIsOpen(true)}
                                    disabled={isLoading}
                                >
                                    <img
                                        src='/media/icons/avanguard/trash-white.png'
                                        height='20px'
                                        className='align-top me-2'
                                    />
                                    Удалить
                                </button>
                            </div>
                            <div className='col text-end'>
                                <button
                                    className='btn av-btn-simple fw-boldest'
                                    onClick={() => setUpdateModalIsOpen(true)}
                                    disabled={isLoading}
                                >
                                    <img
                                        src='/media/icons/base/edit-3.svg'
                                        height='20px'
                                        className='align-top me-2'
                                    />{' '}
                                    Изменить
                                </button>
                            </div>
                        </div>
                    ) : null}
                    {employee.warnings?.includes('a') ||
                    employee.warningDescriptions !== null ? (
                        <div className='card info-card mb-4 av-bg-warning av-border-warning border'>
                            <div className='card-body py-3 px-4'>
                                <div className='d-flex flex-row'>
                                    <div className='info-card-icon info-card-icon-warning me-4 align-self-center'>
                                        <img src='/media/icons/base/info.svg' />
                                    </div>
                                    <div className='align-self-center'>
                                        <FaceWarnings
                                            warnings={employee.warnings}
                                        />
                                        {employee.warningDescriptions?.map(
                                            (item, index) => (
                                                <div key={index}>{item}</div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default EmployeePanel;
