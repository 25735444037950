import DeviceWidget from './DeviceWidget';
import AccountWidget from './AccountWidget';
import HostWidget from './HostWidget';
import SupportWidget from './SupportWidget';
import { useGetSummaryQuery } from '../../../app/storeReducers/adminApiSlice';

const DashboardList = () => {
    const { data: summary } = useGetSummaryQuery();

    return (
        <div className='row g-5 g-xl-8'>
            <div className='col-md-6'>
                <DeviceWidget
                    deviceCountTotal={summary?.result?.devices_count_total}
                    deviceCountOnline={
                        (summary?.result?.devices_count_total ?? 0) - (summary?.result?.devices_count_offline ?? 0)
                    }
                    deviceCountOffline={summary?.result?.devices_count_offline}
                />
                <AccountWidget
                    accountCountTotal={summary?.result?.accounts_count_total}
                    accountCountOnline={
                        (summary?.result?.accounts_count_total ?? 0) - (summary?.result?.accounts_count_banned ?? 0)
                    }
                    accountCountOffline={summary?.result?.accounts_count_banned}
                />
                <HostWidget
                    hostCountTotal={summary?.result?.hosts_count_total}
                    hostCountOnline={
                        (summary?.result?.hosts_count_total ?? 0) - (summary?.result?.hosts_count_offline ?? 0)
                    }
                    hostCountOffline={summary?.result?.hosts_count_offline}
                />
                <SupportWidget
                    supportCountTotal={summary?.result?.support_count_total}
                    supportCountNew={summary?.result?.support_count_new}
                    supportCountOpen={summary?.result?.support_count_open}
                />
            </div>
        </div>
    );
};

export default DashboardList;
