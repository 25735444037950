import { DateToString, UtcToLocal } from "../../../../helpers/DateHelpers";

const GuestModal = (props) => {
    const { entity, author, isOpen, closeHandle } = props;

    return (
        <>
            <div
                className={"modal fade" + (isOpen ? " show" : "")}
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Информация о посетителе
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row mb-2">
                                <div className="col">Аккаунт</div>
                                <div className="col">
                                    {entity?.account?.title}
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">ФИО посетителя</div>
                                <div className="col">{entity?.name}</div>
                            </div>
                            {entity?.carNumber ? (
                                <div className="row mb-2">
                                    <div className="col">Автомобиль</div>
                                    <div className="col">
                                        {entity?.carNumber} {entity?.carRegion}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}

                            <div className="row mb-2">
                                <div className="col">Заказчик</div>
                                <div className="col">{author?.name}</div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">Телефон</div>
                                <div className="col">{author?.user?.title}</div>
                            </div>
                            {entity?.carDateIn ? (
                                <div className="row mb-2">
                                    <div className="col">Дата въезда</div>
                                    <div className="col">
                                        {DateToString(
                                            UtcToLocal(entity?.carDateIn),
                                            true
                                        )}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {entity?.carDateOut ? (
                                <div className="row mb-2">
                                    <div className="col">Дата выезда</div>
                                    <div className="col">
                                        {DateToString(
                                            UtcToLocal(entity?.carDateOut),
                                            true
                                        )}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn av-btn-simple"
                                onClick={closeHandle}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className="modal-backdrop fade show" /> : null}
        </>
    );
};

export default GuestModal;
