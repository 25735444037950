import { useContext, useEffect, useState } from 'react';
import ReactJoyride, { ACTIONS, STATUS } from 'react-joyride';
import CallMeModal from './CallMeModal';
import EndModal from './EndModal';
import FinishModal from './FinishModal';
import SecutiryContext from '../../features/securityContext';

const Onboarding = (props) => {
    const { steps, run, callback, last } = props;

    const { context } = useContext(SecutiryContext);

    const [canRun, setCanRun] = useState(true);
    const [userLogin, setUserLogin] = useState('');

    const [finishIsOpen, setFinishIsOpen] = useState(false);
    const [callMeModalIsOpen, setCallMeModalIsOpen] = useState(false);
    const [endModalIsOpen, setEndModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setUserLogin(context?.user?.login);
        }

        loadResourcesAndDataAsync();
    }, [context]);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            const hasTutorial = localStorage.getItem('tutorial') ? true : false;
            setCanRun(!hasTutorial);
        }

        loadResourcesAndDataAsync();
    }, []);

    const Tooltip = ({
        continuous,
        index,
        step,
        backProps,
        closeProps,
        primaryProps,
        tooltipProps,
        skipProps,
    }) => (
        <div className='card onboarding-card' {...tooltipProps}>
            {step.title && <div>{step.title}</div>}
            <div className='card-body p-3'>
                {step.content}
                <div className='row'>
                    <div className='col'>
                        <div className='d-grid'>
                            <button
                                className='btn btn-sm av-btn-simple'
                                {...skipProps}
                            >
                                Пропустить
                            </button>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='d-grid'>
                            {continuous && (
                                <button
                                    className='btn btn-sm av-btn-primary'
                                    {...primaryProps}
                                >
                                    Дальше
                                </button>
                            )}
                            {!continuous && (
                                <button
                                    className='btn btn-sm av-btn-primary'
                                    {...closeProps}
                                >
                                    Дальше
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if (action === ACTIONS.SKIP) {
            localStorage.setItem('tutorial', 'false');
            setFinishIsOpen(true);
        } else {
            if (last && action == ACTIONS.CLOSE && status == STATUS.FINISHED) {
                localStorage.setItem('tutorial', 'true');
                setFinishIsOpen(true);
            } else {
                callback(action, status, index);
            }
        }
    };

    const openCallMeModal = () => {
        setFinishIsOpen(false);
        setCallMeModalIsOpen(true);
    };

    const callMeOk = () => {
        setCallMeModalIsOpen(false);
        setEndModalIsOpen(true);
    };

    return (
        <>
            <FinishModal
                isOpen={finishIsOpen}
                okHandle={() => {
                    openCallMeModal();
                    window.reachGoal('demolead');
                }}
                closeHandle={() => {
                    setFinishIsOpen(false);
                    window.reachGoal('democlose');
                }}
            />
            <CallMeModal
                isOpen={callMeModalIsOpen}
                okHandle={callMeOk}
                closeHandle={() => setCallMeModalIsOpen(false)}
            />
            <EndModal
                isOpen={endModalIsOpen}
                closeHandle={() => setEndModalIsOpen(false)}
            />
            {/* временно отключил изменив логин */}
            <ReactJoyride
                steps={steps}
                run={run && canRun && userLogin === '70000000000'}
                tooltipComponent={Tooltip}
                spotlightPadding={0}
                callback={handleJoyrideCallback}
                disableOverlayClose={false}
                disableScrolling={true}
                disableScrollParentFix={true}
                styles={{
                    options: {
                        zIndex: 1060,
                    },
                }}
            />
        </>
    );
};

export default Onboarding;
