import { useContext, useEffect, useState } from 'react';
import { Http } from '../../../http';
import { useDispatch } from 'react-redux';
import { updateAsync } from '../../../app/storeReducers/AccountLocationsSlice';
import SkeletonInput from '../../../components/skeleton/SkeletonInput';
import LocationDeleteModal from '../LocationDeleteModal';
import SecutiryContext from '../../../features/securityContext';

const LocationEditPanel = (props) => {
    const { accountId, entityId } = props;

    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');

    const { context } = useContext(SecutiryContext);
    const [canEdit, setCanEdit] = useState(false);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setIsLoading(true);
            const getResult = await Http.get('api/locations/' + entityId, true);
            setName(getResult.result.name);
            setAddress(getResult.result.address);
            setIsLoading(false);
        }

        loadResourcesAndDataAsync();
    }, [entityId]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(context.isRoot || actions?.includes('location_edit'));
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            name,
            account: { id: accountId },
            id: entityId,
            address,
        };
        dispatch(updateAsync(entity));
    };

    return (
        <>
            <LocationDeleteModal
                entity={{ name, id: entityId, account: { id: accountId } }}
                isOpen={deleteModalIsOpen}
                closeHandle={() => setDeleteModalIsOpen(false)}
            />
            <div className='card border'>
                <div className='card-body pb-2'>
                    <div className='row mb-2'>
                        <div className='col-md-6'>
                            <h3>Объект</h3>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='name'
                            label='Название'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className='mb-3'>
                        <SkeletonInput
                            isLoading={isLoading}
                            id='address'
                            label='Адрес'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-4'>
                            <button
                                className='btn av-btn-danger fw-boldest'
                                onClick={() => setDeleteModalIsOpen(true)}
                                disabled={!canEdit}
                            >
                                <i className='far fa-trash-alt me-2' /> Удалить
                            </button>
                        </div>
                        <div className='col text-end'>
                            <button
                                className='btn av-btn-simple fw-boldest'
                                onClick={saveEntity}
                                disabled={!canEdit}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LocationEditPanel;
