import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SkeletonInput from "../../../../components/skeleton/SkeletonInput";
import TransportEmergencySelect from "./TransportEmergencySelect";
import { selectContext } from "../../../../app/storeReducers/AuthSlice";
import {
    useBlockAccountMutation,
    useGetAccountByIdQuery,
    useUnblockAccountMutation,
    useUpdateAccountMutation,
} from "../../../../app/storeReducers/acountsApiSlice";
import { addNotification } from "../../../../app/storeReducers/NotifySlice";

const AccountEditPanel = (props) => {
    const { accountId } = props;

    const dispatch = useDispatch();
    const context = useSelector(selectContext);

    const { data: account, isLoading } = useGetAccountByIdQuery(accountId);
    const [updateAsync] = useUpdateAccountMutation();
    const [blockAsync] = useBlockAccountMutation();
    const [unblockAsync] = useUnblockAccountMutation();

    const [name, setName] = useState("");
    const [recognitionIgnoreRegion, setRecognitionIgnoreRegion] =
        useState(false);
    const [banGuestParking, setBanGuestParking] = useState(false);
    const [allowAnonymousGuest, setAllowAnonymousGuest] = useState(false);
    const [allowPersonalCardForGuest, setAllowPersonalCardForGuest] =
        useState(false);
    const [singleGuestParking, setSingleGuestParking] = useState(false);
    const [guestCarNumberRequired, setGuestCarNumberRequired] = useState(false);
    const [host, setHost] = useState("");
    const [guestQrCode, setGuestQrCode] = useState(false);
    const [guestPinCode, setGuestPinCode] = useState(false);
    const [directPinCode, setDirectPinCode] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [selectedClearCardHour, setSelectedClearCardHour] = useState(-1);
    const [oneDayTicket, setOneDayTicket] = useState(false);
    const [allowedSpecialTransports, setAllowedSpecialTransports] = useState(
        []
    );
    const [preinstallSpecTrans, setPreinstallSpecTrans] = useState([]);
    const [selectedBlockDuration, setSelectedBlockDuration] = useState(0);
    const [carGuestOrderMode, setCarGuestOrderMode] = useState(0);
    const [webhook, setWebhook] = useState("");
    const [vehicleQuota, setVehicleQuota] = useState(0);

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            setName(account?.result?.name);
            setRecognitionIgnoreRegion(
                account?.result?.recognitionIgnoreRegion
            );
            setBanGuestParking(account?.result?.banGuestParking);
            setAllowAnonymousGuest(account?.result?.allowAnonymousGuest);
            setAllowPersonalCardForGuest(
                account?.result?.allowPersonalCardForGuest
            );
            setHost(account?.result?.host?.title);
            setGuestQrCode(account?.result?.guestQrCode);
            setGuestPinCode(account?.result?.guestPinCode);
            setDirectPinCode(account?.result?.directPinCode);
            setSingleGuestParking(account?.result?.singleGuestParking);
            setSelectedClearCardHour(account?.result?.clearCardHour ?? -1);
            setGuestCarNumberRequired(account?.result?.guestCarNumberRequired);
            setOneDayTicket(account?.result?.oneDayTicket);
            setPreinstallSpecTrans(account?.result?.allowedSpecialTransports);
            setAllowedSpecialTransports(
                account?.result?.allowedSpecialTransports
            );
            setSelectedBlockDuration(account?.result?.blacklistBlockDuration);
            setCarGuestOrderMode(account?.result?.carGuestOrderMode);
            setWebhook(account?.result?.webhook ?? "");
            setVehicleQuota(account?.result?.vehicleQuota);
        }
        if (account) {
            loadResourcesAndDataAsync();
        }
    }, [account]);

    useEffect(() => {
        const actions = context?.permissions?.find(
            (x) => x.id === parseInt(accountId)
        )?.actions;
        setCanEdit(
            context?.isRoot ||
                actions?.includes("account_edit") ||
                actions?.includes("subaccount_edit")
        );
    }, [context, accountId]);

    const saveEntity = async () => {
        const entity = {
            id: accountId,
            name,
            recognitionIgnoreRegion,
            banGuestParking,
            allowAnonymousGuest,
            allowPersonalCardForGuest,
            guestQrCode,
            guestPinCode,
            clearCardHour: selectedClearCardHour,
            guestCarNumberRequired,
            singleGuestParking,
            oneDayTicket,
            allowedSpecialTransports,
            blacklistBlockDuration: selectedBlockDuration,
            carGuestOrderMode,
            webhook,
            vehicleQuota,
            directPinCode
        };
        await updateAsync(entity);
    };

    const blockAccount = async () => {
        const request = await blockAsync(accountId).unwrap();

        if (request?.error) {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: "Ошибка блокировки аккаунта!",
                    description: request.error,
                    type: "danger",
                })
            );
            return;
        }
        dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: "Аккаунт заблокирован!",
                description: "Блокировка аккаунта прошла успешно!",
                type: "success",
            })
        );
    };

    const unblockAccount = async () => {
        const request = await unblockAsync(accountId).unwrap();

        if (request?.error) {
            dispatch(
                addNotification({
                    dateNotify: Date.now(),
                    title: "Ошибка разблокировки аккаунта!",
                    description: request.error,
                    type: "danger",
                })
            );
            return;
        }
        dispatch(
            addNotification({
                dateNotify: Date.now(),
                title: "Аккаунт разблокирован!",
                description: "Разблокировка аккаунта прошла успешно!",
                type: "success",
            })
        );
    };

    return (
        <div className="card">
            <div className="card-body py-3">
                <div className="mb-3">
                    {account?.result?.isBlocked ? (
                        <div className="mb-4">
                            <span className="badge bg-danger">Блокировка</span>
                        </div>
                    ) : null}
                    <SkeletonInput
                        isLoading={isLoading}
                        id="name"
                        label="Название"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                {context?.isRoot ? (
                    <div className="mb-5">
                        <SkeletonInput
                            isLoading={isLoading}
                            id="host"
                            label="Хост"
                            value={host}
                            readOnly
                        />
                    </div>
                ) : null}

                <div className="form-group form-check mb-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="banGuestParking"
                        checked={banGuestParking}
                        onChange={(e) => setBanGuestParking(e.target.checked)}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="banGuestParking"
                    >
                        Запрет парковки посетителей
                    </label>
                </div>
                <div className="mb-3">
                    <label className="form-label">
                        Создание заявки на транспорт охраной
                    </label>
                    <select
                        className="form-select"
                        value={carGuestOrderMode}
                        onChange={(e) => setCarGuestOrderMode(e.target.value)}
                    >
                        <option value="0">Выключено</option>
                        <option value="1">Включено</option>
                        <option value="2">
                            Включено с подтверждением выезда
                        </option>
                        <option value="3">Включено с быстрой кнопкой</option>
                        <option value="4">
                            Включено с быстрой кнопкой с подтверждением выезда
                        </option>
                    </select>
                </div>

                {account?.result?.type !== "subaccount" ? (
                    <>
                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="guestCarNumberRequired"
                                checked={guestCarNumberRequired}
                                onChange={(e) =>
                                    setGuestCarNumberRequired(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="guestCarNumberRequired"
                            >
                                Для посетителей обязателен номер автомобиля
                            </label>
                        </div>

                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="recognitionIgnoreRegion"
                                checked={recognitionIgnoreRegion}
                                onChange={(e) =>
                                    setRecognitionIgnoreRegion(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="recognitionIgnoreRegion"
                            >
                                Игнорировать регион при распознавании
                            </label>
                        </div>

                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="allowAnonymousGuest"
                                checked={allowAnonymousGuest}
                                onChange={(e) =>
                                    setAllowAnonymousGuest(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="allowAnonymousGuest"
                            >
                                Разрешить создавать посетителей без номера
                                телефона
                            </label>
                        </div>
                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="allowPersonalCardForGuest"
                                checked={allowPersonalCardForGuest}
                                onChange={(e) =>
                                    setAllowPersonalCardForGuest(
                                        e.target.checked
                                    )
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="allowPersonalCardForGuest"
                            >
                                Разрешить вход посетителей с персональными
                                картами
                            </label>
                        </div>
                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="guestQrCode"
                                checked={guestQrCode}
                                onChange={(e) =>
                                    setGuestQrCode(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="guestQrCode"
                            >
                                Генерировать QR код у посетителей
                            </label>
                        </div>
                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="guestPinCode"
                                checked={guestPinCode}
                                onChange={(e) =>
                                    setGuestPinCode(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="guestPinCode"
                            >
                                Генерировать PIN-код у посетителей
                            </label>
                        </div>
                        {context?.isRoot ? (
                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="guestPinCode"
                                checked={directPinCode}
                                onChange={(e) =>
                                    setDirectPinCode(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="guestPinCode"
                            >
                                Прямой PIN-код (не конвертировать)
                            </label>
                        </div>
                        ) : ''}
                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="singleGuestParking"
                                checked={singleGuestParking}
                                onChange={(e) =>
                                    setSingleGuestParking(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="singleGuestParking"
                            >
                                Однократный въезд для посетителей
                            </label>
                        </div>
                        <div className="form-group form-check mb-3">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="oneDayTicket"
                                checked={oneDayTicket}
                                onChange={(e) =>
                                    setOneDayTicket(e.target.checked)
                                }
                            />
                            <label
                                className="form-check-label"
                                htmlFor="oneDayTicket"
                            >
                                Для посетителей субаккаунтов пропуск на один
                                день
                            </label>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">
                                Параметры обнуления пропусков посетителей
                            </label>
                            <select
                                className="form-select"
                                value={selectedClearCardHour}
                                onChange={(e) =>
                                    setSelectedClearCardHour(e.target.value)
                                }
                            >
                                <option value="-1">нет</option>
                                <option value="0">00:00</option>
                                <option value="2">02:00</option>
                                <option value="4">04:00</option>
                                <option value="6">06:00</option>
                                <option value="8">08:00</option>
                            </select>
                        </div>
                        <TransportEmergencySelect
                            accountEmergences={preinstallSpecTrans}
                            setAccountEmergences={(items) =>
                                setAllowedSpecialTransports(items)
                            }
                        />
                        <div className="mb-3">
                            <label className="form-label">
                                Поиск кандидатов для черного списка.
                                <br />
                                Укажите число дней возможной блокировки
                            </label>
                            <select
                                className="form-select"
                                value={selectedBlockDuration}
                                onChange={(e) =>
                                    setSelectedBlockDuration(e.target.value)
                                }
                            >
                                <option value="0">Выключено</option>
                                <option value="7">7 дней</option>
                                <option value="14">14 дней</option>
                                <option value="21">21 день</option>
                                <option value="28">28 дней</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <SkeletonInput
                                isLoading={isLoading}
                                id="webhook"
                                label="Вебхук для отправки событий"
                                value={webhook}
                                onChange={(e) => setWebhook(e.target.value)}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mb-5">
                            <SkeletonInput
                                isLoading={isLoading}
                                id="vehicleQuota"
                                label="Квота для транспорта резидентов"
                                value={vehicleQuota}
                                onChange={(e) =>
                                    setVehicleQuota(e.target.value)
                                }
                                type="number"
                            />
                        </div>
                    </>
                )}
                <div className="row mb-3 mt-5">
                    <div className="col">
                        {context?.isRoot ? (
                            <>
                                {account?.result?.isBlocked ? (
                                    <>
                                        <button
                                            type="button"
                                            className="btn av-btn-simple me-4"
                                            onClick={unblockAccount}
                                            disabled={!canEdit}
                                        >
                                            Разблокировать
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn av-btn-danger me-4"
                                        onClick={blockAccount}
                                        disabled={!canEdit}
                                    >
                                        Заблокировать
                                    </button>
                                )}
                            </>
                        ) : null}
                    </div>
                    <div className="col text-end">
                        <button
                            type="button"
                            className="btn av-btn-primary"
                            onClick={saveEntity}
                            disabled={!canEdit}
                        >
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountEditPanel;
