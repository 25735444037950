/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { useGetNotificationsQuery } from '../../app/storeReducers/NotificationsAPI';
import Paginator from '../../components/Paginator';
import SkeletonTableFull from '../../components/skeleton/SkeletonTableFull';

const PersonalNotificationsList = (props) => {
    const [pageSize, setPageSize] = useState(50);
    const [pageIndex, setPageIndex] = useState(1);
    const [forcePage, setForcePage] = useState(0);
    const accountId = 0;
    const onlyNew = false;

    const changePage = (index) => {
        setPageIndex(index);
    };
    const changePageSize = async (size) => {
        setForcePage(pageIndex - 1);
        setPageSize(size);
    };

    const { data, isLoading } = useGetNotificationsQuery({ accountId, onlyNew, pageIndex, pageSize });

    return (
        <>
            <div className='card mb-3'>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <SkeletonTableFull
                            cols={{
                                rKeyItem: 'id',
                                dtString: 'Дата',
                                accountTitle: 'Аккаунт',
                                message: 'Текст',
                            }}
                            rowsCount={5}
                            isLoading={isLoading}
                            classTable='table-striped gy-7 gs-7'
                            entity={data?.result}
                        />
                    </div>
                </div>
            </div>
            <Paginator
                onPageChange={changePage}
                onPageSizeChange={changePageSize}
                pageCount={data?.paginator.totalPages}
                pageIndex={pageIndex}
                pageSizeArray={[50, 100, 200]}
                pageSize={pageSize}
                countCurrent={data?.result.length}
                countTotal={data?.paginator.totalCount}
                forcePage={forcePage}
            />
        </>
    );
};

export default PersonalNotificationsList;
