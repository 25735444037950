import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCredentials } from '../app/storeReducers/CurrentUserSlice';
import { useHistory } from 'react-router-dom';
import WaitLoading from '../components/WaitLoading';
import { useLazyGetCurrentUserQuery } from '../app/storeReducers/profileApiSlice';
import { useGetSecurityContextMutation, useLoginMutation } from '../app/storeReducers/authApiSlice';
import { selectContext, setSecurityContext } from '../app/storeReducers/AuthSlice';

const DemoPage = (props) => {
    const history = useHistory();

    const [getCurrentUser] = useLazyGetCurrentUserQuery();
    const [loginUser] = useLoginMutation();
    const [getContext] = useGetSecurityContextMutation();

    const context = useSelector(selectContext);

    const dispatch = useDispatch();

    const loginHandle = async () => {
        const result = await loginUser({
            login: '70000000000',
            password: '123456',
        }).unwrap();

        dispatch(setCredentials(result));
        dispatch(setSecurityContext((await getContext().unwrap()).result));
    };

    useEffect(() => {
        async function loadData() {
            let redirectUrl = '/personal/overview';
            if (props.location.state !== undefined) {
                redirectUrl = props.location.state.from;
            }
            if (context) {
                history.push(redirectUrl);
            } else {
                loginHandle();
            }
        }
        loadData();
    }, [context]);

    return <WaitLoading isLoading={true}></WaitLoading>;
};

export default DemoPage;
