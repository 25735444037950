import PermissionPage from '../../../components/PermissionPage';
import RoleEmployeesPanel from './employee/RoleEmployeesPanel';
import RoleEditPanel from './RoleEditPanel';

const RoleCardPage = (props) => {
    const accountId = props.match.params.accountId;
    const roleId = props.match.params.roleId;

    return (
        <PermissionPage accountId={accountId} roles={['groups_list']}>
            <h1 className='mb-5'>Карточка роли</h1>
            <div className='row'>
                <div className='col-6'>
                    <RoleEditPanel accountId={accountId} roleId={roleId} />
                </div>
                <div className='col-6'>
                    <div className='row'>
                        <div className='col'>
                            <RoleEmployeesPanel
                                accountId={accountId}
                                entityId={roleId}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </PermissionPage>
    );
};

export default RoleCardPage;
