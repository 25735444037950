import Paginator from '../../../components/Paginator';
import {
    selectPaginator,
    setPageSize,
    selectPage,
    selectList,
    getListAsync,
} from '../../../app/storeReducers/AdminAccountsSlice';
import { useDispatch, useSelector } from 'react-redux';
import AccountsList from './AccountsList';

const AccountsPage = (props) => {
    const dispatch = useDispatch();

    const devices = useSelector(selectList);
    const paginator = useSelector(selectPaginator);
    const pageSize = useSelector((state) => state.accountDevices.pageSize);

    const changePage = async (pageIndex) => {
        dispatch(selectPage(pageIndex));
        dispatch(getListAsync());
    };

    const changePageSize = async (size) => {
        dispatch(selectPage(1));
        dispatch(setPageSize(size));
        dispatch(getListAsync());
    };

    return (
        <>
            <div className='row mt-4 mb-4'>
                <div className='col-md-12'>
                    <h1 className='mb-5'>Аккаунты</h1>

                    <AccountsList />
                </div>
            </div>
        </>
    );
};

export default AccountsPage;
