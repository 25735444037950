import { baseApi } from "../baseApi";

export const parkingBillingApiSlice = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getParkingBilling: builder.query({
            query: (data) =>
                `parking/billing?accountId=${data.accountId ?? ""}&date=${
                    data.date ?? ""
                }&export=false`,
        }),
        exportParkingBilling: builder.query({
            query: (data) => ({
                url: `parking/billing?accountId=${data.accountId ?? ""}&date=${
                    data.date ?? ""
                }&export=true`,
                responseHandler: async (response) => {
                    const blob = await response.blob();
                    const url = URL.createObjectURL(blob);
                    return { url };
                },
            }),
        }),
    }),
});

export const {
    useGetParkingBillingQuery,
    useLazyGetParkingBillingQuery,
    useExportParkingBillingQuery,
    useLazyExportParkingBillingQuery,
} = parkingBillingApiSlice;
