import { useEffect, useState } from 'react';
import {
    getDeviceAsync,
    selectDevice,
} from '../../app/storeReducers/AccountDevicesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Http } from '../../http';

const DeviceQrCodeEditModal = (props) => {
    const { isOpen, closeHandle } = props;

    const dispatch = useDispatch();

    const device = useSelector(selectDevice);

    const [qrCode, setQrCode] = useState('');

    useEffect(() => {
        async function loadResourcesAndDataAsync() {
            if (Object.keys(device).length !== 0) {
                setQrCode(device.qrCode !== null ? device.qrCode : device.uid);
            }
        }

        loadResourcesAndDataAsync();
    }, [device]);

    const saveEntity = async () => {
        const request = await Http.put(
            'api/devices/' + device.id + '/qrcode',
            true,
            { value: qrCode }
        );
        dispatch(getDeviceAsync(device.id));
        closeHandle();
    };

    return (
        <>
            <div
                className={'modal fade' + (isOpen ? ' show' : '')}
                tabIndex='-1'
            >
                <div className='modal-dialog modal-dialog-centered'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>
                                Изменить QR-код устройства
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                onClick={closeHandle}
                            ></button>
                        </div>
                        <div className='modal-body pb-2'>
                            <div className='row mb-3'>
                                <div className='col mb-2'>
                                    <div className='form-group'>
                                        <label htmlFor='qrCode'>
                                            Значение QR-кода
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            id='qrCode'
                                            value={qrCode}
                                            onChange={(e) =>
                                                setQrCode(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-footer py-2'>
                            <button
                                type='button'
                                className='btn av-btn-simple'
                                onClick={closeHandle}
                            >
                                Отменить
                            </button>
                            <button
                                type='button'
                                className='btn av-btn-primary'
                                onClick={saveEntity}
                            >
                                Сохранить изменения
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <div className='modal-backdrop fade show' /> : null}
        </>
    );
};

export default DeviceQrCodeEditModal;
